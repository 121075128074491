import React from "react";
// Customizable Area Start
import { Dimensions } from "react-native";
import { Button } from "@material-ui/core";
import MapDialog from "../../../components/src/MapDialog";
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Button data-test-id="open-dialog" onClick={this.handleDialogOpen}>Open</Button>
        <MapDialog 
          data-test-id="map-dialog"
          open={this.state.open}
          handleClose={this.handleDialogClose}
          title="Cinepolis: Nexus Plaza"
          destinationAddress={this.state.destinationAddress}
          fullWidth
        />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
