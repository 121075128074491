Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";
exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Log in With Google";
exports.loginButtonText = "Log in with email and password";
exports.orText = "or";
exports.loginCallMethod = "POST";
exports.loginPhoneEndPoint ="account_block/accounts/send_otps";
exports.loginPasswordEndPoint ="bx_block_login/logins";
exports.mobileOtpConfirmEndPoint="account_block/accounts/sms_confirmations"
exports.EmailEndPoint="account_block/accounts/send_otps/send_email_otp"
exports.emailOtpConfirmEndPoint="account_block/accounts/sms_confirmations"
exports.resendCodeEndPoint="account_block/accounts/send_otps"
exports.errorsMessage = "Password should contain minimum length 8, one digit, one uppercase letter, one lowercase letter, one special character, & no spaces"

exports.signUpGoogleEndPoint = "account/accounts";
exports.apiPostMethodType = "POST";
exports.contentTypeApi = "application/json";
exports.TermsAndConditionsURL = "bx_block_termsandconditions/terms_and_conditions"
exports.PrivacyPolicyURL = 'bx_block_privacy_policy/policy';
exports.getAccountDetailsEndPoint = "account_block/accounts"
// Customizable Area End
