import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import Loader from "../../../components/src/Loader.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { Back_arrow } from "../../contactus/src/assets";
import { hamburgerBarIcon } from "../../splashscreen/src/assets";
import { bank } from "./assets";
import { MainHeadingStyle } from "./AddMoney.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1:{
      fontSize:24,
      fontWeight:600,
      fontFamily:"Poppins",
      color:"#fff",
      marginBottom:18,
      '@media(max-width:600px)': {
        fontSize: "16px",
      },
    },
    h2: {
      fontSize: 24,
      color: "#FFF",
    },
    h3:{
      fontFamily: 'Poppins',
        fontSize: '18px',
        color: '#ffffff',
        fontWeight:500,
        margin: 0,
        '@media(max-width:600px)': {
            fontSize: "14px",
        }
    },
    h4: {
      color: "#fff",
      fontSize: 16,
      fontWeight:"normal",
      fontFamily: "Poppins",
      '@media(max-width:700px)': {
        fontSize: "14px",
      },
    },
    h5: {
      color: "#fff",
      fontSize: 14,
      fontWeight:"normal",
      fontFamily: "Poppins",
      marginTop:10,
      '@media(max-width:700px)': {
        fontSize: "12px",
      },
    },
  },
});

const AvailableBalanceBox = styled(Box)({
    background:"#2d2d2d",
    padding:"32px",
    borderRadius:8,
    width:"100%",
    position:"relative",
    '@media(max-width:600px)': {
      padding:"32px 10px",
    },
})

const BankImgBox = styled(Box)({
  width:96,
  height:96,
  marginRight:24,
  '@media(max-width:600px)': {
    width:50,
    height:50,
    marginRight:15,
  },
})

const BankLogoBox = styled(Box)({
  height:56,
  width:56,
  marginRight:24,
  '@media(max-width:600px)': {
    width:40,
    height:40,
    marginRight:15,
  },
})
// Customizable Area End

import BankAccountsListController, {
    Props,
    configJSON,
  } from "./BankAccountsListController";

export default class BankAccountsList extends BankAccountsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.bgcolor}>
      <Loader loading={this.state.loading} />
      <ThemeProvider theme={theme}>
        <MainHeaderComp 
          navigation ={this.props.navigation} 
        />
        
        <Container maxWidth={"lg"}>
          <Grid container style={webStyle.heading} spacing={3}>
            <Grid item
              xs={12}
              container
              alignItems="center"
              direction="row"
            >
                <img
                  style={webStyle.backArrow}
                  src={Back_arrow}
                  alt='backArrow'
                  data-test-id="back"
                  onClick={this.handleBackBtn}
                />
              <MainHeadingStyle id="contactText">{configJSON.bankAccountListHeading}</MainHeadingStyle>
            </Grid>
          </Grid>

          <Grid container style={webStyle.container}>
            <AvailableBalanceBox>
                <Box 
                  onClick={()=> this.navigateToAccountDetails()} 
                  style={webStyle.enterBox}
                  data-test-id="navigateToAccountDetails"  
                >
                  <Box style={webStyle.bankIconBox}>
                    <BankImgBox>
                      <img width="100%" height="100%" src={bank}/>
                    </BankImgBox>
                    <Box>
                      <Typography variant="h1">Enter New Bank Account Details</Typography>
                      <Typography variant="h4">Select Bank or Enter IFSC Details</Typography>
                    </Box>
                  </Box>
                  <img style={webStyle.nextArrowIcon} src={hamburgerBarIcon.next}/>
                </Box>

                <Typography variant="h3"><span style={{fontWeight:"normal"}}>Saved Bank Accounts</span></Typography>

                {this.state.beneficiaryDetailsList?.map((elm: any) => <Box
                  onClick={() => this.navigateToPaymentInfo(elm.beneId)}
                  style={webStyle.accountList}
                  data-test-id="navigateToPaymentInfo">
                  <Box style={webStyle.bankIconBox}>
                    <BankLogoBox>
                      <img width="100%" height="100%" src={bank} />
                    </BankLogoBox>
                    <Box>
                      <Typography variant="h3">{elm?.name}</Typography>
                      <Typography variant="h5">XX XX {elm?.bankAccount.slice(-4)}</Typography>
                      <Typography variant="h5">{elm?.ifsc}</Typography>
                    </Box>
                  </Box>
                </Box>
                )}
            </AvailableBalanceBox>
          </Grid>
        </Container>
        <FooterWithEvents />
      </ThemeProvider>
    </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  bgcolor: { 
    backgroundColor: "#121212"
  },
  container:{
    background:"#404040",
    borderRadius:"16px",
    marginTop:"32px",
    maxWidth:967
  },
  backArrow:{
    heigth:"32px",
    width:"32px",
    marginRight:10,
    cursor:"pointer"
  },
  heading: {
    marginTop: 30
  },
  cardTitleStyle: {
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 600,
    color: "#FFF"
  },
  enterBox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderBottom:"1px solid #595959",
    maxWidth:835,
    marginBottom:32,
    paddingBottom:24,
    cursor:"pointer"
  },
  nextArrowIcon:{
    width:10,
    height:17
  },
  bankIconBox:{
    display:"flex",
    alignItems:"center"
  },
  accountList:{
    margin:"24px 0px",
    maxWidth:"fit-content",
    cursor:"pointer"
  },
  bankLogo:{
    height:56,
    width:56,
    marginRight:24
  }
}

// Customizable Area End
