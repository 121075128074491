import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { IBookingData, IEventBookingData } from "../../../components/src/interfaces.web";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  handlePayment?: any;
  handleContactInfo? :any
  isPaymentSelection? :boolean,
  selectedValue? :string,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  eventBookingData:IEventBookingData,
  isLoading:boolean,
  isConvenienceFeeOpen:boolean
  isContactInfo:boolean,
  address:string,
  phoneNumber:string,
  email:string,
  emailError:string,
  mobileError:string,
  isITO:string,
  itoClosedIn:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventPreviewBookingController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    bookingDataApiId:any
    updateEventBookingContactInfoApiId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
                eventBookingData:{
                    "id": 70,
                    "account_id": 1402,
                    "event_schedule_id": 30,
                    "parent_id": null,
                    "event_seat_category_id": 36,
                    "sub_total": "150.0",
                    "total_amount": "167.7",
                    "cgst": "1.35",
                    "sgst": "1.35",
                    "base_amount": "15.0",
                    "convenience_fees": "17.7",
                    "status": "pending",
                    "payment_status": "incompleted",
                    "total_seats": 1,
                    "is_list_to_sell": false,
                    "is_invalid": false,
                    "uniq_booking_id": "HJLVPD",
                    "account_email": "test384@gmail.com",
                    "phone": "+918305163668",
                    "payment_order_id": null,
                    "payment_method_type": "other",
                    "seat_number": [],
                    "cancelled_seat_ids": [],
                    "cancel_date": null,
                    "booking_type": "regular",
                    "ito_price_per_seat": null,
                    "ito_status": null,
                    "ito_refund_to": null,
                    "sgst_percent":"9.0",
                    "cgst_percent":"9.0",
                    "seats": [
                        {
                            "id": 3135,
                            "seatable_id": 36,
                            "seatable_type": "BxBlockEvents::EventSeatCategory",
                            "created_at": "2024-02-08T05:58:47.399Z",
                            "updated_at": "2024-02-08T05:58:47.399Z",
                            "seat_number": "3UY2H",
                            "status": "on_hold",
                            "seat_type": "initial"
                        }
                    ],
                    "tier": "Gold",
                    "schedule": {
                        "id": 30,
                        "venue_id": 22,
                        "start_time": "19:51",
                        "end_time": "22:51",
                        "schedule_date": "2023-12-01",
                        "created_at": "2023-11-16T08:51:35.064Z",
                        "updated_at": "2023-11-16T08:51:35.064Z",
                        "duration_in_mintues": 180
                    },
                    "venue": {
                        "id": 22,
                        "name": "Testing 2",
                        "full_address": "Pune Municipal Corporation",
                        "user_city_id": 461,
                        "created_at": "2023-11-16T08:51:35.063Z",
                        "updated_at": "2023-11-16T08:51:35.063Z",
                        "event_detail_id": 9,
                        "lat": "18.520424",
                        "lng": "73.846327",
                        "pin_code": "411004"
                    },
                    "event": {
                        "id": 21,
                        "name": "One Man Army",
                        "description": "Description",
                        "term_and_conditions": "<p>Term and conditions*</p>",
                        "classify": "single",
                        "booking_start_date": "2024-02-08T00:00:00.000Z",
                        "booking_start_time": "11:26",
                        "booking_end_date": "2024-02-16T00:00:00.000Z",
                        "booking_end_time": "23:59",
                        "created_at": "2024-02-08T05:55:14.849Z",
                        "updated_at": "2024-02-08T05:55:14.849Z",
                        "language_ids": [
                            1,
                            3
                        ],
                        "above_age": "8",
                        "show_type": []
                    },
                    "language": [
                        {
                            "id": 1,
                            "name": "English",
                            "created_at": "2023-02-28T11:08:11.089Z",
                            "updated_at": "2023-02-28T11:08:11.089Z"
                        },
                        {
                            "id": 3,
                            "name": "Hindi",
                            "created_at": "2023-02-28T11:08:31.635Z",
                            "updated_at": "2023-02-28T11:08:31.635Z"
                        }
                    ],
                    "poster_url": [],
                    "qr_code": "https://ticketxchange-203359-ruby.b203359.dev.eastus.az.svc.builder.cafe//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBblVNIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--673dc6c874f4ea13d569d93c4dac3e34c803f6cf/QRCode_70.png",
                    "cancelled_seats": [],
                    "listed_resell_seats": null
                },
                isLoading:true,
                isConvenienceFeeOpen:false,
                isContactInfo:false,
                address:"",
                phoneNumber:"",
                email:"",
                emailError:"",
                mobileError:"",
                isITO:"false",
                itoClosedIn:0,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson){
            if (apiRequestCallId === this.bookingDataApiId) {
              const booking_end_date = moment(responseJson.data.attributes.ito_end_date);
              const currentDate = moment();
              const diffTime = booking_end_date.diff(currentDate,'days');

              this.setState({ 
                eventBookingData: responseJson.data.attributes, 
                itoClosedIn: diffTime,
                email: responseJson.data.attributes.account_email,
                phoneNumber: responseJson.data.attributes.phone.split("").splice(2).join("")
              })  
            }

            if(apiRequestCallId === this.updateEventBookingContactInfoApiId){
              this.handleUpdateContactInfoBooking(responseJson.data)
            }
          }
            this.setState({ isLoading: false })
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount () {
        this.getBookingData();
        const data =  await getStorageData(LocalStorageKeys.EventBookingData, true);
        const getITOstate = await getStorageData("ITOstate") as string;
        this.setState({address:data.Address, isITO:getITOstate})
    }

    async getBookingData() {

        const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true)
        this.bookingDataApiId = callApi({
            contentType: configJSON.contentTypeApi,
            method: configJSON.GetMethod,
            endPoint: `${configJSON.getEventBookingDataApiEndPoint}/${bookingId}`,
            headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string  },
          }, runEngine)
      }

      handleOnClickPay = () => {
        if (this.props?.isPaymentSelection) {
          this.state.eventBookingData.phone && this.state.eventBookingData.account_email
            ? this.props?.handlePayment()
            : this.handleContactInfo()
        } else {
          this.props?.handlePayment()
        }
      }
    
      handleContactInfo = () => {
        this.setState({ isContactInfo: true });
      }

      showCostData() {
        this.setState({ isConvenienceFeeOpen: !this.state.isConvenienceFeeOpen })
      }

      onChangeEmail = (e: any) => {
        this.setState({
          email: e.target.value,
          emailError: e.target.value.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) ? "" : "please enter valid email"
        });
      }
    
      onChangeNumber = (event: any) => {
        this.setState({
          phoneNumber: event.target.value,
          mobileError: !event.target.value || event.target.value.length != 10 ? "please enter valid Phone number" : ""
        })
      }

      onContinueContactInfo = async () => {

        const eventPayload = {
          event_booking: {
              account_email: this.state.email,
              phone: this.state.phoneNumber
          }
      }
    
        const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
        const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    
        const validity = this.checkValidation();
    
        if (validity) {
          this.updateEventBookingContactInfoApiId = callApi({
            contentType: configJSON.contentTypeApi,
            method: configJSON.PutMethod,
            endPoint: `${configJSON.eventBookingEndPoint}/${bookingId}`,
            headers: { "token": loginToken },
            body: eventPayload
          }, runEngine);
        }
      }

      checkValidation = () => {

        let isValid = true;
        if (this.state.email === "") {
          this.setState({
            emailError: "Please enter Email Id"
          });
          isValid = false;
        }
    
        const emailRegex = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
    
        if (!emailRegex.test(this.state.email)) {
          this.setState({
            emailError: "Please enter valid Email Id"
          });
          isValid = false;
        }
        if (this.state.phoneNumber === "") {
          this.setState({
            mobileError: "Please enter phone number"
          });
          isValid = false;
        }
        if (this.state.phoneNumber.length !== 10) {
          this.setState({
            mobileError: "Please enter valid phone number"
          });
          isValid = false;
        }
    
        return isValid
      }

      async handleUpdateContactInfoBooking(resJson: any) {
        this.setState((prevState) => ({
          eventBookingData: {
            ...prevState.eventBookingData,
            phone: `91${resJson.attributes.phone}`,
            account_email: resJson.attributes.account_email,
          }
        }));
        this.setState({ isContactInfo: false });
        const paymentContactData = {
          name:"Test",
          phoneNumber:`91${resJson.attributes.phone}`,
          contactEmail:resJson.attributes.account_email
        }
        setStorageData(LocalStorageKeys.PaymentContactInfo, JSON.stringify(paymentContactData))
      }
    // Customizable Area End
}
