import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { LocalStorageKeys, ReviewsFilterOption } from "../../../components/src/enums.web";
import { MovieReviews } from "../../../components/src/interfaces.web";

// Customizable Area End

export const configJSON = require("./config");

type ReviewListData = {
    reviewId: number,
    profileUrl: string,
    reviewerName: string,
    rating: number,
    reviewDate: string,
    reviewDescription: string | null,
    liked: boolean,
    likeCount: number,
    disliked: boolean,
    dislikeCount: number,
    tags: string[]
}
// Customizable Area Start

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isApiLoading: boolean;
    reviewLists: any;
    movieName: string;
    FilterList: { filterId: number, label: string | number }[];
    filterReviewList: ReviewListData[];
    getMovieID: any,
    hastaglist: any,
    reviewInfo: {
        averageRating: number,
        ratingsCount: number
    },
    reviewList: ReviewListData[],
    anchorEl: HTMLElement | null,
    selectedFilter: {
        sortBy: ReviewsFilterOption,
        rating: number
    }
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class RatingsReviewsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiReviewCallId: string = '';
    apiReviewsCallId: string = '';
    likeApiCalledId: string = "";
    dislikeApiCalledId: string = "";
    apiMovieCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.SessionResponseData)
        ];
        this.state = {
            reviewList: [],
            hastaglist: [],
            isApiLoading: false,
            reviewLists: {},
            movieName: '',
            FilterList: [
                { filterId: -1, label: 'All' },
                { filterId: -2, label: 'Top' },
                { filterId: 5, label: 5 },
                { filterId: 4, label: 4 },
                { filterId: 3, label: 3 },
                { filterId: 2, label: 2 },
                { filterId: 1, label: 1 },
            ],
            filterReviewList: [],
            getMovieID: '',
            reviewInfo: {
                ratingsCount: 0,
                averageRating: 0
            },
            anchorEl: null,
            selectedFilter: {
                sortBy: ReviewsFilterOption.NewestFirst,
                rating: -1
            }
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            await this.handleApiResponses(apiRequestCallId, responseJson);
        }
    }

    async handleApiResponses(apiRequestCallId: string, responseJson: any) {
        switch (apiRequestCallId) {
            case this.apiReviewCallId:
                this.handleApiReviewCall(responseJson as MovieReviews);
                break;

            case this.apiReviewsCallId:
                this.handleApiReviewsCall(responseJson);
                break;

            case this.apiMovieCallId:
                this.handleMovieResponse(responseJson)
                break;

        }
    }
    handleApiReviewCall(responseJson: MovieReviews) {

        const result: ReviewListData[] = responseJson.data.map((review) => ({
            reviewId: +review.id,
            profileUrl: review.attributes.user_profile || '',
            reviewerName: review.attributes.user_name,
            liked: review.attributes.is_liked,
            disliked: review.attributes.is_disliked,
            likeCount: review.attributes.like_count,
            dislikeCount: review.attributes.dislike_count,
            reviewDescription: review.attributes.description,
            rating: review.attributes.star,
            reviewDate: review.attributes.review_date,
            tags: review.attributes.tags.data.map(tagItem => tagItem.attributes.name)
        }))

        this.setState({ reviewList: result, filterReviewList: result }, () => {
            this.handleRatingAndSort(this.state.selectedFilter.rating, this.state.selectedFilter.sortBy)
        })
    }

    handleApiReviewsCall(responseJson: any) {
        this.setState({
            reviewInfo: {
                averageRating: responseJson.average_rating,
                ratingsCount: responseJson.ratings
            }
        })
    }


    handleLikeDislikeApiCall() {
        this.setState({ isApiLoading: true });
    }
    handleMovieResponse = (resJson: any) => {
        if (resJson && resJson.data) {
            this.setState({ movieName: resJson.data.attributes.name })
        }

    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        this.getReviewsData();
        this.getMovieDetails();
        const movieId = this.props.navigation.getParam("id");
        this.setState({ getMovieID: movieId }, () => {
            this.getReviewList();
        })
        window.scrollTo(0, 0)

        // Customizable Area End
    }
    // Customizable Area Start

    getMovieDetails = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        const movieId = this.props.navigation.getParam("id");

        this.apiMovieCallId = callApi({
            contentType: configJSON.content,
            method: configJSON.httpGetMethod,
            endPoint: `bx_block_categories/movies/${movieId}`,
            headers: { "token": authToken }
        }, runEngine)
    }

    getReviewList = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        this.apiReviewCallId = callApi({
            contentType: configJSON.contentTypeApi,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.reviewApiCallUrl}?movie_id=${this.state.getMovieID}&top=true`,
            headers: { "token": authToken }
        }, runEngine);
    }



    getReviewsData = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        this.apiReviewsCallId = callApi({
            contentType: configJSON.contentTypeApi,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.reviewApiEndPoint}?movie_id=${this.state.getMovieID}`,
            headers: { "token": authToken }
        }, runEngine);
    }

    handleBack = () => {
        this.props.navigation.goBack();
    }

    handleLike = async (reviewId: number) => {

        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        if(authToken) {
            const httpBody = {
                "data": {
                    "likeable_id": reviewId,
                    "likeable_type": "BxBlockReviews::Review"
                }
            }
            this.likeApiCalledId = callApi({
                contentType: configJSON.contentTypeApi,
                method: configJSON.httpPostMethod,
                endPoint: `${configJSON.likeApiEndPoint}`,
                headers: { "token": authToken },
                body: httpBody
    
            }, runEngine);

            const newReviewList = this.state.reviewList.map((review) => {
                return review.reviewId === reviewId ? {
                    ...review,
                    liked: true,
                    likeCount: review.likeCount + 1,
                    disliked: false,
                    dislikeCount: review.disliked ? review.dislikeCount - 1 : review.dislikeCount
                } : review
            })

            this.setState({ reviewList: newReviewList }, () => this.handleRatingAndSort(this.state.selectedFilter.rating, this.state.selectedFilter.sortBy))
        }
    }

    handleDislike = async (reviewId: number) => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        if(authToken) {
            const httpBody = {
                "data": {
                    "dislikeable_id": reviewId,
                    "dislikeable_type": "BxBlockReviews::Review"
                }
            }
            this.dislikeApiCalledId = callApi({
                contentType: configJSON.contentTypeApi,
                method: configJSON.httpPostMethod,
                endPoint: `${configJSON.dislikeikeApiEndPoint}`,
                headers: { "token": authToken },
                body: httpBody
    
            }, runEngine);

            const newReviewList = this.state.reviewList.map((review) => {
                return review.reviewId === reviewId ? {
                    ...review,
                    disliked: true,
                    dislikeCount: review.dislikeCount + 1,
                    liked: false,
                    likeCount: review.liked ? review.likeCount - 1 : review.likeCount
                } : review
            })

            this.setState({ reviewList: newReviewList }, () => this.handleRatingAndSort(this.state.selectedFilter.rating, this.state.selectedFilter.sortBy))
        }

    }

    handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ anchorEl: event.currentTarget })
    handleFilterClose = () =>  this.setState({ anchorEl: null })

    sortByNewestFirst = (list: ReviewListData[]): ReviewListData[] => {
        return list.sort((prev, current) => new Date(current.reviewDate).getTime() - new Date(prev.reviewDate).getTime());
    }

    sortByOldestFirst(list: ReviewListData[]): ReviewListData[] {
        return list.sort((prev, current) => new Date(prev.reviewDate).getTime() - new Date(current.reviewDate).getTime());
    }

    handleRatingAndSort = (rating: number, sortBy: ReviewsFilterOption) => {

        const { reviewList } = this.state;
        let filteredList: ReviewListData[] = [];

        if(rating < 0) filteredList = reviewList;
        else filteredList = reviewList.filter(review => review.rating === rating)

        if(sortBy === ReviewsFilterOption.NewestFirst) filteredList = this.sortByNewestFirst(filteredList);
        else filteredList = this.sortByOldestFirst(filteredList);

        if(rating === -2) filteredList.sort((first, second) => second.likeCount - first.likeCount)
            
        this.setState({
            filterReviewList: filteredList,
            selectedFilter: {
                rating,
                sortBy
            },
            anchorEl: null
        })

    }

}
// Customizable Area End

