import React from "react";

// Customizable Area Start

import {
    Box,
    CircularProgress,
    Container,
    Fade,
    Paper,
    Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import ReactPlayer from "react-player";
import { playButton } from "../../reservations2/src/assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1:{
            fontSize:24,
            fontWeight:600,
            marginTop:40
        },
    },
});

// Customizable Area End

import EventTrailerScreenController, {
    Props,
} from "./EventTrailerScreenController";

class EventTrailerScreen extends EventTrailerScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0} >
                    <MainHeaderComp navigation = {this.props.navigation} />
                    {this.state.isLoading ? <Fade in={this.state.isLoading} unmountOnExit>
                        <Box display="flex" justifyContent="center">
                            <CircularProgress className={classes.CircularProgress} />
                        </Box>
                    </Fade> :
                        <Container maxWidth="lg">
                            <Typography variant="h1">Event</Typography>
                            {this.state.trailer.map((item: any) => (
                                <div className={classes.trailersAlign} key={item.id}>
                                    <ReactPlayer
                                        id="Trailer"
                                        url={item.link}
                                        className={classes.reactPlayer}
                                        playing
                                        width='100%'
                                        height='100%'
                                        light={this.state.poster_url[0]}
                                        playIcon={<img src={playButton} alt="play" height={50} width={50} />}
                                    />
                                </div>
                            ))}

                            {this.state.videos && this.state.videos.map((item: any) => (
                                <div className={classes.trailersAlign} key={item.id}>
                                     <ReactPlayer
                                        id="Trailer"
                                        url={item}
                                        className={classes.reactPlayer}
                                        playing={false}
                                        controls={true}
                                        width='100%'
                                        height='100%'
                                        light={this.state.poster_url[0]}
                                        playIcon={<img src={playButton} alt="play" height={50} width={50} />}
                                    />
                                </div>
                            ))}
                        </Container>
                    }

                    <FooterWithEvents />
                </Paper>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    WrapperBoxs: {
        minHeight: '100vh',
    },
    reactPlayer: {
        "& .react-player__preview": {
            backgroundPosition: 'top center !important',
            backgroundRepeat: 'no-repeat'
        }
    },
    trailersAlign: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        position: 'relative' as const,
        width: "100%",
        aspectRatio: "1.94",
        ".react-player__preview": {
            backgroundPosition: 'top center !important'
        }
    },
    CircularProgress: {
        marginTop: "20px",
        color: "#ffb43a"
    },
}

export default (withStyles(styles)(EventTrailerScreen))
export { EventTrailerScreen }
// Customizable Area End
