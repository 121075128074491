import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  queryOption: string;
  subject: string;
  phone: string;
  description: string;
  emailError: boolean;
  emailErrorMessage: string;
  subjectError: boolean;
  subjectErrorMessage: string;
  queryError: boolean;
  queryErrorMessage: string;
  descError: boolean;
  descErrorMessage: string;
  mediaImage: boolean;
  mediaImageUrl: any;
  mediaContent:any;
  attachmentsError: any;
  phoneErrorMessage: any;
  completedSuccsessfully:boolean,
  query_id:string,
  loading:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusScreenController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId = ""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      email: "",
      queryOption: "",
      subject: "",
      phone: "",
      description: "",
      emailError: false,
      emailErrorMessage: "",
      subjectError: false,
      subjectErrorMessage: "",
      queryError: false,
      queryErrorMessage: "",
      descError: false,
      descErrorMessage: "",
      mediaImage: false,
      mediaImageUrl: [],
      mediaContent:[],
      attachmentsError: "",
      phoneErrorMessage: "",
      completedSuccsessfully:false,
      query_id:"",
      loading:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
    this.handleSubmit = this.handleSubmit.bind(this)
  }


  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.contactUsApiCallId) {
        this.setState({
          query_id:response.data.attributes.query_id,
          completedSuccsessfully:true
        });
       }
    }  
    // Customizable Area End
  }

  // Customizable Area Start
  handleEmailValue = (val: any) => {
    this.setState({
      email: val?.target?.value,
      emailError: false,
    })
  }

  handleSubjectValue = (val: any) => {
    this.setState({
      subject: val?.target?.value,
      subjectError: false,
    })
  }

  handlePhoneNumberValue = (value :{target:{value: string}}) => {
    (!value.target.value || value.target.value.match(/^[\d]+$/)) && this.setState({
      phone: value?.target?.value,
      phoneErrorMessage: "",
    })
  }

  handleQueryOption = (val: any) => {
    this.setState({
      queryOption: val?.target?.value,
      queryError: false,
    })
  }

  handleMessage = (val: any) => {
    const valueSize = val?.target?.value.length < 500
    valueSize && this.setState({
      description: val?.target?.value,
      descError: false,
    }) 
  }

  handleBackBtn = () => {
    this.props.navigation.goBack();
  }

  removeAttachment = (index:number) => {
    const updateMediaUrl = [...this.state.mediaImageUrl];
    const updateMediaContent = [...this.state.mediaContent];
    updateMediaUrl.splice(index,1);
    updateMediaContent.splice(index,1);
    this.setState({
      mediaImage: updateMediaUrl.length>0 ? true :false,
      mediaImageUrl: updateMediaUrl,
      mediaContent: updateMediaContent
    });
  }

  handleMedia = async(event: any) => {
    const fileValue = event && event.target.files && event.target.files.length>0;
    if (fileValue) {
      const fileSize = event?.target?.files[0].size > 2 * 1024 * 1024;
      if (fileSize){
        this.setState({ attachmentsError: "File name.pdf File size exceeds max limit. Maximum allowed file size is 2 MB" })
      }
      else{
        this.setState({
          mediaImage: true,
          attachmentsError: "",
          mediaImageUrl: [...this.state.mediaImageUrl,URL.createObjectURL(event.target.files[0])],
          mediaContent: [...this.state.mediaContent,event.target.files[0]]
        });
        
      }
    }

  }

  checkValidation = () => {

    let isValid = true;
    if (this.state.email === "") {
      this.setState({
        emailError: true,
        emailErrorMessage: "Please enter Email Id"
      });
      isValid = false;
    }

    const emailRegex = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
    
    if (!emailRegex.test(this.state.email)) {
      this.setState({
        emailError: true,
        emailErrorMessage: "Please enter valid Email Id"
      });
      isValid = false;
    }
    if (this.state.subject === "") {
      this.setState({
        subjectError: true,
        subjectErrorMessage: "Please enter subject"
      });
      isValid = false;
    }
    if (this.state.queryOption === "") {
      this.setState({
        queryError: true,
        queryErrorMessage: "Please select a query"
      });
      isValid = false;
    }
    if (this.state.description === "") {
      this.setState({
        descError: true,
        descErrorMessage: "Please enter your message"
      });
      isValid = false;
    }
    if (this.state.phone === "") {
      this.setState({
        phoneErrorMessage: "Please enter phone number"
      });
      isValid = false;
    }
    if (this.state.phone.length !== 10) {
      this.setState({
        phoneErrorMessage: "Please enter valid phone number"
      });
      isValid = false;
    }

    return isValid
  }

  handleSubmit = () => {
    const validity = this.checkValidation()
    if (validity) {

      const {email, subject, queryOption, phone, description, mediaContent} = this.state

      const formData = new FormData();

      formData.append("data[email_address]", email);
      formData.append("data[subject]", subject);
      formData.append("data[query]", queryOption);
      formData.append("data[mobile_number]", `91${phone}`);
      formData.append("data[description]", description);

      mediaContent.length > 0 && mediaContent.forEach((item: any) => {
        formData.append(`data[media][]`, item, item.name);
      });

      this.setState({loading:true});      
      const header = {
        token: configJSON.guestToken
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.contactUsApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactsUsApi
      );

       requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleDone = () => {
    this.props.navigation.navigate("Homepage");
  }

  // Customizable Area End
}
