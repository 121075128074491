import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IBookingData, IUserDetails } from "../../../components/src/interfaces.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { BookingType, EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL } from "../../../components/src/utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area 
  selectedValue: any;
  isHidden: any,
  orderId: string,
  isKycModal:boolean,
  isPaymentPopup:boolean,
  popupInfo:any,
  totalWalletBalance:number,
  loading:boolean,
  isFromEvent:boolean,
  hideOtherPayment: boolean,
  isFailedTransactionOpen: boolean,
  isBackDropOpen: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
declare let Cashfree: any;

export default class PaymentSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  cashfree: string = "";
  createOrderAPICallId: string = "";
  walletBalaceApi:string = "";
  transectionThroughWalletApi :string = ""
  updatePaymentAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedValue: '',
      isHidden: false,
      orderId: '',
      isKycModal:false,
      isPaymentPopup:false,
      popupInfo:{},
      totalWalletBalance:0,
      loading:false,
      isFromEvent:false,
      hideOtherPayment: false,
      isFailedTransactionOpen: false,
      isBackDropOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(resJson) {
        switch (apiRequestCallId) {
          case this.createOrderAPICallId:
            this.handlePaymentReceive(resJson)
            break;
          case this.walletBalaceApi:
            this.setState({totalWalletBalance:resJson.total_amount})
            break
          case this.transectionThroughWalletApi:
            this.navigateToBookingSuccessfull(resJson.data)
            break
          case this.updatePaymentAPICallId:
            this.handleUpdatePayment(resJson.message);
        }
      }
      this.setState({ loading: false })

    }
    // Customizable Area End
  }


  handlePaymentReceive = async (resJson: any) => {
    let checkoutOptions = {
      paymentSessionId: resJson.payment_session_id,
      returnUrl: window.location.origin + "/BookingSuccessful/"+ resJson.order_id

    }

    // modify the return URL based on feedback from QA
    let url = checkoutOptions.returnUrl.split("/BookingSuccessful/")[0];
    url = `${url}/PaymentSelection${this.state.isFromEvent ? "s/event" : ""}?order_id=${resJson.order_id}`;

    checkoutOptions.returnUrl = url;

    const cashfree = await Cashfree({
      mode: "sandbox" //or production
    });

    cashfree.checkout(checkoutOptions).then(function (result: any) {
      if (result.error) {
        alert(result.error.message)
      }
      if (result.redirect) {
        return
      }
    });
  }

  handleChange = (event: { target: { value: any; }; }) => {
    this.setState({ selectedValue: event.target.value });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isHidden: !prevState.isHidden,
    }));
  };

  // Customizable Area Start

   // create order for payment
   createOrder = async () => {
    let token: string = "";
    if (typeof window !== "undefined") {
      token = localStorage.getItem("login") || ""
    }
    const header = {
      "Content-Type": 'application/json',
      token: token
    };
    const httpBody = await this.getPayloadForPayment()
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOrderAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPayloadForPayment = async () => {

    const PaymentContactInfo = 
    await getStorageData(LocalStorageKeys.PaymentContactInfo, true)
    
    const { id, attributes: { email, full_phone_number, full_name }}: IUserDetails = 
      await getStorageData(LocalStorageKeys.UserDetails, true)
    
      const { totalAmount }: IBookingData = await getStorageData(LocalStorageKeys.BookingData, true)
    
      return {
      customer_details: {
        customer_id: id,
        customer_email: PaymentContactInfo?.contactEmail ?? email,
        customer_phone: PaymentContactInfo?.phoneNumber ? PaymentContactInfo?.phoneNumber : full_phone_number.split("").splice(2).join(""),
        customer_name: full_name ?? PaymentContactInfo?.name
      },
      order_amount: totalAmount,
      order_currency: "INR"
    }
  }

  handleProceedOnSelectWallet = async() => {
    const UserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
    if(UserDetails?.attributes?.kyc_status !== "approved"){
      this.setState({ isKycModal: true });
    } else{
      const { totalAmount }: IBookingData = await getStorageData(LocalStorageKeys.BookingData, true)
      if(totalAmount < this.state.totalWalletBalance){
        this.setState({
          popupInfo:{
            heading:"Confirm Payment",
            discription:`<span style=font-family:Roboto>&#8377;</span> ${totalAmount.toFixed(2)} will be deducted from your wallet balance`
          }
        })
      }else{
        this.setState({
          popupInfo:{
            heading:configJSON.insufficientBalanceHeading,
            discription:` Your Current Balance is less than <br />
            <span style=font-family:Roboto>&#8377;</span>
            ${totalAmount - this.state.totalWalletBalance} kindly
            <br /> update your Wallet Balance.`
          }
        })
      }
      this.setState({isPaymentPopup:true})
    }
  }

  handleProccedByWallet = async () => {
    const { totalAmount }: IBookingData = await getStorageData(LocalStorageKeys.BookingData, true)
    const listedTicket = await getStorageData("listedTicket");
    if (totalAmount < this.state.totalWalletBalance) {
      const data = new FormData();
      data.append("payment_method_type", "wallet");
      listedTicket === "true" && data.append("listed_booking", "true");
      this.setState({ loading: true })
      const { id }: IBookingData = await getStorageData(LocalStorageKeys.BookingData, true)
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.transectionThroughWalletApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ "token": await getStorageData(LocalStorageKeys.LoginToken) })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${this.state.isFromEvent ? configJSON.updateEventPaymentEndPoint:configJSON.updatePaymentEndPoint}/${id}/update_payment`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        data
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      await setStorageData(LocalStorageKeys.RedirectTo,this.state.isFromEvent ? "PaymentSelectionEvent/event" : "PaymentSelection")
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "AddMoney");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.NavigationScreenNameMessage), "Wallet");
      this.send(msg);
    }
  }

  handleOnCloseKyc = () => {
    this.setState({ isKycModal: false });
  }

  handleOnCloseInfoPopup = () => {
    this.setState({ isPaymentPopup: false });
  }

  navigateToBookingSuccessfull = (response:any) => {
     const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
      navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "BookingSuccessfully");
      navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.bookingparams), {
        data: response,
      });
      navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(navigationMessage);
  }

    // to get the total balance in the wallet
    getWalletBalace = async () => {
      this.setState({ loading: true })
      this.walletBalaceApi = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: configJSON.walletBalance,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    checkForPaymentStatus = async (orderId: string) => {

      this.setState({ isBackDropOpen: true });
      
      const userToken = await getStorageData(LocalStorageKeys.LoginToken);
      const { id }: IBookingData = await getStorageData(LocalStorageKeys.BookingData, true)

      this.updatePaymentAPICallId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PutMethod,
        endPoint: `${this.state.isFromEvent ? configJSON.updateEventPaymentEndPoint : configJSON.paymentEndPoint}/${id}/update_payment`,
        headers: { "token": userToken },
        body: {
          payment_order_id: orderId,
          payment_method_type: 'other'
        }
      }, runEngine)
    }

    handleUpdatePayment = (message: string) => {
      if(message === "payment failed or pending") {
        this.setState({ isBackDropOpen: false, isFailedTransactionOpen: true })
      } else {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "BookingSuccessfully");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    }

    handleCloseFailedTransaction = () => this.setState({ isFailedTransactionOpen: false })

  async componentDidMount() {
    let isEvent = getLastPartOfURL();
    const bookingDetails = (await getStorageData(LocalStorageKeys.BookingData, true)) as IBookingData
    const UserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
    UserDetails?.attributes?.kyc_status === "approved" && this.getWalletBalace();

    this.setState({
      hideOtherPayment: bookingDetails?.hideOtherPayment,
      isFromEvent: isEvent.toLocaleLowerCase() === BookingType.Event
    }, async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const orderId = searchParams.get("order_id"); 
      if(orderId) {
        await this.checkForPaymentStatus(orderId);
      }
    })
    await setStorageData(LocalStorageKeys.BookingForEvent,isEvent.toLocaleLowerCase() === BookingType.Event? "event" : "movie")
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getWalletBalace)
  }

  async componentWillUnmount() {
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getWalletBalace);
};
  // Customizable Area End
}

