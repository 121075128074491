import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    Fade,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Dialog,
      DialogTitle,
      DialogContent,
      DialogActions,
      InputAdornment,
      OutlinedInput,
      IconButton,
      Input,
    Paper,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { ArrowForwardIos, ExpandLess, ExpandMore } from "@material-ui/icons";
import {  formatPhoneNumber, getMovieDuration } from "../../../components/src/utilities";
import { OfferIcon } from "../../reservations2/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListWithToggle from "../../../components/src/ListWithToggle.web";
import moment from "moment";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { indianFlag } from "../../contactus/src/assets";
import { location } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
    h1:{
        fontSize: '24px', 
        fontWeight: 600,
        '@media(max-width:600px)': {
          fontSize:18,
        }, 
    },
    h2:{
      fontSize: '22px',
      fontWeight: 600,
      '@media(max-width:600px)': {
        fontSize:18,
      },
      },
      h3: {
        fontSize: '18px',
        margin: 0,
        lineHeight: "1.65",
        display: "flex",
        alignItems: "center",
        '@media(max-width:600px)': {
          fontSize: "14px",
        },
      },
      h4: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#fff",
        '@media(max-width:600px)': {
          fontSize: "16px",
        },
      },
      h5: {
        fontSize: '18px',
        fontWeight: 600,
        margin: 0,
        lineHeight: "1.65",
        '@media(max-width:600px)': {
          fontSize: "14px",
        },
      },
        h6: {
          fontFamily: 'Poppins',
          fontSize: '18px',
          color: '#ffffff',
          '@media(max-width:600px)': {
              fontSize: "14px",
          }
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const BackgroundWrapper = styled(Box)({
  backgroundColor: "#252525",
  padding: "32px",
  fontFamily: "Poppins",
  '@media(max-width:600px)': {
    padding:"32px 10px"
  },
})

const ContinueButton = styled(Button)({
  width: "100%",
      height: "56px",
      margin: "32px 16px",
      borderRadius: "10px",
      backgroundColor: "#FFB43A",
      textTransform: "capitalize",
      fontWeight: 600,
      "&:hover": {
          backgroundColor: "#FFB43A"
      },
      color: "#121212",
      fontFamily: "Poppins",
      fontSize: "16px",
      '@media(max-width:600px)': {
        fontSize:14,
      },
})

// Customizable Area End

import EventPreviewBookingController, {
    Props,
} from "./EventPreviewBookingController";

class EventPreviewBooking extends EventPreviewBookingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { selectedValue } = this.props;
        const { eventBookingData } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0} >
                    <BackgroundWrapper data-test-id="preview-booking-wrapper">

                        {this.state.isLoading ? (
                            <Fade in={this.state.isLoading} unmountOnExit>
                                <Box display="flex" justifyContent="center">
                                    <CircularProgress />
                                </Box>
                            </Fade>
                        ) : (
                            <>
                                <Box display="flex" alignItems="start">

                                    <Box style={webStyle.ImageWrapper}>
                                        <img 
                                            style={webStyle.MovieImage} 
                                            src={eventBookingData.poster_url[0]}
                                            alt='Movie Poster' 
                                        />
                                    </Box>

                                    <Box>
                                        <Typography variant="h2">
                                            {eventBookingData.event.name}
                                        </Typography>
                                        <Typography variant="h3">
                                        {eventBookingData.event.above_age}+ Duration : {getMovieDuration(eventBookingData.schedule.duration_in_mintues)}
                                           </Typography>
                                            <Typography variant="h3">
                                                <span>
                                                    <ListWithToggle itemsList={eventBookingData.language.map((item: any) => item.name)} />
                                                </span>
                                            </Typography>
                                        <Typography variant="h5">
                                            {moment(eventBookingData.schedule.schedule_date).format("MMM D ddd, YYYY")}
                                            <span style={webStyle.PrimaryColor}>&nbsp;|&nbsp;</span>
                                            {moment(eventBookingData.schedule.start_time, ["HH:mm"]).format('hh:mm A')}
                                                         
                                             </Typography>
                                        <Typography variant="h3">
                                          <img style={webStyle.locationIcon} src={location}/>
                                          {this.state.address}
                                          </Typography>
                                    </Box>

                                </Box>

                                <Divider style={webStyle.Divider} />

                                <List style={webStyle.PaymentWrapper}>

                                  {this.state.isITO === "true" && <>
                                    <ListItem style={webStyle.PaymentListItem}>
                                        <ListItemText style={webStyle.MenuListText}>ITO Closes in</ListItemText>
                                        <span>{this.state.itoClosedIn} Days</span>
                                    </ListItem>
                                    <ListItem style={webStyle.PaymentListItem}>
                                        <ListItemText style={webStyle.MenuListText}>ITO Price per Ticket</ListItemText>
                                        <span><span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                        {(+eventBookingData.ito_price_per_seat).toFixed(2)}
                                        </span>
                                    </ListItem>
                                    </>
                                    }

                                    <ListItem style={webStyle.PaymentListItem}>
                                        <ListItemText style={webStyle.MenuListText}>Sub - Total</ListItemText>
                                        <span><span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                        {(+eventBookingData.sub_total).toFixed(2)}
                                        </span>
                                    </ListItem>

                                    <ListItem style={webStyle.PaymentListItem}>
                                        <ListItemText style={webStyle.MenuListText}>
                                          <Box display="flex">
                                            Convenience Fees
                                            <ListItemIcon style={webStyle.MenuExpandIcon} data-test-id="cost-dropdown"
                                            onClick={() => this.showCostData()}
                                            >
                                                {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemIcon>
                                            </Box>
                                        </ListItemText>
                                        <span>
                                            <span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {(+eventBookingData.convenience_fees).toFixed(2)}
                                        </span>
                                    </ListItem>

                                    <Collapse data-test-id="cost-collapse" in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>
                                        <List style={webStyle.SubList} component="div" disablePadding>
                                            <ListItem style={webStyle.paddingZero}>
                                                <ListItemText style={webStyle.MenuListText}>Base Amount</ListItemText>
                                                <span>
                                                    <span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                    {(+eventBookingData.base_amount).toFixed(2)}
                                                </span>
                                            </ListItem>
                                           
                <ListItem style={webStyle.paddingZero}>
                  <ListItemText style={webStyle.MenuListText}>
                    {`CGST @ ${eventBookingData.cgst_percent}%`}
                  </ListItemText>
                  <span>
                    <span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                    {(+eventBookingData.cgst).toFixed(2)}
                  </span>
                </ListItem>
                <ListItem style={webStyle.paddingZero}>
                  <ListItemText style={webStyle.MenuListText}>
                    {`SGST @ ${eventBookingData.sgst_percent}%`}
                  </ListItemText>
                  <span>
                    <span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                    {(+eventBookingData.sgst).toFixed(2)}
                  </span>
                </ListItem>
                                        </List>
                                    </Collapse>

                                    <ListItem style={webStyle.PaymentListItem}>
                                        <ListItemText style={webStyle.MenuListText}>Discount</ListItemText>
                                        <span>
                                            <span style={webStyle.RupeesSymbol}>-&nbsp;{"\u20B9"}&nbsp;</span>
                                            00.00
                                        </span>
                                    </ListItem>

                                    <ListItem style={webStyle.PaymentListItem}>
                                        <ListItemText style={webStyle.MenuListText}>Total Amount</ListItemText>
                                        <span>
                                            <span style={webStyle.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {(+eventBookingData.total_amount).toFixed(2)}
                                        </span>
                                    </ListItem>
                                </List>

                      {
                        this.state.isITO !== "true" && (
                          <>
                            <Divider style={webStyle.Divider} />
                            <Box display="flex" alignItems="center">
                              <Box style={webStyle.IconWrapper}>
                                <img style={webStyle.IconImage} src={OfferIcon} alt="Offer Icon" />
                              </Box>
                              <Box display="flex" alignItems="center" justifyContent="space-between" style={webStyle.FullWidth}>
                                <Box style={webStyle.OfferAction}>Apply Coupons</Box>
                                <Box><ArrowForwardIos style={webStyle.ArrowAction} /></Box>
                              </Box>
                            </Box>
                          </>
                        )
                      }


                                    <Divider style={webStyle.Divider} />

                                    <Box style={webStyle.mTicketInfo}>
                                        <Typography variant="h1">M-Ticket Information</Typography>
                                        <Typography variant="h3">1. Customers can access their tickets under 'My Profile' in the app or mobile web.
                                            <br />2. It is mandatory to show the tickets in my profile section on the mobile app/web at the venue.
                                            <br />3. For entry, no physical tickets are required.</Typography>
                                    </Box>
                                    <Box>
                                    <Box pb={2} display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h4">Contact Information</Typography>
                                        <span data-test-id="edit-contact-info"
                                             onClick={()=>{this.handleContactInfo()}} 
                                            style={webStyle.EditButtonAction}>Edit</span>
                                    </Box>
                                    <Box style={webStyle.ContactDetails} display="flex" alignItems="center" justifyContent="space-between">
                                        <span>Email ID :</span>
                                        <span>
                                        {eventBookingData.account_email}
                                        </span>
                                    </Box>
                                    <Box style={webStyle.ContactDetails} display="flex" alignItems="center" justifyContent="space-between">
                                        <span>Mobile Number :</span>
                                        <span>{formatPhoneNumber(eventBookingData.phone)}</span>
                                    </Box>
                                </Box>

                                <Button fullWidth style={webStyle.ProceedButton} data-test-id="pay-btn"
                                    disabled={selectedValue == ""}
                                  onClick={() => this.handleOnClickPay()}
                                >Proceed to Pay</Button>
                            </>

                        )}
                <Dialog
                  id="language"
                  open={this.state.isContactInfo}
                  PaperProps={{
                    style: {
                      backgroundColor: "#404040",
                      color: "#fff",
                      padding: '12px',
                      margin:"0px 5px"
                    }
                  }}
                  keepMounted
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  data-test-id="contact_dialog"
                >

                  <DialogTitle
                    id="alert-dialog-slide-title"
                  >
                    {"Contact Information"}
                  </DialogTitle>
                  <DialogContent style={{ overflowY: "hidden" }} data-test-id="seat_number">

                    <Box style={webStyle.marginToTop}>
                      <Typography
                        gutterBottom id="modal-modal-title"
                        variant="h6"
                      >
                        Mobile Number
                      </Typography>
                      <div>
                        <OutlinedInput
                          fullWidth
                          type="tel"
                          placeholder="Enter mobile number"
                          style={webStyle.textInput}
                          inputProps={{
                            style: { fontSize: '16px' },
                            maxLength: 10
                          }}
                          data-test-id="phone"
                          value={this.state.phoneNumber}
                          name="phoneNumber"
                          onChange={(event) => this.onChangeNumber(event)}
                          startAdornment={
                            <InputAdornment position="start">
                              <IconButton
                                style={webStyle.flagIcon}
                                id='countrycode'
                              >
                                <img
                                  style={{ height: '14px', marginRight: "5px" }}
                                  src={indianFlag} alt="indian"
                                />
                                <span
                                  style={{
                                    color: "#ffffff",
                                    fontSize: "14px",
                                  }}
                                >
                                  +91
                                </span>
                                <KeyboardArrowDownIcon style={{ color: '#ffb43a' }} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                      {this.state.mobileError &&
                        <Typography variant="h6" style={{...webStyle.errorMsg, marginTop:-15}} gutterBottom id="modal-modal-title">
                          {this.state.mobileError}
                        </Typography>
                      }
                    </Box>

                    <Box style={webStyle.marginToTop} >
                      <Typography variant="h6" gutterBottom id="modal-modal-title">
                        Email Id
                      </Typography>
                      <Input
                        value={this.state.email}
                        style={webStyle.inputStyle}
                        data-test-id={"txtEmail"}
                        placeholder="Enter email id"
                        fullWidth={true}
                        disableUnderline={true}
                        onChange={(e) => this.onChangeEmail(e)}
                      />
                      {this.state.emailError &&
                        <Typography variant="h6" style={webStyle.errorMsg} gutterBottom id="modal-modal-title">
                          {this.state.emailError}
                        </Typography>
                      }
                    </Box>
                  </DialogContent>
                  <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                    <ContinueButton style={{ opacity: this.state.email === "" || this.state.phoneNumber === "" ? 0.5 :1}}
                      id="continue"
                      variant="outlined" color="primary"
                      data-test-id="continue"
                      disabled = {this.state.email === "" || this.state.phoneNumber === ""}
                      onClick={() => { this.onContinueContactInfo() }}>
                      {"Continue"}
                    </ContinueButton>
                  </DialogActions>
                </Dialog>

                    </BackgroundWrapper>
                </Paper>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    WrapperBoxs: {
        minHeight: '100vh',
    },
}

const webStyle = {
    RupeesSymbol: {
      fontFamily: 'Roboto-Medium, sans-serif'
    },
    paddingZero:{
      padding:0
    },
    continueBtn:{
      width: "100%",
      height: "56px",
      margin: "32px 16px",
      borderRadius: "10px",
      backgroundColor: "#FFB43A",
      textTransform: "capitalize",
      fontWeight: 600,
      "&:hover": {
          backgroundColor: "#FFB43A"
      },
      color: "#121212",
      fontFamily: "Poppins",
      fontSize: "16px"
    } as CSSProperties,
    ImageWrapper: {
      width: "130px",
      height: "174px",
      border: "solid 1px #ffb53a",
      marginRight: "18px",
    },
    MovieImage: {
      width: '130px',
      height: '100%',
    },
    locationIcon:{
      width:"18px", 
      height:"18px", 
      marginRight:8
    },
    Divider: {
      margin: "24px 0",
      backgroundColor: "#979797",
    },
    ScreenName: {
      fontSize: "18px",
      fontWeight: 500,
    },
    ScreenInfo: {
      color: "#b4b4b4",
      fontSize: "16px",
    },
    SeatDisplay: {
      gap: "8px",
      maxWidth: "192px",
      flexFlow: "wrap",
    },
    PaymentWrapper: {
      padding: 0,
    },
    PaymentListItem: {
      padding: "4px 0",
      maxHeight: "36px",
      "& span": {
        fontSize: "18px",
        lineHeight: 1,
      },
      "&:last-child": {
        paddingBottom: 0,
      },
      "&:first-child": {
        paddingTop: 0,
      },
    },
    MenuListText: {
      display: "inline-flex",
      alignItems: "center",
      "& span": {
        display: 'flex',
        gap: "8px",
        fontWeight: 500,
        alignItems: 'center',
      },
    },
    MenuExpandIcon: {
      color: '#ffb43a',
      cursor: "pointer",
    },
    RemoveButton: {
      color: "#b4b4b4",
      marginTop: "-3px",
      fontSize: "14px",
      marginBottom: "5px",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        fontSize: "16px",
        paddingRight: "4px",
      },
    },
    EditButtonAction: {
      fontSize: "16px",
      color: "#ffb53a",
      cursor: "pointer",
    },
    ProceedButton: {
      fontWeight: 700,
      backgroundColor: "#ffb43a",
      height: "56px",
      color: "#000",
      borderRadius: "10px",
      border: `1px solid #ffb43a`,
      textTransform:"none" as const,
      "&.MuiButton-root": {
        textTransform: 'none',
        fontSize: "1.125rem",
      },
      "&:hover": {
        color: "#fff",
        backgroundColor: "#2f2617",
      },
    },
    ContactDetails: {
      paddingBottom: "14px",
      "&:last-child": {
        paddingBottom: "32px",
      },
      "& span": {
        fontSize: "18px",
      },
    },
    RemoveIconWrapper: {
      minWidth: 'auto',
      marginRight: '4px',
      color: "#b4b4b4",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
    SubList: {
      "& .MuiListItem-gutters": {
        padding: 0,
      },
    }as CSSProperties,
    IconWrapper: {
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      marginRight: "12px",
    },
    IconImage: {
      width: "22px",
      height: "22px",
    },
    TicketIcon: {
      width: "24px",
      height: "22px",
    },
    OfferAction: {
      color: "#ffb43a",
      fontWeight: 600,
      fontSize: "18px",
    },
    ArrowAction: {
      color: "#ffb43a",
    },
    FullWidth: {
      width: "100%",
    },
    PrimaryColor: {
      color: "#ffb43a",
      fontWeight: 600,
    },
    textInput: {
      border: "solid 1px #b4b4b4",
      borderRadius: "9px",
      color: "#ffffff",
      fontSize: "16px",
      height: "56px",
      marginBottom: 16,
      backgroundColor: "#404040",
    },
    inputStyle: {
      color: "#ffffff",
      border: "1px solid #b4b4b4",
      padding: "16px",
      borderRadius: "12px",
      height: 56,
      backgroundColor: "#404040",
    },
    marginToTop: {
      marginTop: 10
    },
    errorMsg:{
      color:"#ff3b30", 
    },
    flagIcon: {
      borderRight: "1px solid #979797",
      width: "82px",
      height: "36px",
      borderRadius: "0%",
      justifyContent: "center",
      cursor: "pointer",
      fontSize: '10px',
    },
  mTicketInfo:{
    background:"#2f2617",
    width:"100%",
    padding:"16px",
    boxSizing:"border-box" as const,
    margin:"30px 0",
    borderRadius:8
  }
  };

export default (withStyles(styles)(EventPreviewBooking))
export { EventPreviewBooking }
// Customizable Area End
