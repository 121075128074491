import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { IUserDetails } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean,
  accountHolderName:string,
  accountNumber:string,
  confirmAccountNumber:string,
  ifscCode:string,
  accountHolderNameErr:string,
  accountNumberErr:string,
  confirmAccountNumberErr:string,
  ifscCodeErr:string,
  forValidateOnly:boolean,
  ifscPopupInfo:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountDetailsController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
      validateBankDetails = ""
      createBeneficiaryApi = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading:false,
            accountHolderName:"",
            accountNumber:"",
            confirmAccountNumber:"",
            ifscCode:"",
            accountHolderNameErr:"",
            accountNumberErr:"",
            confirmAccountNumberErr:"",
            ifscCodeErr:"",
            forValidateOnly:false,
            ifscPopupInfo:{ifscPopup:false},
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            
            if(responseJson) {
              switch (apiRequestCallId) {
                case this.validateBankDetails:
                  this.onValidateBankAccount(responseJson);
                  break;
                case this.createBeneficiaryApi:
                  this.navigateToBankAccountList()
              }
            }
      
          }
        // Customizable Area End
    }

    // Customizable Area Start

    handleBackBtn = () => {
      this.props.navigation.goBack();
    }

    onChangeAccountNumber = (event:any) =>{
      let accountNumberValue = event.target.value?.replace(/\D/g, '');
      this.setState({accountNumber:accountNumberValue, accountNumberErr:""})
    }

    onChangeConfirmAccountNumber = (event:any) =>{
      this.setState({confirmAccountNumber:event.target.value, confirmAccountNumberErr:""})
    }

    onChangeIfscCode = (event:any) =>{
      this.setState({ifscCode:event.target.value, ifscCodeErr:""})
    }

    onChangeAccountHolderName = (event:any) =>{
      this.setState({accountHolderName:event.target.value, accountHolderNameErr:""})
    }

    checkValidation = () =>{
      let valid = true;
      if(this.state.accountHolderName == "" ){
        this.setState({accountHolderNameErr:"Enter Account holder name"})
        valid = false;
      }

      if(this.state.accountNumber == "" ){
        this.setState({accountNumberErr:"Enter the bank account number."})
        valid = false;
      }

      if(this.state.confirmAccountNumber !== this.state.accountNumber ){
        this.setState({confirmAccountNumberErr:"Account number does not match"})
        valid = false;
      }

      if(this.state.ifscCode == "" ){
        this.setState({ifscCodeErr:"IFSC should have 4 letters, followed by 7 letters or digits."})
        valid = false;
      }

      return valid;
    }

    onClickSaveChange = async(validateOnly:boolean) =>{
      if(this.checkValidation()){
        this.setState({loading:true, forValidateOnly:validateOnly})
        this.validateBankDetails = callApi({
          contentType: configJSON.contentTypeApi,
          method: configJSON.GetMethod,
          endPoint: `${configJSON.validateBankDetailsEndPoint}?name=${this.state.accountHolderName}&bankAccount=${this.state.accountNumber}&ifsc=${this.state.ifscCode}`,
          headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
        }, runEngine)
      }
    }

    onValidateBankAccount = (responseJson:any) =>{
      if(responseJson.status === "SUCCESS"){
        this.state.forValidateOnly ? this.setState({ifscPopupInfo: {ifscPopup:true, ...responseJson.data}}) : this.createBeneficiary(responseJson.data);
      }else{
        let message = responseJson.message;
        if(message.includes("IFSC")){
          this.setState({ifscCodeErr:message})
        }else{
          this.setState({accountNumberErr:message})
        }
        this.setState({loading:false})
      }
    }

    createBeneficiary = async(data:any) =>{

      const { attributes: { email, full_phone_number }}: IUserDetails = 
      await getStorageData(LocalStorageKeys.UserDetails, true)
      const body = {
        beneId: data.nameAtBank.slice(0,3) + new Date().getTime(), //create random ben_id uniq
        name: data.nameAtBank,
        email: email,
        phone: full_phone_number.split("").splice(2).join(""),
        bankAccount: this.state.accountNumber,
        address1:data.branch,
        ifsc: this.state.ifscCode,
        city: data.city,
        state: "",
        pincode:""
      }
      this.createBeneficiaryApi = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.createBeneficiaryEndPoint,
        body: body,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    navigateToBankAccountList = () =>{
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage),"BankAccountsList");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg)
    }
    // Customizable Area End
}
