import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  shareDetails: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  shareUrl: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: false,
      shareUrl: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleShare = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  handleClose = () => {
    this.setState({ isOpen: false })
  }

  async componentDidMount() {
    await super.componentDidMount();
    let url: string = ''
    if (typeof window !== "undefined") {
      url = window.location.origin + window.location.pathname
    }
    this.setState({ shareUrl: url })
  }
  // Customizable Area End
}
