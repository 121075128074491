import React from 'react'
import { IEventBookingFullResponse } from './interfaces.web'
import { Box, Button, Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import moment from 'moment'
import { convertToPad, getMovieDuration } from './utilities'
import { InvalidStatusImage, cancelledImage } from './assets'
import { FilterOption } from './enums.web'
import { EventSeat } from './BookingSuccessfulTicket.web'

interface IEventBookingHistoryListingProps {
  booking: IEventBookingFullResponse 
  message: string
  redirectToViewTicket: (id: number) => void
}

const useStyles = makeStyles(({
  DisplayFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  Ticket: {
    backgroundColor: "#252525",
    border: "solid 0.2px #b4b4b4",
    borderRadius: "12px",
    padding: "48px 32px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "-1px",
      left: "50%",
      width: "40px",
      transform: "translate(-50%, 0)" as const,
      height: "20px",
      backgroundColor: "#121212",
      border: "solid 0.2px #b4b4b4",
      borderRadius: "0 0 30px 30px",
      borderTop: 'none'
    },
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "-1px",
      left: "50%",
      width: "40px",
      transform: "translate(-50%, 0)" as const,
      height: "20px",
      backgroundColor: "#121212",
      border: "solid 0.2px #b4b4b4",
      borderRadius: "30px 30px 0 0",
      borderBottom: 'none'
    }
  },
  MaxWidth: {
    maxWidth: "375px"
  },
  PosterWrapper: {
    alignItem: "center",
    justifyContent: "center",
    border: "solid 0.7px #ffb53a",
    marginRight: "20px"
  },
  MovieTitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#fff",
    marginBottom: "8px"
  },
  FormatAndLanguage: {
    textTransform: "uppercase"
  },
  DateTime: {
    color: "#fff",
    fontWeight: 500,
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "18px",
    "& span": {
      color: "#ffb53a"
    }
  },
  ScreenName: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#fff",
    fontFamily: "Poppins"
  },
  ScreenDetails: {
    maxWidth: "375px",
    position: "relative",
  },
  SeatWrapper: {
    maxWidth: "210px",
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    "& > div": {
      "& div": {
        backgroundColor: "#ffb43a !important",
        minWidth: "72px"
      }
    }
  },
  TicketLabel: {
    fontSize: "18px",
    color: "#b4b4b4"
  },
  Divider: {
    margin: "24px 0 16px 0",
    backgroundColor: "#b4b4b4"
  },
  TicketDivider: {
    border: 'none',
    borderBottom: "dashed 2px #b1b1b1",
    margin: "22px 0"
  },
  TicketTotal: {
    fontSize: "18px",
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
    "& > span": {
      fontFamily: "Roboto"
    }
  },
  FW600: {
    fontWeight: 600
  },
  ScreenLabel: {
    fontSize: "16px",
    color: "#b4b4b4",
    fontFamily: "Poppins",
    textTransform: "capitalize" as const
  },
  SubmitButton: {
    backgroundColor: "#ffb43a",
    color: "#202020",
    padding: "14px 36px",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "capitalize",
    maxHeight: "56px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: "#202020",
    }
  },
  Message: {
    fontSize: "16px"
  },
  PosterImage: {
    width: "212px",
    height: "270px",
  },
  BookingStatusLabel: {
    position: 'absolute',
    top: 0,
    left: "50%",
    transform: "translate(-50%)"
  }
}))

const getBookingStatusLabelURL = (bookingStatus: string) => {

  switch (bookingStatus) {
    case FilterOption.Cancelled:
      return cancelledImage;

    case FilterOption.Invalid:
      return InvalidStatusImage;

    default:
      return null
  }

}


const EventBookingHistoryListing: React.FC<IEventBookingHistoryListingProps> = (props) => {

  const classes = useStyles()
  const { 
    booking: {
      id: ticketId,
      attributes
    },
    message,
    redirectToViewTicket
  } = props

  const bookingHistoryData = {
    image_url: attributes.poster_url[0],
    name: attributes.event.name,
    release_date: attributes.schedule.schedule_date,
    duration_in_minutes: attributes.schedule.duration_in_mintues,
    theatre_name: attributes.venue.name,
    screen_name: "",
    language: attributes.language.map((item: any, ind:number) => `${item.name} ${attributes.language.length-1 != ind ? ', ':""}`),
    show_date_time: [attributes.schedule.schedule_date, moment(attributes.schedule.start_time, ["HH:mm"]).format('hh:mm A')],
    seat_number: attributes.seats,
    booked_date: attributes.booked_date,
    number_of_seats: attributes.total_seats,
    total_amount: attributes.total_amount,
    status: attributes.status,
    is_invalid: attributes.is_invalid,
    seat_allocation_type : attributes.seat_allocation_type,
  }

  let BookingStatusBasedOnConditions = bookingHistoryData.status;

  if(bookingHistoryData.is_invalid) {
    BookingStatusBasedOnConditions = FilterOption.Invalid;
  }

  return (
    <Box className={classes.Ticket}>

      <Grid container>

        <Grid item className={classes.MaxWidth}>
          <Box className={classes.PosterWrapper}>
            <img src={bookingHistoryData.image_url} className={classes.PosterImage} />
          </Box>
        </Grid>

        <Grid item xs>
          <Grid container>

            <Grid item xs>
              <Box>
                <Typography className={classes.MovieTitle}>{bookingHistoryData.name}</Typography>
                <Typography variant="h6">{`${new Date(bookingHistoryData.release_date).getFullYear()} UA ${getMovieDuration(bookingHistoryData.duration_in_minutes)}`}</Typography>
                <Typography variant="h6" className={classes.FormatAndLanguage}>{`${bookingHistoryData.language}`}</Typography>
                <Typography variant="h6" className={classes.DateTime}>
                {moment(bookingHistoryData.show_date_time[0]).format("ddd DD MMM,  YYYY")}
                  <span>&nbsp;|&nbsp;</span>
                  {bookingHistoryData.show_date_time[1]}
                </Typography>
                <Typography variant="h6">{bookingHistoryData.theatre_name}</Typography>
              </Box>
            </Grid>

            <Grid item xs className={classes.ScreenDetails}>
              <Box className={classes.BookingStatusLabel}>
                {
                  getBookingStatusLabelURL(BookingStatusBasedOnConditions) && <img src={getBookingStatusLabelURL(BookingStatusBasedOnConditions)} width="261px" height="204px" />
                }
              </Box>

              <Box className={classes.DisplayFlex}>
              {bookingHistoryData.seat_allocation_type === "manual"
                  ? <Typography variant="h6">Seats will be assigned on a first-come, first-served basis.</Typography>
              :<><span className={classes.ScreenName}>Seat No
                </span>
                <Box className={classes.SeatWrapper}>
                   {
                    bookingHistoryData.seat_number.map((seat) => <EventSeat key={seat.id} seatLabel={seat.seat_number} />)
                   }
                </Box>
                </>}
              </Box>

              <Box className={classes.DisplayFlex} mt={2}>
                <Typography variant="h6" className={classes.FW600}>Booking Date</Typography>
                <Typography variant="h6" className={classes.FW600}>{moment(bookingHistoryData.booked_date).format("DD MMM, YYYY")}</Typography>
              </Box>

              <Divider className={classes.Divider} />

              <Box className={classes.DisplayFlex}>
                <Typography className={classes.TicketLabel}>Tickets ({convertToPad(bookingHistoryData.number_of_seats)})</Typography>
                <Typography className={classes.TicketTotal}><span>₹</span> {Number(bookingHistoryData.total_amount).toFixed(2)}</Typography>
              </Box>

            </Grid>

          </Grid>

          <Divider className={classes.TicketDivider} />

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Button data-test-id="view-ticket-button" className={classes.SubmitButton} onClick={() => redirectToViewTicket(+ticketId)}>View Ticket</Button>
              <Typography variant="h6" className={classes.Message}>{message}</Typography>
            </Box>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  )
}

export default EventBookingHistoryListing