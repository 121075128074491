import React, { CSSProperties } from "react";
import { createTheme, ThemeProvider, withStyles, Theme as AugmentedTheme, createStyles} from "@material-ui/core/styles";
import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    Input,
    Button,
    InputAdornment,
    OutlinedInput,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
   } from "@material-ui/core";
   import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import EditUserProfileController, {Props} from "./EditUserProfileController.web";
import { addpic, alert, backArrow, deleteIcon, editIcon, kycCompleted } from "./assets"
import Loader from "../../../components/src/Loader.web";
import { indianFlag } from "../../social-media-account-login/src/assets";
import { success } from "../../contactus/src/assets";

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },

    typography:{
      h2:{
          fontFamily: 'Poppins',
          fontSize: '24px',
          fontWeight: 600,
          color: '#ffffff',
          margin: "20px 0",
          '@media(max-width:700px)': {
              fontSize: "18px",
          }
      },
      h1:{
          fontFamily: 'Poppins',
          fontSize: '32px',
          color: '#ffffff',
          margin: 0,
          fontWeight:500,
          textTransform:"capitalize",
          '@media(max-width:700px)': {
              fontSize: "18px",
              marginTop:20
          }
      },
      h4:{
        fontFamily: 'Poppins',
          fontSize: '18px',
          color: '#ffffff',
          margin: 0,
          '@media(max-width:700px)': {
              fontSize: "15px",
          }
      },
      h5:{
        fontSize:"16px",
        color:"#fff",
        fontFamily: 'Poppins',
        textAlign:"center",
        margin: "24px 0px 12px 0",
        '@media(max-width:700px)': {
          margin:"10px 0px 0px 0px",
          fontSize: "14px",
      },
      }
    },
});

export const customStyles = (theme: AugmentedTheme) => createStyles({
  container:{
    width:"100%",
    maxWidth:"1280px",
    marginLeft:"auto",
    marginRight:"auto",
    padding:"0 112px",
    boxSizing:"border-box",

  '@media(max-width:1200px)': {
    padding:"0 10px",
    }
  },
  editProfileBox:{
      maxWidth:"967px",
      width:"100%",
      padding: "32px 100px 58px 32px",
      borderRadius: "8px",
      backgroundColor: "#252525",
      boxSizzing:"border-box",

  '@media(max-width:700px)': {
      width:"auto",
      padding:"32px 10px 58px",
  },
  },
  dialogPaper:{
    backgroundColor: "#404040",
    color: "#fff",
    padding: '12px 32px 48px 32px',
    maxWidth:"456px",
    '@media(max-width:700px)': {
      padding: 0,
    },
  },
  dialogContent:{
    textAlign:"center",
  },
  errorMsg:{
    color:"#ff3b30", 
    textTransform:"capitalize"
  }
})
export class EditUserProfile extends EditUserProfileController {

constructor(props: Props) {
    super(props);
}

render() {     

    const {classes} = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading}/>
        <Container maxWidth={false} style={this.state.loading ? webStyle.overFlow : webStyle.paddingLeft}>
        <Paper elevation={0} style={webStyle.mainPaperStyle}>
            {/* ------------------App Bar------------------ */}
            <MainHeaderComp navigation = {this.props.navigation} />
            <form onSubmit={this.handleSave} className={classes.container}>
            <Box style={webStyle.KycImageContainer}>
               <img
                  src={backArrow}
                  style={webStyle.backArrow}
                  alt='backArrow'
                  onClick={this.handleBackBtn}
                  data-test-id="back"
                />
                <Typography variant="h2">Edit Profile</Typography>
                </Box>
        
            <Box className={classes.editProfileBox}>
            <Grid container alignItems="center">
            <Box sx={webStyle.addIconContainer}> 
              {!this.state.showImg && <input 
                style={webStyle.addIconInput} 
                type="file" 
                accept="image/png, image/gif, image/jpeg"
                data-test-id="imageUpload"
                onChange={this.onChangeProfile}
                />}
              <img
                  src={this.state.showImg ? this.state.showImg : addpic}
                  style={webStyle.addIconStyle}
                  alt='addPic'
                  data-test-id="profileImage"
                  onClick={this.onClickProfile}
              />
                    {(this.state.showImg || this.state.profilePic) && this.state.profileClicked && <Box id="editAndDeleteImage" sx={webStyle.editAndDeleteImage}>
                      <input
                        style={webStyle.editIconInput}
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        data-test-id="imageUpload"
                        onChange={this.onChangeProfile}
                      />
                      <Box sx={webStyle.removeProfile} style={webStyle.editText}>
                        <img
                          src={editIcon}
                          style={webStyle.picModifiedIcon}
                          alt='backArrow'
                        />
                        <Typography style={webStyle.picModifiedText}>Change picture</Typography>
                      </Box>

                      <Box data-test-id="removeProfile" onClick={this.onRemoveProfile} sx={webStyle.removeProfile}>
                        <img
                          src={deleteIcon}
                          style={webStyle.picModifiedIcon}
                          alt='backArrow'
                        />
                        <Typography style={webStyle.picModifiedText}>Remove picture</Typography>
                      </Box>
                    </Box>}
            </Box>

            <Grid item >
            <Typography variant="h1">Hi, {this.state.userName ? this.state.userName : "User"}</Typography>
            {this.state.isKycVerified ? 
              <Box style={webStyle.KycImageContainer}>
               <img
                  src={kycCompleted}
                  style={webStyle.kycImage}
                  alt='addPic'
                />
              <Typography variant="h4" style={{color:"#ffb636"}}>KYC Completed</Typography> 
              </Box>:
              <Button onClick={this.getDigiToken} data-test-id="kycBtn" style={webStyle.KycImageContainer}>
               <img
                  src={alert}
                  style={webStyle.kycImage}
                  alt='addPic'
                />
              <Typography variant="h4" className={classes.errorMsg}>KYC Verification Pending</Typography>
              </Button>
              }
            
            </Grid>
        </Grid>

      <Box style={webStyle.inputUpperBox}>       
        <Box style={webStyle.marginToTop}>
                <Typography variant="h4" gutterBottom id="modal-modal-title">
                Name
                </Typography>
                <Input
                value={this.state.name}
                style={webStyle.inputStyle}
                data-test-id={"txtName"}
                placeholder="Enter Name"
                fullWidth={true}
                disableUnderline={true}
                onChange={(e) => this.onChangeName(e)}
                />
                {this.state.nameError && 
                 <Typography variant="h4" className={classes.errorMsg} gutterBottom id="modal-modal-title">
                    {this.state.nameError}
                 </Typography>
                 }
            </Box>
          
            <Box style={webStyle.marginToTop}>
                <Typography variant="h4" gutterBottom id="modal-modal-title">
                Email Id
                </Typography>
                <Input
                value={this.state.email}
                style={webStyle.inputStyle}
                data-test-id={"txtEmail"}
                placeholder="Enter email id"
                fullWidth={true}
                disableUnderline={true}
                  onChange={(e) => this.onChangeEmail(e)}
                />
                {this.state.emailError && 
                 <Typography variant="h4" className={classes.errorMsg} gutterBottom id="modal-modal-title">
                 {this.state.emailError}
                 </Typography>
                 }
            </Box>

            <Box style={webStyle.marginToTop}>
                <Typography variant="h4" gutterBottom id="modal-modal-title">
                Phone Number
                </Typography>
                <div>
                      <OutlinedInput
                        fullWidth
                        type="tel"
                        style={webStyle.textInput}
                        data-test-id="phone"
                        placeholder="Enter mobile number"
                        inputProps={{
                          style: { fontSize: '16px' },
                          maxLength: 10
                        }}
                        value={this.state.phoneNumber}
                        onChange={(event) => this.onChangeNumber(event)}
                        name="phoneNumber"                            
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              style={{
                                borderRight: "1px solid #979797",
                                width: "82px",
                                height: "36px",
                                borderRadius: "0%",
                                justifyContent: "center",
                                cursor: "pointer",
                                fontSize: '10px',
                                
                              }}
                              id='countrycode'
                            >
                              <img src={indianFlag} alt="indian" style={{ height: '14px', marginRight: "5px" }} />
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#ffffff"
                                }}
                              >
                                +91
                              </span>
                              <KeyboardArrowDownIcon style={{ color: '#ffb43a' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                 {this.state.mobileError && 
                 <Typography variant="h4" className={classes.errorMsg} gutterBottom id="modal-modal-title">
                 {this.state.mobileError}
                 </Typography>
                 }
            </Box>

            <Box style={webStyle.buttonMarginTop}>
              <Button 
                type="submit" 
                style ={webStyle.eventBtnStyle} 
                data-test-id="saveBtn"
              >
                Save Changes
              </Button>
            </Box>

            </Box>
            </Box>
            </form>

            <Dialog
                    id="language"
                    open={this.state.completedSuccsessfully}
                    PaperProps={{
                        classes:{root: classes.dialogPaper},
                        elevation: 0,
                    }}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    data-test-id="seat_dialog"

                >
                    <DialogContent className={classes.dialogContent} data-test-id="seat_number">
                    <img src={success} alt="success"
                            color="primary"
                            style={webStyle.successImg}
                        />
                        <Typography variant="h5">
                        {this.state.dialogeContent}
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                    <Button
                          data-test-id="doneBtn"
                          variant="contained"
                          color="primary"
                          style={webStyle.doneBtn}
                          onClick={() => this.state.dialogeContent.includes("KYC") ? this.setState({completedSuccsessfully: false}) : this.handleBackBtn()}                                            
                        >
                          Done
                        </Button>
                    </DialogActions>
                </Dialog>
            <FooterWithEvents />
            </Paper>
        </Container>
      </ThemeProvider>
    );
}
}

// Customizable Area Start

const webStyle = {
    mainPaperStyle: {
      minHeight: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column' as const,
      backgroundColor: "#121212",
    },
    overFlow:{
      overflow:"hidden",
      paddingLeft:0
    },
    paddingLeft:{
      paddingLeft:0
    },
    addIconStyle:{
      width:"130px",
      height:"130px",
      marginRight:20,
      borderRadius:"50%",
      cursor:"pointer"
    },
    inputUpperBox:{
      maxWidth:"835px",
      paddingTop:20,
      width:"100%",
      marginTop:15
    },
    inputStyle:{
      color: "#ffffff",
      border: "1px solid #b4b4b4",
      padding: "16px",
      borderRadius: "12px",
      height:56,
      backgroundColor:"#404040",
     },
    marginToTop:{
      marginTop:20
    },
    buttonMarginTop:{
      marginTop:50,
      display:"flex",
      justifyContent:"flex-end"
    },
    eventBtnStyle:{
      width: '254px',
      height: '56px',
      backgroundColor: '#ffb43a',
      borderRadius: '12px',
      fontFamily: 'Poppins',
      fontSize: '18px',
      color: '#202020',
      fontWeight: 600,
      padding:"17px 16px",
      textTransform: "none"
    }as CSSProperties,
    KycImageContainer:{
      display:"flex",
      alignItems:"center",
      marginTop:10,
      padding:0,
    },
    backArrow:{
      heigth:"32px",
      width:"32px",
      marginRight:10,
      cursor:"pointer"
    },
    kycImage:{
      heigth:"26px",
      width:"26px",
      marginRight:10
    },
    addIconContainer:{
      position:"relative"
    },
    textInput: {
      border: "solid 1px #b4b4b4",
      borderRadius: "9px",
      color: "#ffffff",
      fontSize: "16px",
      height: "56px",
      marginBottom: 16,
      backgroundColor:"#404040",
    },
    addIconInput:{
      width:"130px",
      height:"130px",
      opacity:0,
      cursor:"pointer",
      position:"absolute"
    } as CSSProperties,
    errorMsg:{
      color:"#ff3b30", 
      textTransform:"capitalize"
    },
    successImg:{
      height: '48px', 
      width: '48px'
    },
    doneBtn:{
      padding: '12px 80px',
      width:"100%",
      backgroundColor: '#ffb43a',
      borderRadius: 12,
      textTransform: 'capitalize' as const,
      color: '#202020',
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: 600,
      "&:hover": {
        backgroundColor: '#ffb43a',
      }
    },
    editAndDeleteImage:{
      width:191,
      borderRadius:8,
      border:"1px solid #929292",
      position: "absolute",
      top: 130,
      left: 80,
      zIndex: 1,
      backgroundColor:"#404040"
    },
    removeProfile:{
      width:"100%",
      height:42,
      cursor:"pointer",
      display:"flex",
      alignItems:"center",
      padding: "0 15px",
      boxSizing: "border-box"
    },
    editText:{
      backgroundColor:"#5a5a5a",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    editIconInput:{
      width:"100%",
      height:42,
      backgroung:"#5a5a5a",
      cursor:"pointer",
      opacity:0,
      position:"absolute"
    }as CSSProperties,
    picModifiedIcon:{
      width:24,
      height:24,
      marginRight:8
    },
    picModifiedText:{
      fontSize:16,
      color:"#fff",
      fontFamily: "Poppins",
    }
}

export default withStyles(customStyles, { withTheme: true })(EditUserProfile);
// Customizable Area End