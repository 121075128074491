import React, { useState, useEffect } from "react";
import { makeStyles, Theme, Box, Typography, Chip, IconButton, Avatar } from "@material-ui/core";
import Slider, { Settings } from "react-slick";
import moment from "moment";
import { starFilled, starOutline, thumbsDown, thumbsDownFilled, thumbsUp, thumbsUpFilled } from "./assets";

interface Tag {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      review_count: number;
  };
}

interface ReviewAttributes {
  description: string | null;
  account_id: number;
  movie_id: number;
  star: number;
  like_count: number;
  dislike_count: number;
  tags: {
      data: Tag[];
  };
  user_name: string;
  user_profile: string | null;
  review_date: string;
  is_liked: boolean;
  is_disliked: boolean;
}

interface Review {
  id: string;
  type: string;
  attributes: ReviewAttributes;
}

interface ReviewCarouselProps {
  cardList: Review[]  
  handleLike: (id: number) => Promise<void>
  handleDislike: (id: number) => Promise<void>
}

interface FilteredTag {
  id: string
  label: string
  count: number
}

const useStyles = makeStyles((theme: Theme) => ({
  SliderWrapper: {
    "& .slick-list": {
      "& .slick-track": {
        marginLeft: 0,
        "& .slick-slide": {
          margin: "0 12px"
        },
        [theme.breakpoints.up('md')]: {
          "& .slick-slide:first-child": {
            marginLeft: "0"
          },
        }
      },
    },
    "& .slick-next": {
      right: "19px",
      top: "50%",
      "&::before": {
        fontSize: "3.25rem",
        color: "#ffb43a",
        opacity: "1",
        [theme.breakpoints.down('md')]: {
          fontSize: "2rem"
        }
      },
      [theme.breakpoints.down('md')]: {
        right: "5px"
      }
    },
    "& .slick-prev": {
      top: "50%",
      left: "-24px",
      zIndex: 2,
      "&::before": {
        fontSize: "3.25rem",
        color: "#ffb43a",
        opacity: "1",
        display: "block",
        [theme.breakpoints.down('md')]: {
          fontSize: "2rem"
        }
      },
      [theme.breakpoints.down('md')]: {
        left: "-5px"
      }
    },
    "& .slick-next.slick-disabled": {
      "&::before": {
        display: "none"
      },
    },
    "& .slick-prev.slick-disabled": {
      "&::before": {
        display: "none"
      },
    },
  },
  CardWrapper: { 
    color: "#fff",   
    backgroundColor: "#404040",
    borderRadius: "8px",
  },
  ImageWrapper: {    
    borderRadius: "50%",
    width: "52px",
    height: "52px",
    overflow: "hidden",
  },
  PersonImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  ReviewerName: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins"  ,
    fontWeight: 500,    
  },
  Description: {
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 7,
    height: "165px",
    fontWeight: 300,
    "-webkit-box-orient": "vertical",
    margin: "16px 16px 24px",
  },
  Star: {
    width: "24px",
    height: "24px",
  },
  ThumbIcon: {
    height: "24px",
    width: "24px",
  },
  ReviewDate: {
    marginLeft: "4px",
    fontSize: "16px",
    color: "#b4b4b4",
    fontWeight: 300,
    fontFamily: "Poppins"
  },
  LikeCount: {
    fontSize: "12px",
    color: "#b4b4b4",
    fontFamily: "Poppins",
    width: "25px",
    display: "flex",
    alignItems: "center"
  },
  Tag: {
    color: '#000',
    backgroundColor: "#ffb43a",
    fontSize: "14px",
    border: "solid 1px #ffb43a",
    borderRadius: "12px",
    height: "36px",
    "& .MuiChip-label": {
      fontFamily: "Poppins",
      "& span": {
        fontSize: "12px",
        color: "#000",
        fontFamily: "Poppins",
        paddingLeft: "10px"
      }
    },
    "&:hover": {
      color: '#000',
      backgroundColor: "#ffb43a",
      border: "solid 1px #ffb43a"
    },
    "&:focus": {
      color: '#000',
      backgroundColor: "#ffb43a",
      border: "solid 1px #ffb43a"
    },
    "&.MuiChip-outlined": {
      color: '#ffb43a',
      borderColor: "#b4b4b4",
      backgroundColor: "transparent",
      "& .MuiChip-label": {
        "& span": {
          color: "#b4b4b4",
        }
      },
    },
  },
  TagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: "10px",
    gap: "20px",
    '&::-webkit-scrollbar': {
      display: 'none', // Hide scrollbar in Webkit browsers
    },
    '-ms-overflow-style': 'none', // Hide scrollbar in IE
    scrollbarWidth: 'none',
  }
}));

const sliderSettings: Settings = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 2,
  draggable: true,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 968,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function countReviewDays(date: Date | string): string {
  const duration = moment.duration(moment().diff(moment(date)))

  // Get the values in different units
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();
  const weeks = duration.asWeeks();

  // Determine the appropriate representation
  let result;
  if (weeks >= 1) {
    result = weeks > 52 ? '1y' : `${Math.floor(weeks)}w`;
  } else if (days >= 1) {
    result = days > 6 ? '1w' : `${Math.floor(days)}d`;
  } else if (hours >= 1) {
    result = `${Math.floor(hours)}h`;
  } else {
    result = `${Math.floor(minutes)}m`;
  }
  return result;
}



const ReviewCarousel: React.FC<ReviewCarouselProps> = (props) => {

  const classes = useStyles(props);
  const { cardList, handleLike, handleDislike } = props

  const [selectedTag, setSelectedTag] = useState<number>(-1)
  const [filteredList, setFilteredList] = useState<Review[]>(cardList)

  const generateTagList = (cardList: Review[]): FilteredTag[]  => {
    const tagList: Record<string, FilteredTag> = {}

    cardList.forEach((review) => {

       const { attributes } = review;
       const { tags } = attributes;

       tags.data.forEach((tag) => {

        const { id: tagId, attributes: tagAttributes } = tag;
        
        if(!tagList[tagId]) {
          tagList[tagId] = {
            id: tagId,
            label: tagAttributes.name,
            count: 1,
          }
        } 
        else {
          tagList[tagId].count +=1;
        }

       })

    })

    return Object.values(tagList).sort((a,b) => b.count - a.count );
  }

  const handleSelectTag = (tagId: number) => {
    if (tagId === selectedTag) {
      setFilteredList(cardList);
      setSelectedTag(-1);
      return;
    }
    handleFilter(tagId);
  }

  const handleFilter = (tagId: number) => {
    setSelectedTag(tagId);

    const filteredList = cardList.filter((review) => {
      const { attributes: { tags: { data }}} = review;
      return data.some((tag) => +tag.id === tagId);
    });

    setFilteredList(filteredList);
  }

  useEffect(() => {
    if(selectedTag === -1) setFilteredList(cardList);
    else handleFilter(selectedTag);
  }, [cardList]);

  return (
    <>
      <Slider {...sliderSettings} className={classes.SliderWrapper}>

        {
          filteredList.map((review: Review) => (
            <Box key={review.id} className={classes.CardWrapper} data-test-id="review">

              <Box m={2} display="flex" justifyContent="flex-start">
                <Box mr={2} className={classes.ImageWrapper}>
                  <Avatar className={classes.PersonImage} src={review.attributes.user_profile as string} alt="Pic" />
                </Box>
                <Box>
                  <Typography className={classes.ReviewerName}>{review.attributes.user_name}</Typography>
                </Box>
              </Box>

              <Box m={2} display="flex" justifyContent="flex-start" gridGap="4px">
                {
                  [...Array(5)].map((_, index) => {
                    if (index < review.attributes.star) return <img src={starFilled} className={classes.Star} />
                    else return <img src={starOutline} className={classes.Star} />
                  })
                }
                <Typography className={classes.ReviewDate}>{countReviewDays(review.attributes.review_date)}</Typography>
              </Box>

              <Typography className={classes.Description}>
                {review.attributes.description}
              </Typography>

              <Box m={2} display="flex" justifyContent="flex-start">
                <IconButton data-test-id="like-btn" onClick={() => handleLike(+review.id)} disabled={review.attributes.is_liked}>
                  <img src={review.attributes.is_liked ? thumbsUpFilled : thumbsUp} className={classes.ThumbIcon} />
                </IconButton>
                <span data-test-id="like-count" className={classes.LikeCount}>{review.attributes.like_count}</span>
                <IconButton data-test-id="dislike-btn" onClick={() => handleDislike(+review.id)} disabled={review.attributes.is_disliked}>
                  <img src={review.attributes.is_disliked ? thumbsDownFilled : thumbsDown} className={classes.ThumbIcon} />
                </IconButton>
                <span data-test-id="dislike-count" className={classes.LikeCount}>{review.attributes.dislike_count}</span>
              </Box>

            </Box>
          ))
        }

      </Slider>

      <Box mt={3} className={classes.TagWrapper}>
        {
          generateTagList(cardList).map((tag) => (
            <Chip 
              key={tag.id}
              variant={selectedTag == +tag.id ? "default" : "outlined"} 
              className={classes.Tag}
              label={<>{`#${tag.label}`}<span>{tag.count}</span></>} 
              clickable
              onClick={() => handleSelectTag(+tag.id)}             
            />
          ))
        }
      </Box>

    </>
  );
};

export default ReviewCarousel;
