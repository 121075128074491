Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.RequestTitle = "Requests";
exports.Filter = "Filter";
exports.NoDataFound = "No data found";
exports.RequestID = "Request ID : ";
exports.RequestDate = "Request Date : ";
exports.TicketsRequested = "Tickets Requested : ";
exports.TotalAmount = "Total Amount";
exports.RequestTimeout = "Request Timed Out";
exports.RequestDeclined = "Request Declined";
exports.RequestPending = "Request Pending";
exports.RequestApproved = "Request Approved";
exports.ScreenLabel = "Screen";
exports.PendingLabel = "Pending";
exports.DeclinedLabel = "Declined";
exports.TimeoutLabel = "Timed out";
exports.ApprovedLabel = "Approved";
exports.ViewRequest = "View Request";
exports.ConfirmPayment = "Confirm Payment";
exports.RequestApprovedTitle = "Request Approved";
exports.NumberOfTickets = "Number of Tickets";
exports.RequestPricePerTicket = "Request Price Per Ticket";
exports.SubTotal = "Sub - Total";
exports.ConvenienceFees = "Convenience Fees";
exports.BaseAmount = "Base amount";
exports.CGST = "CGST @ ";
exports.SGST = "SGST @ ";
exports.IGST = "IGST @ ";
exports.TotalPayableAmount = "Total Amount Payable";
exports.ConfirmPayment = "Confirm Payment";
exports.Proceed = "Proceed";
exports.ConfirmPaymentMessage = "{AMOUNT} will be deducted from your Wallet.";
exports.PaymentSuccessful = "Payment Successful!";
exports.Done = "Done";
exports.WalletLow = "Wallet Balance is Low!";
exports.WalletLowMessage = "An amount of {AMOUNT} needs to be added to your wallet to complete this transaction.";
exports.UpdateWalletBalance = "Update Wallet Balance";
exports.ScreenMessage = "All eyes this way please!";
exports.LiveRequests = "Live Requests";
exports.ViewRequests = "View Requests";
exports.LiveRequest = "Live Request";
exports.RequestedSeats = "Requested Seats";
exports.RequestExpires = "Request Expires in ";
exports.PricePerTicket = "Price per Seat:";
exports.NoOfSeats = "No of Seats:";
exports.TotalAmountNew = "Total Amount:";
exports.RejectBtn = "Reject";
exports.AcceptBtn = "Accept";
exports.RequestApproveMsg = "Request Approved Successfully!";
exports.RequestRejectMsg = "Request has been Rejected!";
exports.RequestApproveDetailedMsg = "You will receive a Notification once the Payment has been Confirmed.";
exports.Selected = "Selected";
exports.Available = "Available";
exports.Request = "Request";
exports.Unavailable = "Unavailable";
exports.Buy = "Buy";



exports.APIContentType = "application/json";
exports.GetAPIMethod = "GET";
exports.PutAPIMethod = "PUT";
exports.GetRequestedBookingsApiEndPoint = "bx_block_categories/booking_requests";
exports.GetLiveRequestedBookingsApiEndPoint = "bx_block_categories/booking_requests/live_requests";
exports.GetTicketDetailsApiEndPoint = "bx_block_categories/booking_requests/{ID}";
exports.GetWalletBalanceApiEndPoint = "bx_block_scheduling/wallet_transactions/wallet_ballance";
exports.UpdateBookingApiEndPoint = "bx_block_categories/booking_requests/{ID}";
exports.UpdatePaymentStatusApiEndPoint = "bx_block_categories/bookings/{ID}/update_payment";
exports.MovieTimeSlotsApiEndPoint = "bx_block_categories/screenings";
exports.GetSeatSelectionByScreenIdApiEndPoint ="bx_block_categories/screenings/{ID}"
exports.GetLiveRequestsTable = "bx_block_categories/booking_requests/live_request_table";
exports.GetLiveRequestsListing = "bx_block_categories/booking_requests/live_requests";
exports.UpdateRequestApiEndPoint = "bx_block_categories/booking_requests/{ID}";


exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"
exports.PageLabel = "Live Request List";

// Customizable Area End