// Customizable Area Start

import { Box, Grid, Typography, Button, makeStyles, Divider } from '@material-ui/core'
import React from 'react'
import { Addons, MenuItem } from '../../blocks/reservations2/src/FoodAndBeveragesController.web'
import { MenuFilterList } from './enums.web'
import { nonVegIcon, vegIcon } from './assets'

interface IMenuListProps {
  list: MenuItem[]
  onClickItem: (menuItemId: number, quantity: number, booking_menu_id?: number) => void
}

const useStyles = makeStyles((theme) => ({
  ImageWrapper: {
    width: '106px',
    height: '106px',
    borderRadius: '6px',
    border: '1px solid #404040',
    overflow: 'hidden',
    marginRight: '16px', 
    position: "relative"
  },
  Image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  MenuBoxWrapper: {
    padding: "22px 0 16px 0",
  },
  MenuTitle: {
    fontSize: '22px',
    fontWeight: 500,
    marginBottom: '4px',
    letterSpacing: '0.11px',
  },
  MenuAddOns: {
    color: '#b4b4b4',
    letterSpacing: '0.08px',
  },
  MenuPrice: {
    fontWeight: 500,
    marginTop: "12px",
    fontSize: "16px",
    fontFamily: "Poppins"
  },
  AddBtn: {
    width: "122px",
    padding: "8px 44px",
    backgroundColor: "#2f2617",
    border: "solid 1px #ffb43a",
    "&.MuiButton-root": {
      textTransform: 'none', 
      minWidth: 'auto',
    },
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: '#000',
    },
  },
  ButtonWrapper: {
    minWidth: "122px",
  },
  ActionBtn: {
    fontWeight: 600,
    backgroundColor: "#2f2617",
    border: "solid 1px #ffb43a",
    height: "32px",
    width: "32px",
    "&.MuiButton-root": {
      minWidth: 'auto',
    },
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: '#000',
    },  
  },
  Quantity: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins"
  },
  Divider: {
    backgroundColor: "#404040",
  },
  RupeesSymbol: {
    fontFamily: 'Roboto-Medium, sans-serif'
  },
  foodTypeIcon: {
    position: "absolute",
    top: "4px",
    left: "4px",
    width: "16px",
    height: "16px"
  },
  "@media (max-width: 600px)": {
    ImageWrapper: {
      width: '84px',
      minWidth: "84px",
      height: '84px',
      marginRight: '12px', 
    },
    MenuBoxWrapper: {
      padding: "16px 0",
    },
    MenuTitle: {
      fontSize: '16px',
    },
    AddBtn: {
      width: "100px",
      padding: "8px 20px",
      fontSize: "14px"
    },
    Quantity: {
      fontSize: "16px"
    },
    ButtonWrapper: {
      minWidth: "100px",
    },
    MenuPrice: {
      fontSize: "14px",
      marginTop: "4px"
    },
    foodTypeIcon: {
      position: "absolute",
      top: "4px",
      left: "4px",
      width: "12px",
      height: "12px"
    },
  }
}))

const MenuListItem: React.FC<IMenuListProps> = ({ list, onClickItem }) => {

  const classes = useStyles()

  const getAddOns = (addOns: Addons[]) => {
    const finalString = addOns.reduce((acc, current) => `${acc} + ${current.name}`, '')
    return finalString.slice(2)
  }

  return (
    <> 
       
      {list.length && list.map(item => (

        <Grid item xs={12} key={item.id}>
          <Box data-test-id='menu-item' display="flex" justifyContent="space-between" alignItems="center" className={classes.MenuBoxWrapper}>

            <Box display="flex">
              <Box className={classes.ImageWrapper}>
                <img className={classes.Image} src={item.attributes.menu_image} alt='Food Image' />
                {item.attributes.food_type === MenuFilterList.Veg && <img className={classes.foodTypeIcon} src={vegIcon} alt='icon' />}
                {item.attributes.food_type === MenuFilterList.NonVeg && <img className={classes.foodTypeIcon} src={nonVegIcon} alt='icon' />}
                
              </Box>

              <Box display='flex' alignItems="start" flexDirection='column'>
                <Typography variant='h5' className={classes.MenuTitle}>{item.attributes.name}</Typography>
                <Typography variant='body1' className={classes.MenuAddOns}>{getAddOns(item.attributes.addons)}</Typography>
                <Typography variant='h6' className={classes.MenuPrice}>
                  <span className={classes.RupeesSymbol}>{"\u20B9"}</span>&nbsp;
                  {parseFloat(item.attributes.total_price).toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" flexDirection="column">

              {
                item.attributes.user_cart.total_items ?
                  <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.ButtonWrapper}>
                    <Button data-test-id='remove-item' variant='outlined' className={classes.ActionBtn} onClick={() => onClickItem(item.id, item.attributes.user_cart.total_items - 1, item.booking_menu_id)}>-</Button>
                    <span data-test-id='item-quantity' className={classes.Quantity}>{item.attributes.user_cart.total_items}</span>
                    <Button data-test-id='add-item' variant='outlined' className={classes.ActionBtn} onClick={() => onClickItem(item.id, item.attributes.user_cart.total_items + 1, item.booking_menu_id)}>+</Button>
                  </Box>
                  :
                  <Button data-test-id='add-item' variant='outlined' className={classes.AddBtn} onClick={() => onClickItem(item.id, item.attributes.user_cart.total_items + 1, item.booking_menu_id)}>Add</Button>
              }

            </Box>

          </Box>
          <Divider className={classes.Divider} />
        </Grid>

      ))}
      
    </> 
  )
}

export default MenuListItem
// Customizable Area End