import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastPartOfURL } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTimeSelected: boolean
  venueList: any
  isLoading: boolean
  eventDetail: any
  event_schedule_id: any
  anchorEl: any
  description: any
  Termsandcondtions: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectVenueScreenController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
  getVenueListApiCall: any
  getTermsAndContionsPage: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
      isTimeSelected: false,
      venueList: [],
      isLoading: true,
      eventDetail: {},
      event_schedule_id: 0,
      anchorEl: false,
      description: '',
      Termsandcondtions: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && apiRequestCallId === this.getVenueListApiCall) {
        this.setState({ eventDetail: responseJson.event_detail })
        this.mapData(responseJson.data)
        const eventBookingData = {
          name: responseJson.event_detail.name,
          Address: responseJson.data[0].attributes.name + ", " + responseJson.data[0].attributes.user_city_name,
        }
        setStorageData(LocalStorageKeys.EventBookingData, JSON.stringify(eventBookingData))
      }
      if (apiRequestCallId === this.getTermsAndContionsPage) {
        this.setState({ description: responseJson[0].description, })
      }
      this.setState({ isLoading: false })
    }
        // Customizable Area End
    }

    // Customizable Area Start

  closeLearnMoreDialog = () => {
    this.setState({ Termsandcondtions: false })
  }
  

  mapData = (data: any) => {
    let finalArray = [] as Array<{}>;
    let count = 0;
    data.forEach((item: any) => {
      const city = item.attributes.user_city_name;
      const name = item.attributes.name;
      const address = item.attributes.full_address;
      let keys = Object.keys(item.attributes.event_schedules);

      for (const key of keys) {
        let temp = {
          city: city,
          name: name,
          address: address,
          date: key,
          time: item.attributes.event_schedules[key],
          open: false,
          id: count++
        };
        finalArray.push(temp);
      }
    });
    this.setState({ venueList: finalArray })
  }
  selectTime = (id: any) => {
    this.setState({ isTimeSelected: true, event_schedule_id: id })
  }
  onClosePreference = () => {
    this.setState({ isTimeSelected: false })
  }

  onSelectRegularBooking = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SelectTier");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.event_schedule_id);
    this.send(msg);
  }

  onSelectListedTicket = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ListedTicket");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.event_schedule_id);
    this.send(msg);
  }

  onTermsAndConditionsCheck = async (slotId: number, venueName: string, city: string) => {
      const getITOstate = await getStorageData("ITOstate")as string;
      const eventHeaderData = await getStorageData(LocalStorageKeys.EventBookingData, true);

      const newHeaderData = {
        ...eventHeaderData,
        Address: venueName.concat(", ", city)
      }

      await setStorageData(LocalStorageKeys.EventBookingData, JSON.stringify(newHeaderData));
      
      if (getITOstate === "true") {
        this.setState({ Termsandcondtions: true, event_schedule_id: slotId })
      } else {
        this.selectTime(slotId); // Assuming selectTime is a function defined elsewhere
      }
  }



  async componentDidMount() {
    this.getVenueList()
    this.TermsAndContions()
    
  }

  getVenueList = async () => {
    const id = getLastPartOfURL()
    const getITOstate = await getStorageData("ITOstate")as string;
    this.getVenueListApiCall = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: `${getITOstate === "true" ? configJSON.getITOEventVenueListEndPoint : configJSON.getEventVenueListEndPoint}?city_name=${await getStorageData(LocalStorageKeys.Location)}&event_id=${id}`,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) as string },
    }, runEngine)
  }


  TermsAndContions = async () => {
      this.getTermsAndContionsPage = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: configJSON.TermsAndConditionsURL,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) as string },
      }, runEngine)
  }
    // Customizable Area End
}
