import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL } from "../../../components/src/utilities";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { IBookingData } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  listedTickets:any,
  isLoading:boolean,
  ticketMessage:boolean,
  message:string,
  pageCount:number,
  currentPage:number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ListedTicketController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    getListedTicketApiCall:any
    resellEventBooking:any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            listedTickets:[],
            isLoading:true,
            ticketMessage:false,
            message:"",
            pageCount:1,
            currentPage:1,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            
            if(responseJson) {
              if(apiRequestCallId === this.getListedTicketApiCall){
                this.setState({
                  listedTickets:responseJson.data, 
                  pageCount:responseJson.meta.pagination_details.pagination.total_pages
                })
              }
              if(apiRequestCallId === this.resellEventBooking){
                responseJson.message 
                  ? this.setState({message:responseJson.message}) 
                  :this.handleListedEventBookResponse(responseJson.data);
              }
            }
            this.setState({isLoading:false})
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      this.getListedTicket()
    }

    getListedTicket = async() =>{
      const id = getLastPartOfURL()
      this.getListedTicketApiCall = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: `${configJSON.getListedTicketApiEndPoint}${id}&per=8&page=${this.state.currentPage}`,
        headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string  },
      }, runEngine)
    }

    buyListedTicket = async(id:any) => {
      const httpBody = { }
      this.resellEventBooking = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.resellEventBookingEndPoint.replace("bookingId",id),
        body: httpBody,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    handleListedEventBookResponse = async(responseJson:any) =>{
      const bookingData: IBookingData = {
        id: +responseJson.id,
        totalAmount: +responseJson?.attributes?.total_amount,
        hideOtherPayment: true
      }
      await setStorageData(LocalStorageKeys.BookingData, JSON.stringify(bookingData))
      await setStorageData("listedTicket", "true")
  
      const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "PaymentSelectionEvent");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationScreenNameMessage), "event");
        this.send(msg);
     
    }

    handleOnPageChange = (e: any) => {
      this.setState({ currentPage: e.selected + 1 }, ()=> this.getListedTicket())
    }
    // Customizable Area End
}
