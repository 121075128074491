import React from 'react';

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Slider, { Settings } from "react-slick";
import { PreviousBtnMain } from "../../../components/src/PreviousBtnPlay.web";
import { NextBtnMain } from "../../../components/src/NextBtnPlay.web";
import "./customadvertisements.css";
// Customizable Area End

import CustomadvertisementsController, {
  Props,
  configJSON,
} from "./CustomadvertisementsController.web";

export default class Customadvertisements extends CustomadvertisementsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  renderAdsSlider = () => {
    const settings: Settings = {
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "center",
      centerMode: true,
      dots: true,
      variableWidth: true,
      dotsClass: "button__bar",
      arrows: true,
      draggable: true,
      prevArrow: <PreviousBtnMain />,
      nextArrow: <NextBtnMain />,
      centerPadding: "100px",

      responsive: [
        {
          breakpoint: 600,
          settings: {
            centerPadding: "73px",
          },
        },
      ]
    };
  
    return (
      <Box
        className="home_slider"
      >
        <Slider {...settings}>
          {this.state.adsData.map(({ id, attributes}, index) => {
            return (
              index >= 5? null :
            <React.Fragment key={id}>
              <img
                src={attributes.content}
                alt={`Image for the movie or event ${attributes.title}`}
              />
              <div className="img_overlay" />
            </React.Fragment>
          )}
          )}
        </Slider>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
           this.state.adsData.length ? this.renderAdsSlider() : null
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
