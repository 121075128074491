declare global {
  interface Window {
      Digio:any;
  }
}
 
 const DigioFunction = (
    kycVerificationCompleted: any, 
    digioDocumentId :string, 
    digioLoginToken:string, 
    email:string, 
    phoneNumber:any ) => {

    const options = {
        environment : "sandbox",
        callback : (response: any) => {
        if(response.hasOwnProperty("error_code")){
        return console.log("error occurred in process", response);
        }
        if(response.hasOwnProperty("digio_doc_id")){
          kycVerificationCompleted();
        }
        },
    
        logo : "https://www.mylogourl.com/image.jpeg",
        is_iframe:false,
        theme : {
        primaryColor : "#AB3498",
        secondaryColor : "#000000",
        }
        }
    
    const digioInstance = new window.Digio(options);
      
    digioInstance.init();
    digioInstance.submit(digioDocumentId, 
      email ?? phoneNumber, 
      digioLoginToken);
    
}


export default DigioFunction;