import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import CustomMap from './CustomMap.web'


interface IMapDialogProps {
  open: boolean
  fullWidth?: boolean
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
  title: string
  destinationAddress: string
  handleClose?: () => void
}

const CLOSE_BUTTON = require("./assets/close.png")
const LOCATION_ICON = require("./assets/location_icon.png")

const useStyles = makeStyles((theme) => ({
  DialogPaper: {    
    backgroundColor: "#404040",
    borderRadius: "8px",
    padding: "48px 16px 16px",
    height: "80%",
    [theme.breakpoints.down('sm')]: {
      padding: "24px 8px"
    }
  },
  DialogTitle: {
    fontFamily: "Poppins",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px"
    }
  },
  IconButtonWrapper: {
    position: 'absolute',
    top: "12px",
    right: "12px",
    padding: 0    
  },
  IconButton: {
    height: "24px",
    width: "24px"
  },
  TitleWrapper: {
    padding: "0 0 16px",
    [theme.breakpoints.down('sm')]: {
      padding: "0 0 8px"
    }
  },
  ContentWrapper: {
    padding: 0
  },
  IconWrapper: {
    paddingTop: "4px"
  },
  AddressWrapper: {
    marginTop: "32px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "8px"
    }
  },
  JustifyContentLeft: {
    justifyContent: "start"
  }
}))

const MapDialog: React.FC<IMapDialogProps> = ({
  open,
  handleClose,
  maxWidth = 'md',
  fullWidth = false,
  destinationAddress
}) => {

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      classes={{ paper: classes.DialogPaper}}
    >
      <DialogTitle className={classes.TitleWrapper} id="alert-dialog-title">
        <IconButton className={classes.IconButtonWrapper} onClick={handleClose}>
          <img className={classes.IconButton} src={CLOSE_BUTTON} width="28px" height="28px" />
        </IconButton>
        <Typography className={classes.DialogTitle} align="center">Cinepolis: Nexus Plaza</Typography>
      </DialogTitle>
      <DialogContent className={classes.ContentWrapper}>

          <CustomMap destinationAddress={destinationAddress} />

      </DialogContent>
      <DialogActions className={classes.JustifyContentLeft}>
        <Box display="flex" className={classes.AddressWrapper} gridGap="8px">
          <Icon className={classes.IconWrapper}>
            <img src={LOCATION_ICON} width="20px" height="24px" />
          </Icon>
          <Typography className={classes.DialogTitle}>{destinationAddress}</Typography>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default MapDialog