import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  CssBaseline,
  CircularProgress,
  Fade
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { BackArrow, HeroReferAndEarnImage, CopyIcon } from "./assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { goToPrevPage } from "../../../components/src/utilities";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    },
    text: {
      primary: "#fff"
    }
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h2: {
      fontWeight: 500,
      fontSize: "24px"
    },
    h6: {
      fontSize: "18px",
      fontWeight: "normal"
    }
  },
});

const PageHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "32px 0",
  "& .page__title": {
    marginLeft: "16px"
  },
  "& .back__button": {
    cursor: "pointer"
  }
})

const ContainerBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh"
})

const ContentWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "60px 0",
  "& .hero__image": {
    height: "218px",
    width: "335px"
  },
  "& .reward__title": {
    margin: "32px 0 16px"
  },
  "& .rupees__icon": {
    fontFamily: "Roboto, sans-serif"
  },
  "& .refer__content": {
    maxWidth: "699px",
    textWrap: "wrap",
    textAlign: "center",
    marginBottom: "32px"
  },
  "& .referral__code__wrapper": {
    display: "flex",
    gap: "12px",
    border: "dashed 2px #b4b4b440",
    justifyContent: "center",
    borderRadius: "8px",
    alignItems: "center",
    minHeight: "48px",
    color: "#b4b4b4",
    flexWrap: "wrap",
    padding: "8px 36px",
    "& .referral__code": {
      fontWeight: 500,
      textTransform: "uppercase",
      lineHeight: 1,
      color: "#FFF"
    },
    "& .copy__icon": {
      height: "24px",
      width: "20px",
      cursor: "pointer"
    }
  }
})
// Customizable Area End

import ReferralsController, { Props, configJSON } from "./ReferralsController.web";

export default class Referrals extends ReferralsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="service-shop-name">
                This is the reviced value:{this.state.txtSavedValue}{" "}
              </InputLabel>
              <Input
                data-test-id={"txtInput"}
                type={this.state.enableField ? "password" : "text"}
                placeholder={configJSON.txtInputPlaceholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.txtInputValue}
                onChange={({ target }) => this.setInputValue(target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.setEnableField}
                      edge="end">
                      {this.state.enableField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box
              data-test-id="btnAddExample"
              onClick={() => this.doButtonPressed()}
              component="button"
              sx={webStyle.buttonStyle}>
              <Button color={"primary"}>{configJSON.btnExampleTitle}</Button>
            </Box>
          </Box>
        </Container>

        {/* Refer and Earn start */}
        <CssBaseline />

        <ContainerBox>

          <MainHeaderComp navigation = {this.props.navigation} />

          <Box flex={1}>
            <Container>

              <PageHeader>
                <img src={BackArrow} className="back__button" alt="back-arrow" width="32px" height="32px" onClick={goToPrevPage} />
                <Typography data-test-id="title-text" variant="h2" className="page__title">{configJSON.ReferAndEarnTitle}</Typography>
              </PageHeader>

              {
                this.state.isLoading ? (
                  <Fade in={this.state.isLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress style={webStyle.CircularProgress} />
                    </Box>
                  </Fade>
                ) : (
                  <ContentWrapper>

                    <img className="hero__image" src={HeroReferAndEarnImage} alt="Hero" />

                    <Typography variant="h2" className="reward__title">
                      {configJSON.RewardEarn}
                      <span className="rupees__icon">&nbsp;&#8377;&nbsp;</span>
                      {this.state.referralAmount}
                    </Typography>

                    <Typography variant="h6" className="refer__content">
                      <span className="rupees__icon">&nbsp;&#8377;&nbsp;</span>
                      {this.state.referralAmount}&nbsp;
                      {configJSON.ReferContent}
                    </Typography>

                    <Box className="referral__code__wrapper">
                      <Typography variant="h6">{configJSON.ReferralCode}</Typography>
                      <Typography>
                        <span className="referral__code">{this.state.referralCode}</span>
                      </Typography>

                        <img className="copy__icon" src={CopyIcon} alt="copy" onClick={() => { navigator.clipboard.writeText(this.state.referralCode) }} />
                    </Box>

                  </ContentWrapper>
                )
              }

            </Container>
          </Box>

          <FooterWithEvents />
        </ContainerBox>

        {/* Refer and Earn end */}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "none",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  CircularProgress: {
    marginTop: "20px",
    color: "#ffb43a"
  },
};
// Customizable Area End
