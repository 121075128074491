import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  styled,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    InputAdornment,
    OutlinedInput,
    IconButton,
    Input
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import { indianFlag } from "../../social-media-account-login/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { SingleSeat } from "../../../components/src/Seat.web";

const Continuebtn = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "56px",
  margin: "32px 16px 0px",
  borderRadius: "10px",
  backgroundColor: "#FFB43A",
  textTransform: "capitalize",
  fontWeight: 600,
  "&:hover": {
      backgroundColor: "#FFB43A"
  },
  color: "#121212",
  fontFamily: "Poppins",
  fontSize: "16px"
}));

const SeatTypeData = styled(Box)({
  textTransform: 'capitalize'
})

export const configJSON = require("./config");

// Customizable Area End

import PreviewBookingController, {
  Props,
} from "./PreviewBookingController.web";
import { Seat } from "../../../components/src/BookingSuccessfulTicket.web";
import { ArrowForwardIos, DeleteOutlined, ExpandLess, ExpandMore, HighlightOffOutlined } from "@material-ui/icons";
import { OfferIcon, SeatIcon, TicketStar } from "./assets";
import { calculateRemainingTime, convertToPad, getMovieDuration } from "../../../components/src/utilities";
import { SeatStatus } from "../../../components/src/enums.web";


export class PreviewBooking extends PreviewBookingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDialog = () => {

    const { classes } = this.props;
    return(
      <Dialog
          id="language"
          open={true}
          PaperProps={{
            style: {
              backgroundColor: "#404040",
              color: "#fff",
              padding: '12px'
            }
          }}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          data-test-id="contact_dialog"
        >

          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.SeatNumber}
          >
            {"Contact Information"}
          </DialogTitle>
          <DialogContent style={{ overflowY: "hidden" }} className={classes.dialogContent} data-test-id="seat_number">

            <Box className={classes.marginToTop}>
              <Typography 
                gutterBottom id="modal-modal-title"
                variant="h4"
                className={classes.inputLable}
              >
                Mobile Number
              </Typography>
              <div>
                <OutlinedInput
                  fullWidth
                  type="tel"
                  placeholder="Enter mobile number"
                  className={classes.textInput}
                  inputProps={{
                    style: { fontSize: '16px' },
                    maxLength: 10
                  }}
                  data-test-id="phone"
                  value={this.state.phoneNumber}
                  name="phoneNumber"
                  onChange={(event) => this.onChangeNumber(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        className={classes.flagIcon}
                        id='countrycode'
                      >
                        <img 
                          style={{ height: '14px', marginRight: "5px" }} 
                          src={indianFlag} alt="indian" 
                        />
                        <span
                          style={{
                            color: "#ffffff",
                            fontSize: "14px",
                          }}
                        >
                          +91
                        </span>
                        <KeyboardArrowDownIcon style={{ color: '#ffb43a' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              {this.state.mobileError &&
                <Typography variant="h4" className={classes.errorMsg} gutterBottom id="modal-modal-title">
                  {this.state.mobileError}
                </Typography>
              }
            </Box>

            <Box className={classes.marginToTop} >
              <Typography className={classes.inputLable} variant="h4" gutterBottom id="modal-modal-title">
                Email Id
              </Typography>
              <Input
                value={this.state.email}
                className={classes.inputStyle}
                data-test-id={"txtEmail"}
                placeholder="Enter email id"
                fullWidth={true}
                disableUnderline={true}
                onChange={(e) => this.onChangeEmail(e)}
              />
              {this.state.emailError &&
                <Typography variant="h4" className={classes.errorMsg} gutterBottom id="modal-modal-title">
                  {this.state.emailError}
                </Typography>
              }
            </Box>
          </DialogContent>
          <DialogActions style={{justifyContent:"center"}}>
            <Continuebtn id="continue"
              style={{opacity: this.state.email === "" || this.state.phoneNumber === "" ? 0.5 :1}}
              variant="outlined" 
              color="primary"
              data-test-id="continue"
              disabled = {this.state.email === "" || this.state.phoneNumber === ""}
              onClick={() => {this.onContinueContactInfo()}}>
              {"Continue"}
            </Continuebtn>
          </DialogActions>
        </Dialog>
    )
  }
  // Customizable Area End

  render() {
    const { classes, selectedValue } = this.props;
    return (
      // Customizable Area Start
      <Box className={classes.BackgroundWrapper} data-test-id="preview-booking-wrapper">

      {this.state.isLoading ? (
        <Fade in={this.state.isLoading} unmountOnExit>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Fade>
      ) : (
        <>
          <Box display="flex" alignItems="start">

              <Box className={classes.ImageWrapper}>
                <img className={classes.MovieImage} src={this.state.previewData.movie?.imageUrl} alt='Movie Poster' />
              </Box>

            <Box>
              <Typography variant="h5" className={classes.MovieName}>{this.state.previewData.movie?.name}</Typography>
              <Typography variant="subtitle1" className={classes.MovieDescription1}>
                {this.state.previewData.movie?.releaseDate}&nbsp;UA&nbsp;{getMovieDuration(this.state.previewData.movie?.duration)}
              </Typography>
              <Typography variant="subtitle1" className={classes.MovieDescription1}>
                {`${this.state.previewData.movie?.language} ${this.state.previewData.movie?.format}`.toUpperCase()}
              </Typography>
              <Typography variant="subtitle1" className={classes.MovieDescription2}>
                {this.state.previewData.theater.showDate}
                <span className={classes.PrimaryColor}>&nbsp;|&nbsp;</span>
                {this.state.previewData.theater?.showTime}
              </Typography>
              <Typography variant="subtitle1" className={classes.MovieDescription1}>{this.state.previewData.theater?.name}</Typography>
            </Box>

            </Box>

            <Divider className={classes.Divider} />

            <Box display="flex" alignItems="center" justifyContent="space-between">

              <Box display="flex" alignItems="center">
                <Box className={classes.IconWrapper}>
                  <img src={TicketStar} alt="Seat" className={classes.TicketIcon} />
                </Box>
                <SeatTypeData>{this.state.previewData.seats?.type || this.state.itoDetails?.seatTier}</SeatTypeData>
              </Box>
              <Box display="flex" alignItems="center">
                <Box className={classes.IconWrapper}>
                  <img src={SeatIcon} alt="Seat" className={classes.SeatIcon} />
                </Box>
                <Box>No of Seats {convertToPad(this.state.previewData.seats?.selectedSeats?.length || this.state.itoDetails?.numbersOfSeats)}</Box>
              </Box>

            </Box>

            <Divider className={classes.Divider} />

            <Box>

              <Box display="flex" justifyContent="space-between">
                <span className={classes.ScreenName}>{this.state.previewData.theater?.screenName}</span>
                <Box display="flex" className={classes.SeatDisplay}>

                {this.state.previewData.seats?.selectedSeats.map(seat => <Seat key={seat.seat_id} seatLabel={seat.seat_number} />)}
                {this.state.itoDetails.isItoBooking && [...Array(this.state.itoDetails.numbersOfSeats).keys()].map(seatNumber => <SingleSeat key={seatNumber} status={SeatStatus.Unavailable} />)}
                
              </Box>
            </Box>
            <Box pt={1} display="flex" justifyContent="space-between">
              <span className={classes.ScreenInfo}>Screen</span>
              <span className={classes.ScreenInfo}>Seats</span>
            </Box>

            </Box>

            <Divider className={classes.Divider} />

            <List className={classes.PaymentWrapper}>

                {
                  this.state.itoDetails.isItoBooking && (
                    <>
                      <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText} data-test-id="ito-heading">{configJSON.itoCloseIn}</ListItemText>
                        <span>{calculateRemainingTime(this.state.itoDetails.itoCloseIn)}</span>
                      </ListItem>

                      <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>{configJSON.itoPricePerTicket}</ListItemText>
                        <span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{this.state.itoDetails.itoPricePerTicket.toFixed(2)}</span>
                      </ListItem>
                    </>
                  )
                }

              
              <ListItem className={classes.PaymentListItem}>
                <ListItemText className={classes.MenuListText}>{configJSON.subTotal}</ListItemText>
                <span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{this.state.previewData.costDetails.subTotal.toFixed(2)}</span>
              </ListItem>

              <ListItem className={classes.PaymentListItem}>
                <ListItemText className={classes.MenuListText}>
                  {configJSON.convenienceFees}
                  <ListItemIcon className={classes.MenuExpandIcon} data-test-id="cost-dropdown" onClick={() => this.showCostData()}>
                    {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                </ListItemText>
                <span>
                  <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                  {this.state.previewData.costDetails.convenienceFees.toFixed(2)}
                </span>
              </ListItem>

            <Collapse data-test-id="cost-collapse" in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>
              <List className={classes.SubList} component="div" disablePadding>
                <ListItem>
                  <ListItemText className={classes.MenuListText}>{configJSON.baseAmount}</ListItemText>
                  <span>
                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                    {this.state.previewData.costDetails.convenienceFeesBifurcation.baseAmount.toFixed(2)}
                  </span>
                </ListItem>
                {this.stateCheck(this.state.previewData.theater.state.toLowerCase()) ?  
                <ListItem>
                  <ListItemText className={classes.MenuListText}>
                    {`IGST @ ${this.state.previewData.costDetails.convenienceFeesBifurcation.IGSTPercent.toFixed(2)}%`}
                  </ListItemText>
                  <span>
                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                    {this.state.previewData.costDetails.convenienceFeesBifurcation.IGST.toFixed(2)}
                  </span>
                </ListItem>: <>
                <ListItem>
                  <ListItemText className={classes.MenuListText}>
                    {`CGST @ ${this.state.previewData.costDetails.convenienceFeesBifurcation.CGSTPercent}%`}
                  </ListItemText>
                  <span>
                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                    {this.state.previewData.costDetails.convenienceFeesBifurcation.CGST.toFixed(2)}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.MenuListText}>
                    {`SGST @ ${this.state.previewData.costDetails.convenienceFeesBifurcation.SGSTPercent}%`}
                  </ListItemText>
                  <span>
                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                    {this.state.previewData.costDetails.convenienceFeesBifurcation.SGST.toFixed(2)}
                  </span>
                </ListItem>
                </>}
              </List>
            </Collapse>

            <ListItem className={classes.PaymentListItem}>
              <ListItemText className={classes.MenuListText}>{configJSON.discount}</ListItemText>
              <span>
                <span className={classes.RupeesSymbol}>-&nbsp;{"\u20B9"}&nbsp;</span>
                {this.state.previewData.costDetails.convenienceFeesBifurcation.discount.toFixed(2)}
              </span>
            </ListItem>

                {this.state.previewData.costDetails.totalFoodAmount ? (
                  <>
                    <ListItem className={classes.PaymentListItem}>
                      <ListItemText className={classes.MenuListText}>
                        {configJSON.foodBeverage}
                        <ListItemIcon className={classes.MenuExpandIcon} data-test-id="food-dropdown" onClick={() => this.showFoodData()}>
                          {this.state.isFoodOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                      </ListItemText>
                      <span>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {this.state.previewData.costDetails?.totalFoodAmount?.toFixed(2)}
                      </span>
                    </ListItem>

                    <Collapse data-test-id="food-collapse" in={this.state.isFoodOpen} timeout="auto" unmountOnExit>
                      <List className={classes.SubList} component="div" disablePadding>

                        {
                          this.state.previewData.costDetails?.Food?.map(({
                            label, price, quantity, menuId
                          }) => (
                            <ListItem key={menuId}>
                              <ListItemIcon className={classes.RemoveIconWrapper} onClick={() => this.removeItemByMenuId(menuId)}>
                                <HighlightOffOutlined />
                              </ListItemIcon>
                              <ListItemText className={classes.MenuListText}><span>{`${label} (Qty ${quantity})`}</span></ListItemText>
                              <span>
                                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                {price.toFixed(2)}
                              </span>
                            </ListItem>
                          ))
                        }

                      </List>
                    </Collapse>
                    
                    <Box
                      data-test-id="remove-food-button"
                      onClick={() => this.removeItemByMenuId()}
                      className={classes.RemoveButton}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end">
                        <DeleteOutlined />{configJSON.remove}
                    </Box>
                  </>
                ): null}

            

            <ListItem className={classes.PaymentListItem}>
              <ListItemText className={classes.MenuListText}>{configJSON.totalAmount}</ListItemText>
              <span>
                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                {this.state.previewData.costDetails.total.toFixed(2)}
              </span>
            </ListItem>
          </List>

            <Divider className={classes.Divider} />

          <Box>
            <Box pb={2} display="flex" alignItems="center" justifyContent="space-between">
              <span className={classes.SidebarMenuTitle}>{configJSON.contactInformation}</span>
              <span data-test-id="edit-contact-info" onClick={()=>{this.handleContactInfo()}} className={classes.EditButtonAction}>Edit</span>
            </Box>
            <Box className={classes.ContactDetails} display="flex" alignItems="center" justifyContent="space-between">
              <span>Email ID :</span>
              <span>{this.state.previewData.userInfo.email}</span>
            </Box>
            <Box className={classes.ContactDetails} display="flex" alignItems="center" justifyContent="space-between">
              <span>Mobile Number :</span>
              <span>{`+91${this.state.previewData.userInfo.phoneNumber}`}</span>
            </Box>
          </Box>

              <Button fullWidth className={classes.ProceedButton} data-test-id="pay-btn"
                disabled = {selectedValue == ""}
                onClick={() => this.handleOnClickPay()}
              >{configJSON.proceedToPay}</Button>
          </>

        )}

        {this.state.isContactInfo && this.renderDialog()}

      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const useStyles = {
  BackgroundWrapper: {
    backgroundColor: "#252525",
    padding: "32px",
    fontFamily: "Poppins",
  },
  RupeesSymbol: {
    fontFamily: 'Roboto-Medium, sans-serif'
  },
  ImageWrapper: {
    width: "130px",
    height: "174px",
    border: "solid 1px #ffb53a",
    marginRight: "18px",
  },
  MovieImage: {
    width: '100%',
    height: '100%',
  },
  MovieName: {
    fontSize: '22px',
    fontWeight: 600,
  },
  MovieDescription1: {
    fontSize: '18px',
    margin: 0,
    lineHeight: "1.65",
  },
  MovieDescription2: {
    fontSize: '18px',
    fontWeight: 600,
    margin: 0,
    lineHeight: "1.65",
  },
  Divider: {
    margin: "24px 0",
    backgroundColor: "#979797",
  },
  ScreenName: {
    fontSize: "18px",
    fontWeight: 500,
  },
  ScreenInfo: {
    color: "#b4b4b4",
    fontSize: "16px",
  },
  SeatDisplay: {
    gap: "8px",
    maxWidth: "192px",
    flexFlow: "wrap",
  },
  PaymentWrapper: {
    padding: 0,
  },
  PaymentListItem: {
    padding: "4px 0",
    maxHeight: "36px",
    "& span": {
      fontSize: "16px",
      lineHeight: 1,
    },
    "&:last-child": {
      paddingBottom: 0,
    },
    "&:first-child": {
      paddingTop: 0,
    },
  },
  MenuListText: {
    display: "inline-flex",
    alignItems: "center",
    "& span": {
      display: 'flex',
      gap: "8px",
      alignItems: 'center',
    },
  },
  MenuExpandIcon: {
    color: '#ffb43a',
    cursor: "pointer",
  },
  RemoveButton: {
    color: "#b4b4b4",
    marginTop: "-3px",
    fontSize: "14px",
    marginBottom: "5px",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      paddingRight: "4px",
    },
  },
  SidebarMenuTitle: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#fff",
  },
  EditButtonAction: {
    fontSize: "16px",
    color: "#ffb53a",
    cursor: "pointer",
  },
  ProceedButton: {
    backgroundColor: "#ffb43a",
    height: "56px",
    color: "#000",
    borderRadius: "10px",
    border: `1px solid #ffb43a`,
    "&.MuiButton-root": {
      textTransform: 'none',
      fontSize: "1rem",
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2f2617",
    },
  },
  ContactDetails: {
    paddingBottom: "14px",
    "&:last-child": {
      paddingBottom: "32px",
    },
    "& span": {
      fontSize: "16px",
    },
  },
  RemoveIconWrapper: {
    minWidth: 'auto',
    marginRight: '4px',
    color: "#b4b4b4",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  SubList: {
    "& .MuiListItem-gutters": {
      padding: 0,
    },
    "& span": {
      fontSize: "14px",
    }
  },
  IconWrapper: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginRight: "12px",
  },
  IconImage: {
    width: "22px",
    height: "22px",
  },
  TicketIcon: {
    width: "24px",
    height: "22px",
  },
  SeatIcon: {
    width: "26px",
    height: "22px",
  },
  OfferAction: {
    color: "#ffb43a",
    fontWeight: 600,
    fontSize: "18px",
  },
  ArrowAction: {
    color: "#ffb43a",
  },
  FullWidth: {
    width: "100%",
  },
  PrimaryColor: {
    color: "#ffb43a",
    fontWeight: 600,
  },
  SeatNumber: {
    '& h2': {
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
    }
  },
  textInput: {
    border: "solid 1px #b4b4b4",
    borderRadius: "9px",
    color: "#ffffff",
    fontSize: "16px",
    height: "56px",
    marginBottom: 16,
    backgroundColor: "#404040",
  },
  inputStyle: {
    color: "#ffffff",
    border: "1px solid #b4b4b4",
    padding: "16px",
    borderRadius: "12px",
    height: 56,
    backgroundColor: "#404040",
  },
  marginToTop: {
    marginTop: 10
  },
  errorMsg:{
    color:"#ff3b30", 
    fontFamily: 'Poppins',
    fontSize: '18px',
    '@media(max-width:700px)': {
        fontSize: "15px",
    }
  },
  flagIcon: {
    borderRight: "1px solid #979797",
    width: "82px",
    height: "36px",
    borderRadius: "0%",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: '10px',
  },
  inputLable:{
    fontFamily: 'Poppins',
    fontSize: '18px',
    color: '#ffffff',
    '@media(max-width:700px)': {
        fontSize: "15px",
    }
},
};

export default withStyles(useStyles, { withTheme: true })(PreviewBooking)
// Customizable Area End