import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Paper,
    Container,
    Grid,
    Tooltip,
    CircularProgress,
    Fade,
    CssBaseline,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { convertTo12HourFormat, getMovieDuration, getTrailersText, goToPrevPage} from "../../../components/src/utilities";
import { backarrowleft as backButton, closeIcon, filled_heart, heartNotSelected, playButton,  } from "./assets";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import DateCarousel from "../../../components/src/DateCarousel.web";
import SelectDropDown from "../../../components/src/SelectDropdown.web";
import { search } from "../../splashscreen/src/assets";
import AddFavourites from "../../favourites/src/AddFavourites.web";
import RFHDialog from "../../../components/src/RFHDialog";

const seatsStatusMap = {
    available: "Available",
    fast_filling: "Fast Filling",
    sold_out: "Sold Out"
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            default: "#121212",
            paper: "#404040"
        },
        text: {
            primary: "#FFF"
        }
    },
    typography: {
        fontFamily: "Poppins, Roboto, sans-serif",
        h1: {
            fontSize: "22px",
            fontWeight: 500,
            "@media (max-width: 600px)": {
                fontSize: "20px",
            }
        },
        h2: {
            fontSize: "32px",
            fontWeight: 500,
            color: "#FFF"
        },
        h3: {
            fontSize: "24px",
            fontWeight: 500,
            color: "#FFF",
        },
        h5: {
            fontSize: "16px",
            color: "#FFF",
            margin: "16px 0"
        },
        h6: {
            fontSize: "18px",
            color: "#FFF",
            fontWeight: 500
        },
        body1: {
            fontSize: "18px",
            color: "#FFF"
        },
        subtitle1: {
            fontSize: "24px",
            fontWeight: 500,
            color: "#FFF",
        }
    },
    overrides: {
        MuiTypography: {
            root: {
                "&.seat__type": {
                    textTransform: "uppercase",
                    fontSize: "12px",
                    color: "#fff",
                    textAlign: "center"
                },
                "&.seat__price": {
                    textTransform: "capitalize",
                    fontSize: "12px",
                    color: "#fff",
                    textAlign: "center",
                    "& .rupees__icon": {
                        fontFamily: "Roboto, sans-serif"
                    }
                },
                "&.seat__status": {
                    color: "#ffb43a",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    textAlign: "center",
                    "&.sold_out": {
                        color: "#FFF",
                    }
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                display: "flex",
                justifyContent: "space-between",
                maxWidth: 256,
                width: "100%",
                padding: "12px",
                gap: "12px",
                border: "solid 1px #979797",
                backgroundColor: "#121212",
            },
            arrow: {
                color: "#121212",
                "&::before": {
                    border: "solid 1px #979797",
                }
            }
        },
    },
});

const RFHButton = styled(Button)({
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    color: "#000",
    backgroundColor: "#ffb43a",
    borderRadius: "12px",
    height: "56px",
    fontWeight: 600,
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#ffb43a"
    }
})

// Customizable Area End

import RegularBookingController, {
    Props,
    configJSON
} from "./RegularBookingController";

export default class RegularBooking extends RegularBookingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>

                    <CssBaseline />

                    <BoxWrapper data-test-id="regular-booking">

                        <Box display={{ xs: "none", sm: "block" }}><MainHeaderComp navigation={this.props.navigation} /></Box>

                        <Box display={{ xs: "block", sm: "none" }} className='mobile__header'>
                            <img src={backButton} className='back__button' alt='back' onClick={goToPrevPage} />
                        </Box>

                        <Box flex={1}>
                            {
                                this.state.isInitialLoading ? (
                                    <Fade in={this.state.isInitialLoading} unmountOnExit>
                                        <Box className="circular__wrapper">
                                            <CircularProgress className='circular__progress' />
                                        </Box>
                                    </Fade>
                                ) : (
                                    <>
                                        <Box className="hero__section__wrapper">
                                            <img alt="movie-poster" src={this.state.movieDetail.attributes.imageUrl} />

                                            <Container>
                                                <Box className='movie__wrapper'>
                                                    <Box className='movie__title'>
                                                        <Typography variant="h1" data-test-id="movie-name">{this.state.movieDetail.attributes.name}</Typography>
                                                        <Box className='movie__detail__wrapper'>
                                                            {
                                                                this.state.movieDetail.attributes.categoryType.map(category => (
                                                                    <span key={category} className='category__name'>{category}</span>
                                                                ))
                                                            }
                                                            <span>
                                                                {configJSON.UA}&nbsp;&nbsp;
                                                                {configJSON.Duration}
                                                                {getMovieDuration(this.state.movieDetail.attributes.durationInMinutes)}
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box className="trailer__wrapper">
                                                        <img src={playButton} alt="play-button" className='play-btn' onClick={this.handleNavigateToTrailer} data-test-id="trailer-button" />
                                                        <span className='trailer-text'>
                                                            {`${getTrailersText(this.state.movieDetail.attributes.youtube_links)} (${this.state.movieDetail.attributes.youtube_links.length})`}
                                                        </span>
                                                    </Box>
                                                </Box>
                                            </Container>
                                        </Box>

                                        <Paper elevation={0} className='filter__wrapper'>
                                            <Container>
                                                <Grid container>
                                                    <Grid item xs={12} md={7} className='date__carousel'>
                                                        <DateCarousel
                                                            selectedDate={this.state.filterData.selectedDate}
                                                            noOfDaysToDisplay={20}
                                                            onChange={this.handleDateChange}
                                                            startDate={this.initialDate()}
                                                        />
                                                    </Grid>
                                                    <Grid item md={5} xs={false}>
                                                        <Box className="filter__child__wrapper" display={{ xs: "none", md: "flex" }}>

                                                            {
                                                                this.state.isSearch ? (
                                                                    <Box className='open__search'>
                                                                        <img
                                                                            src={search}
                                                                            className='search__icon'
                                                                            alt='search'
                                                                        />
                                                                        <input type="text"
                                                                            name='search'
                                                                            placeholder='Search Cinema'
                                                                            className='search__input'
                                                                            data-test-id="searchText"
                                                                            onChange={this.onSearchCinema}
                                                                        />
                                                                        <img
                                                                            src={closeIcon}
                                                                            className="close__icon"
                                                                            alt='close'
                                                                            data-test-id="crossbtn"
                                                                            onClick={this.handleOnCloseSearchBar}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <>
                                                                        <SelectDropDown
                                                                            value={this.state.filterData.selectedLanguageId}
                                                                            options={this.state.languageOptionsList}
                                                                            elementWidth="140px"
                                                                            handleChange={this.handleLanguageChange}
                                                                        />
                                                                        <SelectDropDown
                                                                            value={this.state.filterData.selectedFormatId}
                                                                            options={this.state.formatOptionsList}
                                                                            elementWidth="130px"
                                                                            handleChange={this.handleFormatChange}
                                                                        />
                                                                        <Box data-test-id="searchByName" onClick={this.onSearch}>
                                                                            <img
                                                                                src={search}
                                                                                className='search__icon'
                                                                                alt='search'
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )
                                                            }
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </Paper>

                                        <Container>
                                            <Grid container data-test-id="time-slot-listing-wrapper">
                                                {
                                                    this.state.isMovieTimeSlotApiLoading ? (
                                                        <Grid item xs>
                                                            <Fade in={this.state.isMovieTimeSlotApiLoading} unmountOnExit>
                                                                <Box className="circular__wrapper">
                                                                    <CircularProgress className='circular__progress' />
                                                                </Box>
                                                            </Fade>
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            {
                                                                this.state.timeSlotList.length > 0 ? this.state.timeSlotList.map((screen) => {

                                                                    return (
                                                                        <Grid key={screen.screenName} item xs={12} >
                                                                            <Box className='movie__timeslot__wrapper'>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} className='d__flex' md={4}>
                                                                                        <AddFavourites
                                                                                            addFavourites={{
                                                                                                heartIcon: heartNotSelected,
                                                                                                filledHeart: filled_heart,
                                                                                                imageStyle: {},
                                                                                                favouriteable_id: screen.theatreId.toString(),
                                                                                                favouriteable_type: "BxBlockReservations::Theatre",
                                                                                                tooltipDirection: "bottom",
                                                                                                isFavourite: screen.isFavourite || false
                                                                                            }}
                                                                                            id="favourites"
                                                                                            navigation={this.props.navigation}
                                                                                        />

                                                                                        <Box data-test-id="theater-name"className='theater__name' >
                                                                                            {screen.theaterName}
                                                                                        </Box>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12} md={8}>
                                                                                        <Box className="time__wrapper" data-test-id="box-wrapper">
                                                                                            {
                                                                                                screen.timeSlots.map((slot) => (
                                                                                                    <Tooltip
                                                                                                        arrow
                                                                                                        key={slot.screenId}
                                                                                                        title={
                                                                                                            slot.availability.map((availability) => (
                                                                                                                <Box key={availability.seat_type}>
                                                                                                                    <Typography className='seat__type'>
                                                                                                                        {availability.seat_type}
                                                                                                                    </Typography>
                                                                                                                    <Typography className='seat__price'>
                                                                                                                        <span className='rupees__icon'>{"\u20B9"}&nbsp;</span>
                                                                                                                        {availability.price.toFixed(2)}
                                                                                                                    </Typography>
                                                                                                                    <Typography className={`seat__status ${availability.seat_status}`}>
                                                                                                                        {seatsStatusMap[availability.seat_status]}
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                            ))
                                                                                                        }
                                                                                                    >
                                                                                                        <Button
                                                                                                            key={slot.screenId}
                                                                                                            className="timeslot__button"
                                                                                                            data-test-id="time-slot"
                                                                                                            onClick={() => this.selectTimeSlot(slot.screenId, screen.theaterName, screen.screenName, slot.timeSlot)}
                                                                                                        >
                                                                                                            {convertTo12HourFormat(slot.timeSlot)}
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                ))
                                                                                            }
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    )

                                                                }) : (
                                                                    <Grid item xs={12}>
                                                                        <Box mt={3}>
                                                                            <Typography className="error__message" variant="h6">
                                                                                {configJSON.noDataAvailable}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </>
                                                    )

                                                }

                                            </Grid>
                                        </Container>
                                    </>
                                )
                            }
                        </Box>

                        <Box>
                            <FooterWithEvents />
                        </Box>

                    </BoxWrapper>
                    <RFHDialog
                        maxWidth="md"
                        showTitleDivider={true}
                        disableEscapeKeyDown={true}
                        data-test-id="terms-and-condition"
                        open={this.state.termsModel}
                        handleClose={this.termsModelClose}
                        dialogActions={(
                            <>
                                <DialogButton data-test-id="cancel-btn" variant='outlined' fullWidth onClick={this.termsModelClose}>
                                    {configJSON.cancel}
                                </DialogButton>
                                <DialogButton data-test-id="accept-btn" fullWidth onClick={this.acceptTerms}>
                                    {configJSON.accept}
                                </DialogButton>
                            </>
                        )}
                        dialogContent={(
                            <ContentWrapper data-test-id="terms-condition-content">
                                <Typography
                                    className='terms_conditions'
                                    dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionDialogContent }}>
                                </Typography>
                            </ContentWrapper>
                        )}
                        dialogTitle={(
                            <ContentWrapper>
                                <Typography className='terms_condition_title'>
                                    {configJSON.termsAndCondition}
                                </Typography>
                            </ContentWrapper>
                        )}
                    />
                </ThemeProvider>

                <RFHDialog
                    data-test-id="-payment-dialog"
                    open={this.state.isSoldOutOpen}
                    handleClose={() => {}}
                    maxWidth="xs"
                    disableEscapeKeyDown={true}
                    dialogTitle={(
                        <ContentWrapper>
                            <Typography className="soldout__title">{configJSON.SoldOut}</Typography>
                        </ContentWrapper>
                    )}
                    dialogActions={(
                        <RFHButton fullWidth onClick={() => {}}>
                            {configJSON.Proceed}
                        </RFHButton>
                    )}
                    dialogContent={(
                        <ContentWrapper>
                            <Typography className="soldout__description">{configJSON.SoldOutDescription}</Typography>
                        </ContentWrapper>
                    )}
                />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const DialogButton = styled(Button)({
    color: "#000",
    backgroundColor: "#ffb43a",
    borderRadius: "12px",
    fontSize: "18px",
    height: "56px",
    textTransform: "capitalize",
    "&:hover": {
        fontWeight: 600,
        backgroundColor: "#ffb43a"
    },
    "&.MuiButton-outlined": {
        fontWeight: 300,
        backgroundColor: "#373737",
        borderRadius: "12px",
        border: "solid 1px #ffb43a",
        marginRight: "24px",
        color: "#FFF",
    },
    "@media (max-width: 600px)": {
        height: "48px !important",
        fontSize: "14px !important",
        "&.MuiButton-outlined": {
            marginRight: "18px !important"
        }
    }
})

const ContentWrapper = styled(Box)({
    "& .terms_conditions": {
        fontSize: "16px",
        fontFamily: "Poppins",
    },
    "& .terms_condition_title": {
        textAlign: "center" as const,
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "Poppins, sans-serif",
    },
    "@media (max-width: 600px)": {
        "& .terms_conditions": {
            fontSize: "12px !important",
        },
        "& .terms_condition_title": {
            fontSize: "14px !important",
        }
    },
    "& .soldout__title": {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "Poppins",
        color: "#fff",
    },
    "& .soldout__description": {
        fontSize: "18px",
        fontFamily: "Poppins",
        color: "#fff"
    }
})

const BoxWrapper = styled(Box)({
    flexDirection: "column",
    display: "flex",
    minHeight: "100vh",
    "& .circular__wrapper": {
        display: "flex",
        justifyContent: "center",
        "& .circular__progress": {
            marginTop: "20px",
            color: "#ffb43a"
        },
    },
    "& .mobile__header": {
        padding: "12px",
        "& .back__button": {
            width: "24px",
            height: "24px"
        }
    },
    "& .hero__section__wrapper": {
        position: "relative",
        height: "340px",
        "& > img": {
            height: "100%",
            objectFit: "cover",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: -100
        },
        "&::before": {
            content: '""',
            position: "absolute",
            left: 0,
            top: "-1px",
            height: "101%",
            width: "100%",
            backdropFilter: "blur(6px)",
            background: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.9),rgba(0,0,0,0.8),rgba(0,0,0,0.4), rgba(0,0,0,0.1))",
            zIndex: -99,
        },
        "& .movie__wrapper": {
            position: "relative",
            height: "100%",
            "& .movie__title": {
                position: "absolute",
                bottom: "32px",
                left: 0,
                width: "100%",
                "& .movie__detail__wrapper": {
                    marginTop: "28px",
                    "& .category__name": {
                        fontSize: "14px",
                        border: "solid 0.5px #ffb43a",
                        padding: "6px",
                        borderRadius: "1px",
                        marginRight: "16px"
                    },
                }
            },
            "& .trailer__wrapper": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                "& .play-btn": {
                    width: "42px",
                    height: "42px"
                },
                "& .trailer-text": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: 500
                }
            },
        }
    },
    "& .filter__wrapper": {
        padding: "0.75rem 0",
        "& .date__carousel": {
            "@media (max-width: 600px)": {
                paddingLeft: "12px"
            }
        },
        "& .filter__child__wrapper": {
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
            gap: "24px",
            "& .search__icon": {
                height: '28px',
                width: '28px',
                cursor:"pointer",
            },
            "& .open__search": {
                height: '48px',
                maxWidth: "597px",
                width: "100%",
                borderRadius: '4px',
                backgroundColor: '#404040',
                padding: '0px 6px 0px 10px',
                display: 'flex',
                alignItems: 'center',
                position: "absolute",
                border: "solid 0.4px #979797",
                zIndex: 10
            },
            "& .search__input": {
                height: 48,
                maxWidth: "597px",
                border: 'none',
                width: "100%",
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                fontFamily: "Poppins",
                fontSize: '14px',
                margin: '0 0 0 8px',
                color: '#fff',
            },
            "& .close__icon": {
                height: 20,
                width: 20,
                right: 10,
                position: "absolute",
                cursor: "pointer",
            }
        },
    },
    "& .movie__timeslot__wrapper": {
        backgroundColor: "#404040",
        color: "white",
        borderRadius: "8px",
        marginTop: "20px",
        padding: "20px",
        "& img": {
            cursor: "pointer",
            width: "20px",
            marginRight: "16px"
        },
        "& .d__flex": {
            display: "flex",
            alignItems: "center",
            height:"min-content"
        },
        "& .theater__name": {
            fontFamily: "Poppins",
            fontSize: "1.25rem",
            fontWeight: 600,
            letterSpacing: "0.1px",
            color: "#FFF",
        },
        "& .time__wrapper": {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(114px, 134px))",
            gap: "1rem",
            borderRadius: "12px",
            border: "solid 1px #97979",
            backgroundColor: "#121212",
            padding: "24px",
            "& .timeslot__button": {
                padding: "16px 13px",
                minWidth: "114px",
                fontFamily: "Poppins",
                backgroundColor: "#2f2617",
                fontSize: "1.125rem",
                borderRadius: "8px",
                border: "solid 1px #ffb53a",
                color: "#FFF",
                "&:hover": {
                    backgroundColor: "#ffb53a",
                    color: "#000",
                }
            },
            "@media (max-width: 600px)": {
                padding: "18px",
                gridTemplateColumns: "1fr 1fr",
            }
        }
    },
    "& .error__message": {
        textAlign: "center"
    },
    "@media (max-width: 600px)": {
        "& .hero__section__wrapper": {
            height: "280px",
            "& .movie__wrapper": {
                "& .movie__title": {
                    marginBottom: "20px !important",
                    bottom: "0px !important",
                    "& .movie__detail__wrapper": {
                        display: "none",
                    }
                },
                "& .trailer__wrapper": {
                    "& .play-btn": {
                        width: "24px !important",
                        height: "24px !important"
                    },
                    "& .trailer-text": {
                        fontSize: "14px !important",
                    }
                },
            }
        }
    }
})
// Customizable Area End
