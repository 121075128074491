import React from "react";

import {
    // Customizable Area Start
    Container,
    Grid,
    Paper,
    Box,
    Typography,
    Button,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    InputAdornment,
    Fade,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";

// Customizable Area Start
import { ExpandLess, ExpandMore } from "@material-ui/icons";
const configJSON = require("./config");

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

});

export const SeatType = styled(Box)({
    textTransform: 'capitalize',
    fontFamily: "Poppins",
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 600

})
import { backarrowleft, location, close, car, phone, verified, food, cancelListing, InvalidStatusImage, greenIcon, upGreyIcon, redIcon, downGreyIcon } from "./assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import moment from "moment";
import KycDialogeBox from "../../cfwallet19/src/KycDialogeBox.web";
import IITOMovieEventTicketsInfo from "../../../components/src/ITOMovieEventTicketsInfo.web";

// Customizable Area End

import ViewTicketController, {
    Props,
} from "./ViewTicketController.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { Seat } from "../../../components/src/BookingSuccessfulTicket.web";
import MapDialog from "../../../components/src/MapDialog";
import { SeatIcon } from "../../reservations2/src/assets";




class ViewTicket extends ViewTicketController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    movieDetails = (classes: any, bookingAttributes: any, dateArray: any) => {

        return (
            <Box className={classes.viewBgs} >
                <Box className={classes.viewMainWrappers}>
                    <Box className={classes.ImageWrapper} >
                        <img src={bookingAttributes?.movie.image_url} alt="" className={classes.MovieImage} />
                    </Box>
                    <Box className={classes.viewMovieWrappers}>
                        <Typography component={'p'} className={classes.viewWrapperTypos} >{bookingAttributes?.movie.name}</Typography>
                        <Typography component={'p'}> {bookingAttributes?.language}, {bookingAttributes?.screen_type}</Typography>

                        <Box className={classes.viewBodyTypogrphys}>
                            <span>{moment(dateArray && dateArray[0]).format("ddd DD MMM,  YYYY")}
                                <span style={webStyle.dateSeparateLine}> | </span>
                                {dateArray && dateArray[1]}
                            </span>
                        </Box>
                        <Typography component={'p'}> {bookingAttributes?.theatre_name}</Typography>
                        <Typography component={'p'} className={classes.viewTypo}>  Release Date : {moment(bookingAttributes?.movie.release_date).format("DD MMM,  YYYY")}</Typography>
                        <Box className={classes.FindCinemaWrapper} onClick={this.handleOpenNavigation}>
                            <Icon>
                                <img src={location} className={classes.IconWrappers} width="18px" height="18px" />
                            </Icon>
                            <span className={classes.TheaterName}>{configJSON.FindCinema}</span>
                        </Box>
                    </Box>
                </Box>
                <IITOMovieEventTicketsInfo
                    applicationData={bookingAttributes?.booked_date}
                    screeningData={bookingAttributes?.movie.release_date}
                    ticketRequested={bookingAttributes?.number_of_seats}
                    ticketSold={bookingAttributes?.sold_ticket_seats}
                    ticketAlloted={bookingAttributes?.seat_number}
                    ticketListedToSell={bookingAttributes?.sold_ticket_seats}
                    bookingType={bookingAttributes?.booking_type}
                    isListToSell={bookingAttributes?.is_list_to_sell}
                    tier={bookingAttributes?.seat_tier[0]}
                    isInvalid = {bookingAttributes.is_invalid}
                />
            </Box>
        )
    }

    renderSeatSelection() {
        const { classes } = this.props;
        const { bookingData, seatID } = this.state
        const bookingAttributes = bookingData?.attributes
        return (
            <Box>
                <Box className={classes.viewMainWrappers} style={webStyle.cursorPointer} data-test-id="seats">
                    {bookingAttributes?.seat_id_number.map((seat: any) => (
                        <Box
                            className={`${classes.viewBoxBgs}`}
                            data-test-id={`seatSelect${seat.seat_id}`}
                            key={seat.seat_id}
                            onClick={() => this.handleSeatSelect(seat.seat_id)}
                            style={seatID[seat.seat_id] ? { background: "#FFB43A", color: "#121212" } : {}}
                        >
                            <Typography component={"span"}>
                                <SeatType >{seat.seat_number}</SeatType>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
    renderSelectedSeats() {
        const { classes } = this.props;
        const { bookingData } = this.state
        const bookingAttributes = bookingData?.attributes
        return (
            <Box display="flex" className={classes.SeatDisplay}>
                {bookingAttributes?.requests && bookingAttributes?.requests.map((seat: any) => (
                    <>
                        {seat.seats.map((seats: any) => (
                            <Box key={seats.seat_id}>
                                <Seat key={seats.seat_number} seatLabel={seats.seat_number} />
                            </Box>
                        ))}
                    </>
                ))}
                <Box ml={1} display="flex" alignItems="center" justifyContent="end">
                    <Typography component={"span"} data-test-id="edit-seat" onClick={()=>this.handleEditSeat()} className={classes.EditButtonAction}>Edit</Typography>
                </Box>
            </Box>
        );
    }
    renderContent() {
        const { classes } = this.props;
        const { isEdit } = this.state;

        if (isEdit) {
            return this.renderEditMode();
        } else {
            return (
                <Box>
                    <Box style={webStyle.flexCenter}>
                        <img src={verified} alt="check" className={classes.imageStyle} />
                    </Box>
                    <Box className={classes.viewSubeWrappers}>
                        Listing Cancelled!
                    </Box>
                </Box>
            );
        }
    }

    renderEditMode() {
        const { classes } = this.props;
        const { isSeatEdit } = this.state;

        return (
            <Box>
                <Box className={classes.isEditContent} >
                    <Typography component={"span"} className={classes.viewWrapperTypos}>Tickets Listed to Sell</Typography>
                    {isSeatEdit ? this.renderSeatSelection() : this.renderSelectedSeats()}
                    <Box className={classes.editPrice}>
                        <Typography component={"span"} className={classes.viewBodyTypogrphys}>Price Per Ticket</Typography>
                        <Box className={classes.textInput}>
                            {this.renderPriceInput()}
                            <Box className={classes.priceError} data-test-id="priceError" >
                                {this.state.priceError}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    renderPriceInput() {
        const { classes } = this.props;
        const { price } = this.state;

        return (
            <TextField
                data-test-id="price"
                type="test"
                variant="outlined"
                placeholder="Enter Price"
                value={price}
                className={classes.viewPrices}
                defaultValue={this.state.defaultPrice}
                onChange={(e: any) => this.handleChange(e)}
                onBlur={() => this.isPriceValids()}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <span className={classes.RupeesSymbols}>₹</span>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    renderButton() {
        const { classes } = this.props;
        const { bookingRequest } = this.state;
        const buttonText = bookingRequest?.length === 0 || bookingRequest === null ? 'List to Sell' : 'Cancel Listing';
        const onClickHandler = bookingRequest?.length === 0 || bookingRequest === null ? this.handleRedirectListTOsell : this.handleSubmit;

        return (
            <Box>
                <Button className={classes.viewBtns} disabled={this.isShowTime()} data-test-id={'view'} onClick={()=> onClickHandler()}>
                    {buttonText}
                </Button>
            </Box>
        );
    }

    renderTicketSold () {
        const { classes } = this.props;
        const { attributes: bookingAttributes } = this.state.bookingData;

        return( <Box display="flex" justifyContent="space-between" style={{ margin: "5px 0px" }}>
        <Typography className={classes.viewBodyTypogrphys}>Tickets Sold</Typography>
        <Box display="flex" className={classes.SeatDisplays}>
            {bookingAttributes?.sold_ticket_seats && bookingAttributes?.sold_ticket_seats.map((seat: string, index: number) => (
                <Box key={index}>
                    <Seat key={index} seatLabel={seat} />
                </Box>
            ))}
        </Box>
    </Box>)
    }

    renderTotalAmountDropdowm() {

        const { classes } = this.props;
        const { attributes: bookingAttributes } = this.state.bookingData;
        const isTicketInvalid = this.state.ticketinvalid === true;

        return (
            <List className={classes.PaymentWrapper}>
                <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>
                        <span className={classes.SpecialFontSize}>
                            Total Amount
                        </span>
                        <ListItemIcon className={classes.MenuExpandIcon} data-test-id="totalCost-dropdown" onClick={() => this.showCost()}>
                            {this.state.isCostOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItemText>
                    <span className={classes.SpecialFontSize}>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {bookingAttributes?.total_amount}
                    </span>
                </ListItem>
                <Collapse data-test-id="cost-collapse" in={this.state.isCostOpen} timeout="auto" unmountOnExit>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}> Price per Ticket</ListItemText>
                        <span>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {(+this.state.bookingData?.attributes?.sub_total / this.state.bookingData?.attributes?.number_of_seats).toFixed(2)}
                        </span>
                    </ListItem>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>Sub - Total</ListItemText>
                        <span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{bookingAttributes?.sub_total}</span>
                    </ListItem>

                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>
                            Convenience Fees
                            <ListItemIcon className={classes.MenuExpandIcon} data-test-id="cost-dropdown" onClick={() => this.showCostData()}>
                                {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemIcon>
                        </ListItemText>
                        <span>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {bookingAttributes?.convenience_fees}
                        </span>
                    </ListItem>

                    <Collapse data-test-id="cost-collapse" in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>
                        <List className={classes.SubList} component="div" disablePadding>
                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>Base Amount</ListItemText>
                                <span className={classes.SubListPayment}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {bookingAttributes?.base_amount}
                                </span>
                            </ListItem>
                            {this.checkForState(bookingAttributes?.theatre.state.toLowerCase()) ?
                                <ListItem className={classes.viewList}>
                                    <ListItemText className={classes.MenuListText}>
                                        {`IGST @ ${bookingAttributes?.cgst_percent + bookingAttributes?.sgst_percent}%`}
                                    </ListItemText>
                                    <span className={classes.SubListPayment}>
                                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                        {bookingAttributes?.igst}
                                    </span>
                                </ListItem> : <>
                                    <ListItem className={classes.viewList}>
                                        <ListItemText className={classes.MenuListText}>
                                            {`CGST @ ${bookingAttributes?.cgst_percent}%`}
                                        </ListItemText>
                                        <span className={classes.SubListPayment}>
                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {bookingAttributes?.cgst}
                                        </span>
                                    </ListItem>
                                    <ListItem className={classes.viewList}>
                                        <ListItemText className={classes.MenuListText}>
                                            {`SGST @ ${bookingAttributes?.sgst_percent}%`}
                                        </ListItemText>
                                        <span className={classes.SubListPayment}>
                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                            {bookingAttributes?.sgst}
                                        </span>
                                    </ListItem></>}
                        </List>
                    </Collapse>

                    {
                        this.state.foodCostCalculation.totalFoodAmount > 0 && (
                            <>
                                <ListItem className={classes.PaymentListItem}>
                                    <ListItemText className={classes.MenuListText}>
                                        Food & Beverages
                                        <ListItemIcon className={classes.MenuExpandIcon} data-test-id="food-cost-dropdown" onClick={this.showFoodCostData}>
                                            {this.state.isFoodOpen ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemIcon>
                                    </ListItemText>
                                    <span>
                                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                        {this.state.foodCostCalculation.totalFoodAmount.toFixed(2)}
                                    </span>
                                </ListItem>

                                <Collapse data-test-id="food-cost-collapse" in={this.state.isFoodOpen} timeout="auto" unmountOnExit>
                                    <List className={classes.SubList} component="div" disablePadding>
                                        {
                                            this.state.foodCostCalculation.Food.map(food => (
                                                <ListItem className={classes.viewList} key={food.menuId}>
                                                    <ListItemText className={classes.MenuListText}>{`${food.label} (Qty ${food.quantity})`}</ListItemText>
                                                    <span className={classes.SubListPayment}>
                                                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                        {food.price.toFixed(2)}
                                                    </span>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            </>
                        )
                    }
                    
                </Collapse>

                {!isTicketInvalid && <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>{configJSON.Discount}</ListItemText>
                    <span>
                        {`-`}
                        <span className={classes.RupeesSymbol}>&nbsp;{"\u20B9"}&nbsp;</span>
                        {`00.00`}
                    </span>
                </ListItem>}

            </List>
        )
    }

    renderTicketListedToSell () {

        const { classes } = this.props;
        const { attributes: bookingAttributes } = this.state.bookingData;
        const isTicketInvalid = this.state.ticketinvalid === true;

        if(!isTicketInvalid && this.state.bookingData?.attributes?.is_list_to_sell){
            return(
                <>
                <Divider className={classes.DashedDivider} />
                <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.viewBodyTypogrphys}>Tickets Listed to Sell</Typography>
                    <Box display="flex" className={classes.SeatDisplays}>
                        {bookingAttributes?.requests && bookingAttributes?.requests.map((seat: any) => (
                            <>
                                {seat.seats.map((seats: any) => {
                                    return (
                                        <Box key={seats.seat_id}>
                                            <Seat key={seats.seat_number} seatLabel={seats.seat_number} />
                                        </Box>
                                    );
                                })}

                            </>
                        ))}
                    </Box>
                </Box>
                <Divider className={classes.DashedDivider} />
                <Box>
                    <Box pb={2} display="flex" alignItems="center" justifyContent="end">
                        <span data-test-id="edit-info" onClick={() => { this.handleEditInfo() }} className={classes.EditButtonAction}>Edit</span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography component={"span"} className={classes.viewWrappe}>Tickets Listed to Sell</Typography>
                        <Box display="flex" className={classes.SeatDisplay}>
                            {bookingAttributes?.requests && bookingAttributes?.requests.map((seat: any) => (
                                <>
                                    {seat.seats.map((seats: any) => {
                                        return (
                                            <Box key={seats.seat_id}>
                                                <Seat key={seats.seat_number} seatLabel={seats.seat_number} />
                                            </Box>
                                        );
                                    })}
                                </>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.priceTicket}>
                    <span className={classes.viewWrappe}>Price per Ticket</span>
                    {bookingAttributes?.requests && bookingAttributes?.requests.map((seat: any) => (
                        <Typography component={"span"} key={seat.id}>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {seat.ticket_price && seat.ticket_price.toFixed(2)}
                        </Typography>
                    ))}
                </Box>
                <Box className={classes.priceTicket}>
                    <span className={classes.viewWrappe}>Total Amount</span>
                    {bookingAttributes?.requests && bookingAttributes?.requests.map((seat: any) => {
                        const finalPrice = seat.ticket_price * seat.seats.length
                        return (
                            <Typography component={"span"} key={seat.id}>
                                <span className={classes.RupeesSymbol}>{"₹"}&nbsp;</span>
                                {finalPrice !== undefined && finalPrice !== null && finalPrice.toFixed(2)}
                            </Typography>
                        )
                    })}
                </Box>
            </>
            )
        }

    }

    renderCancelBookingButton () {
        const {classes} = this.props;
        return (
            <Box className={classes.FullWidth}>
                <Box
                    className={classes.OfferAction}
                    data-test-id="cancel"
                    onClick={() => this.handleCancelBooking()}
                    style={{ opacity: this.isShowTime() || this.state.bookingData.attributes.is_resold_booking || this.state.bookingData.attributes?.booking_type === "ito" ? 0.5 : 1 }}
                > Cancel Booking</Box>
            </Box>
        )
    }

    renderDifferenceAmountForMovie(isProfit?: boolean, differencePercentage?: string) {

        const { classes } = this.props;

        return (
            <Box className={classes.differenceWrapper}>
                {configJSON.labelDifferenceAmount}
                <Box className={classes.differenceIconWrapper}>
                    <img className={classes.arrowIcon} src={isProfit ? greenIcon : upGreyIcon} alt="icon" />
                    <img className={classes.arrowIcon} src={isProfit ? downGreyIcon : redIcon} alt="icon" />
                </Box>
                <span className={isProfit ? classes.profitLabel : classes.lossLabel}>
                    {parseFloat(differencePercentage || "").toFixed(2)}%
                </span>
            </Box>
        )
    }


    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { bookingData, ticketinvalid } = this.state
        const bookingAttributes = bookingData?.attributes;
        const dateArray = bookingAttributes?.show_date_time?.split(" | ");

        const isTicketInvalid = ticketinvalid === true;

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0}>
                    <MainHeaderComp navigation={this.props.navigation} />
                    {this.state.loading ? (
                            <Fade in={this.state.loading} unmountOnExit>
                                <Box display="flex" justifyContent="center">
                                    <CircularProgress className={classes.CircularProgress} />
                                </Box>
                            </Fade>
                    ):
                    (<Box className={classes.viewWrapperBoxs} >
                        <Container  >
                            <Box className={classes.viewWrapperHeads} data-test-id="viewHead">
                                <img src={backarrowleft} alt="" style={webStyle.backErrow}
                                    data-test-id="backs" onClick={()=> this.handleBack()} />
                                <Typography component={'p'} data-testid="head" className={classes.viewWrapperTypos} >View Ticket</Typography>
                            </Box>

                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                    {this.movieDetails(classes, bookingAttributes, dateArray)}
                                </Grid>


                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <Box style={{ minHeight: 'auto' }} className={classes.viewBgs}>
                                        <Box className={classes.mainBoxWrapper}>
                                            <Typography component={'p'} className={classes.viewTypos}>  Booking Summary</Typography>
                                            <Box>
                                                <Box display="flex" justifyContent="center">
                                                  
                                                    <Box className={classes.BookingStatusLabel}>
                                                  { isTicketInvalid && (
                                                        <img src={InvalidStatusImage} width="261px" height="204px" />
                                                        )}
                                                    </Box>
                                                 
                                                    <img className={classes.QRwrapper} src={bookingAttributes?.qr_code} />
                                                </Box>
                                                <Box>
                                                    <Typography className={classes.Booking} align="center">Booking Id</Typography>
                                                    <Typography className={classes.BookingID} align="center">{bookingAttributes?.uniq_booking_id}</Typography>
                                                </Box>
                                            </Box>

                                            <Box>

                                                <Box display="flex" justifyContent="space-between">
                                                    <span className={classes.viewBodyTypogrphys}> {bookingAttributes?.screen_name}</span>
                                                    <Box display="flex" className={classes.SeatDisplay} >
                                                        {bookingAttributes?.seat_id_number.map((seat: any) => (
                                                            <Seat seatLabel={seat.seat_number} />
                                                        ))}
                                                    </Box>
                                                </Box>
                                                <Box pt={1} display="flex" justifyContent="space-between">
                                                    <span className={classes.ScreenInfo}>Screen</span>
                                                    <span className={classes.ScreenInfo}> Seats : {bookingAttributes?.seat_tier[0]}</span>
                                                </Box>
                                            </Box>
                                            <Divider className={classes.Dividers} />
                                            <Box>
                                                {this.renderTotalAmountDropdowm()}
                                            </Box>

                                            {this.renderTicketListedToSell()}

                                            {!isTicketInvalid ? <>

                                                <Divider className={classes.Dividers} />

                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <Box className={classes.IconWrappe}>
                                                        <img className={classes.IconImage} src={car} alt="Offer Icon" />
                                                        <Box className={classes.FullWidth}>
                                                            <Box className={classes.OfferAction}> Book a Cab</Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.IconWrappe}>
                                                        <img className={classes.IconImage} src={food} alt="Offer Icon" />
                                                        <Box className={classes.FullWidth}>
                                                            <Box className={classes.OfferAction}> Grab a Bite</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider className={classes.Dividers} />
                                                <Box className={classes.boxContainer}>
                                                    <Box className={classes.IconWrappe}>
                                                        <img className={classes.IconImage} src={phone} alt="Offer Icon" />
                                                        <Box className={classes.FullWidth}>
                                                            <Box className={classes.OfferAction} data-test-id="contact-us" onClick={()=> this.handleNavigate()}> Contact Us</Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.IconWrappe}
                                                        style={(!this.state.isBookingCalled || this.isShowTime() || bookingAttributes?.booking_type === "ito") ? { opacity: '0.5', cursor: "not-allowed" } : {}}
                                                    >
                                                        <img className={classes.IconImage} src={cancelListing} alt="Offer Icon" />
                                                        {this.renderCancelBookingButton()}
                                                    </Box>
                                                </Box>

                                                <Divider className={classes.DashedDivider} />

                                                {this.renderButton()}

                                                <Divider className={classes.DashedDivider} />

                                                <Typography className={classes.ticketText} align="center">{configJSON.TicketMessage}</Typography>
                                            </> : <>
                                                        {
                                                            bookingAttributes.child_bookings && (
                                                                <>
                                                                    <Typography className={classes.ScreenInfo} style={{ marginTop: 15 }} align="center">{configJSON.InvalidTicketMessage}</Typography>
                                                                    <Typography data-test-id="newLink" align="center" className={classes.EditButtonAction} onClick={() => this.handleClickNewTicket()}>Link of new ticket.</Typography>
                                                                </>
                                                            )
                                                        }
                                                <Divider className={classes.DashedDivider} />
                                                {this.renderTicketSold()}
                                                <Divider className={classes.DashedDivider} />
                                                <Typography className={classes.viewBodyTypogrphys} style={{marginBottom:10}}> Transaction Details</Typography>
                                                {this.renderTicketSold()}

                                                <List className={classes.PaymentWrapper}>
                                                    <ListItem className={classes.PaymentListItem}>
                                                        <ListItemText className={classes.MenuListText}>
                                                            <span className={classes.SpecialFontSize}>
                                                                Transaction Amount
                                                            </span>
                                                            <ListItemIcon onClick={() => this.showTransectionData()} className={classes.MenuExpandIcon} data-test-id="totalCost-dropdown-invalid">
                                                                {this.state.isTransferAmount ? <ExpandLess /> : <ExpandMore />}
                                                            </ListItemIcon>
                                                        </ListItemText>
                                                        <span className={classes.SpecialFontSize}>
                                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                            {bookingAttributes.transaction_details?.trans_amount}
                                                        </span>
                                                    </ListItem>

                                                    <Collapse data-test-id="cost-collapse" in={this.state.isTransferAmount} timeout="auto" unmountOnExit>
                                                        <List className={classes.SubList} component="div" disablePadding>
                                                            <ListItem className={classes.viewList}>
                                                                <ListItemText className={classes.MenuListText}>Sold @</ListItemText>
                                                                <span>
                                                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                    {bookingAttributes.transaction_details?.sold}
                                                                </span>
                                                            </ListItem>

                                                            <ListItem className={classes.viewList}>
                                                                <ListItemText className={classes.MenuListText}>
                                                                    Purchased @
                                                                </ListItemText>
                                                                <span>
                                                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                    {bookingAttributes.transaction_details?.purchased}
                                                                </span>
                                                            </ListItem>
                                                            <ListItem className={classes.viewList}>
                                                                        <ListItemText className={classes.MenuListText}>
                                                                            {this.renderDifferenceAmountForMovie(bookingAttributes.transaction_details?.is_profit, bookingAttributes.transaction_details?.diff_percent)}
                                                                        </ListItemText>
                                                                        <span>
                                                                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                            {bookingAttributes.transaction_details?.diff_amount && (parseFloat(bookingAttributes.transaction_details?.diff_amount).toFixed(2))}
                                                                        </span>
                                                            </ListItem>
                                                            <ListItem className={classes.viewList}>
                                                                <ListItemText className={classes.MenuListText}>
                                                                    Service Fee @ {bookingAttributes.transaction_details?.service_percent}% of <br /> Difference Amount
                                                                </ListItemText>
                                                                <span>
                                                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                    {bookingAttributes.transaction_details?.service_fee}
                                                                </span>
                                                            </ListItem>
                                                        </List>
                                                    </Collapse>
                                                </List>

                                                    <Box className={classes.priceTicket}>
                                                        <span className={classes.viewWrappe}>{configJSON.TransferToWalletText}</span>
                                                        <Typography component={"span"}>
                                                            <span className={classes.RupeesSymbol}>{"₹"}&nbsp;</span>
                                                            {bookingAttributes.transaction_details?.amount_credit_to_wallet}
                                                        </Typography>
                                                    </Box>

                                                <Box className={classes.IconWrappe} style={{ marginTop: 20 }}>
                                                    <img className={classes.IconImage} src={phone} alt="phone Icon" />
                                                    <Box className={classes.FullWidth}>
                                                        <Box className={classes.OfferAction} data-test-id="contact-us"
                                                            onClick={this.handleNavigate}
                                                        > Contact Us</Box>
                                                    </Box>
                                                </Box>
                                            </>}
                                        </Box>

                                    </Box>
                                </Grid>
                            </Grid>

                        </Container>
                    </Box>
        )}
                    <FooterWithEvents />
                </Paper>


                <Dialog
                    open={this.state.isModalOpen}
                    PaperProps={{ style: webStyle.dialogProps}}
                >
                    <Box style={webStyle.flexEnd}>
                        <img src={close} alt="closebutton"
                            color="primary"
                            data-test-id="closeModal"
                            className={classes.viewCloseIcons}
                            onClick={this.handleModalClose}
                        />
                    </Box>
                    <DialogContent>
                        {this.renderContent()}
                    </DialogContent>
                    <DialogActions >
                        <Button 
                            className={classes.viewDialogBtns} 
                            data-test-id="navigate" 
                            onClick={()=> this.handleUpdateSubmit()}
                        >{this.state.isEdit ? "Confirm" : "Done"}</Button>
                    </DialogActions>

                </Dialog>

                <MapDialog
                    data-test-id="map-dialog"
                    open={this.state.openNavigationDialog}
                    handleClose={this.handleDialogClose}
                    title={this.state.bookingData.attributes?.theatre_name || ''}
                    destinationAddress={this.state.bookingData.attributes?.theatre?.complete_address || ''}
                    fullWidth
                />

                {this.state.isKycApproved && 
                    <KycDialogeBox
                        data-test-id = "crossBtn"
                        navigation={this.props.navigation} 
                        id="seat-selection-kyc" 
                        handleOnClose={this.handleOnCloseKyc} 
                    />
                }

            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    dialogProps : {
        backgroundColor: "#404040",
        color: "#fff",
        padding: '12px'
    },
    flexEnd: { 
        display: "flex", 
        justifyContent: "end" 
    },
    dateSeparateLine: { 
        color: '#ffb43a' 
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    flexCenter: { 
        display: "flex", 
        justifyContent: "center" 
    },
    backErrow: { 
        width: '32px', 
        height: '32px', 
        cursor: "pointer" 
    }
}

const styles = () => ({
    selected: {
        background: "#FFB43A",
        color: "#121212",
    },
    textInput: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column'
    },
    boxContainer: {
        display: "flex",
        alignItems: "center",
        margin: "0px 25px 0px 16px",
        justifyContent: "space-between",
        "@media (min-width: 960px) ": {
            margin: "0px 16px 0px 16px",
        },
    },
    viewList: {
        '&.MuiListItem-gutters': {
            padding: '0px'
        }
    },
    priceError: {
        fontFamily: 'Poppins',
        color: ' red',
        fontSize: '16px',
    },
    editPrice: {
        margin: '7px auto',
        display: 'flex',
        flexDirection: 'column',
        gridGap: '12px',
        alignItems: 'center',

    },
    RupeesSymbol: {
        fontFamily: 'Roboto'
    },
    isEditContent: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: 'center',
        gridGap: '10px'
    },
    MovieImage: {
        width: '100%',
        height: '100%',
    },
    ImageWrapper: {
        width: "179px",
        height: "229px",
        border: "solid 1px #ffb53a",
        marginRight: "18px",
        "@media (max-width: 660px)": {
            width: "130px",
            height: "174px",
        },
        "@media (max-width: 560px)": {
            marginRight: "12px",
            width: "110px",
            height: "130px",
        },
    },
    imageStyle: {
        width: "48px",
        heigth: " 48px"
    },
    OfferAction: {
        color: "#ffb43a",
        fontSize: "18px",
        cursor: 'pointer'
    },
    IconWrappe: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "12px",
        gridGap: '8px'
    },
    IconImage: {
        width: "22px",
        height: "22px",
    },
    priceTicket: {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0"
    },
    EditButtonAction: {
        fontSize: "16px",
        color: "#ffb53a",
        cursor: "pointer",
    },
    ticketText: {
        color: "#b4b4b4",
    },
    mainBoxWrapper: {
        display: "flex",
        flexDirection: 'column',
        position: "relative",
        height: '95%'
    },
    MenuExpandIcon: {
        color: '#ffb43a',
        cursor: "pointer",
    },
    MenuListText: {
        display: "inline-flex",
        alignItems: "center",
        fontSize: "16px",
        "& span": {
            display: 'flex',
            gap: "8px",
            alignItems: 'center',
        },
    },
    PaymentListItem: {
        padding: "4px 0",
        maxHeight: "36px",
        "& span": {
            fontSize: "16px",
            lineHeight: 1,
        },
        "&:last-child": {
            paddingBottom: 0,
        },
        "&:first-child": {
            paddingTop: 0,
        },
    },
    SubListPayment: {
        fontSize: "16px",
        lineHeight: 1,
    },
    PaymentWrapper: {
        padding: 0,
    },
    ScreenInfo: {
        color: "#b4b4b4",
        fontSize: "16px",
        textTransform: 'capitalize',
    },
    SeatDisplay: {
        gap: "8px",
        maxWidth: "192px",
        flexFlow: "wrap",
    },
    SeatDisplays: {
        gap: "8px",
        maxWidth: "192px",
        flexFlow: "wrap",
    },
    Dividers: {
        margin: "24px 0",
        backgroundColor: "#979797",
    },
    Booking: {
        color: "#fff",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif"
    },
    BookingID: {
        color: "#fff",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500
    },
    QRwrapper: {
        marginTop: "18px",
        marginBottom: "16px",
        height: "80px",
        width: "80px",
        "@media (min-width: 600px)": {
            marginTop: "24px",
            height: "132px",
            width: "132px",
        }
    },
    IconWrapper: {
        display: "flex",
        gridGap: '15px'
    },
    TicketIcon: {
        width: "24px",
        height: "22px",
    },
    TheaterName: {
        color: "#ffb636",
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
    },
    FindCinemaWrapper: {
        display: "flex",
        alignItems: "center",
        maxWidth: "150px",
        gap: "4px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    IconWrappers: {
        width: "18px",
        height: "22px"
    },
    Divider: {
        height: 0,
        boxSizing: 'border-box' as const,
        borderBottom: "dotted 1.5px #f0f6f7",
        margin: "20px 0",
        opacity: '0.6'
    },
    RupeesSymbols: {
        fontFamily: 'Roboto'
    },
    viewWrapperBoxs: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#fff',
        marginTop: '12px',

    },
    customDividers: {
        backgroundColor: "#979797",
    },
    viewTilteHeadBoxs: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    viewDialogBoxs: {
        fontSize: '18px',
        fontFamily: 'Poppins',
        width: '392px',
        height: '25px',
        margin: '12px 0px 0px 0px',
        textAlign: 'center',
        color: '#fff'
    },
    viewDialogBtns: {
        width: '400px',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: '#202020',
        fontSize: '18px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',

        }

    },
    viewCloseIcons: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        height: '28px',
        width: '28px',
        cursor: 'pointer',
    },
    viewTitleWrappers: {
        width: '330px',
        height: '33px',
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center',
        margin: '4px 1px 0px 0px',

    },
    viewSubeWrappers: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '22px',
        fontWeight: 600,
        textAlign: 'center',
        marginTop: '28px',
    },
    mainWrapp: {
        padding: "26px 106px",
        maxWidth: '1920',
    },
    viewMainWrappers: {
        display: 'flex',
        gridGap: '5px'
    },
    viewWrapperHeads: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '15px',
        marginBottom: '22px'
    },
    viewWrapperTypos: {
        fontSize: '24px',
        fontWeight: 600
    },
    viewWrappe: {
        fontSize: '18px',
    },
    viewBodyTypogrphys: {
        fontSize: '18px',
        fontWeight: 600
    },
    viewsWrapperBoxs: {
        minHeight: '100vh',
    },
    viewMovieWrappers: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '8px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
    viewPrices: {
        width: '146px',
        height: '56px',
        border: 'solid 1px #fff',
        backgroundColor: '#404040',
        borderRadius: '12px',
        fontFamily: "Poppins",
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '38px'
        }
    },
    viewTypos: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 600,
        color: "#b4b4b4"
    },
    viewTypo: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: "#b4b4b4",
        opacity: '0.6'
    },
    viewTexts: {
        width: '122px',
        height: '33px',
        margin: '32px 582px 32px 24px',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: '0.12px',
        textAlign: 'center' as const,
        color: '#fff',
    },
    viewSeatBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '30px'
    },
    viewBgs: {
        backgroundColor: 'rgba(64,64,64,0.4)',
        padding: '26px',
        "@media (max-width: 600px)": {
            padding: "14px"
        }
    },
    viewBoxBgs: {
        backgroundColor: '#404040',
        width: '28px',
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'solid 1px #615e5e',
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: "Poppins",
        borderRadius: "4px",
        color: "#fff",
        "&.selected": {
            backgroundColor: '#404040',

        },
        ".selected": {
            background: "#FFB43A",
            color: "#121212",
        },
    },
    SpecialFontSize: {
        fontSize: "18px !important",
    },
    DashedDivider: {
        margin: "27px 0",
        background: "repeating-linear-gradient(to right, transparent, transparent 3px, #b1b1b1 3px, #b1b1b1 8px)"
    },
    viewBtns: {
        width: '100%',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        color: '#202020',
        fontSize: '18px',
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',

        }

    },
    BookingStatusLabel: {
        position: 'absolute',
        top: "35px",
        right: "0%",
        transform: "translate(-30%)"
    },
    DisplayFlex: {
        display: "flex",
        justifyContent: "space-between"
    },
    ScreenLabel: {
        fontSize: "18px",
        color: "#fff",
        fontFamily: "Poppins",
        textTransform: "capitalize" as const,
        marginBottom: 4
    },
    differenceWrapper: {
        alignItems: "center",
        display: "flex",
    },
    differenceIconWrapper: {
        display: "flex",
        flexDirection: "column" as const,
        margin: "0 6px 0 8px",
        gap: "3px",
    },
    arrowIcon: {
        width: "10px",
        height: "7px",
    },
    lossLabel: {
        color: "#ff3b30"
    },
    profitLabel: {
        color: "#5ed808"
    }
})
//@ts-ignore
export default (withStyles(styles)(ViewTicket))
export { ViewTicket }
// Customizable Area End
