import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import AccountDetailsAdapter from "../../adapters/src/AccountDetailsAdapter";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
const AccountDetailsAdapterObj = new AccountDetailsAdapter();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean,
  beneficiaryList:any,
  beneficiaryDetailsList:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BankAccountsListController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    beneficiaryListApi = ""
    beneficiaryDetailsListApi = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
              loading:false,
              beneficiaryList:[],
              beneficiaryDetailsList:[]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          if (apiRequestCallId === this.beneficiaryListApi) {
            responseJson.map((elm: any) => this.getBeneficiaryDetails(elm.beneficiary_id));
            responseJson.length == 0  && this.setState({ loading: false });
          }
          if (apiRequestCallId === this.beneficiaryDetailsListApi) {
            responseJson.status === "SUCCESS" && this.setState({beneficiaryDetailsList:[responseJson.data]})
            this.setState({ loading: false });
          }
        }
      }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
      window.scrollTo(0,0);
      this.getBeneficiaryList();
    }

    getBeneficiaryList = async () => {
      this.setState({ loading: true })
      this.beneficiaryListApi = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: configJSON.beneficiaryListEndPoint,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    getBeneficiaryDetails = async(beneficiary_id:any) => {
      this.setState({ loading: true })
      this.beneficiaryDetailsListApi = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: configJSON.beneficiaryDetailsEndPoint+beneficiary_id,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    handleBackBtn = () => {
      this.props.navigation.goBack();
    }

    navigateToAccountDetails = () => {
      const msg = new Message(getName(MessageEnum.NavigationToAccountDetails))
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      AccountDetailsAdapterObj.send(msg)
    }

  navigateToPaymentInfo = (beneficiary_id: any) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PaymentInfo");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), beneficiary_id);
    this.send(msg);
  }
    // Customizable Area End
}
