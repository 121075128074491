import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { DirectionsRenderer, GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'

const API_KEY = "AIzaSyD_IKMpAMPww2DYwlFn52uKrw48piXHgA4"

interface ICoordinates {
  lat: number
  lng: number
}

interface ICustomMapProps {
  destinationAddress: string
}

interface IMapMarkerProps {
  googleMapURL: string
  loadingElement: React.ReactElement
  containerElement: React.ReactElement
  mapElement: React.ReactElement
  userCurrentLocation: ICoordinates
  destinationAddress?: string
}

const MapWithAMarker: React.ComponentClass<IMapMarkerProps> = withScriptjs(withGoogleMap(({ userCurrentLocation, destinationAddress }) => {

  const [directions, setDirections] = useState(null);
  let lastLocation: ICoordinates;

  useEffect(() => {

    const geocoder = new (window as any).google.maps.Geocoder();

    geocoder.geocode({ address: destinationAddress }, (results: any, status: any) => {
      if (status === 'OK' && results && results.length > 0) {
        const location = results[0].geometry.location;
        lastLocation = { lat: location.lat(), lng: location.lng() }

        const directionsService = new (window as any).google.maps.DirectionsService();

        directionsService.route(
          {
            origin: userCurrentLocation,
            destination: lastLocation,
            travelMode: 'DRIVING',
          },
          (result: any, status: any) => {
            if (status === 'OK') {
              setDirections(result);
            } else {
              setDirections(null)
            }
          }
        );

      }
    });

  }, [userCurrentLocation])

  return (
    <GoogleMap
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >

      {directions &&
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: 'red',
            },
          }}
        />
      }

    </GoogleMap>
  )
}

));


const CustomMap: React.FC<ICustomMapProps> = ({ destinationAddress }) => {

  const [currentLocation, setCurrentLocation] = useState<ICoordinates>({} as ICoordinates)
  const [isGeoLocationBlocked, setIsGeoLocationBlocked] = useState<boolean>(false);

  function getLocation(): Promise<ICoordinates> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            setIsGeoLocationBlocked(false)
            resolve({ lat, lng });
          },
          function (error) {
            reject(new Error(`Error getting location: ${error.message}`));
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by your browser'));
      }
    });
  }

  useEffect(() => {
    getLocation().then((location: ICoordinates) => setCurrentLocation(location)).catch(() => setIsGeoLocationBlocked(true))
  }, [])

  return (
    <>
    {
        isGeoLocationBlocked ? (
          <Box display="flex" alignItems="center" justifyContent="center" minHeight="300px">
            <h3>Geolocation is blocked</h3>
          </Box>
        ) : (
          <Box sx={{ width: "100%", height: "100%" }}>
            {
              currentLocation && (
                <MapWithAMarker
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  userCurrentLocation={currentLocation}
                  destinationAddress={destinationAddress}
                />
              )
            }
          </Box>
        )
    }
    </>
  )
}

export default CustomMap