import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area Start
import { IMovieData, IMovieDetailsResponse, IMoviesItoFilter, IScreeningResponse, ITermsConditionResponse } from "../../../components/src/interfaces.web";
import { getLastPartOfURL } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import moment from "moment";
import { SelectOption } from "../../../components/src/SelectDropdown.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
interface AuctionData  {
  id?: string;
  type?: string;
  attributes?: Attributes | undefined;
}

interface Attributes {
  product_name?: string;
  description?: string;
  start_time: string;
  end_time?:  string;
  minimum_bid?: string;
  product_images?: [];
  maximum_bid_placed?: string;
  time_remaining? : string | null;
}

interface BiddingList {
  id?: string;
  type?: string;
  attributes?: BiddingListAttributes;
}

interface BiddingListAttributes {
  amount?: number;
  created_at?: Date;
  updated_at?: Date;
  user?: {
    id?: string;
    type?: string;
    attributes: {
        full_name: string;
        profile_image: {
            url?: string
          };
        updated_at: Date
    }
  }
}

interface IAvailability {
  seat_type: string,
  price: number,
  seat_status: string,
}
interface IScreeningData {
  type: string;
  id: string;
  attributes: {
    theatre_name: string;
    screen_name: string;
    slot_time: string;
    theatre_id: number;
    slot_date: string;
    is_favourite:boolean;
    availability: IAvailability[]
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedTab : number;
  // auctionType : number;
  // auctionId: number;
  auctionId: string | undefined;
  index: number;
  auctionData?: AuctionData;
  biddingList: Array<BiddingList>;
  openPlaceBidModal: boolean;
  bidingAmount: string;
  error:string;
  placeBidConfirmModal: boolean;

  movieId: string,
  location: string,
  isDataLoading: boolean
  movieData: IMovieData
  filterData: {
    languageId: number,
    date: Date,
    formatId: number,
  },
  languageOptions: SelectOption[],
  formatOptions: SelectOption[],
  isSearchOpen: boolean,
  isTimeSlotLoading: boolean,
  timeSlots: {
    screenName: string;
    theaterName: string;
    theatreId: number;
    isFavourite: boolean;
    timeSlots: {
      timeSlot: string;
      screenId: number;
      availability: IAvailability[];
    }[];
  }[],
  totalTimeSlots: {
    screenName: string;
    theaterName: string;
    theatreId: number;
    isFavourite: boolean;
    timeSlots: {
      timeSlot: string;
      screenId: number;
      availability: IAvailability[];
    }[];
  }[],
  searchText: string,
  isTermsAndConditionOpen: boolean;
  termsAndConditionContent: string;
  selectedTimeSlotDetail: {
    screenName: string,
    screenId: number,
    theaterName: string,
    selectedTimeSlot: string,
  },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AuctionBiddingDetailController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAuctionDetailCallId : string = "";
    getBiddingListCallId: string = "";
    placeBidCallId: string = "";
    getMovieDataCallId: string = "";
    movieSlotApiCallId: string = "";
    termsAndConditionCallId: string = "";
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      // Customizable Area Start
      // Customizable Area End
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage)
        // Customizable Area Start
        // Customizable Area End
      ];
      
      let auction_id_from_url = window?.location?.pathname.split("/").pop()
      this.state = {
        // Customizable Area Start
        selectedTab: 0,
        
        auctionId: auction_id_from_url,
        index: 0,
        auctionData: {},
        biddingList: [],
        openPlaceBidModal: false,
        bidingAmount: "",
        error:"",
        placeBidConfirmModal: false,

        movieId: "",
        location: "",
        isDataLoading: true,
        isTimeSlotLoading: true,
        movieData: {} as IMovieData,
        filterData: {
          date: this.initialDate(),
          languageId: 0,
          formatId: 0,
        },
        languageOptions: [],
        formatOptions: [],
        isSearchOpen: false,
        timeSlots: [],
        totalTimeSlots: [],
        searchText: "",
        isTermsAndConditionOpen: false,
        termsAndConditionContent: "",
        selectedTimeSlotDetail: {
          theaterName: '',
          screenId: -1,
          screenName: '',
          selectedTimeSlot: '',
        },
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  
      // Customizable Area Start
      // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        this.getAuctionDetailData();
        this.getBiddingList();

        window.scrollTo(0, 0);

        let movieId = getLastPartOfURL();
        const selectedData = (await getStorageData(LocalStorageKeys.MoviesItoFilter, true) as IMoviesItoFilter)
        const location = await getStorageData(LocalStorageKeys.Location) as string

        this.setState((prevState) => ({
          ...prevState,
          movieId: movieId,
          location: location,
          filterData: {
            ...prevState.filterData,
            languageId: selectedData.selectedLanguageId,
            formatId: selectedData.selectedFormatId
          }
        }), () => {
          this.getTimeSlotsByMovieId();
          this.getMovieDataCallId = callApi({
            contentType: configJSON.ContentType,
            method: configJSON.GetMethod,
            endPoint: (configJSON.GetMovieITOApiCallId as string).replace("{MOVIE_ID}", `${movieId}`),
            headers: { "token": configJSON.GuestToken }
          }, runEngine)

          this.termsAndConditionCallId = callApi({
            contentType: configJSON.ContentType,
            method: configJSON.GetMethod,
            endPoint: configJSON.termsAndConditionApiEndPoint,
            headers: { "token": configJSON.GuestToken }
          }, runEngine)
        })
        // Customizable Area End
        
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        runEngine.debugLog("API Message Recived", message);
        
        if (responseJson && !responseJson.errors) {
          //istanbul ignore next
          if (apiRequestCallId === this.getAuctionDetailCallId) {
            this.setState({auctionData : responseJson.data })
          }
          //istanbul ignore next
          if(apiRequestCallId === this.getBiddingListCallId) {
            if(responseJson.data) {
              this.setState({ biddingList: responseJson.data.reverse() })
            }
          }
          //istanbul ignore next
          if(apiRequestCallId === this.placeBidCallId) {
            alert("Bid place successfully")
            this.getAuctionDetailData();
            this.getBiddingList();
          }

          switch(apiRequestCallId) {
            case this.getMovieDataCallId:
              const languageOptions =  (responseJson as IMovieDetailsResponse).data.attributes.languages.map((language: { "language_id": string | number, "language_name": string }) => ({
                value: language['language_id'],
                label: language['language_name'],
              }));
              const formatOptions =  (responseJson as IMovieDetailsResponse).data.attributes.screen_types.map((screen: { "screen_type_id": string | number, "screen_type": string }) => ({
                value: screen['screen_type_id'],
                label: screen['screen_type'],
              }))
              this.setState({ isDataLoading: false, movieData: (responseJson as IMovieDetailsResponse).data, languageOptions, formatOptions })
              break;

            case this.movieSlotApiCallId:
              this.generateTimeSlotFromData(responseJson);
              break;

            case this.termsAndConditionCallId:
              this.setState({ termsAndConditionContent: (responseJson as ITermsConditionResponse).data.description })
              break;

            default:
              break;
          }

        } //istanbul ignore next
        else if (responseJson && responseJson.errors) {
          if (responseJson.errors) {
            if (apiRequestCallId === this.getAuctionDetailCallId) {
              
            }
            if(apiRequestCallId === this.getBiddingListCallId) {

            }
            if(apiRequestCallId === this.placeBidCallId) {

            }
          }
        }
      }
      // Customizable Area End
    }

    // Customizable Area Start
    apiCall = async(data: {contentType: string,method: string, endPoint: string,body?:object, type?: string }) => {
      const { contentType, method, endPoint, body, type } = data;
      
      //istanbul ignore next
      const token = (await StorageProvider.get("TOKEN")) || "";
      
      // const token = `eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDkwLCJleHAiOjE2NzcxMzQ2NjMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.lDA5I5aqPIZ9pTPE3FcoOcMGBv2gZ9XSS0qB4A0d_IUu51Cd40Ov6qK2Pm5oZ_kYpwpacedcXFB9Bb0PQ72snQ`
      const header = {
        "Content-Type": contentType,
        token : type != "login" ? token : ""
      };
      //istanbul ignore next
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      //istanbul ignore next
      body && 
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
       //istanbul ignore next
      runEngine.sendMessage(requestMessage.id, requestMessage);
      //istanbul ignore next
      return requestMessage.messageId;
    }

    getAuctionDetailData = async() => {
      this.getAuctionDetailCallId = await this.apiCall({
        contentType: configJSON.getAuctionDetailApiContentType,
        method: configJSON.getAuctionDetailApiMethod,
        endPoint: configJSON.getAuctionDetailApiEndPoint + `/${this.state.auctionId}`,
        
      });
    }
  
    getBiddingList = async() => {
      this.getBiddingListCallId = await this.apiCall({
        contentType: configJSON.getBiddingApiContentType,
        method: configJSON.getBiddingApiMethod,
        endPoint: configJSON.getBiddingApiEndPoint + `?auction_id=${this.state.auctionId}`,
        
      });
    }

    onTabChange = (event: object, newValue: number) => {
      this.setState({ selectedTab: newValue})
    };
    
    handleTextChange = (value: string) => {
      this.setState({ bidingAmount: value })
    }

    handlePlaceBid = () => {
      this.setState({ openPlaceBidModal: !this.state.openPlaceBidModal})
    }

    handlePlaceBidConfirmModal = () => {

      if(this.state.bidingAmount.length > 0){
        this.setState({ 
          error: '',
          openPlaceBidModal: false, 
          placeBidConfirmModal: !this.state.placeBidConfirmModal 
        });
      } else {
        this.setState({ error: "Amount is require"})
      }
    }

    handleConfirmation = () => {
      this.placeBid();
      this.setState({ placeBidConfirmModal: !this.state.placeBidConfirmModal })
    }

    handleCancelConfirmModal = () =>  {
      this.setState({ placeBidConfirmModal: false })
    }
    
    placeBid = async() => {
  
      const httpBody = {
        amount: this.state.bidingAmount,
        auction_id: this.state.auctionId,
      };
      
      this.placeBidCallId = await this.apiCall({
        contentType: configJSON.placeBidApiContentType,
        method: configJSON.placeBidApiMethod,
        endPoint: configJSON.placeBidApiEndPoint,
        body: httpBody,
      });

    }

    initialDate = () => moment(new Date()).startOf('day').toDate()

    handleDateChange = (date: Date) => {
      this.setState((prevState) => ({
        filterData: {
          ...prevState.filterData,
          date,
        }
      }), () => this.getTimeSlotsByMovieId())
    }

    handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState((prevState) => ({
        filterData: {
          ...prevState.filterData,
          languageId: event.target.value as number,
        }
      }), () => this.getTimeSlotsByMovieId())
    }

    handleFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState((prevState) => ({
        filterData: {
          ...prevState.filterData,
          formatId: event.target.value as number,
        }
      }), () => this.getTimeSlotsByMovieId())
    }

    openSearch = () => this.setState({ isSearchOpen: true })
    closeSearch = () => this.setState({ isSearchOpen: false })

    handleSearchChange = (value: string) => {
      const filteredTimeSlots = this.state.totalTimeSlots.filter((slot) => slot.theaterName.toLowerCase().includes(value.toLowerCase()));
      this.setState({ timeSlots: filteredTimeSlots, searchText: value });
    }

    redirectToTrailer = () => {

      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Trailers");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.movieData.id);
      this.send(message);

    }

    getTimeSlotsByMovieId = async () => {

      this.setState({ isTimeSlotLoading: true })
      const {
        movieId,
        location,
        filterData: {
          date,
          languageId,
          formatId
        }
      } = this.state;
  
      this.movieSlotApiCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.GetMethod,
        endPoint: `${configJSON.timeSlotApiEndPoint}?language_id=${languageId}&location=${location}&screen_type_id=${formatId}&slot_date=${moment(date).format("YYYY-MM-DD")}&movie_id=${movieId}`,
        headers: { "token": configJSON.GuestToken },
      }, runEngine)
    }

    generateTimeSlotFromData = (responseJson: IScreeningResponse) => {

      const screeningByScreen: {
        [key: string]: {
          timeSlot: string,
          availability: IAvailability[],
          screenId: number,
          theatreId: number,
          screenName: string,
          isFavourite: boolean,
        }[]
      } = {};
  
      // loop through data array and group the screenings by screen_name
      responseJson.data.forEach((screening: IScreeningData) => {
        const { id, attributes } = screening;
        const { screen_name, theatre_id, slot_time, theatre_name, availability, is_favourite, slot_date } = attributes;
  
        // check this slot is past time or not
        let currentDateTime = moment(moment().format('YYYY-MM-DD HH:mm'),'YYYY-MM-DD HH:mm');
        const slotDateTime = moment(`${slot_date} ${slot_time}`,'YYYY-MM-DD HH:mm');
        if (slotDateTime.isBefore(currentDateTime)) {
          //it is a past time then will not include
          return;
        }
  
        if (!screeningByScreen[theatre_name]) {
          screeningByScreen[theatre_name] = [{
            screenId: +id,
            screenName: screen_name,
            timeSlot: slot_time,
            availability: availability,
            theatreId: theatre_id,
            isFavourite: is_favourite
          }];
        }
        else {
          screeningByScreen[theatre_name].push({ screenId: +id, timeSlot: slot_time, screenName: screen_name, availability: availability, theatreId:theatre_id, isFavourite:is_favourite });
        }
      });
  
      // convert grouped object into an array of ScreenType
      const screenTypeArray = Object.keys(screeningByScreen).map((theatre_name) => ({
        screenName: screeningByScreen[theatre_name][0]?.screenName,
        theaterName: theatre_name,
        theatreId:screeningByScreen[theatre_name][0]?.theatreId,
        isFavourite:screeningByScreen[theatre_name][0]?.isFavourite,
        timeSlots: this.sortTimeSlot(screeningByScreen[theatre_name]),      
      }))
  
      this.setState({ 
        timeSlots: screenTypeArray, 
        totalTimeSlots: screenTypeArray,
        isTimeSlotLoading: false
      })

    }

    sortTimeSlot = (screen: {
      screenId: number;
      timeSlot: string;
      availability: IAvailability[]
    }[]) => {
      const sortedScreen = [...screen];
      sortedScreen.sort((first, second) => first.timeSlot.localeCompare(second.timeSlot));
      return sortedScreen;
    }

    handleCloseTermsAndCondition = () => {
      this.setState({ isTermsAndConditionOpen: false})
    }

    selectTimeSlot = (screenId: number, theaterName: string, screenName: string, timeSlot: string) => {
      this.setState({ isTermsAndConditionOpen: true, selectedTimeSlotDetail: { screenId, theaterName, screenName, selectedTimeSlot: timeSlot } })
    }

    acceptTermsAndCondition = () => {
      const detailsToStore = {
        movieName: { ...this.state.movieData.attributes, movieId: this.state.movieData.id },
        selectedScreenDetail: {
          screenDetail: this.state.selectedTimeSlotDetail,
          selectedLanguage: this.state.languageOptions.find(languague => languague.value === this.state.filterData.languageId),
          selectedFormat: this.state.formatOptions.find(fomrat => fomrat.value === this.state.filterData.formatId),
          selectedDate: this.state.filterData.date,
        },
        screenTimeSlots: this.state.timeSlots.find(element => element.theaterName === this.state.selectedTimeSlotDetail.theaterName),
      }
      setStorageData(LocalStorageKeys.SelectTimeSlotDetail, JSON.stringify(detailsToStore)).then(() => {
        const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
        navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), 'AuctionBiddingAuditorium');
        navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.selectedTimeSlotDetail.screenId);
        this.send(navigationMessage);
      })
    }
    // Customizable Area End
  }