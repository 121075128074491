export const close = require("../assets/close.png");
export const MoviePoster = require("../assets/Rocketry_The_Nambi_Effect.jpg");

export const starFilled = require("../assets/starFilled.png");
export const starOutline = require("../assets/starOutline.png");

// emoji image
export const notHappy = require("../assets/notHappy.png");
export const bad = require("../assets/bad.png");
export const wow = require("../assets/wow.png");
export const sad = require("../assets/sad.png");
export const simple = require("../assets/simple.png");
export const thumbsDown = require("../assets/thumbsDown.png");
export const thumbsUp = require("../assets/thumbsUp.png");
export const logo = require("../assets/logoSmall.png");
export const back = require("../assets/backarrowleft.png");
export const checkboxicon = require("../assets/checkboxicon.svg");
export const check = require("../assets/check.svg");
export const happy = require("../assets/happy.png");
export const FilterIcon = require("../assets/filter_icon.png");

