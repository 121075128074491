export const backarrowleft = require("../assets/backarrowleft.png");
export const MovieImage = require("../assets/poster.png");
export const close = require("../assets/close.png");
export const location = require("../assets/location.png");
export const ticketStar = require("../assets/TicketStar.png");
export const qr_code = require("../assets/QR_Booking_Successful.jpg");
export const car = require("../assets/car.png");
export const verified = require("../assets/verified.png");
export const food = require("../assets/food.png");
export const cancelListing = require("../assets/cancel.png");
export const phone = require("../assets/phone.png");
export const cancelledImage = require("../assets/image-bitmap-copy-2.png");
export const InvalidStatusImage = require("../assets/Invalid.png");


export const FilterIcon = require("../assets/mi_filter.png");
export const redIcon = require("../assets/down_red.png");
export const greenIcon = require("../assets/up.png");
export const upGreyIcon = require("../assets/grey_up.png");
export const downGreyIcon = require("../assets/down.png");