import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area Start
import { createRef } from 'react'
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys, SeatStatus } from "../../../components/src/enums.web";
import { IBookingData, IBookingResponse, IPrice, IScreeningApiResponse, IScreeningData, ISeat, ISeatSelectionGridApiResponse, ISeatStructure, ISection, ISelectedScreeningDetail, IUserDetails } from "../../../components/src/interfaces.web";
import { callApi } from "../../../components/src/Toolkit";
import moment from "moment";

interface IBookTicketsPayload {
  "booking": {
    "booking_type": "ito",
    "screening_id": number,
    "number_of_seats": number,
    "price_structure_id": number, // price section id 
    "bookable_id": number,
    "bookable_type": "BxBlockCategories::Movie",
    "account_email": string,
    "full_phone_number": string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  auction_product: {
    date?: string,
    notifications?: [
      {
        auction_bidding?: {
          data?: {
            id:string,
            attributes?:{
              product_name?: string,
              product_images:[
                {
                  url: string
                }
              ]
            }
          }
        },
        auction_bid?: {
          amount: number
        }
      }
    ]
   }[];
  isApiLoading: boolean;
  timeSlots: {
    screenId: string,
    timeSlot: string
  }[];
  selectedDetail: {
    theaterName: string,
    movieId: number,
    screenId: number,
    date: string | Date,
  },
  isGridApiLoading: boolean,
  isChooseSeatOpen: boolean,
  isEditSeatOpen: boolean,
  isBackDropOpen: boolean,
  seatGrid: ISection[],
  selection: {
    numberOfSeats: number,
    selectedSectionName: string
  },
  seatSectionDetails: IPrice[],
  selectedSection: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AuctionBiddingNotificationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getNotificationListCallId : string = "";
    timeSlotApiCallId: string = "";
    seatGridApiCallId: string = "";
    createItoBookingApiCallId: string = "";
    parentWrapperRef: React.RefObject<HTMLDivElement>;
    childWrapperRef: React.RefObject<HTMLDivElement>;
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      // Customizable Area Start
      // Customizable Area End
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage)
        // Customizable Area Start
        // Customizable Area End
      ];
  
      this.state = {
        // Customizable Area Start
        selectedDetail: {
          screenId: 0,
          movieId: 0,
          theaterName: "",
          date: ""
        },
        isApiLoading: true,
        isGridApiLoading: true,
        isChooseSeatOpen: true,
        isEditSeatOpen: false,
        isBackDropOpen: false,
        auction_product: [],
        timeSlots: [],
        seatGrid: [],
        selection: {
          numberOfSeats: 2,
          selectedSectionName: ""
        },
        seatSectionDetails: [],
        selectedSection: ''
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  
      // Customizable Area Start
      this.parentWrapperRef = createRef();
      this.childWrapperRef = createRef();
      // Customizable Area End
    }
    
    async componentDidMount() {
      // Customizable Area Start
      this.getAuctionAccetedList();

      this.getTimeSlotList();
      // Customizable Area End
      
     
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        runEngine.debugLog("API Message Recived", message);
        
        if (responseJson && !responseJson.errors) {
          //istanbul ignore next
          if (apiRequestCallId === this.getNotificationListCallId) {
            this.setState({ auction_product: responseJson });
  
          }

          switch(apiRequestCallId) {
            case this.timeSlotApiCallId:
              this.handleMovieTimeSlot(responseJson);
              break;

            case this.seatGridApiCallId: 
              this.handleSeatSelectionGrid(responseJson);
              break;

            case this.createItoBookingApiCallId:
              this.handleCreateBooking(responseJson.data);
              break;
            
            default: 
              break;
          }
          
        }//istanbul ignore next
         else if (responseJson && responseJson.errors) {
          if (responseJson.errors) {
            if (apiRequestCallId === this.getNotificationListCallId) {
              
            }
          }
        }
      }
      // Customizable Area End
    }
    // Customizable Area Start
    apiCall = async(data: {contentType: string,method: string, endPoint: string,body?:object, type?: string }) => {
      const { contentType, method, endPoint, body, type } = data;
  
      const token = (await StorageProvider.get("TOKEN")) || "";
      
      const header = {
        "Content-Type": contentType,
        token : type != "login" ? token : ""
      };
      //istanbul ignore next
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      //istanbul ignore next
      body && 
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
          
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    }
    
    getAuctionAccetedList = async() => {
      this.getNotificationListCallId = await this.apiCall({
        contentType: configJSON.getAuctionDetailApiContentType,
        method: configJSON.AuctionAcceptedNotificationApiMethod,
        endPoint: configJSON.AuctionAcceptedNotificationApiEndPoint 
      })
    }

    getTimeSlotList = async () => {
      const selectedDetails = (await getStorageData(LocalStorageKeys.SelectTimeSlotDetail, true)) as ISelectedScreeningDetail
      const location = (await getStorageData(LocalStorageKeys.Location)) as string

      const {
        movieName: {
          movieId
        },
        selectedScreenDetail: {
          screenDetail: {
            theaterName,
            screenId
          },
          selectedLanguage: { 
            value: languageId
          },
          selectedFormat: {
            value: formatId
          },
          selectedDate
        }
      } = selectedDetails

      
      this.setState({ selectedDetail: { theaterName: theaterName, screenId: screenId, movieId: movieId, date: selectedDate } }, () => {
        this.timeSlotApiCallId = callApi({
          contentType: configJSON.ContentType,
          method: configJSON.GetMethod,
          endPoint: `${configJSON.timeSlotApiEndPoint}?language_id=${languageId}&location=${location}&screen_type_id=${formatId}&slot_date=${moment(selectedDate).format("YYYY-MM-DD")}&movie_id=${movieId}`,
          headers: { "token": configJSON.GuestToken },
        }, runEngine)

        this.getSeatGridById(screenId);

      })
    }

    handleMovieTimeSlot(responseJson: IScreeningApiResponse) {

      const { data } = responseJson
  
      const filteredScreenings = data.filter((screening: IScreeningData) => screening.attributes.theatre_name == this.state.selectedDetail.theaterName);
  
      const timeSlotArray: { screenId: string, timeSlot: string }[] = [];
      filteredScreenings.forEach((screening: IScreeningData) => {
        timeSlotArray.push({
          screenId: screening.id,
          timeSlot: screening.attributes.slot_time
        })
      })
  
      this.setState({ timeSlots: timeSlotArray, isApiLoading: false })
  
    }

    getSeatGridById = (screenId: number) => {
      this.setState((prevState) => ({ 
        selectedDetail: {
          ...prevState.selectedDetail,
          screenId: screenId
        },
        isGridApiLoading: true 
      }))

      this.seatGridApiCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.GetMethod,
        endPoint: `${(configJSON.SeatGridApiEndPoint as string).replace("{ID}", screenId.toString())}`,
        headers: { "token": configJSON.GuestToken },
      }, runEngine)

    }

    handleSeatSelectionGrid = (resJson: ISeatSelectionGridApiResponse) => {

      const seatPriceSection = resJson.price

      // Create price object with having key of seat_type
      const sectionType: { [objectKey: string]: IPrice} = {};
      resJson.price.forEach((price : IPrice) => {
        sectionType[price.seat_type] = price
      })
  
      // Group seats by seat_type
      const seatsByType: { [objectKey: string]: ISeatStructure[] } = {};
      resJson.seats.data.forEach((seat: ISeatStructure) => {
        const seatType = seat.attributes.seat_type;
        if (!seatsByType[seatType]) {
          seatsByType[seatType] = [];
        }
        seatsByType[seatType].push(seat);
      });
  
      // Create sections and rows
      const seatGrid: ISection[] = [];

      for (let seatType in seatsByType) {
        const section: ISection = {
          price: sectionType[seatType].price,
          sectionName: seatType,
          rows: []
        };
  
        const seatsData = seatsByType[seatType].reduce((accumlator: { [objectKey: string]: ISeatStructure[] }, seat: ISeatStructure) => {
          if (!accumlator[seat.attributes.row]) {
            accumlator[seat.attributes.row] = [];
          }
          accumlator[seat.attributes.row].push(seat);
          return accumlator;
        }, {});
  
        for (let rowLabel in seatsData) {
          const seatsInRow = seatsData[rowLabel].map((seat: ISeatStructure) => {
            return {
              id: +seat.id,
              seatNumber: seat.attributes.seat_number,
              status: SeatStatus.Unavailable
            } as ISeat;
          });
          section.rows.push({
            rowLabel,
            seats: seatsInRow
          });
        }
  
        seatGrid.push(section);
      }

      this.setState({ 
        seatGrid, 
        isGridApiLoading: false, 
        selection: { 
          numberOfSeats: 2, 
          selectedSectionName: seatGrid.length ? seatGrid[0].sectionName : "" 
        },
        seatSectionDetails: seatPriceSection
      }, () => {
        this.updateJustifyContent()
      });
  
    }

    updateJustifyContent = () => {
      if (this.parentWrapperRef.current && this.childWrapperRef.current) {
        const parentWidth = this.parentWrapperRef.current.offsetWidth;
        const childWidth = this.childWrapperRef.current.offsetWidth;
  
        if (childWidth > parentWidth) {
          this.parentWrapperRef.current.style.justifyContent = 'flex-start';
          this.parentWrapperRef.current.style.paddingLeft = '18px';
        } else {
          this.parentWrapperRef.current.style.justifyContent = 'center';
        }
      }
    }

  handleChooseSeat = () => this.setState({ isChooseSeatOpen: false });
  openEditSeatNumber = () => this.setState({ isEditSeatOpen: true });
  closeEditSeat = () => this.setState({ isEditSeatOpen: false });

  changeSectionSelect = (value: string) => this.setState((prevState) => ({ selection: { ...prevState.selection, selectedSectionName: value } }));
  changeNumberOfSeats = (value: number) => this.setState((prevState) => ({ selection: { ...prevState.selection, numberOfSeats: value } }));

  handleBookTickets = async () => {

    const userToken = (await getStorageData(LocalStorageKeys.LoginToken) as string);
    const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true) as IUserDetails);

      this.setState({ isBackDropOpen: true })

      // create ITO booking
      const {
        selection: {
          numberOfSeats,
          selectedSectionName,
        },
        selectedDetail: {
          movieId,
          screenId
        }
      } = this.state;

      const selectedTierPrice = this.state.seatSectionDetails.find(section => section.seat_type.toLowerCase() === selectedSectionName.toLowerCase())?.id;

      const payload = {
        booking: {
          booking_type: "ito",
          screening_id: screenId,
          number_of_seats: numberOfSeats,
          price_structure_id: selectedTierPrice,
          bookable_id: +movieId,
          bookable_type: "BxBlockCategories::Movie",
          account_email: userDetails.attributes.email,
          full_phone_number: userDetails.attributes.full_phone_number
        }
      } as IBookTicketsPayload

      this.createItoBookingApiCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.PostMethod,
        endPoint: configJSON.ItoBookingApiEndPoint,
        headers: { "token": userToken },
        body: payload
      }, runEngine)

  }

  handleCreateBooking = (result: IBookingResponse) => {

    const detailToStore = {
      id: +result.id,
      totalAmount: +result.attributes.total_amount,
      hideOtherPayment: true
    } as IBookingData

    setStorageData(LocalStorageKeys.BookingData, JSON.stringify(detailToStore)).then(() => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "PaymentSelection");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    })
    
  }
    // Customizable Area End
  }