import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { IPrice, IRequestedBookingResponse, IScreeningApiResponse, IScreeningData, ISeat, ISeatSelectionGridApiResponse, ISeatStructure, ISection } from "../../../components/src/interfaces.web";
import { LocalStorageKeys, SeatStatus } from "../../../components/src/enums.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Toolkit";

interface INavigationPayload {
  slot_date: string
  language_id: number
  screen_type_id: number
  screening_id: number
  movie_id: number
  theatre_name: string
  movie_name: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  seatSelection: ISection[];
  isLiveRequestsOpen: boolean;
  liveRequestList: IRequestedBookingResponse[];
  isAccceptOpen: boolean;
  isRejectOpen: boolean;
  navigationPayload: INavigationPayload;
  timeSlots: {
    screening_id: string,
    slot_time: string
  }[];
  selectedScreenId: number;
  isTimeSlotLoading: boolean;
  isSeatGridLoading: boolean;
  isApproveRejectLoading: boolean;
  liveRequestTable: {
    classic_approved_request: number;
    classic_open_request: number;
    classic_requested_request: number;
    prime_approved_request: number;
    prime_open_request: number;
    prime_requested_request: number;
    recliner_approved_request: number;
    recliner_open_request: number;
    recliner_requested_request: number;
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveRequestController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  movieTimeSlotApiCallId = '';
  seatSelectionGridApiCallId = '';
  liveRequestsTableApiCallId = '';
  liveRequestListingApiId = '';
  approveRequestApiId = '';
  rejectRequestApiId = ''; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLiveRequestsOpen: false,
      navigationPayload: {} as INavigationPayload,
      userToken: '',
      timeSlots: [],
      seatSelection: [],
      selectedScreenId: 0,
      isTimeSlotLoading: true,
      isSeatGridLoading: true,
      liveRequestList: [],
      liveRequestTable: {
        classic_approved_request: 0,
        classic_open_request: 0,
        classic_requested_request: 0,
        prime_approved_request: 0,
        prime_open_request: 0,
        prime_requested_request: 0,
        recliner_approved_request: 0,
        recliner_open_request: 0,
        recliner_requested_request: 0
      },
      isAccceptOpen: false,
      isRejectOpen: false,
      isApproveRejectLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

      const { payload } = message.getData(getName(MessageEnum.NavigationToLiveRequestPayload))
      this.setState({ navigationPayload: payload, selectedScreenId: (payload as INavigationPayload).screening_id })
      this.getMovieScreenings(payload as INavigationPayload)
      this.getSeatSelectionGrid((payload as INavigationPayload).screening_id)

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


      if (apiRequestCallId && responseJson !== undefined && responseJson?.errors === undefined) {
        this.handleAPIResponse(apiRequestCallId, responseJson)
      }

    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleAPIResponse(apiRequestCallId: string, responseJson: any) {

    switch (apiRequestCallId) {
      case this.movieTimeSlotApiCallId:
        this.handleMovieTimeSlot(responseJson)
        break;

      case this.seatSelectionGridApiCallId:
        this.handleSeatSelectionGrid(responseJson)
        break;

      case this.liveRequestsTableApiCallId:
        this.handleLiveRequestTable(responseJson)
        break;

      case this.liveRequestListingApiId:
        this.setState({ liveRequestList: responseJson?.data })
        break;

      case this.approveRequestApiId:
        this.setState({ isAccceptOpen: true, isApproveRejectLoading: false })
        break;

      case this.rejectRequestApiId:
        this.setState({ isRejectOpen: true, isApproveRejectLoading: false })
        break;
      
    }

  }

  getMovieScreenings(payload: INavigationPayload) {
    const { language_id, screen_type_id, slot_date, movie_id } = payload
    this.movieTimeSlotApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: `${configJSON.MovieTimeSlotsApiEndPoint}?language_id=${language_id}&screen_type_id=${screen_type_id}&slot_date=${slot_date}&movie_id=${movie_id}`,
      headers: { "token": this.state.userToken },
    }, runEngine)
  }

  getSeatSelectionGrid(screeningId: number) {

    this.setState({ selectedScreenId: screeningId, isSeatGridLoading: true })

    this.seatSelectionGridApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: `${(configJSON.GetSeatSelectionByScreenIdApiEndPoint as string).replace("{ID}", screeningId.toString())}`,
      headers: { "token": this.state.userToken },
    }, runEngine)

  }

  getLiveRequestTableData() {
    this.liveRequestsTableApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.GetLiveRequestsTable,
      headers: { "token": this.state.userToken },
    }, runEngine)
  }

  handleMovieTimeSlot(responseJson: IScreeningApiResponse) {

    const { data } = responseJson

    const filteredScreening = data.filter((screening: IScreeningData) => screening.attributes.theatre_name == this.state.navigationPayload.theatre_name);

    const timeSlotArray: { screening_id: string, slot_time: string }[] = [];
    filteredScreening.forEach((screening: IScreeningData) => {
      timeSlotArray.push({
        screening_id: screening.id,
        slot_time: screening.attributes.slot_time
      })
    })

    this.setState({ timeSlots: timeSlotArray, isTimeSlotLoading: false })

  }

  handleSeatSelectionGrid(resJson: ISeatSelectionGridApiResponse) {

    // Create price object with having key of seat_type
    const sectionType: { [key: string]: IPrice} = {};
    resJson.price.forEach((price : IPrice) => {
      sectionType[price.seat_type] = price
    })

    // Group seats by seat_type
    const seatsByType: { [key: string]: ISeatStructure[] } = {};
    resJson.seats.data.forEach((seat: ISeatStructure) => {
      const seatType = seat.attributes.seat_type;
      if (!seatsByType[seatType]) {
        seatsByType[seatType] = [];
      }
      seatsByType[seatType].push(seat);
    });

    // Create sections and rows
    const seatSelection: ISection[] = [];
    for (let seatType in seatsByType) {
      const section: ISection = {
        sectionName: seatType,
        price: sectionType[seatType].price,
        rows: []
      };

      const seatsData = seatsByType[seatType].reduce((acc: { [key: string]: ISeatStructure[] }, seat: ISeatStructure) => {
        if (!acc[seat.attributes.row]) {
          acc[seat.attributes.row] = [];
        }
        acc[seat.attributes.row].push(seat);
        return acc;
      }, {});

      for (let rowLabel in seatsData) {
        const seatsInRow = seatsData[rowLabel].map((seat: ISeatStructure) => {
          return {
            id: +seat.id,
            seatNumber: seat.attributes.seat_number,
            status: seat.attributes.reserved == SeatStatus.Request ? SeatStatus.Request : SeatStatus.OnlyShowAvailable
          } as ISeat;
        });
        section.rows.push({
          rowLabel,
          seats: seatsInRow
        });
      }

      seatSelection.push(section);
    }

    // Assign the structured data to the state
    this.setState({ seatSelection, isSeatGridLoading: false });

  }

  handleLiveRequestTable(responseJson: any) {
    this.setState({ liveRequestTable: responseJson.data })
  }

  openLiveRequest = () => {
    this.setState({ isLiveRequestsOpen: true })

    this.liveRequestListingApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.GetLiveRequestsListing,
      headers: { "token": this.state.userToken },
    }, runEngine)
  }
  
  handleAcceptButton = (id: number) => {

    this.setState({ isApproveRejectLoading: true })
   
    this.approveRequestApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.PutAPIMethod,
      endPoint: (configJSON.UpdateRequestApiEndPoint as string).replace("{ID}", id.toString()),
      headers: { "token": this.state.userToken },
      body: {
        "data": {
          "status": "approved"
        }
      }
    }, runEngine)

  }

  handleAcceptClose = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "RequestManagement");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleRejectButton = (id: number) => {

    this.setState({ isApproveRejectLoading: true })
   
    this.rejectRequestApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.PutAPIMethod,
      endPoint: (configJSON.UpdateRequestApiEndPoint as string).replace("{ID}", id.toString()),
      headers: { "token": this.state.userToken },
      body: {
        "data": {
          "status": "declined"
        }
      }
    }, runEngine)

  }

  async componentDidMount() {
    const userToken = await getStorageData(LocalStorageKeys.LoginToken)
    this.setState({ userToken: userToken })

    this.getLiveRequestTableData();
  }

  // Customizable Area End
}
