export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backicon = require("../assets/backarrow.png");
export const arrowdown = require("../assets/dropdown1.png")
export const arrowup = require("../assets/dropdown.png")
export const wallet = require("../assets/Wallet.png")
export const walletcd = require("../assets/walletCD.png")
export const bank = require("../assets/bank.png")
export const toWallet = require("../assets/toWallet.png")
export const toBank = require("../assets/toBank.png")
export const barcode = require("../assets/Barcode.png")
export const rupeeIcon = require("../assets/ruppe.png")
export const failedIcon = require("../assets/Failed.png")
export const pendingIcon = require("../assets/pending.png")
export const paymentSucessfull = require("../assets/payment_sucessfull.png")
export const paymentFailed = require("../assets/failedTransaction.png")
export const resetIcon = require("../assets/Reset.png")
export const LightWalletIcon = require("../assets/light-wallet.png");
export const ListBarcodeIcon = require("../assets/light-barcode.png");
export const FailedTransactionIcon = require("../assets/failed-transaction-red.png");