import React, { CSSProperties } from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  withStyles,
  Tooltip,
  Fade,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
export const configJSON = require("./config");
import Customadvertisements from "../../customadvertisements/src/Customadvertisements.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { PreviousBtnPlay } from "../../../components/src/PreviousBtnPlay.web";
import { NextBtnPlay} from "../../../components/src/NextBtnPlay.web";
import { Link } from "react-router-dom";
import './currentplay.css'
import Slider from "react-slick";
import {
  friday_white,
  coming_white,
  current_icon,
  heart, play, rating_star, mainslider,
  event1, event2, event3, event4
} from "./assets";
import Promotecontent from "../../promotecontent/src/Promotecontent.web";
import KycDialogeBox from "../../cfwallet19/src/KycDialogeBox.web";
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";

const BookTicketBtn = styled(Button)({
  backgroundColor: "#121212",
  padding: "12px",
  textAlign: "center",
  maxHeight: "40px",
  color: "#fff",
  border: "0.5px solid #FFB636",
  borderRadius: "4px",
  fontFamily: "Poppins",
  textTransform: "capitalize",
  position: "relative",
  zIndex: 10,
  cursor: "pointer",
  "&:hover": {
      backgroundColor: "#FFB636",
      color: "#000"
  }
})

const SliderComponent = styled(Slider)({
  "& .slick-list" :{
    "& .slick-track":{
      height:400
    }
  },
  "& .slick-arrow": {
    zIndex: 500,
    "& .previous__button": {
      height: "35px",
      width: "35px",
      cursor: "pointer",
    },
    "& .next__button": {
      height: "35px",
      width: "35px",
      cursor: "pointer"
    },
  },
  "@media (max-width: 600px)": {
    "& .slick-arrow": {
      "& .next__button": {
        height: "28px !important",
        width: "28px !important",
      },
      "& .previous__button": {
        height: "28px !important",
        width: "28px !important",
      },
    },
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontSize: "12px",
      fontFamily: "Poppins"
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },

    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      },
    },
  },
});

const toolttip = {
  tooltip: {
    backgroundColor: '#121212',
  },
  arrow: {
    color: "#121212",
  }
};

const CustomTooltip = withStyles(toolttip)(Tooltip);
// Customizable Area End

import HomepageController, {
  Props,
} from "./HomepageController.web";
import BookTicketCard, { MovieDetails,EventDetails } from "../../../components/src/BookTicketCard.web";
import { HomePageFilterOption } from "../../../components/src/enums.web";

export class Homepage extends HomepageController {
  constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
  }

  // Customizable Area Start
  renderEventComp = (event: any) => {
      const {isHover} = this.state;
      const {
          id,
          attributes: {
              languages,
              poster_url,
              name,
              show_type,
              is_promoted
          }
      }: any = event;

      const languagesNames = languages.map(
          (language: any) => language.name
      );
      const languagesString = languagesNames?.join(', ');


      return (
          <Box
              sx={webStyle.boxCurrentPlay}
              data-test-id="event-card-wrapper"
              onTouchStart={() => this.handleMouseEnter(id)}
              onMouseLeave={() => this.handleMouseLeave(id)}
              onMouseEnter={() => this.handleMouseEnter(id)}>
              {is_promoted && <Promotecontent navigation="" id=""/>}
              <img src={poster_url[0]} style={webStyle.cardImgStyle}/>

              <Fade in={isHover[id]}>
                  <Box sx={webStyle.fadeStyle}>
                      <Box sx={webStyle.eventNameTxt}>{name}</Box>
                      <Box sx={webStyle.languagetxt}> {`${languagesString}`}</Box>
                      <Box sx={webStyle.categorytxt}>{show_type}</Box>
                      <Box sx={webStyle.BoxWrapper}>
                          <BookTicketBtn
                              onClick={() => this.navigateToEventDetail(id)}
                              data-test-id="bookTicket"
                              fullWidth
                          >
                              Book Tickets
                          </BookTicketBtn>
                      </Box>
                  </Box>
              </Fade>
          </Box>
      )
  }

  renderCurrentPlayList = () => {

    const settings = {
      infinite: false,
      slidesToScroll: 4,
      speed: 500,
      slidesToShow: 4,
      arrow: true,
      responsive: [
        {
          breakpoint: 540,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
            nextArrow: <NextBtnPlay slidesToShow={1} />
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2,
            nextArrow: <NextBtnPlay slidesToShow={2} />
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: <NextBtnPlay slidesToShow={2} />
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToScroll: 3,
            slidesToShow: 3,
            nextArrow: <NextBtnPlay slidesToShow={4} />
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
            nextArrow: <NextBtnPlay slidesToShow={4} />
          }
        }
      ],
      prevArrow: <PreviousBtnPlay />,
      nextArrow: <NextBtnPlay slidesToShow={4} />,
    };
    return <div className='cur_play' style={webStyle.cen}>
      {this.state.currentPlayList.length > 0 ? (
        <Container maxWidth='lg' style={webStyle.gridMainCardStyle}>
          <SliderComponent {...settings}>

            {
              this.state.currentPlayList.map((movieDetails: MovieDetails) => (
                <BookTicketCard 
                  key={movieDetails.id} 
                  movie={movieDetails} 
                  redirectTo={`/MovieDetails/${movieDetails.id}`} 
                  navigateToMovieDetails={this.navigateToMovieDetails}
                />
              ))
            }

          </SliderComponent>
        </Container>
      ) : <Typography variant="h6" align="center" style={webStyle.message}>{configJSON.EmptyMovieMessage}</Typography>

      }

    </div>
  }
  renderEventSlideList = (eventList: any) => {

    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrow: true,
      responsive: [
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextBtnPlay slidesToShow={1} />
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: <NextBtnPlay slidesToShow={2} />
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: <NextBtnPlay slidesToShow={2} />
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: <NextBtnPlay slidesToShow={4} />
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <NextBtnPlay slidesToShow={4} />
          }
        }
      ],
      prevArrow: <PreviousBtnPlay />,
      nextArrow: <NextBtnPlay slidesToShow={4} />

    };

    return <div className='cur_play' style={webStyle.cen}>

      {
        eventList?.length > 0 ? (
      <Container maxWidth='lg' style={webStyle.gridMainCardStyle}>
        <SliderComponent {...settings}>

          {eventList?.map((eventList:any)=> {
            return(
              <>{this.renderEventComp(eventList)}</>
            )
          })}

        </SliderComponent>
      </Container>
        ) : <Typography variant="h6" align="center" style={webStyle.message}>{configJSON.EmptyEventMessage}</Typography>
      }

    </div>
  }





  renderEventsITOList = () => {

    const settings = {
      infinite: false,
      speed: 500,
      slidesToScroll: 4,
      slidesToShow: 4,
      arrow: true,
      responsive: [
        {
          breakpoint: 540,
          settings: {
            nextArrow: <NextBtnPlay slidesToShow={1} />,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            
            slidesToShow: 2,
            nextArrow: <NextBtnPlay slidesToShow={2} />,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            nextArrow: <NextBtnPlay slidesToShow={2} />,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToScroll: 3,
            nextArrow: <NextBtnPlay slidesToShow={4} />,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToScroll: 4,
            nextArrow: <NextBtnPlay slidesToShow={4} />,
            slidesToShow: 4,
            
          }
        }
      ],
      nextArrow: <NextBtnPlay slidesToShow={4} />,
      prevArrow: <PreviousBtnPlay />,
     

    };
    return <div className='cur_play' style={webStyle.cen}>
      {this.state.allEventITOList.length > 0 ? (
        <Container maxWidth='lg' style={webStyle.gridMainCardStyle} data-test-id="take-book-ticket-card">
          <SliderComponent {...settings}>
            {
              this.state.allEventITOList.map((eventDetails: EventDetails) => (
               <BookTicketCard isIto = {true} key={eventDetails.id} movie={eventDetails} 
                redirectTo = ""
                handleOnClick={(bookingId) => this.handleItoRedirection(bookingId, eventDetails.type as string)} />))
            }
          </SliderComponent>
        </Container>
      ) : (
        <Typography variant="h6" align="center" style={webStyle.message}>{configJSON.EmptyMessage}</Typography>
      )
      }

    </div>
  }

  generateFilter = () => ([
    { label: configJSON.labelThisFriday, icon: friday_white, value: HomePageFilterOption.ThisFriday, areaLabel: "left aligned" },
    { label: configJSON.labelCurrentPlay, icon: current_icon, value: HomePageFilterOption.CurrentlyPlaying, areaLabel: "centered" },
    { label: configJSON.labelComing, icon: coming_white, value: HomePageFilterOption.ComingSoon, areaLabel: "right aligned" },
  ])

  // Customizable Area End

  render() {
    const { classes } = this.props

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} >
          <Paper elevation={0} style={webStyle.mainPaperStyle}>
            <MainHeaderComp navigation = {this.props.navigation} addPadding={true} />
            <Customadvertisements navigation={this.props.navigation} id="customadvertisements"/>

            <Grid className={classes.gridMovieBtnStyle}>
              <Box component={"div"} className={classes.MovieBtnBack}>

                <ToggleButtonGroup value={this.state.selectedFilter} className={classes.formControl} exclusive aria-label="text alignment">

                  {
                    this.generateFilter().map((item) => (
                      <ToggleButton data-test-id="filter-button" value={item.value} onClick={() => this.getCurrentPlayList(item.value)} aria-label={item.areaLabel} className={classes.root}>
                        <Box className={classes.innerMovieBoxStyle}>
                          <img src={item.icon} className={classes.inactiveMovieBtnIconStyle} />
                          <Typography className={item.value === this.state.selectedFilter ? classes.activeMovieBtnLabelStyle : classes.inactiveMovieBtnLabelStyle}>{item.label}</Typography>
                        </Box>
                      </ToggleButton>
                    ))
                  }

                </ToggleButtonGroup>
                
              </Box>
            </Grid>

            <Container maxWidth='lg'>
              <Grid style={webStyle.gridPlayStyle1}>
                <Box style={webStyle.boxPlayStyle}>
                  <Typography style={webStyle.cardHeaderStyle}> </Typography>
                  <Link to={'seeall'} style={{ textDecoration: 'none' }}><Typography style={webStyle.seeAllStyle}> {configJSON.labelSeeAll}</Typography></Link>
                </Box>
              </Grid>
            </Container>

            {
              this.state.isCurrentlyPlayingLoading ? (
                <Container>
                  <Fade in={true} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress size="2rem" style={webStyle.CircularProgress} />
                    </Box>
                  </Fade>
                </Container>
              ) : this.renderCurrentPlayList()
            }


            <Container maxWidth='lg'>
              <Grid style={webStyle.gridPlayStyle}>
                <Box style={webStyle.boxPlayStyle}>
                  <Typography style={webStyle.cardHeaderStyle}>{configJSON.labelEvents}</Typography>
                  <Link to={'allevent'} style={{ textDecoration: 'none' }}>
                  <Typography style={webStyle.seeAllStyle}>{configJSON.labelSeeAll}</Typography>
                  </Link>
                </Box>
              </Grid>
            </Container>

            {
              this.state.isEventsLoading ? (
                <Container>
                  <Fade in={this.state.isEventsLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress size="2rem" style={webStyle.CircularProgress} />
                    </Box>
                  </Fade>
                </Container>
              ) : this.renderEventSlideList(this.state.eventList)
            }


            <Container maxWidth='lg'>
              <Grid style={webStyle.gridPlayStyle}>
                <Box style={webStyle.boxPlayStyle}>
                  <Typography style={webStyle.cardHeaderStyle}>{configJSON.labelITO} </Typography>
                  <Typography data-test-id="see_all" style={webStyle.seeAllStyle} onClick={()=>{this.redirectToItoListing()}}> {configJSON.labelSeeAll}</Typography>
                </Box>
              </Grid>
            </Container>
       
            {
              this.state.isItoListingLoading ? (
                <Container>
                  <Fade in={this.state.isItoListingLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress size="2rem" style={webStyle.CircularProgress} />
                    </Box>
                  </Fade>
                </Container>
              ) : this.renderEventsITOList()
            }
            
            {
              this.state.openSignup && (
                <UserSignUp navigation={this.props.navigation} id="homepage" showSignup={this.closeSignUp} />
              )
            }

            {this.state.openKycPendingDialog && <KycDialogeBox navigation={this.props.navigation} id="kyc-ito" handleOnClose={this.closeKycPopup} />}


            <FooterWithEvents />
          </Paper>
        </Container>


      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  CircularProgress: {
    color: "#ffb43a",
  },
  message: {
    margin: "16px",
    color: "#aeacac"
  },
  mainPaperStyle: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column' as const,
    backgroundColor: "#121212"
  },
  cen: {
    display: 'flex',
    justifyContent: 'center'
  },
  topRightIcons: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    right: "10px",
    top: "10px",
    backgroundColor: "rgb(0,0,0,0.7)",
    padding: "10px",
  },
  starIconStyle: {
    color: "#FFB636",
    margin: "15px 0px"
  },
  moviesBtnBoxStyle: {
    marginTop: '54px',
    height: '48px',
    width: '584px',
    borderRadius: '12px',
    backgroundColor: '#2f2617',
    padding: '0 24px',
    border: 'solid 1px #ffb43a',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  seeAllStyle: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    color: '#ffb636',
    cursor:"pointer"
  },
  cardHeaderStyle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    color: '#fff',
    fontWeight: 600,
  },
  boxCurrentPlay: {
    position: 'relative' as const,
  },

  cardImgStyle: {
    width: '278px',
    height: '380px',
    border: '1px solid #ffb636'
  },
  blackTagStyle: {
    position: 'absolute' as const,
    boxSizing: 'border-box',
    width: '40px',
    height: '136px',
    backgroundColor: ' rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    top: "8px",
    right: "8px",
  } as CSSProperties,
  ratingImgStyle: {
    width: '21px',
    height: '21px',
  },
  ratingHeartStyle: {
    width: "20px",
    height: "18px",
  },
  heartImgStyle: {
    width: '20px',
    height: '18px',
  },
  gridMainCardStyle: {
    marginTop: '24px',
  },
  boxMargin: {
    marginTop: '32px'
  },
  gridPlayStyle1: {
    padding: '0 25px',
    display: 'flex',
    justifyContent: 'center'
  },
  gridPlayStyle: {
    marginTop: '42px',
    padding: '0 25px',
    display: 'flex',
    justifyContent: 'center'
  },
  boxPlayStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '1440px'
  },
  teamStyle: {
    height: '100%',
    width:"100%",
    position: 'relative' as const,
  },
  fadeStyle: {
    bottom: "10px",
    width: "100%",
    transition: "0.8s ease-in-out",
    position: "absolute",
    background:"linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 0.6), #362203)"
  },
  eventNameTxt: {
    color: "#fff",
    margin: "10px",
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: "16px"
  },
  languagetxt: {
    color: "#fff",
    margin: "10px",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  categorytxt: {
    color: "#fff",
    margin: "10px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "bold"
  },
  BoxWrapper: {
    padding: "10px"
  },
}
const styles = {
  inactiveMovieBtnLabelStyle: {
    margin: '0 0 0 12px',
    fontFamily: 'Poppins',
    fontSize: '18px',
    color: '#fff',
  },
  activeMovieBtnLabelStyle: {
    margin: '0 0 0 12px',
    fontFamily: 'Poppins',
    fontSize: '18px',
    color: '#121212',
    fontWeight: 600
  },
  root: {
    height: "48px",
    width: "250px",
    padding: "0px 24px",
    border: "none",
    display: "flex",
    justifyContent: "space-evenly",
    color: "#fff"
  },
  formControl: {
    flexDirection: 'row' as const,
    '@media (max-width: 755px)': {
      flexDirection: 'column' as const
    },
    "& .MuiToggleButton-root.Mui-selected": {
      background: "rgb(255, 180, 58) !important",
      color: "#fff",
      borderRadius: "8px",
    },
    "& .MuiToggleButton-root": {
      textTransform: 'capitalize'
    },
  },
  gridMovieBtnStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
  MovieBtnBack: {
    background: "#2f2617",
    width: "fit-content",
    borderRadius: "12px",
    border: "solid 1px #ffb43a",
  },
  innerMovieBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inactiveFridayBtnIconStyle: {
    height: '20px',
    width: '28px',
  },
  activeMovieBtnIconStyle: {
    height: '24px',
    width: '24px',
  },
  inactiveMovieBtnIconStyle: {
    height: '24px',
    width: '24px',
  }
}
// Customizable Area End

export default withStyles(styles)(Homepage);