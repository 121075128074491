import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Divider,
  IconButton,
  CssBaseline,
  Chip,
  Fade,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
export const configJSON = require("./config");
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import CardCarousel from "../../../components/src/CardCarousel";
import ReviewCarousel from "../../../components/src/ReviewCarousel";
import moment from "moment";
import { AddFavourites } from "../../favourites/src/AddFavourites.web";
import { Link } from "react-router-dom";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { getTrailersText, getMovieDuration, goToPrevPage, getFormattedDate } from "../../../components/src/utilities";
import RFHDialog from "../../../components/src/RFHDialog";

import {
  starFilled,
  starOutline,
  whiteHeart,
  btnPlay,
  imgShare,
  filled_heart,
} from "./assets"
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    },
    text: {
      primary: "#FFF"
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h2: {
      fontWeight: 500,
      fontSize: "24px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "20px",
    },
    h5: {
      fontSize: "14px",
      margin: "12px 0",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "16px",
      "@media (max-width: 600px)": {
        fontSize: "14px"
      }
    },
    subtitle1: {
      fontSize: "20px",
      fontWeight: 500,
      marginBottom: "14px"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#121212",
        color: "#b4b4b4",
        border: "solid 1px #b4b4b4",
        position: 'relative',
        fontFamily: "Poppins"
      },
      arrow: {
        '&:before': {
          border: 'solid 1px #b4b4b4',
        },
        color: '#121212',
      },

    },
    MuiButton: {
      root: {
        color: "#000",
        textTransform: "capitalize"
      },
      contained: {
        backgroundColor: "#ffb43a",
        "&:hover": {
          backgroundColor: "#ffcc80 !important",
        }
      }
    },
  }
});


const BookTicketsBtn = styled(Button)({
  fontSize: "16px",
  color: "#000",
  textTransform: 'capitalize',
  fontWeight: 600,
  fontFamily: "Poppins",
  border: "none",
  borderRadius: "12px",
  cursor: "pointer",
  backgroundColor: "#ffb43a",
  marginRight: "24px",
  padding: "16px",
  minWidth: "200px",
  "&:hover": {
    backgroundColor: "#ffb43a",
    color: "#000",
  }
})

const getRatingText = (rating: number | null): string => {
  let ratingText = "No Ratings"

  if (rating) {
    rating = Math.ceil(rating);
    ratingText = `${rating}/5`;
  }

  return ratingText
}
// Customizable Area End

import MovieDetailsController, {
  Props,
} from "./MovieDetailsController.web";
import ListWithToggle from "../../../components/src/ListWithToggle.web";
import { Ratings } from "../../reviews/src/Ratings.web";
import Share from "../../share/src/Share.web";
import { countReviewDays } from "../../../components/src/utilities";
import { ILanguage, IScreenType } from "../../../components/src/interfaces.web";


export default class MovieDetails extends MovieDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderCastCrewList = (castcrewListing: any) => <CardCarousel cardList={castcrewListing} />

  handlecolor = (obj: any) => {
    if (this.state.selected === obj?.language_id) {
      return '#FFB43A'
    } else {
      return '#2F2617'
    }
  }

  handlecolor2 = (sub: any) => {
    if (this.state.selectedSec === sub?.screen_type_id) {
      return '#FFB43A'
    } else {
      return '#2F2617'
    }
  }

  handleblack = (sub: any) => {
    if (this.state.selectedSec === sub?.screen_type_id) {
      return '#000000'
    } else {
      return '#ffffff'
    }
  }

  handlewhite = (obj: any) => {

    if (this.state.selected === obj?.language_id) {
      return '#000000'
    } else {
      return '#ffffff'
    }
  }

  renderDuration = () => {
    const durationInMinutes = this.state.dummyData?.attributes?.duration_in_minutes;
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    let formattedTime = `${hours}h ${minutes}m`;
    return <Typography style={{ ...webStyle.fontStyle, ...webStyle.contentMovieDuration }}>UA&nbsp;&nbsp;&nbsp;Duration:&nbsp;<span style={{ ...webStyle.fontStyle, ...webStyle.contentMovieDuration }}>
      {formattedTime}</span></Typography>
  }
  getStarBasedOnRating = () => {
    return (
      <>
        {
          [...Array(5)].map((_, index) => {
            if (index < this.state.isReviewList.star) return <img src={starFilled} width="24px" height="24px" />
            else return <img src={starOutline} width="24px" height="24px" />
          })
        }
      </>
    )
  }
  renderStarRating() {
    const { averageRating } = this.state;
    const filledStarsCount = averageRating.average_rating || 0;

    return [...Array(5)].map((_, index) => {
      const starImage = index < filledStarsCount ? starFilled : starOutline;

      return (
        <img
          key={index}
          src={starImage}
          style={{ width: '20px', height: '20px' }}
          alt={index < filledStarsCount ? 'filled star' : 'empty star'}
        />
      );
    });
  }
  // Customizable Area End

  render() {
    const addFavourites = {
      heartIcon: whiteHeart,
      filledHeart: filled_heart,
      imageStyle: webStyle.imgShareStyles,
      favouriteable_id: this.state.getMovie,
      favouriteable_type: "BxBlockCategories::Movie",
      tooltipDirection: "bottom",
      isFavourite: this.state.dummyData?.attributes?.is_favourite
    }
    const ratingsItems = {
      movieId: this.state.getMovie,
      bannerImage: this.state.dummyData?.attributes?.image_url,
      movieName: this.state.dummyData?.attributes,
      buttonStyle: webStyle.btnRateStyle,
      buttonText: configJSON.btnRateNow,
      boxRatingTitle: webStyle.boxRatingTitle,
      buttonLabel: configJSON.labelAddRating,
      boxRatingSubTitle: webStyle.boxRatingSubTitle,
      labelFeelFree: configJSON.labelFeelFree,
    }
    const shareAttributies = {
      shareSrc: imgShare,
      imageStyle: webStyle.imgShareStyles
    }
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <CssBaseline />

        <BoxWrapper>
        <Box style={webStyle.mainPaperStyle}>
        

          <Box display={{ xs: "none", md: "block" }}>

          <MainHeaderComp navigation={this.props.navigation} />

              {
                this.state.isMovieDetailsLoading ? (
                  <Fade in={this.state.isMovieDetailsLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress className='circular__progress' />
                    </Box>
                  </Fade>
                ) : (
                  <Box className="hero__wrapper">
                    <img alt="heroposter" src={this.state.dummyData?.attributes?.image_url} />

                    <Container>

                      <Box className='details__wrapper'>

                        <Box>

                          <Typography variant="h2" id="movieName">{this.state.dummyData?.attributes?.name}</Typography>
                          <Box className='category__wrapper'>
                            {this.state.dummyData?.attributes?.category_type?.split(", ").map((category: string) => <span key={category} className='category'>{category}</span>)}
                          </Box>
                          <Typography variant='h5'>
                            {configJSON.UA}&nbsp;&nbsp;
                            {configJSON.Duration}&nbsp;
                            {getMovieDuration(this.state.dummyData?.attributes?.duration_in_minutes)}
                          </Typography>
                          <Typography variant='h5' className='screen__types'>
                            {(this.state.dummyData?.attributes?.screen_types?.map((screen: IScreenType) => screen.screen_type))?.join(", ")}
                            &nbsp;&nbsp;
                            <ListWithToggle itemsList={this.state.dummyData?.attributes?.languages?.map((language: ILanguage) => language.language_name)} />
                          </Typography>
                          <Typography className='release__date'>
                            {configJSON.releaseDate}
                            {
                              getFormattedDate(this.state.dummyData?.attributes?.release_date, "DD MMM, YYYY")
                            }
                          </Typography>


                          <Box className="rating__wrapper">
                            {this.state.isReviewList.is_reviewed ? (
                              <Box className="rating__label">
                                <Box className="rating__text">

                                  <Typography className="rating__title">{configJSON.yourRating}</Typography>
                                  <Typography className="rating__time">Posted  {countReviewDays(this.state.isReviewList.review_date)} Ago</Typography>

                                </Box>

                                <Box className="star__wrapper">
                                  {this.getStarBasedOnRating()}
                                </Box>

                              </Box>
                            )
                              :
                              <Ratings navigation={this.props.navigation} id={""} ratingsData={ratingsItems} />
                            }

                          </Box>

                          <Box style={webStyle.displayRowStyle} gridGap="4px" mb={2}>
                            {this.renderStarRating()}
                            <Typography style={{ ...webStyle.ratingTextStyle, ...webStyle.fontStyle }}>{getRatingText(this.state.averageRating.average_rating)}</Typography>
                            <Typography style={webStyle.votesStyle}>{`(${this.state.averageRating.ratings}) Votes`}</Typography>
                          </Box>

                          <Box className='action-wrapper'>
                            <Button
                              data-test-id="apply-btn"
                              className="book_btn"
                              variant="contained"
                              size="small"
                              onClick={this.bookTicket}
                              id="languageModal"
                            >
                              {configJSON.btnBookTickets}
                            </Button>

                            <Box data-test-id="play-btn" onClick={this.navigateToTrailer}>
                              <Tooltip title="Play Trailer" arrow>
                                <img className="trailer__button" src={btnPlay} alt="trailer-button" />
                              </Tooltip>
                            </Box>

                            <AddFavourites
                              navigation={this.props.navigation}
                              id="navigation"
                              addFavourites={addFavourites}
                            />

                            <Share shareDetails={shareAttributies} navigation={this.props.navigation} id="share" />
                          </Box>

                        </Box>

                        <img alt="side-photo" src={this.state.dummyData?.attributes?.image_url} />
                      </Box>

                    </Container>

                  </Box>
                )
              }

          </Box>

          <Box display={{ xs: "block", md: "none" }}>

            <Container style={webStyle.MobileHeader}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <IconButton style={webStyle.BackButton} onClick={goToPrevPage}>
                  <ArrowBackIosOutlined style={webStyle.BackButtonIcon} />
                </IconButton>

                <IconWrapper>

                    {!this.state.isMovieDetailsLoading && <AddFavourites addFavourites={addFavourites} navigation={""} id={""} />}

                    <Share shareDetails={shareAttributies} navigation={''} id={""} />

                </IconWrapper>
              </Box>
            </Container>
              {
                this.state.isMovieDetailsLoading ? (
                  <Fade in={this.state.isMovieDetailsLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress className='circular__progress' />
                    </Box>
                  </Fade>
                ) : (
                  <>

            <Box position="relative" mb={2}>
              <img src={this.state.dummyData?.attributes?.image_url} alt="poster-image" style={webStyle.Poster} />

              <Box style={webStyle.TrailerButton} display="flex" alignItems="center" justifyContent="center">
                <Box style={webStyle.CursorPointer} display="flex" alignItems="center" gridGap="4px" onClick={this.navigateToTrailer}>
                  <img
                    src={btnPlay}
                    style={webStyle.MobileTrailerBtn}
                  />
                  <span style={webStyle.MTrailerText}>{`${getTrailersText(this.state.dummyData?.attributes?.youtube_links?.length)} (${this.state.dummyData?.attributes?.youtube_links?.length})`}</span>
                </Box>
              </Box>

              <Box style={webStyle.BackDrop}></Box>
            </Box>

            <Container>
              <Box display="flex" gridGap="14px" mb={3}>

                <Box style={webStyle.PosterImage}>
                  <img src={this.state.dummyData?.attributes?.image_url} alt="poster-image" style={webStyle.ThumbnailPoster} />
                </Box>

                <Box>

                  <Typography style={webStyle.Title}>{this.state.dummyData?.attributes?.name}</Typography>
                  <Box display="flex" gridGap="8px" gridRowGap="8px" mt={1.5} mb={1.5} flexWrap="wrap" maxWidth="185px">
                    {
                      this.state.dummyData?.attributes?.category_type.split(",").map((tag: string) => <span key={tag} style={webStyle.MovieTag}>{tag}</span>)
                    }
                  </Box>
                  <Typography style={webStyle.Duration}><span style={webStyle.TextTransform}>U/A</span>&nbsp;{`${configJSON.Duration} : ${getMovieDuration(this.state.dummyData?.attributes?.duration_in_minutes)}`}</Typography>
                  <Typography style={webStyle.LanguageFormat}>
                    <span style={webStyle.LanguageFormatSpacing}>
                      <ListWithToggle itemsList={this.state.dummyData?.attributes?.screen_types?.map((item: any) => item?.screen_type)} />
                    </span>
                    <span>
                      <ListWithToggle itemsList={this.state.dummyData?.attributes?.languages.map((item: any) => item.language_name)} />
                    </span>
                  </Typography>
                  <Typography style={webStyle.ReleaseDate}>{configJSON.releaseDate}{moment(this.state.dummyData?.attributes?.release_date).format('DD MMM, YYYY')}</Typography>

                </Box>

              </Box>
            </Container>

            <Container>
              <Box textAlign="center">
                <BookTicketsBtn onClick={this.bookTicket} style={webStyle.BtnMaxHeight}>{configJSON.btnBookTickets}</BookTicketsBtn>
              </Box>

              <Box mt={3} mb={1.5} display="flex" gridGap="4px" alignItems="center">
                {this.renderStarRating()}
                <span style={webStyle.RatingText}>{getRatingText(this.state.averageRating.average_rating)}</span>
                <Typography style={webStyle.votesStyles}>{`(${this.state.averageRating.ratings}) Votes`}</Typography>
              </Box>

              <Box >
                {this.state.isReviewList.is_reviewed ?
                  <Box style={webStyle.MobileRatingWrapper}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography style={webStyle.rating}>
                        Your Rating
                      </Typography>
                      <Typography component={'span'} style={webStyle.ratingSpan}>
                        Posted  {countReviewDays(this.state.isReviewList.review_date)} Ago
                      </Typography>
                    </Box>
                    <Box sx={webStyle.starStyle}>
                      {this.getStarBasedOnRating()}
                    </Box>
                  </Box>
                  :
                  <Box style={webStyle.MobileRatingWrapper}>
                    <Ratings navigation={this.props.navigation} id={""}
                      ratingsData={ratingsItems} />
                  </Box>
                }
              </Box>
            </Container>
                 
                  </>
                )
              }

          </Box>

          <Container>

              <Box display={{ xs: "block", md: "none" }}>
                <hr className="divider" />
              </Box>

              <Box className="movie__description">
                <Typography className="section__title">
                  {configJSON.labelMovieDesc}
                </Typography>
                <Typography className="section__description">
                  {this.state.dummyData?.attributes?.description}
                </Typography>
              </Box>

            <hr className="divider" />

            <Box style={webStyle.SectionTitle}>
              <Typography className="section__title">{configJSON.labelCast} </Typography>
            </Box>

            {this.state.isCastListLoaded ? <>
              {this.state.castList && this.renderCastCrewList(this.state.castList)}
              {this.state.castError && <Typography style={{ ...webStyle.fontStyle, ...webStyle.loaderStyle, fontSize: '18px' }}>{this.state.crewError}</Typography>}
            </>
              : <Box style={webStyle.loaderStyle}>
                <CircularProgress style={{ color: '#fff' }} />
              </Box>
            }
            <hr className="divider" />

            <Box style={webStyle.SectionTitle}>
              <Typography className="section__title">{configJSON.labelCrew} </Typography>
            </Box>
            {this.state.isCrewListLoaded ? (
              <>
                {this.state.crewList && this.renderCastCrewList(this.state.crewList)}
                {this.state.crewError && (
                  <Typography style={{ ...webStyle.fontStyle, ...webStyle.loaderStyle, fontSize: '18px' }}>
                    {this.state.crewError}
                  </Typography>
                )}
              </>
            ) : (
              <Box style={webStyle.loaderStyle}>
                <CircularProgress style={{ color: '#fff' }} />
              </Box>
            )}
            <hr className="divider" />


            <Box style={webStyle.boxPlayStyle}>
              <Typography className="section__title">{configJSON.labelReview} </Typography>
              <Link to={{ pathname: `/RatingsReviews/${this.state.getMovie}` }} style={{ textDecoration: 'none' }}>
                <Typography className="seeall__label"> {configJSON.labelSeeAll}</Typography>
              </Link>
            </Box>


            {this.state.isReviewListLoaded ? (
              <>
                {this.state.reviewList && <ReviewCarousel data-test-id="review-carousel" cardList={this.state.reviewList} handleLike={this.handleLike} handleDislike={this.handleDislike} />}
                {this.state.reviewError && (
                  <Typography style={{ ...webStyle.fontStyle, ...webStyle.loaderStyle, fontSize: '18px' }}>
                    {configJSON.NoReviewsMessage}
                  </Typography>
                )}
              </>
            ) : (
              <Box style={webStyle.loaderStyle}>
                <CircularProgress style={{ color: '#fff' }} />
              </Box>
            )}

              <RFHDialog
                data-test-id="language-format-dialog"
                open={this.state.languageModal}
                handleClose={this.closeModal}
                maxWidth="sm"
                disableEscapeKeyDown={true}
                dialogTitle={
                  <RFHDialogContent>
                    <Typography className='header_title'>
                      {configJSON.selectLanguageAndFormat}
                    </Typography>
                  </RFHDialogContent>
                }
                dialogActions={(
                  <RFHButton
                    fullWidth
                    data-test-id="continue"
                    onClick={this.continueToBook}
                    disabled={!(this.state.selectedScreenId && this.state.selectedIdForLanguage)}
                  >
                    {configJSON.Continue}
                  </RFHButton>
                )}
                dialogContent={(
                  <RFHDialogContent>

                    <Typography className="title">
                      {configJSON.languageLabel}
                    </Typography>
                    <Box className='options__wrapper'>

                      {
                        this.state.dummyData?.attributes?.languages?.map((language: ILanguage) => (
                          <Chip
                            data-test-id="language-chip"
                            className="modified_chip"
                            label={language.language_name}
                            variant={this.state.selectedIdForLanguage === language.language_id ? "outlined" : "default"}
                            onClick={() => this.changeLanguageFormat(language.language_id, "selectedIdForLanguage")}
                            key={language.language_id} />
                        ))
                      }

                    </Box>

                    <Divider className='divider' />

                    <Box className='options__wrapper'>

                      {
                        this.state.dummyData?.attributes?.screen_types?.map((screen_type: IScreenType) => (
                          <Chip
                            data-test-id="format-chip"
                            className="modified_chip"
                            label={screen_type.screen_type}
                            key={screen_type.screen_type_id}
                            variant={this.state.selectedScreenId === screen_type.screen_type_id ? "outlined" : "default"}
                            onClick={() => this.changeLanguageFormat(screen_type.screen_type_id, "selectedScreenId")} />
                        ))
                      }

                    </Box>

                  </RFHDialogContent>
                )}
              />

          </Container>

          <FooterWithEvents />
        </Box>
        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const BoxWrapper = styled(Box)({
  "& .circular__progress": {
    marginTop: "20px",
    color: "#ffb43a"
  },
  "& .hero__wrapper": {
    position: "relative",
    "& > img": {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      left: "0",
      top: "0",
      zIndex: -100
    },
    "&::before": {
      content: '""',
      top: 0,
      position: "absolute",
      width: "100%",
      left: 0,
      background: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.9),rgba(0,0,0,0.8),rgba(0,0,0,0.4), rgba(0,0,0,0.1))",
      height: "100%",
      zIndex: -99,
      backdropFilter: "blur(6px)",
    },
    "& .details__wrapper": {
      display: "flex",
      padding: "35px 45px 40px 0",
      justifyContent: "space-between",
      "& > img": {
        width: "320px",
        aspectRatio: 0.73,
      },
      "& .category__wrapper": {
        display: "flex",
        gap: "16px",
        margin: "20px 0",
        "& .category": {
          color: "#FFF",
          fontSize: "14px",
          border: "solid 0.5px #ffb43a",
          padding: "8px",
          borderRadius: "1px",
          fontFamily: "Poppins, sans-serif"
        },
      },
      "& .screen__types": {
        textTransform: "uppercase"
      },
      "& .release__date": {
        border: "solid 0.8px #fff",
        backgroundColor: "#2f2617",
        fontSize: "14px",
        fontFamily: "Poppins",
        borderRadius: "4px",
        padding: "8px",
        display: "inline-block"
      },
      "& .rating__wrapper": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        backgroundColor: '#404040',
        borderRadius: '8px',
        width: '388px',
        marginBottom: '14px',
        marginTop: '26px',
        boxSizing: "border-box",
        "& .rating__label": {
          display: 'flex', 
          justifyContent: 'space-between', 
          gridGap: '5rem', 
          alignItems: 'center',
          "& .rating__text": {
            display: "flex",
            flexDirection: "column",
            "& .rating__title": {
              display: 'flex',
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontWeight: 600,
              color: '#fff'
            },
            "& .rating__time": {
              fontFamily: 'Poppins',
              fontSize: '14px',
              color: '#b5b9b5'
            }
          },
          "& .star__wrapper": {
            display: 'flex', 
            alignItems: 'center', 
            gridGap: '5px'
          }
        }
      },
      "& .action-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "24px",
        "& .book_btn": {
          borderRadius: "10px",
          border: "none",
          padding: "12px 28px",
          fontWeight: 600,
          fontSize: "14px",
          "&.Mui-disabled": {
            backgroundColor: "#ffb43a",
            cursor: "not-allowed",
          }
        },
        "& .trailer__button" : {
          width: "28px",
          height: "28px",
          cursor: "pointer"
        }
      }
    }
  },
  "& .movie__description": {
    marginTop: "28px",
    "@media (max-width: 600px)": {
      marginTop: "0"
    }
  },
  "& .section__title": {
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins",
    marginBottom: "14px",
    "@media (max-width: 600px)": {
      fontSize: "16px",
      marginBottom: "12px"
    }
  },
  "& .section__description": {
    fontSize: "16px",
    "@media (max-width: 600px)": {
      fontSize: "12px",
    }
  },
  "& .seeall__label": {
    fontSize: "18px",
    color: "#ffb636",
    "@media (max-width: 600px)": {
      fontSize: "14px"
    }
  },
  "& .divider": {
    margin: "30px 0",
    border: "solid 0.5px #404040",
    "@media (max-width: 600px)": {
      margin: "22px 0"
    }
  }
})
const webStyle = {
  starStyle: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '5px'
  },
  ratingSpan: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#b5b9b5'
  },
  rating: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    color: '#fff'
  },
  mainPaperStyle: {
    display: 'flex',
    minHeight: "100vh",
    flexDirection: 'column' as const,
  },
  Tooltip: {
    backgroundColor: "#121212",
    border: "solid 1px #b4b4b4",
    color: "#b4b4b4"
  },
  tags: {
    height: '36px',
    margin: '23px 12px 93px 7px',
    padding: '8px 12px',
    borderRadius: ' 12px',
    border: 'solid 1px #b4b4b4',
    display: 'flex',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "#FFB43A"
    },
  },
  customDivider: {
    border: "solid 0.5px #979797",
    margin: "24px 0",
    height: 0
  },
  justify: {
    display: "flex",
    justifyContent: "center",
    gap: 20,
    marginBottom: "50px",
    marginLeft: "15px"
  },
  HeroWrapper: {
    position: 'relative',
    height: "640px",
    overflow: 'hidden'
  },
  imgPaperStyle: {
    content: '""',
    position: 'absolute' as const,
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: 1,
    filter: "blur(8px)",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  SectionWrapper: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2
  },
  gridBannerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 100px',
    zIndex: 100,
    width: '1240px',

  },
  bannerContent: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column' as const,
  },
  bannerImage: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: "35px"
  },
  tagtxt: {
    display: 'flex',
    alignItem: 'center',
    color: '#ffb53a',
    alignItems: 'center',
    fontFamily: 'Poppins'
  },
  bannerMovieImg: {
    width: '386px',
    height: '528px',
  },
  contentMovieName: {
    fontSize: '32px',
    marginBottom: '22px',
    marginTop: '68px',
    fontWeight: 500
  },
  contentMovieDuration: {
    fontSize: '16px',
    marginTop: '16px',
    fontWeight: 300,
  },
  contentMovieFormat: {
    fontSize: '16px',
    marginRight: '6px',
    marginTop: '16px',

  },
  contentMovieGenre: {
    fontSize: '16px',
    border: 'solid 0.5px #ffb53a',
    borderRadius: '1px',
    padding: '8px',
    boxSizing: 'border-box' as const,
  },
  boxGenre: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fontStyle: {
    fontFamily: 'Poppins', color: '#fff'
  },
  contentMovieDate: {
    width: 'fit-content',
    fontSize: '16px',
    border: 'solid 0.8px #fff',
    padding: '8px',
    boxSizing: 'border-box' as const,
    borderRadius: '4px',
    backgroundColor: '#2f2617',
  },
  boxRating: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#404040',
    width: '388px',
    height: '85px',
    marginTop: '30px',
    marginBottom: '14px'
  },
  boxRatingTitle: {
    fontSize: '18px',
    fontWeight: 'bold' as const,
    color: '#fffcfc',
    fontFamily: 'Poppins',
  },
  boxRatingSubTitle: {
    marginTop: '6px',
    fontSize: '14px',
    color: '#b4b4b4',
    fontFamily: 'Poppins',
  },
  btnRateStyle: {
    height: '38px',
    width: '107px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    border: ' solid 1px #ffb43a',
    padding: '10px 18px',
    boxSizing: 'border-box' as const,
    borderRadius: '8px',
    color: '#ffb43a',
    cursor: 'pointer',
    lineHeight: 1,
    textAlign: 'center' as const
  },
  displayRowStyle: {
    display: 'flex',
  },
  ratingstarStyle: {
    height: '24px',
    width: '24px',
    marginRight: '4px'
  },
  imgShareStyles: {
    height: '28px',
    width: '28px',
    cursor: "pointer"
  },
  votesStyle: {
    fontSize: '14px',
    fontWeight: 600,
    color: ' #b4b4b4',
    fontFamily: 'Poppins',
    marginLeft: '14px',
  },
  votesStyles: {
    fontSize: '12px',
    fontWeight: 600,
    color: ' #b4b4b4',
    fontFamily: 'Poppins',
  },
  ratingTextStyle: {
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "8px"
  },
  btnBookTicket: {
    height: '56px',
    width: '200px',
    backgroundColor: '#ffb43a',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontSize: '18px',
    color: '#000',
    fontWeight: 600,
    marginRight: '24px',
  },
  movieDescBoxStyle: {
    marginTop: '32px'
  },
  lineStyle: {
    height: '1px',
    backgroundColor: '#404040',
    border: 'none',
    margin: '32px 0',
  },
  SectionTitle: {
    marginBottom: "12px"
  },
  selecttxt: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    color: "#fff",
    marginBottom: "16px"
  },
  imgCastCrewStyle: {
    height: '152px',
    width: '152px',
    border: 'solid 1px #ffb43a',
    borderRadius: '4px',
    margin: '0 14px',
    color: '#fff',
    objectFit: 'cover' as const,
  },
  castcrewText: {
    margin: '18px 14px',
    fontSize: '16px',
    fontWeight: 600
  },
  loaderStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  reviewMainBox: {
    height: '364px',
    width: '288px',
    backgroundColor: '#404040',
    padding: '14px 22px',
    borderRadius: '6px',
    boxSizing: 'border-box' as const,
    marginRight: '22px',
    marginLeft: '6px',
  },
  boxPlayStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarStyle: {
    height: '52px',
    width: '52px',
    marginRight: '14px'
  },
  logoSmallStyle: {
    marginLeft: '6px',
    height: '18px',
    width: '18px',
  },
  likeContainer: {
    display: 'flex',
    bottom: '2px',
    position: 'absolute' as const,
  },
  likeBody: {
    display: 'flex',
    height: '18px',
    width: '18px',
    margin: '21px 6px',
    color: '#b4b4b4',
    fontSize: '12px',
    fontFamily: 'Poppins',
  },
  DialogStyle: {
    backgroundColor: "#404040",
    color: "#fff",
    padding: '32px',
    position: 'relative' as const,
    borderRadius: "8px",
    width: "400px"
  },
  CloseBtn: {
    position: 'absolute' as const,
    height: '28px',
    width: '28px',
    right: "12px",
    top: "12px",
    cursor: 'pointer'
  },
  DialogTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "24px",
    marginBottom: "32px"
  },
  DialogContent: {
    padding: 0,
    marginBottom: "32px"
  },
  DialogTitleRoot: {
    padding: 0
  },
  DialogActionsRoot: {
    padding: 0,
  },
  MobileHeader: {
    paddingTop: "12px",
    paddingBottom: "12px"
  },
  BackButton: {
    width: "24px",
    height: "24px",
    backgroundColor: "#ffb43a"
  },
  BackButtonIcon: {
    width: "16px",
    height: "16px",
  },
  IconWrapper: {
    height: "24px",
    width: "24px",
  },
  IconColor: {
    color: "#fff"
  },
  Poster: {
    height: "211px",
    width: "100%",
    objectFit: "fill" as const
  },
  MobileTrailerBtn: {
    width: "24px",
    height: "24px",
  },
  TrailerButton: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2
  },
  MTrailerText: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
  },
  BackDrop: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: "grey",
    opacity: 0.4,
  },
  CursorPointer: {
    cursor: "pointer"
  },
  PosterImage: {
    height: "158px",
    width: "145px",
    border: "solid 1px #ffb43a",
    overflow: "hidden"
  },
  ThumbnailPoster: {
    width: "100%",
    height: "100%",
    objectFit: "fill" as const
  },
  Title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
  },
  MovieTag: {
    padding: "4px 8px",
    color: "#fff",
    fontSize: "12px",
    border: "solid 0.5px #ffb43a",
    borderRadius: "1px",
    fontFamily: "Poppins, sans-serif"
  },
  TextTransform: {
    textTransform: "uppercase" as const,
  },
  Duration: {
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    fontSize: "12px",
    fontWeight: 200
  },
  LanguageFormat: {
    fontFamily: "Poppins, sans-serif",
    textTransform: "uppercase" as const,
    color: "#fff",
    fontSize: "10px",
    maxWidth: "185px",
    textWrap: "wrap",
    marginTop: "4px"
  },
  ReleaseDate: {
    color: "#fff",
    fontSize: "11px",
    padding: "4px 8px",
    border: "solid 0.5px #fff",
    borderRadius: "4px",
    backgroundColor: "#2f2617",
    display: "inline-block" as const,
    marginTop: "8px"
  },
  BtnMaxHeight: {
    maxHeight: "48px"
  },
  RatingText: {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Poppins, sans-serif",
    color: "#b4b4b4",
    marginLeft: "8px"
  },
  RatingIcon: {
    height: "16px",
    width: "16px"
  },
  MobileRatingWrapper: {
    padding: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    backgroundColor: "#404040"
  },
  LanguageFormatSpacing: {
    marginRight: "24px",
  }
}

const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  "& img": {
    width: "24px !important",
    height: "24px !important",
    margin: "0 !important"
  }
})

const RFHButton = styled(Button)({
  textTransform: "capitalize",
  color: "#000",
  borderRadius: "12px",
  backgroundColor: "#ffb43a",
  height: "56px",
  fontSize: "18px",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#ffb43a"
  },
  "@media (max-width: 600px)":{
    height: "48px",
    fontSize: "16px"
  }
})

const RFHDialogContent = styled(Box)({
  "& .header_title": {
    color: "#FFF",
    fontWeight: 600,
    fontSize: "22px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "center"
  },
  "& .title": {
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 600,
    marginBottom: "16px",
    fontSize: "18px",
  },
  "& .options__wrapper": {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px"
  },
  "& .divider": {
    height: "1px",
    margin: "24px 0",
    backgroundColor: "#979797",
  },
  "& .modified_chip": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins",
    padding: "12px 0",
    borderRadius: "8px",
    lineHeight: 1.2,
    cursor: "pointer",
    minHeight: "40px",
    color: "#FFF",
    backgroundColor: "#2f2617",
    border: "solid 1px #ffb43a",
    "&.MuiChip-outlined": {
      color: "#000",
      backgroundColor: "#ffb53a",
    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#ffb53a",
    }
  },
  "@media (max-width: 600px)": {
    "& .header_title": {
      fontSize: "16px !important",
    },
    "& .title": {
      fontSize: "14px !important",
    },
    "& .modified_chip": {
      fontSize: "12px !important",
      minHeight: "unset !important",
      padding: "10px 0 !important"
    },
    "& .divider": {
      margin: "18px 0 !important"
    },
  }
})

// Customizable Area End
