import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Card,
  Divider,
  OutlinedInput,
  Popover,
  DialogActions,
  TextareaAutosize,
} from "@material-ui/core";

import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import Loader from "../../../components/src/Loader.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { Back_arrow, close, success } from "../../contactus/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { edit_file, phone_call, send } from "./assets";

const howItWorkList = [
  {
    img: edit_file,
    heading:"Fill the form",
    description:"Give us the details of your event"
  },
  {
    img:send,
    heading:"Confirm and Submit",
    description:"Review your details and submit"
  },
  {
    img:phone_call,
    heading:"We will connect back shortly",
    description:"We will get back to you in no time!"
  },
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1:{
      fontSize:24,
      fontWeight:500,
      fontFamily:"Poppins",
      color:"#fff",
      marginBottom:5,
      textAlign:"center",
      '@media(max-width:700px)': {
        fontSize: "18px",
      },
    },
    h3:{
      fontFamily: 'Poppins',
        fontSize: '18px',
        color: '#ffffff',
        fontWeight:500,
        margin: 0,
        '@media(max-width:700px)': {
            fontSize: "15px",
        }
    },
    h4: {
      color: "#fff",
      fontSize: 14,
      fontWeight:"normal",
      fontFamily: "Poppins",
      marginTop:5,
      '@media(max-width:700px)': {
        fontSize: "14px",
      },
    },
  },
});

const CardComponent = styled(Card)(({ theme }) => ({
  background: "#252525",
  marginTop: 30,
  color: "#FFF",
  borderRadius: 8,
  padding: 30,
  width: "100%",
  marginBottom: 80,
  '@media(max-width:700px)': {
    padding: 10,
},
  "& .MuiPaper-root":{
    backgroundColor:'#929292 !important',
    color:'#fff'
  },
  "& .MuiDivider-root": {
    backgroundColor: "#b4b4b4"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "none",
    borderWidth: 0
  },
  "& .MuiOutlinedInput-input": {
    fontSize: '16px'
  },
  "& .MuiInputBase-inputMultiline": {
    color: '#fff'
  },
  "& .MuiSelect-iconOutlined": {
    color: '#ffb43a'
  },
  "& .MuiSvgIcon-root": {
    color: "#ffb43a",
  },
  "& .MuiPaper-elevation8": {
    boxShadow:"0px 0px 0px 0px !important",
  },
}));

const Dropdownbtn = styled(Button)(({ theme }) => ({
  height: "56px",
  padding: "13px 16px 12px",
  border: "solid 1px #b4b4b4",
  borderRadius: "8px",
  backgroundColor: "#404040",
  '&:hover': {
      backgroundColor: "#404040"
  },
  textTransform: "capitalize",
  fontFamily: "Poppins", 
  fontSize: "16px",
  alignItems:"center",
  width:"100%",
  justifyContent:"space-between",
  "& .MuiSvgIcon-root":{
     color: "#ffb43a" 
   },
}));

const TextareaComponent = styled(TextareaAutosize)(({ theme }) => ({
  border: "solid 1px #b4b4b4",
  borderRadius: "9px",
  backgroundColor: "#404040",
  color: "#ffffff",
  fontSize: "16px",
  height: "56px",
  width:"100%",
  padding:20,
  "&:focus" : {
    outline: "none"
  }
}));

// Customizable Area End

import AddEventDetailController, {
    Props,
    configJSON,
  } from "./AddEventDetailController";

export default class AccountDetails extends AddEventDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getDivStyle() {
    if (this.state.isDone || this.state.ishowItWorkOpen) {
      return webStyle.blurEffect;
    } else if (this.state.loading) {
      return webStyle.loading;
    } else {
      return webStyle.bgcolor;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div 
        style={this.getDivStyle()}>
        <Loader loading={this.state.loading} />
        <ThemeProvider theme={theme}>
          <MainHeaderComp navigation = {this.props.navigation} />
          <Container maxWidth={"lg"}>

            <Grid container style={webStyle.heading} spacing={3}>
              <Grid 
                direction="row"
                item
                xs={12}
                container
                alignItems="center"
                >
                <img
                  alt='backArrow'
                  src={Back_arrow}
                  data-test-id="back"
                  onClick={this.handleBackBtn}
                  style={webStyle.backArrow}
                />
                <Box 
                  style={webStyle.cardTitleStyle} 
                  component={"span"} 
                  id="contactText"
                >
                  List Your Show</Box>
              </Grid>
            </Grid>

            <Grid container spacing={3} style={webStyle.mainGrid}>
              <Grid container item xs={12} md={10}>
                <CardComponent >

                  <Grid item xs={12}>
                    <Box component={"div"} style={webStyle.cardTitleStyle}>Please Fill The Following Details</Box>
                    <Divider style={webStyle.divider} />
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>First Name</label>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <OutlinedInput
                        onChange={(event) => this.onChangeFirstName(event)}
                        name="name"
                        value={this.state.firstName}
                        placeholder="Enter First name"
                        fullWidth
                        data-test-id="first-Name"
                        style={webStyle.textInput} />
                    </div>
                    {this.state.firstNameError ? (
                      <span style={webStyle.formerror}>
                        {this.state.firstNameError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Last Name</label>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <OutlinedInput
                        onChange={(event) => this.onChangeLastName(event)}
                        name="lastName"
                        value={this.state.lastName}
                        placeholder="Enter Last name"
                        fullWidth
                        data-test-id="last-Name"
                        style={webStyle.textInput} />
                    </div>
                    {this.state.lastNameError ? (
                      <span style={webStyle.formerror}>
                        {this.state.lastNameError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Email id</label>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <OutlinedInput
                        onChange={(event) => this.onChangeEmail(event)}
                        name="email"
                        value={this.state.emailId}
                        placeholder="Enter Email id"
                        fullWidth
                        data-test-id="email-id"
                        style={webStyle.textInput} />
                    </div>
                    {this.state.emailIdError ? (
                      <span style={webStyle.formerror}>
                        {this.state.emailIdError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Phone Number</label>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <OutlinedInput
                        onChange={(event) => this.onChangePhoneNumber(event)}
                        name="phoneNumber"
                        type="tel"
                        value={this.state.phoneNumber}
                        placeholder="Your Contact number"
                        fullWidth
                        data-test-id="phone-number"
                        style={webStyle.textInput} />
                    </div>
                    {this.state.phoneNumberError ? (
                      <span style={webStyle.formerror}>
                        {this.state.phoneNumberError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Event Region</label>
                    </div>
                    <div>
                      <div style={{ marginTop: "16px" }}>
                        <MenuButton
                          data-test-id="event-region"
                          obj={{
                            title: "Select Region",
                            options: this.state.eventRegionList
                          }}
                          keyName="region"
                          listHeading="Select your Region"
                          transectionfilterInput={this.state.eventRegion}
                          handleSelectTransection={this.onSelectEventRegion}
                        />
                      </div>

                    </div>
                    {this.state.eventRegionError ? (
                      <span style={webStyle.formerror}>
                        {this.state.eventRegionError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Event City</label>
                    </div>
                    <div>
                      <div style={{ marginTop: "16px" }}>
                        <MenuButton
                          data-test-id="event-city"
                          obj={{
                            title: "Select City",
                            options: this.state.eventCityList
                          }}
                          transectionfilterInput={this.state.eventCity}
                          handleSelectTransection={this.onSelectEventCity}
                        />
                      </div>

                    </div>
                    {this.state.eventCityError ? (
                      <span style={webStyle.formerror}>
                        {this.state.eventCityError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Event Type</label>
                    </div>
                    <div>
                      <div style={{ marginTop: "16px" }}>
                        <MenuButton
                          data-test-id="event-type"
                          obj={{
                            title: "Select Event type",
                            options: this.state.eventTypeList
                          }}
                          keyName="event_type"
                          transectionfilterInput={this.state.eventType}
                          handleSelectTransection={this.onSelectEventType}
                        />
                      </div>

                    </div>
                    {this.state.eventTypeError ? (
                      <span style={webStyle.formerror}>
                        {this.state.eventTypeError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Expected Audience</label>
                    </div>
                    <div>
                      <div style={{ marginTop: "16px" }}>
                        <MenuButton
                          data-test-id="expected-audience"
                          obj={{
                            title: "Select Range",
                            options: this.state.expectedAudienceList
                          }}
                          keyName="people"
                          transectionfilterInput={this.state.expectedAudience}
                          handleSelectTransection={this.onSelectExpectedAudience}
                        />
                      </div>

                    </div>
                    {this.state.expectedAudienceError ? (
                      <span style={webStyle.formerror}>
                        {this.state.expectedAudienceError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Event Date</label>
                    </div>
                    <div>
                      <div style={{ marginTop: "16px" }}>
                        <MenuButton
                          data-test-id="event-date"
                          obj={{
                            title: "Select Event Date",
                            options: this.state.eventDateList
                          }}
                          keyName="date_between"
                          transectionfilterInput={this.state.eventDate}
                          handleSelectTransection={this.onSelectEventDate}
                        />
                      </div>

                    </div>
                    {this.state.eventDateError ? (
                      <span style={webStyle.formerror}>
                        {this.state.eventDateError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid item xs={12} md={11}>
                    <div style={webStyle.label}>
                      <label style={webStyle.labelStyle}>Event Description</label>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <TextareaComponent
                        onChange={(event) => this.onChangeDescription(event)}
                        name="description"
                        value={this.state.eventDescription}
                        placeholder="Maximum 500 Characters"
                        data-test-id="event-description"
                        minRows={3}
                        maxRows={3} 
                      />
                    </div>
                    {this.state.eventDescriptionError ? (
                      <span style={webStyle.formerror}>
                        {this.state.eventDescriptionError}
                      </span>
                    ) : (<></>)}
                  </Grid>

                  <Grid
                    item
                    xs={12} md={11}
                    style={{
                      marginTop: "52px",
                      display: "flex",
                      justifyContent: "end"
                    }}
                  >
                    <Button
                      data-test-id="submitBtn"
                      variant="contained"
                      color="primary"
                      style={webStyle.submitBtn}
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>

                </CardComponent>
              </Grid>
            </Grid>

            {/* how it work popup */}
            <Dialog
              id="language"
              open={this.state.ishowItWorkOpen}
              PaperProps={{
                style: {
                  backgroundColor: "#404040",
                  color: "#fff",
                  padding: '48px 32px',
                  maxWidth: "456px",
                  boxSizing: "border-box",
                  width: "95%",
                },
                elevation: 0,
              }}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              data-test-id="how_it_work"
            >
              <img
                src={close}
                alt='closebutton'
                data-test-id="crossBtn"
                style={webStyle.crossBtn}
                onClick={() => this.setState({ ishowItWorkOpen: false })}
              />
              <DialogContent
                data-test-id="seat_number">

                <Typography variant="h1">{configJSON.labelHowItWork}</Typography>
                {howItWorkList.map((elm: any, inx: number) => <Box key={inx} style={webStyle.howItWorkBox}>
                  <img src={elm.img} style={webStyle.howItWorkIcon} />
                  <Box>
                    <Typography variant="h3">{elm.heading}</Typography>
                    <Typography variant="h4">{elm.description}</Typography>
                  </Box>
                </Box>)}

              </DialogContent>
            </Dialog>

            {/* sucssess popup */}
            <Dialog
              id="language"
              open={this.state.isDone}
              PaperProps={{
                style: {
                  backgroundColor: "#404040",
                  color: "#fff",
                  padding: '28px 32px 48px ',
                  maxWidth: "456px",
                  boxSizing: "border-box",
                  width: "95%",
                },
                elevation: 0,
              }}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              data-test-id="successfully_done"

            >
              <DialogContent style={{ textAlign: "center" }} data-test-id="seat_number">
                <img src={success} alt="success"
                  color="primary"
                  style={webStyle.successImg}
                />
                <Typography variant="h3" style={{fontWeight:600, margin:"20px 0px 15px"}}>Request Submitted Successfully!</Typography>
                <Typography variant="h4" style={{marginTop:15, fontSize:16}}>We will get back to you shortly.</Typography>

              </DialogContent>
              <DialogActions >
                <Button
                  data-test-id="doneBtn"
                  variant="contained"
                  color="primary"
                  style={webStyle.doneBtn}
                  onClick={this.handleDone}
                >
                  Done
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
          <FooterWithEvents />
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const MenuButton = ({ obj, transectionfilterInput, handleSelectTransection, keyName, listHeading }: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Dropdownbtn 
        style={
          transectionfilterInput.id ? { color: "#ffffff", fontWeight: 500 } : { fontWeight: "normal", color: "#b4b4b4" }} 
        data-test-id="drop-down-btn" 
        onClick={handleClick}
      >
        {transectionfilterInput.id ? transectionfilterInput.lable : obj.title}
        {open ? (
          <ExpandLessIcon style={webStyle.downarrow} />
        ) : (
          <KeyboardArrowDownIcon style={webStyle.downarrow} />
        )}
      </Dropdownbtn>

      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            borderRadius: '12px',
            marginTop: "12px",
            width: "100%",
            maxWidth: "343px",
            background: "#404040",
            padding: "19px 24px",
            maxHeight: "412px",
            boxShadow: "none",
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        data-test-id="anchorbtn"
      >
        <Typography style={{marginBottom:5,...webStyle.defaultOption}}>{listHeading ?? obj.title}</Typography> 
        {obj?.options?.map((list: any, index: number) => {
          return (
            <Box
              data-test-id="listselected"
              onClick={() => {
                handleSelectTransection(list.id, list[keyName] ?? list?.attributes?.name);
                handleClose()
              }}
              key={index}
            >
              <Typography style={webStyle.listtxt}>
                {list[keyName] ?? list?.attributes?.name}
              </Typography>
            </Box>
          )
        })}
      </Popover>
    </>
  )
}

const webStyle = {
  blurEffect: {
    overflow: "hidden",
    backgroundColor: "#121212",
    '-webkit-filter': 'blur(2px)',
    '-moz-filter': 'blur(2px)',
    '-o-filter': 'blur(2px)',
    '-ms-filter': 'blur(2px)',
    filter: 'blur(2px)',
  },
  loading: {
    overflow: "hidden",
    backgroundColor: "#121212",
  },
  bgcolor: {
    backgroundColor: "#121212"
  },
  crossBtn: {
    height: '28px',
    cursor: 'pointer',
    position: "absolute",
    top: 8,
    right: 8
  } as CSSProperties,
  dialogContent: {
    textAlign: "center",
}as CSSProperties,
howItWorkBox:{
  display:"flex",
  marginTop:30,
  alignItems:"center"
},
howItWorkIcon:{
  height:32,
  weight:32,
  marginRight:15
},
backArrow:{
  heigth:"32px",
  width:"32px",
  marginRight:10,
  cursor:"pointer"
},
heading: {
  marginTop: 30
},
cardTitleStyle: {
  fontFamily: 'Poppins',
  fontSize: 24,
  fontWeight: 600,
  color: "#FFF"
},
divider:{ 
  marginTop: "14px", 
  background: "#b4b4b4" 
},
mainGrid: {
  marginBottom: '80px'
},
labelStyle: {
  fontFamily: 'Poppins',
  fontSize: 18,
  fontWeight: 500
},
label: {
  marginTop: "24px"
},
submitBtn: {
  padding: '12px 80px',
  backgroundColor: '#ffb43a',
  borderRadius: 12,
  textTransform: 'capitalize' as const,
  color: '#202020',
  fontFamily: "Poppins",
  fontSize: 18,
  fontWeight: 600,
  "&:hover": {
    backgroundColor: '#ffb43a',
  }
},
textInput: {
  border: "solid 1px #b4b4b4",
  borderRadius: "9px",
  backgroundColor: "#404040",
  color: "#ffffff",
  fontSize: "16px",
  height: "56px",
},
selectLable: {
  borderRadius: "9px",
  color: "#ffffff",
  border: "solid 1px #b4b4b4",
  marginTop: "15px  ",
  padding: 0,
  background: "#404040"
},
defaultOption:{
  fontFamily:'Poppins',
  fontSize: '16px',
  color:'#b4b4b4'
},
optionsStyle: {
  backgroundColor: "#404040",
  color: '#ffffff',
  borderBottom: "1px solid #979797",
  padding:"10px 16px"
},
downarrow: {
  color: '#ffb43a'
},
listtxt: {
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  fontFamily: "Poppins",
  textTransform: "capitalize",
  fontWeight:500,
  marginBottom:5,
  cursor:"pointer"
}as CSSProperties,
formerror: {
  color: "red",
  marginTop: 10,
  fontFamily: "Poppins"
},
successImg:{
  height: '48px', 
  width: '48px'
},
doneBtn:{
  padding: '12px 80px',
  width:"100%",
  backgroundColor: '#ffb43a',
  borderRadius: 12,
  textTransform: 'capitalize' as const,
  color: '#202020',
  fontFamily: "Poppins",
  fontSize: 18,
  fontWeight: 600,
  "&:hover": {
    backgroundColor: '#ffb43a',
  }
},
}
// Customizable Area End
