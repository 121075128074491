import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL } from "../../../components/src/utilities";
import CfWalletAdapter from "../../adapters/src/CfWalletAdapter";
import { paymentFailed, paymentSucessfull } from "./assets";

const walletAdapterObj = new CfWalletAdapter();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean,
  beneficiaryDetails:any,
  amount:any,
  transectionStatusPopupContent:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentInfoController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    beneficiaryDetailsApi = ""
    transferMoneyToBankApi = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading:false,
            beneficiaryDetails:{},
            amount:"",
            transectionStatusPopupContent:{}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          if (responseJson) {
            if (apiRequestCallId === this.beneficiaryDetailsApi) {
              this.setState({beneficiaryDetails:responseJson.data})
            }
            if(apiRequestCallId === this.transferMoneyToBankApi){
              this.setState({
                transectionStatusPopupContent: 
                  responseJson.status === "ACCEPTED" 
                  ? {image:paymentSucessfull, message:"Transaction Successful!"} 
                  : {image:paymentFailed, message:"Transaction Failed!"}
              })
            }
            this.setState({ loading: false });
          }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
      let amount = await getStorageData("amountTransfered",true);
      this.setState({amount:amount})
      this.getBeneficiaryDetails();
    }

    getBeneficiaryDetails = async() => {
      this.setState({ loading: true })
      const id = getLastPartOfURL()
      this.beneficiaryDetailsApi = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: configJSON.beneficiaryDetailsEndPoint+id,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    handleProceed = async() => {
      this.setState({ loading: true })
      const httpBody = {
        beneId:getLastPartOfURL(),
        amount:this.state.amount,
        transferId:this.state.beneficiaryDetails.ifsc.slice(0,4) + new Date().getTime()
      }
      this.transferMoneyToBankApi = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.transferMoneyToBankEndPoint,
        body: httpBody,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    navigateToWallet = () => {
      const msg = new Message(getName(MessageEnum.NavigationWallet))
      msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
      walletAdapterObj.send(msg)
    }

    handleBackBtn = () => {
      this.props.navigation.goBack();
    }
    // Customizable Area End
}
