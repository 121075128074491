import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IBookingData, IEventBookingFullResponse } from "../../../components/src/interfaces.web";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { getLastPartOfURL } from "../../../components/src/utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  seatsSelected: boolean;
  isModalOpen: boolean;
  eventPrice: any;
  eventBookingData: IEventBookingFullResponse;
  seatID: any[];
  eventListToSellResponse: any;
  priceError: any;
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventListToSellController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    eventBookingHistoryApiID: any;
    addListToSellApiID: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            seatsSelected: false,
            isModalOpen: false,
            eventPrice: null,
            eventBookingData: {} as IEventBookingFullResponse,
            seatID: [],
            eventListToSellResponse: {},
            priceError: '',
            isLoading:true,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

          if (apiRequestCallId === this.eventBookingHistoryApiID) {
              this.setState({ eventBookingData: resJson && resJson.data, isLoading:false })
          }
          if (apiRequestCallId === this.addListToSellApiID) {
            this.handleReceiveResponse(resJson)
        }

      }
        // Customizable Area End
    }

    // Customizable Area Start
    
    async componentDidMount() {
      window.scrollTo(0, 0);
      this.getEventBookingData()
  }

  getEventBookingData = async () => {
      const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
      const bookingId = getLastPartOfURL()
      this.eventBookingHistoryApiID = callApi({
          contentType: configJSON.apiContentType,
          method: configJSON.GETMethod,
          endPoint: `${configJSON.getEventBookingApiEndPoint.replace(":id", bookingId)}`,
          headers: { "token": authToken },
      }, runEngine)
  }

  isStringNull = (str: string) => {
    return str === null || str?.length === 0
  }

  isPriceValid = (data?: string) => {
    if (this.isStringNull(data || this.state.eventPrice)) {
      this.setState({ priceError: 'Enter a Price' })
    }
    else {
        this.setState({ priceError: '' })
    }
}

handleChange = (e: any) => {
  const inputValue = e.target.value;
  let regex = /^\d+$/;
  const isValidInput = regex.test(inputValue);
  if (inputValue > 49999) {
      this.setState({ priceError: 'Enter less than 49999 amount' })
  }
  else {
      this.setState({ eventPrice: isValidInput ? inputValue : '', priceError: '' });
  }
}

handleSelectTicket = (seatId: number) => {
  const { eventBookingData, seatID } = this.state;
  // Check if the seat is already selected
  const isSeatSelected = seatID.some((selectedSeat: any) => selectedSeat.id === seatId);
  
  if (isSeatSelected) {
      // Deselect the seat
      const updatedSeats = seatID.filter((selectedSeat: any) => selectedSeat.id !== seatId);
      this.setState({ seatID: updatedSeats, seatsSelected: updatedSeats.length > 0 });
  } else {
      // Check if the seat is available for selection
      let selectedSeat = eventBookingData.attributes.seats.find((seat: any) => {return(seat.id === seatId)});
      if (selectedSeat) {
         this.setState({ seatID: [...seatID, selectedSeat], seatsSelected: true ,priceError:''});
      }
  }
}

handleOnClickListToSell = async () => {
  const { eventPrice, seatID } = this.state
  // const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
  const bookingId = getLastPartOfURL();
  const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

  if (eventPrice === '' || isNaN(parseFloat(eventPrice))) {
      this.setState({ priceError: 'Enter a Price' })
      return
  }

  const seatIds = seatID.map((seat: any) => seat.id)
  const body = {
    "event_booking_request": {
        "event_booking_id": bookingId, 
        "price_per_seat" : eventPrice,
        "no_of_seats": seatIds.length,
        "seat_ids": seatIds
    }
}
  this.addListToSellApiID = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.POSTMethod,
      endPoint: `${configJSON.addEventListToSell}`,
      headers: { "token": authToken },
      body: body
  }, runEngine)
}

handleReceiveResponse = (res:any) =>{
  if (res.data) {
    this.setState({ 
      eventListToSellResponse: res.data, 
      isModalOpen: true, 
      eventPrice: '', 
      priceError: '' 
    })
}
else{
    this.setState({priceError: res.error.listed_ticket[0]})
}
}

handleBack = () =>{
  this.props.navigation.goBack();
}

navigateToViewTicket = () =>{
  const bookingId = getLastPartOfURL()
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "EventViewTicket");
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  msg.addData(getName(MessageEnum.NavigationScreenNameMessage), bookingId);
  this.send(msg)
}
    // Customizable Area End
}
