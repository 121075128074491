Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'

exports.getMovieEventEndPoint = "bx_block_categories/currently_playing/currently_playing";
exports.getAllEventEndPoint="bx_block_events/events";
exports.getLanguageEndPoint="bx_block_events/events/event_languages";
exports.guestToken = "guest_user";
exports.uaText = "UA";
exports.movieText = "Movie";
exports.pageTitle = "Advanced Search";
// Customizable Area End
