import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  IconButton,
  Popper,
  Popover,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Fade,
  CircularProgress,
  Grid,
  Divider,
  Button,
} from "@material-ui/core";

import { createTheme, makeStyles, styled, ThemeProvider } from "@material-ui/core/styles";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { RequestFilterOption } from "../../../components/src/enums.web";
import { convertTo12HourFormat, convertToPad, goToPrevPage } from "../../../components/src/utilities";
import { Seat } from "../../../components/src/BookingSuccessfulTicket.web";
import moment from "moment";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { IRequestedBookingResponse, ISelectOption } from "../../../components/src/interfaces.web";
import { FilterIcon } from "./assets";



const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h6: {
      color: "#fff",
      fontSize: "20px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "18px",
      }
    },
    body1: {
      color: "#fff",
      fontSize: "18px",
      lineHeight: 1.25,
      "@media (max-width: 600px)": {
        fontSize: "14px",
        lineHeight: 1.5
      }
    },
    body2: {
      color: "#FFF",
      fontSize: "18px",
      lineHeight: 1.25,
      fontWeight: 500,
      "@media (max-width: 600px)": {
        fontSize: "14px",
        lineHeight: 1.5

      }
    }
  }
});

const RFHMenuList = styled(MenuList)({
  padding: "0 !important",
  minWidth: "190px",
  "& > li:nth-child(odd)": {
    color: "#fff",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    minHeight: "42px",
    border: "solid 1px #5a5a5a !important",
    backgroundColor: "#5a5a5a !important",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "#ffb43a !important",
      border: "none"
    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#ffb43a !important"
    }
  },
  "& > li:nth-child(even)": {
    color: "#fff",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    minHeight: "42px",
    border: "solid 1px #5a5a5a !important",
    backgroundColor: "#404040 !important",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "#ffb43a !important",
      border: "none"

    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#ffb43a !important"
    }
  }
})

interface IRequestBookingProps {
  redirectToConfirmPayment: (ticketId: number) => void
  redirectToLiveRequest: (ticketId: number) => void
  request: IRequestedBookingResponse
}

interface IMobileBookingProps {
  redirectToConfirmPayment: (ticketId: number) => void
  redirectToLiveRequest: (ticketId: number) => void

  request: IRequestedBookingResponse
}

const FilterOptionList: ISelectOption<RequestFilterOption>[] = [
  { label: "All", value: RequestFilterOption.All },
  { label: "View Requests", value: RequestFilterOption.ViewRequests },
  { label: "Request Approved", value: RequestFilterOption.RequestApproved },
  { label: "Request Declined", value: RequestFilterOption.RequestDeclined },
  { label: "Request Pending", value: RequestFilterOption.RequestPending },
  { label: "Request Timed Out", value: RequestFilterOption.RequestTimeOut }
]
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Box style={webStyle.MainBackgroundWrapper}>

          <Box display={{ xs: "none", sm: "flex"}} style={webStyle.DesktopWrapper}>

            <Box>
            <MainHeaderComp navigation = {this.props.navigation} />
            </Box>

            <Box flex={1}>
              <Container>
                <Box display="flex" justifyContent="space-between" mt={3} mb={3}>

                  <Box display="flex" alignItems="center">
                    <IconButton style={webStyle.BackButton} data-test-id="button" onClick={() => goToPrevPage()}>
                      <ArrowBackIosOutlined style={webStyle.BackButtonIcon} />
                    </IconButton>

                    <Typography variant="h6">{configJSON.RequestTitle}</Typography>
                  </Box>

                  <Box display="flex" alignItems="center" style={webStyle.FilterWrapper}>
                    <IconButton
                      style={webStyle.FilterIcon}
                      onClick={this.handleClick}
                      aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.anchorEl ? 'true' : undefined}
                      data-test-id="filter-menu-button"
                    >
                      <img src={FilterIcon} width="22px" height="22px" alt="filter-button" />
                      <Typography variant="subtitle2" style={webStyle.FilterText}>{configJSON.Filter}</Typography>
                    </IconButton>

                      <Popover
                        onClose={this.handleClose}
                        anchorEl={this.state.anchorEl}
                        open={!!this.state.anchorEl}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}

                        PaperProps={{
                          style: {
                            boxShadow: "none",
                            borderRadius: "12px",
                            background: 'transparent !important'
                          },
                        }}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}
                      >
                          <RFHMenuList
                            id="composition-menu"
                            autoFocusItem={!this.state.anchorEl}
                            data-test-id="filter-menu-root"
                          >
                            {
                              FilterOptionList.map(option => (
                                <MenuItem
                                  key={option.value}
                                  data-test-id="filter-menu"
                                  selected={this.state.selectedFilter === option.value}
                                  onClick={() => this.handleFilterChange(option.value)}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            }

                          </RFHMenuList>
                      </Popover>

                  </Box>

                </Box>

                <Box display="flex" flexDirection="column" gridGap="25px" style={webStyle.ListingWrapper}>

                  {
                    this.state.isLoading ? (
                      <Fade in={this.state.isLoading} unmountOnExit>
                        <Box display="flex" justifyContent="center">
                          <CircularProgress style={webStyle.CircularProgress} />
                        </Box>
                      </Fade>
                    ) : (
                      <Box display="flex" flexDirection="column" gridGap="25px" data-test-id="ticket-listing-wrapper">
                        {
                          this.state.requestList.length ? (
                            this.state.requestList.map((bookingReuqest: IRequestedBookingResponse) => (
                              <RequestBooking key={bookingReuqest.id} request={bookingReuqest} redirectToConfirmPayment={this.redirectToConfirmPayment} redirectToLiveRequest={this.redirectToLiveRequest} />
                            ))
                          ) : (
                            <Typography align="center" variant="h6">{configJSON.NoDataFound}</Typography>
                          )
                        }
                      </Box>
                    )
                  }


                </Box>

              </Container>
            </Box>

            <Box>
            <FooterWithEvents />
            </Box>

          </Box>

          <Box display={{ xs: "block", sm: "none" }} style={webStyle.MobileWrapper}>
            <Box style={webStyle.MinHeight}>

              <Box style={webStyle.MHeader}>
                <Container>
                  <Box pb={2} pt={2} display="flex" justifyContent="space-between" >

                    <Box display="flex" alignItems="center">
                      <IconButton data-test-id="secondary-button" onClick={() => goToPrevPage()} style={webStyle.MBackButton} >
                        <ArrowBackIosOutlined style={webStyle.MBackButtonIcon} />
                      </IconButton>

                      <Typography variant="h6">{configJSON.RequestTitle}</Typography>
                    </Box>

                    <IconButton
                      onClick={this.handleClick}
                      aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.anchorEl ? 'true' : undefined}
                    >
                      <img src={FilterIcon} width="22px" height="22px" alt="filter-button" />
                    </IconButton>

                      <Popover
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        open={!!this.state.anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                          style: {
                            borderRadius: "12px",
                            boxShadow: "none",
                            background: 'transparent !important'
                          },
                        }}
                      >
                          <RFHMenuList
                            id="composition-menu"
                            autoFocusItem={!this.state.anchorEl}
                          >
                            {
                              FilterOptionList.map(option => (
                                <MenuItem
                                  data-test-id="filter-menu"
                                  key={option.value}
                                  onClick={() => this.handleFilterChange(option.value)}
                                  selected={this.state.selectedFilter === option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            }
                          </RFHMenuList>
                      </Popover>

                  </Box>
                </Container>
              </Box>

              <Container>
                <Box display="flex" gridGap="25px" flexDirection="column" pt={2} pb={2}>

                  {
                    this.state.isLoading ? (
                      <Fade in={this.state.isLoading} unmountOnExit>
                        <Box display="flex" justifyContent="center">
                          <CircularProgress style={webStyle.CircularProgress} />
                        </Box>
                      </Fade>
                    ) : (
                      <>
                        {
                          this.state.requestList.length ? (
                            this.state.requestList.map((bookingReuqest: IRequestedBookingResponse) => (
                              <MobileRequestBooking key={bookingReuqest.id} request={bookingReuqest} redirectToConfirmPayment={this.redirectToConfirmPayment} redirectToLiveRequest={this.redirectToLiveRequest}/>
                            ))
                          ) : (
                            <Typography align="center" variant="h6">{configJSON.NoDataFound}</Typography>
                          )
                        }
                      </>
                    )
                  }

                </Box>
              </Container>

            </Box>
          </Box>

        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  DesktopWrapper: {
    flexDirection: "column" as const,
    minHeight: "100vh"
  },
  MainBackgroundWrapper: {
    backgroundColor: "#121212"
  },
  BackButton: {
    width: "28px",
    height: "28px",
    backgroundColor: "#ffb43a",
    marginRight: "20px"
  },
  BackButtonIcon: {
    width: "16px",
    height: "16px"
  },
  FilterIcon: {
    color: "#ffb43a",
    padding: 0
  },
  FilterText: {
    fontSize: "16px",
    color: "#fff",
    fontFamily: "Poppins",
    marginLeft: "12px"
  },
  zIndex: {
    zIndex: 2,
    top: "16px !important",
  },
  FilterWrapper: {
    marginRight: "48px"
  },
  ListingWrapper: {
    paddingLeft: "48px",
    paddingRight: "48px",
  },
  CircularProgress: {
    marginTop: "20px",
    color: "#ffb43a"
  },
  MinHeight: {
    minHeight: "100vh"
  },
  MobileWrapper: {
    minHeight: "100vh",
    backgroundColor: "#404040"
  },
  MHeader: {
    backgroundColor: "#121212"
  },
  MBackButton: {
    width: "24px",
    height: "24px",
    backgroundColor: "#ffb43a",
    marginRight: "16px",
  },
  MBackButtonIcon: {
    width: "14px",
    height: "14px"
  },
  MListingWrapper: {
    display: "flex",
    alignItems: ""
  }
};

const getMessageLabel = (bookingStatus : RequestFilterOption, liveRequest: boolean): string => {

  if(liveRequest) return ''

  switch (bookingStatus) {
    case RequestFilterOption.RequestPending:
      return configJSON.RequestPending;

    case RequestFilterOption.RequestTimeOut:
      return configJSON.RequestTimeout;

    case RequestFilterOption.RequestDeclined:
      return configJSON.RequestDeclined;

    case RequestFilterOption.RequestApproved:
      return configJSON.RequestApproved;

    default: 
      return ""
  }
}

const getButtonByStatus = (
  bookingStatus: RequestFilterOption, 
  liveRequest: boolean,
  ticketId: number,
  redirectToConfirmPayment: (id: number) => void,
  redirectToLiveRequest: (id: number) => void
  ) : React.ReactElement | null => {

  if(bookingStatus === RequestFilterOption.RequestApproved) {
    return (
      <Button className="submit-button" data-test-id="confirm-payment-redirect-btn" onClick={() => redirectToConfirmPayment(ticketId)}>
        {configJSON.ConfirmPayment}
      </Button>
    )
  } else if( bookingStatus === RequestFilterOption.RequestPending && liveRequest) {
    return (
      <Button className="submit-button" data-test-id="view-request-btn" onClick={() => redirectToLiveRequest(ticketId)}>
        {configJSON.ViewRequest}
      </Button>
    )
  } else {
    return null
  }
}

const getBookingStatusLabel = (bookingStatus: RequestFilterOption, liveRequest: boolean): React.ReactElement | null => {

  if(liveRequest) return null

  let labelString: string;
  let filteredClassName: string;
  switch (bookingStatus) {
    case RequestFilterOption.RequestPending:
      labelString = configJSON.PendingLabel;
      filteredClassName = "status-pending"
      break;

    case RequestFilterOption.RequestTimeOut:
      labelString = configJSON.TimeoutLabel;
      filteredClassName = "status-time-out"
      break;

    case RequestFilterOption.RequestDeclined:
      labelString = configJSON.DeclinedLabel;
      filteredClassName = "status-declined"
      break;

    case RequestFilterOption.RequestApproved:
      labelString = configJSON.ApprovedLabel;
      filteredClassName = "status-approved"
      break;

    default:
      labelString = "";
      filteredClassName = "";
  }
  
  return (labelString ? (
    <div className={`status-label ${filteredClassName}`}>
      <span>
        {labelString}
      </span>
    </div>
   ) : null)
}

const useStylesWeb = makeStyles(({
  DisplayFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  Ticket: {
    backgroundColor: "#252525",
    display: "flex",
    borderRadius: "12px",
    border: "solid 0.2px #b4b4b4",
    boxSizing: "border-box",
    padding: "48px 32px",
    position: "relative",
    justifyContent: "space-between",
    "&::before": {
      position: "absolute",
      content: "''",
      left: "50%",
      top: "-1px",
      transform: "translate(-50%, 0)" as const,
      width: "40px",
      backgroundColor: "#121212",
      height: "20px",
      borderRadius: "0 0 30px 30px",
      border: "solid 0.2px #b4b4b4",
      borderTop: 'none'
    },
    "&::after": {
      position: "absolute",
      content: "''",
      left: "50%",
      bottom: "-1px",
      transform: "translate(-50%, 0)" as const,
      width: "40px",
      backgroundColor: "#121212",
      height: "20px",
      borderRadius: "30px 30px 0 0",
      border: "solid 0.2px #b4b4b4",
      borderBottom: 'none'
    },
    "& .status-label": {
      color: "#000",
      position: "absolute",
      fontSize: "14px",
      transform: 'rotate(-45deg)',
      textTransform: "uppercase",
      fontWeight: 500,
      fontFamily: "Poppins, sans-serif",
      height: 0,
      textAlign: "center",
      borderLeft: "solid 20px transparent",
      borderBottom: "solid 20px #ffb43a",
      borderRight: "solid 20px transparent",
      top: "20px",
      width: "80px",
      left: "-30px",
      "&::before": {
        position: 'absolute',
        content: "''",
        left: "-20px",
        bottom: "-23px",
        borderLeft: "solid 3px transparent",
        borderTop: "solid 3px #D09432",
        borderRight: "solid 3px transparent"
      },
      "&::after": {
        position: 'absolute',
        content: "''",
        right: "-19px",
        top: "20px",
        borderLeft: "solid 3px transparent",
        borderTop: "solid 3px #D09432",
        borderRight: "solid 3px transparent"
      },
      "&.status-pending": {
        borderBottom: "solid 20px #ffb43a",
        "&::after": {
          borderTop: "solid 3px #D09432",
        },
        "&::before": {
          borderTop: "solid 3px #D09432",
        },
      },
      "&.status-time-out": {
        borderBottom: "solid 20px #ff3b30",
        "&::after": {
          borderTop: "solid 3px #d0332a",
        },
        "&::before": {
          borderTop: "solid 3px #d0332a",
        },
      },
      "&.status-approved": {
        borderBottom: "solid 20px #05d848",
        "&::after": {
          borderTop: "solid 3px #07b23d",
        },
        "&::before": {
          borderTop: "solid 3px #07b23d",
        },
      },
      "&.status-declined": {
        borderBottom: "solid 20px #ff3b30",
        "&::after": {
          borderTop: "solid 3px #d0332a",
        },
        "&::before": {
          borderTop: "solid 3px #d0332a",
        },
      }
    }
  },
  MaxWidth: {
    maxWidth: "375px"
  },
  PosterWrapper: {
    alignItem: "center",
    justifyContent: "center",
    border: "solid 0.7px #ffb53a",
    marginRight: "20px"
  },
  MovieTitle: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#fff",
    marginBottom: "8px"
  },
  PosterImage: {
    width: "212px",
    height: "270px",
    objectFit: "cover"
  },
  FormatAndLanguage: {
    textTransform: "uppercase",
    marginBottom: "8px"
  },
  ScreenDetails: {
    maxWidth: "375px",
  },
  SeatWrapper: {
    maxWidth: "146px",
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    "& > div": {
      "& div": {
        backgroundColor: "#ffb43a !important"
      }
    }
  },
  Divider: {
    margin: "24px 0 16px 0",
    backgroundColor: "#b4b4b4"
  },
  TicketDivider: {
    border: 'none',
    borderBottom: "dashed 2px #b1b1b1",
    margin: "22px 0"
  },
  TicketTotal: {
    fontSize: "18px",
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
    "& > span": {
      fontFamily: "Roboto"
    }
  },
  ScreenLabel: {
    fontSize: "16px",
    color: "#b4b4b4",
    fontFamily: "Poppins",
    textTransform: "capitalize" as const
  },
  SubmitButton: {
    backgroundColor: "#ffb43a",
    color: "#202020",
    padding: "14px 36px",
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "capitalize",
    maxHeight: "56px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: "#202020",
    }
  },
  Message: {
    fontSize: "16px",
    margin: "auto 0px"
  },
  TicketTotalSign: {
    fontFamily: "Roboto",
    paddingRight: "8px"
  },
  TextTransform: {
    textTransform: "capitalize"
  },
  MinHeightContainer: {
    minHeight: "56px",
    "& .submit-button": {
      backgroundColor: "#ffb43a",
      color: "#202020",
      padding: "14px 36px",
      fontWeight: 600,
      fontSize: "18px",
      textTransform: "capitalize",
      maxHeight: "56px",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ffb43a",
        color: "#202020",
      }
    }
  }
}))

export const RequestBooking: React.FC<IRequestBookingProps> = (props) => {

  const classes = useStylesWeb()

  const { 
    request: {
      id,
      attributes: {
        movie_name,
        movie_image,
        movie_languages,
        movie_screen_types,
        theatre_name,
        seat_number,
        total_amount,
        seat_type,
        request_id,
        status: bookingStatus,
        slot_date,
        created_at,
        slot_time,
        live_request,
        ticket_requested
      }
    },
    redirectToConfirmPayment,
    redirectToLiveRequest
  } = props

  return (
    <Box className={classes.Ticket}>

      {/* Booking label to show status */}
      {getBookingStatusLabel(bookingStatus as RequestFilterOption, live_request)}

      <Grid container>

        <Grid item className={classes.MaxWidth}>
          <Box className={classes.PosterWrapper}>
            <img src={movie_image} className={classes.PosterImage} alt="Poster-movie" />
          </Box>
        </Grid>

        <Grid item xs>
          <Grid container spacing={2}>

            <Grid item xs={12} md>
              <Box maxWidth="350px">
                <Typography className={classes.MovieTitle}>{movie_name}</Typography>
                <Typography variant="body1" className={classes.FormatAndLanguage}>{`${movie_languages}, ${movie_screen_types}`}</Typography>
                <Typography variant="body1">{theatre_name}</Typography>

                <Box className={classes.DisplayFlex} mt={2} display={{ xs: "block", md: "flex" }}>
                  <Typography variant="body2">
                    {moment(slot_date).format("DD MMM, YYYY")}
                  </Typography>
                  <Box className={classes.SeatWrapper}>
                    {
                      seat_number.map((seat) => <Seat key={seat} seatLabel={seat} />)
                    }
                  </Box>
                </Box>

                <Box className={classes.DisplayFlex} mt={0.5}>
                  <Typography variant="body1">{convertTo12HourFormat(slot_time)}</Typography>
                  <Typography variant="body1" className={classes.TextTransform}>{`Seats : ${seat_type}`}</Typography>
                </Box>

              </Box>
            </Grid>

            <Grid item xs={12} md className={classes.ScreenDetails}>

              <Box className={classes.DisplayFlex} mb={2}>
                <Typography variant="body1">{configJSON.RequestID}</Typography>
                <Typography variant="body2">{request_id}</Typography>
              </Box>

              <Box className={classes.DisplayFlex} mb={2}>
                <Typography variant="body1">{configJSON.RequestDate}</Typography>
                <Typography variant="body2">{moment(created_at).format("DD MMM, YYYY")}</Typography>
              </Box>

              <Box className={classes.DisplayFlex} mb={2}>
                <Typography variant="body1">{configJSON.TicketsRequested}</Typography>
                <Typography variant="body2">{convertToPad(ticket_requested)}</Typography>
              </Box>

              <Divider className={classes.Divider} />

              <Box className={classes.DisplayFlex} mb={2}>
                <Typography variant="body1">{configJSON.TotalAmount}</Typography>
                <Typography variant="body2"><span className={classes.TicketTotalSign}>₹</span> {Number(total_amount).toFixed(2)}</Typography>
              </Box>

            </Grid>

          </Grid>

          <Divider className={classes.TicketDivider} />

          <Grid item xs={12}>

            <Grid container alignItems="center" justifyContent="center" className={classes.MinHeightContainer}>
              <Grid item xs={12} md>
                {getButtonByStatus(bookingStatus as RequestFilterOption, live_request, +id, redirectToConfirmPayment, redirectToLiveRequest)}
              </Grid>

              <Grid item xs={12} md className={classes.ScreenDetails}>
                <Typography variant="body1" align="center">{getMessageLabel(bookingStatus as RequestFilterOption, live_request)}</Typography>
              </Grid>
            </Grid>

          </Grid>

        </Grid>

        </Grid>
    </Box>
  )
}

const useStylesMobile = makeStyles(({
  DisplayFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  Ticket: {
    backgroundColor: "#121212",
    border: "none",
    borderRadius: "12px",
    padding: "16px",
    position: "relative",
    "&::after": {
      content: "''",
      width: "16px",
      position: "absolute",
      top: "112px",
      height: "32px",
      transform: "translate(6px, 0)" as const,
      right: "0",
      borderRadius: "32px 0 0 32px",
      backgroundColor: "#404040",
      border: 'none'
    },
    "&::before": {
      content: "''",
      width: "16px",
      position: "absolute",
      top: "112px",
      height: "32px",
      left: "0",
      backgroundColor: "#404040",
      transform: "translate(-6px, 0)" as const,
      border: 'none',
      borderRadius: "0 32px 32px 0",
    },
    "& .status-label": {
      position: "absolute",
      color: "#000",
      transform: 'rotate(-45deg)',
      fontSize: "11px",
      fontWeight: 500,
      textTransform: "uppercase",
      textAlign: "center",
      fontFamily: "Poppins, sans-serif",
      height: 0,
      borderBottom: "solid 20px #ffb43a",
      borderLeft: "solid 20px transparent",
      borderRight: "solid 20px transparent",
      width: "62px",
      top: "14px",
      left: "-26px",
      lineHeight: 1.8,
      "&::before": {
        content: "''",
        position: 'absolute',
        bottom: "-23px",
        left: "-20px",
        borderTop: "solid 3px #D09432",
        borderLeft: "solid 3px transparent",
        borderRight: "solid 3px transparent"
      },
      "&::after": {
        content: "''",
        position: 'absolute',
        top: "20px",
        right: "-19px",
        borderTop: "solid 3px #D09432",
        borderRight: "solid 3px transparent",
        borderLeft: "solid 3px transparent",
      },
      "&.status-pending": {
        borderBottom: "solid 20px #ffb43a",
        "&::before": { borderTop: "solid 3px #D09432" },
        "&::after": { borderTop: "solid 3px #D09432" },
      },
      "&.status-approved": {
        borderBottom: "solid 20px #05d848",
        "&::before": { borderTop: "solid 3px #07b23d" },
        "&::after": { borderTop: "solid 3px #07b23d" },
      },
      "&.status-time-out": {
        borderBottom: "solid 20px #ff3b30",
        "&::before": { borderTop: "solid 3px #d0332a" },
        "&::after": { borderTop: "solid 3px #d0332a" },
      },
      "&.status-declined": {
        borderBottom: "solid 20px #ff3b30",
        "&::before": { borderTop: "solid 3px #d0332a" },
        "&::after": { borderTop: "solid 3px #d0332a" },
      }
    },
    "& .right-side-cut": {
      position: "absolute",
      height: "32px",
      width: "16px",
      bottom: "49px",
      right: "0",
      backgroundColor: "#404040",
      transform: "translate(6px, 0)" as const,
      border: 'none',
      borderRadius: "32px 0 0 32px",
    },
    "& .left-side-cut": {
      position: "absolute",
      height: "32px",
      width: "16px",
      bottom: "49px",
      left: "0",
      backgroundColor: "#404040",
      transform: "translate(-6px, 0)" as const,
      border: 'none',
      borderRadius: "0 32px 32px 0",
    },
  },
  PosterWrapper: {
    justifyContent: "center",
    alignItem: "center",
  },
  PosterImage: {
    border: "solid 0.7px #ffb53a",
    width: "64px",
    borderRadius: "2px",
    objectFit: "cover",
    height: "80px",
  },
  TicketTitle: {
    fontSize: "16px",
    color: "#fff",
    marginBottom: "10px",
    fontWeight: 600,
  },
  TicketLanguageFormat: {
    color: "#fff",
    fontSize: "14px",
    textTransform: "uppercase",
    margin: "4px 0"
  },
  Divider: {
    backgroundColor: "#b4b4b4",
    margin: "18px 0"
  },
  Location: {
    color: "#fff",
    fontSize: "14px",
  },
  ScreenLabel: {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    color: "#FFF",
    textTransform: "capitalize" as const
  },
  Labels: {
    color: "#b4b4b4",
    fontSize: "12px",
    textTransform: "capitalize"
  },
  SeatWrapper: {
    display: "flex",
    maxWidth: "146px",
    flexWrap: "wrap",
    gap: "6px",
    "& > div": {
      "& div": {
        width: "23px",
        height: "23px",
        backgroundColor: "#ffb43a !important",
        fontSize: "8px",
      }
    }
  },
  BookingDateLabel: {
    fontSize: "14px",
    color: "#b4b4b4",
    textTransform: "capitalize",
    marginTop: "12px",
  },
  DottedDivider: {
    borderTop: "dashed 1px #b1b1b1",
    border: "none",
    margin: "12px 0"
  },
  TicketTotal: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    "& > span": {
      fontFamily: "Roboto"
    }
  },
  Message: {
    color: "#b4b4b4",
    fontSize: "12px",
    textAlign: "center"
  },
  TextTransform: {
    textTransform: "capitalize"
  },
  TicketTotalSign: {
    fontFamily: "Roboto",
    paddingRight: "4px"
  },
  ButtonWrapper: {
    "& .submit-button": {
      width: "100%",
      backgroundColor: "#ffb53a",
      border: "none",
      borderRadius: "8px",
      color: "#000",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: "16px",
      fontFamily: "Poppins, sans-serif",
      minHeight: "48px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ffb43a",
        color: "#202020",
      }
    }
  },
  MobileMessage: {
    marginBottom: "12px"
  }
}))

export const MobileRequestBooking: React.FC<IMobileBookingProps> = (props) => {
  
  const classes = useStylesMobile()

  const { 
    request: {
      id,
      attributes: {
        movie_name,
        movie_image,
        movie_languages,
        movie_screen_types,
        theatre_name,
        seat_number,
        total_amount,
        seat_type,
        request_id,
        status: bookingStatus,
        slot_date,
        created_at,
        slot_time,
        live_request,
        ticket_requested
      }
    },
    redirectToConfirmPayment,
    redirectToLiveRequest
  } = props

  return (
    <Box className={classes.Ticket}>

      {/* Booking label to show status */}
      {getBookingStatusLabel(bookingStatus as RequestFilterOption, live_request)}

      <Box display="flex" gridGap="12px">

        <Box className={classes.PosterWrapper}>
          <img className={classes.PosterImage} src={movie_image} alt="Poster-image"  />
        </Box>

        <Box>
          <Typography className={classes.TicketTitle}>{movie_name}</Typography>
          <Typography className={classes.TicketLanguageFormat}>{`${movie_languages}, ${movie_screen_types}`}</Typography>
          <Typography className={classes.Location}>{theatre_name}</Typography>
        </Box>

      </Box>

      <Box className={classes.DisplayFlex} mt={2.5} mb={1}>
        <Typography variant="body1">{configJSON.RequestID}</Typography>
        <Typography variant="body2">{request_id}</Typography>
      </Box>

      <Box className={classes.DisplayFlex} mb={1}>
        <Typography variant="body1">{configJSON.RequestDate}</Typography>
        <Typography variant="body2">{moment(created_at).format("DD MMM, YYYY")}</Typography>
      </Box>

      <Box className={classes.DisplayFlex} mb={1}>
        <Typography variant="body1">{configJSON.TicketsRequested}</Typography>
        <Typography variant="body2">{convertToPad(ticket_requested)}</Typography>
      </Box>

      <Divider className={classes.Divider} />

      <Box className={classes.DisplayFlex} alignItems="center" mt={2}>

        <Typography variant="body1">{moment(slot_date).format("DD MMM, YYYY")}</Typography>

        <Box className={classes.SeatWrapper}>
          {
            seat_number.map((seat) => <Seat key={seat} seatLabel={seat} />)
          }
        </Box>

      </Box>

      <Box className={classes.DisplayFlex} mt={0.5}>

        <Typography variant="body1">
          {convertTo12HourFormat(slot_time)}
        </Typography>

        <Typography variant="body1" className={classes.TextTransform}>
          {`Seats : ${seat_type}`}
        </Typography>

      </Box>

      <Divider className={classes.Divider} />

      <Box className={classes.DisplayFlex} mb={2}>
        <Typography variant="body1">{configJSON.TotalAmount}</Typography>
        <Typography variant="body2"><span className={classes.TicketTotalSign}>₹</span>{Number(total_amount).toFixed(2)}</Typography>
      </Box>

      <hr className={classes.DottedDivider} />

      <Typography variant="body1" align="center" className={classes.MobileMessage}>{getMessageLabel(bookingStatus as RequestFilterOption, live_request)}</Typography>

      <Box className={classes.ButtonWrapper}>
        {getButtonByStatus(bookingStatus as RequestFilterOption, live_request, +id, redirectToConfirmPayment, redirectToLiveRequest)}
      </Box>

      <hr className={classes.DottedDivider} />

      <span className="left-side-cut"></span>
      <span className="right-side-cut"></span>

    </Box>
  )
}
// Customizable Area End
