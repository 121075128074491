import { Dialog, DialogTitle, IconButton, DialogActions, DialogContent, Typography, Button, makeStyles, Paper, Divider } from '@material-ui/core'
import React from 'react'

interface IRFHDialogProps {
  open: boolean
  fullWidth?: boolean
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs',
  handleClose?: () => void
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  hideCloseButton?: boolean
  showTitleDivider?: boolean
  dialogContent?: React.ReactNode
  dialogActions?: React.ReactNode
  dialogTitle?: React.ReactNode
}

const CLOSE_BUTTON = require("./assets/close.png")

const useStyles = makeStyles(({
  CloseButton: {
    position: "absolute",
    top: "0",
    right: "0",
    "& img": {
      width: "28px",
      height: "28px",
      "@media (max-width: 600px)": {
        width: "20px",
        height: "20px",
      }
    }
  },
  BackDrop: {
    backdropFilter: "blur(2px)"
  },
  Divider: {
    backgroundColor: "#979797",
    height: "0.5px"
  },
  DialogPaper: {    
    backgroundColor: "#404040",
    zoom: 0.8,
    borderRadius: "8px",
    paddingTop: "48px",
    paddingBottom: "48px",
    boxShadow: 'none',
    boxSizing:"border-box",
    width: "100%",
    "& .MuiDialogTitle-root": {
      padding: "0 32px 16px 32px",
      "& h2": {
        textTransform: "capitalize !important"
      }
    },
    "& .MuiDialogContent-root": {
      padding: "0 32px",
      '@media(max-width:600px)': {
        padding:"0px 10px !important"
    },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      padding: "32px 32px 0 ",
      '@media(max-width:600px)': {
        padding:"32px 10px 0px"
    },
    },
    "&.MuiDialog-paperWidthXs": {
      maxWidth: "396px"
    },
    "&.MuiDialog-paperWidthSm": {
      maxWidth: "456px"
    },
    "&.MuiDialog-paperWidthMd": {
      maxWidth: "575px",
      margin:5
    }
  },
}))


const RFHDialog: React.FC<IRFHDialogProps> = ({
  open,
  handleClose,
  fullWidth = false,
  maxWidth = "sm",
  hideCloseButton = false,
  dialogTitle,
  dialogActions,
  dialogContent,
  disableEscapeKeyDown = false,
  showTitleDivider = false
}) => {

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      classes={{
        paper: classes.DialogPaper
      }}
      BackdropProps={{ className: classes.BackDrop }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      
      {!hideCloseButton && (
        <IconButton onClick={handleClose} className={classes.CloseButton} data-test-id="dialog-close-btn">
          <img src={CLOSE_BUTTON} />
        </IconButton>
      )}

      {dialogTitle && (
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
      )}

      {showTitleDivider && <Divider className={classes.Divider} />}

      {dialogContent && (
        <DialogContent>
          {dialogContent}
        </DialogContent>
      )}

      {dialogActions && (
        <DialogActions>
          {dialogActions}
        </DialogActions>
      )}

    </Dialog>
  )
}

export default RFHDialog