import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { LocalStorageKeys } from "../../../components/src/enums.web";

// Customizable Area End

export const configJSON = require("./config");
// Customizable Area Start
interface RatingsProps {
    movieId: number;
    bannerImage: any;
    movieName: any;
    buttonStyle: any;
    buttonText: any
    boxRatingTitle: any;
    buttonLabel: any
    boxRatingSubTitle: any;
    labelFeelFree: any;
}
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any;
    ratingsData?: RatingsProps
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isModalOpens: boolean;
    description: string;
    hashTagData: any;
    hashTagId: any;
    sliderValue: number;
    errorMessage: string;
    isApiLoading: boolean;
    showContent: boolean;
    doSignUp: boolean;
    selectedHash: any;
    reviewDate: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class RatingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getHashTagCalledApi: string = "";
    createReviewsCalledApi: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.SessionResponseData)
        ];
        this.state = {
            isModalOpens: false,
            description: '',
            hashTagData: [],
            hashTagId: [],
            sliderValue: 0,
            errorMessage: '',
            isApiLoading: false,
            showContent: false,
            doSignUp: false,
            selectedHash: {},
            reviewDate: null
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            await this.handleApiResponse(apiRequestCallId, responseJson);
        }
    }

    async handleApiResponse(apiRequestCallId: string, responseJson: any) {
        switch (apiRequestCallId) {
            case this.getHashTagCalledApi:
                this.handleReciveTags(responseJson);
                break;

            case this.createReviewsCalledApi:
                this.handleReciveReviews(responseJson);
                break;


        }
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        this.getHashTagApi();
        window.scrollTo(0, 0)
        // Customizable Area End
    }
    // Customizable Area Start

    handleCloseModal = () => {
        this.setState({ isModalOpens: false, doSignUp: false, errorMessage:'' });
    };

    handleOpenDialog = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string;
        if (!authToken) {
            this.setState({ isModalOpens: false, doSignUp: true });
        }
        else {
            this.setState({ isModalOpens: !this.state.isModalOpens, doSignUp: false });
            this.getHashTagApi()
        }
    }
    handleSubmit = () => {
        this.createHashTagsApi()
    }
    handleChange = (event: any) => {
        this.setState({ description: event.target.value })
    }
    handleHashClick = (hashName: any) => {
        const { id } = hashName;
        this.setState((prevState) => {
            const { hashTagId, selectedHash } = prevState;
            const isHashSelected = hashTagId.includes(id);
            if (isHashSelected) {
                // If already selected, remove it from the array
                const updatedSelectedHash = { ...selectedHash };
                delete updatedSelectedHash[id];
                const updatedSelectedHashIds = hashTagId.filter((selectedId: any) => selectedId !== id);
                return { hashTagId: updatedSelectedHashIds, errorMessage: '', selectedHash: updatedSelectedHash };
            } else {
                // If not selected, add it to the array
                const updatedSelectedHash = { ...selectedHash, [id]: true };
                const updatedSelectedHashIds = [...hashTagId, id];
                return { hashTagId: updatedSelectedHashIds, errorMessage: '', selectedHash: updatedSelectedHash };
            }
        });
    };

    handleSliderChange = (_: React.ChangeEvent<{}>, newValue: number | number[]) => {
        this.setState({ sliderValue: newValue as number, errorMessage: '' });
    };

    getImage = (sliderValue: any) => {
        switch (true) {
            case sliderValue >= 5:
                return require("../assets/wow.png");

            case sliderValue >= 4:
                return require("../assets/simple.png");

            case sliderValue >= 3:
                return require("../assets/happy.png");

            case sliderValue >= 2:
                return require("../assets/bad.png");
            case sliderValue >= 1:
                return require("../assets/sad.png");
            default:
                return require("../assets/notHappy.png");
        }
    }
    getHashTagApi = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        if (authToken) {
            this.getHashTagCalledApi = callApi({
                contentType: configJSON.contentTypeApi,
                method: configJSON.httpGetMethod,
                endPoint: `${configJSON.getHashTagsApiEndPoint}?movie_id=${this.props.ratingsData?.movieId}`,
                headers: { "token": localStorage.getItem("login") },
            }, runEngine)
        } else {
            this.setState({ isModalOpens: false });
        }
    }

    handleReciveTags = (responseJson: any) => {
        this.setState({ hashTagData: responseJson && responseJson.data })
    }

    createHashTagsApi = async () => {
        if (this.state.sliderValue === 0) {
            this.setState({
                errorMessage: "Please select a rating before submitting your review.",
            })
            return;
        }
        const tagId = (this.state.hashTagId)

        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

        const httpBody = this.state.description !== "" ? {
            "movie_id": this.props.ratingsData?.movieId,
            "tag_ids": tagId,
            "star": this.state.sliderValue === 6 ? 5 : this.state.sliderValue,
            "description": this.state.description
        }
            : {
                "movie_id": this.props.ratingsData?.movieId,
                "tag_ids": tagId,
                "star": this.state.sliderValue === 6 ? 5 : this.state.sliderValue,
            }



        this.createReviewsCalledApi = callApi({
            contentType: configJSON.contentTypeApi,
            method: configJSON.httpPostMethod,
            endPoint: `${configJSON.createReviewsApiEndPoint}`,
            headers: { "token": authToken },
            body: httpBody
        }, runEngine)
    }


    handleReciveReviews = (responseJson: any) => {
        console.log("rating",responseJson)
        if (responseJson.data.attributes.movie_id) {

            this.props.navigation.navigate('RatingsReviews', { id: this.props.ratingsData?.movieId, })

        }
    }

    // on close of the signup modal
    closeSignUpModal = () => {
        this.setState({ doSignUp: false });
    }


}
// Customizable Area End

