import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Paper,
  CssBaseline,
  List,
  ListItem,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { BackArrow, FilledBellIcon, OutlinedBellIcon, imgBell } from "./assets";

const RFHList = styled(List)(({
  padding: 0,
  "& li": {
    padding: 0,
    borderBottom: "solid 1px #404040",
    "&:hover": {
      backgroundColor: "#404040",
      borderColor: "#404040",
      cursor: "pointer"
    }
  }
}))

// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Box style={{ width: "70%" }}>
              <Typography variant="h6" style={{ marginTop: 12 }}>
                {configJSON.notifications}
              </Typography>
            </Box>
            {this.state.data
              ?.sort((a: any, b: any) => a.attributes.id - b.attributes.id)
              ?.map((item: any) => {
                return (
                  <Box
                    key={item.attributes.id}
                    style={webStyle.itemWrapper}
                    onClick={() => {
                      this.setState({ selectedData: item });
                      !item.attributes.is_read &&
                        this.markAsRead(item.attributes.id);
                    }}
                  >
                    <img src={imgBell} style={webStyle.iconStyle} />
                    <Box style={{ marginLeft: 16, flex: 1 }}>
                      <Box style={webStyle.itemHeadingWrapper}>
                        <Typography
                          variant="h6"
                          style={
                            item.attributes.is_read
                              ? webStyle.itemHeading
                              : webStyle.itemHeadingRead
                          }
                        >
                          {item.attributes.headings}
                        </Typography>
                        <Typography variant="h6" style={webStyle.contents}>
                          {this.timeSince(item.attributes.created_at)}
                        </Typography>
                      </Box>
                      <Typography variant="h6" style={webStyle.contents}>
                        {item.attributes.contents}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            <Dialog
              maxWidth={"md"}
              open={!!this.state.selectedData}
              onClose={() => this.setState({ selectedData: null })}
            >
              <DialogContent>
                <Typography
                  variant="h6"
                  style={
                    this.state.selectedData?.attributes.is_read
                      ? webStyle.itemHeading
                      : webStyle.itemHeadingRead
                  }
                >
                  {this.state.selectedData?.attributes.headings}
                </Typography>
                <Typography variant="h6" style={webStyle.contents}>
                  {this.convertDate(
                    this.state.selectedData?.attributes.created_at
                  )}
                </Typography>
                <Typography variant="h6" style={webStyle.contents}>
                  {this.state.selectedData?.attributes.contents}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ selectedData: null })}
                  color="primary"
                  style={webStyle.okButton}
                >
                  {configJSON.okText}
                </Button>
                <Button
                  onClick={() =>
                    this.deleteNotifications(
                      this.state.selectedData?.attributes.id
                    )
                  }
                  variant="contained"
                  color="primary"
                  style={webStyle.deleteButton}
                >
                  {configJSON.deleteText}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>

      




      
        <CssBaseline />

        {this.props.isNotificationOpen && (
          <Paper elevation={0} style={webStyle.NotificationContainer}>

            <Box style={webStyle.NotificationHeader}>
              <img src={BackArrow} alt="back-arrrow" style={webStyle.BackIcon} onClick={() => this.props.setIsNotificationOpen(false)}/>
              <Typography variant="h4" data-test-id="page-title">{configJSON.notifications}</Typography>
            </Box>

            {
              this.state.isLoading ? (
                <Fade in={this.state.isLoading} unmountOnExit>
                  <Box display="flex" justifyContent="center">
                    <CircularProgress style={webStyle.CircularProgress} />
                  </Box>
                </Fade>
              ) : (
                <RFHList data-test-id="notification-wrapper">
                    {
                      this.state.notificationList.length > 0 ? (
                        this.state.notificationList.map((notification) => (
                          <ListItem key={notification.id}>
                            <Box display="flex" gridGap="8px" style={webStyle.ListItem} data-test-id="notification" onClick={() => this.onNotificationClick(+notification.id, notification.attributes.remarks)}>
                              <img src={notification.attributes.is_read ? OutlinedBellIcon : FilledBellIcon} alt="bell" width="14px" height="16px" />
                              <Box>
                                <Typography variant="body1">{this.timeSince(notification.attributes.created_at)}</Typography>
                                <Typography variant="body2" className={notification.attributes.is_read ? "" : "unread"}>{notification.attributes.remarks}</Typography>
                              </Box>
                            </Box>
                          </ListItem>
                        ))
                      ) : (
                        <Box style={webStyle.NoNotifications}>
                          <Typography variant="body2">{configJSON.NoNotifications}</Typography>
                        </Box>
                      )
                    }
                </RFHList>
              )}



          </Paper>)
        }

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#121212"
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h4: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#FFF"
    },
    body1: {
      fontSize: "10px",
      color: "#FFF",
      marginBottom: "4px",
      marginTop: "2px"
    },
    body2: {
      fontSize: "12px",
      color: "#b4b4b4",
      "& .unread": {
        fontWeight: 500,
        color: "#f8b43a"
      }
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: "none",
  },
  NotificationContainer: {
    height: "calc(100vh - 84px)",
    overflowY: "auto" as const,
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
  NotificationHeader: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "20px 16px 24px"
  },
  BackIcon: {
    cursor: "pointer",
    width: "24px",
    height: "24px"
  },
  ListItem: {
    padding: "10px 10px 10px 16px",
    width: "100%"
  },
  NoNotifications: {
    padding: "0 16px",
    width: "100%"
  },
  CircularProgress: {
    marginTop: "20px",
    color: "#ffb43a"
  },
};
// Customizable Area End
