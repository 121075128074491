import React from "react";

// Customizable Area Start
import { 
  Backdrop,
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  Container, 
  CssBaseline, 
  Fade, 
  Grid, 
  Hidden, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  ThemeProvider, 
  Typography, 
  createTheme, 
  makeStyles, 
  styled 
} from "@material-ui/core";
import { BackArrow, PaymentSuccessfull, Screen, WalletLow } from "./assets";
import { IRequestedBookingResponse, IRow, ISeat, ISection } from "../../../components/src/interfaces.web";
import Seat, { SingleSeat } from "../../../components/src/Seat.web";
import { convertTo12HourFormat, convertToPad, goToPrevPage } from "../../../components/src/utilities";
import RFHDialog from "../../../components/src/RFHDialog";
import { SeatStatus } from "../../../components/src/enums.web";
import CountDownTimer from "../../../components/src/CountDownTimer";
import moment from "moment";

interface ISeatSelectionGridProps {
  seatSelection: ISection[]
  hoverEnabled?: boolean
}

interface ILiveRequestCardProps {
  liveRequest: IRequestedBookingResponse
  handleAcceptButton: (id: number) => void
  handleRejectButton: (id: number) => void
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    },
    text: {
      primary: "#FFF"
    }
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h4: {

    },
    h5: {
      fontSize: "16px",
      fontWeight: 500,
      "@media (max-width: 600px)": {
        fontSize: "14px"
      }
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "10px"
      }
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "16px",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    body1: {
      fontSize: "18px",
      textTransform: "capitalize",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    body2: {
      fontSize: "20px",
      fontWeight: 500
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#000",
        textTransform: "capitalize"
      },
    },
    MuiChip: {
      root: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#000",
        fontFamily: "Poppins",
        borderRadius: "8px",
        padding: "12px 0",
        lineHeight: 1,
        minHeight: "36px",
        backgroundColor: "#ffb53a",
        cursor: "pointer",
        "@media (max-width: 600px)": {
          fontSize: "10px",
          padding: "10px",
          height: "28px",
          minHeight: "unset",
          borderRadius: "4px",
          "& .MuiChip-label": {
            padding: 0,
          }
        }
      },
      outlined: {
        fontSize: "12px",
        color: "#FFF",
        border: "solid 1px #ffb53a",
        background: "transparent",
        "@media (max-width: 600px)": {
          fontSize: "10px",
        }
      },
      label: {
        fontWeight: 500
      },
      clickable: {
        '&:active': {
          backgroundColor: '#ffb53a',
        },
        '&:focus': {
          backgroundColor: '#ffb53a', 
        },
        '&:hover': {
          backgroundColor: '#ffb53a', 
        },
      },
    },
    MuiTable: {
      root: {
        maxWidth: "550px",
      }
    },
    MuiTableCell: {
      root: {
        fontSize: "16px",
        borderBottom: "unset",
        padding: "12px 0 0",
        "@media (max-width: 600px)": {
          fontSize: "14px"
        }
      },
      body: {
        color: "#FFF",
      },
      head: {
        color: "#FFF",
        fontWeight: 500,
        padding: "6px 0 12px",
        borderBottom: "solid 0.5px #979797",
      }
    },
  },
});

const RFHButton = styled(Button)({
  color: "#000",
  backgroundColor: "#ffb43a",
  maxWidth: "386px",
  padding: "16px",
  maxHeight: "48px",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#ffb43a",
    color: "#000"
  }
})

const RFHDialogButton = styled(Button)({
  textTransform: "capitalize",
  color: "#000",
  backgroundColor: "#ffb43a",
  borderRadius: "12px",
  height: "56px",
  fontWeight: 600,
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#ffb43a"
  }
})
// Customizable Area End

import LiveRequestController, { Props, configJSON } from "./LiveRequestController";

export default class LiveRequest extends LiveRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  SEAT_HEADER = [
    { label: configJSON.Selected, status: SeatStatus.Selected },
    { label: configJSON.Request, status: SeatStatus.Request },
    { label: configJSON.Available, status: SeatStatus.Available },
    { label: configJSON.Unavailable, status: SeatStatus.Unavailable },
    { label: configJSON.Buy, status: SeatStatus.Buy },
  ]
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

        <CssBaseline />

        <Backdrop style={styles.BackDrop} open={this.state.isApproveRejectLoading}>
          <CircularProgress style={styles.CircularProgress} color="inherit" />
        </Backdrop>

        <MainWrapper>

          <Box className="header">
            <Container>
              <Box justifyContent="space-between" display="flex" alignItems="center">
                <Box alignItems="center" display="flex" gridGap="20px">

                  <img src={BackArrow} className="back__button" alt="back-arrow" onClick={goToPrevPage} />
                  <Box>
                    <Typography variant="subtitle1">
                      {this.state.navigationPayload.movie_name}
                    </Typography>
                    <Typography variant="subtitle2">
                      {this.state.navigationPayload.theatre_name}
                    </Typography>
                  </Box>

                </Box>
                <Hidden smDown>

                  <Box className="header__seat__wrapper">

                    {
                      this.SEAT_HEADER.map((item) => (
                        <Box className="header__seat" key={item.status}>
                          <SingleSeat status={item.status} />
                          <span className="seat">
                            {item.label}
                          </span>
                        </Box>
                      ))
                    }

                  </Box>
                </Hidden>

              </Box>
            </Container>
          </Box>

          <Box className="main__content">
            <Container>

              {
                this.state.isTimeSlotLoading ? (
                  <Fade in={this.state.isTimeSlotLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress style={styles.CircularProgress} />
                    </Box>
                  </Fade>
                ) : (

                  <Box mb={5} className="date__wrapper">
                    <Typography variant="h5">
                      {moment(this.state.navigationPayload.slot_date).format("ddd, DD MMM")}
                    </Typography>
                    <Box className="chip__wrapper">

                      {
                        this.state.timeSlots.map((timeSlot) => (
                          <Chip
                            label={convertTo12HourFormat(timeSlot.slot_time)}
                            key={timeSlot.screening_id}
                            variant={+timeSlot.screening_id === this.state.selectedScreenId ? "default" : "outlined"}
                            onClick={() => this.getSeatSelectionGrid(+timeSlot.screening_id)}
                          />
                        ))
                      }

                    </Box>
                  </Box>
                )
              }


              {
                this.state.isLiveRequestsOpen ? (
                  <Container maxWidth="md" data-test-id="live-request-card-container" className="card__container">
                    <Typography className="live__request__title">{configJSON.LiveRequest}</Typography>

                    <Box display="flex" flexDirection="column" gridGap="32px">
                      {
                        this.state.liveRequestList.map((liveRequest: IRequestedBookingResponse) => <LiveRequestCard key={liveRequest.id} liveRequest={liveRequest} handleAcceptButton={this.handleAcceptButton} handleRejectButton={this.handleRejectButton} />)
                      }
                    </Box>
                  </Container>
                ) : (
                  <>
                    <Box justifyContent="center" display="flex" position="relative" mb={7}>
                      <img src={Screen} className="screen__image" alt="screen" />
                      <Typography variant="h6" align="center" style={styles.ScreenMessage}>{configJSON.ScreenMessage}</Typography>
                    </Box>

                    {
                      this.state.isSeatGridLoading ? (
                        <Fade in={this.state.isSeatGridLoading} unmountOnExit>
                          <Box display="flex" justifyContent="center">
                            <CircularProgress style={styles.CircularProgress} />
                          </Box>
                        </Fade>
                      ) : (
                        <>
                          <Box mb={4}>
                            <SeatSelectionGrid seatSelection={this.state.seatSelection} hoverEnabled={false} />
                          </Box>

                          <Paper className="dashboard">
                            <Typography align="center" variant="body2">{configJSON.LiveRequests}</Typography>
                            <Table>

                              <TableHead>
                                <TableRow>
                                  <TableCell>Tier</TableCell>
                                  <TableCell align="center">Requests</TableCell>
                                  <TableCell align="center">Accepted</TableCell>
                                  <TableCell align="center">Open</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow>
                                  <TableCell scope="row" component="th">Classic</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.classic_requested_request}</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.classic_approved_request}</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.classic_open_request}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell scope="row" component="th">Prime</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.prime_requested_request}</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.prime_approved_request}</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.prime_open_request}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell scope="row" component="th">Recliner</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.recliner_requested_request}</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.recliner_approved_request}</TableCell>
                                  <TableCell align="center">{this.state.liveRequestTable.recliner_open_request}</TableCell>
                                </TableRow>
                              </TableBody>

                            </Table>
                          </Paper>
                        </>
                      )
                    }

                  </>
                )
              }


            </Container>
          </Box>


          <Box className="footer">

            {
              !this.state.isLiveRequestsOpen && <RFHButton fullWidth data-test-id="live-requests-btn" onClick={this.openLiveRequest}>{configJSON.ViewRequests}</RFHButton>
            }
            
          </Box>

          <RFHDialog
            data-test-id="accept-request-dialog"
            open={this.state.isAccceptOpen}
            handleClose={this.handleAcceptClose}
            maxWidth="xs"
            disableEscapeKeyDown={true}
            dialogActions={(
              <RFHDialogButton data-test-id="accept-done-btn" fullWidth onClick={this.handleAcceptClose}>
                {configJSON.Done}
              </RFHDialogButton>
            )}
            dialogContent={(
              <Box justifyContent="center" display="flex" flexDirection="column">
                <Box justifyContent="center" display="flex" p={0}>
                  <img src={PaymentSuccessfull} width="48px" height="48px" alt="done" />
                </Box>
                <Typography align="center" variant="body2" style={styles.FW600}>
                  {configJSON.RequestApproveMsg}
                </Typography>
                <Typography align="center" variant="subtitle2" style={styles.MarginTop}>
                  {configJSON.RequestApproveDetailedMsg}
                </Typography>
              </Box>
            )}
          />

          <RFHDialog
            data-test-id="reject-request-dialog"
            open={this.state.isRejectOpen}
            handleClose={this.handleAcceptClose}
            maxWidth="xs"
            disableEscapeKeyDown={true}
            dialogActions={(
              <RFHDialogButton data-test-id="reject-done-btn" fullWidth onClick={this.handleAcceptClose}>
                {configJSON.Done}
              </RFHDialogButton>
            )}
            dialogContent={(
              <Box justifyContent="center" display="flex" flexDirection="column">
                <Box justifyContent="center" display="flex" p={0}>
                  <img src={WalletLow} width="48px" height="48px" alt="done" />
                </Box>
                <Typography align="center" variant="body2" style={styles.FW600}>
                  {configJSON.RequestRejectMsg}
                </Typography>
              </Box>
            )}
          />

        </MainWrapper>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  CircularProgress: {
    margin: "20px auto",
    color: "#ffb43a"
  },
  BackDrop: {
    color: "#ffb43a",
    zIndex: 2000
  },
  ScreenMessage: {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "118px"
  },
  RupeesIcon: {
    fontFamily: "Roboto, sans-serif"
  },
  SectionName: {
    fontWeight: 500
  },
  FW600: {
    fontWeight: 600,
    marginTop: "32px",
    fontSize: "18px"
  },
  MarginTop: {
    marginTop: "16px"
  }
}

const useStyles = makeStyles({
  SeatSelectionRoot: {
    display: "flex",
    justifyContent: "center",
  },
  SeatSelectionWrapper: {
    width: "fit-content",
  },
  Section: {
    marginBottom: "42px",
  },
  SectionTitle: {
    fontWeight: 500,
    marginBottom: "32px",
    fontSize: "16px",
    "& .rupees-icon": {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 600
    },
    "& .price": {
      fontWeight: 600
    },
    "&:last-child": {
      marginBottom: 0
    },
    "@media (max-width: 600px)": {
      fontSize: "14px",
      marginBottom: "20px"
    }
  },
  SeatRowWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& > div": {
      width: "26px",
      height: "26px",
    },
    "@media (max-width: 600px)": {
      gap: "8px",
      "& > div": {
        width: "20px",
        height: "20px",
        borderRadius: "4px"
      },
    }
  },
  SeatSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "@media (max-width: 600px)": {
      gap: "8px"
    }
  },
  SeatLabel: {
    fontSize: "16px",
    fontWeight: 500,
    width: "40px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
    }
  },
  BackButton: {
    cursor: "pointer"
  }
}) 


export const SeatSelectionGrid: React.FC<ISeatSelectionGridProps> = ({
  seatSelection,
  hoverEnabled = true
}) => {

  const parentRef = React.useRef<HTMLDivElement>(null);
  const childRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles()
  
  const updateJustifyContent = () => {
    if (parentRef.current && childRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      const childWidth = childRef.current.offsetWidth;

      if (childWidth > parentWidth) {
        parentRef.current.style.justifyContent = 'flex-start';
      } else {
        parentRef.current.style.justifyContent = 'center';
      }
    }
  };

  React.useEffect(() => {
    updateJustifyContent();
  }, []);

  return (
    <div className={classes.SeatSelectionRoot} ref={parentRef}>
      <div className={classes.SeatSelectionWrapper} ref={childRef}>

        {
          seatSelection.map((section: ISection) => (
            <Box className={classes.Section} key={section.sectionName}>

              <Typography className={classes.SectionTitle}>
                {`${section.sectionName}:`}
                <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                <span className="price">{section.price}</span>
              </Typography>

              <Box className={classes.SeatSectionWrapper}>
                {
                  section.rows.map((row: IRow) => (
                    <Box className={classes.SeatRowWrapper} key={row.rowLabel}>
                      <Typography className={classes.SeatLabel}>{row.rowLabel}</Typography>

                      {
                        row.seats.map((seat: ISeat) => <Seat key={seat.id} seat={seat} hoverEnable={hoverEnabled} />)
                      }

                    </Box>
                  ))
                }
              </Box>

            </Box>
          ))
        }

      </div>
    </div>
  )
}

const cardStyles = makeStyles({
  CardRoot: {
    padding: "32px 88px",
    backgroundColor: "#404040",
    borderRadius: "6px",
    "@media (max-width: 600px)": {
      padding: "16px"
    }
  },
  LeftPart: {
    marginRight: "40px",
    "@media (max-width: 600px)": {
      maxWidth: "145px",
      marginRight: 0
    }
  },
  CardTitle: {
    fontWeight: 500,
    paddingBottom: "16px",
    fontSize: "18px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
    }
  },
  TicketDetails: {
    paddingBottom: "16px",
    textTransform: "none",
    "& span.time": {
      fontWeight: 600,
      display: "inline-block",
      "& p": {
        margin: 0
      }
    },
    "& span.rupees__icon": {
      fontFamily: "Roboto, sans-serif"
    },
    "@media (max-width: 600px)": {
      paddingBottom: "8px"
    }
  },
  Seat: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins",
    borderRadius: "4px",
    color: "#000",
    backgroundColor: "#ffb43a",
    "@media (max-width: 600px)": {
      width: "24px",
      height: "24px",
      fontSize: "10px"
    }
  },
  ActionButton: {
    fontWeight: 600,
    fontSize: "18px",
    minHeight: "56px",
    borderRadius: "12px",
    "&.MuiButton-outlined": {
      border: "solid 1px #ffb43a",
      backgroundColor: "#373737",
      color: "#FFF"
    },
    "&.MuiButton-contained": {
      border: "none",
      backgroundColor: "#ffb43a",
      color: "#000"
    },
    "@media (max-width: 600px)": {
      fontSize: "16px",
      height: "48px",
      minHeight: "unset"
    }
  },
  GridContainer: {
    gap: "40px",
    "@media (max-width: 600px)": {
      gap: "16px"
    }
  }
})

export const LiveRequestCard: React.FC<ILiveRequestCardProps> = ({
  liveRequest,
  handleAcceptButton,
  handleRejectButton
}) => {

  const classes = cardStyles();

  return (
    <Paper className={classes.CardRoot}>
      <Grid container>
        <Grid item xs className={classes.LeftPart}>

          <Typography className={classes.CardTitle}>{configJSON.RequestedSeats}</Typography>

          <Box display="flex" gridGap="8px" maxWidth="152px" flexWrap="wrap">
            {
              liveRequest.attributes.seat_number.map((seatLabel: string) => (
                <Box display="inline-block" key={seatLabel}>
                  <Box className={classes.Seat}>
                    {seatLabel}
                  </Box>
                </Box>
              ))
            }
          </Box>

        </Grid>
        <Grid item xs>

          <Typography className={classes.TicketDetails}>
            {configJSON.RequestExpires}
            <span className="time"><CountDownTimer expiryDate={liveRequest.attributes.expire_time} /></span>
          </Typography>
          <Typography className={classes.TicketDetails}>
            {configJSON.PricePerTicket}
            <span className="rupees__icon">&nbsp;&nbsp;&#x20B9;&nbsp;</span>
            {Number(liveRequest.attributes.ticket_price).toFixed(2)}
          </Typography>
          <Typography className={classes.TicketDetails}>{configJSON.NoOfSeats}&nbsp;&nbsp;{convertToPad(liveRequest.attributes.ticket_requested)}</Typography>
          <Typography className={classes.TicketDetails}>{configJSON.TotalAmountNew}<span className="rupees__icon">&nbsp;&nbsp;&#x20B9;&nbsp;</span>{Number(liveRequest.attributes.sub_total).toFixed(2)}</Typography>

        </Grid>
      </Grid>

      <Grid container className={classes.GridContainer}>
        <Grid item xs>
          <Button
          data-test-id="reject-request-btn"
            className={classes.ActionButton}
            onClick={() => handleRejectButton(+liveRequest.id)}
            variant="outlined"
            fullWidth>
            {configJSON.RejectBtn}
          </Button>
        </Grid>
        <Grid item xs>
          <Button
          data-test-id="accept-request-btn"
            className={classes.ActionButton}
            onClick={() => handleAcceptButton(+liveRequest.id)}
            variant="contained"
            fullWidth>
            {configJSON.AcceptBtn}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

const MainWrapper = styled(Box)({
  height: "100vh",
  display: "flex",
  flexDirection: "column" as const,
  "& .header": {
    backgroundColor: "#40404040",
    paddingTop: "12px",
    paddingBottom: "12px",
    "& .back__button": {
      width: "36px",
      height: "36px"
    },
    "& .header__seat__wrapper": {
      display: "flex",
      alignItems: "center",
      gridGap: "16px",
      "& .header__seat": {
        display: "flex", 
        alignItems: "center",
        gridGap: "8px",
        "& > div": {
          width: "26px",
          height: "26px"
        },
        "& .seat": {
          fontSize: "12px",
          fontWeight: 500,
        }
      }
    },
    "@media (max-width: 600px)": {
      "& .back__button": {
        width: "24px",
        height: "24px"
      },
    }
  },
  "& .main__content": {
    padding: "22px 0 44px",
    flexGrow: 1,
    overflowY: "scroll",
    "& .screen__image": {
      width: "100%",
      maxWidth: "342px",
      aspectRatio: 5.02
    },
    "& .date__wrapper": {
      padding: "8px 0",
      "& .chip__wrapper": {
        gap: "16px",
        display: "flex",
        alignItems: "center",
        marginTop: "8px"
      }
    },
    "& .dashboard": {
      margin: "0 auto",
      maxWidth: "797px",
      padding: "24px 0",
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center",
      gap: "20px",
    },
    "& .card__container": {
      "& .live__request__title": {
        fontSize: "24px",
        fontWeight: 600,
        marginBottom: "32px"
      }
    }
  },
  "& .footer": {
    backgroundColor: "#40404040",
    padding: "14px 12px",
    display: "flex",
    justifyContent: "center",
  },
  "@media (max-width: 600px)": {
    "& .header": {
      "& .back__button": {
        width: "24px",
        height: "24px"
      },
    },
    "& .main__content": {
      paddingTop: 0,
      "& .screen__image": {
        width: "100%",
        maxWidth: "252px !important",
        aspectRatio: 5.02
      },
      "& .date__wrapper": {
        "& .chip__wrapper": {
          gap: "12px !important"
        }
      },
      "& .dashboard": {
        padding: "16px !important",
        gap: "18px !important",
      },
      "& .card__container": {
        padding: 0,
        "& .live__request__title": {
          fontSize: "16px !important",
          marginBottom: "16px !important"
        }
      }
    },
  }
})
// Customizable Area End
