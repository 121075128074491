import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FilterOption, LocalStorageKeys, TabOption } from "../../../components/src/enums.web";
import { eventOrMovieBookingData} from "../../../components/src/interfaces.web";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
interface IPagination {
  per_page: number;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookingHistory: eventOrMovieBookingData [],
  isLoading: boolean,
  pagination: IPagination,
  anchorEl: HTMLElement | null,
  selectedTab: TabOption
  selectedFilter: FilterOption
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookingHistoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  bookingHistoryApiID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookingHistory: [],
      isLoading: true,
      pagination: {
        per_page: 0,
        current_page: 0,
        next_page: null,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      },
      anchorEl: null,
      selectedTab: TabOption.UpComing,
      selectedFilter: FilterOption.All
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.bookingHistoryApiID) {
        this.setState({ 
          bookingHistory: resJson.data, 
          pagination: resJson.meta.pagination, 
          isLoading: false 
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ anchorEl: event.currentTarget });

  handleTabChange = (_: React.ChangeEvent<any>, newValue: TabOption) => {

    if (newValue === this.state.selectedTab) return
    this.getBookingHistory(this.state.selectedFilter, newValue)
    this.setState({ selectedTab: newValue, isLoading: true })
  }

  handleClose = () => this.setState({ anchorEl: null })
  handleFilterChange = (newValue: FilterOption) => {

    if (newValue === this.state.selectedFilter) return

    this.getBookingHistory(newValue, this.state.selectedTab)
    this.setState({ selectedFilter: newValue, isLoading: true })
    this.handleClose()
  }

  redirectToViewTicket = (ticketId: number) => {
    let getTicketDetails = this.state.bookingHistory.find((elm)=> +elm.id === ticketId)
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), getTicketDetails?.type === "event_booking" ? "EventViewTicket": "ViewTicket");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), ticketId);
    this.send(msg);
  }

  redirectToCancleTicket = (ticketId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CancelBooking");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), ticketId);
    this.send(msg);
  }

  async getBookingHistory(filter = FilterOption.All, period = TabOption.UpComing, page?: number | null) {

    let url = configJSON.BookingHistoryEndPoint as string

    if (filter !== FilterOption.All) url = url.concat(`?filter=${filter}&period=${period}`)
    else url = url.concat(`?period=${period}`)

    if(page) url = url.concat(`&page=${page}`)

    const token = await getStorageData(LocalStorageKeys.LoginToken)

    this.bookingHistoryApiID = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.GETMethod,
      endPoint: url,
      headers: { "token": token },
    }, runEngine)

  }

  async componentDidMount() {
    this.getBookingHistory()
    window.scrollTo(0, 0);
  }
  // Customizable Area End
}
