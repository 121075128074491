import React, { CSSProperties } from "react";
import {
  // Customizable Area Start
  Typography,
  Paper,
  Grid,
  Button,
  Box,
  Snackbar,
  Portal,
  styled,
  Hidden,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MainCityLocation from './MainCityLocation.web';
import MainGeoLocation from "./MainGeoLocation.web";
import UserSignUp from "../../blocks/social-media-account-registration/src/UserSignUp.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import {
  grid_icon,
  grid_selected,
  hamburger_menu,
  ticket_logo,
  search,
  location_icon,
  addpic,
  SuccessIcon,
  hamburgerBarIcon
} from "../../blocks/splashscreen/src/assets";
import LoginHamburgerBar from "./LoginHamburgerBar";
import { AppRoutings } from "./WebEnums/app-routings"
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from './enums.web';
import { success } from "../../blocks/contactus/src/assets";
import { close } from "../../blocks/reservations2/src/assets";
import KycDialogeBox from "../../blocks/cfwallet19/src/KycDialogeBox.web";
import RFHDialog from "./RFHDialog";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch.web";
const { baseURL } = require("../../framework/src/config");

interface CityListResponse {
  attributes: {
    image: string
    name: string
  }
  id: string
  type: string
}

const RFHDialogButton = styled(Button)({
  textTransform: "capitalize",
  fontFamily: "Poppins, sans-serif",
  color: "#202020",
  backgroundColor: "#ffb43a",
  borderRadius: "12px",
  height: "56px",
  fontWeight: 600,
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#ffb43a"
  }
})

// Customizable Area End


export interface Props {
  // Customizable Area Start
  navigation?:any
  addPadding?: boolean
  // Customizable Area End
}

const WalletDescription: any = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '24px',
  fontWeight: 600,
  color: '#ffffff',
  margin: "20px 0",
  [theme.breakpoints.down('md')]: {
    fontSize: "18px",
  },
}));

const LoginBtn: any = styled(Button)(({ theme }) => ({
  width: 'max-content',
  backgroundColor: '#121212',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#121212',
  },
  color: 'white',
  textTransform: "capitalize",
  fontSize: '16px',
  fontFamily:'Poppins',
  marginLeft:'16px',
  height:'36px',
  // padding:'0 70px',
  [theme.breakpoints.down('xs')]: {
   display:'none'
  },
}));

interface IconWrapperProps {
  profilePic: string
}

export const PlanPremiumIcon: React.FC<IconWrapperProps> = ({ profilePic}) => {
  return <img 
            src={profilePic ?? addpic} 
            alt="images" 
            style={{marginLeft:'10px',height:'24px', width:"24px", borderRadius:"50%"}} 
          />;
}

export const WalletDialogBox = ({onClickProceed, handleOnClose}:any) => { 
  return (
    <Dialog
      id="language"
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "#404040",
          color: "#fff",
          padding: '48px 32px',
          maxWidth: "456px",
        },
        elevation: 0,
      }}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      data-test-id="seat_dialog"

    >
        <img 
          src={close} 
          alt='closebutton' 
          style={webStyle.crossBtn} 
          onClick={()=> handleOnClose()} 
        />
      <DialogContent
        style={webStyle.dialogContent}
        data-test-id="seat_number">
        <img src={success} alt="success"
          color="primary"
          style={webStyle.successImg}
        />
        <WalletDescription>Complete your KYC to access Wallet.</WalletDescription>
      </DialogContent>
      <DialogActions >
        <a
          data-test-id="doneBtn"
          color="primary"
          style={webStyle.doneBtn}
          href="Cfwallet19"
        >
          Proceed
        </a>
      </DialogActions>
    </Dialog>
  )
}


interface S {
  // Customizable Area Start
  openLocation: boolean;
  displayGrid: boolean;
  geoLocActive: boolean;
  locationCity: any;
  activeMenu: boolean;
  locationError: string;
  snackbarError: boolean;
  message: string,
  showSignUp: boolean;
  login: string,
  openHamburgerBar:boolean,
  topCitieslist: CityListResponse[],
  allCitiesList: CityListResponse[],
  token: any;
  isWalletModal:boolean,
  isKycVerified:boolean,
  isReferralSuccessOpen: boolean,
  isSearchOpen:boolean,
  profilePic: string,
  userName: string
  // Customizable Area End
}

export default class MainHeaderComp extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      openLocation: false,
      displayGrid: false,
      geoLocActive: false,
      locationCity: localStorage.getItem("location") || "Location",
      activeMenu: false,
      locationError: "",
      snackbarError: false,
      message: "",
      showSignUp: false,
      login: '',
      openHamburgerBar:false,
      topCitieslist: [],
      allCitiesList: [],
      token: '',
      isWalletModal:false,
      isKycVerified:false,
      isReferralSuccessOpen: false,
      isSearchOpen:false,
      profilePic: "",
      userName: "Guest",
    };
  }

  handleMoreCities = () => {
    this.setState({ displayGrid: !this.state.displayGrid })
  }

  openLocationModal = () => {
    this.setState({ openLocation: true })
  }
  handleSignupModal = () => {
    this.setState({ showSignUp: true, openHamburgerBar:false });
  }

  handleWallet = async() => {
    this.setState({ isWalletModal: true, openHamburgerBar:false });
    // const UserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
    // console.log("isKycVerified ----- ", UserDetails.attributes)
    // if(UserDetails.attributes.kyc_status === "approved"){
    //   this.navigateToWallet();
    // }else{
    //   this.setState({ isWalletModal: true, openHamburgerBar:false });
    // } 
  }

  handleOnClose = () =>{
    this.setState({ isWalletModal: false });
  }

  navigateToWallet = () =>{
   
  }

  closeSignUpModal = () => {
    this.setState({ showSignUp: false });
    window.location.href="Homepage"
  };

  sendCityLocation = (cityName: string) => {
    this.setState({ openLocation: false })
    this.setState({ displayGrid: false })
    this.setState({ locationCity: cityName })
    localStorage.setItem('location', cityName)
    this.updateLocation(cityName);

    window.dispatchEvent(new Event(EventListenerKeys.ProfileUpdated))
  }

  updateLocation = async (cityName:string) =>{
    const data = new FormData();
    const token = JSON.parse(JSON.stringify( await getStorageData("login")));
    data.append("data[location]", cityName);
    const requestOptions:any = {
      method: 'PUT',
      headers: { "token": token },
      body: data
  };
    try{
      const response = await fetch(
        `${baseURL}/account_block/accounts`,
          requestOptions
      );
    }catch(err){
      console.log(err);
    }}

  setGeoLoc = () => {
    this.setState({ geoLocActive: true })
  }

  menuHandler = () => {
    this.setState({ activeMenu: !this.state.activeMenu })
  }

  locationAllow = (city: string) => {
    city && this.setState({ locationCity: city })
    city && localStorage.setItem('location', city)
    this.setState({ openLocation: false })
    this.setState({ geoLocActive: false })
  }

  locationBlock = (error: string) => {
    this.setState({ locationError: error })
    this.setState({ openLocation: true })
    this.setState({ geoLocActive: false })
    this.setState({ snackbarError: true, message: error })
  }

  handleError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarError: false })
  }

  handleHamburgerBar = () =>{
    this.setState({openHamburgerBar:false})
  }

  getCitiesFromLocalStorage = async (): Promise<void> => {
    const topCities = await getStorageData(LocalStorageKeys.TopCities, true) || [] as CityListResponse[]
    const allCities = await getStorageData(LocalStorageKeys.AllCities, true) || [] as CityListResponse[]
    const location = await getStorageData(LocalStorageKeys.Location) as string;
    const userName = await getStorageData(LocalStorageKeys.UserName) as string;
    const token = await getStorageData(LocalStorageKeys.LoginToken) as string;
    const profilePic = await getStorageData(LocalStorageKeys.ProfilePicture) as string
    this.setState({
      allCitiesList: allCities,
      topCitieslist: topCities,
      locationCity: location,
      userName: userName || "Guest",
      token: token,
      profilePic: profilePic
    })
  }

  getKYCVerified = async () =>{
    const UserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
    if(UserDetails?.attributes?.kyc_status === "approved"){
      this.setState({ isKycVerified: true });
    } 
  }

  checkReferralSuccess = async () => {
    const isOpen = await getStorageData(LocalStorageKeys.isReferralSuccess) as boolean
    if(isOpen) {
      this.setState({ isReferralSuccessOpen: true });
      await removeStorageData(LocalStorageKeys.isReferralSuccess)
    }
  }

  closeReferralDialog = () => this.setState({ isReferralSuccessOpen: false })

  async componentDidMount() {
    this.getCitiesFromLocalStorage()
    this.getKYCVerified();
    this.checkReferralSuccess();
    window.addEventListener('storage', this.getCitiesFromLocalStorage)
    window.addEventListener('CustomCityList', this.getCitiesFromLocalStorage)
    window.addEventListener('customUserDetails', this.getKYCVerified)
    window.addEventListener(EventListenerKeys.OpenSignUp, this.handleSignupModal)
    window.addEventListener(EventListenerKeys.UserDetails, this.getCitiesFromLocalStorage)
  }

  componentWillUnmount(): void {
    window.removeEventListener('storage', this.getCitiesFromLocalStorage)
    window.removeEventListener(EventListenerKeys.UserDetails, this.getCitiesFromLocalStorage)
    window.removeEventListener('CustomCityList', this.getCitiesFromLocalStorage)
    window.removeEventListener('customUserDetails', this.getKYCVerified)
    window.removeEventListener(EventListenerKeys.OpenSignUp, this.handleSignupModal)
  }

  openSearch = () =>{
    this.setState({isSearchOpen: !this.state.isSearchOpen})
  }

  openHamburgerMenu = () => this.setState({ openHamburgerBar: true })

  render() {
    const token = localStorage.getItem('login');
    return <>
      <Portal>
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarError}
          autoHideDuration={4000}
          onClose={this.handleError}
          message={this.state.message}
          style={{ zIndex: 2000 }}
        >
          <Alert severity="error" style={{ zIndex: 2000 }}>
            {this.state.message}
          </Alert>

        </Snackbar>
      </Portal>
      {
        !localStorage.getItem('location') &&
        <MainGeoLocation locationAllow={this.locationAllow} locationBlock={this.locationBlock} />
      }
      <Box display={{ xs: "none", md: "block" }}>

      <Grid>
        <Paper elevation={0} style={webStyle.paperAppBarStyle}>
        <Container maxWidth='lg'>
          <Box style={webStyle.boxAppBarStyle}>

            <Box style={webStyle.menuLeftStyle}>
              <Link to={AppRoutings.Home}>
                <img src={ticket_logo} style={webStyle.logoStyle} alt='TicketExchange Logo' />
              </Link>
              <Hidden mdDown>
              <Box component={"p"} style={{ marginLeft: '10px' }}></Box>
              <Link to={AppRoutings.MovieListing}><Box component={"p"} style={webStyle.menuTextStyle}>Movies</Box></Link>
              <Link to={"/itolisting"}><Box component={"p"} style={webStyle.menuTextStyle}>ITO</Box></Link>
              <Box component={"p"} style={webStyle.menuTextStyle}>Requests</Box>
              {/* <Box component={"p"} style={webStyle.menuTextStyle}>Events</Box> */}
              <Link to={"/allevent"}><Box component={"p"} style={webStyle.menuTextStyle}>Events</Box></Link>
              <Box component={"p"} style={webStyle.menuTextStyle}>Sports</Box>
              

              <Box style={{ marginLeft: '32px' }}>
                {
                  !this.state.activeMenu ?
                    <img
                      src={grid_icon}
                      style={webStyle.menuGridStyle}
                      alt='menu grid'
                      onClick={this.menuHandler}
                    /> :
                    <>
                      <img
                        src={grid_selected}
                        style={webStyle.menuGridStyle}
                        alt='selected menu grid'
                        onClick={this.menuHandler}
                      />
                      <Box style={webStyle.dropdownMenuStyle}>
                        <Link to="/voguelist" style={webStyle.dropdownMenuList}>Vogue</Link>
                        <Link to="/" style={webStyle.dropdownMenuList}> Hotels</Link>
                        <Link to="" style={webStyle.dropdownMenuList}> Trains</Link>
                        <Link to="" style={webStyle.dropdownMenuList}>Flights</Link>
                      </Box>
                    </>

                }

              </Box>
              </Hidden>
            </Box>
            
            <Box style={webStyle.menuRightStyle}>
            <Hidden mdDown>
              <Box onClick={()=> this.openSearch()} style={webStyle.searchBoxStyle}>
                <img
                  src={search}
                  style={webStyle.searchIconStyle}
                  alt='search icon'
                />
                <Box 
                  style={webStyle.searchInputStyle}
                  data-testid="searchText"
                >What do you want to watch?</Box>

              </Box>
              </Hidden>

                <Box style={webStyle.locationBoxStyle}>
                  <img
                    src={location_icon}
                    style={webStyle.locationIconStyle}
                    alt='location icon'
                    onClick={this.openLocationModal}

                  />
                <Box component={"div"}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    ...webStyle.locationTextStyle
                  }}>
                  {this.state.locationCity}
                </Box>
              </Box>
              {this.state.token ? (
                <>
                <LoginBtn
                  onClick={()=> this.setState({openHamburgerBar:true})}
                  variant="contained"
                  color="default"
                  startIcon= {<PlanPremiumIcon profilePic={this.state.profilePic} />}
                  endIcon={<KeyboardArrowDownIcon style={{color:'#ffb43a'}}/>}
                >
                  Hi,{this.state.userName}!
                </LoginBtn>
                </>
              ) : (
                <>
                  <Button style={webStyle.signupBtnStyle} onClick={this.handleSignupModal}>
                    Sign up
                  </Button>
                  {this.state.showSignUp && (
                    <UserSignUp
                      navigation={undefined}
                      id=""
                      showSignup={this.closeSignUpModal}
                    />
                  )}
                  <Box 
                    style={{ marginLeft: '20px' }}
                  >
                    <Button onClick={()=> this.setState({openHamburgerBar:true})}>
                    <img
                      src={hamburger_menu}
                      style={webStyle.hamburgerMenuStyle}
                      alt="Hamburger menu grid"
                    />
                    </Button>
                  </Box>
                </>
              )}

          {this.state.openHamburgerBar && 
            <LoginHamburgerBar 
              onHandleHamburgerBar={this.handleHamburgerBar}
              onHandleSignUpModal={this.handleSignupModal}
              onChangeLocation={this.openLocationModal}
              onHandleWallet = {this.handleWallet}
              isKycVerified={this.state.isKycVerified}
            />}
            </Box>
            

          </Box>

          {/* <WalletDialogBox 
                onClickProceed = {this.navigateToWallet}
                handleOnClose = {this.handleOnClose}
              /> */}
            {this.state.isWalletModal &&
              
              <KycDialogeBox navigation={""} id="" handleOnClose={this.handleOnClose}/>
            }
        </Container>
        </Paper>
      </Grid>
      <MainCityLocation
        openLocation={this.state.openLocation}
        displayGrid={this.state.displayGrid}
        handleMoreCities={this.handleMoreCities}
        citiesList={this.state.topCitieslist}
        allCitiesList={this.state.allCitiesList}
        sendCityLocation={this.sendCityLocation}
        locationAllow={this.locationAllow}
        locationBlock={this.locationBlock}
        geoLocActive={this.state.geoLocActive}
        setGeoLoc={this.setGeoLoc}
      />

      </Box>


      {this.state.isSearchOpen && <AdvancedSearch navigation={this.props.navigation} id="" open={this.state.isSearchOpen} handleClose={this.openSearch}/>}
      
      <RFHDialog
        data-test-id="success-referral-code"
        open={this.state.isReferralSuccessOpen}
        hideCloseButton
        maxWidth="xs"
        disableEscapeKeyDown={true}
        dialogActions={(
          <RFHDialogButton data-test-id="accept-done-btn" fullWidth onClick={this.closeReferralDialog}>
            Done
          </RFHDialogButton>
        )}
        dialogContent={(
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Box display="flex" justifyContent="center" p={0}>
              <img src={SuccessIcon} height="48px" width="48px" alt="done" />
            </Box>
            <Typography align="center" style={webStyle.ReferralTitle}>
              Referral added Successfully!
            </Typography>
            <Typography align="center" style={webStyle.ReferralDescription}>
              Amount will be credited to wallet when KYC is done.
            </Typography>
          </Box>
        )}
      />

      <Box display={{ xs: "block", md: "none" }}>
        <Container>
          <BoxWrapper className={this.props.addPadding ? "padding" : ""}>

            <Box className="profile__details__wrapper">
              <img className="profile__pic" src={this.state.profilePic || hamburgerBarIcon.addpic} alt="profile" />
              
              <Box className="profile__name">
                <Typography className="profile__title">Hi, {this.state.userName}</Typography>

                <Box className="location__wrapper">
                  <img src={location_icon} alt="location" className="location__icon" onClick={this.openLocationModal} />
                  <Typography className="location">{this.state.locationCity}</Typography>
                </Box>

              </Box>
            </Box>

            <Box className="redirection__wrapper">
              <img src={search} alt="search" className="search__icon" onClick={this.openSearch} />
              <img src={hamburger_menu} alt="hamburger-menu" className="hamburger__icon" onClick={this.openHamburgerMenu}/>
            </Box>

          </BoxWrapper>
        </Container>
      </Box>

    </>
  }
}

// Customizable Area Start
const webStyle = {

  paperAppBarStyle: {
    height: '82px',
    backgroundColor: 'rgba(64,64,64,0.4)',
    backgroundBlendMode: 'normal',
    // padding: '0 100px',
    display: 'flex',
    justifyContent: 'center'
  },
  addbutton: {
    borderRadius: "16px",
    width: "128px"
  },
  ReferralTitle: {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: 600,
    color: "#FFF",
    marginTop: "32px"
  },
  ReferralDescription: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#FFF",
    marginTop: "16px",
    maxWidth: "332px"
  },
  boxAppBarStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    // width: '1440px',

  },
  menuLeftStyle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: "-5px"
  },
  menuRightStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logoStyle: {
    height: '72px',
    width: '52px',
    cursor: 'pointer'
  },
  menuTextStyle: {
    marginLeft: '18px',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
  },
  menuGridStyle: {
    height: '24px',
    width: '24px',
    cursor: 'pointer',
    position: 'relative' as const,
  },
  searchBoxStyle: {
    height: '32px',
    width: '272px',
    borderRadius: '4px',
    backgroundColor: '#404040',
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
    cursor:"pointer"
  },
  searchInputStyle: {
    height: '32px',
    width: '298px',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '12px',
    fontFamily: "Poppins",
    color: '#fff',
    display:"contents",
  },
  searchIconStyle: {
    height: '16px',
    width: '16px',
    marginRight: '8px',
  },
  locationBoxStyle: {
    marginLeft: '16px',
    height: '31px',
    width: '150px',
    borderRadius: '4px',
    backgroundColor: '#404040',
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  locationIconStyle: {
    height: '24px',
    width: '22px',
    margin: '0 8px 0 0',
    cursor: 'pointer'
  },
  locationTextStyle: {
    fontSize: '16px',
    fontFamily: "Poppins",
    color: '#fff',
  },
  signupBtnStyle: {
    marginLeft: '16px',
    width: '89px',
    height: '37px',
    backgroundColor: '#ffb43a',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    color: '#000',
  },
  hamburgerMenuStyle: {
    height: '30px',
    width: '30px',
  },



  dropdownMenuStyle: {
    position: 'absolute' as const,
    zIndex: 1000,
    width: '112px',
    height: '166px',
    padding: '16px',
    borderRadius: '12px',
    boxShadow: '2px 2px 12px 0 rgba(0, 0, 0, 0.6)',
    border: 'solid 1px #404040',
    backgroundColor: '#252525',
    top: '72px',
    display:"flex",
    flexDirection:"column"
  }as CSSProperties,
  dropdownMenuList: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#fff',
    fontWeight: 500,
    lineHeight: 1.38,
    marginBottom: '16px'
  },
  successImg:{
    height: '48px', 
    width: '48px'
  },
  dialogContent:{
    textAlign:"center",
  }as CSSProperties,
  doneBtn:{
    padding: '12px 80px',
    width:"100%",
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign:"center",
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  }as CSSProperties,
  crossBtn:{  
    height: '28px', 
    cursor: 'pointer', 
    position:"absolute", 
    top:8, 
    right:8 
  }as CSSProperties
  

}

const BoxWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "16px",
  "&.padding": {
   paddingLeft: "16px",
   paddingRight: "16px" 
  },
  "& .profile__details__wrapper": {
    display: "flex",
    alignItems: "space-between",
    gap: "12px",
    "& .profile__pic": {
      width: "56px",
      height: "56px",
      borderRadius: "56px",
    },
    "& .profile__name": {
      "& .profile__title": {
        color: "#fff",
        fontFamily: "Poppins",
        fontSize: "16px",
        marginBottom: "7px"
      },
      "& .location__wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        "& .location__icon": {
          width: "13px",
          height: "15px",
          marginRight: "6px",
          display: "inline-block"
        },
        "& .location": {
          color: "#FFF",
          fontFamily: "Poppins",
          fontSize: "14px"
        }
      }
    },
  },
  "& .redirection__wrapper": {
    display: "flex",
    alignItems: "center",
    gap: "18px",
    "& .search__icon": {
      width: "24px",
      height: "24px"
    },
    "& .hamburger__icon": {
      height: "24px"
    }
  }
})

  // Customizable Area End 