import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCOCrjfYXd6o_1NU73X9Y2keliIMPow8YA",
  authDomain: "ticketxchange-c3e9d.firebaseapp.com",
  projectId: "ticketxchange-c3e9d",
  storageBucket: "ticketxchange-c3e9d.appspot.com",
  messagingSenderId: "454055361543",
  appId: "1:454055361543:web:d1ccb0f310dee419e47ea2",
  measurementId: "G-X7VP099BLH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
