import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Typography,
  Tab,
  CircularProgress,
  Fade,
  IconButton,
  Popper,
  Popover,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Checkbox
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom"

import { search } from "../../splashscreen/src/assets";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { IEventAdvanchSearch, IMovieAdvanchSearch } from "../../../components/src/interfaces.web";
import { FilterIcon } from "../../ordermanagement/src/assets";
import { PlanPremiumIcon, PlanPremiumIcon2 } from "../../reservations2/src/SeeAllMovies.web";

const configJSON = require("./config");

const TabPanelComponent = styled(TabPanel) ({
  maxHeight:"55vh",
  overflowY:"auto",
  "& .MuiTabPanel-root-307":{
    padding:"24px 0px"
  }
});

const WhiteBackgroundCheckboxUnchecked = withStyles({
  root: {
      color: "#ffb43a",
      '&$checked': {
          color: '#ffb43a',
      },
      "& .MuiIconButton-label": {
          position: "relative",
          zIndex: 0
      },
      "&:not($checked) .MuiIconButton-label:after": {
          content: '""',
          left: 4,
          height: 15,
          width: 15,
          position: "absolute",
          top: 4,
          zIndex: -1
      },
      padding: '0px',
      marginRight: "12px"
  },
  checked: {}
})(Checkbox);

const RFHMenuList = styled(MenuList)({
  padding: "0 !important",
  minWidth: "190px",
  "& > li:nth-child(odd)": {
    padding: "8px 16px",
    color: "#fff",
    border: "solid 1px #5a5a5a !important",
    fontFamily: "Poppins",
    fontSize: "16px",
    backgroundColor: "#5a5a5a !important",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "#ffb43a !important",
      border: "none"
    },
  },
  "& > li:nth-child(even)": {
    padding: "8px 16px",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "16px",
    backgroundColor: "#404040 !important",
    border: "solid 1px #5a5a5a !important",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "#ffb43a !important",
      border: "none"
    }
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {movieList, eventList} = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Modal
            open={true}
            onClose={this.props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>

              <Box style={webStyle.searchBoxStyle}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={search}
                    style={webStyle.searchbarIconStyle}
                    alt='searchbar icon'
                  />

                  <input type="text"
                    name='search'
                    placeholder='What do you want to watch?'
                    value={this.state.searchValue}
                    onChange={(event)=> this.handleSearchChange(event)}
                    style={webStyle.searchInputStyle}
                    data-test-id="searchText"
                  />

                </Box>
              </Box>

              <Box sx={{ p: 2, display:"flex", justifyContent:"space-between"}}>
                <Box style={{width:"100%"}}>
                <TabContext value={this.state.tabValue}>

                  <TabList
                    id="tabChange"
                    style={{ alignItems: "center" }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#ffb636",
                        maxWidth: "113px",
                        minWidth: 0
                      }
                    }}
                    onChange={this.handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab style={webStyle.custonTab} label="Movies" value="1" />
                    <Box sx={webStyle.tabDivider}></Box>
                    <Tab style={webStyle.custonTab} label="Events" value="2" />
                  </TabList>

                  <Typography style={webStyle.h1}>Trending Searches</Typography>

                  {this.state.loading ? <Fade in={this.state.loading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress style={webStyle.CircularProgress} />
                    </Box>
                  </Fade> :
                    <>
                      <TabPanelComponent value="1">
                        {movieList.length > 0 ? movieList.map((movieList: IMovieAdvanchSearch) => {
                          const { id, attributes: { name, category_type, image_url, is_ito_live } } = movieList;
                          return (<Box data-test-id = "redirectToMovie" style={{cursor:"pointer"}} key={id} onClick={()=> this.navigateToMovieDetail(+id, is_ito_live)}>
                          <Box 
                            sx={webStyle.movieTypo} 
                            >
                            <img style={webStyle.movieCardImage} src={image_url} />
                            <Box style={webStyle.nameAndTypeContainer}>
                              <Typography style={webStyle.h3}>{name}</Typography>
                              <Typography style={webStyle.h4}>
                                {configJSON.movieText}&nbsp;&nbsp;{configJSON.uaText}&nbsp;{category_type}</Typography>
                            </Box>
                          </Box>
                          </Box>)
                        })
                          : <Typography align="center" style={webStyle.h1}>NO result found</Typography>
                        }

                      </TabPanelComponent>
                      <TabPanelComponent value="2">
                        {eventList.length > 0 ? eventList.map((eventList: IEventAdvanchSearch) => {
                          const { id, attributes: { name, poster_url, show_type } } = eventList;
                          return (<Box data-test-id = "redirectToEvent" style={{cursor:"pointer"}} key={id} onClick={()=> this.navigateToEventDetail(+id)}>
                          <Box 
                            sx={webStyle.movieTypo} 
                            >
                            <img style={webStyle.movieCardImage} src={poster_url[0]} />
                            <Box style={webStyle.nameAndTypeContainer}>
                              <Typography style={webStyle.h3}>{name}</Typography>
                              <Typography style={webStyle.h4}>
                              {show_type.map((showType:string , index :number) => <span key={index}>{showType} </span>)}
                              </Typography>
                            </Box>
                          </Box>
                          </Box>)
                        })
                          : <Typography align="center" style={webStyle.h1}>NO result found</Typography>
                        }
                      </TabPanelComponent></>}

                </TabContext>
                </Box>
                <Box display="flex" alignItems="flex-start" style={{paddingTop:12}}>
                    <IconButton
                      style={webStyle.FilterIcon}
                      onClick={this.handleClick}

                      aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.anchorEl ? 'true' : undefined}
                      data-test-id="filter-menu-button"
                    >
                      <img src={FilterIcon} alt="Filter-Button" width="22px" height="22px" />
                      <span style={webStyle.FilterText}>Filter</span>
                    </IconButton>

                  <Popper
                    open={!!this.state.anchorEl}
                    anchorEl={this.state.anchorEl}
                    role={undefined}
                    placement="bottom-end"
                    transition
                    disablePortal
                    style={webStyle.zIndex}
                  >
                    <Popover
                      open={!!this.state.anchorEl}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          background: 'transparent !important',
                          borderRadius: "12px",
                          boxShadow: "none",
                        },
                      }}

                      >
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <RFHMenuList
                            autoFocusItem={!this.state.anchorEl}
                            id="composition-menu"
                            data-test-id="filter-menu-root"
                          >

                            {
                              this.state.languages.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  data-test-id="filter-menu"
                                  onClick={() => this.handleFilterLanguage(option.id)}
                                >
                                  <WhiteBackgroundCheckboxUnchecked
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    data-test-id="checkboxt"
                                    checked={this.state.languageIds.find((langId)=> langId===option.id)? true:false}
                                    onChange={() => {
                                    }}
                                    checkedIcon={<PlanPremiumIcon />}
                                    icon={<PlanPremiumIcon2 />}
                                  />
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                          </RFHMenuList>
                        </ClickAwayListener>
                      </Popover>

                    </Popper>

                  </Box>
              </Box>
            </Box>
          </Modal>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const modalStyle = {
  position: "relative" as const,
  top: 30 as const,
  maxWidth: "1009px",
  bgcolor: "#252525",
  border: "2px solid #000" as const,
  boxShadow: "1px 2px 20px 1px #000" as const,
  width:"100%",
  margin:'0.5vw auto',
  borderRadius:"20px"
};

const webStyle = {
  searchBoxStyle: {
    width: "100%",
    borderRadius: '0.2vw',
    height: 53,
    backgroundColor: '#404040',
    alignItems: 'center'as const,
    display: 'flex',
    boxSizing: 'border-box' as const,
    padding: "0px 24px",
    justifyContent: 'space-between', 
  },
  CircularProgress: {
    margin: "20px 0px",
    color: "#ffb43a"
},
  searchInputStyle: {
    boxShadow: 'none',
    fontFamily: "Poppins",
    border: 'none',
    color: '#fff',
    outline: 'none',
    fontSize: 16,
    backgroundColor: 'transparent',
  },
  searchbarIconStyle: {
    marginRight: 8,
    width: 20,
    height: 20,
  },
  custonTab: {
    color: "#fff",
    fontFamily: 'Poppins',
    opacity: "1",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: '1.5',
    textTransform: "capitalize" as const,
    minWidth: 100,
    width: 113
  },
  tabDivider: {
    height: "32px",
    width: "2px",
    margin: "0px 24px",
    backgroundColor: "#404040",
  },
  movieCardImage: {
    height: 55,
    width: 55,
    marginRight: 16
  },
  nameAndTypeContainer:{
    flexDirection:"column" as const,
    display:"flex",
    justifyContent:"center"
  },
  movieTypo: {
    borderBottom:"1px solid #000000",
    padding:"15px 0px",
    display: "flex",
    cursor:"pointer",
    width: "100%",
  },
  h1: {
    fontFamily: "Poppins",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 40,
    color: "#fff",
  },
  h3: {
    color: "#fff",
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "Poppins",
    marginBottom: 5,
  },
  h4: {
    fontWeight: 300,
    fontFamily: "Poppins",
    color: "#b4b4b4",
    fontSize: 14,
  },
  FilterIcon: {
    padding: 0,
    color: "#ffb43a",
  },
  FilterText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    marginLeft: "12px",
    color: "#fff",
  },
  zIndex: {
    top: "16px !important",
    zIndex: 2,
  },
}
// Customizable Area End
