import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData } from "../../../framework/src/Utilities";
import { getLastPartOfURL } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { IEventBookingFullResponse, IUserDetails } from "../../../components/src/interfaces.web";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEdit:boolean;
  eventBookingData: IEventBookingFullResponse;
  isConvenienceFeeOpen: boolean;
  isCostOpen: boolean;
  isLoading:boolean;
  openNavigationDialog:boolean;
  eventListToSell:any;
  defaultPrice:any;
  isModalOpen:boolean;
  dialogueMessage:string;
  updatedPrice:any;
  inputErrorMessage:string;
  seatID:any;
  bookingRequestEventFilter:any;
  isSeatEdit:boolean;
  is_invalid:boolean;
  isTransferAmountData:boolean;
  isApprovedKyc:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventViewTicketController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    eventBookingHistoryApiID:any
    inValidEventBookingApiID:any
    eventCancelListingApiID:any
    UpdateEventListAPiID:any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isEdit:false,
            eventBookingData:{} as IEventBookingFullResponse,
            isConvenienceFeeOpen: false,
            isCostOpen: false,
            isLoading:true,
            openNavigationDialog:false,
            eventListToSell:{},
            defaultPrice:0,
            isModalOpen:false,
            dialogueMessage:"",
            updatedPrice:null,
            inputErrorMessage:"",
            seatID:{},
            bookingRequestEventFilter:[],
            isSeatEdit:false,
            is_invalid:false,
            isTransferAmountData:false,
            isApprovedKyc:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

          if (resJson) {

            switch (apiRequestCallId) {
              case this.eventBookingHistoryApiID:
                resJson.data.attributes.is_invalid
                  ? this.handleInValidTicket()
                  : this.setState({
                    eventBookingData: resJson.data,
                    isLoading: false,
                    eventListToSell: resJson.data.attributes.listed_resell_seats,
                    is_invalid: resJson.data.attributes.is_invalid
                  })
                break;

              case this.inValidEventBookingApiID:
                this.setState({
                  eventBookingData: resJson.data,
                  isLoading: false,
                  eventListToSell: resJson.data.attributes.listed_resell_seats,
                  is_invalid: resJson.data.attributes.is_invalid
                })
                break;

              case this.eventCancelListingApiID:
                this.setState({ dialogueMessage: "Listing Cancelled..!" });
                break;

              case this.UpdateEventListAPiID:
                this.handleUpdateEventResponse(resJson)
                break;
            }
          }
      }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
      window.scrollTo(0, 0);
      this.getEventBookingData();
      window.addEventListener(EventListenerKeys.ProfileUpdated, this.getEventBookingData)
  }

  async componentWillUnmount() {
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getEventBookingData);
  };

  getEventBookingData = async () => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    const bookingId = getLastPartOfURL()

    this.eventBookingHistoryApiID = callApi({
        method: configJSON.GETMethod,
        contentType: configJSON.apiContentType,
        endPoint: `${configJSON.getEventBookingApiEndPoint.replace(":id", bookingId)}`,
        headers: { "token": authToken },
    }, runEngine)
}

handleShowCost() {
  this.setState({ isCostOpen: !this.state.isCostOpen })
}

showCostData() {
  this.setState({ isConvenienceFeeOpen: !this.state.isConvenienceFeeOpen })
}

navigateToContactUs = () => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "Contactus");
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  msg.addData(getName(MessageEnum.NavigationScreenNameMessage), {});
  this.send(msg)
}

handleOpenNavigation = () => {
  this.setState({ openNavigationDialog: true })
}

handleCloseDialog = () => {
  this.setState({ openNavigationDialog: false })
}

navigateToListTOsell = async() =>{
  const bookingId = getLastPartOfURL();
  const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true)) as IUserDetails;
  if (userDetails.attributes.kyc_status != "approved") {
    this.setState({ isApprovedKyc: true });
    return
  }
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "EventListToSell");
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  msg.addData(getName(MessageEnum.NavigationScreenNameMessage), bookingId);
  this.send(msg)
}

handleCancelListing = async(id:any) => {
  const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    this.eventCancelListingApiID = callApi({
        method: configJSON.DELETEMethod,
        contentType: configJSON.apiContentType,
        endPoint: `${configJSON.deleteEventListingApiEndPoint.replace(":id", id)}`,
        headers: { "token": authToken },
    }, runEngine)
}

isShowTimeOut = () => {
  const showTiming = moment(this.state.eventBookingData?.attributes?.schedule.start_time, 'YYYY-MM-DD | hh:mm A');
   const currentTiming = moment().format('YYYY-MM-DD | hh:mm A');
  const currentDateTimeFormatted = moment(currentTiming, 'YYYY-MM-DD | hh:mm A');
   return (showTiming.isBefore(currentDateTimeFormatted) || this.state.eventBookingData?.attributes?.status === "cancelled")
}

handleGoBack = () => {
  this.props.navigation.goBack();
}

handleEditInfo = () => {
  const { eventBookingData, eventListToSell } = this.state
  let arr = eventBookingData && eventBookingData.attributes.seats.map((item_number) => {
      let newArr: never[] = []
      return eventListToSell && eventListToSell.all_listed_seats.find((item: any, index: number) => {
          if (item.id === item_number.id) {
              this.setState({ defaultPrice: item.total_amount })
              return [...newArr, item]
          }
      })
  }
  )
  const seatArr = arr.filter(item => item !== undefined)
  this.setState({
      isModalOpen: true,
      isEdit: true,
      bookingRequestEventFilter: seatArr
  });
}

handleOnClickDoneCancleListing = () => { 
  this.setState({ dialogueMessage: "" }, ()=> this.getEventBookingData())
}

handleChangePrice = (e:any) =>{
  const inputValue = e.target.value;
  let regex = /^\d+$/;
  const isValidInputs = regex.test(inputValue);
  if (inputValue > 49999) {
      this.setState({ inputErrorMessage: 'Enter less than 49999 amount' })
  }
  else {
      this.setState({ updatedPrice: isValidInputs ? inputValue : '', inputErrorMessage: '' });
  }
}

isValid = (data?: string) => {
  if(this.checkStringNulls(data || this.state.updatedPrice)){
    this.setState({ inputErrorMessage: 'Enter a Price' })
  }
  else {
      this.setState({ inputErrorMessage: '' })
  }
} 

checkStringNulls = (str: string) => {
  return str === null || str?.length === 0
}

handleEditSeat = () => {
  this.setState({
      isSeatEdit: true,
  });
}

updateListApiById = async () => {
  const { bookingRequestEventFilter, updatedPrice, seatID } = this.state
  const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
  let bookingRequestId = this.state.eventBookingData.attributes.listed_resell_seats.id;

  let seatIds = Object.keys(seatID)

  if (seatIds.length === 0) {
      // Extract seat IDs from the requests array
      seatIds = bookingRequestEventFilter.flatMap((request: any) => {
        return request.id;
      });
  }

  const httpBody = {
     "event_booking_request": {
        "price_per_seat": updatedPrice,
        "no_of_seats": seatIds.length,
        "seat_ids": seatIds
    }
  }

  this.UpdateEventListAPiID = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.PUTMethod,
      endPoint: `${configJSON.updateEventListingApiEndPoint}/${bookingRequestId}`,
      headers: { "token": authToken },
      body: httpBody
  }, runEngine)
}

handleSeatSelect = (seatId: any) => {
  this.setState((prevState) => {
      const isSeatSelecteds = prevState.seatID[seatId];
      return {
          seatID: {
              ...prevState.seatID,
              [seatId]: !isSeatSelecteds,
          },
      };
  });
}

handleUpdateEventResponse = (resJson: any) => {
  if (resJson && resJson.error) {
      this.setState({ inputErrorMessage: resJson.error.listed_ticket[0] })
  }
  if (resJson.data) {
      this.setState({
          isSeatEdit: false,
          isModalOpen: false,
          inputErrorMessage: '',
          seatID: {}
      }, () => {
          this.forceUpdate()
          this.getEventBookingData()
      });
  }
}

handleInValidTicket = async() => {
  const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    const bookingId = getLastPartOfURL()

    this.inValidEventBookingApiID = callApi({
        method: configJSON.GETMethod,
        contentType: configJSON.apiContentType,
        endPoint: `${configJSON.getInvalidEventBookingApiEndPoint.replace(":id", bookingId)}`,
        headers: { "token": authToken },
    }, runEngine)
}

handleClickEventNewTicket = () => {
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "EventViewTicket");
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.eventBookingData.attributes.child_booking?.id);
  this.send(msg);

  this.getEventBookingData()
}

showTransectionAMountData() {
  this.setState({ isTransferAmountData: !this.state.isTransferAmountData })
}

handleCloseKycPopup = () => this.setState({ isApprovedKyc: false });

handleCloseEditPopup = () => this.setState({isModalOpen:false})
    // Customizable Area End
}
