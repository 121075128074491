import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITOFilterOption, LocalStorageKeys, TabOption } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { IPagination, eventOrMovieBookingData } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filterAnchorEl: HTMLElement | null,
  isLoading: boolean,
  selectedTab: TabOption,
  itoBookingHistory:eventOrMovieBookingData [],
  selectedFilter: ITOFilterOption,
  itoPagination: IPagination,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ITOBookingHistoryController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
        itoBookingHistoryApiID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            filterAnchorEl: null,
            isLoading: true,
            selectedTab: TabOption.UpComing,
            itoBookingHistory:[],
            selectedFilter: ITOFilterOption.All,
            itoPagination: {
                per_page: 0,
                current_page: 0,
                next_page: null,
                prev_page: null,
                total_pages: 0,
                total_count: 0,
              },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
            if (apiRequestCallId === this.itoBookingHistoryApiID) {
              this.setState({ 
                itoBookingHistory: resJson.data, 
                itoPagination: resJson.meta.pagination, 
                isLoading: false 
              })
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    

    async getITOBookingHistory(filter = ITOFilterOption.All, period = TabOption.UpComing, page?: number | null) {

        let apiUrl = configJSON.ITOBookingHistoryEndPoint as string
    
        if (filter !== ITOFilterOption.All) apiUrl = apiUrl.concat(`?filter=${filter}&period=${period}`)
        else apiUrl = apiUrl.concat(`?period=${period}`)
     
        if(page) apiUrl = apiUrl.concat(`&page=${page}`)
    
        const token = await getStorageData(LocalStorageKeys.LoginToken)
    
        this.itoBookingHistoryApiID = callApi({
          method: configJSON.GETMethod,
          contentType: configJSON.apiContentType,
          endPoint: apiUrl,
          headers: { "token": token },
        }, runEngine)
    
      }

    async componentDidMount() {
        this.getITOBookingHistory()
        window.scrollTo(0, 0);
    }

    handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ filterAnchorEl: event.currentTarget });

    handleChangeFilter = (newValue: ITOFilterOption) => {

        if (newValue === this.state.selectedFilter) return
    
        this.getITOBookingHistory(newValue, this.state.selectedTab)
        this.setState({ selectedFilter: newValue, isLoading: true })
        this.handleFilterClose()
    }

    handleFilterClose = () => this.setState({ filterAnchorEl: null })

    redirectToViewTicketpage = (ticketId: number) => {
      let getTicketDetails = this.state.itoBookingHistory.find((elm)=> +elm.id === ticketId)
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), getTicketDetails?.type === "event_booking" ? "EventViewTicket": "ViewTicket");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.NavigationScreenNameMessage), ticketId);
      this.send(msg);
    }
    // Customizable Area End
}
