import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import Loader from "../../../components/src/Loader.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { Back_arrow } from "../../contactus/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h1:{
        fontSize:24,
        fontWeight:500,
        fontFamily:"Poppins",
        color:"#fff",
        marginBottom:5,
        '@media(max-width:600px)': {
          fontSize: "18px",
        },
      },
      h2:{
        fontWeight: 600,
        color: "#FFF",
        fontFamily: 'Poppins',
        fontSize: 24,
        '@media(max-width:600px)': {
          fontSize: "16px",
        },
      },
      h4: {
        color: "#fff",
        fontSize: 16,
        fontWeight:"normal",
        fontFamily: "Poppins",
        '@media(max-width:700px)': {
          fontSize: "14px",
        },
      },
      h3:{
        fontFamily: 'Poppins',
          fontSize: '18px',
          color: '#ffffff',
          fontWeight:500,
          margin: 0,
          '@media(max-width:700px)': {
              fontSize: "15px",
          }
      },
    },
  });

const AvailableBalanceContainer = styled(Box)({
    position: "relative",
    width: "100%",
    background: "#2d2d2d",
    padding: "32px",
    borderRadius: 8,
    height: 700,
    '@media(max-width:600px)': {
      padding:"32px 10px",
      height: "500",
    },
})

// Customizable Area End

import PaymentInfoController, {
    Props,
    configJSON,
} from "./PaymentInfoController";

export default class PaymentInfo extends PaymentInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div style={this.state.loading ? webStyle.overFlow : webStyle.bgcolor}>
            <Loader loading={this.state.loading} />
            <ThemeProvider theme={theme}>
                <MainHeaderComp navigation = {this.props.navigation} />
                <Container maxWidth={"lg"}>
                    <Grid 
                      style={webStyle.heading} 
                      spacing={3} 
                      container
                    >
                        <div style={webStyle.displayflex}>
                            <img
                                onClick={this.handleBackBtn}
                                alt='backArrow'
                                style={webStyle.backArrow}
                                src={Back_arrow}
                                data-test-id="back"
                            />
                            <Typography variant="h2" id="contactText">{configJSON.paymentInfoHeading}</Typography>
                        </div>
                    </Grid>

                    <Grid container style={webStyle.container}>
                        <AvailableBalanceContainer>
                            <Box style={webStyle.cardBox}>
                                <Box style={{ marginBottom: 40 }}>
                                    <Typography variant="h1">{this.state.beneficiaryDetails.name}</Typography>
                                    <Typography variant="h4">IFSC Code {this.state.beneficiaryDetails.ifsc} Account No. XX XX {this.state.beneficiaryDetails.bankAccount?.slice(-4)}</Typography>
                                </Box>
                                <Grid container justifyContent="space-between" style={webStyle.marginBottom20}>
                                    <Typography variant="h3">Amount</Typography>
                                    <Typography variant="h3"><span style={{fontFamily:"Roboto"}}>&#8377;</span> {this.state.amount}</Typography>
                                </Grid>

                                <Grid container justifyContent="space-between" style={webStyle.marginBottom20}>
                                    <Typography variant="h3">Charges (0%)</Typography>
                                    <Typography variant="h3"><span style={{fontFamily:"Roboto"}}>&#8377;</span> 0</Typography>
                                </Grid>
                                <Box style={webStyle.separeteLine}></Box>

                                <Grid container justifyContent="space-between" style={webStyle.marginBottom20}>
                                    <Typography variant="h3">Total Payable</Typography>
                                    <Typography variant="h3"><span style={{fontFamily:"Roboto"}}>&#8377;</span> {this.state.amount}</Typography>
                                </Grid>
                            </Box>
                            <Button
                                data-test-id="proceedBtn"
                                color="primary"
                                style={webStyle.doneBtn}
                                onClick={() => this.handleProceed()}
                            >
                                Proceed
                            </Button>
                        </AvailableBalanceContainer>
                    </Grid>

                    {/* for success and failed transection popup */}
                    <Dialog
                      id="language"
                      open={this.state.transectionStatusPopupContent.message}
                      PaperProps={{
                        style: {
                          backgroundColor: "#404040",
                          color: "#fff",
                          padding: '25px 32px',
                          maxWidth: "456px",
                          boxSizing: "border-box",
                        },
                        elevation: 0,
                      }}
                      keepMounted
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      data-test-id="seat_dialog"
                    >
                      <DialogContent
                        style={webStyle.dialogContent}
                        data-test-id="seat_number"
                      >
                        <img 
                          style={webStyle.tansactionImg}
                          src={this.state.transectionStatusPopupContent.image} 
                          alt="success"
                        />
                        <Typography variant="h1" style={{ marginTop: 24 }}>{this.state.transectionStatusPopupContent.message}</Typography>
                      </DialogContent>
                      <DialogActions >
                        <Button
                          data-test-id="transactionStatusBtn"
                          onClick={() => this.navigateToWallet()}
                          style={webStyle.popupBtn}
                          color="primary"
                        >
                          Done
                        </Button>
                      </DialogActions>
                    </Dialog>
                </Container>
                <FooterWithEvents />
            </ThemeProvider>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  heading: {
    marginTop: 30
  },
  displayflex:{
    display:"flex",
    alignItems:"center"
  },
  container: {
    marginTop: "32px",
    maxWidth: 967,
    background: "#404040",
    borderRadius: "16px",
  },
  backArrow: {
    cursor: "pointer",
    heigth: "32px",
    marginRight: 10,
    width: "32px",
  },
  cardBox: {
    maxWidth: 689,
    height: 292,
    borderRadius: 16,
    padding: 20,
    backgroundColor: "#404040",
    borderBottom: "20px solid #ffb43a"
  },
  overFlow:{
    overflow:"hidden",
    backgroundColor: "#121212"
  },
  bgcolor: {
    backgroundColor: "#121212"
  },
  doneBtn: {
    padding: '12px 80px',
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    position: "absolute",
    right: 32,
    bottom: 32,
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  } as CSSProperties,
  separeteLine: {
    width: "100%",
    background: "#595959",
    height: 1,
    marginBottom: 20
  },
  marginBottom20: {
    marginBottom: 20
  },
  dialogContent: {
    textAlign: "center",
  } as CSSProperties,
  popupBtn: {
    padding: '12px 80px',
    width: "100%",
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    marginTop:18,
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  } as CSSProperties,
  tansactionImg:{
    width:253,
    height:157
  }
}
// Customizable Area End
