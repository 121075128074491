export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const productImg = require("../assets/shoes.png");
export const confirmImage = require("../assets/confirm.png");
export const user = require("../assets/user.png");
export const notification = require("../assets/notification.png");

export const FeedbackSuccessful = require("../assets/successful.png");
export const PlayBtn = require("../assets/play.png");
export const searchIcon = require("../assets/search.png");
export const closeIcon = require("../assets/close.png");
export const filledHeart = require("../assets/filledheart.png");
export const outlinedHeart = require("../assets/heartNotSelected.png");
export const shareImg = require("../assets/share.png");
export const Screen = require("../assets/screen.png");
export const CheckedRadioButton = require("../assets/RadioButton.png");
export const editIcon = require("../assets/editicon.png");
export const reportIcon = require("../assets/report.png");
export const backButton = require("../assets/back.png");