import React, { CSSProperties } from "react";
import {
    Modal,
    Box,
    Button,
    Grid,
    styled,
    Paper,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Checkbox,
    withStyles,
    Hidden,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    createTheme,
    ThemeProvider,
    Typography,
} from "@material-ui/core";
import { WhatsApp, Google, MovieStart, Mail, EyeClose, EyeOpen, Mobile, indianFlag, checkbox, uncheck, closeIcon, requestimg, buyimg } from "./assets"
import UserSignUpController, { Props } from "./UserSignUpController.web";
import OtpInput from 'react-otp-input';
import Carousel from 'react-material-ui-carousel';
import UserLogin from "../../social-media-account-login/src/UserLogin.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import Loader from "../../../components/src/Loader.web";

export const configJSON = require("./config");

export const PlanPremiumIcon = ({ ...other }) => {
    return <img src={checkbox} alt="images" width="18px" />;
}

export const PlanPremiumIcon2 = ({ ...other }) => {
    return <img src={uncheck} alt="images" width="18px" />;
}

const WhiteBackgroundCheckbox = withStyles((theme) => ({
    root: {
        color: "black",
        "& .MuiIconButton-label": {
            zIndex: 0,
            position: "relative"
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            position: "absolute",
            left: 4,
            top: 4,
            width: 15,
            height: 15,
            zIndex: -1,
        },
        "& .MuiSvgIcon-root": {
            color: "#33a720"
        }
    },
    checked: {}
}))(Checkbox);

const theme = createTheme({
    typography: {
        h6: {
            marginTop: 20,
            fontWeight: 600,
            fontFamily: "Poppins",
            color: "#fff",
            textAlign: "center",
            fontSize: 24,
        },
    },
});


const sliderdata = [
    {
        "img": MovieStart,
        "title": "ITO - Movies",
        "para": "At Ticket Xchange you can apply under ITO for select movies and have the chance of watching your favourite Cinema in the first weekend at a discounted price."
    },
    {
        "img": buyimg,
        "title": "Buy - Sell",
        "para": "At Ticket Xchange you can conveniently buy or sell tickets in the simplest manner."
    },
    {
        "img": requestimg,
        "title": "Request",
        "para": "At Ticket Xchange you can request to buy the tickets for the seats of your choice."
    }

]

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    background: 'none',
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", alignItems: "center"
}));

const LoginBtn: any = styled(Button)(({ theme }) => ({
    height: '42px',
    width: '270px',
    backgroundColor: '#404040',
    borderRadius: '9px',
    margin: '24px 72px 0 72px',
    '&:hover': {
        background: '#404040',
    },
    color: 'white',
    textTransform: 'full-size-kana',
    justifyContent: 'start',
    fontSize: '13px'
}));

const Signupbtn = styled(Button)(({
    color: '#202020',
    backgroundColor: '#ffb43a',
    width: '294px',
    height: '42px',
    padding: '9px 28px 9px 12px',
    borderRadius: '9px',
    margin: '9px 72px 0 72px',
    '&:hover': {
        background: '#ffb43a',
    },
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: "Poppins"
}));


export default class UserSignUp extends UserSignUpController {
    constructor(props: Props) {
        super(props);
    }

    handlesignupwith = () => {
        return (this.state.signUpwith &&
            <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
                <Item style={WebStyle.removeBoxShadow}>
                    <div>
                        <div>
                            <span data-test-id="signup-title" style={WebStyle.mainheader} id="signup">Sign Up</span>
                        </div>
                        <Box component={"div"} display={"flex"} flexDirection={"column"}>
                            <LoginBtn id='phone' onClick={() => this.handleLoginWith('sms_account')}><img src={Mobile} alt="Mobile logo" style={WebStyle.icon} /><Box component={"span"} sx={WebStyle.captitxt}>Proceed with Mobile Number</Box></LoginBtn>

                            <LoginBtn id='google' onClick={this.googleSignup}><img src={Google} alt="Google logo" style={WebStyle.icon} />
                                <Box component={"span"} sx={WebStyle.captitxt}>Proceed with Google</Box>
                            </LoginBtn>

                            <LoginBtn data-test-id="proceed-with-email" id='emailModal' onClick={() => this.handleLoginWith('email_account')}><img src={Mail} alt="Mail logo" style={WebStyle.Micon} /><Box component={"span"} sx={WebStyle.captitxt}>Proceed with Email</Box></LoginBtn>
                        </Box>
                        <div style={WebStyle.or_div}>
                            <div style={WebStyle.orLeftLine}></div>
                            <span style={WebStyle.orWord}>OR</span>
                            <div style={WebStyle.orRightLine}></div>
                        </div>
                        <Box component={"div"} mt={"15px"}>
                            <span style={WebStyle.alreadyAccountText}>Already have an Account? <span id="logChange" onClick={this.changeToLogin} style={WebStyle.switchLoginText}>Login</span></span>
                        </Box>
                    </div>
                </Item>
            </Grid>
        )
    }


    handlewithemail = () => {
        return (this.state.withEmail &&
            <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
                <Item style={WebStyle.removeBoxShadow}>
                    <div >
                        <span data-test-id="email-title" style={WebStyle.mainheader} id="emailText">Create Account</span>
                    </div>
                    <Box component={"div"} display={"flex"} flexDirection={"column"}>
                        <label style={WebStyle.emailtext} data-test-id="email-label">Email Id</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                data-test-id="email"
                                id="email"
                                name="email"
                                type="text"
                                autoComplete='off'
                                className="email-text-input"
                                error={false}
                                onBlur={() => this.handleEmailValidation()}
                                placeholder="Enter email id"
                                onChange={(event) => this.handleEmailValue(event.target.value)}
                                value={this.state.email}
                            />
                        </FormControl>
                        <span style={WebStyle.formerror} data-test-id="emailError">
                            {this.renderErrorMessageforProfile(this.state.emailError)}
                        </span>
                        <label style={WebStyle.emailtext} data-test-id="email-label">Password</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                data-test-id="password"
                                autoComplete='off'
                                type={this.state.isShowPassword ? "text" : "password"}
                                error={false}
                                placeholder="Enter password"
                                value={this.state.password}
                                className="password-text-input"
                                name="password"
                                onChange={(event) => this.handlePasswordValue(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">

                                        <IconButton
                                            id="passwordShow"
                                            onClick={this.showPasswordChange}
                                            aria-label="toggle password visibility" edge="end">
                                            <img
                                                src={this.state.isShowPassword ? EyeClose : EyeOpen}
                                                alt="type"
                                                style={WebStyle.eye}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <span style={WebStyle.passwordError} data-test-id="password-error-message">
                            {this.renderErrorMessageforProfile(this.state.passwordError)}
                        </span>
                        <label style={WebStyle.emailtext} data-test-id="email-label">Confirm Password</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                data-test-id="confirm_password"
                                type={this.state.isShowConfirmPassword ? "text" : "password"}
                                error={false}
                                name="confirmPassword"
                                onBlur={() => this.validateConfirmPassword()}
                                placeholder="Enter Confirm password"
                                className="confirm-password-text-input"
                                onChange={(event) => this.handleConPasswordValue(event.target.value)}
                                value={this.state.confirmPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            id='showbutton'
                                            onClick={this.showConfirmPassword}
                                            aria-label="toggle password visibility" edge="end">
                                            <img
                                                src={this.state.isShowConfirmPassword ? EyeClose : EyeOpen}
                                                alt="type"
                                                style={WebStyle.eye}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <span style={WebStyle.formerror}>
                            {this.renderErrorMessageforProfile(this.state.confirmPasswordError)}
                        </span>

                        {
                            this.state.isReferralCodeApplied ? (
                                <Typography style={WebStyle.EmailAddReferralCode}>
                                    {configJSON.AppliedReferralText}&nbsp;
                                    <span data-test-id="email-referral-code-applied" style={WebStyle.AddReferralCodeLink} onClick={() => this.removeReferralCode()}>{configJSON.AppliedReferralRemove}</span>
                                </Typography>
                            ) : (

                                <Typography style={WebStyle.EmailAddReferralCode}>
                                    {configJSON.ReferralText}&nbsp;
                                    <span data-test-id="add-referral-btn" style={WebStyle.AddReferralCodeLink} onClick={() => this.openReferralCodeDialog('withEmail')}>{configJSON.ReferralLinkText}</span>
                                </Typography>
                            )
                        }

                    </Box>
                    <Box component={'div'} mt='45px' style={{ marginLeft: "-5px" }}>
                        <WhiteBackgroundCheckbox
                            id='checkbox-withEmail'
                            checkedIcon={<PlanPremiumIcon />}
                            checked={this.state.isChecked}
                            onClick={this.handleCheckChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            icon={<PlanPremiumIcon2 />}
                            size="small" />
                        <span style={WebStyle.terms}>I agree to the &nbsp;
                            <span style={WebStyle.forgot}
                                onClick={this.handleOnClickTermsAndCondition}>
                                Terms &amp; Conditions&nbsp;
                            </span >
                            <span style={WebStyle.terms}>and</span>
                            <span style={WebStyle.forgot}
                                onClick={this.handleOnClickPrivacyPolicy}>
                                &nbsp;Privacy Policy
                            </span>
                        </span>
                        {this.state.isChecked ? null : (
                            <span style={WebStyle.termsError}>Please accept Terms &amp; Conditions and Privacy Policy.</span>
                        )}
                    </Box>
                    <div>
                        <Signupbtn id='signup-button' onClick={this.handleSave} >Sign Up</Signupbtn>
                    </div>
                    <div style={WebStyle.or_div}>
                        <div style={WebStyle.orLeftLine}></div>
                        <span style={WebStyle.orWord}>OR</span>
                        <div style={WebStyle.orRightLine}></div>
                    </div>
                    <Box component={"div"} mt={"15px"}>
                        <span style={WebStyle.alreadyAccountText}>Already have an Account? <span onClick={this.changeToLogin} style={WebStyle.switchLoginText}>Login</span></span>
                    </Box>
                </Item>
            </Grid >
        )
    }

    handleReferralCode = () => {
        return (
            this.state.isReferralCodeOpen && (
                <Grid item xs={12} md={6} sm={12} style={WebStyle.displayGridReferral}>
                <Item style={WebStyle.referralWrapper}>
                    <Box component={"div"} display={"flex"} flexDirection={"column"} justifyContent="space-between">
                        <Typography data-test-id="add-referral-code" style={WebStyle.mainheader} id="emailText">{configJSON.AddReferralCode}</Typography>
                        <label style={WebStyle.emailtext} data-test-id="email-label">{configJSON.ReferralCode}</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                data-test-id="referral-code-input"
                                name="referralcode"
                                type="text"
                                autoComplete='off'
                                error={false}
                                placeholder="Enter Code"
                                onChange={(event) => this.handleReferralCodeChange(event.target.value)}
                                value={this.state.referralCode}
                            />
                        </FormControl>
                        <span style={WebStyle.referralcodeError} data-test-id="referral-error">
                            {this.renderErrorMessageforProfile(this.state.isReferralCodeErrorMessage)}
                        </span>
                    </Box>
                    <div>
                        <Signupbtn data-test-id="apply-referral-code" disabled={!this.state.referralCode} onClick={() => this.verifyCode()}>{configJSON.Apply}</Signupbtn>
                    </div>
                </Item>
            </Grid >
            )
        )
    }

    handlesmsaccount = () => {
        return (this.state.sms_account && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item style={WebStyle.removeBoxShadow}>
                <div>
                    <span style={WebStyle.mainheader}>Sign Up</span>
                </div>
                <Box component={"div"} sx={WebStyle.verifyPhoneText} id="mobileText">
                    <span>Verify your Mobile Number</span>
                </Box>
                <Box component={"div"} sx={WebStyle.digitfourr}>
                    <span >You will receive a 4-digit Verification Code on your Mobile Number.</span>
                </Box>
                <div>
                    <label style={{ fontSize: "13px", color: "rgb(255, 255, 255)", display: "flex", marginBottom: '12px', fontFamily: "Poppins" }} data-test-id="email-label">Mobile Number</label>
                    <OutlinedInput
                        value={this.state.phone}
                        onChange={(e) => this.handlePhoneChange(e.target.value)}
                        style={WebStyle.phoneer}
                        data-test-id="mobileNumber"
                        type="tel"
                        placeholder="Enter mobile number"
                        inputProps={{
                            style: { fontSize: '12px',fontFamily: "Poppins", },
                        }}
                        name="phoneNumber"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    style={{
                                        borderRight: "1px solid #979797",
                                        width: "67px",
                                        height: "36px",
                                        borderRadius: "0%",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        fontSize: '10px'
                                    }}
                                    id='countrycode'
                                >
                                    <img src={indianFlag} alt="indian" style={{ height: '11px', marginRight: "5px" }} />
                                    <span
                                        style={{
                                            fontSize: "10px",
                                            color: "#ffffff",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        +91
                                    </span>
                                    <KeyboardArrowDownIcon style={{ color: '#ffb43a' }} />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {this.state.phoneError && <span style={WebStyle.phoneError} id="phoneErr">{this.state.phoneErr}</span>}
                    {
                        this.state.isReferralCodeApplied ? (
                            <Typography style={WebStyle.AddReferralCode}>
                                {configJSON.AppliedReferralText}&nbsp;
                                <span style={WebStyle.AddReferralCodeLink} onClick={this.removeReferralCode}>{configJSON.AppliedReferralRemove}</span>
                            </Typography>
                        ) : (

                            <Typography style={WebStyle.AddReferralCode}>
                                {configJSON.ReferralText}&nbsp;
                                <span style={WebStyle.AddReferralCodeLink} onClick={() => this.openReferralCodeDialog('sms_account')}>{configJSON.ReferralLinkText}</span>
                            </Typography>
                        )
                    }
                </div>
                <div style={{ marginTop: '130px', borderRadius: '5px', display: 'inline-block', padding: '5px', marginLeft: "-5px" }}>
                    <WhiteBackgroundCheckbox
                        id='checkbox'
                        checked={this.state.isChecked}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onClick={this.handleCheckChange}
                        checkedIcon={<PlanPremiumIcon />}
                        icon={<PlanPremiumIcon2 />}
                        size="small" />
                    <span style={WebStyle.terms}>I agree to the &nbsp;
                        <span id="termsConditionLink"
                            style={WebStyle.forgot}
                            onClick={this.handleOnClickTermsAndCondition}>
                            Terms &amp; Conditions&nbsp;
                        </span>
                        <span style={WebStyle.terms}>and</span>
                        <span id="privacyPolicyLink"
                            style={WebStyle.forgot}
                            onClick={this.handleOnClickPrivacyPolicy}>
                            &nbsp;Privacy Policy
                        </span>
                    </span>
                    {this.state.isChecked ? null : (
                        <span style={WebStyle.termsError} id="checked">Please accept Terms &amp; Conditions and Privacy Policy.</span>
                    )}
                </div>
                <div>
                    <Signupbtn type='submit' id='phonesingup' onClick={() => this.handleSignUp()}>Sign Up</Signupbtn>
                </div>
                <div style={WebStyle.or_div}>
                    <div style={WebStyle.orLeftLine}></div>
                    <span style={WebStyle.orWord}>OR</span>
                    <div style={WebStyle.orRightLine}></div>
                </div>
                <Box component={"div"} mt={"15px"}>
                    <span style={WebStyle.alreadyAccountText}>Already have an Account? <span id="changelog" onClick={this.changeToLogin} style={WebStyle.switchLoginText}>Login</span></span>
                </Box>
            </Item>
        </Grid>)
    }

    handleotpverify = () => {

        return (this.state.otpVerify && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item style={WebStyle.removeBoxShadow}>
                <div>
                    <span style={WebStyle.mainheader} id="otpText">OTP</span>
                </div>
                <Box component={"div"} sx={WebStyle.verifyPhoneText}>
                    <span id="textOtp">Verify your Mobile Number</span>
                </Box>
                <Box component={"div"} sx={WebStyle.digitfourr}>
                    <span>Enter 4 digit code sent on {`+91${this.state.phone}`}</span>
                </Box>
                <Box component={"div"} mt='15px' display='flex' gridGap='3px' justifyContent='center'>
                    <OtpInput
                        value={this.state.otp || ''}
                        data-test-id="otp_values"
                        onChange={(value) => this.handleOtpValue(value)}
                        inputType="tel"
                        inputStyle={{
                            margin: '15px',
                            MozAppearance: 'textfield',
                            width: '42px',
                            borderRadius: '9px',
                            fontSize: '13px',
                            height: '42px',
                            backgroundColor: '#404040',
                            color: 'white',
                            border: 'none',
                            textAlign: 'center'
                        }}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                    />
                </Box>
                <div>
                    <HandleOtpError
                        data-test-id="otp-wrapper"
                        minutes={this.state.minutes}
                        seconds={this.state.seconds}
                        otpError={this.state.otpError}
                    />
                </div>
                <Box component={"div"} mt="158px">
                    <Box component={"div"} mt={"15px"}>
                        <span style={WebStyle.alreadyAccountText}>Didn't receive a Code? <span id='resend' onClick={() => { this.resendCode() }} style={WebStyle.switchLoginText}>Resend OTP</span></span>
                    </Box>
                </Box>
                <Box component={"div"} mt="15px">
                    <Signupbtn disabled={this.state.isOTPExpired}  type='submit' id='otp-submit' onClick={this.handleOTP}>Verify</Signupbtn>
                </Box>

            </Item>
        </Grid>)
    }
    render() {
        return (
            <div>
                {/* Customizable Area Start */}
                <ThemeProvider theme={theme}>
                    {!this.state.isTermsConditionPrivacyPolicy ? <Modal
                        id="modalOpen"
                        open={this.state.modelOpen}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={WebStyle.modal_style} >
                            <Hidden smUp>
                                <img id="closebtn" onClick={this.handleClose} src={closeIcon} alt="closeIcon" style={{ height: '30px', width: '30px', position: 'absolute', top: '74px', right: '12px' }} />
                            </Hidden>
                            <Box component={"div"} justifyContent="space-between" display='flex'>
                                <Hidden xsDown>
                                    <Grid item md={6} sm={6} style={WebStyle.gridFixCenterc}>
                                        <Item style={WebStyle.removeBoxShadow}>
                                            <div>
                                                <Carousel
                                                    navButtonsAlwaysInvisible={true}
                                                    IndicatorIcon={<Button></Button>}
                                                    animation="slide"
                                                    indicatorIconButtonProps={{
                                                        style: {
                                                            margin: "0 9px",
                                                            height: "3px",
                                                            borderRadius: "3px",
                                                            backgroundColor: "#ffb53a",
                                                            width: "3px",
                                                        }
                                                    }}
                                                    activeIndicatorIconButtonProps={{
                                                        style: {
                                                            backgroundColor: '#ffb53a',
                                                            width: "21px",
                                                            height: "3px",
                                                            borderRadius: "3px",
                                                        }
                                                    }}
                                                >
                                                    {sliderdata.map((data, index) => {
                                                        return (
                                                            <Box
                                                                component={"div"}
                                                                key={index}
                                                                justifyContent='center'
                                                                display='flex'
                                                                flexDirection='column'
                                                                alignItems='center'
                                                            >
                                                                <div>
                                                                    <img src={data.img} alt="df" style={WebStyle.caraImg} />
                                                                </div>
                                                                <div>
                                                                    <Box component={"span"} sx={WebStyle.cTitle}>{data.title}</Box>
                                                                    <Box component={"p"} sx={WebStyle.cPara}>{data.para}</Box>
                                                                </div>
                                                            </Box>
                                                        )
                                                    })}
                                                </Carousel>

                                            </div>
                                        </Item>
                                    </Grid>
                                    <hr style={WebStyle.divider}></hr>
                                </Hidden>

                                {this.handleReferralCode()}

                                {this.handlesignupwith()}

                                {this.handlewithemail()}

                                {this.handlesmsaccount()}

                                {this.handleotpverify()}
                            </Box>
                        </Box>
                    </Modal> :
                        <Dialog
                            data-test-id="dialog-wrapper"
                            id="terms"
                            open={true}
                            aria-labelledby="alert-dialog-slide-title"
                            keepMounted
                            PaperProps={{
                                style: WebStyle.terms_Condition_modal_style
                            }}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Loader loading={this.state.loading} />
                            <Grid style={{ display: "flex", justifyContent: "end" }}>
                                <img id="closedialogebtn"
                                    onClick={this.handleOnCloseTermsConditionPrivacyPolicy}
                                    src={closeIcon}
                                    alt="closeIcon"
                                    style={WebStyle.closeDialoge}
                                />
                            </Grid>
                            <DialogTitle
                                id="alert-dialog-slide-title"
                            >
                                {this.state.termsConditionPrivacyPolicyData.heading}
                            </DialogTitle>
                            <Divider style={WebStyle.customDivider} />
                            <DialogContent
                                id="alert-dialog-slide-description"
                                style={WebStyle.termsConditionDescription}
                                data-test-id="description"
                                dangerouslySetInnerHTML={{ __html: this.state.termsConditionPrivacyPolicyData.description }}
                            >
                            </DialogContent>
                        </Dialog>
                    }
                </ThemeProvider>
                {this.state.ToLogin && <UserLogin navigation={undefined} id={"closeLog"} ToLogin={this.closeLogin} showSignup={this.props.showSignup} />}

                {/* Customizable End Start */}
            </div >
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const WebStyle = {
    referralWrapper: {
        height: "100%", 
        justifyContent: "space-between",
        boxShadow: "none"
    },
    removeBoxShadow: {
        boxShadow: "none"
    },
    checkBoxRoot: {
        color: "black",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: "gray",
            zIndex: -1
        },
        "& .MuiSvgIcon-root": {
            color: "green"
        }
    },
    divider: {
        color: "#3e3e3e",
    },
    termsError: {
        color: 'red', display: 'flex', marginLeft: '8px', fontSize: "9px"
    },
    forgot: {
        color: '#f8b43a', fontSize: '10px', textDecoration: 'underline', cursor: "pointer"
    },
    terms: {
        color: '#b4b4b4', fontSize: '10px', fontFamily: "Poppins"
    },
    AddReferralCode: {
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        color: "#FFF",
        paddingTop: "24px",
        textAlign: "left" as const
    },
    AddReferralCodeLink: {
        fontWeight: 500,
        color: "#ffb636",
        cursor: "pointer" as const
    },
    EmailAddReferralCode: {
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        color: "#FFF",
        textAlign: "left" as const,
        margin: "9px 54px 0px"
    },
    EmailAddReferralCodeLink: {
        fontWeight: 500,
        color: "#ffb636",
        cursor: "pointer" as const,
        margin: "9px 54px 0px"

    },
    verifyPhoneText: {
        height: "15px",
        margin: "24px 24px 12px 9px",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1px",
        letterSpacing: "1px",
        textAlign: "center",
        color: " #fff"
    },
    otptimer: { marginTop: '3px', color: '#ffffff', fontFamily: "Poppins" },
    phoneError: {
        color: "red",
        display: "flex",
        marginTop: '10px',
        fontSize: '12px',
        fontFamily: "Poppins",
        textAlign: "initial" as const,
        width: '290px'
    },
    gridFixCenter: {
        display: 'flex', justifyContent: 'center',
        '@media (min-width: 320px) and (max-width: 768px)': {
            display: 'flex',
            justifyContent: "center"
        }
    },
    gridFixCenterc: {
        display: 'flex', justifyContent: 'center',

    },
    displayGridReferral: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column" as const
    },
    switchLoginText: { color: '#f8b43a', fontSize: "12px", textDecoration: 'none', cursor: 'pointer' },
    alreadyAccountText: { color: '#ffffff', fontSize: "12px", fontFamily: "Poppins" },
    orRightLine: { margin: '7px 0 7px 3px', width: '119px', height: '1px', backgroundColor: '#3e3e3e' },
    orLeftLine: { height: '1px', width: '119px', margin: '7px 0 7px 3px', backgroundColor: '#3e3e3e' },
    cPara: {
        width: "290px",
        height: "69px",
        margin: "10px 0 24px",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff",
        fontFamily: "Poppins"
    },
    icon: {
        width: "24px",
        height: "24px",
        margin: "0 12px 0 0",
    },
    Micon: {
        width: "24px",
        height: "17px",
        margin: "0 12px 0 0",
    },
    or_word: {
        width: "20px",
        height: "15px",
        color: "#8f9bb3",
        fontSize: "11px",
        padding: "0px 3px",
    },
    or_div: {
        margin: "20px 72px 0 72px",
        display: "flex",
        alignItems: "center"
    },
    cTitle: {
        width: "112px",
        height: "24px",
        margin: "54px 88px 10px",
        fontSize: "18px",
        fontWeight: "500",
        color: "#fff",
        fontFamily: "Poppins",
    },
    digitfourr: {
        height: "34px",
        width: "250px",
        margin: "0 24px 24px 9px",
        fontSize: "12px",
        textAlign: "center",
        color: "#929292",
        fontFamily: "Poppins"
    },
    modal_style: {
        boxSizing: "content-box" as const,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "500px",
        width: "800px",
        padding: "67px 0px",
        borderRadius: "12px",
        backgroundColor: "#121212",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '@media (min-width: 320px) and (max-width: 768px)': {
            height: "100vh",
            width: "100vw"
        }
    },
    terms_Condition_modal_style: {
        backgroundColor: "#404040",
        color: "white",
        height: "825px",
        width: "575px",
        borderRadius: 8,
    },
    closeDialoge: {
        height: '30px',
        width: '30px',
        position: 'absolute',
        top: '10px',
        right: '12px'
    } as CSSProperties,
    termsConditionDescription: {
        fontFamily: "Poppins",
        color: "#fff",
        fontSize: 16,
        textAlign: "justify",
    } as CSSProperties,
    customDivider: {
        backgroundColor: "#979797"
    },
    formerror: {
        color: "red",
        display: "flex",
        marginTop: "9px",
        fontSize: "12px",
        marginLeft: "57px",
        textAlign: "initial" as const,
        fontFamily: "Poppins, sans-serif"
    },
    referralcodeError: {
        color: "red",
        display: "flex",
        marginTop: "9px",
        fontSize: "12px",
        marginLeft: "57px",
        textAlign: "initial" as const,
        fontFamily: "Poppins, sans-serif",
        textTransform: "capitalize" as const
    },
    passwordError: {
        display: "block",
        maxWidth: "287px",
        color: "red",
        textAlign: "initial" as const,
        fontSize: "12px",
        margin: "9px 57px 0",
        fontFamily: "Poppins",
    },
    mainheader: {
        fontSize: '24px',
        fontWeight: 600,
        color: '#fff',
        fontFamily: "Poppins"
    },
    detail: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "50%",
        marginLeft: "27%",
    },
    emailtext: {
        fontSize: "13px",
        display: 'flex',
        color: "#ffffff",
        margin: "15px 37px 0 57px",
        fontFamily: "Poppins"
    },
    captitxt: {
        fontFamily: "Poppins",
        textTransform: "capitalize"
    },
    inputbox: {
        width: "294px",
        height: "42px",
        borderRadius: "9px",
        margin: "9px 54px 0 54px",
        backgroundColor: "#404040",
        border: "solid 1px #b4b4b4",
        color: '#ffffff',
        fontSize: "12px",
    },

    Otpinputbox: {
        backgroundColor: '#626060',
        width: '42px',
        height: '42px',
        alignSelf: "center",
        display: "flex",
        color: '#ffffff',
        justifyContent: "center",
    },
    otpError: { fontSize: '12px', color: 'red' },
    eye: {
        color: '#f8b43a', height: "13px", width: "18px"
    },
    phoneer: { border: "1px solid grey", borderRadius: "12px", color: "#ffffff", backgroundColor: '#404040', width: '294px', height: '43px' },
    caraImg: { height: "172px", width: "197px", margin: "0 32px 54px" },
    orWord: {
        color: "#8f9bb3",
        fontSize: "11px",
        padding: "0px 3px",
        fontFamily: "Poppins"
    }
}

const OtpError = ({ errorText, minutes, seconds }: { errorText: string; minutes: number; seconds: number }) => (
    <Box component="div" textAlign="start" width="248px">
        <span style={WebStyle.otpError}>{errorText}</span>
        {errorText === "OTP expired" ? ""
            :

            <Box component={'div'} textAlign="start" width="248px">
                <span style={WebStyle.otptimer}>OTP expires in
                    <b>{` ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`} </b>
                    seconds</span>
            </Box>
        }
    </Box>
);

const OtpTimer = ({ minutes, seconds }: { minutes: number; seconds: number }) => (
    <Box component="div" width="248px" textAlign="start">
        <span style={WebStyle.otptimer}>
            OTP expires in <b>{` ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
            </b> seconds
        </span>
    </Box>
);

export const HandleOtpError = ({ minutes, seconds, otpError }: { minutes: number; seconds: number; otpError: string }) => {
    if (minutes === 0 && seconds === 0) {
        return <OtpError errorText="OTP expired" minutes={minutes} seconds={seconds} />;
    } else if (otpError) {
        return <OtpError errorText={otpError} minutes={minutes} seconds={seconds} />;
    } else {
        return <OtpTimer minutes={minutes} seconds={seconds} />;
    }
};

// Customizable Area End