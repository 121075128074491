import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { signInWithPopup } from "firebase/auth";
import { firebaseConfigJson } from "../../social-media-account-registration/src/UserSignUpController.web";
import { callApi } from "../../../components/src/Toolkit";
import { EventListenerKeys, LocalStorageKeys, LoginTypes } from "../../../components/src/enums.web";
import { IUserDetails } from "../../../components/src/interfaces.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  ToLogin: any;
  showSignup: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  loading: boolean;
  isRegistration: boolean;
  isShowPassword: boolean;
  modelOpen: boolean;
  isChecked: boolean;
  isShowConfirmPassword: boolean;
  phone: any;
  emailOtpVerify: boolean
  mobileOtpVerify: boolean,
  sms_account: boolean,
  withGoggle: boolean,
  withEmail: boolean,
  logInwith: boolean,
  passwordLoginValue: any,
  LognInType: string,
  rememberMe: boolean,
  emailOtpScreen: boolean,
  activeButton: string,
  withPassword: boolean,
  forgotPass: boolean,
  mobileOtp: string,
  emailOtp: string,
  minutes: number,
  seconds: number,
  otpError: string,
  email: string,
  ToSignUp: boolean,
  phoneError: boolean,
  phoneErr: string,
  toForgot: boolean,
  password: any,
  emailAddressError: any,
  passwordError: any,
  emailAddress: any,
  otpEmail: any,
  otpEmailError: any,
  isRequestingOTP: boolean,
  phoneToken: any,
  notRegUserError: any,
  isOTPExpired: boolean,
  isTermsConditionPrivacyPolicy: boolean,
  termsConditionPrivacyPolicyData: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

interface ErrorState {
  [objKey: string]: string;
}

type Errors = {
  [objKey: string]: boolean;
}

export default class UserLoginController
  extends BlockComponent<Props, S, SS>
{
  loginPhoneCallId: any;
  loginPasswordCallId: any;
  mobileOtpConfirmCallId: any;
  emailOtpConfirmCallId: any;
  EmailCallId: any;
  resendCodeCallId: any
  // Customizable Area Start
  signInGoogleAPICallId: any;
  getTermsAndContions: any;
  getPrivacyPolicy: any;
  getAccountDetailsCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      loading: false,
      isRegistration: false,
      isShowPassword: false,
      modelOpen: true,
      isShowConfirmPassword: false,
      phone: null,
      isChecked: true,
      mobileOtpVerify: false,
      sms_account: false,
      emailOtpVerify: false,
      withGoggle: false,
      logInwith: true,
      withEmail: false,
      LognInType: '',
      passwordLoginValue: null,
      activeButton: 'OTP',
      rememberMe: false,
      emailOtpScreen: false,
      forgotPass: false,
      withPassword: false,
      mobileOtp: '',
      emailOtp: '',
      minutes: 1,
      seconds: 59,
      otpError: '',
      email: '',
      emailAddress: '',
      password: '',
      emailAddressError: "",
      passwordError: '',
      ToSignUp: false,
      phoneError: false,
      toForgot: false,
      otpEmail: '',
      otpEmailError: false,
      isRequestingOTP: false,
      phoneToken: '',
      phoneErr: '',
      notRegUserError: '',
      isOTPExpired: false,
      isTermsConditionPrivacyPolicy: false,
      termsConditionPrivacyPolicyData: {
        heading: "",
        description: ""
      }
      // otpValue:null
      // Customizable Area End
    };
  }

  // Customizable Area Start
  //When facebook sends back the reponse this gets called

  handleToSignup = () => {
    this.props.ToLogin()
  }

  handleRememberMeChange = (event: any) => {
    this.setState({ rememberMe: event.target.checked });
  }

  startLoading = () => {
    this.setState({ loading: true });
  };
  handleClose = async () => {
    this.setState({ modelOpen: false })
    await this.props.ToLogin()
    await this.props.showSignup()
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  handleToForgot = () => {
    this.setState({ toForgot: true })
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    this.stopLoading();

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
        runEngine.debugLog("GOIT");
        return;
    }

    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
        case this.loginPasswordCallId:
            this.handleLoginpass(resJson);
            break;

        case this.getTermsAndContions:
            this.handleReciveTermsAndCondition(resJson, "Terms & Conditions");
            break;

        case this.getPrivacyPolicy:
            this.handleReciveTermsAndCondition(resJson, "Privacy Policy");
            break;

        case this.loginPhoneCallId:
            this.handleReceive(resJson);
            break;

        case this.mobileOtpConfirmCallId:
            this.handleOtpMobileConfirmation(resJson);
            break;

        case this.emailOtpConfirmCallId:
            this.handleOtpEmailConfirmation(resJson);
            break;

        case this.resendCodeCallId:
            this.handleResendOtp(resJson);
            break;

        case this.signInGoogleAPICallId:
            this.handleGoogleSignIn(resJson);
            break;

        case this.EmailCallId:
            this.handleReceiveEmailOtp(resJson);
            break;

        case this.getAccountDetailsCallId:
            this.handleGetAccount(resJson);
            break;

        default:
            runEngine.debugLog("GOIT");
            break;
    }
}
  // Customizable Area Start
  handleReceiveEmailOtp = (resJson: any) => {
    if (resJson && resJson?.message && resJson.message === "account not found") {
      this.setState({ otpEmailError: true, isRequestingOTP: false })
    } else if (resJson && resJson.data) {
      this.setState({ emailOtpScreen: false, emailOtpVerify: true, seconds: 59, minutes: 1, isRequestingOTP: false, emailOtp: '' });
      if (this.state.isOTPExpired) this.startTimer()
    }
  }

  handleResendOtp = (resJson: any) => {
    if (resJson?.errors) {
      this.setState({ otpError: "Incorrect OTP" })
    } else if (resJson && resJson.data) {
      this.setState({ seconds: 59, minutes: 1, otpError: '', mobileOtp: '' });
      if (this.state.isOTPExpired) this.startTimer()
    }
  }

  startTimer = () => {
    const interval = setInterval(() => {
      this.setState(prevState => {
        const { minutes, seconds } = prevState;
        if (minutes === 0 && seconds === 0) {
          clearInterval(interval);
          return { isOTPExpired: true, minutes: 0, seconds: 0 }
        } else {
          const newSeconds = seconds === 0 ? 59 : seconds - 1;
          const newMinutes = seconds === 0 ? minutes - 1 : minutes;
          return { isOTPExpired: false, minutes: newMinutes, seconds: newSeconds };
        }
      });
    }, 1000);
  }

  handleLoginpass = (resJson: any) => {
    if (resJson?.errors) {
      this.setState({ notRegUserError: "Email or password you are using is incorrect." });
    }
    if (resJson && resJson.meta) {
      const token = resJson.meta.token;
      localStorage.setItem('login', token);
      this.getAccountDetails()
    }
    this.setState({ loading: false })
  }

  handleReceive = (resJson: any) => {
    if (resJson && resJson.errors && resJson.errors.length) {
      this.setState({ phoneError: true })
    } else if (resJson && resJson.meta) {
      const token = resJson.meta.token;
      this.setState({ sms_account: false, mobileOtpVerify: true, phoneToken: token });
    } else {
      this.setState({ phoneError: true, phoneErr: resJson?.errors?.message })
    }
  }
  handleOtpMobileConfirmation = async (resJson: any) => {
    console.log("res", resJson)
    if (resJson && resJson?.errors && resJson?.errors?.length) {
      const errors = resJson.errors[0];
      if (errors) {
        this.setState({ otpError: "Incorrect OTP" })
      }
    } else {
      const token = resJson.meta.token;
      localStorage.setItem('login', token);
      localStorage.setItem('isGuest', 'false');
      this.getAccountDetails()
    }

  }

  handleOtpEmailConfirmation = (resJson: any) => {
    if (resJson && resJson.errors && resJson.errors.length) {
      const errors = resJson.errors[0];
      if (errors) {
        this.setState({ otpError: "Incorrect OTP" })
      }
      else if (resJson?.data) {
        this.setState({ seconds: 59, minutes: 1, otpError: '' });
      }
    } else {
      const token = resJson.meta.token;
      localStorage.setItem('login', token);
      this.getAccountDetails()
    }
  }

  handleGoogleSignIn = (resJson: any) => {
    if (resJson) {
      const token = resJson.meta.token;
      localStorage.setItem('login', token);
      this.getAccountDetails()
    }
  }

  // Customizable Area Start
  //Create response callback.
  showPasswordChange = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  showConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
  };
  handleEmailOtpValue = (value: any) => {
    const onlyNumbers = /^\d*$/;

    if (onlyNumbers.test(value) || value === '') {
      this.setState({ emailOtp: value, otpEmailError: '' });
    } else {
      this.setState({ otpEmailError: 'Please enter only numbers.' });
    }
  }


  handleCheckChange = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }

  handlePhoneChange = (value: any) => {
    let numericValue = value?.replace(/\D/g, '');
    if (numericValue?.length <= 10) {
      this.setState({ phone: numericValue, phoneError: false })
    }
    this.setState({ phoneErr: "Enter a valid 10 digit Mobile Number" })
  }

  handleMobileOtpValue = (value: any) => {
    const onlyNumbers = /^\d*$/;

    if (onlyNumbers.test(value) || value === '') {
      this.setState({ mobileOtp: value, otpError: '' });
    } else {
      this.setState({ otpError: 'Please enter only numbers.' });
    }
  }


  handleotpEmailChange = (value: any) => {
    this.setState({ otpEmail: value, otpEmailError: false })
  }
  handleEmailValue = (value: any) => {
    this.setState({ emailAddress: value, emailAddressError: '' })
  }
  handlePasswordValue = (value: any) => {
    const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s:])([^\s]){8,}$/);
    const password = value;

    // Check if the entered password meets the criteria
    if (!passwordRegex.test(password)) {
      this.setState({
        password: value,
        passwordError: configJSON.errorsMessage
      });
    } else {
      this.setState({
        password: value,
        passwordError: '', // Clear any existing password errors
      });
    }
  }

  handleValidatePassword = () => {
    let passError = "Password Required"
    if (this.state.password === "") {
      this.setState({ passwordError: passError });
    } else {
      this.setState({ passwordError: "" });
    }
  }

  validateEmail = () => {
    let invalidEmail = "Enter a valid Email id"
    if (this.state.emailAddress === "" || !this.state.emailAddress.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
      this.setState({ emailAddressError: invalidEmail });
    }
  }

  handleValidateEmail = () => {
    if (this.state.otpEmail === "" || !this.state.otpEmail.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
      this.setState({ otpEmailError: "Enter a valid Email id" });
    } else {
      this.setState({ otpEmailError: "" });
    }
  }
  renderErrorMessageforProfile = (Message: any) => {
    if (Message) {
      return Message
    }
  }
  handleSave = () => {
    const errors: Errors = {
      password: this.state.password === "" || !this.state.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/),
      emailAddress: this.state.emailAddress === "" || !this.state.emailAddress.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
    };
    const invalidError = "Password Required"
    const errorMessages: ErrorState = {
      emailAddress: "Enter a valid Email id.",
      password: invalidError,
    };

    let isErrors = false;
    const errorObject: ErrorState = {};

    Object.keys(errors).forEach((objKey) => {
      if (errors[objKey]) {
        isErrors = true;
        errorObject[`${objKey}Error`] = errorMessages[objKey];
      } else {
        errorObject[`${objKey}Error`] = "";
      }
    });

    if (isErrors) {
      this.setState((prevState) => ({
        ...prevState,
        ...errorObject,
      }));

    } else if (this.state.isChecked === false) {
      return
    }
    else {
      this.handleLogIn();
    }
  }

  handleLoginWith = (isSelected: any) => {

    if (isSelected === "OTP") {
      this.setState({ activeButton: "OTP", withPassword: false, logInwith: false, emailOtpScreen: true })
    }
    if (isSelected === "sms_account") {
      this.setState({ sms_account: true, logInwith: false })
    }
    if (isSelected === "Password") {
      this.setState({ activeButton: "Password", withPassword: true, emailOtpScreen: false, logInwith: false })
    }
    if (isSelected === "forgotPass") {
      this.setState({ forgotPass: true, withPassword: false })
    }
    if (isSelected === LoginTypes.Google) {
      this.googleSignIn()
    }
    this.setState({ LognInType: isSelected })
  }

  googleSignIn = async () => {

    const customProvider =
      firebaseConfigJson.provider.setCustomParameters(
        {
          prompt: "select_account",
        }
      );

    const { user: { email, uid } } = await signInWithPopup(firebaseConfigJson.auth, customProvider)
    const httpBody = { data: { "type": "social_account", "attributes": { "email": email, "unique_auth_id": uid, "platform": "google" } } };
    this.signInGoogleAPICallId = callApi({ contentType: configJSON.contentTypeApi, method: configJSON.apiPostMethodType, endPoint: configJSON.signUpGoogleEndPoint, body: httpBody }, runEngine);
  }

  handlesms = () => {
    this.verfiedPhoneNumber();

    this.startTimer()
  }

  handlepassword = () => {
    this.verfiedPasswordlogin();
  }

  handleotp = () => {
    this.verfiedEmailId();

    this.startTimer();
  }


  handleLogIn = () => {
    const { LognInType } = this.state;
    if (LognInType === "sms_account") {
      if (!this.state.phone) {
        this.setState({ phoneError: true, phoneErr: "Mobile number is required." });
      } else if (!this.state.phone.match(/\d{10}/)) {
        this.setState({ phoneError: true });
      } else if (this.state.isChecked === false) {
        return
      } else {
        this.handlesms()
      }
    }

    if (LognInType === "Password") {
      if (this.state.isChecked === false) {
        return
      } else {
        this.handlepassword()
      }
    }

    if (LognInType === "OTP") {
      this.handleOtpLogin()
    }

  };

  handleOtpLogin = () => {
    if (this.state.otpEmail === '' || !this.state.otpEmail.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
      this.setState({ otpEmailError: true })
    } else if (this.state.isChecked === false) {
      return
    } else {
      this.handleotp()
    }
  }

  verfiedPhoneNumber = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      data: {
        "attributes": {
          "full_phone_number": `+91${this.state.phone}`
        }
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginPhoneCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginPhoneEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }




  verfiedPasswordlogin = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      data: {
        "type": "email_account",
        "attributes": {
          "email": this.state.emailAddress,
          "password": this.state.password
        }
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  handleMobileOTP = () => {

    if(this.state.mobileOtp.length < 4) { 
      this.setState({ otpError: "OTP required" })
      return
    }

    const token = this.state.phoneToken
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const httpBody = {
      "pin": this.state.mobileOtp,
      "device": "android"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.mobileOtpConfirmCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mobileOtpConfirmEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verfiedEmailId = () => {
    this.setState({ isRequestingOTP: true })
    const header = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      "email": this.state.otpEmail
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.EmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EmailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleResenddOtp = () => {
    const token = this.state.phoneToken
    const header = {
      "token": token,
      "Content-Type": 'application/json'
    };
    const httpBody = {
      "data": {
        "attributes": {
          "full_phone_number": `+91${this.state.phone}`
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendCodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendCodeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handleEmailOTP = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const httpBody = {
      data: {
        "type": "email_account",
        "attributes": {
          "email": this.state.otpEmail,
          "email_otp": this.state.emailOtp
        }
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailOtpConfirmCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleReciveTermsAndCondition = (resJson:any, heading:string) => {
    this.setState({termsConditionPrivacyPolicyData: {
      heading:heading,
      description:resJson.data.description
    },
    loading:false
    })
  }

  handleOnClickTermsAndCondition = () => {
    const method = "GET"
    const header = {
      "Content-Type": 'application/json',
    };

    this.setState({
      termsConditionPrivacyPolicyData: {
        description: "",
        heading: "",
      }, loading: true
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsAndContions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TermsAndConditionsURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isTermsConditionPrivacyPolicy: true });
  }

  handleOnClickPrivacyPolicy = () => {
    const method = "GET"
    const header = {
      "Content-Type": 'application/json',
    };

    this.setState({
      termsConditionPrivacyPolicyData: {
        description: "",
        heading: "",
      }, loading: true
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PrivacyPolicyURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isTermsConditionPrivacyPolicy: true });
  }

  handleOnCloseTermsConditionPrivacyPolicy = () => {
    this.setState({ isTermsConditionPrivacyPolicy: false });
  }
  getAccountDetails = async() => {
    const method = "GET"
    const token = await getStorageData(LocalStorageKeys.LoginToken) as string
    const header = {
      "Content-Type": 'application/json',
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleGetAccount = async (response: any) => {
    await setStorageData(LocalStorageKeys.UserDetails, JSON.stringify(response.data as IUserDetails))
    await setStorageData(LocalStorageKeys.UserName, (response.data as IUserDetails).attributes.first_name)
    await setStorageData(LocalStorageKeys.ProfilePicture, (response.data as IUserDetails).attributes.profile_image)
    await setStorageData(LocalStorageKeys.Location, (response.data as IUserDetails).attributes.user_city.name)
    window.dispatchEvent(new Event(EventListenerKeys.UserDetails))
    this.props.ToLogin()
    this.props.showSignup()
    this.forceUpdate()
  }

  async componentDidMount() {
    await super.componentDidMount();
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    if (loginToken) {
      await setStorageData("isGuest", 'false')
      this.getAccountDetails()
    } else {
      await setStorageData("isGuest", 'true')
    }
  }
  // Customizable Area End
}