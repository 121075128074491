import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area Start
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL, isEmpty } from "../../../components/src/utilities";
import { IMovieData, IMovieDetailsResponse, IMoviesItoFilter } from "../../../components/src/interfaces.web";
import { MovieCrewCast } from "../../../components/src/CardCarousel";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";

const INITIAL_FEEDBACK_OPTIONS = [
  { optionId: 1, label: "Producer", checked: false },
  { optionId: 2, label: "Director", checked: false },
  { optionId: 3, label: "Lead Actor", checked: false},
  { optionId: 4, label: "Lead Actress", checked: false },
  { optionId: 5, label: "Character Artist", checked: false },
  { optionId: 6, label: "Others (Specify in Message Box)", checked: false }
]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface AuctionList {
  id?: string,
  type?: string,
  attributes: {
    product_name?: string;
    description?: string;
    start_time?: Date;
    end_time?: Date;
    minimum_bid?: number;
    maximum_bid?: number;
    status?: string;
    product_images:[
      {
        id: number,
        url: string
      }
    ];
    maximum_bid_placed?: number;
    time_remaining?: string;
  }
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedTab : number;
  live_auction: Array<AuctionList>,
  upcoming_auction: Array<AuctionList>,
  isLoading: boolean,
  movieDetails: IMovieData,
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  isExpired: boolean,
  movieCastList: MovieCrewCast[],
  movieCrewList: MovieCrewCast[],
  isMovieCastLoading: boolean,
  isMovieCrewLoading: boolean,
  isFeedbackOpen: boolean,
  isSelectLanguageOpen: boolean,
  selectedLanguageId: number,
  selectedFormatId: number,
  isFeedbackFromOpen: boolean,
  selectedOptionsIds: number[],
  feedbackMessage: string,
  feedBackOptions: { optionId: number, label: string, checked: boolean }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AuctionBiddingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId : string = "";
    getLiveAuctionCallId : string = "";
    getUpcommingAuctionCallId : string = "";
    getMovieIPOCallId: string = "";
    getMovieCastCallId: string = "";
    getMovieCrewCallId: string = "";
    feedbackCallId: string = "";
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      // Customizable Area Start
      // Customizable Area End
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage)
        // Customizable Area End
      ];
  
      this.state = {
        // Customizable Area Start
        selectedTab: 0,
        live_auction: [],
        upcoming_auction: [],
        isLoading: true,
        isMovieCastLoading: true,
        isMovieCrewLoading: true,
        movieCastList: [],
        movieCrewList: [],
        movieDetails: {} as IMovieData,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isExpired: true,
        isFeedbackOpen: false,
        isSelectLanguageOpen: false,
        isFeedbackFromOpen: false,
        selectedFormatId: 0,
        selectedLanguageId: 0,
        selectedOptionsIds: [],
        feedbackMessage: "",
        feedBackOptions: INITIAL_FEEDBACK_OPTIONS
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  
      // Customizable Area Start
      // Customizable Area End
    }
    
    async componentDidMount() {
     // Customizable Area Start
      this.getLiveAuctionData();
      this.getUpcommingAuctionData();

      window.scrollTo(0, 0);
      let movieID = getLastPartOfURL();
      const userToken = (await getStorageData(LocalStorageKeys.LoginToken)) as string

      this.getMovieIPOCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.GetMethod,
        endPoint: (configJSON.GetMovieITOApiCallId as string).replace("{MOVIE_ID}", `${movieID}`),
        headers: { "token": userToken }
      }, runEngine)

      this.getMovieCastCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.GetMethod,
        endPoint: (configJSON.GetMovieCastApiCallId as string).replace("{MOVIE_ID}", `${movieID}`),
        headers: { "token": configJSON.GuestToken }
      }, runEngine)

      this.getMovieCrewCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.GetMethod,
        endPoint: (configJSON.GetMovieCrewApiCallId as string).replace("{MOVIE_ID}", `${movieID}`),
        headers: { "token": configJSON.GuestToken }
      }, runEngine)

      
      // Customizable Area End  
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        runEngine.debugLog("API Message Recived", message);
        
        if (responseJson && !responseJson.errors) {

            switch(apiRequestCallId) {
              case this.getMovieIPOCallId:
                this.startTimer((responseJson as IMovieDetailsResponse).data.attributes.ito_end_date, (responseJson as IMovieDetailsResponse).data.attributes.ito_end_time)
                this.setState({ isLoading: false, movieDetails: (responseJson as IMovieDetailsResponse).data })
                break;
              case this.getMovieCastCallId:
                this.setState({ isMovieCastLoading: false, movieCastList: (responseJson.data as MovieCrewCast[])})
                break;
              case this.getMovieCrewCallId:
                this.setState({ isMovieCrewLoading: false, movieCrewList: (responseJson.data as MovieCrewCast[])})
                break;
              case this.feedbackCallId:
                this.setState({ 
                  isFeedbackFromOpen: false, 
                  isFeedbackOpen: true,
                  feedbackMessage: "",
                  feedBackOptions: INITIAL_FEEDBACK_OPTIONS
                })
                break;
              default:
                break;
            }
          
        }
      }
      // Customizable Area End
    }
    // Customizable Area Start
    apiCall = async(data: {contentType: string,method: string, endPoint: string,body?:object, type?: string }) => {
      
      const { contentType, method, endPoint, body, type } = data;
  
      const token = (await StorageProvider.get("TOKEN")) || "";
      
      
      const header = {
        "Content-Type": contentType,
        token : type != "login" ? token : ""
      };
      //istanbul ignore next
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      //istanbul ignore next
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      
      //istanbul ignore next
      body && 
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      //istanbul ignore next
      runEngine.sendMessage(requestMessage.id, requestMessage);
      //istanbul ignore next
      return requestMessage.messageId;
    }

    //istanbul ignore next
    getLoginDetail = async() => {
      //istanbul ignore next
      let email = prompt("Please enter email", "");
      // istanbul ignore next
      let password = prompt("Please enter password", "");

      const attrs = {
        email: email,
        password: password,
        // device_id: StorageProvider.get("devicePushToken")
      };
      const setData = {
        attributes: attrs,
        type: "email_account",
      };
      const httpBody = {
        data: setData,
      };

      this.loginApiCallId = await this.apiCall({
        contentType: configJSON.loginApiContentType,
        method: configJSON.loginApiMethod,
        endPoint: configJSON.loginApiEndPoint,
        body: httpBody,
      });

    }
    
    getLiveAuctionData = async() => {
      this.getLiveAuctionCallId = await this.apiCall({
        contentType: configJSON.getAuctionApiContentType,
        method: configJSON.getAuctionApiMethod,
        endPoint: configJSON.getAuctionApiEndPoint + `?auction_type=live`,
        
      });
    }
  
    getUpcommingAuctionData = async() => {
      this.getUpcommingAuctionCallId = await this.apiCall({
        contentType: configJSON.getAuctionApiContentType,
        method: configJSON.getAuctionApiMethod,
        endPoint: configJSON.getAuctionApiEndPoint + `?auction_type=upcoming`,
        
      });
    }

    onTabChange = (event: object, newValue: number) => {
      //istanbul ignore next
      this.setState({ selectedTab: newValue})
    };
    
  startTimer(ito_end_date: string, ito_end_time: string) {
    let timerID = setInterval(() => {

      const endDateString: string = ito_end_date;
      const endTimeString: string = ito_end_time;

      const combinedDateTimeString: string = `${endDateString}T${endTimeString}`;

      let targetDate = new Date(combinedDateTimeString).getTime();

      const currentTime = new Date().getTime();
      const distance = targetDate - currentTime;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.setState({ days, hours, minutes, seconds, isExpired: false });
      } else {
        this.setState({ isExpired: true })
        clearInterval(timerID);
      }
    }, 1000);
  }

  handleFeedbackClose = () => {
    this.setState({ isFeedbackOpen: false, isSelectLanguageOpen: true, selectedFormatId: 0, selectedLanguageId: 0 })
  }

  proceedToBook = () => {

    const movieId = this.state.movieDetails.id;
    const dataToStore = {
      selectedLanguageId: this.state.selectedLanguageId,
      selectedFormatId: this.state.selectedFormatId
    } as IMoviesItoFilter

    setStorageData(LocalStorageKeys.MoviesItoFilter, JSON.stringify(dataToStore))
      .then(() => {
        const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
        navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AuctionBiddingDetail");
        navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), movieId);
        this.send(navigationMessage);
      })
  }

  handleLanguageClose = () => {
    this.setState({ isSelectLanguageOpen: false })
  }

  selectLanguageFormat = (selectedId: number, option: "selectedFormatId" | "selectedLanguageId") => {
      this.setState((prevState) => ({ 
        ...prevState,
        [option]: selectedId 
      }))
  }

  handleApplyForIto = async () => {

    const isGuestUser = isEmpty(await getStorageData(LocalStorageKeys.LoginToken) as string)
    if (isGuestUser) {
      this.setState({ isSelectLanguageOpen: true, selectedFormatId: 0, selectedLanguageId: 0 });
    }
    else {
      this.setState({ isFeedbackFromOpen: true });
    }
  }

  handleCloseFeedbackForm = () => {
    this.setState({
      isFeedbackFromOpen: false,
      isSelectLanguageOpen: true,
      selectedFormatId: 0,
      selectedLanguageId: 0,
      feedbackMessage: "",
      feedBackOptions: INITIAL_FEEDBACK_OPTIONS
    })
  }

  submitFeedbackForm = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    const userToken = await getStorageData(LocalStorageKeys.LoginToken) as string

    if(userToken) {
      const payload = {
        feedback: {
          message: this.state.feedbackMessage,
          fan_types: this.state.feedBackOptions
            .filter(option => option.checked)
            .map(option => option.label),
          movie_id: this.state.movieDetails.id
        }
      }
  
      this.feedbackCallId = callApi({
        contentType: configJSON.ContentType,
        method: configJSON.PostMethod,
        endPoint: configJSON.submitFeedbackApiEndPoint,
        headers: { "token": userToken },
        body: payload
      }, runEngine)

    }
    
  }

  redirectToTrailers = () => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Trailers");
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.movieDetails.id);
    this.send(navigationMessage);
  }

  changeFeedbackMessage = ({ target: { value } }: { target: { value: string } }) => {
    this.setState({ feedbackMessage: value })
  }

  selectOption = (selectedId: number) => {
    const updatedOptions = this.state.feedBackOptions.map((option) => {
      if (option.optionId === selectedId) {
        return { ...option, checked: !option.checked };
      } else {
        return option;
      }
    });
    this.setState({ feedBackOptions: updatedOptions });
  }
    // Customizable Area End
  }