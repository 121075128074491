import { Button } from '@material-ui/core';
import React, { Component } from 'react'


interface Props {
  content: string
  charsToDisplay: number
}

interface S {
  isExpanded: boolean
}

export default class ReadMore extends Component<Props, S> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isExpanded: false
    };
    this.toggleReadMore = this.toggleReadMore.bind(this);
  }

  toggleReadMore() {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  }

  render() {

    const { content, charsToDisplay } = this.props;
    const { isExpanded } = this.state;
    const truncatedText = content.length > charsToDisplay
    ? content.substring(0, charsToDisplay) + '...'
    : content;


    return (
      <>
        {isExpanded ? content : truncatedText}
        {content.length > charsToDisplay && (
          <Button className='read__more' onClick={this.toggleReadMore}>
            {isExpanded ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </>
    )
  }
}
