export enum SeatStatus {
  Selected = 'selected',
  Available = 'available',
  Unavailable = 'unavailable',
  Buy = 'buy',
  Request = 'request',
  Block = 'block',
  OnlyShowAvailable = 'onlyShowAvailable'
}

export enum MenuFilterList {
  All = 'all',
  NonVeg = 'non_veg',
  Combos = 'combos',
  BestSellers = 'best_sellers',
  Veg = 'veg',
}

export enum LocalStorageKeys {
  TopCities = 'citiesList',
  AllCities = 'allCitiesList',
  UserDetails = 'userDetails',
  LoginToken = 'login',
  BookingData = 'bookingData',
  Location = 'location',
  SelectTimeSlotDetail = 'selectedTimeSlotDetail',
  PaymentContactInfo = 'paymentContactInfo',
  IsCashFree = "isCashFree",
  isGuest = "guest",
  RedirectTo="redirectTo",
  EventBookingData = "eventBookingData",
  isReferralSuccess = "isReferralSuccess",
  BookingForEvent = "bookingForEvent",
  FCMToken = "FCMToken",
  MoviesItoFilter = "movies_ito_filter",
  ProfilePicture = "profilePic",
  UserName = "userName",
  ValidListToSellRedirection = "valid_list_to_sell_redirection"
}

export enum LoginTypes {
  Google = 'google'
}

export enum EventListenerKeys {
  UpdateMenu = 'updateMenu',
  PreviewBookingUpdateMenu = 'previewBookingUpdateMenu',
  ProfileUpdated = 'profileUpdated',
  OpenSignUp = "openSignUp",
  GenerateFCMToken = "generateFCMToken",
  UserDetails = "userDetailsUpdate"
}

export enum BookableType {
  Movie = "BxBlockCategories::Movie",
  Venue = "BxBlockCategories::Venue"
}

export enum FilterOption {
  All = "All",
  Confirmed = "confirmed",
  Cancelled = "cancelled",
  BuyAndRequest = "buy_resell_request",
  Invalid = "is_invalid"
}

export enum TabOption {
  UpComing = 'upcoming',
  PastBooking = 'past'
}

export enum RequestFilterOption {
  All = "All",
  ViewRequests = "view_requests",
  RequestApproved = "approved",
  RequestDeclined = "declined",
  RequestPending = "pending",
  RequestTimeOut = "timed_out"
}

export enum ReviewsFilterOption {
  NewestFirst,
  OldestFirst
}

export enum BookingType {
  Event = "event"
}

export enum ITOBookingStatus {
  Pending = "pending",
  ListToSell = "alloted",
  Unsuccessfull = "refunded"
}

export enum ITOFilterOption {
  All = "All",
  Pending = "pending",
  Successfull = "alloted",
  Unsuccessfull = "refunded",
  ListToSell = "is_list_to_sell",
  Invalid = "is_invalid"
}

export enum DetailsType {
  Event = "event_detail",
  Movie = "movie"
}

export enum KycStatus {
  Approved = "approved",
  Pending = "Pending"
}

export enum HomePageFilterOption {
  ThisFriday = "coming_friday",
  CurrentlyPlaying = "playing",
  ComingSoon = "coming_soon"
}