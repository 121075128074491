export const imgSplash = require("../assets/logo.png");
export const imgloader = require("../assets/loader.png");

export const card1 = require("../assets/card1.png");
export const card2 = require("../assets/card2.png");
export const card3 = require("../assets/card3.png");
export const card4 = require("../assets/card4.png");
export const event1 = require("../assets/event1.png");
export const event2 = require("../assets/event2.png");
export const event3 = require("../assets/event3.png");
export const event4 = require("../assets/event4.png");

export const facebook = require("../assets/facebook.png");
export const grid_icon = require("../assets/grid_icon.png");
export const grid_selected = require("../assets/grid_selected.png");
export const hamburger_menu = require("../assets/hamburger_menu.png");
export const heart = require("../assets/heart.png");
export const instagram = require("../assets/instagram.png");
export const linkedin = require("../assets/linkedin.png");
export const twitter = require("../assets/twitter.png");
export const play = require("../assets/Play.png");
export const previous = require("../assets/previous.png");
export const rating_star = require("../assets/star.png");
export const search = require("../assets/search.png");
export const search_icon = require("../assets/search_icon.png");
export const ticket_logo = require("../assets/ticket_logo.png");
export const coming_white = require("../assets/coming_white.png");
export const current_icon = require("../assets/current_icon.png");
export const friday_white = require("../assets/friday_white.png");
export const location_icon = require("../assets/location_icon.png");
export const mainslider = require("../assets/mainslider.png");
export const addpic = require("../assets/addpic.png");
export const filled_heart = require("../assets/filled_heart.png");
export const SuccessIcon = require("../assets/success-icon.png");

const Notification = require("../assets/icon-notification.png");
const wallet = require("../assets/icon-wallet.png");
const bookingHistory = require("../assets/icon-booking-history.png");
const itoHistory = require("../assets/icon-ito-history.png");
const request = require("../assets/icon-request.png");
const listYourShow = require("../assets/icon-calendar.png");
const paymentSetting = require("../assets/icon-payment-setting.png");
const discountAndSetting = require("../assets/icon-discount.png");
const referAndEarn = require("../assets/icon-refer-and-earn.png");
const favourite = require("../assets/icon-favourite.png");
const contactUs = require("../assets/icon-contact-us.png");
const changeLocation = require("../assets/icon-location.png");
const logout = require("../assets/icon-logout.png");
const cancel = require("../assets/cancel.png");
const next = require("../assets/next.png");
const TransactionHistory = require("../assets/transaction-history.png");
const homeIcon = require("../assets/home.png");
const vogueIcon = require("../assets/vogue.png");

export const hamburgerBarIcon = {
  Notification,
  wallet,
  bookingHistory,
  itoHistory,
  request,
  listYourShow,
  paymentSetting,
  discountAndSetting,
  referAndEarn,
  favourite,
  contactUs,
  changeLocation,
  logout,
  cancel,
  addpic,
  next,
  TransactionHistory,
  homeIcon,
  vogueIcon
};
