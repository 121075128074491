import React, { CSSProperties } from "react";

import AddFavouritesController, { Props, configJSON } from "./AddFavouritesController.web";

// Customizable Area Start
import {
  Box,
  Container,
  Typography,
  Tab,
  Tooltip,
  CssBaseline,
} from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import Loader from "../../../components/src/Loader.web";
import { styled, createTheme, ThemeProvider, withStyles, Theme as AugmentedTheme, createStyles } from "@material-ui/core/styles";
import { backArrow } from "../../user-profile-basic/src/assets";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import { AddFavourites } from "./AddFavourites.web";
import { filled_heart, whiteHeart } from "../../reservations2/src/assets";
import { cinema, noFav } from "./assets";
import { goToPrevPage } from "../../../components/src/utilities";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      paper: "#404040",
      default: "#121212",
    },
    text: {
      primary: "#FFF",
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h2: {
      fontSize: '22px',
      fontWeight: 600,
      '@media (max-width: 600px)': {
        fontSize: "18px",
      }
    },
    h3: {
      fontSize: '18px',
      fontWeight: 500,
      '@media (max-width: 600px)': {
        fontSize: "16px"
      }
    },
    h4: {
      fontSize: '16px',
      fontWeight: "normal",
      '@media (max-width: 600px)': {
        fontSize: "14px"
      }
    },
    subtitle1: {
      fontSize: '18px',
      "@media (max-width: 600px)": {
        fontSize: "16px"
      } 
    }
  }
});

const CustomTab = styled(Tab)({
  fontFamily: 'Poppins',
  color: "#fff",
  fontWeight: 600,
  opacity: "1",
  fontSize: "20px",
  lineHeight: '1.5',
  textTransform: "capitalize",
  minWidth: 100,
  padding: "8px 12px",
  "@media (max-width: 600px)": {
    fontSize: "18px",
    padding: "8px 10px",
  }
});

export function DataNotAvailable(desc: any) {
  return <Box className="not__available__wrapper">
    <img className="not__available__poster" src={noFav} />
    <Typography data-test-id="no-favourites-message" variant="h2">No Favourites yet!</Typography>
    <Typography variant="subtitle1">{desc}</Typography>
  </Box>
}

export function FavouritableComponent(data: any, addFavourites: any, addFavouritesCall: any) {
  return (
    <Tooltip title="Dislike" arrow>
      <img
        src={filled_heart}
        className="like__dislike__button"
        id="heartIcon"
        alt="Heart Icon"
        onClick={() => addFavouritesCall(
          addFavourites.favouriteable_id ?? "0",
          addFavourites.favouriteable_type ?? "")}
      />
    </Tooltip>
  )
}

export function MovieDetailsCards(data: any, addFavouritesCall: any) {
  return data.map((elm: any) => {
    const { movie_image, movie_name, favouriteable_id } = elm.attributes;
    const addFavourites = {
      favouriteable_id: favouriteable_id,
      favouriteable_type: "BxBlockCategories::Movie",
    }
    return (
      <Box className="card__wrapper">
        <Box className="card__details">
          <img className="card__poster" src={movie_image} alt="movie-poster" />
          <Box>
            <Typography className="card__title" variant="h3">{movie_name}</Typography>
            <Typography className="card__subtitle" variant="h4">Movie</Typography>
          </Box>
        </Box>
        <Box>
          {FavouritableComponent(data, addFavourites, addFavouritesCall)}
        </Box>
      </Box>
    )
  })
}





export function EventDetailsCards(data: any, addFavouritesCall: any) {
  return data.map((elm: any) => {
    const { favouriteable_id, poster_url } = elm.attributes;
    const items = elm && elm.attributes.favouriteable || {};
    const addFavourites = {
      favouriteable_id: favouriteable_id,
      favouriteable_type: "BxBlockEvents::EventDetail",
    }
    return (
      <Box className="card__wrapper">
        <Box className="card__details">
          <img className="card__poster" src={poster_url} />
          <Box>
            <Typography className="card__title" variant="h3">{items.name}</Typography>
            <Typography className="card__subtitle" variant="h4">Event</Typography>
          </Box>
        </Box>
        <Box>
          {FavouritableComponent(data, addFavourites, addFavouritesCall)}
        </Box>
      </Box>
    )
  })
}

export function VenuesDetailsCards(data: any, addFavouritesCall: any) {

  const list = data.map((elm: any) => {
    const { theatre_name, favouriteable_id, theatre_complete_address } = elm.attributes;
    const addFavourites = {
      favouriteable_type: "BxBlockReservations::Theatre",
      favouriteable_id: favouriteable_id,
    }
    return (
      <Box className="card__wrapper">
        <Box className="card__details">
          <img className="card__poster" src={cinema} />
          <Box>
            <Typography className="card__title" variant="h3">{theatre_name}</Typography>
            <Typography className="card__subtitle" variant="h4">{theatre_complete_address}</Typography>
          </Box>
        </Box>
        <Box>
          {FavouritableComponent(data, addFavourites, addFavouritesCall)}
        </Box>
      </Box>
    )
  })

  return list

}

export function VogueDetailsCards(data: any, addFavouritesCall: any) {
  const list = data.map((elm: any) => {
    const { favouriteable_id, favouriteable, media } = elm.attributes;
    const items = elm && elm.attributes|| {};
    const addFavourites = {
      favouriteable_id: favouriteable_id,
      favouriteable_type: "BxBlockCategories::Vogue",
    }
    return (
      <Box  className="card__wrapper">
        <Box className="card__details">
          <img className="card__poster" src={media && media[0]} />
          <Box>
            {items.title &&
              <Typography className="innerHtmlStyle" variant="h3">{items.title.replace(/<[^<>]+?>/g, '')}</Typography>}
          </Box>
        </Box>
        <Box>
          {FavouritableComponent(data, addFavourites, addFavouritesCall)}
        </Box>
      </Box>
    )
  })

  return list

}
// Customizable Area End


export default class FavouritesComponent extends AddFavouritesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        
        <CssBaseline />

        <MainBoxWrapper>

          <Loader loading={this.state.loading} />

          <Box><MainHeaderComp navigation={this.props.navigation} /></Box>

          <Box flex={1}>
            <Container>

              <Box className="sub__header">
                <img className="back__button" data-test-id="back" src={backArrow} alt="back" onClick={goToPrevPage} />
                <Typography variant="h2">{configJSON.pageTitle}</Typography>
              </Box>

              <section className="content__wrapper">

              <TabContext value={this.state.tabValue}>

                <TabList
                  id="tabChange"
                  className="tab__list"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#ffb636",
                      maxWidth: "113px",
                      minWidth: 0
                    }
                  }}
                  onChange={this.handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <CustomTab label="Movies" value="1" />
                  <Box className="tab__divider"></Box>
                  <CustomTab label="Events" value="2" />
                  <Box className="tab__divider"></Box>
                  <CustomTab label="Venues" value="3" />
                  <Box className="tab__divider"></Box>
                  <CustomTab label="Vogue" value="4" />
                </TabList>

                <TabPanel value="1">
                  {this.state.favourites
                    ? MovieDetailsCards(this.state.favourites, this.addFavouritesCall)
                    : DataNotAvailable(configJSON.emptyMovie)
                  }
                </TabPanel>
                <TabPanel value="2">
                  {this.state.favourites
                    ? EventDetailsCards(this.state.favourites, this.addFavouritesCall)
                    : DataNotAvailable(configJSON.emptyEvent)
                  }
                </TabPanel>
                <TabPanel value="3">
                  {this.state.favourites
                    ? VenuesDetailsCards(this.state.favourites, this.addFavouritesCall)
                    : DataNotAvailable(configJSON.emptyVenue)
                  }
                </TabPanel>
                <TabPanel value="4">
                  {this.state.favourites
                    ? VogueDetailsCards(this.state.favourites, this.addFavouritesCall)
                    : DataNotAvailable(configJSON.emptyVogue)
                  }
                </TabPanel>
              </TabContext>
              </section>

            </Container>
          </Box>

          <Box><FooterWithEvents /></Box>

        
        </MainBoxWrapper>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainBoxWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "& .sub__header": {
    display: "flex",
    gap: "8px",
    margin: "22px 0",
    alignItems: "center",
    "& .back__button": {
      margin: "0 8px 0 4px",
      width: "28px",
      height: "28px",
      cursor: "pointer",
      "@media (max-width: 600px)": {
        width: "24px",
        height: "24px"
      }
    }
  },
  "& .content__wrapper": {
    "& .tab__list": {
      alignItems: "center",
      "& .tab__divider": {
        width: "1.5px",
        height: "32px",
        backgroundColor: "#404040",
        margin: "auto 24px",
        "@media (max-width: 600px)": {
          margin: "auto 8px"
        }
      },
      "& .MuiTabs-scroller": {
        overflow: "scroll !important",
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }
    },
    "& .MuiTabPanel-root": {
      padding: "8px 0"
    },
    "& .card__wrapper": {
      width: "100%",
      maxWidth: "698px",
      display: "flex",
      borderBottom: "1px solid #404040",
      padding: "22px 0px",
      justifyContent: "space-between",
      "& .card__details": {
        display: "flex",
        "& .card__poster": {
          width: "100px",
          height: "80px",
          borderRadius: "8px",
          marginRight: "14px",
          minWidth: "100px",
          "@media (max-width: 600px)": {
            width: "55px",
            height: "60px",
            minWidth: "55px",
            borderRadius: "unset",
            border: "solid 0.5px #404040"
          }
        },
        "& .card__title": {
          paddingBottom: "8px",
        },
        "& .card__subtitle": {
          color: "#b4b4b4"
        }
      },
      "& .like__dislike__button": {
        width: "20px",
        height: "18px",
        cursor: "pointer",
        marginTop: "2px",
      }
    }
  },
  "& .not__available__wrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .not__available__poster": {
      width: "300px",
      height: "212px",
      "@media (max-width: 600px)": {
        width: "260px",
        height: "220px"
      }
    }
  }
})

// Customizable Area End