import React from "react";
import {
    Modal,
    Box,
    Button,
    Grid,
    styled,
    Paper,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Checkbox,
    Dialog,
    withStyles,
    Hidden
} from "@material-ui/core";
import { MovieStart, EyeClose, EyeOpen, lockImage, checkbox, uncheck, requestimg, buyimg } from "./assets"
import UserPasswordForgotController, { Props } from "./UserPasswordForgotController.web";
import OtpInput from 'react-otp-input';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";
import UserLogin from "../../social-media-account-login/src/UserLogin.web";

export const PlanPremiumIcon = ({ ...other }) => {
    return <img src={checkbox} alt="images" width="18px" />;
}

export const PlanPremiumIcon2 = ({ ...other }) => {
    return <img src={uncheck} alt="images" width="18px" />;
}

const WhiteBackgroundCheckbox = withStyles((theme) => ({
    root: {
        color: "black",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            height: 15,
            top: 4,
            width: 15,
            backgroundColor: "rgb(64, 64, 64)",
            position: "absolute",
            zIndex: -1
        },
        "& .MuiSvgIcon-root": {
            color: "#33a720"
        }
    },
    checked: {}
}))(Checkbox);


const propsReactCodeInput = {
    inputStyle: {
        margin: '15px',
        MozAppearance: 'textfield',
        width: '42px',
        borderRadius: '10px',
        fontSize: '13px',
        height: '42px',
        backgroundColor: '#404040',
        color: 'white',
        border: 'none',
        textAlign: 'center'
    }
}


const otpProps: any = {
    inputStyle: {
        fontFamily: "Poppins",
        margin: "12px",
        MozAppearance: "textfield",
        width: "42px",
        height: "42px",
        fontSize: "13px",
        textAlign: "center",
        borderRadius: "6px",
        backgroundColor: '#626060'
    },
};

const sliderdata = [
    {
        "img": MovieStart,
        "title": "ITO - Movies",
        "para": "At Ticket Xchange you can apply under ITO for select movies and have the chance of watching your favourite Cinema in the first weekend at a discounted price."
    },
    {
        "img": buyimg,
        "title": "Buy - Sell",
        "para": "At Ticket Xchange you can conveniently buy or sell tickets in the simplest manner."
    },
    {
        "img": requestimg,
        "title": "Request",
        "para": "At Ticket Xchange you can request to buy the tickets for the seats of your choice."
    }

]


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    background: 'none'
}));

const ResetButton = styled(Button)(({
    backgroundColor: "#FFB43A",
    color: "black",
    borderRadius: '10px',
    height: '50px',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '15px'
}))

const Loginbtn: any = styled(Button)(({ theme }) => ({
    color: '#202020',
    backgroundColor: '#ffb43a',
    width: '270px',
    padding: '10px 28px 10px 12px',
    borderRadius: '10px',
    height: '42px',
    margin: '9px 72px 0 72px',
    '&:hover': {
        background: '#ffb43a',
    },
    textTransform: 'capitalize',
    fontWeight: 'bold'
}));


export default class UserPasswordForgot extends UserPasswordForgotController {
    constructor(props: Props) {
        super(props);
    }



    handleforgotpassword = () => {
        return (this.state.forgotPass &&

            <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
                <Item >
                    <div>
                        <Box component={"span"} sx={WebStyle.rightsidetoptxt}>Forgot Password</Box>
                    </div>
                    <Box component={"div"} sx={WebStyle.verfiyheader} >
                        <span >Don't worry! It happens!</span>
                    </Box>
                    <Box component={"div"} sx={WebStyle.digitfourre}>
                        <span >Enter your Registered Email Id below to receive Password Reset instructions.</span>
                    </Box>
                    <Box component={"div"} display='flex' flexDirection='column'>
                        <label style={WebStyle.emailtexte} data-test-id="email-label">Email Id</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputboxe}
                                id="email"
                                type="text"
                                name="email"
                                onBlur={() => this.handleEmailValidate()}
                                placeholder="Enter email id"
                                onChange={(event) => this.handleEmail(event.target.value)}
                                value={this.state.email}
                            />
                        </FormControl>
                        {this.state.emailError ? <span style={WebStyle.formerrorEmail}>{this.state.emailErrorMsg}</span> : null}
                    </Box>
                    <Box component={"div"} mt='94px'>
                        <WhiteBackgroundCheckbox
                            id='checkbox'
                            checked={this.state.isChecked}
                            checkedIcon={<PlanPremiumIcon />}
                            icon={<PlanPremiumIcon2 />}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={this.handleCheckChange}
                            size="small" />
                             <span style={WebStyle.tearm_Condition}>I agree to the &nbsp;<Link to={"TermsAndConditions"} style={WebStyle.condition}>Terms &amp; Conditions&nbsp;</Link ><span style={WebStyle.tearm_Condition}>and</span><Link to={"privacypolicy"} style={WebStyle.condition}>&nbsp;Privacy Policy</Link></span>                       
                    </Box>
                    {this.state.isChecked ? null : (
                        <span style={WebStyle.tearms}>Please accept Terms &amp; Conditions and Privacy Policy.</span>
                    )}
                    <Box component={"div"} mt='0px'>
                        <Loginbtn type='submit' id='login' onClick={this.handleLogIn}>Login</Loginbtn>
                    </Box>
                    <div style={WebStyle.or_div}>
                        <div style={WebStyle.orLeftLine}></div>
                        <span style={WebStyle.or_word}>OR</span>
                        <div style={WebStyle.orRightLine}></div>
                    </div>
                    <Box mt={"15px"}>
                        <span style={WebStyle.AlradyAcces}>Don't have an account? <span style={WebStyle.switchLoginText} onClick={this.handleToSignup}>Sign Up</span></span>
                        {this.state.ToSignUp && <UserSignUp navigation={undefined} id={""} showSignup={undefined} />}
                    </Box>
                </Item>
            </Grid>)
    }

    handleemailverify = () => {
        return (this.state.emailOtpVerify && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item>
                <div style={WebStyle.Otp}>
                    <span>OTP</span>
                </div>
                <Box component={"div"} sx={WebStyle.VerifyyourEmail}>
                    <span>Verify your Email Id</span>
                </Box>
                <Box component={"div"} sx={WebStyle.digitfourrr}>
                    <span >A Verification Code has been sent to {this.state.email}</span>
                </Box>
                <div style={WebStyle.forgot}>
                    <OtpInput
                        value={this.state.forgotOtp ? this.state.forgotOtp : ''}
                        data-test-id="otpInput"
                        onChange={(value) => this.handleForgotOtpValue(value)}
                        inputStyle={{
                            margin: '15px',
                            MozAppearance: 'textfield',
                            width: '42px',
                            borderRadius: '10px',
                            fontSize: '13px',
                            height: '42px',
                            backgroundColor: '#404040',
                            color: 'white',
                            border: 'none',
                            textAlign: 'center'
                        }}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                    />
                </div>
                <Box component={"div"} sx={WebStyle.otpError}>
                    <HandleOtpError minutes={this.state.minutes} seconds={this.state.seconds} otpError={this.state.otpError} />
                </Box>
                <Box component={"div"} mt='158px'>
                    <Box mt={"15px"}>
                        <span style={WebStyle.AlradyAcces}>Didn't receive a Code? <span id="resend" onClick={this.forgotPassword} style={WebStyle.resentText}>Resend OTP</span></span>
                    </Box>
                </Box>
                <Box component={"div"} mt='15px'>
                    <Loginbtn type='submit' onClick={this.handleForgotOTP}>Continue</Loginbtn>
                </Box>
            </Item>
        </Grid>)
    }

    handlereset = () => {
        return (this.state.resetPassword &&
            <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>

                <Item >
                    <div style={WebStyle.resetPassword}>
                        <span>Reset Password</span>
                    </div>
                    <Box component={"div"} sx={WebStyle.newpassword}>
                        <label style={WebStyle.emailtext} data-test-id="email-label">New Password</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                data-test-id="new_password"
                                type={this.state.isShowPassword ? "text" : "password"}
                                placeholder="Enter password"
                                name="new_password"
                                value={this.state.new_password}
                                onBlur={() => this.handlePasswordValidation()}
                                onChange={(event) => this.handlePasswordValue(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">

                                        <IconButton
                                            onClick={this.showPasswordChange}
                                            aria-label="toggle password visibility" edge="end">
                                            <img
                                                src={this.state.isShowPassword ? EyeClose : EyeOpen}
                                                alt="type"
                                                style={WebStyle.IconSize}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {this.state.new_passwordError && <span style={WebStyle.passwordError}>{this.state.new_passwordError}</span>}
                        <label style={WebStyle.emailtext} data-test-id="email-label">Confirm Password</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                data-test-id="confirm-password"
                                type={this.state.isShowConfirmPassword ? "text" : "password"}
                                placeholder="Enter password"
                                name="confirm_password"
                                onBlur={() => this.validateConfirmPassword()}
                                value={this.state.confirm_password}
                                onChange={(event) => this.handleConPasswordValue(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.showConfirmPassword}
                                            aria-label="toggle password visibility" edge="end">
                                            <img
                                                alt="type"
                                                src={this.state.isShowConfirmPassword ? EyeClose : EyeOpen}
                                                style={WebStyle.IconSize}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {<span style={WebStyle.formerror}>{this.state.confirmPasswordError}</span>}
                    </Box>
                    <Box component={"div"} mt='165px'>
                        <Loginbtn type='submit' onClick={this.handleResetPassword}>Continue</Loginbtn>
                    </Box>
                    {this.state.passwordreset && this.SuccessModal()}
                    {this.state.loginModal && <UserLogin navigation={undefined} id={""} ToLogin={undefined} showSignup={undefined} />}
                </Item>

            </Grid>
        )
    }

    SuccessModal = () => {
        return (
            <Dialog open={this.state.passwordreset} PaperProps={{
                style: {
                    borderRadius: '20px'
                }
            }}
            >
                <Box width='400px' p='80px' display='flex' justifyContent='center' alignItems='center' flexDirection='column' bgcolor='#404040'>
                    <div>
                        <img
                            src={lockImage}
                            style={{ width: '80px' }}
                        />
                    </div>
                    <div style={{ margin: "20px 0px", color: 'white', fontSize: '18px', fontFamily: 'Poppins' }}>Password Reset Successfully!</div>
                    <ResetButton id="closeModel" onClick={() => this.handleCloseModal()} variant="contained" fullWidth>
                        Done
                    </ResetButton>
                </Box>
            </Dialog>
        );
    }


    render() {
        return (
            <div>
                <Modal
                    open={this.state.modelOpen}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={WebStyle.modal_style}>
                        <Box display='flex' justifyContent="space-between" >
                            {this.state.carasol &&
                                <Hidden xsDown>
                                    <Grid item md={6} sm={6} style={WebStyle.gridFixCenter}>
                                        <Item>
                                            <div>
                                                <Carousel
                                                    animation="slide"
                                                    IndicatorIcon={<Button></Button>}
                                                    navButtonsAlwaysInvisible={true}
                                                    indicatorIconButtonProps={{
                                                        style: {
                                                            backgroundColor: "#ffb53a",
                                                            margin: "0 10px",
                                                            width: "4px",
                                                            borderRadius: "4px",
                                                            height: "4px",
                                                        }
                                                    }}
                                                    activeIndicatorIconButtonProps={{
                                                        style: {
                                                            borderRadius: "4px",
                                                            height: "4px",
                                                            width: "22px",
                                                            backgroundColor: '#ffb53a',
                                                        }
                                                    }}
                                                >
                                                    {sliderdata.map((data, index) => {
                                                        return (
                                                            <Box
                                                            key={index}
                                                                alignItems='center'
                                                                flexDirection='column'
                                                                display='flex'
                                                                justifyContent='center'
                                                            >
                                                                <div>
                                                                    <img src={data.img} alt="df" style={WebStyle.carousel_img} />
                                                                </div>
                                                                <div>
                                                                    <h3 style={{ color: '#ffffff' }}>{data.title}</h3>
                                                                    <p style={{ minHeight: "46px", color: '#ffffff', wordBreak: 'break-word', maxWidth: '264px' }}>{data.para}</p>
                                                                </div>
                                                            </Box>
                                                        )
                                                    })}
                                                </Carousel>

                                            </div>
                                        </Item>
                                    </Grid>
                                    <hr color='#3e3e3e'></hr>
                                </Hidden>}

                            {this.handleforgotpassword()}

                            {this.handleemailverify()}

                            {this.handlereset()}
                        </Box>
                    </Box>
                </Modal>
            </div>
        );
    }

}

const HandleOtpError = ({ minutes, seconds, otpError }: any) => {

    if (minutes === 0 && seconds === 0) {
        return (
            <div >
                <span id="otpError" style={WebStyle.sizeFont}>OTP expired </span>
            </div>
        )
    } else if (otpError) {
        return (
            <div>
                <span style={WebStyle.sizeFont}>{otpError}</span>
                <div>
                <span style={WebStyle.otpExpire}>OTP expires in {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`} seconds</span>
            </div>
            </div>
        )
    } else {
        return (
            <div>
                <span style={WebStyle.otpExpire}>OTP expires in {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`} seconds</span>
            </div>
        )
    }

}


const WebStyle = {
    switchLoginText: { color: '#f8b43a', fontSize: "12px", textDecoration: 'none', cursor: 'pointer' },
    resentText: { color: '#f8b43a', textDecoration: 'none', fontSize: "12px", cursor: 'pointer' },
    carousel_img: {
        height: "183px",
        width: "198px",
        margin: "0 32px 54px",
    },
    verfiyheader: {
        width: "247px",
        height: "16px",
        margin: "50px 24px 12px 95px",
        fontSize: "18px",
        fontWeight: "600",
        textAlign: "center",
        color: "#fff",
        lineHeight: "1px",
        fontFamily: "Poppins"
    },
    or_word: {
        width: "20px",
        height: "15px",
        color: "#8f9bb3",
        fontSize: "11px",
        padding: "0px 4px",
    },
    or_div: {
        margin: "20px 73px 0 73px",
        display: "flex",
        alignItems: "center"
    },
    formerror: {
        color: "red",
        display: "flex",
        marginTop: "9px",
        fontSize: "12px",
        marginLeft: "57px",
        textAlign: "initial" as const,
        fontFamily: "Poppins"
    },
    passwordError: {
        display: "block",
        maxWidth: "287px",
        color: "red",
        textAlign: "initial" as const, 
        fontSize: "12px",        
        margin: "9px 57px 0",
        fontFamily: "Poppins",
    },
    formerrorEmail: {
        color: "red",
        display: "flex",
        marginTop: "9px",
        fontSize: "10px",
        marginLeft: "75px",
    },
    digitfourrr: {
        width: "247px",
        height: "35px",
        margin: "12px 24px 24px 95px",
        fontSize: "12px",
        textAlign: "center",
        color: "#929292",
    },
    digitfourre: {
        width: "247px",
        height: "35px",
        margin: "12px 24px 0 95px",
        fontSize: "12px",
        textAlign: "center",
        color: "#929292",
    },
    rightsidetoptxt: {
        width: "92px",
        height: "34px",
        margin: "0 88px 25px 90px",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#fff",
    },
    modal_style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "500px",
        width: "800px",
        padding: "68px 0px",
        borderRadius: "12px",
        backgroundColor: "#121212",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '@media (min-width: 320px) and (max-width: 768px)': {
            height: "100vh",
            width: "100vw"
        }
    },
    emailtext: {
        fontSize: "13px",
        display: 'flex',
        margin: "15px 37px 0 52px",
        color: "#ffffff"
    },
    emailtexte: {
        fontSize: "13px",
        display: 'flex',
        margin: "15px 37px 0 74px",
        color: "#ffffff"
    },
    detail: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "50%",
        marginLeft: "27%",
    },

    inputbox: {
        width: "295px",
        height: "42px",
        margin: "10px 54px 0 54px",
        borderRadius: "10px",
        border: "solid 1px #b4b4b4",
        backgroundColor: "#404040",
        fontSize: "12px",
        color: '#ffffff',
    },
    inputboxe: {
        width: "272px",
        height: "42px",
        margin: "10px 75px 0 auto",
        borderRadius: "10px",
        border: "solid 1px #b4b4b4",
        backgroundColor: "#404040",
        fontSize: "12px",
        color: '#ffffff',
    },
    Otpinputbox: {
        backgroundColor: '#626060',
        height: '42px',
        color: '#ffffff',
        width: '42px',
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
    },

    sizeFont: {
        color: 'red',
        fontSize: '12px',
        marginLeft: '90px'
    },

    otpExpire: {
        color: '#ffffff',
        marginTop: '4px',
        marginLeft: '90px'
    },
    gridFixCenter: { display: 'flex', justifyContent: 'center' },
    orLeftLine: { height: '1px', width: '120px', margin: '7px 0 6px 3px', backgroundColor: '#3e3e3e' },
    orRightLine: { margin: '7px 0 6px 3px', width: '120px', height: '1px', backgroundColor: '#3e3e3e' },
    AlradyAcces: { fontSize: "12px", color: '#ffffff' },
    Otp: {
        height: "34px",
        fontFamily: "Poppins",
        fontSize: "24px",
        margin: "0 88px 25px 176px",
        width: "92px",
        fontWeight: 600,
        letterSpacing: "normal",
        fontStyle: "normal",
        color: "#fff",
        lineHeight: "normal",
        fontStretch: "normal",
    },
    VerifyyourEmail: {
        height: "15px",
        fontSize: "18px",
        margin: "24px 24px 12px 95px",
        lineHeight: "1px",
        fontFamily: "Poppins",
        fontWeight: 600,
        color: " #fff",
        width: "247px ",
        fontStretch: "normal",
        letterSpacing: "1px",
        fontStyle: "normal",
        textAlign: "center",
    },
    forgot: { marginTop: '15px', display: 'flex', justifyContent: 'center', gap: '4px' },
    otpError: { width: "393px", textAlign: "start" },
    resetPassword: {
        height: "34px",
        margin: "0 0 40px 22px",
        fontSize: "24px",
        fontFamily: "Poppins",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#fff",
    },
    newpassword: {
        flexDirection: 'column', display: 'flex'
    },
    IconSize: { color: '#f8b43a', height: "13px", width: "17px" },
    tearm_Condition: { color: '#b4b4b4', fontSize: '10px' },
    condition: { color: '#f8b43a', fontSize: '10px' },
    tearms: { color: 'red', display: 'flex', fontSize: '10px', marginLeft: '71px' },
    signUp_Reset: { color: '#f8b43a', textDecoration: 'none', fontSize: "12px", cursor: 'pointer' }
}