import React from "react";
// Customizable Area Start
import { Tooltip } from '@material-ui/core';
import AddFavouritesController, { Props } from "./AddFavouritesController.web";
import { withStyles, Theme as AugmentedTheme, createStyles } from "@material-ui/core/styles";
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";

export const configJSON = require("./config");

// Customizable Area End

export const customStyles = (theme: AugmentedTheme) => createStyles({
  ToolTip: {
      backgroundColor: '#121212',
      fontSize: "8px",
  },
  ToolTipArrow: {
      color: "#121212",
  },
})

export class AddFavourites extends AddFavouritesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <>
        <Tooltip 
          title={this.state.isFavourite ? configJSON.DisLike : configJSON.Like}  
          arrow 
          placement={this.props.addFavourites.tooltipDirection} 
          classes={{  
            tooltip: this.props.classes?.ToolTip, 
            arrow: this.props.classes?.ToolTipArrow 
          }}>
          <img 
            src={this.state.isFavourite ? this.props.addFavourites.filledHeart : this.props.addFavourites.heartIcon} 
            id="heartIcon"
            style={this.props.addFavourites.imageStyle}
            onClick={()=> this.addFavouritesCall(
              this.props.addFavourites?.favouriteable_id ?? "0",
              this.props.addFavourites?.favouriteable_type ?? "")} 
            alt="Heart Icon" 
          />
        </Tooltip>
        {this.state.doSignUp &&  
          <UserSignUp
            navigation={undefined}
            id="testId"
            showSignup={this.closeSignUpModal}
          />}
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(AddFavourites);
// Customizable Area End