import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Modal, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { hamburgerBarIcon } from "../../blocks/splashscreen/src/assets";
import { Link } from 'react-router-dom';
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import { LocalStorageKeys } from "./enums.web";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import { runEngine } from "../../framework/src/RunEngine";
import { callApi } from "./Toolkit";
interface myProps {
  onHandleHamburgerBar: any;
  onHandleSignUpModal: any;
  onHandleWallet: any;
  isKycVerified:boolean;
  onChangeLocation: () => void
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontSize: '24px',
      fontWeight: 600,
      textTransform:"capitalize",
      color: '#fff',
      textOverflow: "ellipsis",
      maxWidth: "150px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      '@media(max-width:700px)': {
        fontSize: "18px",
      }
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '18px',
      fontWeight: 400,
      color: '#fff',
      '@media(max-width:700px)': {
        fontSize: "14px",
      }
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      color: '#fff',
      '@media(max-width:700px)': {
        fontSize: "14px",
      }
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    maxWidth: "362px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    top: 0,
    right: 0,
    backgroundColor: "#121212",
    zIndex: 1,
  },
  contentContainer: {
    padding: "16px 13px 16px 15px",
    cursor: "pointer",
  },
  upperContainer: {
    backgroundColor: "#404040",
    height: "84px",
    padding: "13px 15px 13px 17px",
    color: "#ffffff"
  },
  seperateLine: {
    borderBottom: "solid 1px #404040",
  },
  iconStyle: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
  },
  crossIconStyle:{
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  headingIconStyle: {
    width: "56px",
    height: "56px",
    borderRadius:"50%"
  },
  arrowIconStyle: {
    width: "15px",
    height: "24px",
    margin: "4px 14px 6.7px 71px"
  },
  listContainer: {
    flex: 1,
    overflowY: "scroll",
    paddingTop: "18px",
  },
  NotificationContainer: {
    height: "calc(100vh - 84px)",
    overflowY: "auto",
  },
  arrowheaderIconStyle: {
    width: 8,
    height: 12,
    marginLeft: 5
  },
  displayflex: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  MenuItemLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  MaxWidthForIcons: {
    maxWidth: "48px",
    position: "relative"
  },
  UnreadCountWrapper: {
    position: "absolute",
    top: 0,
    right: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "16px",
    borderRadius: "15px",
    backgroundColor: "#ffb43a",
  },
  UnreadNotificationCount: {
    fontWeight: 600,
    fontSize: "10px",
    fontFamily: "Poppins, sans-serif",
    color: "#000",
    lineHeight: 0,
    marginTop: "1px"
  }
}));

enum MenuItem {
  Notification = "Notification",
  Home = "Home",
  Vogue = "Vogue",
  Wallet = "Wallet",
  BookingHistory = "Booking History",
  ITOHistory = 'ITO History',
  Request = 'Request',
  TransactionHistory = "Transaction History",
  ListYourShow = 'List Your Show',
  PaymentSettings = 'Payment Settings',
  DiscountAndOffers = 'Discount & Offers',
  ReferAndEarn = "Refer and Earn",
  Favourite = "Favourites",
  ContactUs = "Contact Us",
  ChangeLocation = "Change Location",
  Logout = "Logout",
  signUp = "Sign Up"
}

type MenuList = {
  icon: string,
  heading: MenuItem,
  navigateTo?: string
}

export const menuWithoutLogin: MenuList[] = [
  {
    icon: hamburgerBarIcon.logout,
    heading: MenuItem.signUp,
  },
  {
    icon: hamburgerBarIcon.homeIcon,
    heading: MenuItem.Home,
    navigateTo: "/",
  },
  {
    icon: hamburgerBarIcon.vogueIcon,
    heading: MenuItem.Vogue,
    navigateTo: "/voguelist",
  },
  {
    icon: hamburgerBarIcon.changeLocation,
    heading: MenuItem.ChangeLocation,
  },
  {
    icon: hamburgerBarIcon.discountAndSetting,
    heading: MenuItem.DiscountAndOffers,
    navigateTo: "",
  },
  {
    icon: hamburgerBarIcon.contactUs,
    heading: MenuItem.ContactUs,
    navigateTo : "/contactus",
  },
]

export const menuItems: MenuList[] = [
  {
    icon: hamburgerBarIcon.Notification,
    heading: MenuItem.Notification,
    navigateTo: "",
  },
  {
    icon: hamburgerBarIcon.homeIcon,
    heading: MenuItem.Home,
    navigateTo: "/",
  },
  {
    icon: hamburgerBarIcon.vogueIcon,
    heading: MenuItem.Vogue,
    navigateTo: "/voguelist",
  },
  {
    icon: hamburgerBarIcon.wallet,
    heading: MenuItem.Wallet,
    navigateTo: "/Cfwallet19",
  },
  {
    icon: hamburgerBarIcon.bookingHistory,
    heading: MenuItem.BookingHistory,
    navigateTo: "/bookinghistory",
  },
  {
    icon: hamburgerBarIcon.itoHistory,
    heading: MenuItem.ITOHistory,
    navigateTo: "/itobookinghistory",
  },
  {
    icon: hamburgerBarIcon.request,
    heading: MenuItem.Request,
    navigateTo: "/request",
  },
  {
    icon: hamburgerBarIcon.TransactionHistory,
    heading: MenuItem.TransactionHistory,
    navigateTo: "/TransactionHistory",
  },
  {
    icon: hamburgerBarIcon.listYourShow,
    heading: MenuItem.ListYourShow,
    navigateTo: "/addEventDetail",
  },
  {
    icon: hamburgerBarIcon.discountAndSetting,
    heading: MenuItem.DiscountAndOffers,
    navigateTo: "",
  },
  {
    icon: hamburgerBarIcon.referAndEarn,
    heading: MenuItem.ReferAndEarn,
    navigateTo: "/Referrals",
  },
  {
    icon: hamburgerBarIcon.favourite,
    heading: MenuItem.Favourite,
    navigateTo: "/favourites",
  },
  {
    icon: hamburgerBarIcon.contactUs,
    heading: MenuItem.ContactUs,
    navigateTo: "/contactus",
  },
  {
    icon: hamburgerBarIcon.changeLocation,
    heading: MenuItem.ChangeLocation,
  },
  {
    icon: hamburgerBarIcon.logout,
    heading: MenuItem.Logout,
  },
]

export default function LoginHamburgerBar(props: myProps) {

  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [unReadNotificationCount, setUnReadNotificationCount] = useState<number>(0)

  const classes = useStyles();
  const { onChangeLocation, onHandleHamburgerBar, isKycVerified } = props

  let showMenu = localStorage.getItem("login") ? menuItems : menuWithoutLogin

  const handleLogout = async () => {

    const userToken = (await getStorageData(LocalStorageKeys.LoginToken)) as string
    const fcmToken = (await getStorageData(LocalStorageKeys.FCMToken)) as string

    if(userToken && fcmToken) {
      callApi({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `/account_block/device_ids/delete_device_id?device_token=${fcmToken}`,
        headers: { "token": userToken },
      }, runEngine)
    }

    localStorage.removeItem('login');
    localStorage.removeItem('userName');
    localStorage.removeItem('profilePic');
    removeStorageData(LocalStorageKeys.Location);
    removeStorageData(LocalStorageKeys.UserDetails);
    removeStorageData(LocalStorageKeys.PaymentContactInfo);
    removeStorageData(LocalStorageKeys.FCMToken);
    props.onHandleHamburgerBar();
    window.location.href = "/";
  }

  const handleChangeLocation = () => {
    onHandleHamburgerBar()
    onChangeLocation()
  }

  const handleMenuItemClick = (menuItem: MenuItem) => {
    switch (menuItem) {
      case MenuItem.ChangeLocation:
        handleChangeLocation()
        break;
      case MenuItem.signUp:
        props.onHandleSignUpModal()
        break;
      case MenuItem.Logout:
        handleLogout()
        break;
      case MenuItem.Wallet: 
        props.onHandleWallet();
        break;
      case MenuItem.Notification:
        setIsNotificationOpen(true)
        break;
      default:
        break;
    }
  }

  const isMobile = window.outerWidth < 600
  showMenu = isMobile ? showMenu : showMenu.filter(menu => ![MenuItem.Home, MenuItem.Vogue].includes(menu.heading));

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.root}>
          <Grid className={classes.upperContainer} container alignItems="center">
            <Grid item xs={3}>
              <img
                src={localStorage.getItem("profilePic") ?? hamburgerBarIcon.addpic}
                className={classes.headingIconStyle}
                alt='addPic'

              /></Grid>
            <Grid item xs={7}>
              <Typography variant="h1">Hi, 
              {!localStorage.getItem("login")
                ? " Guest" 
                : localStorage.getItem("userName")
                ? localStorage.getItem("userName") 
                  : " User"

              }
              !</Typography>
              {localStorage.getItem("login") && <Link to="/edituserprofile" className={classes.displayflex}>
                <Typography variant="h3">Edit Profile</Typography>
                <img
                  src={hamburgerBarIcon.next}
                  className={classes.arrowheaderIconStyle}
                  alt='arrow'
                />
              </Link>}
            </Grid>
            <Grid item xs={2}>
              <img
                src={hamburgerBarIcon.cancel}
                className={classes.crossIconStyle}
                alt='cancel'
                onClick={props.onHandleHamburgerBar}
              />
            </Grid>
          </Grid>


          <Notifications 
            navigation={undefined} 
            id="notifications" 
            isNotificationOpen={isNotificationOpen} 
            setIsNotificationOpen={setIsNotificationOpen}
            setUnReadNotificationCount={setUnReadNotificationCount}
          />
          {!isNotificationOpen && (
            <Box className={classes.listContainer}>
              {showMenu.map((elm: any) => {

                return (
                  <>
                    {elm.heading == "Change Location"
                      || elm.heading == "Logout"
                      || elm.heading == "Sign Up"
                      || (elm.heading == "Wallet" && !isKycVerified)
                      || elm.heading == "Notification"
                      ? <Grid
                        onClick={() => handleMenuItemClick(elm.heading)}
                        className={classes.contentContainer} container alignItems="center">

                        <Grid item xs={2} className={classes.MaxWidthForIcons}>
                          <img
                            src={elm.icon}
                            className={classes.iconStyle}
                          />
                          {
                            elm.heading == "Notification" && !!unReadNotificationCount && (
                              <Box className={classes.UnreadCountWrapper}>
                                <span className={classes.UnreadNotificationCount}>{unReadNotificationCount}</span>
                              </Box>
                            )
                          }
                        </Grid>
                        <Grid item xs={10} className={classes.MenuItemLabel}>
                          <Typography display="inline" variant="h2">{elm.heading}</Typography>
                          <img
                            src={hamburgerBarIcon.next}
                            className={classes.arrowIconStyle}
                            alt='arrow'
                          />
                        </Grid>
                      </Grid>
                      : <Link to={elm.navigateTo}>
                        <Grid
                          className={classes.contentContainer} container alignItems="center">
                          <Grid item xs={2} className={classes.MaxWidthForIcons}>
                            <img
                              src={elm.icon}
                              className={classes.iconStyle}
                            />
                          </Grid>
                          <Grid item xs={10} className={classes.MenuItemLabel}>
                            <Typography display="inline" variant="h2">{elm.heading}</Typography>
                            <img
                              src={hamburgerBarIcon.next}
                              className={classes.arrowIconStyle}
                              alt='arrow'
                            />
                          </Grid>
                        </Grid>
                      </Link>}
                    {elm.heading !== "Logout" &&
                      <div className={classes.seperateLine}>
                      </div>}
                  </>
                )
              })}
            </Box>
          )
          }
          
        </div>
      </Modal>
    </ThemeProvider>
  )
}
