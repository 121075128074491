import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { IBookingResponse } from "../../../components/src/interfaces.web";
import { getLastPartOfURL } from "../../../components/src/utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  booking: IBookingResponse
  isDataLoaded: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ElectronicTicketController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      booking: {} as IBookingResponse,
      isDataLoaded: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.bookingDetailsApi) {
        this.setState({ booking: resJson.data as IBookingResponse, isDataLoaded: true })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  bookingDetailsApi: string = ''

  async componentDidMount() {
    const bookingID = getLastPartOfURL();

    this.bookingDetailsApi = callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiGetMethodType,
      endPoint: `bx_block_categories/bookings/{BOOKING_ID}/show_booking`.replace("{BOOKING_ID}", `${bookingID}`),
      headers: { "token": configJSON.guestToken },
    }, runEngine)
  }
  // Customizable Area End
}
