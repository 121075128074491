import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { GalleryItem, InvestorApiResponse } from "../../../components/src/interfaces.web";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDataLoading: boolean;
  investorDetails: GalleryItem[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutInvestorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getInvInfoApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      investorDetails: [],
      isDataLoading: true      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

  // ---------------------  Team Info response start -----------------------
    if (this.getInvInfoApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse: InvestorApiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiResponse) {
        this.setState({ investorDetails: apiResponse.data, isDataLoading: false })
      }

    }

    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0)
    this.getInvDetails()
 // Customizable Area End
  }

  // Customizable Area Start

        ///Team info api request start///
        getInvDetails = () => {

          const header = {
            "Content-Type": configJSON.contentTypeApi,
            };
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getInvInfoApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.investorInfoApiCallUrl
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
         }
    
         ///team details api request end///
      handleBackBtn=()=>{
        this.props.navigation.navigate('AboutUs')
      }
  // Customizable Area End
}
