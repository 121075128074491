import React from 'react'
import {
    Box,
    Typography,
    // Customizable Area Start
    ThemeProvider,
    createTheme,
    Container,
    Hidden,
    // Customizable Area End
} from "@material-ui/core";
import { styled, withStyles } from '@material-ui/core/styles';
import { getStorageData } from '../../framework/src/Utilities';
import { SeatStatus } from './enums.web';
import { SingleSeat } from './Seat.web';

const backArrow = require("../../blocks/cfwallet19/assets/backarrow.png")

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        text: {
            primary: "#FFF"
        }
    },
    typography: {
        fontFamily: "Poppins, Roboto, sans-serif",
        subtitle1: {
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: 1.5,
            "@media (max-width: 600px)": {
                fontSize: "16px"
            }
        },
        subtitle2: {
            fontSize: "14px",
            lineHeight: 1.25,
            "@media (max-width: 600px)": {
                fontSize: "12px"
            }
        },
        body1: {
            fontSize: "14px",
            fontWeight: 500,
        }
    },
});

const RFHBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    "@media (min-width: 1000px)": {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
    }
})
interface Props {
    classes: any;
    seatType: boolean;
    navigation: any;
}
interface S {
    // Customizable Area Start
    movieName: any;
    screen: {
        screenId: number;
        theaterName: string;
    },
    // Customizable Area End

}
class Header extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props)
        this.state = {
            movieName: '',
            screen: {
                screenId: 0,
                theaterName: ""
            }
        }

    }

    componentDidMount(): void {
        this.getDataFromLocal()
    }
    getDataFromLocal = async () => {
        let screen: any = {};
        if (typeof window !== "undefined") {
            screen = await JSON.parse(await getStorageData('selectedTimeSlotDetail', false))
        }
        const screenName = screen?.selectedScreenDetail?.screenDetail
        this.setState({ screen: await screenName })

        this.setState({ movieName: screen?.movieName?.name })
    }
    handleBack = () => {
        this.props.navigation.goBack();
    }
    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>

                <Box className={classes.Header}>
                    <Container>
                        <RFHBox>

                            <Box display="flex" alignItems="center" gridGap="18px">

                                <img src={backArrow} className={classes.BackButton} alt="back-arrow" onClick={this.handleBack} />

                                <Box>
                                    <Typography variant="subtitle1">{this.state.movieName}</Typography>
                                    <Typography variant="subtitle2">{this.state.screen?.theaterName}</Typography>
                                </Box>

                            </Box>

                            {this.props.seatType && (
                                <Hidden xsDown>
                                <Box display="flex" alignItems="center" gridGap="16px" flexWrap="wrap">

                                    <Box display="flex" alignItems="center" gridGap="8px">
                                        <SingleSeat status={SeatStatus.Selected} />
                                        <Typography display="inline" variant="body1">Selected</Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center" gridGap="8px">
                                        <SingleSeat status={SeatStatus.Request} />
                                        <Typography display="inline" variant="body1">Request</Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center" gridGap="8px">
                                        <SingleSeat status={SeatStatus.OnlyShowAvailable} />
                                        <Typography display="inline" variant="body1">Available</Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center" gridGap="8px">
                                        <SingleSeat status={SeatStatus.Unavailable} />
                                        <Typography display="inline" variant="body1">Unavailable</Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center" gridGap="8px">
                                        <SingleSeat status={SeatStatus.Buy} />
                                        <Typography display="inline" variant="body1">Buy</Typography>
                                    </Box>



                                </Box>
                                </Hidden>
                            )}

                        </RFHBox>
                    </Container>
                </Box>

                {/* HEADER END */}
            </ThemeProvider>
        )
    }
}

const styles = (theme: any) => ({
    Header: {
        backgroundColor: "#40404040",
        paddingTop: "16px",
        paddingBottom: "16px",
        "@media (max-width: 600px)": {
            paddingTop: "12px",
            paddingBottom: "12px",
        }
    },
    ContainerWithoutPadding: {
        paddingLeft: 0,
        paddingRight: 0
    },
    mainContainer: {
        backgroundColor: "#252525",
    },
    mainPaperStyle: {
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column' as const,
        backgroundColor: "#121212"
    },
    BackButton: {
        cursor: "pointer",
        width: "36px",
        height: "36px",
        "@media (max-width: 600px)": {
            width: "24px",
            height: "24px",
        }
    },
    header: {
        maxWidth: '1475px',
        width: '100%',
        height: '60px',
        padding: '18px 978px 14px 100px',
        display: "flex-root",
        // backgroundColor: '#252525',
        [theme.breakpoints.down("sm")]: {
            padding: "21px 0px 0px 4px"
        },
        [theme.breakpoints.up("md")]: {
            padding: "11px 5px 5px 5px",
            margin: "12px"
        }
    },
    typo: {
        color: 'white',
        fontFamily: 'Poppins',
        top: '20px',
        marginLeft: '70px',
        fontSize: '22px',
    },
    typoTheater: {
        color: 'white',
        fontFamily: 'Poppins',
        marginLeft: '70px',
        fontSize: '16px',
    },
    SocialLogo: {
        width: "40px",
        height: "40px",
        position: 'absolute' as const,
        marginTop: '30px',
        marginLeft: '100px',
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "25px"
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: "35px"
        }
    },
    bookingSpan: {
        color: 'white',
        fontFamily: 'Poppins',
        marginLeft: '32px',
        margin: "8px 0 3px 8px",
        fontSize: '16px',
        display: "inline-flex"
    },
    seat: {
        display: "inline-block",
        marginTop: "1rem auto",
        width: "24px",
        height: "24px",
        transition: " transform 0.3s ease-in-out",
        position: "relative" as "relative",
        top: "2px",
        marginRight: "5px",
        borderRadius: "2px"
    },
    seatSelected: {
        background: "#f6b438"
    },
    seatRequested: {
        background: "#60aa27"
    },
    seatAvailable: {
        border: "1px solid #ffb43a ",
    },
    seatUnavailable: {
        background: "#404040"
    },
    seatBuy: {
        background: "#e84e4e"
    }

})
export default withStyles(styles)(Header)
