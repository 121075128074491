export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const MovieImage = require("../assets/poster.png");
export const facebook = require("../assets/facebook.png");
export const instagram = require("../assets/insta.png");
export const twitter = require("../assets/twitter.png");
export const Linkedin = require("../assets/linkedin.png");
export const logo = require("../assets/logo.png");
export const heartSelected = require("../assets/heartSelected.png");
export const dropdownicon = require("../assets/dropdown.png");
export const dropdownicon1 = require("../assets/dropdown1.png");
export const heartNotSelected = require("../assets/heartNotSelected.png");
export const backarrowleft = require("../assets/backarrowleft.png");
export const backarrowright = require("../assets/backarrowright.png");

export const resetImg = require("../assets/reset.png");
export const check = require("../assets/check.svg");
export const checkboxicon = require("../assets/checkboxicon.svg");

export const starFilled = require("../assets/starFilled.png");
export const starOutline = require("../assets/starOutline.png");
export const whiteHeart = require("../assets/whiteHeart.png");
export const filled_heart = require("../assets/filledheart.png");
export const btnPlay = require("../assets/btnPlay.png");
export const logoSmall = require("../assets/logoSmall.png");
export const thumbsUp = require("../assets/thumbsUp.png");
export const thumbsUpFilled = require("../assets/thumbsUpFilled.png");
export const thumbsDown = require("../assets/thumbsDown.png");
export const imgShare = require("../assets/imgShare.png");
export const close = require("../assets/close.png");
export const playButton = require("../assets/play.png");
export const sreenImage = require("../assets/auditorium.png")
export const QRCode = require("../assets/QR_Booking_Successful.jpg");

export const SeatIcon = require("../assets/seat.png");
export const OfferIcon = require("../assets/offers.png");
export const TicketStar = require("../assets/TicketStar.png");

export const MoviePoster = require("../assets/Rocketry_The_Nambi_Effect.jpg");
export const closeIcon = require("../assets/X-Mark.png");
export const editIcon = require("../assets/editicon.png")
export const Wimage =  require("../../cfwallet19/assets/Wallet.png")
export const image =  require("../../cfwallet19/assets/dropdown.png")
export const alertImage =  require("../assets/image_Bitmap.png")
export const LocationIcon = require("../assets/icon-location.png")
export const QRcode = require("../assets/QR_Booking_Successful.jpg")
