import React from "react";

import {
	// Customizable Area Start
	Typography,
	Box,
	Grid,
	Paper,
	Container,
	Divider,
	Fade,
	CircularProgress,
	Hidden,
	Button
	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";


export const configJSON = require("./config");

const theme = createTheme({
	palette: {
		primary: {
			main: "#ffb43a",
		},
		background: {
			paper: "#121212",
			default: "#404040",
		},
		text: {
			primary: "#FFF",
		},
	},
	typography: {
		fontFamily: "Poppins",
		h5: {
			fontWeight: 500,
			fontSize: "20px",
			"@media (max-width: 600px)": {
				fontSize: "16px"
			}
		},
		h6: {
			fontSize: "20px",
			fontWeight: 500,
			"@media (max-width: 600px)": {
				fontSize: "14px",
			}
		},
		subtitle1: {
			margin: "20px 0px",
		},
		caption: {
			fontSize: "14px",
		},
		body1:{
			fontSize: "16px",
			"@media (max-width: 600px)": {
				fontSize: "12px"
			}
		},
	},
});
// Customizable Area End

import FoodAndBeveragesController, {
	Props,
} from "./FoodAndBeveragesController.web";
import FilterList from "../../../components/src/FilterList.web";
import MenuListItem from "../../../components/src/MenuListItem.web";
import HeaderWeb from "../../../components/src/Header.web";
import PreviewBookingWeb from "./PreviewBooking.web";



export default class FoodAndBeverages extends FoodAndBeveragesController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End

	render() {
		return (
			// Customizable Area Start
			<ThemeProvider theme={theme}>

			<BoxWrapper>

				<Paper className="paper" elevation={0}>

					<HeaderWeb seatType={false} navigation={this.props.navigation} />

					<Container className="container__wrapper">
						<Grid container spacing={2}>

							<Grid item xs={12} md={7}>
								<Box className="title__wrapper">
										<Typography variant="h5">{configJSON.foodAndBeverages}</Typography>
										<Typography variant="caption" className="skip__button" onClick={this.navigateToPaymentScreen}>
											{configJSON.skip}
											<ChevronRight className="skip__icon" /></Typography>
								</Box>
								<Box display="flex">
									<FilterList selectedFilter={this.state.activeFilter} handleOnClick={this.onFilterChange} />
								</Box>
								<Divider className="divider" />

								<Grid container data-test-id="menu-list-container">

									{this.state.isLoading ? (
										<Grid item xs={12} justifyContent="center">
											<Box p={2} display="flex" justifyContent="center">
												<Fade in={this.state.isLoading} unmountOnExit>
													<CircularProgress />
												</Fade>
											</Box>
										</Grid>
									) : (
										<MenuListItem
											list={this.state.filteredMenuList}
											onClickItem={this.onAddItem}
										/>
									)}

								</Grid>
							</Grid>
							<Hidden smDown>
									<Grid item xs={false} md={5} style={webStyles.MinHeight}>

										<PreviewBookingWeb data-test-id="preview-booking-sidebar" handlePayment={this.navigateToPaymentScreen} id="preview-booking" navigation={this.props.navigation} isUpdated={this.addItemApiCallId} />

									</Grid>
							</Hidden>

						</Grid>
					</Container>
				</Paper>

					{
						this.state.totalItemsAddedInCart > 0 && (
							<Box className="mobile__footer">
								<Typography className="items__text">{`${this.state.totalItemsAddedInCart} ${this.state.totalItemsAddedInCart > 1 ? configJSON.itemsSelected : configJSON.itemSelected}`}</Typography>
								<Button className="proceed__btn" onClick={this.navigateToPaymentScreen}>
									{configJSON.Proceed}
								</Button>
							</Box>
						)
					}

				</BoxWrapper>
			</ThemeProvider>
			// Customizable Area End
		);
	}
}

// Customizable Area Start

const webStyles = {
	MinHeight: {
		minHeight: "100vh",
	},
	Title: {
		fontWeight: 500,
	}
}

const BoxWrapper = styled(Box)({
	position: "relative",
	"& .paper": {
		paddingBottom: "50px",
		minHeight: "100vh"
	},
	"& .container__wrapper": {
		paddingTop: "20px",
		"& .title__wrapper": {
			display: "flex",
			"& .skip__button": {
				display: "none",
				cursor: "pointer",
				color: "#ffb43a",
				marginLeft: "auto",
				alignItems: "center",
				"@media (max-width: 600px)": {
					display: "flex",
				}
			}
		}
	},
	"& .divider":{
		height: "1px",
		backgroundColor: "#2f2617",
	},
	"& .mobile__footer": {
		display: "none",
		position: "fixed",
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "#121212",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "8px 16px",
		"& .items__text": {
			color: "#FFF",
			fontSize: "16px",
			fontWeight: 600
		},
		"& .proceed__btn": {
			color: "#000",
			backgroundColor: "#ffb43a",
			padding: "12px 54px",
			maxHeight: "48px",
			borderRadius: "12px",
			fontSize: "14px",
			fontWeight: 600,
			textTransform: "capitalize",
			"&:hover": {
				backgroundColor: "#ffb43a",
				color: "#000"
			}
		}
	},
	"@media (max-width: 600px)": {
		"& .container__wrapper": {
			paddingTop: "16px !important",
		},
		"& .mobile__footer": {
			display: "flex"
		}
	}
})
// Customizable Area End
