import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";

interface IAdItem {
  id: string;
  type: string,
  attributes: {
    title: string;
    banner_type: string;
    created_at: string;
    updated_at: string;
    content: string
  }
};

export interface IAdsDataResponse {
  data: IAdItem[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  adsData: IAdItem[];
  userToken: string;
  location: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomadvertisementsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  adsListApiId = ''
  _isMounted = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      adsData: [],
      userToken: '',
      location: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && resJson && 
        resJson?.errors === undefined && 
        apiRequestCallId === this.adsListApiId &&
        this._isMounted) {
          this.setState({adsData: resJson.data});
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await super.componentDidMount();
    this._isMounted = true;
    this.getAdsList();
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getAdsList);
  };



  async componentWillUnmount() {
    await super.componentWillUnmount();
    this._isMounted = false;
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getAdsList);
  };

  getAdsList = async () => {
    const userToken = await getStorageData(LocalStorageKeys.LoginToken) as string;
    const location = await getStorageData(LocalStorageKeys.Location) as string;

    if(this._isMounted) {
      this.setState((prevState) => ({ 
      ...prevState,
      userToken: userToken || "guest_user" ,
      location
    }));

    this.adsListApiId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.customAdsListEndpoint}&city_name=${this.state.location}`,
      headers: { "token": this.state.userToken },
      }, runEngine);
    }
  }
  // Customizable Area End
}