import React from 'react'
import {
    Box,
    Typography,
    // Customizable Area Start
    ThemeProvider,
    createTheme,
    Container,
    // Customizable Area End
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { getStorageData } from '../../framework/src/Utilities';
import { LocalStorageKeys } from './enums.web';

const backArrow = require("../../blocks/cfwallet19/assets/backarrow.png")

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
    },
    typography: {
        fontFamily: "Poppins, Roboto, sans-serif",
        h2:{
            fontSize:22,
            fontWeight:600,
            '@media(max-width:600px)': {
                fontSize:18,
            },
        },
        h4:{
            fontSize:16,
            fontWeight:"normal",
            '@media(max-width:600px)': {
                fontSize:12,
            },
        },
        
    },
});

interface Props {
    classes: any;
    navigation: any;
    hideLocation?: boolean
}
interface S {
    // Customizable Area Start
    eventName:string,
    address:string
    // Customizable Area End

}
class EventHeader extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props)
        this.state = {
            eventName: "",
            address: ""
        }
    }

    componentDidMount(): void {
        this.toGetDataFromLocal()
    }

    toGetDataFromLocal = async () => {
        const data = await getStorageData(LocalStorageKeys.EventBookingData, true);
        this.setState({ eventName: data.name, address: data.Address })
    }

    handleBack = () => {
        this.props.navigation.goBack();
    }

    render() {
        const { classes, hideLocation = false } = this.props;
        return (
            <ThemeProvider theme={theme}>
               <Box className={classes.paperAppBarStyle}>
        <Container className={classes.headerMain}>
            <img src={backArrow} alt="back" 
                className={classes.backImageSize}
                data-test-id="back"
                onClick={()=> this.handleBack()}
            />
            <Box>
                <Typography variant="h2">{this.state.eventName}</Typography>
                {!hideLocation && <Typography variant="h4">{this.state.address}</Typography>}
            </Box>
        </Container>
    </Box>
                {/* HEADER END */}
            </ThemeProvider>
        )
    }
}

const styles = (theme: any) => ({
    backImageSize: {
        height: 40,
        width: 40,
        marginRight: 10,
        cursor:"pointer",
        '@media(max-width:600px)': {
            height: 24,
            width: 24,
        },
    },
    paperAppBarStyle: {
        height: '90px',
        backgroundColor: '#252525',
        backgroundBlendMode: 'normal',
        padding: '18px 0',
        display: 'flex',
        width:"100vw",
        boxSizing:"border-box" as const
    },
    headerMain: {
        display: "flex",
        alignItems: "center"
    },
})
export default withStyles(styles)(EventHeader)
