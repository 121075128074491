import React from 'react'
import { ISeat } from './interfaces.web'
import { Box, Tooltip, makeStyles } from '@material-ui/core'
import { SeatStatus } from './enums.web'

const useStyles = makeStyles({
  Seat: {
    width: "28px",
    height: "28px",
    borderRadius: "3.2px",
    border: "none",
    boxSizing: 'border-box',
    backgroundColor: "transparent",
    transition: "all 0.1s ease-in-out",
    "&.selected": {
      backgroundColor: "#ffb43a",
      cursor: "pointer",
    },
    "&.available": {
      border: "solid 1px #ffb43a",
      backgroundColor: "transparent",
    },
    "&.onlyShowAvailable": {
      border: "solid 1px #ffb43a",
      backgroundColor: "transparent",
    },
    "&.unavailable": {
      backgroundColor: "#404040",
    },
    "&.request": {
      backgroundColor: "#50a925",
    },
    "&.buy": {
      backgroundColor: "#e94e4e",
    },
    "&.hovered:hover": {
      "&.buy": {
        backgroundColor: "#ffb43a",
        cursor: "pointer",
      },
      "&.request": {
        backgroundColor: "#ffb43a",
        cursor: "pointer",
      },
      "&.available": {
        backgroundColor: "#ffb43a",
        cursor: "pointer",
      }
    }
  },
  RupeesIcon: {
    fontFamily: "Roboto, sans-serif"
  }
})

interface ISeatProps {
  seat: ISeat,
  onClick?: (id: number, status: SeatStatus, seatSectionType: string) => void
  hoverEnable?: boolean
  isSelected?: boolean
  isTooltipEnable?: boolean
}

interface ISingleSeatProps {
  status: SeatStatus
}

const Seat: React.FC<ISeatProps> = ({
  seat,
  onClick,
  hoverEnable = true,
  isSelected = false,
  isTooltipEnable = false
}) => {

  const classes = useStyles()
  const { status, id, seatSectionType } = seat

  const handleOnClick = () => {
    if (onClick) onClick(id, status, seatSectionType)
    else return
  }
  
  return isTooltipEnable && seat.status === SeatStatus.Buy ? (
    <Tooltip 
      title={<><span className={classes.RupeesIcon}>₹&nbsp;</span>{seat.seatPrice}</>} 
      key={seat.id} 
      placement='top'
    >
      <Box
        className={`${classes.Seat} ${isSelected ? "selected" : status} ${hoverEnable ? "hovered" : ""}`}
        onClick={handleOnClick}
      >
      </Box>
    </Tooltip>
  ) : (
    <Box
      className={`${classes.Seat} ${isSelected ? "selected" : status} ${hoverEnable ? "hovered" : ""}`}
      onClick={handleOnClick}
    >
    </Box>
  )
}

export const SingleSeat: React.FC<ISingleSeatProps> = ({ status }) => {

  const classes = useStyles()

  return (
    <Box className={`${classes.Seat} ${status}`}></Box>
  )
}

export default Seat