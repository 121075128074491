import React from 'react'
import { Box, Divider, Typography, makeStyles } from '@material-ui/core'
import { convertToPad, getFormattedDate } from './utilities'
import { ticketStar } from '../../blocks/ordermanagement/src/assets'
import { SeatIcon } from '../../blocks/reservations2/src/assets'
import { Seat } from './BookingSuccessfulTicket.web'

interface IITOMovieEventTicketsInfoProps {
    applicationData : string | undefined, 
    screeningData : string, 
    ticketRequested :number , 
    ticketSold : string,
    ticketListedToSell : string [],
    bookingType : string,
    isListToSell : boolean,
    tier : string,
    ticketAlloted : string [],
    isInvalid:boolean
}


const useStyles = makeStyles(({
    DisplayFlex: {
        display: "flex",
        justifyContent: "space-between"
    },
    ScreenLabel: {
        fontSize: "18px",
        color: "#fff",
        fontFamily: "Poppins",
        textTransform: "capitalize" as const,
        marginBottom: 4
    },
    IconWrapper: {
        display: "flex",
        gridGap: '15px'
    },
    IconWrappers: {
        width: "18px",
        height: "22px"
    },
    Divider: {
        height: 0,
        boxSizing: 'border-box' as const,
        borderBottom: "dotted 1.5px #f0f6f7",
        margin: "20px 0",
        opacity: '0.6'
    },
    TicketIcon: {
        width: "24px",
        height: "22px",
    },
    tireFont: {
        textTransform: 'capitalize',
        fontFamily: "Poppins",
        textAlign: 'center'
    }

}))

const IITOMovieEventTicketsInfo: React.FC<IITOMovieEventTicketsInfoProps> = (props) => {

    const classes = useStyles()
    const { 
        applicationData, 
        screeningData, 
        ticketRequested, 
        ticketSold,
        ticketListedToSell,
        bookingType,
        isListToSell,
        tier,
        ticketAlloted,
        isInvalid,

    } = props;

    return (
        <Box 
        data-test-id='ITO_Movie_event'
        style={{  
            gridGap: '25px', 
            display: 'flex',
        }}>
        <Box style={{ width: '45%' }}>
            <Box>
                <Divider className={classes.Divider} />
            </Box>
                {bookingType === "ito"
                    ? <Box className={classes.DisplayFlex} mt={0.5}>
                        <span className={classes.ScreenLabel}>Application Date :</span>
                        <Typography className={classes.ScreenLabel}>{applicationData && getFormattedDate(applicationData, "DD MMM, YYYY")}</Typography>
                    </Box>
                    : <Box className={classes.IconWrapper}>
                        <Box className={classes.IconWrappers}>
                            <img
                                src={ticketStar}
                                className={classes.TicketIcon}
                                alt="Seat"
                            />
                        </Box>
                        <Typography className={classes.tireFont}>{tier}</Typography>
                    </Box>
                }

            <Box >
                <Divider className={classes.Divider} />
            </Box>
        </Box>
        <Box style={{ width: '45%' }}>
            <Box>
                <Divider className={classes.Divider} />
            </Box>

                {bookingType === "ito"
                    ? <>
                    <Box className={classes.DisplayFlex} mt={0.5}>
                            <span className={classes.ScreenLabel}>Screening Date :</span>
                            <Typography className={classes.ScreenLabel}>{getFormattedDate(screeningData, "DD MMM, YYYY")}</Typography>
                        </Box>
                    </>
                : <Box className={classes.IconWrapper}>
                    <Box className={classes.IconWrappers}>
                        <img src={SeatIcon} alt="Seat" className={classes.TicketIcon} />
                    </Box>
                    <Typography className={classes.tireFont}>No of Seats {convertToPad(ticketRequested)}</Typography>
                </Box>
            }

            <Box >
                <Divider className={classes.Divider} />
            </Box>
        </Box>
    </Box>
    )
}

export default IITOMovieEventTicketsInfo