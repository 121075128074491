Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";
exports.favouritesApiApiContentType = "application/json";
exports.favouritesApiEndPoint = "favourites/favourites";
exports.favouritesListApiEndPoint = "favourites/favourites?favouriteable_type=";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorType = "Please enter a type";
exports.configErrorId = "Please enter a valid id";
exports.emptyMovie = "Your Favourite movies will be added here.";
exports.emptyEvent = "Your Favourite Events will be added here.";
exports.emptyVogue = "Your Favourite Vouge will be added here.";
exports.emptyVenue = "Your Favourite Venue will be added here.";
exports.noDataMsg = "No Favourites yet!";
exports.pageTitle = "Favourites";
exports.DisLike = "Dislike";
exports.Like = "Like";
exports.newLabel = "Favourites";

// Customizable Area End