import React from "react";
import { makeStyles, Theme, Button, Box, Typography } from "@material-ui/core";
import Slider, { Settings } from "react-slick";
import moment from "moment";

export interface MovieCrewCast {
  id: string;
  type: string;
  attributes: {
      name: string;
      image_url: string | null;
      role?: string;
      movie_name: string;
  };
}

interface CardCarouselProps {
  cardList: MovieCrewCast[]
}

const useStyles = makeStyles((theme: Theme) => ({
  SliderWrapper: {
    "& .slick-list": {
      "& .slick-track": {
        marginLeft: 0,
        "& .slick-slide": {
          margin: "0 12px"
        },
        [theme.breakpoints.up('md')]: {
          "& .slick-slide:first-child": {
            marginLeft: "0"
          },
        }
      },
    },
    "& .slick-next": {
      right: "19px",
      top: "80px",
      "&::before": {
        fontSize: "3.25rem",
        color: "#ffb43a",
        opacity: "1",
        [theme.breakpoints.down('md')]: {
          fontSize: "2rem"
        }
      },
      [theme.breakpoints.down('md')]: {
        right: "5px"
      }
    },
    "& .slick-prev": {
      top: "80px",
      left: "-24px",
      zIndex: 2,
      "&::before": {
        fontSize: "3.25rem",
        color: "#ffb43a",
        display: "block",
        opacity: "1",
        [theme.breakpoints.down('md')]: {
          fontSize: "2rem"
        }
      },
      [theme.breakpoints.down('md')]: {
        left: "-5px"
      }
    },
    "& .slick-next.slick-disabled": {
      "&::before": {
        display: "none"
      },
    },
    "& .slick-prev.slick-disabled": {
      "&::before": {
        display: "none"
      },
    },
  },
  CardWrapper: {
    
  },
  ImageWrapper: {
    border: "solid 1.75px #ffb53a",
    borderRadius: "4px",
    height: "154px",
    overflow: "hidden",
    marginBottom: "20px",
    [theme.breakpoints.down('sm')]: {
      height: "165px",
    }
  },
  PersonImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  PersonName: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: "12px"
    }
  },
  PersonRole: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      fontSize: "12px"
    }
  }
}));

const sliderSettings: Settings = {
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 3,
  draggable: true,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 968,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const CardCarousel: React.FC<CardCarouselProps> = (props) => {

  const classes = useStyles(props);
  const { cardList } = props

  return (
    <Slider {...sliderSettings} className={classes.SliderWrapper}>

      {
        cardList.map((card: MovieCrewCast) => (
          <Box key={card.id} className={classes.CardWrapper} >            
            <Box className={classes.ImageWrapper}>
              <img className={classes.PersonImage} src={card.attributes.image_url as string} alt="Profile Image" />
            </Box>

            <Typography className={classes.PersonName}>{card.attributes.name}</Typography>
            <Typography className={classes.PersonRole}>{card.attributes?.role}</Typography>
          </Box>
        ))
      }      

    </Slider>    
  );
};

export default CardCarousel;
