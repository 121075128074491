import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStylesOptions } from "@material-ui/core/styles/withStyles";
import { customStyles } from "./EditUserProfile.web";
import DigioFunction from "../../../components/src/Digio.web";

export const configJSON = require("./config");
export interface Props extends WithStylesOptions<typeof customStyles> {
  navigation: any;
  id: string;
  classes:any;
}
interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  name:string,
  email:string,
  phoneNumber:string,
  isKycVerified:boolean,
  digioDocumentId:string,
  digioLoginToken:string,
  profilePic:any,
  showImg:any,
  emailError:string,
  mobileError:string,
  nameError:string,
  loading:boolean,
  userName:string,
  completedSuccsessfully:boolean,
  dialogeContent:string,
  kycBy:string,
  profileClicked:boolean,
  // Customizable Area End
}

interface SS {
  id: string;
}

declare global {
  interface Window {
      Digio:any;
  }
}

export default class EditUserProfileController extends BlockComponent<
  Props,
  S,
  SS
>
{
  // Customizable Area Start
  getAccountDetails:any
  putProfileDetails:any
  getDigiTokenApiCallId:any
  kycVerifiedSuccessful:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

this.state = {
  // Customizable Area Start
  token: "",
  accountId: -1,
  name:"",
  email:"",
  phoneNumber:"",
  isKycVerified:false,
  digioDocumentId:"",
  digioLoginToken:"",
  profilePic:null,
  showImg:"",
  emailError:"",
  mobileError:"",
  nameError:"",
  loading:false,
  userName:"",
  completedSuccsessfully:false,
  dialogeContent:"",
  kycBy:"",
  profileClicked:false,
  // Customizable Area End
};
runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleBackBtn=()=>{
    this.props.navigation.goBack();
  }

  getDigiToken = () => {
    const headers = {
      "Content-Type": "application/json"
    };

this.setState({loading:true});

const body = {
  "customer_identifier": this.state.kycBy,
  "customer_name": "rahul",
  "actions": [
    {
      "type": "DIGILOCKER",
      "title": "Digilocker KYC",
      "description": "Please share your aadhaar card and Pan from digilocker",
      "document_types": ["AADHAAR"]

    }
  ],
  "notify_customer": true,
  "generate_access_token": true
}

const getDigiTokenMsg = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.getDigiTokenApiCallId = getDigiTokenMsg.messageId;

getDigiTokenMsg.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  "bx_block_profile/profile_kyc_verifications/digio_request"
);

getDigiTokenMsg.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(headers)
);
getDigiTokenMsg.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(body)
);
getDigiTokenMsg.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "POST"
);
runEngine.sendMessage(getDigiTokenMsg.id, getDigiTokenMsg);
return true;
  }

  kycVerificationCompleted = () => {
    const method = "PUT";
    const token = window.localStorage.getItem('login')
    const header = {
      "token": token 
    };
    this.setState({loading:true});

const data = new FormData();
data.append("data[kyc_status]", "approved");
   
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.kycVerifiedSuccessful = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.accountDetailsUrl
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  data
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);
runEngine.sendMessage(requestMessage.id, requestMessage);
   }

  handleReceive(resJson:any){
    let num = resJson.full_phone_number;
    if(resJson.full_phone_number && resJson.full_phone_number.length > 10){
      num = resJson.full_phone_number.split("").splice(2).join("")
    }
    this.setState({
      isKycVerified:resJson.kyc_status == "approved" ? true : false,
      phoneNumber:num ?? "",
      name:resJson.full_name ?? "",
      email:resJson.email ?? "",
      showImg:resJson.profile_image,
      userName:resJson.full_name ?? "",
      kycBy : resJson.email ?? num,
     })
   }

  async receive(from: string, message: Message) {

// Customizable Area Start
if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const resJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  if (apiRequestCallId === this.getAccountDetails) {
    this.handleReceive(resJson.data.attributes);
  }

  if (apiRequestCallId === this.putProfileDetails) {
    this.updateProfile(resJson);
  }

  if (apiRequestCallId === this.kycVerifiedSuccessful) {
    this.setState({ isKycVerified: true, dialogeContent: "KYC Verification Successful!", completedSuccsessfully:true });
  }

  if (apiRequestCallId === this.getDigiTokenApiCallId) {
    this.setState({
      digioDocumentId : resJson.access_token?.entity_id,
      digioLoginToken: resJson.access_token?.id },()=> {
        DigioFunction(
          this.kycVerificationCompleted,
          this.state.digioDocumentId,this.state.digioLoginToken, 
          this.state.kycBy,  
          this.state.phoneNumber )
     })
  }

  this.setState({loading:false});
} 
  
// Customizable Area End
  }
  // Customizable Area Start

  updateProfile = (resJson :any) =>{
    if(resJson?.data?.attributes){
      this.setState({completedSuccsessfully:true, dialogeContent:"Profile Updated Successfully!"});
      localStorage.setItem("userName", resJson.data.attributes.full_name)
    }else{
      resJson.errors[0].phone_number &&this.setState({mobileError:resJson.errors[0].phone_number})
      resJson.errors[0].email &&this.setState({emailError:resJson.errors[0].email}) 
    }
  }

  getAccountInfo = () => {
    const method = "GET";
    const token = window.localStorage.getItem('login')
    const header = {
      "Content-Type": 'application/json',
      "token": token 
    };

this.setState({loading:true});

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.getAccountDetails = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.accountDetailsUrl
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);
runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   onClickProfile = () => {
    this.setState({profileClicked: !this.state.profileClicked});
   }

   onRemoveProfile = () => {
    this.setState({profilePic:null,showImg:"",})
   }

  onChangeProfile = (e: any) => {
    this.setState({
        profilePic: e.target.files[0], 
        showImg:URL.createObjectURL(e.target.files[0]),
        profileClicked: false
      })
  }

   onChangeName = (e : any) => {
    this.setState({name: e.target.value, 
      nameError: !e.target.value ? "please enter name" : ""
  })
   }

   onChangeEmail = (e :any) => {
    this.setState({email: e.target.value,
      emailError: e.target.value.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) ? "" : "please enter valid email"
    });
   }

   onChangeNumber = (event :any) => {
    (!event.target.value || event.target.value.match(/^[0-9]+$/)) && this.setState({phoneNumber: event.target.value,
      mobileError: !event.target.value || event.target.value.length != 10 ? "please enter valid Phone number" : ""
    })
   }

   checkValidation = () =>{
    let isValid = true;
    if(!this.state.name){
      this.setState({nameError:"Please enter name"});
      isValid = false;
    }
    if(!this.state.email){
      this.setState({emailError:"Please enter Email"});
      isValid = false;
    }else if(!this.state.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)){
      this.setState({emailError:"please enter valid email"});
      isValid = false;
    }
    if(!this.state.phoneNumber){
      this.setState({mobileError:"please enter Phone number"});
      isValid = false;
    }else if(this.state.phoneNumber.length != 10){
      this.setState({mobileError:"please enter Valid Phone number"});
      isValid = false;
    }
    return isValid;
   }

   handleSave = (e:any) => {
    e.preventDefault();
    if(this.checkValidation()){
      const method = "PUT";
      const token = window.localStorage.getItem('login')
      const header = {
        "token": token 
      };
      this.setState({loading:true});
      const data = new FormData();
      data.append("data[full_name]", this.state.name);
      data.append("data[email]", this.state.email);
      data.append("data[full_phone_number]", 91+this.state.phoneNumber);
      !this.state.profilePic && !this.state.showImg && data.append("data[remove_profile]","true")
      this.state.profilePic && data.append("data[profile_image]",this.state.profilePic, this.state.profilePic.name)
        // : data.append("data[remove_profile]","true");

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.putProfileDetails = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountDetailsUrl
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        data
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
   }

  async componentDidMount() {
    this.getAccountInfo();
  }
}

