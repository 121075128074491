import React from "react";

// Customizable Area Start
const API_KEY = 'AIzaSyCOCrjfYXd6o_1NU73X9Y2keliIMPow8YA';

interface PlusCode {
  compound_code: string;
  global_code: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Location {
  lat: number;
  lng: number;
}

interface Viewport {
  northeast: Location;
  southwest: Location;
}

interface Geometry {
  location: Location;
  location_type: string;
  viewport: Viewport;
  bounds?: Viewport;
}

interface Result {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: Geometry;
  place_id: string;
  plus_code?: PlusCode;
  types: string[];
}

interface locationApiResponse {
  plus_code: PlusCode;
  results: Result[];
  status: string;
}
// Customizable Area End


export interface Props {

    // Customizable Area Start
    locationAllow:any;
    locationBlock:any;
    // Customizable Area End
  }
  interface Location {
    city: string;
    latitude: number;
    longitude: number;
  }
interface S {
    // Customizable Area Start

   
   // Customizable Area End
  }

export default class MainGeoLocation extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { 
      
    };
  }
 
  componentDidMount() {
    setTimeout(() => {
      this.getLocation();
    }, 3000)
  }

   fetchLocation = async (latitude:number, longitude:number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
      );
      const data = (await response.json()) as locationApiResponse;
         
      if (data.status === "OK") {

        const city = data.results[0].address_components.find((component) => component.types.includes('locality'));

        if (city) {
               this.props.locationAllow(city.long_name)
        } else {
                this.props.locationBlock('City not found')
        }
      } else {
              this.props.locationBlock('Error retrieving location')
             }
      } catch (error) {
              this.props.locationBlock('Error retrieving location')
      }
  };



   getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
         (position) => {
          const { latitude, longitude } = position.coords;
     
         void this.fetchLocation(latitude, longitude);
        },
        () => {
             this.props.locationBlock('Geolocation access blocked')
        }
      );
    } else {
       this.props.locationBlock('Geolocation not supported')
    }
  };
  render() {
    return <>
    </>
  
  }
}
 
