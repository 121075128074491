import React from "react";
import { Link } from 'react-router-dom';

import {
    // Customizable Area Start
    Typography,
    Paper,
    Grid,
    Button,
    Box,
    Container,
    makeStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import {
  ticket_logo,
  facebook,
  instagram,
  linkedin,
  twitter,
 
} from "../../blocks/splashscreen/src/assets"

const useStyles = makeStyles((theme) => ({
   eventBoxStyle:{
        display:'flex', 
        justifyContent:'flex-end',
        alignItems:'center',
        [theme.breakpoints.down(959)]: {
          justifyContent:'center',
          marginTop: 20
        }
    },
    buttonStyle:{
      textTransform:'none', 
      marginRight:32,
      marginLeft:0,
    },
    socialIcon:{
      display:'flex',
      alignItems:'center',
      justifyContent:'flex-end',
      [theme.breakpoints.down(959)]: {
        justifyContent:'center',
        marginTop: 40
      }
    },
    footerContent:{
      display:'flex',
      alignItems:'center', 
      width:'100%',
      [theme.breakpoints.down(959)]: {
        justifyContent:'center'
      }
    },
    menuItemStyle:{
      fontFamily: 'Poppins',
      fontSize: 18,
      color: '#b4b4b4',
      cursor:'pointer',
      [theme.breakpoints.down(578)]: {
        fontSize: 10,
      }
    },
}));

const FooterWithEvents = () => {
  const classes = useStyles()

  
  return (
<>
            {/* ---------------------------- Create  Events ------------------------------------------ */}  
            <Paper elevation={0} style={webStyle.paperFooterStyle} >
          <Container style={webStyle.gridPaddingStyle}>
            <Grid container style={webStyle.eventMainBox}>
              <Grid item xs={12}>

                <Grid container alignItems="center">
                  <Grid item xs={12} md={8}>
                    <Box component={"p"} style={webStyle.createEventTextStyle}>Hosting a show or event? Join forces with us and showcase it on Ticket Xchnage!</Box>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.eventBoxStyle}>
                    <Link to="/contactus" style ={{textTransform:'none', ...webStyle.eventBtnStyle}}>Contact Us</Link>
                  </Grid>
                </Grid>

              </Grid>

            </Grid>
           </Container>
          {/* ---------------------------- Footer == menu  and social media ------------------------------------------ */}  
          <Container  style={webStyle.gridFooterPaddingStyle}>
           <Grid container style={webStyle.footerMainBox}>
                 <Grid item xs={12} md={8} className={classes.footerContent}>
                 <Link to='/AboutUs' style={{textDecoration:'none' }}>
                 <Box component={"p"} className={classes.menuItemStyle}>About Us</Box>
                 </Link>
                 <Box component={"p"} style={{margin:'0 8px'}} className={classes.menuItemStyle}>|</Box>
                 <Link to="/TermsAndConditions" style={webStyle.textdecorNone}>
                  <Box component={"p"}  className={classes.menuItemStyle}>Terms & Conditions</Box>
                 </Link>
                 <Box component={"p"} style={{margin:'0 8px'}} className={classes.menuItemStyle}>|</Box>
                 <Link to="/privacypolicy" style={webStyle.textdecorNone}>
                  <Box component={"p"}  className={classes.menuItemStyle}>Privacy Policy</Box>
                 </Link>
                 <Box component={"p"} style={{margin:'0 8px'}} className={classes.menuItemStyle}>|</Box>
                 <Box component={"p"} className={classes.menuItemStyle}>Sitemap</Box>
            </Grid>

             <Grid item xs={12} md={4} className={classes.socialIcon}>
                         <Box style={{marginLeft:'14px'}}>
                         <img
                           src={facebook}
                           style={webStyle.socialMediaStyle}
                           alt='social media icon'
                       />
                         </Box>
                         <Box style={{marginLeft:'14px'}}>
                         <img
                           src={linkedin}
                           style={webStyle.socialMediaStyle}
                           alt='social media icon'
                       />
                         </Box>
                         <Box style={{marginLeft:'14px'}}>
                         <img
                           src={instagram}
                           style={webStyle.socialMediaStyle}
                           alt='social media icon'
                       />
                         </Box>
                         <Box style={{marginLeft:'14px'}}>
                         <img
                           src={twitter}
                           style={webStyle.socialMediaStyle}
                           alt='social media icon'
                       />
                         </Box>
                 </Grid>
                       
           </Grid>
           </Container>
            {/* ---------------------------- Footer == logo ------------------------------------------ */}  
          
          
            <Container  >
           <Box style={webStyle.footerLogoBox}>
                     <Box>
                     <Link to='/'>
                         <img
                           src={ticket_logo}
                           style={webStyle.logoFooterStyle}
                           alt='logo icon icon'
                       />
                       </Link>
                         </Box>
                         <Typography style={webStyle.logoTextStyle}>Ticket Xchange</Typography>
            </Box>
            </Container>
            {/* ---------------------------- Footer == copyright------------------------------------------ */}  
             <hr style={webStyle.lineStyle}/>

             <Container>
             <Typography align='center' style={webStyle.copyrightStyle}>Copyright © 2023 Ticket Xchange Pvt. Ltd. All Rights Reserved. </Typography>
             <Typography align='center' style={webStyle.copyrightContent}>The content and images used on this website are protected by copyright and are the property of their respective owners. Using the content and pictures of this website to promote works does not constitute an endorsement of the artist. Unauthorized use is prohibited and legal responsibilities shall be investigated according to law. </Typography>
             </Container>
        </Paper> 
</>
    

  );
}

// Customizable Area Start
const webStyle = {
 
createEventHeaderStyle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#fff',
    fontWeight: 500,
  },
  createEventTextStyle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#fff',
    fontWeight: 500,
  },
  eventBtnStyle:{
    width: '200px',
    height: '55px',
    backgroundColor: '#ffb43a',
    borderRadius: '12px',
    fontFamily: 'Poppins',
    fontSize: '18px',
    color: '#202020',
    fontWeight: 600,
    textDecoration:"none",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  },
  eventMainBox:{
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:'center',
    border: 'solid 0.5px #b4b4b4',
    backgroundImage: 'linear-gradient(to bottom, #000, #404040)',
    padding: '36px 32px 32px',
    marginTop:'-144px',
    widht:'1240px'
  },
  footerMainBox:{
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:'center',
   },
   footerLogoBox:{
    display:'flex', 
    justifyContent:'center', 
    alignItems:'center',
   },
  
  gridFooterPaddingStyle:{
    marginTop:'46px', 
     marginBottom:'28px',
      display:'flex',
      justifyContent:'center'
    },
    gridPaddingStyle:{
      marginTop:'62px', 
      // padding: "0 38px"
      },
  socialMediaStyle:{
    height:'32px',
    width:'32spx',
  
  },
  logoTextStyle:{
    fontFamily: 'Poppins',
    fontSize: '24px',
    color: '#fff',
    fontWeight:500,
    marginLeft:'12px',
  },
  logoFooterStyle:{
    height:'66px',
    width:'48px',
    cursor:'pointer'
  },
  lineStyle:{
    height: '1px',
    backgroundColor:'rgb(204, 204, 204, 0.5)',
    border: 'none' ,
    width:'100%',
    margin: '32px 0'
  },
  copyrightStyle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#fff',
    marginBottom:'1.14px',
  
  },
  copyrightContent: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#b4b4b4',
    marginBottom:'24px',
    lineHeight: 1.71,
    marginTop:20
  },
  paperFooterStyle:{
    width: '100%',
    marginTop:'144px',
    backgroundColor: 'rgba(64, 64, 64, 0.4)',
    display: 'flex',
    flexDirection: 'column' as const, 
  },
  eventTextBox:{
    display:'flex', 
    justifyContent:'space-between', 
    flexDirection:'column' as const,
  },
  textdecorNone:{
    textDecoration:'none'
  }

}
export default FooterWithEvents;
// Customizable Area End