import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { IBookingResponse, IRequest, IUserDetails } from "../../../components/src/interfaces.web";
import { getLastPartOfURL } from "../../../components/src/utilities";
import moment from "moment";

type FoodCostDetails = {
    totalFoodAmount: number
    Food: {
        menuId: number
        label: string
        price: number
        quantity: number
    }[],
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    isConvenienceFeeOpen: boolean;
    isCostOpen: boolean;
    isModalOpen: boolean;
    isEdit: boolean;
    price: any;
    isSeatEdit: boolean;
    isSeatSelected: boolean;
    bookingData: IBookingResponse;
    bookingId: any;
    seatID: any;
    isApiLoading: boolean;
    priceError: string;
    bookingRequest: IRequest[];
    bookingRequestFilter: any;
    defaultPrice: any;
    isBookingCalled: boolean;
    openNavigationDialog: boolean;
    ticketinvalid:boolean;
    loading:boolean;
    foodCostCalculation: FoodCostDetails,
    isFoodOpen: boolean
    isTransferAmount: boolean;
    isKycApproved:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ViewTicketController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    bookingHistoryApiID: any;
    bookingHistoryDeleteApiID: any;
    UpdateListAPiID: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isConvenienceFeeOpen: false,
            isCostOpen: false,
            isModalOpen: false,
            isEdit: false,
            price: null,
            isSeatEdit: false,
            isSeatSelected: false,
            bookingData: {} as IBookingResponse,
            bookingId: null,
            seatID: {},
            isApiLoading: false,
            priceError: '',
            bookingRequest: [],
            bookingRequestFilter: [],
            defaultPrice: null,
            isBookingCalled: false,
            openNavigationDialog: false,
            ticketinvalid:false,
            loading:true,
            foodCostCalculation: {} as FoodCostDetails,
            isFoodOpen: false,
            isTransferAmount:false,
            isKycApproved:false,
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId === this.bookingHistoryApiID) {

                const foodDetails = this.getFoodCostDetails(resJson.data as IBookingResponse)

                this.setState({ 
                    bookingData: resJson && resJson.data, 
                    bookingRequest: resJson.data.attributes.requests as IRequest[], 
                    isBookingCalled: !resJson.data.attributes.is_list_to_sell , 
                    ticketinvalid:resJson.data.attributes.is_invalid,
                    loading: false,
                    foodCostCalculation: foodDetails
                })
            }
            if (apiRequestCallId === this.bookingHistoryDeleteApiID) {
                this.handleDeleteResponse(resJson)
            }
            if (apiRequestCallId === this.UpdateListAPiID) {
                this.handleUpdateResponse(resJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getFoodCostDetails = (resJson: IBookingResponse): FoodCostDetails => {
        const { attributes: { menu } } = resJson
        let total_menu_amount = 0
        let menuItems: { price: number, quantity: number, menuId: number, label: string }[] = []
        if (menu) {
            menu.forEach(({ quantity, quantity_price, booking_menu_id, data: { attributes: { name } } }) => {
                total_menu_amount += quantity_price;
                menuItems.push({ price: quantity_price, quantity, label: name, menuId: booking_menu_id, })
            })
        }
        return { totalFoodAmount: total_menu_amount, Food: menu ? menuItems : [] }
    }

    showFoodCostData = () => this.setState({ isFoodOpen: !this.state.isFoodOpen });

    handleBack = () => {
        this.props.navigation.goBack();
    }
    showCostData() {
        this.setState({ isConvenienceFeeOpen: !this.state.isConvenienceFeeOpen })
    }
    showCost() {
        this.setState({ isCostOpen: !this.state.isCostOpen })
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: false }, () => window.scrollTo(0, 0));

    }
    handleSubmit = () => {
        this.deleteBookingAPi()
    }

    handleRedirectListTOsell = async() => {
        // check user is KYC verified or not
        const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true)) as IUserDetails;
        if (userDetails.attributes.kyc_status != "approved") {
            this.setState({ isKycApproved: true });
            return
        }
        const {id} = this.state.bookingData
        await setStorageData(LocalStorageKeys.ValidListToSellRedirection, "true")
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ListToSell");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
        this.send(message)
    }
    handleEditInfo = () => {
        const { bookingData, bookingRequest } = this.state
        let arr = bookingData && bookingData.attributes.seat_id_number.map((item_number) => {
            let newArr: never[] = []
            return bookingRequest && bookingRequest.find((item: any, index: number) => {
                if (item.seat_ids[index] === item_number.seat_id) {
                    this.setState({ defaultPrice: item.ticket_price })
                    return [...newArr, item]
                }
            })
        }
        )
        const seatArr = arr.filter(item => item !== undefined)
        this.setState({
            isModalOpen: true,
            isEdit: true,
            isSeatEdit: false,
            bookingRequestFilter: seatArr
        });
    }
    handleChange = (e: any) => {
        const inputValue = e.target.value;
        let regex = /^\d+$/;
        const isValidInputs = regex.test(inputValue);
        if (inputValue > 49999) {
            this.setState({ priceError: 'Enter less than 49999 amount' })
        }
        else {
            this.setState({ price: isValidInputs ? inputValue : '', priceError: '' });
        }
    }
    isPriceValids = (data?: string) => {
        if (this.isStringNulls(data || this.state.price)) {
            this.setState({ priceError: 'Enter a Price' })
        }
        else {
            this.setState({ priceError: '' })
        }
    }
    isStringNulls = (str: string) => {
        return str === null || str?.length === 0
    }
    handleEditSeat = () => {
        this.setState({
            isModalOpen: true,
            isSeatEdit: true,
        });
    }
    handleSeatSelect = (seatId: any) => {
        this.setState((prevState) => {
            const isSeatSelecteds = prevState.seatID[seatId];
            return {
                seatID: {
                    ...prevState.seatID,
                    [seatId]: !isSeatSelecteds,
                },
            };
        });
    }

    getBookingData = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
        const bookingId = getLastPartOfURL()

        this.bookingHistoryApiID = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.GETMethod,
            endPoint: `${configJSON.getBookingApiEndPoint.replace(":id", bookingId)}`,
            headers: { "token": authToken },
        }, runEngine)
    }
    deleteBookingAPi = async () => {
        const { bookingData } = this.state
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
        let bookingRequestId;
        if (bookingData.attributes.requests && Array.isArray(bookingData.attributes.requests) && bookingData.attributes.requests.length > 0) {
            // Check if the first request's type is "resell"
            if (bookingData.attributes.requests[0].request_type === "resell") {
                bookingRequestId = bookingData.attributes.requests[0].id;
            }
        }
        this.bookingHistoryDeleteApiID = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.DeleteMethod,
            endPoint: `${configJSON.deleteBookingApiEndPoint}/${bookingRequestId}`,
            headers: { "token": authToken },
        }, runEngine)
    }
    handleDeleteResponse = (resJson: any) => {
        if (resJson && resJson.message) {
            this.setState({
                isModalOpen: true,
                isEdit: false,
                isSeatEdit: false,
                isApiLoading: true,
                isBookingCalled: true,
                bookingRequest: []
            }, () => {
            });
        }

    }
    updateListApiById = async () => {
        const { bookingRequestFilter, price, seatID, defaultPrice } = this.state
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
        let bookingRequestId = null;
        if (bookingRequestFilter.length > 0) {
            bookingRequestId = bookingRequestFilter[0].id;
        }
        let seatIds = Object.keys(seatID)

        if (seatIds.length === 0) {
            // Extract seat IDs from the requests array
            seatIds = bookingRequestFilter.flatMap((request: any) => {
                // Check if seat_ids array is not empty
                if (request.seat_ids.length > 0) {
                    return request.seat_ids;
                } else {
                    // Extract seat_id from the seats array
                    return request.seats.map((seat: any) => seat.seat_id);
                }
            });
        }
        const httpBody = {
            "booking_request": {
                "seat_ids": seatIds,
                "ticket_price": price > bookingRequestFilter[0].ticket_price ? price : defaultPrice
            }
        }

        this.UpdateListAPiID = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.PUTMethod,
            endPoint: `${configJSON.updateListApiEndPoint}/${bookingRequestId}/update_resell_request`,
            headers: { "token": authToken },
            body: httpBody
        }, runEngine)
    }
    handleUpdateResponse = (resJson: any) => {
        if (resJson && resJson.message) {
            this.setState({ priceError: resJson.message[0] })
        }
        if (resJson.data) {
            this.setState({
                isModalOpen: false,
                isSeatEdit: false,
                priceError: '',
                seatID: {}
            }, () => {
                this.forceUpdate()
                this.getBookingData()
            });
        }
    }
    handleUpdateSubmit = () => {
        if (this.state.isEdit === true) {
            this.updateListApiById()
        }
        else {
            this.setState({ isModalOpen: false }, () => {
                this.getBookingData()
            })
        }
    }
    handleNavigate = () => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Contactus");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationScreenNameMessage), {});
        this.send(msg)
    }

    isShowTime(){
        const showTiming = moment(this.state.bookingData?.attributes?.show_date_time, 'YYYY-MM-DD | hh:mm A');
         const currentDateTime = moment().format('YYYY-MM-DD | hh:mm A');
        const currentDateTimeFormatted = moment(currentDateTime, 'YYYY-MM-DD | hh:mm A');
         return (showTiming.isBefore(currentDateTimeFormatted) || this.state.bookingData?.attributes?.status === "cancelled")
    }

    checkForState(stateName  :string){
        return (stateName === "maharshtra" || stateName === "maharashtra")
    }

    handleCancelBooking=()=>{
        
        if(this.isShowTime() || this.state.bookingData.attributes.is_resold_booking || this.state.bookingData.attributes?.booking_type === "ito"){
            return false
        }
        if(!this.state.bookingData.attributes.is_list_to_sell){
            const bookingId = getLastPartOfURL()
            const msg = new Message(getName(MessageEnum.NavigationMessage));
            msg.addData(getName(MessageEnum.NavigationTargetMessage), "CancelBooking");
            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            msg.addData(getName(MessageEnum.NavigationScreenNameMessage), bookingId);
            this.send(msg)
        }
    }

    handleOpenNavigation = () => {
        this.setState({ openNavigationDialog: true })
    }

    handleDialogClose = () => {
        this.setState({ openNavigationDialog: false })
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getBookingData()
        window.addEventListener(EventListenerKeys.ProfileUpdated, this.getBookingData)
    }

    async componentWillUnmount() {
        window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getBookingData);
    };

    handleClickNewTicket = () => {

        const ticketId = this.state.bookingData.attributes.child_bookings.find((elm) => 
            (this.state.bookingData.attributes.account_id === elm.account_id)
        )

        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewTicket");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationScreenNameMessage), ticketId.id);
        this.send(msg);

        this.getBookingData()
    }

    showTransectionData() {
        this.setState({ isTransferAmount: !this.state.isTransferAmount })
    }

    handleOnCloseKyc = () => this.setState({ isKycApproved: false });

    // Customizable Area End
}
