import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  CssBaseline,
  Fade,
  CircularProgress,
  Divider,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { previous } from './assets'
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { goToPrevPage } from "../../../components/src/utilities";
export const configJSON = require("./config");


const customizedTheme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    },
    text: {
      primary: "#FFF"
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h4: {
      fontSize: "20px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "18px"
      }
    },
    body1: {
      fontSize: "16px",
      color: "#d6d6d6",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    }
  },
});
// Customizable Area End

import AboutTeamController, {
  Props,
} from "./AboutTeamController.web";
import ReadMore from "../../../components/src/ReadMore.web";

export default class AboutTeam extends AboutTeamController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={customizedTheme}>

        <CssBaseline />

        <MainWrapper>

          <Box><MainHeaderComp /></Box>

          <Box flex={1}>
            <Container>

              <Box className="child__header">
                <img data-test-id="btn-back" src={previous} className="back__button" onClick={goToPrevPage} />
                <Typography variant="h4" className="page__title">{configJSON.labelTeam}</Typography>
              </Box>

              {
                this.state.isLoading ? (
                  <Fade in={this.state.isLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress className="circular__progress" />
                    </Box>
                  </Fade>
                ) : (
                    <>
                    {
                      this.state.teamDetails.map((detail, index) => (
                        <section className="member_section__wrapper">
                          
                          <Typography className="member__details">
                            <img src={detail.attributes.image} className={`member__photo ${index % 2 ? 'right' : 'left'}`} alt="investor" />
                            <span className="member__name">
                              {detail.attributes.name}
                              <span className="member__designation">{` (${detail.attributes.designation})`}</span>
                            </span>
                            <ReadMore content={detail.attributes.description} charsToDisplay={500} />
                          </Typography>

                          <Divider className="divider" />

                        </section>
                      ))
                    }
                    </>
                )
              }
            </Container>
          </Box>

          <Box><FooterWithEvents /></Box>

        </MainWrapper>


      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "& .circular__progress": {
    margin: "20px auto",
    color: "#ffb43a"
  },
  "& .child__header": {
    display: "flex",
    alignItems: "center",
    margin: "24px 0",
    "& .back__button": {
      width: "28px",
      height: "28px",
      marginRight: "12px",
    },
  },
  "& .member_section__wrapper": {
    "& .divider": {
      margin: "40px 0",
      height: "1px",
      backgroundColor: "#404040",
    },
    "& .member__details": {
      minHeight: "234px",
      textAlign: "justify",
      "& .member__photo": {
        "&.left": {
          float: "left",
          margin: "0 16px 0 0",
        },
        "&.right": {
          float: "right",
          margin: "0 0 0 16px",
        },
        borderRadius: "8px",
        width: "240px",
        height: "240px",
      },
      "& .member__name": {
        fontSize: "20px",
        fontWeight: 600,
        display: "block",
        marginBottom: "4px",
        textAlign: "left",
        "& .member__designation": {
          fontWeight: 400,
          color: "#ffb43a",
          paddingLeft: "4px",
          fontSize: "18px"
        }
      },
      "& .read__more": {
        color: "#ffb43a",
        textTransform: "capitalize",
        lineHeight: 1
      }
    },
    "&:last-child": {
      "& .divider": {
        display: "none"
      }
    }
  },
  "@media (max-width: 600px)": {
    "& .child__header": {
      margin: "18px 0 22px !important",
      "& .back__button": {
        width: "24px !important",
        height: "24px !important",
        marginRight: "10px !important",
      },
    },
    "& .member_section__wrapper": {
      "& .divider": {
        margin: "24px 0 !important",
      },
      "& .member__details": {
        minHeight: "unset !important",
        "& .member__photo": {
          "&.left": {
            margin: "0 8px 0 0 !important",
          },
          "&.right": {
            margin: "0 0 0 8px !important",
          },
          borderRadius: "8px",
          width: "100px !important",
          height: "100px !important",
        },
        "& .member__name": {
          marginBottom: "2px",
          fontSize: "18px !important",
          "& .member__designation": {
            fontSize: "14px !important"
          }
        },
      }
    },
  }
})
// Customizable Area End
