import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Container,
    Paper,
    Typography,
   } from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import TermsAndConditionsController from "./TermsAndConditionsController.web";
import Loader from "../../../components/src/Loader";

  const theme = createTheme({
   
    typography:{
      h2:{
        margin: 0,
        padding:"0 40px",
        fontFamily: 'Poppins',
        fontSize: '18px',
        color: '#ffffff',
        '@media(max-width:700px)': {
            fontSize: "14px",
            padding:"0 10px",
        }
    },
      h1:{
          fontFamily: 'Poppins',
          fontSize: '24px',
          fontWeight: 600,
          color: '#ffffff',
          margin: "20px 0",
          padding:"0 40px",
          '@media(max-width:700px)': {
              fontSize: "18px",
              padding:"0 10px",
          }
      },
    },

    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
  });
  

export default class TermsAndConditions extends TermsAndConditionsController {
    
    render() {
        return (
        <ThemeProvider theme={theme}>
          <Loader loading={this.state.loading}/>
        <Container maxWidth={false} style={webStyle.paddingLeft}>
          <Paper elevation={0} style={webStyle.mainPaperStyle}>
            {/* ----------------------------App Bar---------------------------------------------------- */}
              <MainHeaderComp navigation = {this.props.navigation} />
              <div style={webStyle.container}>
                <Typography variant="h1">Terms and conditions</Typography>
        
              <Typography align="justify" variant="h2" style={{lineHeight:"1.5", paddingRight: "20px"}} 
              dangerouslySetInnerHTML={{__html: this.state.description}}
              >
               </Typography>
              </div>
              <FooterWithEvents />
              </Paper>
              </Container>
              </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start

const webStyle = {
    mainPaperStyle: {
      minHeight: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column' as const,
      backgroundColor: "#121212",
    },
    container:{
      width:"100%",
      maxWidth:"1280px",
      marginLeft:"auto",
      marginRight:"auto",
      paddingRight: "30px"
  },
  paddingLeft:{
    paddingLeft:0
  }
}
// Customizable Area End