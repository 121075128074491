import React from "react";

import {
    Container,
    Box,
    Typography,
    // Customizable Area Start
    Paper,
    FormControlLabel,
    Radio,
    RadioGroup,
    withStyles,
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Backdrop,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import EventHeader from "../../../components/src/EventHeader";
import { paymentFailed } from "./assets";
import { EventPreviewBooking } from "../../events/src/EventPreviewBooking.web";
import RFHDialog from "../../../components/src/RFHDialog";

export const configJSON = require("./config");

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
        h4: {
            fontFamily: 'Poppins',
            fontSize: '18px',
            color: '#ffffff',
            margin: 0,
            ontWeight: "normal",
            '@media(max-width:700px)': {
                fontSize: "15px",
            }
        },
        h1: {
            fontSize: 24,
            fontWeight: 600,
            fontFamily: "Poppins",
            color: "#fff",
            marginTop: 40,
            marginBottom: 18,
            '@media(max-width:600px)': {
                fontSize:16,
            },
        },
        h2:{
            fontSize: '22px',
            fontWeight: 600,
            '@media(max-width:600px)': {
              fontSize:18,
            },
        },
    },
});

const CustomRadio = withStyles({
    root: {
        color: '#faf8f6',
        '&$checked': {
            color: '#ffb43a',
        },
    },
    checked: { fontWeight: 'bold', },
})((props) => <Radio color="default" {...props} />);

const RFHDialogButton = styled(Button)({
    textTransform: "capitalize",
    fontFamily: "Poppins, Roboto",
    color: "#000",
    backgroundColor: "#ffb43a",
    borderRadius: "12px",
    height: "56px",
    fontWeight: 600,
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#ffb43a"
    }
})

// Customizable Area End

import PaymentSelectionController, {
    Props,
} from "./PaymentSelectionController.web";
import HeaderWeb from "../../../components/src/Header.web";
import PreviewBookingWeb from "../../reservations2/src/PreviewBooking.web";
import KycDialogeBox from "./KycDialogeBox.web";
import Loader from "../../../components/src/Loader.web";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { close } from "../../contactus/src/assets";


export class PaymentSelection extends PaymentSelectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { classes } = this.props;

        const { selectedValue } = this.state;
        const radioOptions = [
            { value: 'Wallet', label: `Wallet Balance <span style=font-family:Roboto>&#8377;</span> ${this.state.totalWalletBalance.toFixed(2) ?? 0}`, ImgUrl: require('../assets/Wallet.png') },
            { value: 'Other', label: 'Other Payment' },

        ];
        return (
            // Customizable Area Start

            <ThemeProvider theme={theme}>
                <Paper elevation={0}
                >

                    {this.state.isFromEvent
                        ? <EventHeader navigation={this.props.navigation} />
                        : <HeaderWeb
                            seatType={false}
                            navigation={this.props.navigation}
                        />
                    }
                    <Container className={classes.containerMain}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7} >
                                <Box
                                    className={classes.paymentbox}
                                >
                                    <Typography
                                        data-test-id="payOption"
                                        onClick={this.handleClick}
                                        className={classes.paymentName}
                                        component={"span"}
                                        id=""
                                    >
                                        {configJSON.paymentOptions}
                                    </Typography>


                                    <Box className={classes.choosepaybox}  >
                                        <Typography
                                            component={"span"} className={classes.choose}
                                        >
                                            {configJSON.chooseOptions}
                                        </Typography>
                                        <Box>
                                            <RadioGroup
                                                aria-label="Bank"
                                                name="Bank"
                                                value={selectedValue}
                                                onChange={this.handleChange}
                                                data-test-id="radio_button"
                                            >
                                                {radioOptions.map((option, index) => {
                                                    if(this.state.hideOtherPayment && option.value === "Other") return
                                                return (

                                                    <Box
                                                        key={index}
                                                        style={{ marginTop: option.value == 'Wallet' ? '0px' : '10px', position: 'relative' }}>
                                                        <FormControlLabel
                                                            key={option.value}
                                                            value={option.value}
                                                            control={<CustomRadio />}
                                                            label={
                                                                <div
                                                                    style={{ flexDirection: 'row' as const, display: 'flex', }}
                                                                >
                                                                    <p
                                                                        dangerouslySetInnerHTML={{ __html: option.label }}
                                                                        className={classes.radioContainer}
                                                                    />
                                                                    {option.ImgUrl ?

                                                                        <img className={classes.radioImage} style={{ marginTop: option.value == 'Wallet' ? '38px' : '10px' }} src={option.ImgUrl}></img>
                                                                        : ""}
                                                                </div>
                                                            }
                                                        />
                                                    </Box>
                                                )})}
                                            </RadioGroup>
                                        </Box>

                                    </Box>


                                </Box>


                            </Grid>
                            <Grid item xs={12} sm={12} md={5} >
                                {/* Preview Screen comes here*/}
                                <Box className={classes.MinHeight}>

                                    {this.state.isFromEvent
                                        ? <EventPreviewBooking
                                            navigation={this.props.navigation}
                                            id="preview-booking"
                                            classes={classes}
                                            handlePayment={this.state.selectedValue === "Wallet" ? this.handleProceedOnSelectWallet : this.createOrder}
                                            isPaymentSelection={true}
                                            selectedValue={this.state.selectedValue}
                                        />
                                        : <PreviewBookingWeb
                                            id="preview-booking"
                                            handlePayment={this.state.selectedValue === "Wallet" ? this.handleProceedOnSelectWallet : this.createOrder}
                                            navigation={this.props.navigation}
                                            isPaymentSelection={true}
                                            selectedValue={this.state.selectedValue}
                                        />
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        {this.state.isKycModal &&
                            <KycDialogeBox navigation={""} id="" handleOnClose={this.handleOnCloseKyc} />
                        }
                    </Container>
                </Paper>
                <Dialog
                    id="language"
                    open={this.state.isPaymentPopup}
                    PaperProps={{
                        style: {
                            backgroundColor: "#404040",
                            color: "#fff",
                            padding: '25px 32px',
                            maxWidth: "456px",
                            boxSizing: "border-box",
                            margin:"0px 5px"
                        },
                        elevation: 0,
                    }}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    data-test-id="balance"
                >
                    <img
                        src={close}
                        alt='closebutton'
                        data-test-id="crossBtn"
                        style={webStyle.crossBtn}
                        onClick={() => this.handleOnCloseInfoPopup()}
                    />
                    <DialogContent
                        style={webStyle.dialogContent}
                        data-test-id="seat_number">

                        <Typography variant="h1" style={{ marginTop: 24 }}>{this.state.popupInfo.heading}</Typography>
                        <Typography variant="h4" dangerouslySetInnerHTML={{ __html: this.state.popupInfo.discription }}>
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                        <Button
                            data-test-id="doneBtn"
                            color="primary"
                            style={webStyle.popupBtn}
                            onClick={() => this.handleProccedByWallet()}
                        >
                            {configJSON.proceed}
                        </Button>
                    </DialogActions>
                </Dialog>

                <RFHDialog
                    data-test-id="price-alert-dialog"
                    open={this.state.isFailedTransactionOpen}
                    handleClose={this.handleCloseFailedTransaction}
                    maxWidth="xs"
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHDialogButton fullWidth data-test-id="price-alert-proceed-btn" onClick={this.handleCloseFailedTransaction}>
                            {configJSON.TryAgain}
                        </RFHDialogButton>
                    )}
                    dialogContent={(
                        <>
                            <Box display="flex" justifyContent="center" mb={3}>
                                <img src={paymentFailed} width="180px" alt="payment-fail" />
                            </Box>
                            <Typography data-test-id="payment-transaction-failed" align="center" className={classes.TransactionFailed}>{configJSON.TransactionFailed}</Typography>
                        </>
                    )}
                />

                <Backdrop style={styles.BackDrop} data-test-id="loader" open={this.state.loading || this.state.isBackDropOpen}>
                    <CircularProgress style={styles.CircularProgress} color="inherit" />
                </Backdrop>

            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = ({
    containerMain: {
        marginTop: "20px"
    },
    BackDrop: {
        color: "#ffb43a",
        zIndex: 2000
    },
    CircularProgress: {
        color: "#ffb43a"
    },
    TransactionFailed: {
       fontFamily: "Poppins",
       fontSize: "18px",
       color: "#FFF",
       fontWeight: 600 
    },
    MainWrapper: {
        padding: "26px 0",
    },
    MinHeight: {
        minHeight: "100vh"
    },
    mainPaperStyle: {
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column' as const,
        backgroundColor: "#121212",
        [theme.breakpoints.down("sm")]: {
            width: "100vh"
        },
        [theme.breakpoints.up("md")]: {
            width: "100vw"
        },
    },
    paymentbox: {
        padding: '24px 20px 48.9px',
        backgroundColor: "#252525",
        fontFamily: "Poppins",
        borderRadius: '8px',
    },
    paymentName: {
        fontSize: '20px',
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 600
    },
    choose: {
        fontSize: '18px',
        color: 'white',
        marginTop: '10px',
        fontFamily: 'Poppins'
    },
    choosepaybox: {
        marginTop: '15px',
        marginBottom: '20px'
    },
    header: {
        width: '1440px',
        height: '90px',
        padding: '18px 978px 14px 100px',
        backgroundColor: '#252525',
    },
    Btn: {
        marginTop: "8%",
        width: "201px",
        height: "56px",
        fontFamily: "Poppins",
        color: "black",
        borderRadius: "12px",
        backgroundColor: "#ffb43a",
        marginLeft: "10%",
    },
    radioContainer: {
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '18px',
        marginLeft: '7.5px',
        width: '138px'
    },
    radioImage: {
        height: '30px',
        width: '32px',
        position: 'absolute' as const,
        right: '0px',
        [theme.breakpoints.down("sm")]: {
            marginLeft: "8vh"
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: "24vh"
        },
    }
})

const webStyle = {
    popupBtn: {
        width: "100%",
        backgroundColor: '#ffb43a',
        padding: '12px 80px',
        borderRadius: 12,
        color: '#202020',
        textTransform: 'capitalize' as const,
        fontFamily: "Poppins",
        fontSize: 18,
        textAlign: "center",
        fontWeight: 600,
        marginTop: 18,
        "&:hover": {
            backgroundColor: '#ffb43a',
        }
    } as CSSProperties,
    dialogContent: {
        textAlign: "center",
    } as CSSProperties,
    crossBtn: {
        height: '28px',
        position: "absolute",
        cursor: 'pointer',
        top: 8,
        right: 8
    } as CSSProperties
}
export default (withStyles(styles, { withTheme: true })(PaymentSelection))

// Customizable Area End


