import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from "@material-ui/core";
import { close, success } from "../../contactus/src/assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    h2: {
      fontFamily: 'Poppins',
      fontSize: '24px',
      fontWeight: 600,
      color: '#ffffff',
      margin: "20px 0",
      '@media(max-width:700px)': {
        fontSize: "18px",
      },
    },
  },
});

// Customizable Area End

import KycDialogeBoxController, {
    Props,
    configJSON,
  } from "./KycDialogeBoxController";

export default class KycDialogeBox extends KycDialogeBoxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          id="language"
          open={true}
          PaperProps={{
            style: webStyle.dialogStyle,
            elevation: 0,
          }}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          data-test-id="seat_dialog"
        >
          <img
            src={close}
            alt='closebutton'
            data-test-id="crossBtn"
            style={webStyle.crossBtn}
            onClick={() => this.props.handleOnClose()}
          />
          <DialogContent
            style={webStyle.dialogContent}
            data-test-id="seat_number">
            <img src={success} alt="success"
              color="primary"
              style={webStyle.successImg}
            />
            <Typography variant="h2">{configJSON.KycPopupDescription}</Typography>
           </DialogContent>
          <DialogActions >
            <Button
              data-test-id="proceedBtn"
              color="primary"
              style={webStyle.doneBtn}
              onClick={() => this.handleKYCVerification()}
            >
              {configJSON.proceed}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  dialogStyle:{
    backgroundColor: "#404040",
    color: "#fff",
    padding: '48px 32px',
    maxWidth: "456px",
    width:"100vw",
    margin:"0px 5px"
  },
  successImg: {
    height: '48px',
    width: '48px'
  },
  dialogContent: {
    textAlign: "center",
  } as CSSProperties,
  doneBtn: {
    padding: '12px 80px',
    width: "100%",
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  } as CSSProperties,
  crossBtn: {
    height: '28px',
    cursor: 'pointer',
    position: "absolute",
    top: 8,
    right: 8
  } as CSSProperties
}
// Customizable Area End
