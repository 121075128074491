import React from "react";
import DatePicker from "react-datepicker";
import {
  Box,
  Button,
  Popover,
  Popper,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

interface myProps {
  handleOnChange:any,
  dateSelected:any,
  loader:boolean
}

const useStyles = makeStyles(() => ({
  customDatePickerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#404040',
    color: '#b4b4b4',
    fontWeight: 'bold',
    fontSize:21,
    padding:"0 8px",

    '& button': {
      color: '#b4b4b4',
      border: 'none',
      cursor: 'pointer',
      background:"none",
    },
  },
  dateBtn:{
    height: "48px",
    padding: "13px 16px 12px",
    margin:"0px 10px",
    border: "solid 0.5px #ffb636",
    borderRadius: "8px",
    backgroundColor: "#2f2617",
    color: "#ffffff",
    '&:hover': {
        backgroundColor: "#2f2617"
    },
    textTransform: "capitalize",
    fontFamily: "Poppins", 
    fontSize: "14px", 
    fontWeight: 600,
    "& .MuiSvgIcon-root":{
       color: "#ffb43a" 
     },
     '@media(max-width:600px)': {
      height: "30px",
      padding: "13px 6px 12px",
      fontSize: "12px",
  },
  },
  datePickerContent:{
    maxWidth:"349px",
    width:"100%",
    display: "flex",
    backgroundColor: "#5a5a5a",
    padding: '10px',
    minWidth: "100px",
    justifyContent:"space-between",
    alignItems:"center",
    borderRadius:"12px",
    '& .react-datepicker__header':{
      backgroundColor:"#404040"
    },
    '& .react-datepicker__current-month':{
      color:"#b4b4b4"
    },
    '& .react-datepicker__day--selected':{
      background:"#ffb636",
      color:"#404040"
    },
    '& .react-datepicker__day--disabled':{

    }
  },
  datePickerBtn:{
    width:"146px",
    height: "38px",
    padding: "9px",
    border: "solid 1px #b4b4b4",
    borderRadius: "12px",
    backgroundColor: "#2f2617",
    color: "#ffffff",
    textTransform: "capitalize",
    fontFamily: "Poppins", 
    fontSize: "14px", 
    fontWeight: "normal",
  },
  downarrow: {
    color: '#ffb43a'
},
calender:{
background:"#404040",
},
calenderDate:{
  fontSize:16,
  fontFamily: "Poppins", 
  color:"#b4b4b4"
},
calenderWeek:{
  fontSize:15,
  fontFamily: "Poppins", 
  color:"#b4b4b4",
  background:"#404040",
}
}));

export default function DatePickerComponent(props: myProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {handleOnChange, dateSelected, loader} = props

  const start = dateSelected.startDate ?? new Date();
  const end = dateSelected.endDate ?? new Date();

  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Button data-test-id="date-picker-button" className={classes.dateBtn} onClick={handleClick}>
        {"Date"}
        {open ? (
          <ExpandLessIcon className={classes.downarrow} />
        ) : (
          <KeyboardArrowDownIcon className={classes.downarrow} />
        )}
      </Button>

      <Popper
        id={id}
        open={open && !loader}
        transition
        style={{
          marginTop: "12px",
          borderRadius: '12px',
          // overflowX: "inherit",
          // overflowY: "inherit",
          height: "57px",
        }}
        // onClose={handleClose}
        anchorEl={anchorEl}
        // transformOrigin={{
        //   horizontal: 'left',
        //   vertical: 'top',
        // }}
        // PaperProps={{
        //   style: {
        //     marginTop: "12px",
        //     borderRadius: '12px',
        //     overflowX: "inherit",
        //     overflowY: "inherit",
        //     height: "57px",
        //   },
        // }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
        data-test-id="anchorbtn"
      >
        <Box className={classes.datePickerContent}>
        
          <DatePicker
            data-test-id="date-picker"
            selected={start}
            maxDate={new Date()}
            onChange={(date:any) => handleOnChange(date,"startDate")}
            calendarClassName={classes.calender}
            dayClassName={(date:any) =>
              classes.calenderDate}
            weekDayClassName={() => classes.calenderWeek}
            customInput={<Button className={classes.datePickerBtn}>{moment(start).format("DD/MM/YYYY")}</Button>}
          />
          <Box style={{ width: 1, height: 28, background: "#ffb636" }}></Box>
          <DatePicker
            selected={end}
            maxDate={new Date()}
            minDate={start}
            onChange={(date:any) => handleOnChange(date, "endDate")}
            calendarClassName={classes.calender}
            dayClassName={(date:any) =>
              classes.calenderDate}
            weekDayClassName={() => classes.calenderWeek}
            customInput={<Button className={classes.datePickerBtn}>{moment(end).format("DD/MM/YYYY")}</Button>}
          />
        </Box>
      </Popper>
    </>
  )
}
