import React, { useEffect, useRef, useState } from 'react'

interface ICountDownTimerProps {
  expiryDate: string
}

interface ITimeFormat {
  hours: number; 
  minutes: number; 
  seconds: number
}
const CountDownTimer: React.FC<ICountDownTimerProps> = ({ expiryDate }) => {
    function calculateTimeLeft(): ITimeFormat {
        const difference = +new Date(expiryDate) - +new Date();
        let timeLeft = {} as ITimeFormat;
  
        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
  
        return timeLeft;
    }

  const [timeLeft, setTimeLeft] = useState<{ hours: number; minutes: number; seconds: number }>(calculateTimeLeft());


  useEffect(() => {
      let countdownInterval = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => {
          clearInterval(countdownInterval);
      };
  }, []);

  const formatTime = (time: number) => {
      return time < 10 ? `0${time}` : time;
  };

  return (
      <>
          {timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0 ? (
              <p>{`${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(timeLeft.seconds)}`}</p>
          ) : null}
      </>
  );
};

export default CountDownTimer