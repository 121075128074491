import React from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { nextButton } from "./assets";

export const NextBtnPlay = ({ className, onClick, slideCount, currentSlide ,slidesToShow}:any) => {

  return (
    <>
   { currentSlide != slideCount - slidesToShow &&
    <div className={className} onClick={onClick}>
      <img className="next__button" src={nextButton} alt="next" />
    </div>
   }
    </>
  );
};

export const NextBtnMain = ({ className, onClick }:any) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon style={{ color: "black", fontSize: "28px" }} />
    </div>
  );
};