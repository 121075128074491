import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  Input,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import Loader from "../../../components/src/Loader.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { Back_arrow } from "../../contactus/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { MainHeadingStyle } from "./AddMoney.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontSize: 24,
      fontWeight: 600,
      color: "#FFF",
    },
    h4:{
      fontFamily: 'Poppins',
        fontSize: '18px',
        color: '#ffffff',
        fontWeight:500,
        margin: 0,
        '@media(max-width:600px)': {
            fontSize: "14px",
        }
    },
  },
});

const AvailableBalanceBlock = styled(Box)({
  background:"#2d2d2d",
  padding:"40px 32px",
  borderRadius:8,
  width:"100%",
  position:"relative",
  '@media(max-width:600px)': {
    padding:"20px 10px",
  },
})

const ButtonStyle = styled(Button)({
    padding: '12px 80px',
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    "&:hover": {
      backgroundColor: '#ffb43a',
    },
    '@media(max-width:600px)': {
      padding:"5px 40px",
      fontSize: 14,
      borderRadius: 8,
    },
})
// Customizable Area End

import AccountDetailsController, {
    Props,
    configJSON,
  } from "./AccountDetailsController";

export default class AccountDetails extends AccountDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={this.state.loading ? webStyle.overFlow : webStyle.bgcolor}>
        <Loader loading={this.state.loading} />
        <ThemeProvider theme={theme}>
          
          <MainHeaderComp navigation= {this.props.navigation}/>
          <Container maxWidth={"lg"}>
            <Grid container style={webStyle.heading} spacing={3}>
              <Grid item
                xs={12}
                container
                alignItems="center"
                direction="row"
              >
                <img
                  style={webStyle.backArrow}
                  src={Back_arrow}
                  alt='backArrow'
                  data-test-id="back"
                  onClick={this.handleBackBtn}
                />
                <MainHeadingStyle id="contactText">{configJSON.accountDetails}</MainHeadingStyle>
              </Grid>
            </Grid>

            <Grid container style={webStyle.upperContainer}>
              <AvailableBalanceBlock>
                <Box style={webStyle.marginToBottom}>
                  <Typography variant="h4" gutterBottom id="modal-modal-title">
                    Account Number *
                  </Typography>
                  <Input
                    value={this.state.accountNumber}
                    style={webStyle.inputStyle}
                    data-test-id={"accountNumber"}
                    placeholder="Enter account number"
                    fullWidth={true}
                    disableUnderline={true}
                    type="tel"
                    onChange={(e) => this.onChangeAccountNumber(e)}
                  />
                  {this.state.accountNumberErr &&
                    <Typography variant="h4" gutterBottom id="modal-modal-title">
                      <span style={webStyle.errorMsg}>{this.state.accountNumberErr}</span>
                    </Typography>
                  }
                </Box>

                <Box style={webStyle.marginToBottom}>
                  <Typography variant="h4" gutterBottom id="modal-modal-title">
                    Confirm Account Number
                  </Typography>
                  <Input
                    value={this.state.confirmAccountNumber}
                    style={webStyle.inputStyle}
                    data-test-id={"confirmAccountNumber"}
                    placeholder="Re-enter account number"
                    fullWidth={true}
                    disableUnderline={true}
                    onChange={(e) => this.onChangeConfirmAccountNumber(e)}
                  />
                  {this.state.confirmAccountNumberErr &&
                    <Typography variant="h4" gutterBottom id="modal-modal-title">
                      <span style={webStyle.errorMsg}>{this.state.confirmAccountNumberErr}</span>
                    </Typography>
                  }
                </Box>

                <Box style={{position:"relative",...webStyle.marginToBottom}}>
                  <Typography variant="h4" gutterBottom id="modal-modal-title">
                    IFSC Code *
                  </Typography>
                  <Input
                    value={this.state.ifscCode}
                    style={webStyle.inputStyle}
                    data-test-id={"ifscCode"}
                    placeholder="Enter IFSC Code"
                    fullWidth={true}
                    disableUnderline={true}
                  onChange={(e) => this.onChangeIfscCode(e)}
                  />
                   {this.state.ifscCodeErr &&
                    <Typography variant="h4" gutterBottom id="modal-modal-title">
                      <span style={webStyle.errorMsg}>{this.state.ifscCodeErr}</span>
                    </Typography>
                  }
                  <Button
                    data-test-id="searchIfsc"
                    color="primary"
                    style={webStyle.ifscBtn}
                    onClick={() => this.onClickSaveChange(true)}
                  >
                    Search for IFSC
                  </Button>
                </Box>

                <Box style={webStyle.marginToBottom}>
                  <Typography variant="h4" gutterBottom id="modal-modal-title">
                    Account Holders Name *
                  </Typography>
                  <Input
                    value={this.state.accountHolderName}
                    style={webStyle.inputStyle}
                    data-test-id={"accountHolderName"}
                    placeholder="Enter Account Holders Name"
                    fullWidth={true}
                    disableUnderline={true}
                  onChange={(e) => this.onChangeAccountHolderName(e)}
                  />
                  {this.state.accountHolderNameErr &&
                    <Typography variant="h4" gutterBottom id="modal-modal-title">
                      <span style={webStyle.errorMsg}>{this.state.accountHolderNameErr}</span>
                    </Typography>
                  }
                </Box>

                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ButtonStyle
                    data-test-id="saveChanges"
                    color="primary"
                    onClick={() => this.onClickSaveChange(false)}
                  >
                    Save Changes
                  </ButtonStyle>
                </Box>
              </AvailableBalanceBlock>
            </Grid>
            
            <Dialog
              id="language"
              open={this.state.ifscPopupInfo.ifscPopup}
              PaperProps={{
                style: {
                  backgroundColor: "#404040",
                  color: "#fff",
                  padding: '0px 15px 25px',
                  maxWidth: "400px",
                  boxSizing: "border-box",
                  width:"95%"
                },
                elevation: 0,
              }}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              data-test-id="seat_dialog"
            >
              <DialogContent
                data-test-id="seat_number">
               
                <Typography variant="h4" style={{lineHeight:1.5}}>
                  Account Holder : {this.state.ifscPopupInfo.nameAtBank}<br/>
                  Bank Name : {this.state.ifscPopupInfo.bankName}<br/>
                  Account No : {this.state.accountNumber}<br/>
                  IFSC Code: {this.state.ifscCode}<br/>
                  Branch : {this.state.ifscPopupInfo.branch}<br/>
                </Typography>
              </DialogContent>
              <DialogActions >
                <Button
                  data-test-id="doneBtn"
                  color="primary"
                  style={{width: "100%", ...webStyle.doneBtn}}
                  onClick={() => this.setState({ifscPopupInfo:{ifscPopup:false}, loading:false})}
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
          <FooterWithEvents />
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  bgcolor: { 
    backgroundColor: "#121212"
  },
  overFlow:{
    overflow:"hidden",
    backgroundColor: "#121212"
  },
  upperContainer:{
    background:"#404040",
    borderRadius:"16px",
    marginTop:"32px",
    maxWidth:967
  },
  backArrow:{
    heigth:"32px",
    width:"32px",
    marginRight:10,
    cursor:"pointer"
  },
  heading: {
    marginTop: 30
  },
  rupies:{
    color:"#ffb636",
  },
  doneBtn: {
    padding: '12px 80px',
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  } as CSSProperties,
  ifscBtn:{
    color:"#ffb636",
    fontFamily: "Poppins",
    fontSize: 12,
    position:"absolute",
    right:10,
    top:40,
    textTransform:"none",
  }as CSSProperties,
  marginToBottom:{
    marginBottom:25
  },
  inputStyle:{
    color: "#ffffff",
    border: "1px solid #b4b4b4",
    padding: "16px",
    borderRadius: "12px",
    height:56,
    backgroundColor:"#404040",
    fontFamily: "Poppins",
   },
   errorMsg:{
    color: "red",
   }
}
// Customizable Area End
