import React, { CSSProperties } from "react";
import {
    Modal,
    Box,
    Button,
    Grid,
    styled,
    Paper,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Checkbox,
    FormControlLabel,
    withStyles,
    Hidden,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    createTheme,
    ThemeProvider,
} from "@material-ui/core";
import { WhatsApp, Google, MovieStart, Mail, EyeClose, EyeOpen, Mobile, indianFlag, checkbox, uncheck, checkboxicon, closeIcon, requestimg, buyimg } from "./assets"
import UserLogInController, { Props } from "./UserLoginController.web";
import OtpInput from 'react-otp-input';
import Carousel from 'react-material-ui-carousel';
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";
import UserPasswordForgot from "../../forgot-password/src/UserPasswordForgot.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { LoginTypes } from "../../../components/src/enums.web";
import Loader from "../../../components/src/Loader.web";

export const PlanPremiumIcon = ({ ...other }) => {
    return <img src={checkbox} alt="images" width="18px" />;
}

export const PlanPremiumIcon3 = ({ ...other }) => {
    return <img src={checkboxicon} alt="images" width="18px" />;
}

export const PlanPremiumIcon2 = ({ ...other }) => {
    return <img src={uncheck} alt="images" width="18px" />;
}

const theme = createTheme({
    typography: {
        h6: {
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#fff",
            fontSize: 24,
            textAlign: "center",
            marginTop: 20,
        },
    },
});

const WhiteBackgroundCheckbox = withStyles((theme) => ({
    root: {
        color: "black",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            width: 15,
            height: 15,
            position: "absolute",
            top: 4,
            zIndex: -1
        },
        "& .MuiSvgIcon-root": {
            color: "#33a720"
        }
    },
    checked: {}
}))(Checkbox);


const propsReactCodeInput = {
    inputStyle: {
        margin: '15px',
        border: 'none',
        width: '42px',
        borderRadius: '9px',
        backgroundColor: '#404040',
        fontSize: '13px',
        height: '42px',
        color: 'white',
        MozAppearance: 'textfield',
        textAlign: 'center'
    }
}



const otpProps: any = {
    inputStyle: {
        fontFamily: "Poppins",
        margin: "12px",
        MozAppearance: "textfield",
        height: "42px",
        width: "42px",
        fontSize: "13px",
        textAlign: "center",
        backgroundColor: '#626060',
        borderRadius: "6px"
    },
};

const sliderdata = [
    {
        "img": MovieStart,
        "title": "ITO - Movies",
        "para": "At Ticket Xchange you can apply under ITO for select movies and have the chance of watching your favourite Cinema in the first weekend at a discounted price."
    },
    {
        "img": buyimg,
        "title": "Buy - Sell",
        "para": "At Ticket Xchange you can conveniently buy or sell tickets in the simplest manner."
    },
    {
        "img": requestimg,
        "title": "Request",
        "para": "At Ticket Xchange you can request to buy the tickets for the seats of your choice."
    }

]




const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    background: 'none',
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

const Formlabel = styled(FormControlLabel)(({ theme }) => ({
    '& label': { fontSize: '12px' }
}))


const LoginBtn: any = styled(Button)(({ theme }) => ({
    width: '270px',
    height: '42px',
    borderRadius: '9px',
    backgroundColor: '#404040',
    margin: '24px 72px 0 72px',
    '&:hover': {
        background: '#404040',
    },
    justifyContent: 'start',
    color: 'white',
    textTransform: 'full-size-kana',
    fontFamily: "Poppins"
}));

const Loginbtn: any = styled(Button)(({ theme }) => ({
    color: '#202020',
    backgroundColor: '#ffb43a',
    width: '294px',
    padding: '9px 28px 9px 12px',
    borderRadius: '9px',
    height: '42px',
    margin: '9px 72px 0 72px',
    '&:hover': {
        background: '#ffb43a',
    },
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: "Poppins"
}));


export default class UserLogin extends UserLogInController {
    constructor(props: Props) {
        super(props);
    }



    handleloginwith = () => {
        return (this.state.logInwith && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item style={WebStyle.paperRemoveBoxShadow}>
                <div>
                    <div>
                        <Box component={"span"} sx={WebStyle.rightsidetoptxt}>Login</Box>
                    </div>

                    <Box component={"div"} display='flex' flexDirection='column'>
                        <LoginBtn id='phone' data-test-id="proceed-with-mobile" onClick={() => this.handleLoginWith('sms_account')}><img src={Mobile} alt="Mobile logo" style={WebStyle.mobile_icon} /><Box component={"span"} sx={WebStyle.font_login}>Proceed with Mobile Number</Box></LoginBtn>
                        <LoginBtn id='google' onClick={() => this.handleLoginWith(LoginTypes.Google)}><img src={Google} alt="Google logo" style={WebStyle.google_icon as any} /><Box component={"span"} sx={WebStyle.font_login}>Proceed with Google</Box></LoginBtn>
                        <LoginBtn id='email' onClick={() => this.handleLoginWith(this.state.activeButton)}><img src={Mail} alt="Mail logo" style={WebStyle.gmail_icon} /><Box component={"span"} sx={WebStyle.font_login}>Proceed with Email</Box></LoginBtn>
                    </Box>
                    <div style={WebStyle.or_div}>
                        <div style={WebStyle.orLeftLine}></div>
                        <span style={WebStyle.or_word}>OR</span>
                        <div style={WebStyle.orRightLine}></div>
                    </div>
                    <Box component={"div"} mt="15px">
                        <span style={WebStyle.alreadyAccountText}>Don't have an account? <span onClick={this.handleToSignup} style={WebStyle.switchLoginText}>Sign Up</span></span>
                    </Box>
                </div>
            </Item>
        </Grid>)

    }

    handlepasswordlogin = () => {
        return (this.state.withPassword &&
            <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
                <Item style={WebStyle.paperRemoveBoxShadow}>
                    <div>
                        <Box component={"span"} sx={WebStyle.rightsidetoptxt}>Login</Box>
                    </div>
                    <Grid container style={WebStyle.grid_container}>
                        <Grid item xs={5}>
                            <Button id='withotp' onClick={() => this.handleLoginWith('OTP')} style={{ width: '100%', padding: '6px 0px', border: '1px solid #ffb43a', ...(this.state.activeButton === 'OTP' ? WebStyle.custom_button_active : WebStyle.custom_button) }}
                            ><span style={{ textTransform: "capitalize", fontFamily: "Poppins" }}>OTP Login</span></Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button id='withpassword' onClick={() => this.handleLoginWith('Password')} style={{ width: '100%', padding: '6px 0px', border: '1px solid #ffb43a', ...(this.state.activeButton === 'Password' ? WebStyle.custom_button_active1 : WebStyle.custom_button1) }}
                            ><span style={{ textTransform: "capitalize" }}>Password Login</span></Button>
                        </Grid>
                    </Grid>
                    <Box component={"div"} sx={WebStyle.mainDiv}>
                        <label style={WebStyle.emailtext} data-test-id="email-label">Email Id</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                id="emailInput"
                                type="text"
                                name="email"
                                onBlur={() => this.validateEmail()}
                                onChange={(event) => this.handleEmailValue(event.target.value)}
                                placeholder="Enter email id"
                                value={this.state.emailAddress}
                            />
                        </FormControl>
                        <span style={WebStyle.formerror}>
                            {this.renderErrorMessageforProfile(this.state.emailAddressError)}
                        </span>                        <label style={WebStyle.emailtext} data-test-id="email-label">Password</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                id="PasswordInput"
                                type={this.state.isShowPassword ? "text" : "password"}
                                name="password"
                                onBlur={() => this.handleValidatePassword()}
                                placeholder="Enter password"
                                onChange={(event) => this.handlePasswordValue(event.target.value)}
                                value={this.state.password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={this.showPasswordChange}
                                            aria-label="toggle password visibility" edge="end">
                                            <img
                                                alt="type"
                                                src={this.state.isShowPassword ? EyeClose : EyeOpen}
                                                style={WebStyle.eye}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <span style={WebStyle.passwordError}>
                            {this.renderErrorMessageforProfile(this.state.passwordError)}
                        </span>
                        <Box display='flex' justifyContent='space-between' alignItems='center' m='0 66px'>
                            <div style={{ marginLeft: "-10px" }}>
                                <Formlabel
                                    control={
                                        <Checkbox
                                            id="checkbox1"
                                            size="small"
                                            checkedIcon={<PlanPremiumIcon3 />}
                                            icon={<PlanPremiumIcon2 />}
                                            checked={this.state.rememberMe}
                                            onChange={(e) => this.handleRememberMeChange(e)}
                                            style={{
                                                color: this.state.rememberMe ? '#ffb43a' : '#808080',
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "10px"
                                            }}
                                        />
                                    }
                                    label={<span style={{ fontSize: "12px", color: "#fff", fontFamily: "Poppins" }}>Remember me</span>}
                                />

                            </div>
                            <div style={{ marginRight: "-10px" }}>
                                <span onClick={this.handleToForgot} style={WebStyle.forgotText}>Forgot Password?</span>
                                {this.state.toForgot && <UserPasswordForgot navigation={undefined} id={""} ToLogin={undefined} showSignup={undefined} />}
                            </div>
                        </Box>
                    </Box>
                    <Box component={"div"} mt='5px' style={{ marginLeft: "-5px" }}>
                        <WhiteBackgroundCheckbox
                            checked={this.state.isChecked}
                            id='checkbox'
                            checkedIcon={<PlanPremiumIcon />}
                            icon={<PlanPremiumIcon2 />}
                            onChange={this.handleCheckChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            size="small" />
                        <span style={WebStyle.terms}>I agree to the &nbsp;<span id="termsConditionLink" onClick={this.handleOnClickTermsAndCondition} style={WebStyle.forgot}>Terms &amp; Conditions&nbsp;</span ><span style={WebStyle.terms}>and</span><span id="privacyPolicyLink" onClick={this.handleOnClickPrivacyPolicy} style={WebStyle.forgot}>&nbsp;Privacy Policy</span></span>
                    </Box>
                    {this.state.isChecked ? null : (
                        <span style={WebStyle.termsformerror}>Please accept Terms &amp; Conditions and Privacy Policy.</span>
                    )}
                    {this.state.notRegUserError &&
                        <span style={WebStyle.termsformerror}>{this.state.notRegUserError}</span>
                    }
                    <Box component={"div"} mt='0px'>
                        <Loginbtn type='submit' id='login-button' onClick={this.handleSave}>Login</Loginbtn>
                    </Box>
                    <Box component={"div"} sx={WebStyle.or_div}>
                        <Box component={"div"} sx={WebStyle.orLeftLine}></Box>
                        <span style={WebStyle.or_word}>OR</span>
                        <Box component={"div"} sx={WebStyle.orRightLine}></Box>
                    </Box>
                    <Box component={"div"} mt="15px">
                        <span style={WebStyle.alreadyAccountText}>Don't have an account? <span onClick={this.handleToSignup} style={WebStyle.switchLoginText}>Sign Up</span></span>
                        {this.state.ToSignUp && <UserSignUp navigation={undefined} id={""} showSignup={undefined} />}
                    </Box>
                </Item>
            </Grid>
        )
    }

    handleotpemailscreen = () => {
        return (this.state.emailOtpScreen &&
            <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>

                <Item style={WebStyle.paperRemoveBoxShadow}>
                    <div>
                        <Box component={"span"} sx={WebStyle.rightsidetoptxt}>Login</Box>
                    </div>
                    <Grid container style={WebStyle.grid_container}>
                        <Grid item xs={5}>
                            <Button id='withotplogin' onClick={() => this.handleLoginWith('OTP')} style={{ width: '100%', padding: '6px 0px', border: '1px solid #ffb43a', ...(this.state.activeButton === 'OTP' ? WebStyle.custom_button_active : WebStyle.custom_button) }}><span style={{ textTransform: "capitalize", fontFamily: "Poppins" }}>OTP Login</span></Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button id='withpasswordlogin' disabled={this.state.isRequestingOTP} onClick={() => this.handleLoginWith('Password')} style={{ width: '100%', padding: '6px 0px', border: '1px solid #ffb43a', ...(this.state.activeButton === 'Password' ? WebStyle.custom_button_active1 : WebStyle.custom_button1) }}
                            ><span style={{ textTransform: "capitalize", fontFamily: "Poppins" }}>Password Login</span></Button>
                        </Grid>
                    </Grid>
                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' >
                        <Box component={"div"} sx={WebStyle.enterEmailHeader}>
                            <span>Enter Your Email Id</span>
                        </Box>
                        <Box component={"div"} sx={WebStyle.digitfoure}>
                            <span>Enter your Registered Email Id below to receive 4-digit Verification code</span>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column'>
                        <label style={WebStyle.emailtext} data-test-id="email-label">Email Id</label>
                        <FormControl variant="outlined">
                            <OutlinedInput
                                style={WebStyle.inputbox}
                                type="text"
                                data-test-id="mail"
                                onBlur={() => this.handleValidateEmail()}
                                placeholder="Enter email id"
                                value={this.state.otpEmail}
                                name="email"
                                onChange={(event) => this.handleotpEmailChange(event.target.value)}
                            />
                        </FormControl>
                        {this.state.otpEmailError ? <span style={WebStyle.formerror}>Enter a valid Email id</span> : null}
                    </Box>
                    <Box component={"div"} mt='94px' style={{ marginLeft: "-5px" }}>
                        <WhiteBackgroundCheckbox
                            id='checkbox'
                            checkedIcon={<PlanPremiumIcon />}
                            icon={<PlanPremiumIcon2 />}
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={this.state.isChecked}
                            onChange={this.handleCheckChange}
                            size="small" />
                        <span style={WebStyle.terms}>I agree to the &nbsp;<span onClick={this.handleOnClickTermsAndCondition} style={WebStyle.forgot}>Terms &amp; Conditions&nbsp;</span ><span style={WebStyle.terms}>and</span><span onClick={this.handleOnClickPrivacyPolicy} style={WebStyle.forgot}>&nbsp;Privacy Policy</span></span>
                    </Box>
                    {this.state.isChecked ? null : (
                        <span style={WebStyle.termsformerror}>Please accept Terms &amp; Conditions and Privacy Policy.</span>
                    )}
                    <Box component={"div"} mt='0px'>
                        <Loginbtn type='submit' onClick={this.handleLogIn} >Request OTP</Loginbtn>
                    </Box>
                    <Box component={"div"} sx={WebStyle.or_div}>
                        <Box component={"div"} sx={WebStyle.orLeftLine}></Box>
                        <span style={WebStyle.or_word}>OR</span>
                        <Box component={"div"} sx={WebStyle.orRightLine}></Box>
                    </Box>
                    <Box component={"div"} mt="15px">
                        <span style={WebStyle.alreadyAccountText}>Don't have an account? <span onClick={this.handleToSignup} style={WebStyle.switchLoginText}>Sign Up</span></span>
                        {this.state.ToSignUp && <UserSignUp navigation={undefined} id={""} showSignup={undefined} />}
                    </Box>
                </Item>
            </Grid>
        )
    }



    handleotpverify = () => {
        return (this.state.emailOtpVerify && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item style={WebStyle.paperRemoveBoxShadow}>
                <Box component={"div"} sx={WebStyle.mainheader}>
                    <span>OTP</span>
                </Box>

                <Box component={"div"} sx={WebStyle.verifyPhoneText}>
                    <span>Verify your Email Id</span>
                </Box>
                <Box component={"div"} sx={WebStyle.digitfour}>
                    <span>A Verification Code has been sent to {this.state.otpEmail}</span>
                </Box>
                <div style={WebStyle.otp_box}>
                    <OtpInput
                        value={this.state.emailOtp ? this.state.emailOtp : ''}
                        data-test-id="otpInput"
                        inputType="tel"
                        onChange={(value) => this.handleEmailOtpValue(value)}
                        inputStyle={{
                            margin: '15px',
                            border: 'none',
                            width: '42px',
                            borderRadius: '9px',
                            backgroundColor: '#404040',
                            fontSize: '13px',
                            height: '42px',
                            color: 'white',
                            MozAppearance: 'textfield',
                            textAlign: 'center'
                        }}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                    />
                </div>
                <div>
                    <HandleOtpError minutes={this.state.minutes}
                        seconds={this.state.seconds}
                        otpError={this.state.otpError} />
                </div>

                <Box mt='158px'>
                    <Box component={"div"} mt="15px">
                        <span style={{ color: '#ffffff' }}>Didn't receive a Code? <span id='resend' onClick={this.verfiedEmailId} style={{ color: '#f8b43a', textDecoration: 'none', cursor: 'pointer' }}>Resend OTP</span></span>
                    </Box>
                </Box>
                <Box component={"div"} mt='15px'>
                    <Loginbtn type='submit' onClick={this.handleEmailOTP}>Verify</Loginbtn>
                </Box>

            </Item>
        </Grid>)
    }

    handlephonelogin = () => {
        return (this.state.sms_account && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item data-test-id="login-phone-number-wrapper" style={WebStyle.paperRemoveBoxShadow}>
                <Box component={"div"} sx={WebStyle.mainheader}>
                    <span >Login</span>
                </Box>

                <Box component={"div"} sx={WebStyle.loginWithNumberText}>
                    <span>Login with Mobile Number</span>
                </Box>
                <Box component={"div"} sx={WebStyle.digitfour}>
                    <span >You will receive a 4-digit Verification Code on your Mobile Number.</span>
                </Box>
                <div>
                    <label style={WebStyle.mobileLabel} data-test-id="email-label">Mobile Number</label>
                    <OutlinedInput
                        value={this.state.phone}
                        onChange={(e) => this.handlePhoneChange(e.target.value)}
                        placeholder="Enter mobile number"
                        style={WebStyle.outLineInput}
                        data-test-id="mobileNumber"
                        name="phoneNumber"
                        type="tel"
                        inputProps={{
                            style: { fontSize: '12px' },
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    style={WebStyle.countryCode}
                                    id='countrycode'
                                >
                                    <img src={indianFlag} alt="indian" style={{ height: '11px', marginRight: "5px" }} />
                                    <span
                                        style={{
                                            color: "#ffffff",
                                            fontSize: "10px",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        +91
                                    </span>
                                    <KeyboardArrowDownIcon style={{ color: '#ffb43a' }} />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {this.state.phoneError && <span data-test-id="login-mobile-error" style={WebStyle.phoneError} id="phoneErr">{this.state.phoneErr}</span>}

                </div>
                <Box component={"div"} mt='130px' style={{ marginLeft: "-5px" }}>
                    <WhiteBackgroundCheckbox
                        id='checkbox'
                        inputProps={{ 'aria-label': 'controlled' }}
                        checkedIcon={<PlanPremiumIcon />}
                        icon={<PlanPremiumIcon2 />}
                        checked={this.state.isChecked}
                        onChange={this.handleCheckChange}
                        size="small" />
                    <span style={WebStyle.terms}>I agree to the &nbsp;<span onClick={this.handleOnClickTermsAndCondition} style={WebStyle.forgot}>Terms &amp; Conditions&nbsp;</span ><span style={WebStyle.terms}>and</span><span onClick={this.handleOnClickPrivacyPolicy} style={WebStyle.forgot}>&nbsp;Privacy Policy</span></span>
                </Box>
                {this.state.isChecked ? null : (
                    <span style={WebStyle.termsformerror}>Please accept Terms &amp; Conditions and Privacy Policy.</span>
                )}
                <div>
                    <Loginbtn data-test-id="mobile-login-btn" type='submit' onClick={this.handleLogIn} >Login</Loginbtn>
                </div>
                <div style={WebStyle.or_div}>
                    <Box component={"div"} sx={WebStyle.orLeftLine}></Box>
                    <span style={WebStyle.or_word}>OR</span>
                    <Box component={"div"} sx={WebStyle.orRightLine}></Box>
                </div>
                <Box component={"div"} mt="15px">
                    <span style={WebStyle.alreadyAccountText}>Don't have an account? <span onClick={this.handleToSignup} style={WebStyle.switchLoginText}>Sign Up</span></span>
                    {this.state.ToSignUp && <UserSignUp navigation={undefined} id={""} showSignup={undefined} />}
                </Box>
            </Item>
        </Grid>)
    }

    handlemobileotpverify = () => {
        return (this.state.mobileOtpVerify && <Grid item xs={12} md={6} sm={12} style={WebStyle.gridFixCenter}>
            <Item style={WebStyle.paperRemoveBoxShadow}>
                <Box component={"div"} sx={WebStyle.mainheader}>
                    <span>OTP</span>
                </Box>
                <Box component={"div"} sx={WebStyle.verifyPhoneText}>
                    <span>Verify your Mobile Number</span>
                </Box>
                <Box component={"div"} sx={WebStyle.digitfour}>
                    <span>Enter 4 digit code sent on {`+91${this.state.phone}`}</span>
                </Box>
                <Box component={"div"} mt='15px' display='flex' gridGap='4px' justifyContent='center'>
                    <OtpInput
                        data-test-id="otpInputs"
                        value={this.state.mobileOtp ? this.state.mobileOtp : ''}
                        onChange={(value) => this.handleMobileOtpValue(value)}
                        numInputs={4}
                        inputStyle={{
                            margin: '15px',
                            border: 'none',
                            width: '42px',
                            borderRadius: '9px',
                            backgroundColor: '#404040',
                            fontSize: '13px',
                            height: '42px',
                            color: 'white',
                            MozAppearance: 'textfield',
                            textAlign: 'center',
                        }}
                        shouldAutoFocus={false}
                        containerStyle={{ display: 'flex', justifyContent: 'center' }}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                            />
                        )}


                    />
                </Box>
                <div>
                    <HandleOtpError minutes={this.state.minutes}
                        seconds={this.state.seconds}
                        data-test-id="otp-wrapper"
                        otpError={this.state.otpError} />
                </div>
                <Box component={"div"} mt='158px'>
                    <Box component={"div"} mt="15px">
                        <span style={WebStyle.alreadyAccountText}>Didn't receive a Code? <span onClick={this.handleResenddOtp} data-test-id="resend" style={WebStyle.switchLoginText}>Resend OTP</span></span>
                    </Box>
                </Box>
                <Box component={"div"} mt='15px'>
                    <Loginbtn type='submit' onClick={this.handleMobileOTP}>Verify</Loginbtn>
                </Box>
            </Item>
        </Grid>)
    }

    render() {
        return (
            <div>
                <ThemeProvider theme={theme}>
                    {!this.state.isTermsConditionPrivacyPolicy ?
                        <Modal
                            open={this.state.modelOpen}
                            onClose={this.handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <Box sx={WebStyle.modal_style}>
                                <Hidden smUp>
                                    <img onClick={this.handleClose} src={closeIcon} alt="closeIcon" style={{ width: '30px', height: '30px', position: 'absolute', top: '74px', right: '12px' }} />
                                </Hidden>
                                <Box component={"div"} justifyContent="space-between" display='flex'>
                                    <Hidden xsDown>
                                        <Grid item md={6} sm={6} style={WebStyle.gridFixCenter}>
                                            <Item style={WebStyle.paperRemoveBoxShadow}>
                                                <div>
                                                    <Carousel
                                                        navButtonsAlwaysInvisible={true}
                                                        IndicatorIcon={<Button></Button>}
                                                        animation="slide"
                                                        indicatorIconButtonProps={{
                                                            style: WebStyle.indicatorIconButtonProps
                                                        }}
                                                        activeIndicatorIconButtonProps={{
                                                            style: WebStyle.activeIndicatorIconButtonProps
                                                        }}
                                                    >
                                                        {sliderdata.map((data, index) => {
                                                            return (
                                                                <Box
                                                                    component={"div"}
                                                                    key={index}
                                                                    justifyContent='center'
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    alignItems='center'
                                                                >
                                                                    <div>
                                                                        <img src={data.img} alt="df" style={WebStyle.carousel_img} />
                                                                    </div>
                                                                    <div>
                                                                        <Box component={"span"} sx={WebStyle.cTitle}>{data.title}</Box>
                                                                        <Box component={"p"} sx={WebStyle.cPara}>{data.para}</Box>
                                                                    </div>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Carousel>

                                                </div>
                                            </Item>
                                        </Grid>
                                        <hr style={WebStyle.divider}></hr>
                                    </Hidden>
                                    {this.handleloginwith()}

                                    {this.handlepasswordlogin()}

                                    {this.handleotpemailscreen()}

                                    {this.handleotpverify()}

                                    {this.handlephonelogin()}

                                    {this.handlemobileotpverify()}
                                </Box>
                            </Box>
                        </Modal> :
                        <Dialog
                            id="terms"
                            data-test-id="dialog-wrapper"
                            open={true}
                            PaperProps={{
                                style: WebStyle.terms_Condition_modal_style
                            }}
                            keepMounted
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Loader loading={this.state.loading} />
                            <Grid style={{ display: "flex", justifyContent: "end" }}>
                                <img id="closedialogebtn" onClick={this.handleOnCloseTermsConditionPrivacyPolicy} src={closeIcon} alt="closeIcon" style={{ height: '30px', width: '30px', position: 'absolute', top: '10px', right: '12px' }} />
                            </Grid>
                            <DialogTitle
                                id="alert-dialog-slide-title"
                            >
                                {this.state.termsConditionPrivacyPolicyData.heading}
                            </DialogTitle>
                            <Divider style={WebStyle.customDivider} />
                            <DialogContent
                                id="alert-dialog-slide-description"
                                data-test-id="description"
                                style={WebStyle.termsConditionDescription}
                                dangerouslySetInnerHTML={{ __html: this.state.termsConditionPrivacyPolicyData.description }}
                            >
                            </DialogContent>
                        </Dialog>}
                </ThemeProvider>
            </div >
        );
    }
}
// Customizable Area Start
const WebStyle = {
    paperRemoveBoxShadow: {
        boxShadow: "none"
    },
    mainDiv: { marginBottom: "52px", display: 'flex', flexDirection: 'column' },
    orLeftLine: { backgroundColor: '#3e3e3e', width: '119px', height: '1px', margin: '7px 0 7px 3px' },
    orRightLine: { width: '119px', margin: '7px 0 7px 3px', height: '1px', backgroundColor: '#3e3e3e' },
    mobileLabel: { fontSize: "14px", color: "rgb(255, 255, 255)", display: "flex", marginBottom: '12px', fontFamily: "Poppins" },
    forgotText: { color: 'white', fontSize: '12px', fontWeight: 400, lineHeight: 1.5, letterSpacing: '0.00938em', cursor: 'pointer', fontFamily: "Poppins" },
    outLineInput: { borderRadius: "12px", border: "1px solid grey", backgroundColor: '#404040', color: "#ffffff", width: '295px', height: '43px', fontFamily: "Poppins", },
    loginWithNumberText: {
        height: "15px",
        margin: "28px 24px 12px 23px",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1px",
        letterSpacing: "1px",
        textAlign: "center",
        color: " #fff"
    },
    mainheader: {
        width: "93px",
        height: "34px",
        margin: "0 88px 24px 90px",
        fontSize: "24px",
        fontWeight: 600,
        color: "#fff"
    },
    countryCode: {
        width: "67px",
        borderRight: "1px solid #979797",
        height: "36px",
        cursor: "pointer",
        borderRadius: "0%",
        justifyContent: "center",
        fontSize: '10px'
    },
    divider: {
        color: "#3e3e3e",
    },
    phoneError: {
        color: 'red', fontFamily: "Poppins",
        display: 'flex', fontSize: '10px', marginTop: "10px"
    },
    checkbox_css: {
        width: '13px', height: '14px', color: '#33a720'
    },
    otpError: { fontSize: '12px', color: 'red' },
    otptimer: { marginTop: '4px', color: '#ffffff' },
    otp_box: {
        display: 'flex', marginTop: '15px', justifyContent: 'center', gap: '4px'
    },
    indicatorIconButtonProps: {
        width: "4px",
        backgroundColor: "#ffb53a",
        borderRadius: "3px",
        height: "4px",
        margin: "0 9px",
    },
    activeIndicatorIconButtonProps: {
        borderRadius: "3px",
        backgroundColor: '#ffb53a',
        height: "4px",
        width: "21px",
    },
    verifyPhoneText: {
        height: "15px",
        margin: "24px 24px 12px 9px",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "1px",
        letterSpacing: "1px",
        textAlign: "center",
        color: "#fff"
    },
    forgot: {
        color: '#f8b43a', fontSize: '10px', textDecoration: 'underline', cursor: "pointer"
    },
    terms: {
        color: '#b4b4b4', fontSize: '10px', fontFamily: "Poppins"
    },
    eye: {
        color: '#f8b43a', height: "13px", width: "18px"
    },
    grid_container: { width: '100%', display: 'flex', justifyContent: 'center', margin: '10px 0 0 0' },
    gridFixCenter: { display: 'flex', justifyContent: 'center' },
    switchLoginText: { color: '#f8b43a', fontSize: "12px", textDecoration: 'none', cursor: 'pointer', fontFamily: "Poppins" },
    alreadyAccountText: { color: '#ffffff', fontSize: "12px", fontFamily: "Poppins" },
    hor_line: {
        backgroundColor: '#3e3e3e',
        width: '120px',
        height: '1px',
        margin: '8px 0 7px 4px',
    },
    font_login: {
        fontSize: '13px',
        fontFamily: "Poppins",
        textTransform: "capitalize"
    },
    custom_button_active: {
        backgroundColor: '#ffb43a',
        color: 'black',
        marginLeft: '15px',
        zIndex: '1',
        borderRadius: '12px'
    },
    custom_button_active1: {
        backgroundColor: '#ffb43a',
        color: 'black',
        marginLeft: '-15px',
        zIndex: '1',
        borderRadius: '12px'
    },
    custom_button: {
        backgroundColor: '#2f2617',
        color: 'white',
        borderRadius: '12px',
        marginLeft: '10px',
    },
    custom_button1: {
        backgroundColor: '#2f2617',
        color: 'white',
        borderRadius: '12px',
        marginLeft: '-10px',

    },
    cTitle: {
        width: "112px",
        height: "24px",
        margin: "54px 88px 10px",
        fontSize: "18px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#fff",
        fontFamily: "Poppins"
    },
    carousel_img: {
        height: "182px",
        width: "197px",
        margin: "0 32px 54px",
    },
    gmail_icon: {
        height: "18px",
        width: "24px",
        margin: "1px 12px 0 0",
    },
    mobile_icon: {
        width: "24px",
        height: "24px",
        margin: "0 12px 0 0",
    },
    whatapp_icon: {
        width: "24px",
        height: "24px",
        margin: "0 12px 0 0",
    },
    google_icon: {
        width: "24px",
        height: "24px",
        margin: "0 12px 0 0",
        objectFit: "contain",
    },
    enterEmailHeader: {
        width: "246px",
        height: "15px",
        margin: "38px 24px 12px 9px",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1px",
        letterSpacing: "1px",
        textAlign: "center",
        color: "#fff",
    },
    or_word: {
        color: "#8f9bb3",
        fontSize: "11px",
        padding: "0px 3px",
        fontFamily: "Poppins"
    },
    or_div: {
        margin: "20px 72px 0 72px",
        display: "flex",
        alignItems: "center"
    },
    termsformerror: {
        color: "red",
        display: "flex",
        fontSize: "10px",
        fontFamily: "Poppins"
    },
    formerror: {
        color: "red",
        display: "flex",
        marginTop: "9px",
        fontSize: "12px",
        marginLeft: "57px",
        textAlign: "initial" as const,
    },
    passwordError: {
        display: "block",
        maxWidth: "287px",
        color: "red",
        textAlign: "initial" as const,
        fontSize: "12px",
        margin: "9px 57px 0",
        fontFamily: "Poppins",
    },
    digitfoure: {
        width: "246px",
        height: "34px",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#929292",
        fontFamily: "Poppins"
    },
    digitfour: {
        width: "246px",
        height: "34px",
        margin: "0 0 24px 9px",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#929292",
        fontFamily: "Poppins"
    },
    rightsidetoptxt: {
        width: "93px",
        height: "34px",
        margin: "0 88px 24px 90px",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: "600",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#fff",
    },
    cPara: {
        width: "290px",
        height: "69px",
        margin: "10px 0 24px",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#fff",
        fontFamily: "Poppins",
    },
    modal_style: {
        boxSizing: "content-box" as const,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "500px",
        width: "800px",
        padding: "67px 0px",
        borderRadius: "12px",
        backgroundColor: "#121212",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '@media (min-width: 320px) and (max-width: 768px)': {
            height: "100vh",
            width: "100vw"
        }
    },
    emailtext: {
        fontSize: "13px",
        color: "#ffffff",
        margin: "15px 37px 0 57px",
        display: 'flex',
        fontFamily: "Poppins"
    },
    inputbox: {
        width: "295px",
        height: "42px",
        margin: "9px 54px 0 54px",
        border: "solid 1px #b4b4b4",
        borderRadius: "9px",
        backgroundColor: "#404040",
        color: '#ffffff',
        fontSize: "12px",
    },
    detail: {
        display: "flex",
        justifyContent: "center",
        width: "50%",
        flexDirection: "column",
        marginLeft: "27%",
    },
    Otpinputbox: {
        backgroundColor: '#626060',
        height: '42px',
        color: '#ffffff',
        width: '42px',
        alignSelf: "center",
        justifyContent: "center",
        display: "flex",
    },
    forgottext: {
        color: "#3A00E5",
        fontSize: 16,
        paddingRight: "104px",
        textAlign: "end",
        marginTop: "8px"
    },
    terms_Condition_modal_style: {
        backgroundColor: "#404040",
        color: "white",
        height: "825px",
        width: "575px",
        borderRadius: 8,
    },
    customDivider: {
        backgroundColor: "#979797"
    },
    termsConditionDescription: {
        fontFamily: "Poppins",
        color: "#fff",
        fontSize: 16,
        textAlign: "justify",
    } as CSSProperties,
}

const HandleOtpError = ({ minutes, seconds, otpError }: any) => {

    if (minutes === 0 && seconds === 0) {
        return (
            <Box component={'div'} textAlign="start" width="248px">
                <span style={WebStyle.otpError}>OTP expired </span>
            </Box>
        )
    } else if (otpError) {
        return (
            <Box component={'div'} width="248px" textAlign="start">
                <span style={WebStyle.otpError}>{otpError}
                </span>
                <Box component={'div'} textAlign="start" width="248px">
                    <span style={WebStyle.otptimer}>OTP expires in
                        <b>{` ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`} </b>
                        seconds</span>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box component={'div'} textAlign="start" width="248px">
                <span style={WebStyle.otptimer}>OTP expires in
                    <b>{` ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`} </b>
                    seconds</span>
            </Box>
        )
    }

}
// Customizable Area End