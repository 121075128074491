import React from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    Dialog,
    Button,
    Slider,
    TextField,
    Chip
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import {
    close,
    starFilled,
    starOutline,
} from "./assets"
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";

const configJSON = require("./config");

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            // primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const RFHButton = styled(Button)({
    color: "#000",
    backgroundColor: "#ffb43a",
    maxWidth: "386px",
    padding: "12px",
    maxHeight: "40px",
    borderRadius: "12px",
    fontSize: "14px",
    fontWeight: 600,
    "&:hover": {
        backgroundColor: "#ffb43a",
        color: "#000"
    }
})

const RFHChip = styled(Chip)({
    boxSizing: "border-box",
    fontSize: "12px",
    fontFamily: "Poppins",
    borderRadius: "10px",
    padding: "8px 0",
    lineHeight: 1.25,
    letterSpacing: 0.07,
    cursor: "pointer",
    backgroundColor: '#ffb53a',
    color: "#000",
    borderColor: "#ffb43a",
    "&.MuiChip-outlined": {
        color: "#FFF",
        backgroundColor: "#2f2617",
        border: "solid 1px #b4b4b4",
        '&:active': {
            color: "#FFF",
            backgroundColor: "#2f2617",
            border: "solid 1px #b4b4b4",
        },
        '&:focus': {
            color: "#FFF",
            backgroundColor: "#2f2617",
            border: "solid 1px #b4b4b4",
        },
        '&:hover': {
            color: "#FFF",
            backgroundColor: "#2f2617",
            border: "solid 1px #b4b4b4",
        }
    },
    '&:active': {
        backgroundColor: '#ffb53a',
        color: "#000",
        borderColor: "#ffb43a",
    },
    '&:focus': {
        color: "#000",
        backgroundColor: '#ffb53a',
        borderColor: "#ffb43a",
    },
    '&:hover': {
        color: "#000",
        backgroundColor: '#ffb53a',
        borderColor: "#ffb43a",
    },
})
type HashTag = {
    id: string;
    attributes: {
        id: string;
        name: string;
    }

}

// Customizable Area End
import RatingController, { Props } from "./RatingController.web";


export class Ratings extends RatingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { sliderValue, isModalOpens } = this.state

        return (
            // Customizable Area Start
            <>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box style={this.props.ratingsData?.boxRatingTitle}>{this.props.ratingsData?.buttonLabel}</Box>
                    <Box style={this.props.ratingsData?.boxRatingSubTitle}>{this.props.ratingsData?.labelFeelFree}</Box>
                </Box>
                <Box style={this.props.ratingsData?.buttonStyle} data-test-id="ratingModal" onClick={() => this.handleOpenDialog()} >
                    {this.props.ratingsData?.buttonText}
                </Box>

                <DialogWrapper
                    id="language"
                    open={isModalOpens}
                    PaperProps={{
                        className: "dialog__paper__wrapper"
                    }}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    data-test-id="ModalOpen"
                >

                    <img
                        className="close__button"
                        src={close}
                        alt="closebutton"
                        id="closeLanguage"
                        data-test-id="ModalClose"
                        onClick={() => this.handleCloseModal()}
                    />

                    <Box className="dialog__content__wrapper">

                        <Box className="poster__wrapper">
                            <img className="poster__image" src={this.props.ratingsData?.bannerImage} alt="poster-image" />
                        </Box>

                        <Box className="form__wrapper">
                            <Typography className="form__title">
                                {configJSON.ratingTitle}
                            </Typography>
                            <Typography className="movie__name">
                                {this.props.ratingsData?.movieName?.name}
                            </Typography>
                            <Typography className="experience__label">
                                {configJSON.experienceTitle}
                            </Typography>
                            <Box className="star__wrapper">
                                {
                                    Array.from<number>({ length: 5 }).map((_, index) => (
                                        <img
                                            key={index}
                                            src={sliderValue - 1 >= index ? starFilled : starOutline}
                                            className="star"
                                            alt="star"
                                        />
                                    ))
                                }
                            </Box>
                            <Box className="slider__wrapper">
                                <Slider
                                    className="slider__container"
                                    ThumbComponent={(props) => (
                                        <img
                                            {...props}
                                            alt={`Image ${sliderValue}`}
                                            data-test-id='image'
                                            id="rating-img"
                                            src={this.getImage(sliderValue)}
                                        />
                                    )}
                                    value={sliderValue}
                                    data-test-id="sliders"
                                    onChange={this.handleSliderChange}
                                    min={0}
                                    max={6}
                                    step={1}
                                    aria-labelledby="slider-label"
                                />
                            </Box>
                            <Typography className="error__message">{this.state.errorMessage}</Typography>
                            <Box className="textarea__wrapper">
                                <Typography className="field__label">{configJSON.formTitle}</Typography>
                                <TextField
                                    fullWidth
                                    className="review__input"
                                    data-test-id="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    multiline
                                    maxRows={5}
                                    minRows={3}
                                />
                            </Box>
                            <Box className="hastag__wrapper" data-test-id='hashBg'>
                                {
                                    this.state.hashTagData.map((hashTag: HashTag) => (
                                        <RFHChip
                                            label={`#${hashTag.attributes.name}`}
                                            key={hashTag.id}
                                            data-test-id={`hash_data`}
                                            variant={this.state.selectedHash[hashTag.id] ? 'default' : 'outlined'}
                                            onClick={() => this.handleHashClick(hashTag.attributes)}
                                        />
                                    ))
                                }
                            </Box>
                            <RFHButton fullWidth data-test-id="submit" onClick={this.handleSubmit}>
                                {configJSON.submit}
                            </RFHButton>
                        </Box>

                    </Box>
                </DialogWrapper>

                {this.state.doSignUp &&
                    <UserSignUp
                        navigation={undefined}
                        id="testId"
                        showSignup={this.closeSignUpModal}
                    />
                }
            </>
            // Customizable Area End

        )
    }
}

// Customizable Area Start
const DialogWrapper = styled(Dialog)({
    fontFamily: "Poppins, sans-serif !important",
    color: "#FFF",
    "& .dialog__paper__wrapper": {
        backgroundColor: "#121212",
        width: '100%',
        maxWidth: '930px',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.5)',
        border: 'solid 0.5px #404040',
        margin: "14px",
        borderRadius: "12px",
        position: "relative",
        padding: "50px 0",
        "& .close__button": {
            position: "absolute",
            top: "6px",
            right: "6px",
            width: "24px",
            height: "24px",
            cursor: "pointer",
        },
        "& .dialog__content__wrapper": {
            display: "flex",
            justifyContent: "space-evenly",
            "& .poster__wrapper": {
                "& .poster__image": {
                    marginTop: "20px",
                    width: "285px",
                    maxHeight: "430px",
                    aspectRatio: 0.66,
                    border: "solid 1px #ffb43a"
                },
            },
            "& .form__wrapper": {
                width: "320px",
                "& .form__title": {
                    fontSize: "22px",
                    fontWeight: 600,
                    textAlign: "center",
                    marginBottom: "10px"
                },
                "& .movie__name": {
                    fontSize: "14px",
                    color: "#b4b4b4",
                    textAlign: "center",
                    marginBottom: "30px"
                },
                "& .experience__label": {
                    fontWeight: 600,
                    fontSize: "16px",
                    textAlign: "center",
                    marginBottom: "8px"
                },
                "& .star__wrapper": {
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    justifyContent: "center",
                    "& .star": {
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                    },
                },
                "& .slider__wrapper": {
                    display: "flex",
                    justifyContent: "center",
                    "& .slider__container": {
                        width: '220px',
                        height: '5px',
                        margin: '15px 0px 22px',
                        objectFit: 'contain' as const,
                        borderRadius: '5px',
                        backgroundImage: 'linear-gradient(to right, #f00 0%, #ffa000 37%, #ffd400 50%, #b9d300 74%, #00d000 100%)',
                        '& .MuiSlider-root': {
                            padding: '1px',
                        },
                        padding: '1px',
                        "& .MuiSlider-thumb": {
                            pointerEvents: "none"
                        }
                    },
                },
                "& .error__message": {
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: 400,
                    textAlign: "center"
                },
                "& .textarea__wrapper": {
                    "& .field__label": {
                        fontSize: "14px",
                        fontWeight: 500,
                        margin: "8px 0"
                    },
                    "& .review__input": {
                        border: "solid 1px #b4b4b4",
                        backgroundColor: "#404040",
                        borderRadius: "12px",
                        "& .MuiInput-multiline": {
                            padding: "12px",
                            fontSize: "14px"
                        },
                        "& .MuiInput-underline::after": {
                            display: "none"
                        },
                        "& .MuiInput-underline::before": {
                            display: "none"
                        }
                    },
                },
                "& .hastag__wrapper": {
                    margin: "10px 0 16px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    maxHeight: "116px",
                    overflow: "scroll",
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                }
            }
        }
    },
    "@media (max-width: 600px)": {
        "& .dialog__paper__wrapper": {
            padding: "20px 0",
            "& .dialog__content__wrapper": {
                flexDirection: "column",
                overflow: "scroll",
                "& .poster__image": {
                    marginTop: "0",
                    width: "100% !important",
                    objectFit: "cover",
                    aspectRatio: "1.72 !important",
                    border: "none !important"
                },
                "& .form__wrapper": {
                    width: "100% !important",
                    padding: "10px",
                    "& .form__title": {
                        display: "none !important"
                    },
                    "& .movie__name": {
                        fontSize: "16px !important",
                        fontWeight: "500  !important",
                        color: "#FFF !important",
                        marginBottom: "24px !important"
                    },
                    "& .experience__label": {
                        fontSize: "14px !important",
                        fontWeight: "500 !important"
                    }
                }
            }
        }
    }
})

const webStyle = {
    dialogStyleContent:{
        padding: '0px 10px 10px 50px'
    },
    mainContainer: {
        backgroundColor: "#121212",
        color: "#fff",
        width: '930px',
        maxWidth: '930px',
        height: 'auto',
        // height: '809px',
        // padding: '9px 12px 41px 44px',


        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.5)',
        border: 'solid 0.5px #404040',
        margin: "16px"
    },
    mainContainers: {
        backgroundColor: "#404040",
        color: "#fff",
        padding: '9px 12px 41px 44px',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.5)',
        border: 'solid 0.5px #404040',
        borderRadius: '8px'
    },
    thanksMsg: {
        display: "flex",
        justifyContent: 'center',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 500,

    },
    closeIcon: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        height: '28px',
        width: '28px',
        cursor: 'pointer'
    },
    bannerImage: {
        width: '321px',
        height: '480px',
        objectFit: 'cover' as const,
    },
    image: {
        width: '321px',
        height: "486px",
        margin: "47px 1px 1px 0",
        display: 'flex',
        alignItems: "center",
    },
    RatingHeading: {
        width: '285px',
        height: '33px',
        margin: ' 28px 10px 12px 13px',
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 600,
        letterSpacing: '0.12px',
        color: '#fffcfc,',
        display: "flex",
        justifyContent: "center"

    },
    starHead: {
        display: 'flex',
        gridGap: '16px',
    },
    sliderHead: {
        display: 'flex',
        justifyContent: 'center',
    },
    starConatiner: {
        width: '32px',
        height: '32px',
        objectFit: 'contain' as const
    },
    sliderRoot: {
        width: '343px',
        height: '9px',
        margin: '15px 0px 22px',
        objectFit: 'contain' as const,
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(to right, #f00 0%, #ffa000 37%, #ffd400 50%, #b9d300 74%, #00d000 100%)',
        '&.MuiSlider-root': {
            padding: '1px'
        }
    },
    sliderContainer: {
        width: '295px',
        height: '9px',
        margin: '15px 0px 22px',
        objectFit: 'contain' as const,
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(to right, #f00 0%, #ffa000 37%, #ffd400 50%, #b9d300 74%, #00d000 100%)',
        '& .MuiSlider-root': {
            padding: '1px'
        },
        padding: '1px',
        '& #rating-img': {
            left: "15%"
        },
    },
    movieName: {
        height: "23px",
        fontFamily: "Poppins",
        fontSize: "16px",
        color: "#b4b4b4",
        textAlign: 'center' as const
    },
    rateExp: {
        width: "196px",
        height: '25px',
        margin: '48px 59px 12px 54px',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.09px',
        textAlign: 'center' as const,
        color: '#fffcfc',
    },
    reviewHead: {
        color: "#ffffff",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: 'Poppins',
        marginRight: '14rem'

    },
    reviewBody: {
        width: '300px',
        height: '116px',
        margin: '16px 32px 16px 4px',
        borderRadius: '12px',
        border: 'solid 1px #b4b4b4',
        backgroundColor: '#404040',

    },
    hashContainer: {
        display: "flex",
        flexWrap: "wrap" as const,
        flexDirection: "row" as const,
        width: "350px",
        textAlign: "center" as const,
        flex: "center",
        marginTop: "5px",
        maxHeight: "206px",
        overflowY: "scroll" as const,
    },
    hashTag: {
        width: "142px",
        height: "36px",
        borderRadius: "12px",
        border: "solid 1px #b4b4b4",
        backgroundColor: "#2f2617",
        margin: "6px 6px 6px 6px",
        cursor: 'pointer',
        '& .select': {
            backgroundColor: '#404040'
        }

    },
    selected: {
        backgroundColor: 'red'
    },
    hashTagBody: {
        alignItems: "center",
        marginRight: "12px",
        marginTop: "8px",
        color: "#ffffff",
    },
    input: {
        borderRadius: "8px",
        background: "rgb(64, 64, 64)",
        border: "1px solid grey",
        height: "100px",
        overflow: "auto",
        padding: "10px",
        color: "#fff",
        width: "350px",
        marginBottom: "14px",
        marginTop: "5px",
        fontFamily: 'Poppins',
        fontSize: '16px',
    },
    error: {
        display: 'flex',
        justifyContent: 'center',
        color: 'red',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        margin: '2px',
        width: '330px',
    },
    checkImage: {
        display: "flex",
        justifyContent: "center",
        fontSize: "16px",
        padding: '6px'
    },
    doneBtn: {
        display: 'flex',
        justifyContent: 'center',
        margin: '30px 5px 5px 5px',
        padding: '6px',

    }
}
export default Ratings
// Customizable Area End
