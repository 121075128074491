import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { FavouritesApiResponse } from "../../../components/src/interfaces.web";

interface AddFavourites {
  heartIcon: string,
  filledHeart:string,
  imageStyle : any, 
  favouriteable_id:string,
  favouriteable_type:string,
  tooltipDirection:any,
  isFavourite?:boolean
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  addFavourites:AddFavourites;
  classes?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  favouritesId: any;
  favourites: any;
  loading:boolean;
  tabValue:string;
  favouriteable_type_state?:string,
  doSignUp:boolean
  isFavourite: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddFavouritesController extends BlockComponent<Props, S, SS> {
  favouritesApiCallId: any;
  addFavouritesApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
 
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      favouritesId:{},
      favourites: [],
      loading:false,
      tabValue:"1",
      favouriteable_type_state:"",
      doSignUp:false,
      isFavourite: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      favouriteable_type_state:this.props.addFavourites?.favouriteable_type,
      loading:true,
      isFavourite: this.props.addFavourites?.isFavourite || false
    }, ()=> {
      this.props.addFavourites==undefined && this.handleTabChange("", "1");
    });
   }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.addFavouritesApiCallId){
        this.getFavourites();
      }

      if (apiRequestCallId === this.favouritesApiCallId) {

        let isFavourite: boolean;
        
        if(resJson?.errors?.length) isFavourite = false
        else isFavourite = (resJson as FavouritesApiResponse).data.some(favouriteItem => favouriteItem.attributes.favouriteable_id == +this.props?.addFavourites?.favouriteable_id)
        this.setState({ favourites: resJson.data, loading:false, isFavourite },()=>{
          this.forceUpdate();
        });
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start

  // To go back
  handleBackBtn=()=>{
    this.props.navigation.goBack();
  }

  // Handle on Change the tab
  handleTabChange = (event:any, newValue: string) => {
    let fav_type = "";

    if(newValue == "1"){
      fav_type = "BxBlockCategories::Movie"
    }
    if(newValue == "2"){
      fav_type = "BxBlockEvents::EventDetail"
    }
    if(newValue == "3"){
      fav_type = "BxBlockReservations::Theatre"
    }
    if(newValue == "4"){
      fav_type = "BxBlockCategories::Vogue"
    }

    this.setState({
      favouriteable_type_state: fav_type,
      tabValue:newValue,
    },()=> this.getFavourites());
  }

  // Tso get all favourites whose type is assigned
  getFavourites = async() => {
    const header = {
      token: JSON.parse(JSON.stringify( await getStorageData("login"))),
      "Content-Type": configJSON.favouritesApiApiContentType,
    };
    this.setState({loading:true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritesListApiEndPoint}${this.state.favouriteable_type_state ?? "BxBlockCategories::Movie"}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // to add and remove favourites from the list
  addFavouritesCall = async(id:string, type: string) => {
    let authToken =  await getStorageData("login", false);
    if(authToken){
      this.setState({loading:true});
      const header = {
        "Content-Type": configJSON.favouritesApiApiContentType,
        token: authToken,
      };

      let body = {
        favouriteable_type: type,
        favouriteable_id: id,
      };

      const favouriteRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addFavouritesApiCallId = favouriteRequestMessage.messageId;

      favouriteRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouritesApiEndPoint
      );
      favouriteRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      favouriteRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data: body })
      );
      favouriteRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(favouriteRequestMessage.id, favouriteRequestMessage);
      return true;
    }else{
      this.setState({doSignUp:true});
    }
    
  };

  // on close of the signup modal
  closeSignUpModal = () => {
    this.getFavourites();
    this.setState({doSignUp:false});
  }

  // Customizable Area End
}