import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  transectionfilterInput:any;
  statusFilterInput:any,
  walletTransectionList:any,
  authToken:string,
  totalWalletBalance:number,
  loading:boolean,
  date:any,
  pageCount:number,
  currentPage:number,
  perPageData: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfwallet19Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    walletTransectionListApi = ""
    walletBalaceApi=""
    walletTransectionApiCallId=""
    transectionStatusApiCallId = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      transectionfilterInput:{},
      statusFilterInput:{},
      walletTransectionList:[],
      authToken:'',
      totalWalletBalance:0,
      loading:false,
      date:{
        start:null,
        end:null
      },
      pageCount:1,
      currentPage:1,
      perPageData: 8
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if(responseJson) {
        switch (apiRequestCallId) {
          case this.walletTransectionListApi:
            this.handleWalletTransectionList(responseJson);
            break;
          case this.walletBalaceApi:
            this.setState({ totalWalletBalance: responseJson.total_amount })
            break;
          case this.transectionStatusApiCallId:
            const status = responseJson?.find((elm: any) => elm.is_captured === true)
            this.walletTransection("credit", status ? "success" : "failed", responseJson[0]);
            break;
          case this.walletTransectionApiCallId:
            this.getWalletTransection();
            this.getWalletBalace();
            break;
        }
      }

      this.setState({loading:false});
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleSelectTransection = (value:any, lable:any) => {
    this.setState({
      transectionfilterInput:{id:value, lable:lable}
    }, ()=> this.getWalletTransection())
  }

  handleSelectDate = (value: any, field: string) => {
    this.setState({
      date: {...this.state.date, [field]: value }
    }, () => {
      this.getWalletTransection();
    })
  }

  handleSelectStatus = (value:any, lable:any) => {
    this.setState({
      statusFilterInput:{id:value, lable:lable}
    }, ()=>{
      this.getWalletTransection();
    })
  }

  async componentDidMount() {
    this.setState({ authToken: await getStorageData(LocalStorageKeys.LoginToken) as string });
    this.getWalletTransection();
    this.getWalletBalace();
  }

  walletTransection = async(transaction_type:string, status:string, respJson:any) =>{
    const httpBody = {
      wallet_transaction: {
        description: "Money added to Wallet",
        amount: respJson.order_amount,
        transaction_type: transaction_type, //"debit"
        status: status, //success", "failed", "pending
        payment_order_id: respJson.order_id
    }
    }
      this.walletTransectionApiCallId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.PostMethod,
        endPoint: configJSON.walletTransectionEndPoint,
        body: httpBody,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
  }

  // to get the list of transection in the wallet
  getWalletTransection = () => {
    this.setState({loading:true})

    const { startDate, endDate } = this.state.date;
    const { id: statusId } = this.state.statusFilterInput;
    const { id: transectionFilterId } = this.state.transectionfilterInput;
    const { currentPage } = this.state;

    const startTime = startDate ? `&start_time=${moment(startDate).format("DD/MM/YYYY")}` : "";
    const endTime = endDate ? `&end_time=${moment(endDate).format("DD/MM/YYYY")}` : "";
    const status = statusId ? `&status=${statusId}` : "";

    const thirty = transectionFilterId?.includes("30") ? "&thirty=true" : "";
    const sixMonth = transectionFilterId?.includes("6") ? "&six_month=true" : "";

    const endPoint = `${configJSON.getWalletTransections}per=${this.state.perPageData}&page=${currentPage}${startTime}${endTime}${status}${thirty}${sixMonth}`;

    this.walletTransectionListApi = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: endPoint,
      headers: { "token": this.state.authToken },
    }, runEngine)
  }

  // to get the total balance in the wallet
  getWalletBalace = async() =>{
    this.setState({loading:true})
    this.walletBalaceApi = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: configJSON.walletBalance,
      headers: { "token": this.state.authToken },
    }, runEngine)
  }

  handleWalletTransectionList = (respJson:any) =>{
    this.setState({
      pageCount:respJson.meta_data.pagination.total_pages,
      walletTransectionList:respJson.data, 
    });
  }

  handleOnPageChange = (e: any) => {
    this.setState({ currentPage: e.selected + 1 }, ()=> this.getWalletTransection())
  }

  handleBackBtn=()=>{
    this.props.navigation.goBack();
  }

  navigateToAddMoney = (isBank: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "AddMoney");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), isBank);
    this.send(msg);
  }

  resetFilter = () =>{
    this.setState({
      transectionfilterInput:{},
      statusFilterInput:{},
      date:{
        start:null,
        end:null
      },
    },()=> this.getWalletTransection())
  }

  // Customizable Area End
}