import React from "react";

// Customizable Area Start
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Container,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Popper,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
  styled,
  Popover,
  Typography,
  makeStyles,
  Divider,
  Button
} from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { ArrowBackIosOutlined, ChevronLeft, ChevronRight, TuneOutlined } from "@material-ui/icons";
import { IBookingResponse, IEventBookingFullResponse, ISelectOption, eventOrMovieBookingData } from "../../../components/src/interfaces.web";
import { FilterOption, TabOption } from "../../../components/src/enums.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import BookingHistoryListing from "../../../components/src/BookingHistoryListing";
import { convertToPad, getMovieDuration, goToPrevPage } from "../../../components/src/utilities";
import { Seat } from "../../../components/src/BookingSuccessfulTicket.web";
import { FilterIcon, InvalidStatusImage, cancelledImage } from "./assets";
import moment from "moment";
import EventBookingHistoryListing from "../../../components/src/EventBookingHistoryListing";
import EventMobileBookingHistory from "../../../components/src/EventMobileBookingHistory";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h6: {
      fontSize: "18px",
      color: "#b4b4b4",
      fontWeight: "normal"
    }
  }
});

interface IMobileTicketProps {
  booking: IBookingResponse
  message: string
  redirectToViewTicket: (id: number) => void
}

export const RFHMenuList = styled(MenuList)({
  padding: "0 !important",
  minWidth: "190px",
  "& > li:nth-child(odd)": {
    color: "#fff",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontSize: "16px",
    border: "solid 1px #5a5a5a !important",
    backgroundColor: "#5a5a5a !important",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "#ffb43a !important",
      border: "none"
    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#ffb43a !important"
    }
  },
  "& > li:nth-child(even)": {
    color: "#fff",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontSize: "16px",
    border: "solid 1px #5a5a5a !important",
    backgroundColor: "#404040 !important",
    "&.Mui-selected": {
      color: "#000",
      backgroundColor: "#ffb43a !important",
      border: "none"

    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#ffb43a !important"
    }
  }
})

export const RFHTabRoot = styled(Tabs)({
  width: "fit-content",
  boxSizing: "border-box",
  border: "solid 1px #ffb43a !important",
  backgroundColor: "#2f2617",
  borderRadius: "12px",
  color: "#fff",
  "& .MuiTabs-indicator": {
    display: "none"
  }
})

export const RFHTab = styled(Tab)({
  fontSize: "18px",
  textTransform: "capitalize",
  padding: "8px 32px",
  "&.Mui-selected": {
    backgroundColor: "#ffb43a",
    color: "#000",
    borderRadius: "12px",
    fontWeight: 600
  }
})

export const MRFHTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffb43a"
  }
})

export const MRFHTab = styled(Tab)({
  fontSize: "18px",
  textTransform: "capitalize",
  color: "#b4b4b4",
  fontWeight: 600,
  "&.Mui-selected": {
    color: "#fff",
  }
})

export const RFHIconButton = styled(IconButton)({
  color: "#fff",
  "&:disabled": {
    color: "#fff",
    cursor: "not-allowed",
    "&&:hover": {
      cursor: "not-allowed",
    },
  },
})

export const FilterOptionList: ISelectOption<FilterOption>[] = [
  { label: "All", value: FilterOption.All },
  { label: "Confirmed", value: FilterOption.Confirmed },
  { label: "Cancelled", value: FilterOption.Cancelled },
  { label: "Buy - Request", value: FilterOption.BuyAndRequest },
  { label: "Invalid", value: FilterOption.Invalid }
]

// Customizable Area End

import BookingHistoryController, { 
  Props,
  configJSON
} from "./BookingHistoryController.web";

export default class BookingHistory extends BookingHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={styles.MainWrapper}>

          <Box style={styles.MinHeight}>

            <Box display={{ xs: "none", sm: "block" }}>
              <MainHeaderComp navigation = {this.props.navigation} />

              <Container data-test-id="test-id">


                <Box display="flex" justifyContent="space-between" mt={3} mb={3}>
                  <Box display="flex" alignItems="center">
                    <IconButton style={styles.BackButton} data-test-id="back-button" onClick={() => goToPrevPage()}>
                      <ArrowBackIosOutlined />
                    </IconButton>

                    <span style={styles.Title}>{configJSON.BookingHistory}</span>
                  </Box>

                  <Box display="flex" alignItems="center" mr={3}>
                    <IconButton
                      style={styles.FilterIcon}
                      onClick={this.handleClick}
                      aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={this.state.anchorEl ? 'true' : undefined}
                      data-test-id="filter-menu-button"
                    >
                      <img src={FilterIcon} alt="Filter-Button" width="22px" height="22px" />
                      <span style={styles.FilterText}>{configJSON.Filter}</span>
                    </IconButton>

                  <Popper
                    open={!!this.state.anchorEl}
                    anchorEl={this.state.anchorEl}
                    role={undefined}
                    placement="bottom-end"
                    transition
                    disablePortal
                    style={styles.zIndex}
                  >
                    <Popover
                      open={!!this.state.anchorEl}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          background: 'transparent !important',
                          borderRadius: "12px",
                          boxShadow: "none"
                        },
                      }}

                      >
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <RFHMenuList
                            autoFocusItem={!this.state.anchorEl}
                            id="composition-menu"
                            data-test-id="filter-menu-root"
                          >

                            {
                              FilterOptionList.map(option => (
                                <MenuItem key={option.value} data-test-id="filter-menu" selected={this.state.selectedFilter === option.value} onClick={() => this.handleFilterChange(option.value)}>{option.label}</MenuItem>
                              ))
                            }

                          </RFHMenuList>
                        </ClickAwayListener>
                      </Popover>

                    </Popper>

                  </Box>
                </Box>

                {/* WEB VIEW START */}
                <Container style={styles.ListingContainer}>
                  <Box mb={4} display={{ xs: "none", sm: "block" }}>
                    <RFHTabRoot data-test-id="time-interval-tab-root" value={this.state.selectedTab} onChange={this.handleTabChange}>
                      <RFHTab value={TabOption.UpComing} label="Up Coming"></RFHTab>
                      <RFHTab value={TabOption.PastBooking} label="Past Booking"></RFHTab>
                    </RFHTabRoot>
                  </Box>

                  <Box display="flex" flexDirection="column" gridGap="25px">

                    {/* LISTING OF BOOKING */}
                    {
                      this.state.isLoading ? (
                        <Fade in={this.state.isLoading} unmountOnExit>
                          <Box display="flex" justifyContent="center">
                            <CircularProgress style={styles.CircularProgress} />
                          </Box>
                        </Fade>
                      ) 
                      :(
                          <EventMovieTicket
                            bookingHistory={this.state.bookingHistory}
                            redirectToViewTicket={this.redirectToViewTicket}
                            redirectToCancleTicket = {this.redirectToCancleTicket}
                          />
                        )
                    }

                    {/* Pagination */}
                    {
                      this.state.bookingHistory.length > 0 ? (
                        <Box display="flex" alignItems="center" flexDirection="row" justifyContent="flex-end">

                          <RFHIconButton
                            data-test-id="pagination-prev-button"
                            disabled={typeof this.state.pagination.prev_page !== "number"}
                            onClick={() => this.getBookingHistory(this.state.selectedFilter, this.state.selectedTab, this.state.pagination.prev_page)}
                          >
                            <ChevronLeft />
                          </RFHIconButton>
                          {
                            this.state.pagination.prev_page && (
                              <Typography style={styles.PageNumber}>{this.state.pagination.prev_page}</Typography>
                            )
                          }
                          {
                            !!this.state.pagination.current_page && (
                              <Typography data-test-id="pagination-current-page" style={styles.ActivePageNumber} align="center">{this.state.pagination.current_page}</Typography>
                            )
                          }
                          {
                            this.state.pagination.next_page && (
                              <Typography style={styles.PageNumber}>{this.state.pagination.next_page}</Typography>
                            )
                          }
                          <RFHIconButton
                            data-test-id="pagination-next-button"
                            disabled={typeof this.state.pagination.next_page !== "number"}
                            onClick={() => this.getBookingHistory(this.state.selectedFilter, this.state.selectedTab, this.state.pagination.next_page)}
                          >
                            <ChevronRight />
                          </RFHIconButton>

                        </Box>
                      ) : null
                    }

                  </Box>

                </Container>
                {/* WEB VIEW END*/}

              </Container>

              <FooterWithEvents />
            </Box>

            {/* MOBILE VIEW START */}

            <Box display={{ xs: "block", sm: "none" }} style={styles.MobileWrapper}>

              <Box style={styles.MobileHeader}>

                <Container>
                  <Box display={{ xs: "flex", sm: "none" }} justifyContent="space-between" mt={1.5} mb={1.5}>

                    <Box display="flex" alignItems="center">
                      <IconButton style={styles.MBackButton} onClick={goToPrevPage}>
                        <ArrowBackIosOutlined style={{ height: "16px", width: "16px" }} />
                      </IconButton>

                      <span style={styles.MTitle}>{configJSON.BookingHistory}</span>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <IconButton
                        style={styles.FilterIcon}
                        onClick={this.handleClick}
                        aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={this.state.anchorEl ? 'true' : undefined}
                      >
                        <TuneOutlined />
                      </IconButton>
                    </Box>

                  </Box>
                </Container>

                <Box>
                  <MRFHTabs variant="fullWidth" value={this.state.selectedTab} onChange={this.handleTabChange} >
                    <MRFHTab value={TabOption.UpComing} label="Up Coming"></MRFHTab>
                    <MRFHTab value={TabOption.PastBooking} label="Past Booking"></MRFHTab>
                  </MRFHTabs>
                </Box>

              </Box>

              <Box style={styles.ListingWrapper} >

                {/* LISTING OF BOOKING */}
                {
                  this.state.isLoading ? (
                    <Fade in={this.state.isLoading} unmountOnExit>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress style={styles.CircularProgress} />
                      </Box>
                    </Fade>
                  ) :
                    (
                      <EventMovieMobileTicket 
                        bookingHistory = {this.state.bookingHistory}
                        redirectToViewTicket = {this.redirectToViewTicket}
                        redirectToCancleTicket = {this.redirectToCancleTicket}
                      />
                    )
                }
              </Box>

              {
                this.state.bookingHistory.length > 0 ? (
                  <Box flexDirection="row" alignItems="center" display="flex" justifyContent="flex-end">

                    <RFHIconButton
                      onClick={() => this.getBookingHistory(this.state.selectedFilter, this.state.selectedTab, this.state.pagination.prev_page)}
                      disabled={typeof this.state.pagination.prev_page !== "number"}>
                      <ChevronLeft />
                    </RFHIconButton>

                    {this.state.pagination.prev_page && <Typography style={styles.PageNumber}>{this.state.pagination.prev_page}</Typography>}

                    {!!this.state.pagination.current_page && <Typography style={styles.ActivePageNumber} align="center">{this.state.pagination.current_page}</Typography>}

                    {this.state.pagination.next_page && <Typography style={styles.PageNumber}>{this.state.pagination.next_page}</Typography>}

                    <RFHIconButton
                      onClick={() => this.getBookingHistory(this.state.selectedFilter, this.state.selectedTab, this.state.pagination.next_page)}
                      disabled={typeof this.state.pagination.next_page !== "number"}>
                      <ChevronRight />
                    </RFHIconButton>

                  </Box>
                ) : null
              }

            </Box>

            {/* MOBILE VIEW END */}


          </Box>

        </Box>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const styles = {
  MainWrapper: {
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "#121212",
  },
  MinHeight: {
    minHeight: "100vh",
  },
  CircularProgress: {
    marginTop: "20px",
    color: "#ffb43a"
  },
  DisplayFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  BackButton: {
    width: "32px",
    height: "32px",
    backgroundColor: "#ffb43a",
    marginRight: "24px"
  },
  MBackButton: {
    width: "24px",
    height: "24px",
    backgroundColor: "#ffb43a",
    marginRight: "16px"
  },
  Title: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#fff",
    fontFamily: "Poppins"
  },
  MTitle: {
    fontSize: "18px",
    fontFamily: "Poppins",
    color: "#fff",
    fontWeight: 600
  },
  FilterIcon: {
    color: "#ffb43a",
    padding: 0
  },
  FilterText: {
    fontSize: "16px",
    color: "#fff",
    fontFamily: "Poppins",
    marginLeft: "12px"
  },
  zIndex: {
    zIndex: 2,
    top: "16px !important",
  },
  ListingContainer: {
    paddingLeft: "48px",
  },
  NoDataMessage: {
    fontFamily: "Poppins, sans-serif",
    color: "#fff",
    fontWeight: 600,
    minHeight: "60px",
    textAlign: "center" as const
  },
  PageNumber: {
    color: "#fff",
    fontSize: "18px",
    padding: "12px",
    fontFamily: "Poppins, sans-serif",
  },
  ActivePageNumber: {
    fontSize: "18px",
    margin: "12px",
    height: "26px",
    width: "26px",
    backgroundColor: "#ffb43a",
    fontFamily: "Poppins, sans-serif",
    borderRadius: "4px",
    border: "none",
    color: "#000"
  },
  MobileWrapper: {
    minHeight: "100vh",
    backgroundColor: "#404040"
  },
  MobileHeader: {
    backgroundColor: "#121212",
    paddingBottom: "1px"
  },
  ListingWrapper: {
    padding: "16px",
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px"
  }
}

const useStyles = makeStyles(({
  DisplayFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  Ticket: {
    backgroundColor: "#121212",
    border: "none",
    borderRadius: "12px",
    padding: "16px",
    overflow: "hidden",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "16px",
      height: "32px",
      top: "170px",
      left: "0",
      transform: "translate(-6px, 0)" as const,
      backgroundColor: "#404040",
      borderRadius: "0 32px 32px 0",
      border: 'none'
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: "16px",
      height: "32px",
      top: "170px",
      right: "0",
      transform: "translate(6px, 0)" as const,
      backgroundColor: "#404040",
      borderRadius: "32px 0 0 32px",
      border: 'none'
    },
    "& .left-side-cut": {
      position: "absolute",
      width: "16px",
      height: "32px",
      left: "0",
      bottom: "30px",
      transform: "translate(-6px, 0)" as const,
      backgroundColor: "#404040",
      borderRadius: "0 32px 32px 0",
      border: 'none'
    },
    "& .right-side-cut": {
      position: "absolute",
      width: "16px",
      height: "32px",
      right: "0",
      bottom: "30px",
      transform: "translate(6px, 0)" as const,
      backgroundColor: "#404040",
      borderRadius: "32px 0 0 32px",
      border: 'none'
    }
  },
  PosterWrapper: {
    alignItem: "center",
    justifyContent: "center",
  },
  PosterImage: {
    border: "solid 0.7px #ffb53a",
    borderRadius: "2px",
    width: "120px",
    height: "150px",
  },
  TicketTitle: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 600,
    maxWidth: "140px",
    marginBottom: "8px"
  },
  TicketYear: {
    color: "#b4b4b4",
    fontSize: "12px",
  },
  TicketLanguageFormat: {
    color: "#b4b4b4",
    fontSize: "12px",
    textTransform: "uppercase",
    margin: "6px 0"
  },
  DateTime: {
    color: "#fff",
    fontSize: "12px",
    fontWeight: 600,
    marginBottom: "6px"
  },
  Location: {
    fontSize: "12px",
    color: "#b4b4b4"
  },
  Divider: {
    backgroundColor: "#b4b4b4",
    margin: "12px 0"
  },
  ScreenLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#FFF",
    fontFamily: "Poppins",
    textTransform: "capitalize" as const
  },
  SeatWrapper: {
    maxWidth: "146px",
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    "& > div": {
      "& div": {
        height: "23px",
        width: "23px",
        fontSize: "8px",
        backgroundColor: "#ffb43a !important"
      }
    }
  },
  Labels: {
    fontSize: "12px",
    color: "#b4b4b4",
    textTransform: "capitalize"
  },
  BookingDateLabel: {
    color: "#b4b4b4",
    fontSize: "14px",
    marginTop: "12px",
    textTransform: "capitalize",
  },
  TicketTotal: {
    fontSize: "16px",
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
    "& > span": {
      fontFamily: "Roboto"
    }
  },
  DottedDivider: {
    border: "none",
    borderTop: "dotted 1px #b4b4b4",
    margin: "12px 0"
  },
  ViewButton: {
    backgroundColor: "#ffb53a",
    border: "none",
    borderRadius: "8px",
    color: "#000",
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    minHeight: "48px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: "#202020",
    }
  },
  Message: {
    fontSize: "12px",
    color: "#b4b4b4",
    textAlign: "center"
  },
  BookingStatusLabel: {
    position: 'absolute',
    top: "120px",
    left: "50%",
    transform: "translate(-50%)"
  },
}))

// For mobile movie tickets 
export const MobileTicket: React.FC<IMobileTicketProps> = (props) => {

  const classes = useStyles()
  let ImageURL;

  const { 
    booking: {
      id: ticketId,
      attributes: {
        movie: {
          image_url,
          name,
          release_date,
          duration_in_minutes
        },
        theatre_name,
        screen_name,
        language,
        screen_type,
        show_date_time,
        seat_tier,
        seat_number,
        booked_date,
        number_of_seats,
        total_amount,
        status: bookingStatus,
        is_invalid
      }
    },
    message,
    redirectToViewTicket
  } = props

  switch (bookingStatus) {
    case FilterOption.Cancelled:
      ImageURL = cancelledImage;
      break;

    case FilterOption.Invalid:
      ImageURL = InvalidStatusImage;
      break;

    default:
      ImageURL = null;
      break;
  }

  if(is_invalid) ImageURL = InvalidStatusImage


  return (
    <Box className={classes.Ticket}>

      <Box display="flex" gridGap="12px" position="relative">

        {/* BOOKING STATUS LABEL START */}
        <Box className={classes.BookingStatusLabel}>
          {ImageURL && <img src={ImageURL} width="261px" height="204px" />}
        </Box>
        {/* BOOKING STATUS LABEL END */}

        <Box className={classes.PosterWrapper}>
          <img className={classes.PosterImage} src={image_url} alt="Poster-image" />
        </Box>

        <Box>
          <Typography className={classes.TicketTitle}>{name}</Typography>
          <Typography>
            <span className={classes.TicketYear}>{new Date(release_date).getFullYear()}</span>
            &nbsp;
            <span className={classes.TicketYear}>UA</span>
            &nbsp;
            <span className={classes.TicketYear}>{getMovieDuration(duration_in_minutes)}</span>
          </Typography>
          <Typography className={classes.TicketLanguageFormat}>{`${language}, ${screen_type}`}</Typography>
          <Typography className={classes.DateTime}>{show_date_time}</Typography>
          <Typography className={classes.Location}>{theatre_name}</Typography>
        </Box>

      </Box>

      <Divider className={classes.Divider} />

      <Box className={classes.DisplayFlex}>
        <span className={classes.ScreenLabel}>
          {screen_name}
        </span>
        <Box className={classes.SeatWrapper}>
          {
            seat_number.map((seat) => <Seat key={seat} seatLabel={seat} />)
          }
        </Box>
      </Box>

      <Box className={classes.DisplayFlex} mt={1}>
        <span className={classes.Labels}>{configJSON.ScreenLabel}</span>
        <span className={classes.Labels}>{`${configJSON.SeatsLabel}${seat_tier[0]}`}</span>
      </Box>

      <Box className={classes.DisplayFlex}>
        <span className={classes.BookingDateLabel}>{configJSON.BookingDate}</span>
        <span className={classes.BookingDateLabel}>{moment(booked_date).format("DD MMM, YYYY")}</span>
      </Box>

      <Divider className={classes.Divider} />

      <Box className={classes.DisplayFlex} alignItems="center">
        <span className={classes.BookingDateLabel}>{`${configJSON.TicketsLabel} (${convertToPad(number_of_seats)})`}</span>
        <Typography className={classes.TicketTotal}><span>₹</span> {Number(total_amount).toFixed(2)}</Typography>
      </Box>

      <hr className={classes.DottedDivider} />

      <Button className={classes.ViewButton} fullWidth onClick={() => redirectToViewTicket(+ticketId)}>{configJSON.ViewTicket}</Button>

      <hr className={classes.DottedDivider} />

      <Typography className={classes.Message}>{message}</Typography>

      <span className="left-side-cut"></span>
      <span className="right-side-cut"></span>

    </Box>
  )
}


// For mobile event and movie tickets 
export const EventMovieMobileTicket = (props: any) => {
  const { bookingHistory, redirectToViewTicket, redirectToCancleTicket } = props
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" gridGap="25px">
     {
        bookingHistory.length > 0 ? (
          bookingHistory.map((booking: eventOrMovieBookingData) => {
            return (
              booking.type === "event_booking"
                ? <EventMobileBookingHistory
                  booking={booking as IEventBookingFullResponse}
                  message={configJSON.BuyOrSellMsg}
                  redirectToViewTicket={redirectToViewTicket}
                  classes={classes}
                  configJSON={configJSON}

                />
                : <MobileTicket
                  key={booking.id}
                  booking={booking as IBookingResponse}
                  message={configJSON.BuyOrSellMsg}
                  redirectToViewTicket={booking.attributes.status === "cancelled" ? redirectToCancleTicket : redirectToViewTicket}
                />
            )
          })
        ) : (
          <Typography style={styles.NoDataMessage}>{configJSON.NoDataFound}</Typography>
        )
      }
    </Box>
  )
}

// For web event and movie tickets 
export const EventMovieTicket = (props: any) => {

  const { bookingHistory, redirectToViewTicket, redirectToCancleTicket } = props
  return (
    <Box display="flex" flexDirection="column" gridGap="25px" data-test-id="ticket-listing-wrapper">
      {
        bookingHistory.length > 0 ? (
          bookingHistory.map((booking: eventOrMovieBookingData) => {
            return (
              booking.type === "event_booking" 
                ? <EventBookingHistoryListing 
                    key={booking.id} 
                    booking={booking as IEventBookingFullResponse} 
                    message={configJSON.BuyOrSellMsg} 
                    redirectToViewTicket={redirectToViewTicket} 
                  /> 
                : <BookingHistoryListing 
                    key={booking.id} 
                    booking={booking as IBookingResponse} 
                    message={configJSON.BuyOrSellMsg} 
                    redirectToViewTicket={booking.attributes.status === "cancelled" ? redirectToCancleTicket : redirectToViewTicket} 
                  />)
          })
        ) : (
          <Typography style={styles.NoDataMessage}>{configJSON.NoDataFound}</Typography>
        )
      }
    </Box>
  )
}

// Customizable Area End



