import React from "react";

// Customizable Area Start
import { Box, Typography, Dialog, DialogContent, Tooltip } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import {
  FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, EmailIcon,
  EmailShareButton, FacebookMessengerShareButton, FacebookMessengerIcon
} from "react-share";
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box>
          <Tooltip
            title="Share" arrow
          >
            <img
              src={this.props.shareDetails?.shareSrc}
              style={this.props.shareDetails?.imageStyle}
              onClick={this.handleShare}
              data-test-id="shareImg"
            />
          </Tooltip>
        </Box>

        <Dialog
          open={this.state.isOpen}
          onClose={this.handleClose}
          data-test-id="dialogClose"
          PaperProps={{
            style: {
              color: "#fff",
              padding: '12px',
              maxWidth: '310px',
              width: "100%"
            }
          }}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={webStyle.dialogContent}>

            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>

              <Box style={webStyle.mainBox}>
                <Box>

                  <FacebookShareButton
                    url={this.state.shareUrl}
                    quote={configJSON.quote}
                    hashtag={configJSON.hashtag}
                    className={undefined}
                  >
                    <FacebookIcon size={36} round />
                    <Typography>
                      Facebook
                    </Typography>
                  </FacebookShareButton>
                </Box>
                <Box>

                  <WhatsappShareButton
                    url={this.state.shareUrl}
                  >
                    <WhatsappIcon size={36} round />
                    <Typography>
                      Whatsapp
                    </Typography>
                  </WhatsappShareButton>
                </Box>
                <Box>
                  <TelegramShareButton
                    url={this.state.shareUrl}
                  >
                    <TelegramIcon size={36} round />
                    <Typography>
                      Telegram
                    </Typography>
                  </TelegramShareButton>
                </Box>
                <Box>
                  <EmailShareButton
                    url={this.state.shareUrl}
                  >
                    <EmailIcon size={36} round />
                    <Typography>
                      Mail
                    </Typography>

                  </EmailShareButton>
                </Box>
                <Box>
                  <FacebookMessengerShareButton
                    url={this.state.shareUrl}
                    appId="521270401588372"

                  >
                    <FacebookMessengerIcon size={36} round />
                    <Typography>
                      Messanger
                    </Typography>
                  </FacebookMessengerShareButton>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      paper: "#121212",
      default: "#404040",
    },
    text: {
      primary: "#FFF",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body1: {
      marginTop: "6px"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#121212",
        color: "#b4b4b4",
        border: "solid 1px #b4b4b4",
        position: 'relative',
        fontFamily: "Poppins"
      },
      arrow: {
        '&:before': {
          border: 'solid 1px #b4b4b4',
        },
        color: '#121212',
      },

    }
  }
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#121212",
    color: '#fff'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  mainBox: {
    display: 'flex',
    gridGap: '14px',
    justifyContent: "center",
    flexWrap: 'wrap' as const,
    marginTop: '20px',
  },
  dialogContent: {
    padding: "8px 0 0"
  }
};
// Customizable Area End
