import React from 'react';
import Slider from "react-slick";

import moment from "moment";
import AuctionBiddingDetailController, { Props, configJSON } from "./AuctionBiddingDetailController.web";

// Customizable Area Start
import { 
    Grid, 
    Typography, 
    Button, 
    Dialog, 
    TextField, 
    Tab, 
    Tabs, 
    Box,
    ThemeProvider,
    createTheme,
    styled,
    CssBaseline,
    Fade,
    CircularProgress,
    Container,
    Paper,
    Tooltip
} from '@material-ui/core';
import { PlayBtn, backButton, closeIcon, confirmImage, filledHeart, outlinedHeart, searchIcon, user } from "./assets";
import MainHeaderComp from '../../../components/src/MainHeaderComp.web';
import FooterWithEvents from '../../../components/src/FooterWithEvents.web';
import { convertTo12HourFormat, getMovieDuration, getTrailersText, goToPrevPage } from '../../../components/src/utilities';
import DateCarousel from '../../../components/src/DateCarousel.web';
import SelectDropDown from '../../../components/src/SelectDropdown.web';
import { AddFavourites } from '../../favourites/src/AddFavourites.web';
import RFHDialog from '../../../components/src/RFHDialog';

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            default: "#121212",
            paper: "#404040"
        },
        text: {
            primary: "#FFF"
        }
    },
    typography: {
        fontFamily: "Poppins, Roboto, sans-serif",
        h1: {
            fontSize: "22px",
            fontWeight: 500,
            "@media (max-width: 600px)": {
                fontSize: "20px",
            }
        },
        h2: {
            fontSize: "32px",
            fontWeight: 500,
            color: "#FFF"
        },
        h3: {
            fontSize: "24px",
            fontWeight: 500,
            color: "#FFF",
        },
        h5: {
            fontSize: "16px",
            color: "#FFF",
            margin: "16px 0"
        },
        h6: {
            fontSize: "18px",
            color: "#FFF",
            fontWeight: 500
        },
        body1: {
            fontSize: "18px",
            color: "#FFF"
        },
        subtitle1: {
            fontSize: "24px",
            fontWeight: 500,
            color: "#FFF",
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                display: "flex",
                justifyContent: "space-between",
                maxWidth: 256,
                width: "100%",
                padding: "12px",
                gap: "12px",
                border: "solid 1px #979797",
                backgroundColor: "#121212",
            },
            arrow: {
                color: "#121212",
                "&::before": {
                    border: "solid 1px #979797",
                }
            }
        },
        MuiTypography: {
            root: {
                "&.seat__type": {
                    textTransform: "uppercase",
                    fontSize: "12px",
                    color: "#fff"
                },
                "&.seat__price": {
                    textTransform: "capitalize",
                    fontSize: "12px",
                    color: "#fff",
                    "& .rupees__icon": {
                        fontFamily: "Roboto, sans-serif"
                    }
                },
                "&.seat__status": {
                    color: "#ffb43a",
                    fontSize: "14px",
                    textTransform: "capitalize"
                }
            }
        }
    }
})

// Customizable Area End

class AuctionBiddingDetail extends AuctionBiddingDetailController {
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    description = () => {
        const { auctionData } = this.state;
        return (
            <>
                <Grid item xs={12} style={{ margin: "20px 0px" }}>
                    <Grid container>
                        <Grid item xs style={{ paddingLeft: "20px" }}>
                            <Typography className="textStyle">{auctionData?.attributes?.description}</Typography>

                        </Grid>
                    </Grid>

                </Grid>

              
            </>

        )
    }

    biddingDetails = () => {
        //istanbul ignore next
        let date = this.state.auctionData?.attributes?.start_time;
        const start_date = moment(date).format('YYYY-MM-DD');
        const current_date = moment(new Date()).format('YYYY-MM-DD');
        return (
            <>
                <Grid item xs={12} style={{ margin: "20px", backgroundColor: "#d3dbe8", padding: "10px", borderRadius: "10px" }}>
                    <Grid container>
                        <Grid item>
                            <Typography className="textStyle">Biding Start Date : {moment(this.state.auctionData?.attributes?.start_time).format('DD/MM/YYYY')}</Typography>
                            <Typography className="textStyle">Starting price : ${this.state.auctionData?.attributes?.minimum_bid}</Typography>

                        </Grid>
                        <Grid item xs style={{ paddingLeft: "20px" }}>
                            <Typography className="textStyle">Biding End Date : {moment(this.state.auctionData?.attributes?.end_time).format('DD/MM/YYYY')}</Typography>
                            <Typography className="textStyle">Current Bid Price : ${this.state.biddingList.length == 0 ? 0 : this.state.auctionData?.attributes?.maximum_bid_placed}</Typography>
                            <Typography className="textStyle">{start_date <= current_date ? this.state.auctionData?.attributes?.time_remaining +` Remaining` : this.state.auctionData?.attributes?.time_remaining} </Typography>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={11} style={{ margin: "20px 0px" }}>
                    <Grid item xs={9}>
                        <Typography>Live Auction</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{this.state.biddingList?.length} Bids made</Typography>
                    </Grid>
                </Grid>
                {this.state?.biddingList.length > 0 && this.state?.biddingList.map((data) => (
                    <Grid item xs={11} key={data.id} style={{ margin: "2px 20px", backgroundColor: "#d3dbe8", padding: "10px", borderRadius: "10px" }}>
                        <Grid container>
                            <Grid item>
                                <img src={!!data?.attributes?.user?.attributes?.profile_image ? data?.attributes?.user?.attributes?.profile_image?.url : user} alt="image" style={{ width: "50px", height: "50px", borderRadius: "20px" }} />
                            </Grid>
                            <Grid item xs style={{ paddingLeft: "20px" }}>
                                <Typography className="textStyle">{data?.attributes?.user?.attributes?.full_name}</Typography>
                                <Typography className="textStyle">{moment(data?.attributes?.updated_at).format('DD/MM/YYYY hh:mm')}</Typography>

                            </Grid>
                            <Grid item >
                                <Typography>${data?.attributes?.amount}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}

            </>
        )
    }

    handlePlaceBidModal = () => {
        
        return (
            <Dialog onClose={this.handlePlaceBid} aria-labelledby="simple-dialog-title" open={this.state.openPlaceBidModal}>
                <Grid container className="modalDiv" direction="column" >
                    <Grid >
                        <img
                            src={"https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Circle-icons-computer.svg/2048px-Circle-icons-computer.svg.png"}
                            style={{ width: "100px", height: "100px", margin: "20px auto", display: "block" }}
                            alt="image" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" align='center'>Place Bid</Typography>
                    </Grid>
                    <Grid item>
                        <TextField id="outlined-basic" label="Bid Amount" variant="outlined" style={{width: "330px"}} onChange={(event) => this.handleTextChange(event.target.value)} required data-testid="bid-amount-textinput"/>
                        {this.state.error != "" && <p style={{ color: "red"}}>{this.state.error}</p>}
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth className="btnFilled" onClick={this.handlePlaceBidConfirmModal} data-testid="place-bid-btn">Place Bid</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            className="btnOutlined"
                            onClick={this.handlePlaceBid}
                        >Cancel</Button>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    _renderBidConfirmationModal = () => {
        
        return (
            <Dialog onClose={this.handleConfirmation} aria-labelledby="simple-dialog-title" open={this.state.placeBidConfirmModal}>
                <Grid container className="modalDiv" direction="column" >
                    <Grid >
                        <img
                            src={confirmImage}
                            style={{ width: "120px", height: "100px", margin: "20px auto", display: "block" }}
                            alt="image" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" align='center'>Confirm Bid</Typography>
                    </Grid>
                    <Grid item>
                    <Typography className="grayText" align='center'>you have placed a bid for ${this.state.bidingAmount} <br/> Should we place this as your Bid?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth className="btnFilled" onClick={this.handleConfirmation} data-testid="place-bid-confirm-btn">Yes, Place My Bid</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            className="btnOutlined"
                            onClick={()=> this.handleCancelConfirmModal()}
                            data-testid="place-bid-cancel-btn"
                        >Cancel</Button>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            swipeToSlide: true,

        };
        
        let date = this.state.auctionData?.attributes?.start_time;
        
        //istanbul ignore next
        const start_date = moment(date).format('YYYY-MM-DD');
        const current_date = moment(new Date()).format('YYYY-MM-DD');
        
        // Customizable Area End

        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Box display="none">
                <Grid container>
                    <Grid item xs={6} style={{ padding: '20px', maxHeight: "600px" }} >
                        <Slider {...settings}>
                            {this.state.auctionData?.attributes?.product_images?.map((imgUrl: { url: string | undefined }) => (
                                <div >
                                    <img style={{ width: "500px", height: "500px" }} src={imgUrl.url} />
                                </div>
                            ))}
                        </Slider>

                        <h2>{this.state.auctionData?.attributes?.product_name}</h2>
                        
                        {start_date <= current_date &&
                            <Button style={{ width: "300px", backgroundColor: "#4287f5", color: "#000" }} onClick={() => this.handlePlaceBid()} data-testid="placeBidBtn">
                                Place Bid
                            </Button>
                        }
                    
                    </Grid>
                    <Grid item xs={6} style={{ minHeight: "calc(100vh - 70px", position: "relative" }}>
                        
                        <Tabs
                            value={this.state.selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.onTabChange}
                            className="tabStyle"
                            classes={{
                                indicator: "indicator"
                            }}
                            data-testid="tab"
                        >

                            <Tab label="Description" />
                            <Tab label="Bidding Detail" />
                        </Tabs>
                        <Grid container style={{ paddingLeft: "20px" }} >
                            {this.state.selectedTab == 0 && this.description()}
                            {this.state.selectedTab == 1 && this.biddingDetails()}
                        </Grid>
                    </Grid>
                </Grid>

                {this.handlePlaceBidModal()}
                {this._renderBidConfirmationModal()}
            </Box>

                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <MainBoxWrapper>

                        <Box display={{ xs: "none", sm: "block" }}><MainHeaderComp /></Box>

                        <Box display={{ xs: "block", sm: "none" }} className='mobile__header'>
                            <img src={backButton} className='back__button' alt='back' onClick={goToPrevPage} />
                        </Box>

                        <Box flex={1}>
                            {
                                this.state.isDataLoading ? (
                                    <Fade unmountOnExit in={this.state.isDataLoading}>
                                        <Box display="flex" justifyContent="center"><CircularProgress className='circular-progress' />
                                        </Box>
                                    </Fade>
                                ) : (
                                    <>
                                        <Box className="hero-section-wrapper">
                                            <img alt="movie-poster" src={this.state.movieData.attributes.image_url} />

                                            <Container>
                                                <Box className='movie-wrapper'>
                                                    <Box className='movie-title'>
                                                        <Typography variant="h1">{this.state.movieData.attributes.name}</Typography>
                                                            <Box className='movie-detail-wrapper' display={{ xs: "none", sm: "block" }}>
                                                            {this.state.movieData.attributes.category_type.split(", ").map(category => <span key={category} className='category-name'>{category}</span>)}
                                                            <span>
                                                                {configJSON.UA}&nbsp;&nbsp;
                                                                {configJSON.Duration}
                                                                {getMovieDuration(this.state.movieData.attributes.duration_in_minutes)}
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    <Box className="trailer-wrapper">
                                                        <img src={PlayBtn} alt="play-button" className='play-btn' onClick={this.redirectToTrailer} data-test-id="redirect-to-trailer" />
                                                        <span className='trailer-text'>
                                                            {`${getTrailersText(this.state.movieData.attributes.youtube_links)} (${this.state.movieData.attributes.youtube_links.length})`}
                                                        </span>
                                                    </Box>
                                                </Box>
                                            </Container>
                                        </Box>

                                        <Paper elevation={0} className='filter__wrapper'>
                                            <Container>
                                                <Grid container>
                                                    <Grid item xs={12} md={7} className='date__carousel'>
                                                        <DateCarousel
                                                            selectedDate={this.state.filterData.date}
                                                            noOfDaysToDisplay={20}
                                                            onChange={this.handleDateChange}
                                                            startDate={this.initialDate()}
                                                        />
                                                    </Grid>
                                                    <Grid item md={5} xs={false}>
                                                        <Box className="filter__child__wrapper" display={{ xs: "none", md: "flex" }}>

                                                            {
                                                                this.state.isSearchOpen ? (
                                                                    <Box className='open__search'>
                                                                        <img
                                                                            src={searchIcon}
                                                                            className='search__icon'
                                                                            alt='search'
                                                                        />
                                                                        <input type="text"
                                                                            name='search'
                                                                            placeholder='Search Cinema'
                                                                            className='search__input'
                                                                            data-test-id="searchText"
                                                                            value={this.state.searchText}
                                                                            onChange={(event) => this.handleSearchChange(event.target.value)}
                                                                        />
                                                                        <img
                                                                            src={closeIcon}
                                                                            data-test-id="crossbtn"
                                                                            className="close__icon"
                                                                            onClick={this.closeSearch}
                                                                            alt='close'
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <>
                                                                        <SelectDropDown
                                                                            value={this.state.filterData.languageId}
                                                                            options={this.state.languageOptions}
                                                                            elementWidth="140px"
                                                                            handleChange={this.handleLanguageChange}
                                                                        />
                                                                        <SelectDropDown
                                                                            value={this.state.filterData.formatId}
                                                                            options={this.state.formatOptions}
                                                                            elementWidth="130px"
                                                                            handleChange={this.handleFormatChange}
                                                                        />
                                                                        <Box data-test-id="open-search-icon" onClick={this.openSearch}>
                                                                            <img
                                                                                src={searchIcon}
                                                                                className='search__icon'
                                                                                alt='search'
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )
                                                            }
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </Paper>

                                        <Container>
                                            <Grid container data-test-id="time-slot-listing-wrapper">
                                                {
                                                    this.state.isTimeSlotLoading ? (
                                                        <Grid item xs>
                                                            <Fade in={this.state.isTimeSlotLoading} unmountOnExit>
                                                                <Box justifyContent="center" display="flex">
                                                                    <CircularProgress className='circular-progress' />
                                                                </Box>
                                                            </Fade>
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            {
                                                                this.state.timeSlots.length > 0 ? this.state.timeSlots.map((screen) => {

                                                                    return (
                                                                        <Grid key={screen.screenName} item xs={12} >
                                                                            <Box mt={3} p={3} className='movie__timeslot__wrapper'>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} md={4} className='d__flex'>
                                                                                        <AddFavourites
                                                                                            addFavourites={{
                                                                                                heartIcon: outlinedHeart,
                                                                                                filledHeart: filledHeart,
                                                                                                imageStyle: webStyle.iconStyle,
                                                                                                favouriteable_id: screen.theatreId.toString(),
                                                                                                favouriteable_type: "BxBlockReservations::Theatre",
                                                                                                tooltipDirection: "bottom",
                                                                                                isFavourite: screen.isFavourite || false
                                                                                            }}
                                                                                            navigation={this.props.navigation}
                                                                                            id="favourites"
                                                                                        />

                                                                                        <Box className='theater__name' data-test-id="theater-name">
                                                                                            {screen.theaterName}
                                                                                            <span></span>
                                                                                        </Box>

                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={8}>
                                                                                        <Box className="time__wrapper" data-test-id="box-wrapper">
                                                                                            {
                                                                                                screen.timeSlots.map((slot) => (
                                                                                                    <Tooltip
                                                                                                        key={slot.screenId}
                                                                                                        arrow
                                                                                                        title={
                                                                                                            slot.availability.map((availability) => (
                                                                                                                <Box key={availability.seat_type} style={webStyle.TooltipWrapper}>
                                                                                                                    <Typography className='seat__type'>{availability.seat_type}</Typography>
                                                                                                                    <Typography className='seat__price'>
                                                                                                                        <span className='rupees__icon'>{"\u20B9"}&nbsp;</span>
                                                                                                                        {availability.price.toFixed(2)}
                                                                                                                    </Typography>
                                                                                                                    <Typography className='seat__status'>{availability.seat_status}</Typography>
                                                                                                                </Box>
                                                                                                            ))
                                                                                                        }
                                                                                                    >
                                                                                                        <Button
                                                                                                            key={slot.screenId}
                                                                                                            data-test-id="time-slot"
                                                                                                            className="timeslot__button"
                                                                                                            onClick={() => this.selectTimeSlot(slot.screenId, screen.theaterName, screen.screenName, slot.timeSlot)}
                                                                                                        >
                                                                                                            {convertTo12HourFormat(slot.timeSlot)}
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                ))
                                                                                            }
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    )

                                                                }) : (
                                                                    <Grid item xs={12}>
                                                                        <Box mt={3}>
                                                                            <Typography align='center' variant="h6">
                                                                                No data available
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </>
                                                    )

                                                }

                                            </Grid>
                                        </Container>
                                    </>
                                )
                            }
                        </Box>

                        <Box><FooterWithEvents /></Box>

                        <RFHDialog
                            data-test-id="terms-and-condition"
                            open={this.state.isTermsAndConditionOpen}
                            handleClose={this.handleCloseTermsAndCondition}
                            maxWidth="md"
                            disableEscapeKeyDown={true}
                            showTitleDivider={true}
                            dialogActions={(
                                <>
                                    <RFHDialogButton data-test-id="cancel-btn" variant='outlined' fullWidth onClick={this.handleCloseTermsAndCondition}>
                                        {configJSON.Cancel}
                                    </RFHDialogButton>
                                    <RFHDialogButton data-test-id="accept-btn" fullWidth onClick={this.acceptTermsAndCondition}>
                                        {configJSON.Accept}
                                    </RFHDialogButton>
                                </>
                            )}
                            dialogContent={(
                                <DialogWrapper mt={2} data-test-id="terms-condition-content">
                                    <Typography className='terms__conditions' dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionContent }}>
                                    </Typography>
                                </DialogWrapper>
                            )}
                            dialogTitle={(
                                <DialogWrapper>
                                    <Typography className='terms__condition__title'>{configJSON.TermsAndCondition}</Typography>
                                </DialogWrapper>
                            )}
                        />

                    </MainBoxWrapper>

                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainBoxWrapper = styled(Box)({
    flexDirection: "column",
    display: "flex",
    minHeight: "100vh",
    "& .circular-progress": {
        marginTop: "20px",
        color: "#ffb43a"
    },
    "& .mobile__header": {
        padding: "12px",
        "& .back__button": {
            width: "24px",
            height: "24px"
        }
    },
    "& .hero-section-wrapper": {
        position: "relative",
        height: "340px",
        "& > img": {
            height: "100%",
            objectFit: "cover",
            width: "100%",
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: -100
        },
        "&::before": {
            content: '""',
            position: "absolute",
            left: 0,
            top: "-1px",
            height: "101%",
            width: "100%",
            backdropFilter: "blur(8px)",
            background: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.9),rgba(0,0,0,0.8),rgba(0,0,0,0.4), rgba(0,0,0,0.1))",
            zIndex: -99,
        },
        "& .movie-wrapper": {
            position: "relative",
            height: "100%",
            "& .movie-title": {
                position: "absolute",
                bottom: "32px",
                left: 0,
                width: "100%",
                "& .movie-detail-wrapper": {
                    marginTop: "28px",
                    "& .category-name": {
                        fontSize: "14px",
                        padding: "8px",
                        border: "solid 0.5px #ffb43a",
                        borderRadius: "1px",
                        marginRight: "16px"
                    },
                }
            },
            "& .trailer-wrapper": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                "& .play-btn": {
                    width: "42px",
                    height: "42px"
                },
                "& .trailer-text": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: 500
                }
            },
        }
    },
    "& .filter__wrapper": {
        padding: "0.75rem 0",
        "& .date__carousel": {
            "@media (max-width: 600px)": {
                paddingLeft: "12px"
            }
        },
        "& .filter__child__wrapper": {
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
            gap: "24px",
            "& .search__icon": {
                height: '28px',
                width: '28px',
                cursor:"pointer",
            },
            "& .open__search": {
                height: '48px',
                maxWidth: "597px",
                width: "100%",
                borderRadius: '4px',
                backgroundColor: '#404040',
                padding: '0px 6px 0px 10px',
                display: 'flex',
                alignItems: 'center',
                position: "absolute",
                border: "solid 0.4px #979797",
                zIndex: 10
            },
            "& .search__input": {
                height: 48,
                maxWidth: "597px",
                border: 'none',
                width: "100%",
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                fontFamily: "Poppins",
                fontSize: '14px',
                margin: '0 0 0 8px',
                color: '#fff',
            },
            "& .close__icon": {
                height: 20,
                width: 20,
                right: 10,
                position: "absolute",
                cursor: "pointer",
            }
        },
    },
    "& .movie__timeslot__wrapper": {
        backgroundColor: "#404040",
        color: "white",
        borderRadius: "8px",
        "& .d__flex": {
            display: "flex",
            alignItems: "center",
            height:"min-content"
        },
        "& .theater__name": {
            fontFamily: "Poppins",
            fontSize: "1.25rem",
            fontWeight: 600,
            letterSpacing: "0.1px",
            color: "#FFF",
        },
        "& .time__wrapper": {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(114px, 134px))",
            gap: "1rem",
            borderRadius: "12px",
            border: "solid 1px #97979",
            backgroundColor: "#121212",
            padding: "24px",
            "& .timeslot__button": {
                padding: "16px 13px",
                minWidth: "114px",
                fontFamily: "Poppins",
                backgroundColor: "#2f2617",
                fontSize: "1.125rem",
                borderRadius: "8px",
                border: "solid 1px #ffb53a",
                color: "#FFF",
                "&:hover": {
                    backgroundColor: "#ffb53a",
                    color: "#000",
                }
            },
            "@media (max-width: 600px)": {
                padding: "18px",
                gridTemplateColumns: "1fr 1fr",
            }
        }
    },
    "@media (max-width: 600px)": {
        "& .hero-section-wrapper": {
            height: "280px",
            "& .movie-wrapper": {
                "& .movie-title": {
                    marginBottom: "20px !important",
                    bottom: "0px !important",
                    "& .movie-detail-wrapper": {
                        marginTop: "14px !important",
                        "& .category-name": {
                            fontSize: "12px !important",
                            padding: "4px !important",
                            marginRight: "14px !important"
                        },
                    }
                },
                "& .trailer-wrapper": {
                    "& .play-btn": {
                        width: "24px !important",
                        height: "24px !important"
                    },
                    "& .trailer-text": {
                        fontSize: "14px !important",
                    }
                },
            }
        }
    }
})

const RFHDialogButton = styled(Button)({
    textTransform: "capitalize",
    color: "#000",
    backgroundColor: "#ffb43a",
    borderRadius: "12px",
    height: "56px",
    fontSize: "18px",
    "&:hover": {
        fontWeight: 600,
        backgroundColor: "#ffb43a"
    },
    "&.MuiButton-outlined": {
        fontWeight: 300,
        backgroundColor: "#373737",
        border: "solid 1px #ffb43a",
        borderRadius: "12px",
        color: "#FFF",
        marginRight: "24px"
    },
    "@media (max-width: 600px)": {
        height: "48px !important",
        fontSize: "14px !important",
        "&.MuiButton-outlined": {
            marginRight: "18px !important"
        }
    }
})

const DialogWrapper = styled(Box)({
    "& .terms__conditions": {
        color: "#FFF",
        fontSize: "16px",
        fontFamily: "Poppins",
    },
    "& .terms__condition__title": {
        textAlign: "center" as const,
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "Poppins, sans-serif",
        color: "#FFF",
    },
    "@media (max-width: 600px)": {
        "& .terms__conditions": {
            fontSize: "12px !important",
        },
        "& .terms__condition__title": {
            fontSize: "14px !important",
        }
    }
})

const webStyle = {
    iconStyle: {
        width: "20px",
        cursor: "pointer",
        marginRight: 20,
    },
    termsAndConditionTitle: {
        textAlign: "center" as const,
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "Poppins, sans-serif",
        color: "#FFF",
    },
    termsAndConditionContent: {
        color: "#FFF",
        fontSize: "16px",
        fontFamily: "Poppins",
    },
    TooltipWrapper: {
        textAlign: "center" as const
    }
}

// Customizable Area End

export default AuctionBiddingDetail