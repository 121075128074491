import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCat:any;
  vogueList:any;
  loading:boolean;
  catTypeList:any;
  pageCount:number,
  currentPage:number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VogueListController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    categoryListCall : string =""
    vogueListCall : string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectedCat:"",
            vogueList:[],
            loading:true,
            catTypeList:[],
            pageCount:1,
            currentPage:1,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (responseJson) {
          if (apiRequestCallId === this.categoryListCall) {
            this.setState({ catTypeList: responseJson.data })
          }
          if (apiRequestCallId === this.vogueListCall) {
            this.setState({
              pageCount: responseJson.meta.pagination.total_pages,
              vogueList: responseJson.data,
            });
            this.setState({ loading: false });
          }
        }
      }
        // Customizable Area End
    }

    // Customizable Area Start

    handleBack = () => {
      this.props.navigation.goBack();
    }

    navigateToDetails = (id:any) =>{
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "VogueDetails");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(msg);
    }

    handleOnClickCategory = (cat:any) =>{
      this.setState({selectedCat:cat}, ()=> this.getVogueList());
    }

    handleVogueOnPageChange = (e: any) =>{
      this.setState({ currentPage: e.selected + 1 }, ()=> this.getVogueList())
    }

  getCategoryList = async() => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken)
    this.categoryListCall = callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: configJSON.vogueCategoryListAPIEndPoint,
      headers: { "token": authToken || "guest_user" },
    }, runEngine)
  }

  getVogueList = async() =>{
    this.setState({loading:true})
    const authToken = await getStorageData(LocalStorageKeys.LoginToken)
    this.vogueListCall = callApi({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.vogueListAPIEndPoint}?per=6&page=${this.state.currentPage}${this.state.selectedCat && "&vogue_category_id="+this.state.selectedCat}`,
      headers: { "token": authToken || "guest_user" },
    }, runEngine)
  }

    async componentDidMount() {
      this.getCategoryList();
      this.getVogueList();
    }
    // Customizable Area End
}
