import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { LocalStorageKeys, RequestFilterOption } from "../../../components/src/enums.web";
import { IRequestedBookingResponse } from "../../../components/src/interfaces.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Toolkit";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  requestList: IRequestedBookingResponse[]
  isLoading: boolean
  anchorEl: HTMLElement | null,
  selectedFilter: RequestFilterOption
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestedBookingApiCallId: string = "";
  liveRequestedBookingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      requestList: [],
      isLoading: true,
      anchorEl: null,
      selectedFilter: RequestFilterOption.All
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId && resJson) {

      if (apiRequestCallId === this.requestedBookingApiCallId) {
        this.setState((prevState) => ({ 
          requestList: this.state.selectedFilter === RequestFilterOption.All ? 
          [...prevState.requestList, ...resJson.data as IRequestedBookingResponse[]].sort((a, b) => a.attributes.status === RequestFilterOption.RequestPending ? -1 : 1) :
          resJson.data as IRequestedBookingResponse[], 
          isLoading: false 
        })
        )
      }

      if (apiRequestCallId === this.liveRequestedBookingApiCallId) {
        this.setState((prevState) => ({ 
          requestList: this.state.selectedFilter === RequestFilterOption.All ? 
          [...prevState.requestList, ...resJson.data as IRequestedBookingResponse[]].sort((a, b) => a.attributes.status === RequestFilterOption.RequestPending ? -1 : 1) :
          resJson.data as IRequestedBookingResponse[], 
          isLoading: false 
        })
        )
      }

    }

  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null })

  handleFilterChange = (newValue: RequestFilterOption) => {

    if (newValue === this.state.selectedFilter) return

    this.setState({ selectedFilter: newValue, isLoading: true, requestList: [] }, () => {
      this.getRequestedBookings(newValue)
      this.handleClose()
    })
  }

  redirectToConfirmPayment = (ticketId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ConfirmPayment");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), ticketId);
    this.send(msg);
  }

  redirectToLiveRequest = (ticketId: number) => {

    const ticketData = this.state.requestList.find((request) => +request.id === ticketId);

    if(ticketData) {
      const {
        attributes: {
          slot_date,
          language_id,
          screen_type_id,
          movie_id,
          screening_id,
          theatre_name,
          movie_name
        }
      } = ticketData

      const payload = {
        slot_date,
        language_id,
        screen_type_id,
        screening_id,
        movie_id,
        theatre_name,
        movie_name
      }

      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "LiveRequest");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationToLiveRequestPayload), { payload });
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

      this.send(msg);
    }


  }

  async getLiveRequestedBooking() {

    const token = await getStorageData(LocalStorageKeys.LoginToken);
    let url = configJSON.GetLiveRequestedBookingsApiEndPoint as string;

    this.liveRequestedBookingApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: url,
      headers: { "token": token },
    }, runEngine)

  }

  async getRequestedBookings(filter = RequestFilterOption.All) {

    const token = await getStorageData(LocalStorageKeys.LoginToken);
    let url = configJSON.GetRequestedBookingsApiEndPoint as string;
    
    if(filter !== RequestFilterOption.All && filter !== RequestFilterOption.ViewRequests) url = url.concat(`?search=${filter}`);
    else if (filter === RequestFilterOption.ViewRequests) {
      this.getLiveRequestedBooking()
      return
    } else if (filter === RequestFilterOption.All) this.getLiveRequestedBooking()

    this.requestedBookingApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: url,
      headers: { "token": token },
    }, runEngine)

  }

  async componentDidMount() {
    this.getRequestedBookings()
  }

  // Customizable Area End
}
