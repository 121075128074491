Object.defineProperty(exports, "__esModule", {
  value: true,
})

// Customizable Area Start
exports.validationApiContentType = "application/json"
exports.getApiMethodType = "GET"
exports.postApiMethodType = "POST"
exports.exampleApiContentType = "application/json"
exports.Referal_EndPoint = "/bx_block_referrals/referral_codes"
exports.Login_EndPoint = "/bx_block_login/logins";
exports.USERNAME = "buraktest1@gmail.com";
exports.PASSWORD = "123456";

exports.ReferAndEarnTitle = "Refer and Earn";
exports.ReferCodeApiEndPoint = "bx_block_share/referral_codes";
exports.RewardEarn = "Reward Earn";
exports.ReferContent = "will be added to your Wallet when your friends join Ticket Xchange and activate their account using your Invite.";
exports.ReferralCode = "Your Referral Code:";
// Customizable Area End
