import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import { handleFirebase } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";

export const firebaseConfigJson = require("./firebase.config")
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showSignup?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  isRegistration: boolean;
  modelOpen: boolean;
  isShowPassword: boolean;
  isChecked: boolean;
  isShowConfirmPassword: boolean;
  phone: any;
  otpVerify: boolean
  sms_account: boolean,
  withGoggle: boolean,
  withEmail: boolean,
  SignUpType: string,
  signUpwith: boolean,
  emailValue: any,
  otp: any,
  minutes: any,
  otpError: string,
  seconds: any,
  ToLogin: boolean,
  phoneError: boolean,
  phoneErr: string,
  email: string,
  password: string,
  confirmPassword: string
  emailError: any,
  passwordError: any,
  confirmPasswordError: any,
  isTermsConditionPrivacyPolicy: boolean,
  phoneToken:any,
  termsConditionPrivacyPolicyData: any,
  isOTPExpired: boolean,
  loading:boolean,
  isReferralCodeOpen: boolean
  isReferralCodeErrorMessage: string,
  isReferralCodeApplied: boolean,
  referralCode: string,
  prevStep: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
interface ErrorState {
  [objKey: string]: string;
}

type Errors = {
  [objKey: string]: boolean;
}

export default class UserSignUpController
  extends BlockComponent<Props, S, SS> {
  signUpPhoneCallId: any;
  signUpEmailCallId: any;
  signUpGoogleCallId: any;
  otpConfirmCallId: any;
  resendCodeCallId: any;
  getTermsAndContions:any;
  interval: null;
  getPrivacyPolicy:any;
  passwordRegex: RegExp;
  verifyReferralCodeApiCallId: string = "";

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      isRegistration: false,
      modelOpen: true,
      isShowPassword: false,
      isChecked: true,
      isShowConfirmPassword: false,
      phone: null,
      otpVerify: false,
      sms_account: false,
      withEmail: false,
      withGoggle: false,
      signUpwith: true,
      SignUpType: '',
      emailValue: null,
      otp: '',
      minutes: 1,
      otpError: '',
      seconds: 59,
      ToLogin: false,
      phoneError: false,
      phoneErr:'',
      email: '',
      password: '',
      confirmPassword: '',
      emailError: '',
      passwordError: '',
      confirmPasswordError: '',
      isTermsConditionPrivacyPolicy:false,
      phoneToken:'',
      termsConditionPrivacyPolicyData: {
        heading:"",
        description:""
      },
      isOTPExpired: false,
      loading:false,
      isReferralCodeOpen: false,
      isReferralCodeApplied: false,
      isReferralCodeErrorMessage: "",
      referralCode: "",
      prevStep: ""
      // Customizable Area End
    };
    this.interval = null
    this.passwordRegex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s:])([^\s]){8,}$/)
  }

  // Customizable Area Start
  //When facebook sends back the reponse this gets called

  handleClose = async () => {
    this.setState({ modelOpen: false, ToLogin: false })
    await this.props.showSignup()
  }

  changeToLogin = () => {
    this.setState({ 
      ToLogin: true, 
      modelOpen: false,
      isShowPassword: false,
      isChecked: true,
      isShowConfirmPassword: false,
      phone: null,
      otpVerify: false,
      sms_account: false,
      withEmail: false,
      withGoggle: false,
      signUpwith: true,
      SignUpType: '',
      emailValue: null,
      otp: '',
      minutes: 1,
      otpError: '',
      seconds: 59,
      phoneError: false,
      phoneErr:'',
      email: '',
      password: '',
      confirmPassword: '',
      emailError: '',
      passwordError: '',
      confirmPasswordError: '',
      isTermsConditionPrivacyPolicy:false,
      phoneToken:'',
      termsConditionPrivacyPolicyData: {
        heading:"",
        description:""
      },
      isOTPExpired: false,
      loading:false,
      isReferralCodeOpen: false,
      isReferralCodeApplied: false,
      isReferralCodeErrorMessage: "",
      referralCode: "",
      prevStep: ""
    })
  }
  closeLogin = () => {
    this.setState({ ToLogin: false, modelOpen: true })
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.verifyReferralCodeApiCallId:
          if (resJson.message == "Referral code is valid") {
            if (this.state.prevStep == "sms_account") {
              this.setState({
                isReferralCodeOpen: false,
                isReferralCodeApplied: true,
                sms_account: true,
              })
            } else {
              this.setState({
                isReferralCodeOpen: false,
                isReferralCodeApplied: true,
                withEmail: true
              })
            }
          } else {
            this.setState({ isReferralCodeErrorMessage: resJson.message })
          }
          break;

        case this.getPrivacyPolicy:
          this.handleReciveTermsAndCondition(resJson, "Privacy Policy");
          break;

        case this.signUpEmailCallId:
          this.handleReceive(resJson);
          break;

        case this.signUpGoogleCallId:
          this.handleGoogleReceive(resJson);
          break;

        case this.getTermsAndContions:
          this.handleReciveTermsAndCondition(resJson, "Terms & Conditions");
          break;

        case this.signUpPhoneCallId:
          this.handlePhoneReceive(resJson);
          break;

        case this.otpConfirmCallId:
          this.handleOtpReceive(resJson);
          break;

        case this.resendCodeCallId:
          this.handleresend(resJson);
          break;
      }
    } else {
      runEngine.debugLog("GOIT");
    }
  }
  // Customizable Area Startpin

  handleReciveTermsAndCondition = (resJson:any, heading:string) => {
    this.setState({
      loading:false,
      termsConditionPrivacyPolicyData: {
      description:resJson.data.description,
      heading:heading,
    },
  })
  }

  handleOtpReceive = (resJson: any) => {
    if (resJson && resJson.errors && resJson?.errors[0]?.pin) {
      this.setState({ otpError: "Incorrect OTP" })
    } else {
      localStorage.setItem('login',resJson?.meta?.token)
      this.state.isReferralCodeApplied && setStorageData(LocalStorageKeys.isReferralSuccess, "true")
      this.props.showSignup()
    }
  }

  handleresend = (resJson: any) => {
    if(resJson && resJson.data){
      this.setState({seconds: 59, minutes: 1, otp:'',otpError: ""});
      if(this.state.isOTPExpired) this.handleTimer()
    } else if (resJson && resJson.errors && resJson?.errors[0]?.pin){
      this.setState({ otpError: "Incorrect OTP", otp:''})
    }
   
  }

  handlePhoneReceive = (resJson: any) => {
    if (resJson && resJson.errors && resJson.errors.length) {
      this.setState({ phoneError: true })
      this.setState({phoneErr: resJson.errors[0].phone_number})
    } else if (resJson && resJson.message ){      
      if(!resJson.is_verified) this.setState({ otpVerify: true, sms_account: false, phoneToken: resJson.token })
      else this.setState({ phoneErr: resJson.message, phoneError: true })
    }
    else {
      const token = resJson.meta.token;
      this.setState({ ...this.state, otpVerify: true, sms_account: false,phoneToken:token })
    }
  }

  handleReceive = async (resJson: any) => {
    if (resJson && resJson.errors && resJson.errors.length) {
      this.setState({ emailError: true })
    } else if (resJson && resJson.email && resJson.email[0]) {
      this.setState({ emailError: resJson.email[0] })
    }else if (resJson?.message === configJSON.emailExitMes){
      this.setState({ emailError: resJson.message })
    }
    else {
      const token = resJson.meta.token;
      localStorage.setItem('login', token);
      this.state.isReferralCodeApplied && setStorageData(LocalStorageKeys.isReferralSuccess, "true")
      this.props.showSignup()
    }

  }

  handleGoogleReceive = (resJson: any) => {
    const token = resJson.meta.token;
    localStorage.setItem('login', token);
    this.props.showSignup()
  }


  // Customizable Area Start

  //Create response callback.


  showConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
  };

  showPasswordChange = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };


  handleCheckChange = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }



    handlePhoneChange = (value: any) => {
    let mobileValue = value?.replace(/\D/g, '');
    if (mobileValue?.length <= 10) {
      this.setState({ phone: mobileValue, phoneError: false })
    } 
    this.setState({ phoneErr:"Enter a valid 10 digit Mobile Number" })
  }

  handleLoginWith = (isSelected: any) => {
    if (isSelected === "sms_account") {
      this.setState({ sms_account: true, signUpwith: false })
    }
    if (isSelected === "email_account") {
      this.setState({ withEmail: true, signUpwith: false })
    }
    this.setState({ SignUpType: isSelected })
  }

  handleEmailValue = (value: any) => {
    this.setState({ email: value, emailError: '' })
  }

  handleEmailValidation = () => {
    if (this.state.email === "" || !this.state.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)) {
      this.setState({ emailError: "Enter a Valid Email id" });
    }
  }

  validateConfirmPassword = () => {
    if (this.state.confirmPassword === "" || this.state.confirmPassword !== this.state.password) {
      this.setState({ confirmPasswordError: "Password does not match" });
    }
  }

  handlePasswordValue = (value: any) => {
    const passwordRegex = this.passwordRegex;
    const password = value;
  
    // Check if the entered password meets the criteria
    if (value === '') {
      this.setState({
        password: value,
        passwordError: configJSON.PasswordRequiredMessage
      })
    }
    else if (!passwordRegex.test(password)) {
      this.setState({
        password: value,
        passwordError: configJSON.errorsMessage,
      });
    } else {
      this.setState({
        password: value,
        passwordError: '', // Clear any existing password errors
      });
    }
  }

  handleConPasswordValue = (value: any) => {
    this.setState({ confirmPassword: value, confirmPasswordError: '' })
  }

  handleTimer = () => {
    const interval = setInterval(() => {
      const { minutes, seconds } = this.state;
      if (seconds > 0) {
        this.setState({ seconds: seconds - 1, isOTPExpired: false })
      } else {
        if (minutes === 0) {
          this.setState({ isOTPExpired: true })
          clearInterval(interval);
        } else {
          this.setState({ seconds: 59, minutes: minutes - 1, isOTPExpired: false });
        }
      }
    }, 1000)
  }

  handleSignUp = () => {
    if (this.state.SignUpType === "sms_account") {
      if (this.state.phone === null || !this.state.phone.match(/\d{10,10}/)) {
        this.setState({ phoneError: true })
        this.setState({phoneErr: this.state.phone?.length > 0 ? configJSON.phoneNumberError :"Phone number can not be empty"})
      } else if (this.state.isChecked === false) {
        return
      } else {
        this.verfiedPhoneNumber()
        this.handleTimer()
      }
    }
  }

  handleOtpValue = (value: any) => {
    const onlyNumbers = /^\d*$/;
    if (onlyNumbers.test(value) || value === '') {
        this.setState({ otp: value, otpError: '' });
    } else {
        this.setState({ otpError: 'Please enter only numbers.' });
    }
}




  verfiedPhoneNumber = () => {
    const header = {
      "Content-Type": 'application/json',

    };
    const httpBody = {
      data: {
        "type": "sms_account",
        "attributes": {
          "full_phone_number": `+91${this.state.phone}`,
          "referral_code": this.state.referralCode
        }
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpPhoneCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpPhoneEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOTP = () => {

    if(this.state.otp?.length !== 4) {
      this.setState({ otpError: "OTP Required"})
      return 
    }
    
    const token = this.state.phoneToken
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const httpBody = {
      "pin": this.state.otp,
      "device": "android"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otpConfirmCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpConfirmEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resendCode = () => {
    const token = this.state.phoneToken
    const header = {
      "token": token,
      "Content-Type": 'application/json'
    };
    const httpBody = {
      "data": {
        "attributes": {
          "full_phone_number": `+91${this.state.phone}`
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendCodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendCodeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpCallMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  renderErrorMessageforProfile = (Message: any) => {
    if (Message) {
      return Message
    }
  }

  handleSave = () => {
    const errors: Errors = {
      confirmPassword: this.state.confirmPassword === '' || this.state.confirmPassword !== this.state.password,
      password: this.state.password === "" || !this.state.password.match(this.passwordRegex),
      email: this.state.email === "" || !this.state.email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
    };
    const confimInvalidError = this.state.confirmPassword === '' ? 'Confirm password is required.' : 'Password does not match.'
    const invalidError = this.state.password === "" ? 'Password Required' : configJSON.errorsMessage;

    const errorMessages: ErrorState = {
      email: "Enter a valid Email id.",
      password: invalidError,
      confirmPassword: confimInvalidError,
    };

    let isErrors = false;
    const error: ErrorState = {};

    Object.keys(errors).forEach((objKey) => {
      if (errors[objKey]) {
        isErrors = true;
        error[`${objKey}Error`] = errorMessages[objKey];
      } else {
        error[`${objKey}Error`] = "";
      }
    });

    if (isErrors) {
      this.setState((prevState) => ({
        ...prevState,
        ...error,
      }));
    } else if (this.state.isChecked === false) {
      return
    }
    else {
      this.verfiedEmailId();
    }
  }


  verfiedEmailId = () => {
    const httpBody = {
      data: {
        "type": "email_account",
        "attributes": {
          "email": this.state.email,
          "password": this.state.password,
          "confirm_password": this.state.confirmPassword,
          "referral_code": this.state.referralCode,
        }
      },
    };
    const header = {
      "Content-Type": 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpEmailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  googleSignupApi = (email: any, uid: any) => {
    const header = {
      "Content-Type": 'application/json',
    };

    const httpBody = {
      data:{
            "type": "social_account",
            "attributes": {
                "email": email,
                "unique_auth_id": uid,
                "platform": "google"
              }
          }        
    
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpGoogleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpGoogleEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpCallMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  googleSignup = () => {

    const customProvider =
      firebaseConfigJson.provider.setCustomParameters(
        {
          prompt: "select_account",
        }
      );
      handleFirebase(firebaseConfigJson.auth, customProvider, this.googleSignupApi)
   
  }

  handleOnCloseTermsConditionPrivacyPolicy = () => {
    this.setState({isTermsConditionPrivacyPolicy: false});
  }

  handleOnClickPrivacyPolicy = () => {

    this.setState({
      loading: true,
      termsConditionPrivacyPolicyData: {
        description: "",
        heading: "",
      }
    });

    const header = {
      "Content-Type": 'application/json',
    };

    const method = "GET"

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PrivacyPolicyURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isTermsConditionPrivacyPolicy: true});
  }

  handleOnClickTermsAndCondition = () => {

    this.setState({
      loading: true,
      termsConditionPrivacyPolicyData: {
        description: "",
        heading: "",
      }
    });

    const header = {
      "Content-Type": 'application/json',
    };
    const method = "GET"

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsAndContions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TermsAndConditionsURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isTermsConditionPrivacyPolicy: true});
  }

  openReferralCodeDialog = (step: string) => {
    this.setState({ 
      signUpwith: false, 
      isReferralCodeOpen: true, 
      referralCode: "", 
      isReferralCodeErrorMessage: '', 
      isReferralCodeApplied: false,
      sms_account: false,
      withEmail: false,
      prevStep: step
    })
  }

  handleReferralCodeChange = (value: string) => this.setState({ referralCode: value })

  removeReferralCode = () => {
    this.setState({
      referralCode: "",
      isReferralCodeApplied: false,
      isReferralCodeErrorMessage: ""
    })
  }

  verifyCode = () => {
    this.verifyReferralCodeApiCallId = callApi({ 
      contentType: configJSON.contentTypeApi, 
      method: configJSON.apiGetMethodType, 
      endPoint: (configJSON.verifyReferralCodeEndpoint as string).replace("{CODE}", this.state.referralCode),
      headers: { "token": "guest_user" }
    }, runEngine);
  }

  // Customizable Area End
}