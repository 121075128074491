import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
// Customizable Area Start
import { Review, ReviewDislikeResponse, ReviewLikeResponse } from "../../../components/src/interfaces.web";
import { getLastPartOfURL } from "../../../components/src/utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  castList: any;
  crewList: any;
  crewError: string;
  isCrewListLoaded: boolean;
  isCastListLoaded: boolean;
  castError: string;
  reviewList: Review[];
  tags: any;
  reviewError: string;
  isReviewListLoaded: boolean;
  dummyData: any;
  genreList: any;
  itemData: any;
  getMovie: any,
  selected: string,
  selectedSec: string,
  languageModal: boolean,
  isModalOpen: boolean;
  isLiked: boolean;
  averageRating: {
    average_rating: number | null,
    ratings: 0,
    reviews: 0
  };
  filterReviewList: Review[];
  selectedHash: any;
  isReviewList: any;
  selectedScreenId: number,
  selectedIdForLanguage: number,
  isMovieDetailsLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class MovieDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCastCallId: string = "";
  apiCrewCallId: string = "";
  apiReviewCallId: string = "";
  apiMovieCallId: string = "";
  likeApiCalledId: string = "";
  removeLikeApiCalledId: string = "";
  dislikeApiCalledId: string = "";
  removeDislikeApiCalledId: string = "";
  averageApiCalledId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      castList: [],
      crewList: [],
      crewError: '',
      isCrewListLoaded: false,
      isCastListLoaded: false,
      castError: '',
      reviewList: [],
      reviewError: '',
      itemData: [],
      isReviewListLoaded: false,
      genreList: [],
      // this state will be removed once we get dynamic data
      dummyData: [],
      getMovie: '',
      languageModal: false,
      tags: [],
      selected: '',
      selectedSec: '',
      isModalOpen: false,
      isLiked: false,
      averageRating: {
        average_rating: null,
        ratings: 0,
        reviews: 0
      },
      filterReviewList: [],
      selectedHash: {},
      isReviewList: [],
      selectedIdForLanguage: 0,
      selectedScreenId: 0,
      isMovieDetailsLoading: true
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson != null) {
        if (responseJson) {
          await this.parseApiSuccess(responseJson, apiRequestCallId);
        }
      }
      if (apiRequestCallId === this.apiMovieCallId) {
        this.handleReceive(responseJson);
      }
    }
    else {
      runEngine.debugLog("GOIT");
    }
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    const movieId = getLastPartOfURL();

    this.getReviewList();
    this.getAverageRating();
    this.setState({ getMovie: movieId }, () => {
      this.getMovieDetails(); this.getCrewList();
      this.getCastList();
    })
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    if (loginToken) {
      await setStorageData("isGuest", 'false')
    } else {
      await setStorageData("isGuest", 'true')
    }
    window.scrollTo(0, 0)

    // Customizable Area End
  }

  // Customizable Area Start
  handleReceive = (responseJson: any) => {
    this.setState({ dummyData: responseJson?.data, isMovieDetailsLoading: false })
  }

  getCrewList = () => {
    this.setState({ isCrewListLoaded: false })
    this.apiCrewCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `bx_block_categories/movies/${this.state.getMovie}/movie_crew`,
      headers: { "token": configJSON.guestToken }
    }, runEngine);
  }

  getCastList = () => {
    this.setState({ isCastListLoaded: false })
    this.apiCastCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `bx_block_categories/movies/${this.state.getMovie}/movie_cast`,
      headers: { "token": configJSON.guestToken }
    }, runEngine);
  }

  bookTicket = () => {
    this.setState({ languageModal: true })
  }

  closeModal = () => {
    this.setState({ languageModal: false, selectedIdForLanguage:0, selectedScreenId: 0 })
  }

  continueToBook = async () => {
    const movieId = getLastPartOfURL();
    const { selectedIdForLanguage, selectedScreenId } = this.state
    await setStorageData("language_id", selectedIdForLanguage);
    await setStorageData("screen_type_id", selectedScreenId)
    if (selectedIdForLanguage && selectedScreenId) {
      this.props.navigation.navigate("RegularBooking", { id: movieId });
    }
  }

  navigateToTrailer = () => {
    const movieId = getLastPartOfURL();
    
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Trailers");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), movieId);
    this.send(msg);
  }

  getMovieDetails = async() => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    this.setState({ isCastListLoaded: false })
    this.apiMovieCallId = callApi({
      contentType: configJSON.content,
      method: configJSON.apiGetMethodType,
      endPoint: `bx_block_categories/movies/${this.state.getMovie}`,
      headers: { "token": authToken ?? configJSON.guestToken }
    }, runEngine)
  }

  handleReviewLike = (response: ReviewLikeResponse) => {
    const updatedReview = this.state.reviewList.map((review) => {
      if(+review.id !== response.data.attributes.likeable_id) return review
      return {
        ...review,
        attributes: {
          ...review.attributes,
          like_count: review.attributes.like_count + 1,
          is_liked: true,
          dislike_count: review.attributes.is_disliked ? review.attributes.dislike_count - 1 : review.attributes.dislike_count,
          is_disliked: false
        }
      } as Review
    })
    this.setState({ reviewList: updatedReview })
  }

  handleReviewDislike = (response: ReviewDislikeResponse) => {
    const updatedReview = this.state.reviewList.map((review) => {
      if(+review.id !== response.message.dislikeable_id) return review
      return {
        ...review,
        attributes: {
          ...review.attributes,
          is_disliked: true,
          dislike_count: review.attributes.dislike_count + 1,
          is_liked: false,
          like_count: review.attributes.is_liked ? review.attributes.like_count - 1 : review.attributes.like_count,
        }
      } as Review
    })
    this.setState({ reviewList: updatedReview })
  }

  getReviewList = async () => {
    let authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
   
    const movieId = getLastPartOfURL();
    this.apiReviewCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `${configJSON.reviewApiCallUrl}?movie_id=${movieId}&top=true`,
      headers: { "token": authToken ?? configJSON.guestToken  }
    }, runEngine);
  }

  parseApiSuccess = async (responseJson: any, apiRequestCallId: string) => {
    switch (apiRequestCallId) {
      case this.apiCrewCallId:
        this.handleCrewResponse(responseJson);
        break;
      case this.apiCastCallId:
        this.handleCastResponse(responseJson);
        break;
      case this.apiReviewCallId:
        this.handleReviewResponse(responseJson);
        break;
      case this.likeApiCalledId:
        this.handleReviewLike(responseJson as ReviewLikeResponse);
        break;
      case this.dislikeApiCalledId:
        this.handleReviewDislike(responseJson as ReviewDislikeResponse);
        break;
      case this.averageApiCalledId:
        this.handleAverageResponse(responseJson);
        break;
      default:
        break;
    }
  };
  handleCrewResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ crewList: responseJson.data, isCrewListLoaded: true });
    }
    if (responseJson.message) {
      this.setState({ crewError: responseJson.message, isCrewListLoaded: true });
    }
  };
  handleCastResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ castList: responseJson.data, isCastListLoaded: true });
    }
    if (responseJson.message) {
      this.setState({ castError: responseJson.message, isCastListLoaded: true });
    }
  };

  handleReviewResponse = async (responseJson: any) => {
    if (responseJson.data) {
      let uniqueArray = responseJson.data.map((elm: any) => elm.attributes.tags.data).flat(1);

      const uniqueArrays = uniqueArray.filter((obj: any, index: number, self: any) =>
        index === self.findIndex((el: any) => el.id === obj.id)
      );

      this.setState({ 
        reviewList: (responseJson.data as Review[]), 
        isReviewList: responseJson, 
        filterReviewList: (responseJson.data as Review[]), 
        tags: uniqueArrays, isReviewListLoaded: true 
      });
    }
    if (responseJson.errors) {
      this.setState({ reviewError: responseJson.errors, isReviewListLoaded: true });
    }
  };


  handleAverageResponse = (responseJson: any) => {
    this.handleAverageRevive(responseJson);
  };


  handleLike = async (id: number) => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

    const httpBody = {
      "data": {
        "likeable_id": id,
        "likeable_type": "BxBlockReviews::Review"
      }
    }
    this.likeApiCalledId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.postAPiMethod,
      endPoint: `${configJSON.likeApiEndPoint}`,
      headers: { "token": authToken },
      body: httpBody

    }, runEngine);
  }

  handleDislike = async (id: number) => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

    const httpBody = {
      "data": {
        "dislikeable_id": id,
        "dislikeable_type": "BxBlockReviews::Review"
      }
    }
    this.dislikeApiCalledId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.postAPiMethod,
      endPoint: `${configJSON.dislikeikeApiEndPoint}`,
      headers: { "token": authToken },
      body: httpBody

    }, runEngine);
  }

  // average Ratings Api
  getAverageRating = () => {
    const movieId = getLastPartOfURL();

    this.averageApiCalledId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `${configJSON.getAverageApiEndPoint}?movie_id=${movieId}`,
      headers: { "token": configJSON.guestToken },

    }, runEngine);
  }

  handleAverageRevive = (responseJson: any) => {
    this.setState({ averageRating: responseJson })
  }
  handleFilterData = (id: any) => {
    const { filterReviewList, selectedHash } = this.state;
    const tagId = id.id.toString(); // Convert tag ID to string for comparison

    // Check if the tag is already selected
    if (selectedHash[tagId]) {
      this.setState({ reviewList: filterReviewList, selectedHash: {} });
    } else {
      // If not selected, filter reviews with the same tag ID
      let filter = filterReviewList.filter((review: any) => {
        return review.attributes.tags.data.some((tag: any) => tag.id === tagId);
      });

      // Update the state with the filtered reviews and selected hash
      this.setState({ reviewList: filter, selectedHash: { [tagId]: true } });
    }
  };

  changeLanguageFormat = (selectedId: number, option: "selectedScreenId" | "selectedIdForLanguage") => {
    this.setState((prevState) => (
      {
        ...prevState,
        [option]: selectedId
      }
    ))
  }

  // Customizable Area End
}
