export const Mail = require("../assets/gmaiicon.png");
export const MovieStart = require("../assets/movieicon.png");
export const Google = require("../assets/google.png");
export const WhatsApp = require("../assets/whatapp.png");
export const Mobile = require("../assets/image_Bitmap.png")
export const EyeOpen = require("../assets/image_Show.png")
export const EyeClose = require("../assets/image_Hide.png")
export const indianFlag = require("../assets/image_India.png")
export const checkbox = require ("../assets/checkbox.png")
export const uncheck = require("../assets/uncheck.png")
export const checkboxicon = require("../assets/checkboxicon.svg")
export const closeIcon = require("../assets/close.png")
export const requestimg = require("../assets/Request.png")
export const buyimg = require("../assets/buy.png")