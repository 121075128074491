Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "contentmanagement3";
exports.labelBodyText = "contentmanagement3 Body";

exports.btnExampleTitle = "CLICK ME";

exports.headerAboutUs = "About Ticket Xchange";
exports.headerInvestor = "Investors";
exports.headerTeam = "Our Team";
exports.headerFuturePlan = "Our Future Plans";
exports.labelSeeAll = "See all";
exports.labelReadMore = "Read More";
exports.labelReadLess = "Read Less";
exports.teamContent = "lorem femnun sfakruwru iq teiu rem fun sfakruwru iiut tete loremiq teiu qiutitut iutun sfakruwru iiut tete loremiq teiu qiutitut iutun sfakruwru iiut tete loremiq teiu qiutitut iutun sfakruwru iiut tete loremiq teiu qiutitut iutemnun sfakruwru iq teiu qiutitut iut tete sfakruwru iq teiu qiutitut iut tete lorem femnun sfakruwru iq teiu qiutitut iut tetelorem tetelorem femnun sfakruwru iq ";
exports.futurePlansContent = "lorem femnun sfakruwru iiut tete loremiq teiu qiutitut iut tetelorem femnun sfakruwru iq teiu qiutitut iut tetelorem femnun sfakruwru iq teiu qiutitut iut tetelorem femnun sfakruwru iq teiu qiutitut iut tetelorem femnun sfakruwru iq teiu qiutitut iut tetelorem femnun teiu qiutitut iut tetelorem femnun sfakruwru iq teiu  femnun sfakruwru iq teiu qiutitut iut";
exports.labelInv="Investors";
exports.labelTeam="Our Team";
exports.contentSlider="For The Movie Buff’s";
exports.contentSmallSlider="By The Movie Buff’s";
exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";

exports.aboutUsInfoApiCallUrl="bx_block_about/about_us";
exports.investorInfoApiCallUrl="bx_block_gallery/invester";
exports.teamInfoApiCallUrl="bx_block_gallery/our_team";

exports.guestToken = "guest_user";
exports.aboutUsBannerApiEndPoint = "bx_block_about/get_about_banners";
exports.getMethod = "GET";
exports.contentType = "application/json";
// Customizable Area End