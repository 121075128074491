import { Box, Container, Divider, Grid, Icon, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import { Seat } from './BookingSuccessfulTicket.web'
import { IBookingResponse } from './interfaces.web';
import moment from 'moment';
import { convertToPad } from './utilities';
import { BookableType } from './enums.web';

const LOCATION_ICON = require("./assets/location.png")

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    backgroundColor: "#121212"
  },
  TicketWrapper: {
    fontFamily: "Poppins, sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },
  Ticket: {
    backgroundColor: "#404040",
    borderRadius: "12px 16px 12px 12px",
    width: "100%",
    maxWidth: "500px",
    boxSizing: "border-box" as const,
    padding: "16px",
    position: 'relative' as const,
    "@media (min-width: 600px)": {
      padding: "28px",
    },
    "&::before": {
      content: "''",
      height: "32px",
      width: "32px",
      backgroundColor: "#121212",
      position: 'absolute' as const,
      borderRadius: "32px",
      top: "27%",
      left: "-16px",
      "@media (min-width: 600px)": {
        height: "64px",
        width: "64px",
        borderRadius: "64px",
        left: "-38px",
      }
    },
    "&::after": {
      content: "''",
      height: "32px",
      width: "32px",
      backgroundColor: "#121212",
      position: 'absolute' as const,
      borderRadius: "32px",
      top: "27%",
      right: "-16px",
      "@media (min-width: 600px)": {
        height: "64px",
        width: "64px",
        borderRadius: "64px",
        right: "-38px",
      }
    }
  },
  ImageWrapper: {
    minWidth: "88px",
    height: "111px",
    border: "solid 0.7px #ffb53a",
    "@media (min-width: 600px)": {
      width: "140px",
      height: "175px"
    }
  },
  MoviePoster: {
    height: "100%",
    width: "100%"
  },
  MovieDetails: {
    display: "flex",
    gap: "16px",    
    paddingRight: "12px",
    position: 'relative'as const
  },
  MovieTitle: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    color: "#fff",
    marginBottom: "8px"
  },
  MTicketLabel: {
    position: 'absolute' as const,
    bottom: "30px",
    right: "-30px",
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Poppins, sans-serif",
    transform: "rotate(-90deg)"
  },
  LanguageFormat: {
    textTransform: 'uppercase' as const,
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "8px"
  },
  VDivider: {
    color: "#ffb53a",
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    fontWeight: 500
  },
  IconWrapper: {
    width: "18px",
    height: "22px"
  },
  TheaterName: {
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
  },
  ContainerRoot: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    "@media (min-width: 600px)": {
      flexDirection: "column-reverse" as const,
    }
  },
  SeatWrapper: {
    display: "flex",
    gap: "8px",
    maxWidth: "152px",
    flexWrap: "wrap" as const,
    "& > div > div": {
      backgroundColor: "#ffb53a !important"      
    }
  },
  ShowDate: {
    color: "#f0f6f7",
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px"    
  },
  ShowTime: {
    color: "#f0f6f7",
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    textTransform: "uppercase" as const
  },
  MaxWidth: {
    minWidth: "110px"
  },
  QRwrapper: {
    marginTop: "18px",
    marginBottom: "16px",
    height: "80px",
    width: "80px",
    "@media (min-width: 600px)": {
      marginTop: "24px",
      height: "132px",
      width: "132px",
    }
  },
  Booking: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif"
  },
  BookingID: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500
  },
  Divider: {
    height: 0,
    boxSizing: 'border-box' as const,
    borderBottom: "dotted 1.5px #f0f6f7",
    margin: "12px 0"
  },
  Text: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },
  TotalAmount: {
    color: "#ffb53a",
    fontSize: "14px",
    fontWeight: 600
  },
  TicketMessage: {
    backgroundColor: "#2f2617",
    borderRadius: "12px",
    padding: "8px 35px",
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    fontSize: "12px",
    fontWeight: 500
  },
  TicketDetails: {
    marginTop: "48px",
    "@media (min-width: 600px)": {
      paddingBottom: "20px",
      marginTop: 0
    }
  },
  ScreenDetails: {
    display: "flex",
    flexDirection: "column" as const,
    "@media (min-width: 600px)": {
      flexDirection: "row" as const,
      justifyContent: "space-between",
      marginTop: "48px",
      marginBottom: "16px"
    }
  }
}))

interface MTicketProps {
  booking: IBookingResponse
}

const MTicket: React.FC<MTicketProps> = ({ booking }) => {

  const classes = useStyles()

  const {
    attributes: {
      movie: {
        image_url,
        name,
      },
      language,
      screen_type,
      theatre_name,
      screen_name,
      qr_code,
      uniq_booking_id,
      total_amount,
      show_date_time,
      number_of_seats,
      seat_number,
      bookable_type: BookingType
    }
  } = booking

  const getMessage = (bookableType: string) => {
    if (bookableType === BookableType.Movie) {
      return `This ticket can be cancelled up to 30 mins before the show.`
    }
    else {
      return `Cancellation not available for this venue.`
    }
  }

  return (
    <Box className={classes.Wrapper}>
        <Container>
          <Box className={classes.TicketWrapper}>
            <Box className={classes.Ticket}>
              
              <Box className={classes.MovieDetails}>
                <Box className={classes.ImageWrapper}>
                  <img src={image_url} className={classes.MoviePoster} />
                </Box>

                <Box>
                  <Typography className={classes.MovieTitle}>{name}</Typography>
                  <Typography className={classes.LanguageFormat}>{language}<span className={classes.VDivider}>&nbsp;|&nbsp;</span>{screen_type}</Typography>
                  <Box display="flex" gridGap="5px">
                    <Icon> 
                      <img src={LOCATION_ICON} className={classes.IconWrapper} />
                    </Icon>
                    <span className={classes.TheaterName}>{theatre_name}</span>
                  </Box>
                </Box>
                <Typography className={classes.MTicketLabel}>{`M-Ticket`}</Typography>
              </Box>

              <Box className={classes.TicketDetails}>
                <Grid container className={classes.ContainerRoot}>
                  <Grid item>

                    <Box className={classes.ScreenDetails}>
                      <Box mb={1}>
                        <Typography className={classes.ShowDate}>{moment(show_date_time.split(" | ")[0]).format("ddd DD MMM,  YYYY")}</Typography>
                        <Typography className={classes.ShowTime}>{show_date_time.split(" | ")[1]}</Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography className={classes.ShowDate}>SCREEN</Typography>
                        <Typography className={classes.ShowTime}>{screen_name}</Typography>
                      </Box>
                    </Box>       

                    <Box>
                      <Typography className={classes.ShowDate}>{`${convertToPad(number_of_seats)} Tickets`}</Typography>
                      <Box mt={1} className={classes.SeatWrapper}>
                        {
                          seat_number.map((seat) => (
                            <Seat key={seat} seatLabel={seat} />
                          ))
                        }
                      </Box>
                    </Box>             

                  </Grid>
                  <Grid item className={classes.MaxWidth}>
                    
                      <Box display="flex" justifyContent="center">
                        <img  className={classes.QRwrapper} src={qr_code} />
                      </Box>

                      <Box>
                        <Typography className={classes.Booking} align="center">Booking Id</Typography>
                        <Typography className={classes.BookingID} align="center">{uniq_booking_id}</Typography>
                      </Box>

                  </Grid>
                </Grid>
              </Box>

              <Divider className={classes.Divider} />

              <Box display="flex" justifyContent="space-between">
                <span className={classes.Text}>Total Amount</span>
                <span className={classes.TotalAmount}><span>&#x20b9;</span>&nbsp;{Number(total_amount).toFixed(2)}</span>
              </Box>

              <Divider className={classes.Divider} />

              <Typography className={classes.TicketMessage} align="center">{getMessage(BookingType)}</Typography>

            </Box>
          </Box>
        </Container>
      </Box>
  )
}

export default MTicket