import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { getLastPartOfURL, goToPrevPage } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { IRequestedBookingResponse } from "../../../components/src/interfaces.web";

interface IWalletResponse {
  total_amount: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  ticketId: number;
  bookingData: IRequestedBookingResponse;
  isApiLoading: boolean;
  isChildApiLoading: boolean;
  isConvenienceFeeOpen: boolean;
  isConfirmPaymentDialogOpen: boolean;
  isLowWalletBalanceOpen: boolean;
  isSuccessfulOpen: boolean;
  requiredAmountToAddInWallet: number;
  newBookingId: number;
  openBackdropLoadingOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ConfirmPaymentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ticketDetailsApiId = '';
  walletBalanceApiId = '';
  updateBookingApiId = '';
  updatePaymentApiId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: '',
      ticketId: 0,
      bookingData: {} as IRequestedBookingResponse,
      isApiLoading: true,
      isChildApiLoading: false,
      isConvenienceFeeOpen: false,
      isConfirmPaymentDialogOpen: false,
      isLowWalletBalanceOpen: false,
      isSuccessfulOpen: false,
      requiredAmountToAddInWallet: 0,
      newBookingId: 0,
      openBackdropLoadingOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && resJson !== undefined && resJson?.errors === undefined) {
        this.handleAPIResponse(apiRequestCallId, resJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleAPIResponse(apiRequestCallId: string, resJson: any) {

    switch (apiRequestCallId) {
      case this.ticketDetailsApiId:
        this.setState({ bookingData: resJson?.data as IRequestedBookingResponse, isApiLoading: false })
        break;

      case this.walletBalanceApiId:
        this.handleWalletBalance(resJson as IWalletResponse)
        break;

      case this.updateBookingApiId:
        this.setState({ newBookingId: +resJson?.new_booking_id })
        this.handleUpdateBooking(+resJson?.new_booking_id)
        break;

      case this.updatePaymentApiId:
        this.setState({
          isSuccessfulOpen: true,
          isLowWalletBalanceOpen: false,
          isConfirmPaymentDialogOpen: false,
          openBackdropLoadingOpen: false
        })
        break;
    }

  }

  handleUpdateBooking = (newBookingId: number) => {

    this.updatePaymentApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.PutAPIMethod,
      endPoint: `${configJSON.UpdatePaymentStatusApiEndPoint.replace("{ID}", newBookingId)}`,
      headers: { token: this.state.userToken },
      body: {
        payment_method_type: 'wallet'
      }
    }, runEngine)
  }

  handleWalletBalance(data: IWalletResponse) {

    if(data.total_amount >= +this.state.bookingData.attributes.total_amount) {

      this.updateBookingApiId = callApi({
        contentType: configJSON.APIContentType,
        method: configJSON.PutAPIMethod,
        endPoint: `${configJSON.UpdateBookingApiEndPoint.replace("{ID}", this.state.ticketId)}`,
        headers: { token: this.state.userToken },
        body: {
          "data": {
            "status": "payment_done"
          }
        }
      }, runEngine)

    } else {
      const remaining = (+this.state.bookingData.attributes.total_amount - data.total_amount).toFixed(2);
      this.setState({ 
        isLowWalletBalanceOpen: true, 
        isConfirmPaymentDialogOpen: false,
        openBackdropLoadingOpen: false,
        requiredAmountToAddInWallet: +remaining 
      })
    }

  }

  closeConfirmPaymentDialog = () => this.setState({ isConfirmPaymentDialogOpen: false })
  openConfirmPaymentDialog = () => this.setState({ isConfirmPaymentDialogOpen: true })

  redirectToViewTicket = (ticketId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewTicket");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), ticketId);
    this.send(msg);
  }

  redirectToWalletScreen = async () => {
    await setStorageData(LocalStorageKeys.RedirectTo, "RequestManagement");
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "AddMoney");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), "Wallet");
    this.send(msg);
  }

  handleConfirmPayment = () => {

    this.setState({ isConfirmPaymentDialogOpen: false, openBackdropLoadingOpen: true })
    
    // check for if user has balance in wallet or not
    this.walletBalanceApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.GetWalletBalanceApiEndPoint,
      headers: { "token": this.state.userToken }
    }, runEngine)

  }

  getTicketDetails() {

    this.ticketDetailsApiId = callApi({
        contentType: configJSON.APIContentType,
        method: configJSON.GetAPIMethod,
        endPoint: `${configJSON.GetTicketDetailsApiEndPoint.replace("{ID}", this.state.ticketId)}`,
        headers: { "token": this.state.userToken },
    }, runEngine)

  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const userToken = await getStorageData(LocalStorageKeys.LoginToken)
    const ticketId = getLastPartOfURL()

    if (!userToken || !ticketId) goToPrevPage();
    else {
      this.setState({
        userToken: userToken,
        ticketId: +ticketId
      }, () => this.getTicketDetails())
    }
  }
  // Customizable Area End
}
