Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelBodyText = "cfwallet19 Body";

exports.btnExampleTitle = "CLICK ME";


exports.labelTitleText = "Wallet";
exports.availableBalance = "Available Balance";
exports.recentTransaction = "Recent Transactions";
exports.KycPopupDescription = "Complete your KYC to access Wallet.";
exports.proceed = "Proceed";
exports.addMoney = "Add Money";
exports.accountDetails = "Account Details";
exports.transferToBankHeading = "Transfer Money to Bank Account";
exports.transferToWalletHeading = "Transfer Money to Wallet";
exports.bankAccountListHeading = "Select Bank Account"
exports.paymentInfoHeading = "Sending Money from wallet to bank";
exports.insufficientBalanceHeading = "Wallet Balance is Low!";
exports.insufficientBalanceDescription = "needs to be added to your wallet to complete this transection";
exports.transactionFilterList = {
  title: "Transactions",
  options: [
    {lable:"Last 30 Days", value:"Last 30 Days"},
    {lable:"Last 6 Months", value:"Last 6 Months"},
  ]
}

exports.statusFilterList = {
  title: "Status",
  options: [
    {lable:"Successful", value:"success"},
    {lable:"Pending", value:"pending"},
    {lable:"Failed", value:"failed"},
  ]
}

// exports.sliderMarks = ["100","1000","2000","4000","5000","10000"]
exports.sliderMarks = ["","2500","5000","7500","10000"]

//API endpoints
exports.createOrderEndPoint = "bx_block_scheduling/payments"
exports.PostMethod = "POST";
exports.GetMethod = "GET";
exports.PutMethod = "PUT";
exports.getOrderEndPoint = "bx_block_scheduling/payments/get_order"
exports.contentTypeApi = "application/json";
exports.walletBalance = "bx_block_scheduling/wallet_transactions/wallet_ballance";
exports.getWalletTransections = "bx_block_scheduling/wallet_transactions?"
exports.getDigioToken = "bx_block_profile/profile_kyc_verifications/digio_request";
exports.accountDetailsUrl="account_block/accounts";
exports.walletTransectionEndPoint="bx_block_scheduling/wallet_transactions";
exports.getTransectionStatusEndPoint = "bx_block_scheduling/payments/get_payment_details?order_id="
exports.validateBankDetailsEndPoint="bx_block_scheduling/payments/validate_bank_details"
exports.createBeneficiaryEndPoint="bx_block_scheduling/payments/create_beneficiary"
exports.beneficiaryListEndPoint="bx_block_scheduling/payments/beneficiary"
exports.beneficiaryDetailsEndPoint="bx_block_scheduling/payments/beneficiary_details?beneId="
exports.transferMoneyToBankEndPoint="bx_block_scheduling/payments/transfer_bank"
exports.updatePaymentEndPoint="bx_block_categories/bookings"
exports.updateEventPaymentEndPoint="bx_block_events/event_bookings";

exports.TransactionHistoryLabel = "Transaction History";
exports.RecentTransactions = "Recent Transactions";
exports.ClosingBalance = "Closing Balance:";
exports.getTransactionHistoryEndPoint = "bx_block_scheduling/wallet_transactions";
exports.paymentEndPoint = "bx_block_categories/bookings";
exports.TryAgain = "Try Again";
exports.TransactionFailed = "Transaction Failed!";

exports.chooseOptions = "Choose an option to pay";
exports.paymentOptions = "Payment Options";

// Customizable Area End