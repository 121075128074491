import { Box, Button, Fade, Tooltip, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { heart, play, filled_heart } from '../../blocks/splashscreen/src/assets'
import { Link } from "react-router-dom"
import AddFavourites from '../../blocks/favourites/src/AddFavourites.web'
import Promotecontent from '../../blocks/promotecontent/src/Promotecontent.web'
import { starFilled, starOutline } from './assets'

const useStyles = makeStyles(({
  MainWrapper: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "inset 0px -137px 78px -44px rgba(255,182,54,0.33)",
    },
  },
  PosterImage: {
    width: "278px",
    height: "380px",
    border: "1px solid #ffb636",
    zIndex: -50,
    position: "relative",
  },
  PImage: {
    width: "278px",
    height: "380px",
    border: "1px solid #ffb636",
    zIndex: -50,
    position: "relative",
  },
  RatingWrapper: {
    position: 'absolute' as const,
    boxSizing: 'border-box',
    width: '40px',
    padding:"15px 0px",
    backgroundColor: "#000000b3",
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    top: "8px",
    right: "8px",
  },
  RatingHeartIcon: {
    width: "20px",
    height: "18px",
    cursor: "pointer",
  },
  RatingIcon: {
    width: "21px",
    height: "21px",
    cursor: "pointer",
    marginTop: 15
  },
  ToolTip: {
    backgroundColor: '#121212',
    fontSize: "8px",
  },
  ToolTipArrow: {
    color: "#121212",
  },
  HoverWrapper: {
    position: "absolute",
    bottom: 0,
    width: "calc(100% - 16px)",
    padding: "8px",
    paddingBottom: "10px",
    fontFamily: "Poppins",
    transition: "0.8s ease-in-out",
    filter: "blur(0.5px)",
    backgroundImage: "linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 0.6), #362203 200%)",
    color: "#fff",
    zIndex: 150,
  },
  MovieName: {
    fontWeight: 600,
    fontSize: "1rem",
    marginBottom: "4px",
    fontFamily: "Poppins",
  },
  MovieLanguage: {
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  StaticText: {
    color: "#b4b4b4",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  MovieCategory: {
    fontWeight: 500,
    fontSize: "12px",
    marginTop: "4px",
    fontFamily: "Poppins",
  },
  BookTicketsBtn: {
    color: "#FFF",
    fontSize: "1rem",
    marginTop: "12px",
    border: "solid 0.5px #ffb43a",
    borderBox: "box-sizing",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    backgroundColor: "#121212",
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: "#000",
    },
  }
}))

export interface MovieDetails {
  id: string;
  type: string;
  attributes: {
    name: string;
    category_type: string;
    duration_in_minutes: number;
    release_date: string;
    description: string;
    status: string;
    image_url: string;
    youtube_links: {
      id: number;
      language: string;
      link: string;
      linkable_type: string;
      linkable_id: number;
      created_at: string;
      updated_at: string;
      video_id: string;
    }[];
    languages: {
      language_id: number;
      language_name: string;
    }[];
    screen_types: {
      screen_type_id: number;
      screen_type: string;
    }[];
    search_count: number;
    trailers: any[];
    is_favourite: boolean;
    is_promoted: boolean;
    is_reviewed: boolean;
  };
}

export interface EventDetails {
  id: string,
  type: string,
  attributes: {
    name: string,
    description: string,
    term_and_conditions: string,
    classify: string,
    above_age: string,
    created_at: string,
    updated_at: string,
    show_type: string,
    booking_start_date: string,
    booking_start_time: string,
    booking_end_date: string,
    booking_end_time: string,
    languages: [
      {
        id: number,
        name: string,
        created_at: string,
        updated_at: string
      },
      {
        id: number,
        name: string,
        created_at: string,
        updated_at: string
      }
    ],
    poster_url: string,
    video_url: string,
    is_promoted: boolean,
    priority: null,
    venues: [
      {
        id: number,
        name: string,
        full_address: string,
        user_city_id: 49,
        created_at: string,
        updated_at: string,
        event_detail_id: 42,
        lat: string,
        lng: string,
        pin_code: string
      }
    ],
    schedules: [
      {
        id: number,
        venue_id: number,
        start_time: string,
        end_time: string,
        schedule_date: string,
        created_at: string,
        updated_at: string,
        duration_in_mintues: number
      },
    ],
    event_artist: [
      {
        id: number,
        name: string
        image_url: string
        created_at: string
        updated_at: string
        event_detail_id: number
      }
    ],
    is_fav: boolean,
    ito: [
      {
        id: number,
        is_ito_listed: boolean,
        seat_layout: [
          {
            id: number,
            event_tier_id: number,
            event_seat_layout_id: number,
            event_detail_id: number
            number_of_seats: number,
            prefix: string,
            price: string,
            start_sequence: number,
            end_sequence: number,
            created_at: string,
            updated_at: string,
            ito_price: string
          }
        ]
      },

    ],
    youtube_links: [
      {
        id: number,
        language: string,
        link: string,
        linkable_type: string,
        linkable_id: number,
        created_at: string,
        updated_at: string,
        video_id: string
      }
    ]
  }
}

type IBookTicketCardProps = {
  movie: MovieDetails | EventDetails
  redirectTo: string
  isIto ?: boolean
  handleOnClick ?: (ticketId:string)=> void
  navigateToMovieDetails?: (movieId: number) => void
}



const BookTicketCard: React.FC<IBookTicketCardProps> = ({ redirectTo, movie, isIto, navigateToMovieDetails, handleOnClick }) => {

  const classes = useStyles()
  const [hover, setHover] = useState<boolean>(false)


  if (movie.type == "movie") {
    const movieDetails = movie as MovieDetails;
    const addFavourites = {
      heartIcon: heart,
      filledHeart: filled_heart,
      imageStyle: {
        width: "20px",
        height: "18px",
        cursor: "pointer"
      },
      favouriteable_id: movie.id,
      favouriteable_type: "BxBlockCategories::Movie",
      tooltipDirection: "left-end",
      isFavourite: movieDetails.attributes.is_favourite
    }

    const handleRedirect = () => {
      if(movie.type == "movie" && navigateToMovieDetails) {
        navigateToMovieDetails(+movie.id)
      }
    }
    
    return (

      <Box
        position="relative"
        className={classes.MainWrapper}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onTouchStart={() => setHover(true)}
      >
        {movieDetails.attributes.is_promoted && <Promotecontent navigation="" id=""/>}
        <img className={classes.PosterImage} alt="Poster Image" src={movieDetails.attributes.image_url} />
        <Box className={classes.RatingWrapper}>
          <AddFavourites addFavourites={addFavourites} navigation={""} id={""} />
          {!isIto && <Tooltip title="Rate this" arrow placement="left-end" classes={{ tooltip: classes.ToolTip, arrow: classes.ToolTipArrow }}>
            <img src={movieDetails.attributes.is_reviewed ? starFilled : starOutline} className={classes.RatingIcon} alt="Favourite Icon" onClick={handleRedirect} data-test-id="rating-button" />
          </Tooltip>}
          <Tooltip title="Play Trailer" arrow placement="left-end" classes={{ tooltip: classes.ToolTip, arrow: classes.ToolTipArrow }}>
            <Link to={`/trailers/${movieDetails.id}`}>
              <img data-test-id="trailer-image" src={play} alt="Trailer Button" className={classes.RatingIcon} />
            </Link>
          </Tooltip>

        </Box>

        <Fade in={hover}>

          <Box className={classes.HoverWrapper}>
            <Typography className={classes.MovieName}>{movieDetails.attributes.name}</Typography>
            <Typography>
              <span className={classes.MovieLanguage}>
                {movieDetails.attributes.languages.map(language => language.language_name).join(", ")}
              </span>
              <span className={classes.StaticText}>&nbsp;(UA)</span>
            </Typography>
            <Typography className={classes.MovieCategory}>
              {movieDetails.attributes.category_type}
            </Typography>
            <Button data-test-id="apply-for-Ito" onClick={()=> isIto ? handleOnClick && handleOnClick(movieDetails.id): navigateToMovieDetails && navigateToMovieDetails(+movieDetails.id)} className={classes.BookTicketsBtn} fullWidth>
              {isIto ? "Apply for ITO" : "Book Tickets"}
            </Button>
           </Box>

        </Fade>
      </Box>
    )
  }
  else {
    const eventDetails = movie as EventDetails;
    return (


      <Box
        position="relative"
        className={classes.MainWrapper}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onTouchStart={() => setHover(true)}
      >
        {eventDetails.attributes.is_promoted && <Promotecontent navigation="" id=""/>}
        <img className={classes.PosterImage} alt="Poster Image" src={eventDetails.attributes.poster_url} />

        <Fade in={hover}>

          <Box className={classes.HoverWrapper}>
            <Typography className={classes.MovieName}>{eventDetails.attributes.name}</Typography>
            <Typography>
              <span className={classes.MovieLanguage}>
                {eventDetails.attributes.languages.map(language => language.name).join(", ")}
              </span>
            </Typography>
            <Typography className={classes.MovieCategory}>
              {eventDetails.attributes.show_type}
            </Typography>
              <Button data-test-id="apply-for-Ito" onClick={()=> handleOnClick && handleOnClick(eventDetails.id)} className={classes.BookTicketsBtn} fullWidth>
                 {isIto ? "Apply for ITO" : "Book Tickets"}
              </Button>
          </Box>

        </Fade>
      </Box>
    )
  }


}

export default BookTicketCard