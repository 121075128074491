import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Popover,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Back_arrow } from "../../contactus/src/assets";
import { barcode, failedIcon, pendingIcon, resetIcon, toBank, toWallet, wallet } from "./assets";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import DatePickerComponent from "../../../components/src/DatePickerComponent";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import ReactPaginate from 'react-paginate';
import { MainHeadingStyle } from "./AddMoney.web";

const ReactPaginateComp = styled(ReactPaginate)(({ theme }) => ({
  display:"flex",
  color:"#fff",
  fontSize:18,
      fontFamily:"Poppins",
  '& > li':{
    listStyle:"none",
    width: 25,
    height: 25,
    marginLeft: 10,
    textAlign: "center",
    cursor:"pointer"
  },
  '& .selected':{
    background:"#ffb636",
    borderRadius:4,
    color:"#000"
  }
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize:24,
      fontFamily:"Poppins",
      fontWeight:600,
      color:"#b4b4b4",
      marginTop:100,
      textAlign:"center"
    },
    h2: {
      fontSize:24,
      fontFamily:"Poppins",
      fontWeight:500,
      color:"#fff",
      '@media(max-width:600px)': {
        fontSize: "16px",
    },
    },
    h3: {
      fontSize:18,
      fontFamily:"Poppins",
      fontWeight:500,
      color:"#fff",
      textTransform:"capitalize",
      '@media(max-width:600px)': {
        fontSize: "14px",
      },
    },
    h4: {
      fontSize:16,
      fontFamily:"Poppins",
      fontWeight:"normal",
      color:"#b4b4b4",
      marginTop:20,
    }
  },
});

const Dropdownbtn = styled(Button)(({ theme }) => ({
  height: "48px",
  padding: "13px 16px 12px",
  border: "solid 0.5px #ffb636",
  borderRadius: "8px",
  backgroundColor: "#2f2617",
  color: "#ffffff",
  '&:hover': {
      backgroundColor: "#2f2617"
  },
  textTransform: "capitalize",
  fontFamily: "Poppins", 
  fontSize: "14px", 
  fontWeight: 600,
  "& .MuiSvgIcon-root":{
     color: "#ffb43a" 
   },
   '@media(max-width:600px)': {
    height: "30px",
    padding: "13px 6px 12px",
    fontSize: "12px",
    marginLeft:0,
    minWidth:0,
   },
}));

const GridBox = styled(Grid)({
  textAlign:"end",
  '@media(max-width:600px)': {
    display:"flex",
    flexDirection:"row-reverse",
    justifyContent:"space-between",
    width:"100%",
    "& h3": {
      marginTop:20
    }
   },
})

const ImageBox = styled(Box)({
  height: 72,
  width: 72,
  marginRight:20,
  '@media(max-width:600px)': {
    height: 55,
    width: 55,
   },
})

// Customizable Area End

import Cfwallet19Controller, {
  Props,
  configJSON,
} from "./Cfwallet19Controller";

export default class Cfwallet19 extends Cfwallet19Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={this.state.loading ? webStyle.overFlow : webStyle.bgcolor}>
        <Loader loading={this.state.loading}/>
        <ThemeProvider theme={theme}>
          <MainHeaderComp navigation = {this.props.navigation} />
          <Container maxWidth={"lg"}>
            <Grid container spacing={3} style={webStyle.heading}>
              <Grid item
                xs={12}
                alignItems="center"
                container
                direction="row">
                <img
                  src={Back_arrow}
                  style={webStyle.backArrow}
                  alt='backArrow'
                  onClick={this.handleBackBtn}
                  data-test-id="back"
                />
                <MainHeadingStyle id="contactText">{configJSON.labelTitleText}</MainHeadingStyle>
              </Grid>
            </Grid>

            <Grid style={webStyle.upperContainer} container>
              <Box
                style={webStyle.availableBalanceBlock}
              >
                <Grid
                  direction="row"
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h2">{configJSON.availableBalance}</Typography>
                <Typography variant="h2" style={webStyle.rupies}> <span style={{fontFamily:"Roboto"}}>&#8377;</span> {this.state.totalWalletBalance.toFixed(2)}</Typography>
                </Grid>
                
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                style={{ padding: "54px 0px" }}
              >
                <Grid data-test-id="add-money-to-wallet" onClick={()=>this.navigateToAddMoney("Wallet")} style={{ textAlign: "center",cursor:"pointer" }}>
                  <img style={webStyle.imageContainer} src={toWallet} />
                  <Typography variant="h3">Add Money to <br /> Wallet</Typography>
                </Grid>
                <Grid data-test-id="add-money-to-bank" onClick={()=>this.navigateToAddMoney("Bank")} style={{ textAlign: "center",cursor:"pointer" }}>
                  <img style={webStyle.imageContainer} src={toBank} />
                  <Typography variant="h3">Transfer Money to <br /> Bank</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              style={{ margin: "40px 0px" }}
              alignItems="center"
            >
              <Typography style={{marginBottom:10}} variant="h2">{configJSON.recentTransaction}</Typography>

              <Grid
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <MenuButton data-test-id="filter-status" obj={configJSON.statusFilterList} keyName="Successful" transectionfilterInput={this.state.statusFilterInput} handleSelectTransection={this.handleSelectStatus} />
                <DatePickerComponent data-test-id="datepicker" loader={this.state.loading} handleOnChange={this.handleSelectDate} dateSelected={this.state.date}/>
                <MenuButton data-test-id="filter-days" obj={configJSON.transactionFilterList} transectionfilterInput={this.state.transectionfilterInput} handleSelectTransection={this.handleSelectTransection} />
                <Dropdownbtn style={{marginLeft:10}} data-test-id="resetBtn" onClick={() => this.resetFilter()}>
                  <img style={webStyle.resetIcon} src={resetIcon}/>
                </Dropdownbtn>
              </Grid>

            </Grid>
            {this.state.walletTransectionList.length > 0
              ? <>
                {this.state.walletTransectionList.map((elm: any) => {
                  const { description, transaction_type, amount, created_at, status, total_amount } = elm.attributes;
                  return <Grid
                    key={elm.id}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ borderBottom: "1px solid #404040", padding: "36px 0px" }}
                  >
                    <Box style={webStyle.flexRow} data-test-id="transaction">
                      <ImageBox>
                        <img style={{minWidth:55}} width={"100%"} height={"100%"} src={transaction_type == "credit" ? wallet : barcode} />
                      </ImageBox>
                      <Grid>
                        <Typography variant="h3">{description}</Typography>
                        <Typography variant="h4"> <span style={{ fontWeight: 600 }}> {moment(created_at).format("Do MMM YYYY")}</span> {moment(created_at).format('LT')} </Typography>
                      </Grid>
                    </Box>
                    <GridBox>
                      <Typography
                        variant="h3"
                        style={status == "success" ? webStyle.rupies : {}} >
                        {transaction_type == "credit" ? "+" : "-"} <span style={{ fontFamily: "Roboto" }}>&#8377;</span>  {amount.toFixed(2)}
                      </Typography>
                      <Typography variant="h4">
                        {status == "success" && <>Closing Balance: <span style={{ fontFamily: "Roboto" }}>&#8377;</span> {total_amount}</>}
                        {status == "pending" && <span style={webStyle.pendingBox}><img style={webStyle.failedIcon} src={pendingIcon} /> Pending</span>}
                        {status == "failed" && <span style={webStyle.failedBox}> <img style={webStyle.failedIcon} src={failedIcon} />Failed</span>
                        }
                      </Typography>
                    </GridBox>
                  </Grid>
                }
                )}
                <Box data-test-id="pagination-box" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ReactPaginateComp
                    data-test-id="pagination"
                    nextLabel=">"
                    onPageChange={(e) => this.handleOnPageChange(e)}
                    pageCount={this.state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </Box>
              </>
              : <Typography variant="h1">No Recent Transactions</Typography>
            }
           </Container>
          <FooterWithEvents />
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const MenuButton = ({ obj, transectionfilterInput, handleSelectTransection }: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
      <>
          <Dropdownbtn data-test-id="drop-down-btn" onClick={handleClick}>
              {transectionfilterInput.id ? transectionfilterInput.lable : obj.title}
              {open ? (
                  <ExpandLessIcon style={webStyle.downarrow} />
              ) : (
                  <KeyboardArrowDownIcon style={webStyle.downarrow} />
              )}
          </Dropdownbtn>

          <Popover
              id={id}
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              PaperProps={{
                  style: {
                    borderRadius: '12px',
                    marginTop: "12px",
                    width: "190px"
                  },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              data-test-id="anchorbtn"
          >
          {obj?.options?.map((list: any, index: number) => {
            return (
              <Box data-test-id="listselected" onClick={() => { handleSelectTransection(list.value, list.lable); handleClose() }} key={index} style={index % 2 == 0 ? webStyle.lightListStyle : webStyle.darkListStyle}>
                <Typography style={webStyle.listtxt}>{list.lable}</Typography>
              </Box>
            )
          })}
          </Popover>
      </>
  )
}

const webStyle = {
  overFlow:{
    overflow:"hidden",
    backgroundColor: "#121212"
  },
  bgcolor: { 
    backgroundColor: "#121212"
  },
  heading: {
    marginTop: 30
  },
  backArrow:{
    heigth:"32px",
    width:"32px",
    marginRight:10,
    cursor:"pointer"
  },
  upperContainer:{
    background:"#404040",
    borderRadius:"16px",
    marginTop:"32px"
  },
  availableBalanceBlock:{
    background:"#2d2d2d",
    padding:"32px",
    borderBottom:"solid 0.5px #ffb43a",
    borderTopLeftRadius:"16px",
    borderTopRightRadius:"16px",
    width:"100%"
  },
  rupies:{
    color:"#ffb636",
  },
  imageContainer:{
    width:104,
    height:104,
    marginBottom:16
  },
  downarrow: {
    color: '#ffb43a'
},
lightListStyle: {
  display: "flex",
  backgroundColor: "#5a5a5a",
  padding: '10px',
  minWidth: "100px"
},
darkListStyle: {
  display: "flex",
  backgroundColor: "#404040",
  padding: '10px',
  minWidth: "100px"
},
listtxt: {
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  fontFamily: "Poppins",
  textTransform: "capitalize",
}as CSSProperties,
optionsStyle: {
  backgroundColor: "#404040",
  color: '#ffffff',
  borderBottom: "1px solid #979797",
  padding:"10px 16px"
},
flexRow:{
  display:"flex"
},
failedIcon:{
  marginRight:5,
  width:16,
  height:16,
},
failedBox:{ 
  alignItems:"center", 
  display:"flex", 
  color: "#ff3b30", 
},
pendingBox:{ 
  color: "#ffb636", 
  display:"flex", 
  alignItems:"center" 
},
resetIcon:{
  width:20,
  height:20
}
};
// Customizable Area End
