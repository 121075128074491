import React from "react";

import {
    Container,
    Box,
    Typography,
    // Customizable Area Start
    Paper,
    Grid,
    Button,
    Divider,
    CircularProgress,
    Fade,
    TextField,
    FormControlLabel,
    Checkbox,
    Backdrop,
    InputAdornment,
    CssBaseline,
    Chip
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled, withStyles } from "@material-ui/core/styles";
import SeatSelectionController, { Props, configJSON } from "./SeatSelectionController.web";
import Header from "../../../components/src/Header.web";
import { convertTo12HourFormat, getFormattedDate } from "../../../components/src/utilities";
import {
    sreenImage,
    editIcon,
    alertImage
} from "./assets"
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";
import { IRow, ISeat, ISection } from "../../../components/src/interfaces.web";
import Seat from "../../../components/src/Seat.web";
import RFHDialog from "../../../components/src/RFHDialog";
import KycDialogeBox from "../../cfwallet19/src/KycDialogeBox.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#404040",
            default: "#121212",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins, sans-serif",
        h5: {
            fontSize: "16px",
            color: "#FFF",
            margin: "16px 0"
        },
        h6: {
            fontWeight: 500,
        },
        body1: {
            fontSize: "18px",
            color: "#FFF"
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiChip: {
            root: {
                fontSize: "12px",
                fontWeight: 500,
                color: "#000",
                fontFamily: "Poppins",
                borderRadius: "8px",
                padding: "12px 0",
                lineHeight: 1,
                minHeight: "40px",
                backgroundColor: "#ffb53a",
                cursor: "pointer"
            },
            outlined: {
                fontSize: "12px",
                color: "#FFF",
                border: "solid 1px #ffb53a",
                background: "transparent"
            },
            label: {
                fontWeight: 500
            },
            clickable: {
                '&:active': {
                    backgroundColor: '#ffb53a',
                },
                '&:focus': {
                    backgroundColor: '#ffb53a',
                },
                '&:hover': {
                    backgroundColor: '#ffb53a',
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#121212",
                color: "#ffb53a",
                border: "solid 1px #ffb53a",
                position: 'relative',
                fontFamily: "Poppins",
                fontSize: "12px"
            },
            tooltipPlacementTop: {
                top: "10px"
            }
        }
    },
});

const RFHDialogButton = styled(Button)({
    textTransform: "capitalize",
    fontFamily: "Poppins, Roboto",
    color: "#000",
    backgroundColor: "#ffb43a",
    borderRadius: "12px",
    height: "56px",
    fontWeight: 500,
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#ffb43a"
    },
    "@media (max-width: 600px)": {
        fontSize: "16px",
        height: "48px",
        borderRadius: "8px"
    }
  })

const RFHDialogButtonSecondary = styled(Button)({
    textTransform: "capitalize",
    fontFamily: "Poppins, Roboto",
    color: "#FFF",
    backgroundColor: "#373737",
    border: "solid 1px #ffb43a",
    borderRadius: "12px",
    height: "56px",
    fontWeight: 400,
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "#373737"
    }
})

const SELECTION_ARRAY = [
    { numberOfSeats: 1, label: "Solo" },
    { numberOfSeats: 2, label: "Twin" },
    { numberOfSeats: 3, label: "Trio" },
    { numberOfSeats: 4, label: "Band" },
    { numberOfSeats: 5, label: "Unit" },
    { numberOfSeats: 6, label: "Crew" },
    { numberOfSeats: 7, label: "Gang" },
    { numberOfSeats: 8, label: "Club" },
    { numberOfSeats: 9, label: "Team" },
    { numberOfSeats: 10, label: "Army" },
]

const seatStatusMap = {
    available: "Available",
    fast_filling: "Fast Filling",
    sold_out: "Sold Out"
} 

type SeatStatusType = 'available' | 'fast_filling' | 'sold_out';

type IDialogSeat = {
    seat_type: string
    price: number
    seat_status: SeatStatusType
}
// Customizable Area End


class Seatselection extends SeatSelectionController {
    constructor(props: Props) {
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
    SeatGrid = (seatTypeNumber: any, handleNumberSelect: any, selectedNumber: any, classes: any) => (
        <Grid container spacing={2} className={classes.dialogSeat}>
            {seatTypeNumber.map((number: any, index: number) => (
                <Box key={index}>
                    <Typography component="span" className={classes.dialogSeatTitle}>
                        {!this.state.isEdit && <span>{number.title}</span>}
                    </Typography>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        key={number.id}
                        className={`${classes.dialogBody} ${number.numberOfSeats === selectedNumber ? classes.timeSelect : ""
                            }`}
                        data-test-id={`seatno${index}`}
                        onClick={() => handleNumberSelect(number.numberOfSeats)}
                    >
                        {number.numberOfSeats}
                    </Grid>
                </Box>
            ))}
        </Grid>
    );


    render() {

        const { classes } = this.props;
        const { selectedNumber, seatsSelected } = this.state
        let buttonText;
        if (this.state.bookingValue) {
            buttonText = 'Proceed';
        } else {
            buttonText = !this.state.isEdit ? 'Select Seats' : 'Proceed';
        }
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>

                    <CssBaseline />

                    <MainWrapper>

                        <Box>
                            <Header navigation={this.props.navigation} seatType={true} />
                        </Box>

                        <Box flex={1}>

                            <Container>
                                {
                                    this.state.isLoading ? (
                                        <Fade in={this.state.isLoading} unmountOnExit>
                                            <Box display="flex" mt={2} mb={2} justifyContent="center">
                                                <CircularProgress className="circular-progress" />
                                            </Box>
                                        </Fade>
                                    ) : (
                                            <Box mb={6} className='timeslot-wrapper'>
                                                <Box className="timeslot-detail">
                                                    <Box className='timeslot-detail-wrapper'>
                                                        <Typography variant="h5">
                                                            {getFormattedDate(this.state.date, "ddd, DD MMM")}
                                                        </Typography>
                                                        <Box className='edit-seat-number-mobile' onClick={this.handleOpenModalEdit}>
                                                            <Typography className='seat-text'>{`${this.state.selectedNumber} ${configJSON.Tickets}`}</Typography>
                                                            <img src={editIcon} width="20px" height="20px" alt='edit' />
                                                        </Box>
                                                    </Box>

                                                    <Box className="chip__wrapper">
                                                        {
                                                            this.state.timeSlotList.map((timeSlot) => (
                                                                <Chip
                                                                    label={convertTo12HourFormat(timeSlot.timeSlot)}
                                                                    key={timeSlot.screenId}
                                                                    variant={this.getChipClassName(+timeSlot.screenId)}
                                                                    onClick={() => this.handleChangeTime(+timeSlot.screenId)}
                                                                />
                                                            ))
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box data-test-id="edit-button" className='edit-seat-number' onClick={this.handleOpenModalEdit}>
                                                    <Typography data-test-id="selected-seat" className='seat-text'>
                                                        {`${this.state.selectedNumber} ${configJSON.Tickets}`}
                                                    </Typography>
                                                    <img src={editIcon} width="20px" height="20px" alt='edit' />
                                                </Box>
                                            </Box>
                                    )
                                }

                                <Box className='screen-image-wrapper'>
                                    <img src={sreenImage} width="362px" height="70px" alt="screen" />
                                </Box>

                                {
                                    this.state.isLoading ? (
                                        <Fade in={this.state.isLoading} unmountOnExit>
                                            <Box display="flex" justifyContent="center">
                                                <CircularProgress className='circular-progress' />
                                            </Box>
                                        </Fade>
                                    ) : (
                                        <Box mb={4}>

                                            <div ref={this.parentRef} className={classes.SeatSelectionRoot} data-test-id="seat-selection-wrapper">
                                                <div ref={this.childRef} className={classes.SeatSelectionWrapper}>

                                                    {
                                                        this.state.seatSelection.map((section: ISection) => (
                                                            <Box className={classes.Section} key={section.sectionName}>

                                                                <Typography className={classes.SectionTitle}>
                                                                    {`${section.sectionName}:`}
                                                                    <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                                                                    <span className="price">{section.price}</span>
                                                                </Typography>

                                                                <Box className={classes.SeatSectionWrapper}>
                                                                    {
                                                                        section.rows.map((seatRow: IRow) => (
                                                                            <Box className={classes.SeatRowWrapper} key={seatRow.rowLabel}>
                                                                                <Typography className={classes.SeatLabel}>{seatRow.rowLabel}</Typography>

                                                                                {
                                                                                    seatRow.seats.map((seat: ISeat) => (
                                                                                        <Seat
                                                                                            key={seat.id}
                                                                                            seat={seat}
                                                                                            onClick={() => this.handleSeatSelection(seat.id, seat.status, seat.seatSectionType, seat.seatNumber, seat.bookingId)}
                                                                                            isSelected={this.state.selectedSeatIds.has(seat.id)}
                                                                                            isTooltipEnable={true}
                                                                                        />
                                                                                    ))
                                                                                }

                                                                            </Box>
                                                                        ))
                                                                    }
                                                                </Box>

                                                            </Box>
                                                        ))
                                                    }

                                                </div>
                                            </div>

                                        </Box>
                                    )
                                }

                            </Container>

                        </Box>

                        <Box>
                            <Paper elevation={0} className="footer" data-test-id="footer-button">
                                <Button
                                    disabled={this.checkForDisabledButton()}
                                    onClick={this.handleNavigate}
                                    className='submit-button'
                                    fullWidth
                                    data-test-id="book-ticket-button"
                                >
                                    {configJSON.BookTickets}
                                </Button>
                            </Paper>
                        </Box>

                    </MainWrapper>

                    <RFHDialog
                        data-test-id="seat_dialog"
                        open={this.state.isModalOpen}
                        handleClose={this.handleCloseModal}
                        maxWidth="sm"
                        disableEscapeKeyDown={true}
                        dialogActions={(
                            <RFHDialogButton data-test-id="proceed-btn" fullWidth onClick={this.handleCloseModal}>
                                {buttonText}
                            </RFHDialogButton>
                        )}
                        dialogContent={(
                            <DialogWrapper>

                                    <Box className='seat-section-wrapper'>
                                        {
                                            SELECTION_ARRAY.map(selection => (
                                                <Box className='seat-wrapper' key={selection.numberOfSeats}>
                                                    <span className='number-text'>{selection.label}</span>
                                                    <Button data-test-id={`number-of-seat-${selection.numberOfSeats}`} onClick={() => this.handleNumberSelect(selection.numberOfSeats)} variant={this.getButtonClassName(selection.numberOfSeats)} className='number-button'>{selection.numberOfSeats}</Button>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                {!this.state.isEdit && (
                                    <>
                                        <Divider className='divider' />
                                        <Box className='seat-option-wrapper'>
                                            {
                                                this.state.isLoading ? (
                                                    <Fade in={this.state.isLoading} unmountOnExit>
                                                        <Box display="flex" justifyContent="center">
                                                            <CircularProgress className='circular-progress' />
                                                        </Box>
                                                    </Fade>
                                                ) : (
                                                    <Box display="flex" justifyContent="space-around">
                                                        {
                                                            this.state.seats?.price?.map((seat: IDialogSeat) => (
                                                                <Box className="seat__type__wrapper" key={seat.seat_type}>
                                                                    <Typography className="seat__type">{seat.seat_type}</Typography>
                                                                    <Typography className="seat__price"><span className="rupees__icon">&#8377;&nbsp;</span>{seat.price.toFixed(2)}</Typography>
                                                                    <Typography className={`seat__status ${seat.seat_status}`}>{seatStatusMap[seat.seat_status]}</Typography>
                                                                </Box>
                                                            ))
                                                        }
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </>
                                )}
                            </DialogWrapper>
                        )}
                        dialogTitle={(
                            <DialogWrapper>
                                <Typography data-test-id="dialog-title" className="dialog__title">
                                    {configJSON.SelectNumberOfSeats}
                                </Typography>
                            </DialogWrapper>
                        )}
                    />

                    <RFHDialog
                        data-test-id="booking-error-dialog"
                        handleClose={this.closeBookingErrorDialog}
                        open={this.state.openBookingError}
                        maxWidth="sm"
                        disableEscapeKeyDown={true}
                        dialogActions={(
                            <RFHDialogButton data-test-id="proceed-btn" fullWidth onClick={this.closeBookingErrorDialog}>
                                {configJSON.Proceed}
                            </RFHDialogButton>
                        )}
                        dialogContent={(
                            <Typography style={webStyle.BookingErrorMessage} align="center">
                                {this.state.bookingError}
                            </Typography>
                        )}
                        dialogTitle={(
                            <Box display="flex" justifyContent="center">
                                <img src={alertImage} alt="alert" width="24px" height="24px" />
                            </Box>
                        )}
                    />

                </ThemeProvider>

                {this.state.doSignUp &&
                    <UserSignUp
                        navigation={undefined}
                        id="testId"
                        showSignup={this.closeSignUpModal}
                    />
                }

                {/* Request Flow Dialog & Backgrop Loader*/}

                <Backdrop
                    className={classes.BackDrop}
                    open={this.state.isBackDropLoadingOpen}
                >
                    <CircularProgress className={classes.CircularProgress} color="inherit" />
                </Backdrop>

                <RFHDialog
                    data-test-id="price-alert-dialog"
                    open={this.state.isPriceAlertOpen}
                    handleClose={this.handleClosePriceAlert}
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHDialogButton fullWidth data-test-id="price-alert-proceed-btn" onClick={this.acceptPriceAlert}>
                            {configJSON.Proceed}
                        </RFHDialogButton>
                    )}
                    dialogContent={(
                        <Typography align="center" className={classes.PriceAlertContent}>
                            {configJSON.PriceAlertContent}
                            <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                            <span className="price">{Number(this.state.requestBookingData.sectionMinimumPrice).toFixed(2)}</span>
                        </Typography>
                    )}
                />

                <RFHDialog
                    data-test-id="ticket-per-price-dialog"
                    open={this.state.isPricePerTicketOpen}
                    handleClose={this.handleClosePricePerTicket}
                    disableEscapeKeyDown={true}
                    dialogTitle={(
                        <Typography align="center" className={classes.PriceAlertContent}><span className="title">{configJSON.EnterPriceTitle}</span></Typography>
                    )}
                    dialogActions={(
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <RFHDialogButtonSecondary fullWidth onClick={this.handleClosePricePerTicket}>
                                    {configJSON.Back}
                                </RFHDialogButtonSecondary>
                            </Grid>
                            <Grid item xs>
                                <RFHDialogButton fullWidth data-test-id="send-request-btn" onClick={this.sendRequest} disabled={this.state.requestBookingData.sectionMinimumPriceErrorMessage}>
                                    {configJSON.Request}
                                </RFHDialogButton>
                            </Grid>
                        </Grid>
                    )}
                    dialogContent={(
                        <Box className={classes.PricePerTicketContent}>
                            <Grid container>

                                <Grid item xs>
                                    <Typography className="ticket-holder">{configJSON.TicketHolder}</Typography>
                                    <Box display="flex" alignItems="center" gridGap="8px" gridRowGap="8px" maxWidth="172px" flexWrap="wrap" mb={1}>
                                        {
                                            this.state.requestBookingData.seatNumber.map((seat) => <Box key={seat.toString()} className="seat">{seat}</Box>)
                                        }
                                    </Box>
                                    <Typography className="tier">{configJSON.Tier}&nbsp;classic</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        data-test-id="ticket-request-price"
                                        type="text"
                                        variant="outlined"
                                        className="price-input"
                                        value={this.state.requestBookingData.requestPrice}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changePricePerTicket(event.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <span className={classes.RupeesSymbols}>&#8377;</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </Grid>

                            </Grid>
                            
                            {
                                this.state.requestBookingData.sectionMinimumPriceErrorMessage && (
                                    <Typography className={classes.ErrorMessage} align="center">
                                        {configJSON.MinimumPriceErrorMessage}
                                        <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                                        <span>{Number(this.state.requestBookingData.sectionMinimumPrice).toFixed(2)}</span>
                                    </Typography>
                                )
                            }

                            <Divider className="divider" />

                            <Typography className="wallet-balance" align="center">
                                {configJSON.WalletBalance}
                                <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                                <span className="price">{Number(this.state.requestBookingData.walletBalance).toFixed(2)}</span>
                            </Typography>

                            <Divider className="divider" />
                        </Box>
                    )}
                />

                <RFHDialog
                    data-test-id="confirmation-price-dialog"
                    open={this.state.isConfirmationForPriceOpen}
                    handleClose={this.handleCloseConfirmationPrice}
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHDialogButton fullWidth data-test-id="confirm-request-btn" onClick={this.confirmRequest}>
                            {configJSON.Continue}
                        </RFHDialogButton>
                    )}
                    dialogTitle={(
                        <Typography align="center" className={classes.PriceAlertContent}>{configJSON.ConfirmationContentTitle}</Typography>
                    )}
                    dialogContent={(
                        <Typography align="center" className={classes.ConfirmationContent}>
                            <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                            <span className="price">{Number(this.state.requestBookingData.requestPrice * this.state.requestBookingData.seatIds.length).toFixed(2)}</span>
                            <span className="edit-button" data-test-id="edit-price-btn" onClick={this.handleEditPricePerTicket}>{configJSON.Edit}</span>
                        </Typography>
                    )}
                />

                <RFHDialog
                    data-test-id="request-placed-dialog"
                    open={this.state.isRequestPlacedOpen}
                    handleClose={this.completeRequestAndNavigate}
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHDialogButton fullWidth data-test-id="complete-request-btn" onClick={this.completeRequestAndNavigate}>
                            {configJSON.Done}
                        </RFHDialogButton>
                    )}
                    dialogTitle={(
                        <Typography align="center" className={classes.PriceAlertContent}>{configJSON.ReqeustPlaceTitle}</Typography>
                    )}
                    dialogContent={(
                        <>
                            <Typography align="center" className={classes.RequestPlaceContent}>
                                <span className="content">{configJSON.RequestPlaceContent}</span>
                            </Typography>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={1.5}>
                                <label 
                                    htmlFor="notify-me-checkbox" 
                                    className={classes.NotifyMeLabel} 
                                    data-test-id="form-label"
                                >
                                    <Checkbox
                                        id="notify-me-checkbox"
                                        className={classes.CheckBox}
                                        checked={this.state.notifyMeChecked}
                                        onChange={this.handleNotifyMeCheck}
                                        data-test-id="notify-me-checkbox"
                                    />
                                    {configJSON.notifyMe}
                                </label>
                            </Box>
                        </>
                    )}
                />

                <RFHDialog
                    data-test-id="insufficient-balance-dialog"
                    open={this.state.isInsufficientBalanceOpen}
                    handleClose={this.handleCloseInsufficientBalance}
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHDialogButton fullWidth data-test-id="redirect-to-wallet-btn" onClick={this.redirectToWalletScreen}>
                            {configJSON.AddMoneyToWalletBtn}
                        </RFHDialogButton>
                    )}
                    dialogTitle={(
                        <Typography align="center" className={classes.PriceAlertContent}>{configJSON.InsufficientBalanceTitle}</Typography>
                    )}
                    dialogContent={(
                        <>
                            <Typography align="center" className={classes.InsufficientBalanceContent}>
                                <span className="content">{configJSON.InsufficientBalanceContent}</span>
                            </Typography>
                            <Typography align="center" className={classes.InsufficientBalance}>
                                <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                                <span className="price">
                                    {
                                        ((Number(this.state.requestBookingData.requestPrice) * this.state.requestBookingData.seatIds.length) -
                                            Number(this.state.requestBookingData.walletBalance)).toFixed(2)
                                    }
                                </span>
                            </Typography>
                        </>
                    )}
                />

                {
                    this.state.showKycDialog && <KycDialogeBox navigation={this.props.navigation} id="seat-selection-kyc" handleOnClose={this.handleKycClose} />
                }


            </>
            // Customizable Area End
        );
    }
}


// Customizable Area Start

const webStyle = {
    BookingErrorMessage: {
        fontFamily: "Poppins, sans-serif",
        color: "#FFF",
        fontSize: "16px",
    },
}

const MainWrapper = styled(Box)({
    flexDirection: "column",
    display: "flex",
    minHeight: "100vh",
    "& .footer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#40404040",
        padding: "14px",
        "& .submit-button": {
            textTransform: "capitalize",
            borderRadius: "10px",
            padding: "12px",
            color: "#000",
            backgroundColor: "#ffb43a",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            maxWidth: "400px",
            border: "none",
            "&.Mui-disabled": {
                backgroundColor: "#926C2F"
            }
        }
    },
    "& .timeslot-wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        "& .edit-seat-number": {
            cursor: "pointer",
            display: "inline-flex",
            gap: "4px",
            "& .seat-text": {
                color: "#ffb43a",
                fontSize: "16px",
                fontWeight: 500
            },
            "@media (max-width: 600px)": {
                display: "none"
            }
        },
        "& .timeslot-detail": {
            "& .timeslot-detail-wrapper": {
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                "& > .edit-seat-number-mobile": {
                    display: "none",
                    cursor: "pointer",
                    gap: "4px",
                    "& .seat-text": {
                        color: "#ffb43a",
                        fontWeight: 500,
                        fontSize: "16px",
                    },
                    "@media (max-width: 600px)": {
                        display: "inline-flex"
                    }
                },
            },
            "& .chip__wrapper": {
                alignItems: "center",
                display: "flex",
                marginTOp: "8px",
                gap: "16px",
                overflowX: "scroll",
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }
        },
        "@media (max-width: 600px)": {
            display: "block"
        }
    },
    "& .screen-image-wrapper": {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        marginBottom: "32px",
        "& .screen-message": {
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "118px"
        }
    },
})
  

const DialogWrapper = styled(Box)({
    padding: "1px 0",
    "& .dialog__title": {
        fontWeight: 600,
        fontSize: "20px",
        color: "#FFF",
        textAlign: "center" as const
    },
    "& .seat-section-wrapper": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
        gridGap: "32px",
        marginTop: "12px",
        "& .seat-wrapper": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "50px",
            "& .number-text": {
                color: "#FFF",
                fontWeight: 500,
                fontSize: "16px",
                marginBottom: "12px",
                fontFamily: "Poppins, Roboto, sans-serif"
            },
            "& .number-button": {
                color: "#FFF",
                border: "solid 1.1px #ffb53a",
                borderRadius: "6.5px",
                width: "40px",
                height: "40px",
                fontWeight: 600,
                fontSize: "18px",
                minWidth: "unset !important",
                "&.MuiButton-contained": {
                    backgroundColor: "#ffb53a",
                    color: "#000",
                    boxShadow: "unset"
                }
            }
        }
    },
    "& .divider": {
        backgroundColor: "#979797",
        height: "1px",
        width: "100%",
        marginTop: "32px",
        marginBottom: "22px"
    },
    "& .seat-option-wrapper": {
        minHeight: "60px",
        "& .circular-progress": {
            color: "#ffb43a"
        },
        "& .seat__type__wrapper": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "4px",
            "& .seat__type": {
                color: "#fff",
                fontWeight: "normal",
                textTransform: "uppercase",
                fontSize: "18px",
                fontFamily: "Poppins",
            },
            "& .seat__price": {
                color: "#FFF",
                fontSize: "20px",
                fontFamily: "Poppins",
                "& .rupees__icon": {
                    fontFamily: "Roboto, sans-serif"
                }
            },
            "& .seat__status": {
                color: "#ffb43a",
                fontSize: "18px",
                lineHeight: 1.17,
                textTransform: "capitalize",
                fontFamily: "Poppins",
                "&.sold_out ": {
                    color: "#fff"
                },
            }
        }
    },
    "@media (max-width: 600px)": {
        "& .dialog__title": {
            fontSize: "14px !important"
        },
        "& .seat-section-wrapper": {
            gridTemplateColumns: "repeat(auto-fill, minmax(22px, 1fr))",
            "& .seat-wrapper": {
                "& .number-text": {
                    fontSize: "12px !important",
                },
                "& .number-button": {
                    width: "32px !important",
                    height: "32px !important",
                    fontSize: "14px !important",
                }
            }
        },
        "& .seat-option-wrapper": {
            "& .seat__type__wrapper": {
                "& .seat__type": {
                    fontSize: "12px !important",
                },
                "& .seat__price": {
                    fontSize: "14px !important",
                },
                "& .seat__status": {
                    fontSize: "12px !important",
                }
            }
        },
    }
})

const styles = ({
    SeatSelectionRoot: {
        display: "flex",
        overflow: "scroll"
    },
    SeatSelectionWrapper: {
        width: "fit-content",
    },
    Section: {
        marginBottom: "48px",
    },
    RupeesSymbols: {
        fontFamily: 'Roboto',
        fontSize: "18px",
        fontWeight: 500
    },
    ErrorMessage: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#ff2626",
        "& .rupees-icon": {
            fontFamily: "Roboto, sans-serif",
        },
    },
    SectionTitle: {
        fontWeight: 500,
        marginBottom: "32px",
        textTransform: "capitalize" as const,
        "& .rupees-icon": {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 600
        },
        "& .price": {
            fontWeight: 600,
            fontFamily: "Poppins"
        },
        "&:last-child": {
            marginBottom: 0
        }
    },
    SeatRowWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    SeatSectionWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    },
    SeatLabel: {
        fontSize: "16px",
        fontWeight: 500,
        width: "40px"
    },
    BackButton: {
        cursor: "pointer"
    },
    rupee: {
        margin: '-1px 5px 22px 7px',
        fontSize: "19px"
    },
    PriceAlertContent: {
        fontWeight: 600,
        color: "#FFF",
        fontSize: "24px",
        fontFamily: "Poppins",
        "& .rupees-icon": {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 600
        },
        "& .price": {
            fontWeight: 600,
            fontFamily: "Poppins"
        },
        "& .title": {
            maxWidth: "296px",
            display: "inline-block",
            textTransform: "none",
            textWrap: "wrap"
        }
    },
    PricePerTicketContent: {
        marginTop: "16px",
        fontFamily: "Poppins !important",
        "& .divider" : {
            backgroundColor: "#b4b4b4",
            marginTop: "24px",
            marginBottom: "24px",
            "&:last-child": {
                marginBottom: 0
            },
            "&:first-child": {
                marginTop: "36px"
            }
        },
        "& .wallet-balance": {
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "Poppins",
            color: "#FFF",
            "& .rupees-icon": {
                fontFamily: "Roboto, sans-serif",
                fontWeight: 600
            },
            "& .price": {
                fontWeight: 600,
                fontFamily: "Poppins"
            },
        },
        "& .ticket-holder": {
            color: "#FFF",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            marginBottom: "8px"
        },
        "& .seat": {
            height: "28px",
            width: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffb43a",
            fontWeight: 600,
            fontFamily: "Poppins",
            fontSize: "12px",
            borderRadius: "2px"
        },
        "& .tier": {
            color: "#FFF",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
            textTransform: "capitalize" as const
        },
        "& .price-input": {
            height: "36px",
            width: "136px",
            "& .MuiOutlinedInput-adornedStart": {
                color: "#FFF",
                padding: 0,
                "& .MuiInputAdornment-root": {
                    left: "24%",
                    position: "absolute",
                }
            },
            "& input": {
                borderRadius: '12px',
                backgroundColor: 'transparent',
                border: 'solid 1px #fff',
                padding: "16px 16px 16px 50px !important",
                color: "#FFF",
                fontSize: "18px",
                fontFamily: "Poppins",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            }
        }
    },
    ConfirmationContent: {
        color: "#FFF",
        fontSize: "18px",
        "& .rupees-icon": {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 500
        },
        "& .price": {
            fontWeight: 500,
            fontFamily: "Poppins"
        },
        "& .edit-button": {
            color: "#ffb43a",
            fontSize: "16px",
            paddingLeft: "12px",
            cursor: "pointer"
        }
    },
    RequestPlaceContent: {
        color: "#FFF",
        fontSize: "18px",
        "& .content": {
            maxWidth: "294px",
            display: "inline-block",
            fontFamily: "Poppins"
        }
    },
    NotifyMeLabel: {
        color: "#FFF",
        fontSize: "16px",
        fontFamily: "Poppins",
    },
    CheckBox: {
        color: "#ffb43a",
        "&.Mui-checked": {
            color: "#ffb43a"
        }
    },
    InsufficientBalanceContent: {
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: "Poppins",
        color: "#FFF",
        marginBottom: "20px"
    },
    InsufficientBalance: {
        color: "#FFF",
        fontSize: "18px",
        "& .rupees-icon": {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 500
        },
        "& .price": {
            fontWeight: 500,
            fontFamily: "Poppins"
        },
    },
    CircularProgress: {
        margin: "20px auto",
        color: "#ffb43a"
    },
    BackDrop: {
        color: "#ffb43a",
        zIndex: 2000
    },
    seatBox: {
        margin: "1rem",
        [theme.breakpoints.down('sm')]: {
            marginTop: "16rem"
        },
        [theme.breakpoints.down('md')]: {
            marginTop: "18rem"
        },
        [theme.breakpoints.down('lg')]: {
            marginTop: "16rem"
        }
    },
    containerMain: {
        padding: '0px',
        // margin: '0px',
    },
    mainPaperStyle: {
        minHeight: '100',
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as "column",
        backgroundColor: "#121212",
    },
    SeatNumber: {
        '& h2': {
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 600,
        }
    },
    seatTypeSelect: {
        color: "#fff",
        fontWeight: "normal",
        fontSize: "18px",
        fontFamily: "Poppins",
        "& .fast_filling ": {
            color: "#ffb43a"

        },
        "& .availabile": {
            color: "#ffb43a"
        }
    },
    seatTypeSelectAvailability: {
        color: "rgb(255,180, 58)",
        fontWeight: "normal",
        fontSize: "18px",
        fontFamily: "Poppins",
        "& .fast_filling ": {
            color: "#ffb43a"

        },
        "& .availabile": {
            color: "#ffb43a"
        }
    },
    seatPriceDialoge: {
        color: "#fff",
        fontWeight: "normal",
        fontSize: "20px",
        fontFamily: "Poppins",
    },
    fast_filling: {
        color: "#ffb43a"

    },
    availabile: {
        color: "#ffb43a"
    },
    seatPrice: {
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 500,
        display: "flex",
        marginBottom: "20px"
    },
    dialogTypo: {
        margin: " 0 auto",
        display: "flex",
        gridGap: "50px",
        maxWidth: "max-content",
        justifyContent: "center"
    },
    dialogSeat: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridGap: "25px",
        paddingLeft: "10px",
        paddingRight: "10px",
        [theme.breakpoints.down("sm")]: {
            display: "flex"
        },
        [theme.breakpoints.up("sm")]: {
            display: "grid"
        }
    },
    dialogSeatError: {
        display: "flex",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridGap: "25px",
        paddingLeft: "10px",
        paddingRight: "10px",
        [theme.breakpoints.down("sm")]: {
            display: "flex"
        },
        [theme.breakpoints.up("sm")]: {
            display: "flex"
        }
    },
    dialogSeatTitle: {
        display: "flex",
        justifyContent: "flex-start",
        fontFamily: "Poppins",
        fontWeight: "500",
        fontSize: "18px",
        margin: "0px 0px 4px 0px",
    },
    customDivider: {
        backgroundColor: "#979797",
        margin: "24px 0",
        width: "390px"
    },
    timeSlot: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "30px",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "17px"
        },
    },
    time: {
        display: "flex",
        gap: "12px"
    },
    timeSelect: {
        backgroundColor: "#ffb53a",
        color: "#000 !important",
    },
    screenImg: {
        display: "flex",
        justifyContent: "center",
        width: "30%",
        marginLeft: "33%",
        marginBottom: "-6%",
        textAlign: "center" as "center",
        [theme.breakpoints.down("sm")]: {
            width: "70%",
            marginLeft: "14%"
        },
        [theme.breakpoints.down("md")]: {
            width: "50%",
            marginLeft: "23%"
        }

    },
    imageText: {
        textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
        fontFamily: "Poppins",
        color: "#fff",
    },
    datecontanier: {
        margin: "15px",
        fontFamily: "Poppins",
        fontSize: "16px",
        letterSpacing: "0.08px",
        color: " #fff",
    },
    seatsContainer: {
        display: "flex",
        height: '100vh',
        justifyContent: "center",
        flexDirection: "column" as "column",
        alignItems: "center" as "center",
        color: "#fff",
        fontFamily: "Poppins",
        textTransform: "capitalize" as "capitalize",
        gap: "26px",
        [theme.breakpoints.down("sm")]: {
            marginBottom: '16%'
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: '25%'
        },
        [theme.breakpoints.down("lg") ]: {
            marginBottom: '7%'
        },
        [theme.breakpoints.up("md") ]: {
            marginBottom: '7%'
        },
    },
    seatBlock: {
        height: "26px",
        width: "26px",
        borderRadius: "3px",
    },
    seatMain: {
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        alignItems: "center",
    },
    seatType: {
        display: "flex",
        fontFamily: "Poppins",
        fontWeight: 600,
        minWidth: "12.45px"
    },
    seatBody: {
        display: "flex",
        flexDirection: "row" as "row",
        gap: "6px",
        padding: "4px 20px",
        minWidth: "384px",
        "& .buy": {
            backgroundColor: "#e84e4e"
        },
        "& .available": {
            border: "1px solid #ffb43a ",
            boxSizing: "border-box",
        },
        "& .request": {
            background: "#60aa27",
        },
        "& .selected": {
            background: "#f6b438",
        },
        "& .unavailable": {
            background: "#404040",
        },
    },
    selectedSeat: {
        background: "#f6b438",
    },
    unAvailable: {
        background: "#404040",
    },
    editBox: {
        color: "#ffb43a",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "5% !important",
        cursor: "pointer",
        fontSize: 16,
        fontFamily: "Poppins",
        fontWeight: 500,
        "& .MuiSvgIcon-root": {
            height: "21px",
            marginLeft: "3px"
        }
    },
    [theme.breakpoints.down("sm")]: {
        datecontanier: {
            marginLeft: "1rem",
            marginTop: "24px",
        },
        seatBody: {
            marginLeft: "1rem",
            gap: "4px"
        },
        seatMain: {
            gap: "4px"
        },

        editBox: {
            marginRight: "23px"
        }
    },

    // For larger screens (md, lg, xl)
    [theme.breakpoints.up("md")]: {
        datecontanier: {
            marginLeft: "2rem",
        },
    },
    dialogContainer: {
        "& .MuiDialog-paper": {
            backgroundColor: "#404040",
            width: "23%",
            height: "45%",
            display: "flex",
        },
        "& .MuiDialogContent-root": {
            // padding: "75px 35px",
        },
        "& .MuiTypography-root": {
            color: "#fff",
            textAlign: "center"
        },
        "& .MuiDialogActions-root": {
            margin: "6%"
        },
        [theme.breakpoints.down("sm")]: {
            "& .MuiDialog-paper": {
                height: "34%",
                width: "46%"
            }
        },
        [theme.breakpoints.down("md")]: {
            "& .MuiDialog-paper": {
                height: "34%",
                width: "30%"
            }
        }

    },
    dialogBody: {
        border: "1px solid #f5b338",
        padding: "5px",
        color: "#FFF",
        textAlign: "center" as const,
        width: "40px",
        gap: "5px",
        cursor: "pointer",
        borderRadius: "8px",
        fontSize: "18px",
        fontFamily: "Poppins",
        fontWeight: 600,
        "& .selected": {
            backgroundColor: "#f5b338",
        },
        "&:hover": {
            backgroundColor: "#f5b338",
            color: "#121212"
        }
    },
    dialogContent: {
        // display: "flex",
        justifyContent: "flex-start",
        gap: "20px",
        maxHeight: "34%",
        wordBreak: "break-all" as "break-all",
        flexWrap: "wrap" as "wrap",
        textTransform: "capitalize",
    },
    dialogButton: {
        display: "flex",
        justifyContent: "center",
        color: " black",
        backgroundColor: "#f5b338",
        width: "100%",
        "&:hover": {
            backgroundColor: "#ffb43a"
        }
    },
    bookingValue: {
        display: 'flex',
        fontFamily: 'Poppins',
        textAlign: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 500,

    }
})
//@ts-ignore
export default (withStyles(styles)(Seatselection))
export { Seatselection }

// Customizable Area End
