import React from 'react';

import { Link } from "react-router-dom";

import AuctionBiddingNotificationController, { Props, configJSON } from "./AuctionBiddingNotificationController.web";

// Customizable Area Start
import { Grid, Typography, Container, Box, ThemeProvider, createTheme, CssBaseline, styled, Button, Paper, Fade, CircularProgress, Chip, Divider, FormControlLabel, FormControl, RadioGroup, Radio, Backdrop } from '@material-ui/core';

import "../assets/styles/index.css";
import Header from '../../../components/src/Header.web';
import { convertTo12HourFormat, getFormattedDate } from '../../../components/src/utilities';
import { CheckedRadioButton, Screen, editIcon } from './assets';
import { IRow, ISeat, ISection } from '../../../components/src/interfaces.web';
import Seat from '../../../components/src/Seat.web';
import RFHDialog from '../../../components/src/RFHDialog';

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    secondary: {
      main: "#fff",
    },
    background: {
      default: "#121212",
      paper: "#404040",
    },
    text: {
      primary: "#FFF"
    }
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h2: {
      fontSize: "32px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h5: {
      fontSize: "16px",
      margin: "16px 0"
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    body1: {
      fontSize: "18px",
    },
    subtitle1: {
      fontSize: "24px",
      fontWeight: 500,
      marginBottom: "16px"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#000",
        textTransform: "capitalize"
      },
    },
    MuiChip: {
      root: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#000",
        fontFamily: "Poppins",
        borderRadius: "8px",
        padding: "12px 0",
        lineHeight: 1,
        minHeight: "40px",
        backgroundColor: "#ffb53a",
        cursor: "pointer"
      },
      outlined: {
        fontSize: "12px",
        color: "#FFF",
        border: "solid 1px #ffb53a",
        background: "transparent"
      },
      label: {
        fontWeight: 500
      },
      clickable: {
        '&:active': {
          backgroundColor: '#ffb53a',
        },
        '&:focus': {
          backgroundColor: '#ffb53a', 
        },
        '&:hover': {
          backgroundColor: '#ffb53a', 
        },
      },
    },
    MuiRadio: {
      root: {
        color: '#ffb43a',
      },
    },
  },
})

const SELECTION_ARRAY = [
  { numberOfSeats: 1, label: "Solo"},
  { numberOfSeats: 2, label: "Twin"},
  { numberOfSeats: 3, label: "Trio"},
  { numberOfSeats: 4, label: "Band"},
  { numberOfSeats: 5, label: "Unit"},
  { numberOfSeats: 6, label: "Crew"},
  { numberOfSeats: 7, label: "Gang"},
  { numberOfSeats: 8, label: "Club"},
  { numberOfSeats: 9, label: "Team"},
  { numberOfSeats: 10, label: "Army"},
]

const svgIcon: React.ReactNode = <svg viewBox="0 0 24 24" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="12" ry="12" transform="translate(12 12)" fill="#fff" stroke-width="0"/><ellipse rx="7.183115" ry="7.052217" transform="matrix(1.113723 0 0 1.134395 12 12.000001)" fill="#ffb53a" stroke-width="0"/></svg>

// Customizable Area End

class AuctionBiddingNotification extends AuctionBiddingNotificationController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  header = () => {
    return (
      <Grid container xs={12} style={{ backgroundColor: "#e3dede",flexBasis: "0%"}}>
          <Grid xs={11}>
            <h2>Auction Bidding Notification</h2>
          </Grid>
          
      </Grid>
    )
  }
  // Customizable Area End
  render() {
    
    return (
      // Customizable Area Start
      <>
      <Box display="none">
        {this.header()}
        <Container maxWidth={"md"}>
          {
             this.state?.auction_product &&  this.state?.auction_product?.map((item) => 
              (<Grid>
                <Typography className="textStyle">{item.date}</Typography>
                <Grid>
                  {
                   item?.notifications && item?.notifications?.map((data) =>  (
                    <Grid item xs={12} style={{ margin: "20px 0px",backgroundColor: "#d3dbe8", padding:"10px", borderRadius:"10px" }}  >
                      <Grid container>
                        <Grid item>
                          <img
                            src={data?.auction_bidding?.data?.attributes?.product_images[0].url}
                            alt="image" style={{ width: "50px", height: "50px", borderRadius: "20px" }}
                          />
                        </Grid>
                        <Grid item xs style={{ paddingLeft: "20px" }}>
                          <Typography className="textStyle">Product Name : {data?.auction_bidding?.data?.attributes?.product_name}</Typography>
                          <Typography className="textStyle">Closing Price : ${data?.auction_bid?.amount}</Typography>
                          {/* <Typography className="textStyle">Date : {this.state?.auction_product[0].date}</Typography> */}

                          <Link to={`/AuctionBiddingPayment/${data?.auction_bidding?.data?.id}`}>Payment</Link>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                   ))
                    
                  }
                </Grid>
              </Grid>)
            )
          }

        </Container>
      </Box>

      <ThemeProvider theme={theme}>
          <CssBaseline />
          
          <MainWrapper>

            <Box>
              <Header seatType={true} navigation={this.props.navigation} />
            </Box>

            <Box flex={1}>

              <Container>
                {
                  this.state.isApiLoading ? (
                    <Fade in={this.state.isApiLoading} unmountOnExit>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress className="circular-progress" />
                      </Box>
                    </Fade>
                  ) : (
                      <Box mb={6} className='timeslot-wrapper'>
                        <Box className="timeslot-detail">
                          <Box className='timeslot-detail-wrapper'>
                            <Typography variant="h5">{getFormattedDate(this.state.selectedDetail.date, "ddd, DD MMM")}</Typography>
                            <Box className='edit-seat-number-mobile' onClick={this.openEditSeatNumber}>
                              <Typography className='seat-text'>{`${this.state.selection.numberOfSeats} ${configJSON.Tickets}`}</Typography>
                              <img src={editIcon} width="20px" height="20px" alt='edit' />
                            </Box>
                          </Box>
                          <Box className="chip__wrapper">

                            {
                              this.state.timeSlots.map((timeSlot) => (
                                <Chip
                                  label={convertTo12HourFormat(timeSlot.timeSlot)}
                                  key={timeSlot.screenId}
                                  variant={+timeSlot.screenId === this.state.selectedDetail.screenId ? "default" : "outlined"}
                                  onClick={() => this.getSeatGridById(+timeSlot.screenId)}
                                />
                              ))
                            }

                          </Box>
                        </Box>
                        <Box className='edit-seat-number' data-test-id="edit-button" onClick={this.openEditSeatNumber}>
                          <Typography data-test-id="selected-seat" className='seat-text'>{`${this.state.selection.numberOfSeats} ${configJSON.Tickets}`}</Typography>
                          <img src={editIcon} width="20px" height="20px" alt='edit' />
                        </Box>
                    </Box>
                  )
                }

                <Box className='screen-image-wrapper'>
                  <img src={Screen} className='screen__image' alt="screen" />
                  <Typography variant="h6" className="screen-message" align="center">{configJSON.ScreenMessage}</Typography>
                </Box>

                {
                  this.state.isGridApiLoading ? (
                    <Fade in={this.state.isGridApiLoading} unmountOnExit>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress className='circular-progress' />
                      </Box>
                    </Fade>
                  ) : (
                      <Box mb={4}>
                        <div className="seat-selection-root" ref={this.parentWrapperRef} data-test-id="seat-selection-wrapper">
                          <div className="seat-selection-wrapper" ref={this.childWrapperRef}>

                            {
                              this.state.seatGrid.map((section: ISection) => (
                                <Box className="section" key={section.sectionName}>

                                  <Box className={section.sectionName === this.state.selection.selectedSectionName ? "selected" : ""}></Box>

                                  <Typography className="section-title">
                                    {`${section.sectionName}:`}
                                    <span className="rupees-icon">&nbsp;&#8377;&nbsp;</span>
                                    <span className="price">{section.price}</span>
                                  </Typography>

                                  <Box className="seat-section-wrapper">
                                    {
                                      section.rows.map((seatRow: IRow) => (
                                        <Box className="seat-row-wrapper" key={seatRow.rowLabel}>
                                          <Typography className="seat-label">{seatRow.rowLabel}</Typography>

                                          {
                                            seatRow.seats.map((seat: ISeat) => (
                                              <Seat
                                                key={seat.id}
                                                seat={seat}
                                              />
                                            ))
                                          }

                                        </Box>
                                      ))
                                    }
                                  </Box>

                                </Box>
                              ))
                            }

                          </div>
                        </div>
                      </Box>
                  )
                }

              </Container>

            </Box>

            <Box>
              <Paper elevation={0} className="footer" data-test-id="footer-button">
                <Button 
                  disabled={this.state.isApiLoading || this.state.isGridApiLoading} 
                  onClick={this.handleBookTickets} 
                  className='submit-button' 
                  fullWidth
                  data-test-id="book-ticket-button"
                >
                  {configJSON.BookTickets}
                </Button>
              </Paper>
            </Box>

            <RFHDialog
              data-test-id="choose-seat-dialog"
              open={this.state.isChooseSeatOpen}
              handleClose={this.handleChooseSeat}
              maxWidth="sm"
              disableEscapeKeyDown={true}
              dialogActions={(
                <RFHDialogButton disabled={this.state.selection.selectedSectionName ? false : true } data-test-id="proceed-btn" fullWidth onClick={this.handleChooseSeat}>
                  {configJSON.Proceed}
                </RFHDialogButton>
              )}
              dialogContent={(
                <DialogWrapper>
                  <Box className='seat-section-wrapper'>
                    {
                      SELECTION_ARRAY.map(selection => (
                        <Box className='seat-wrapper' key={selection.numberOfSeats}>
                          <span className='number-text'>{selection.label}</span>
                          <Button data-test-id="number-of-seat" onClick={() => this.changeNumberOfSeats(selection.numberOfSeats)} variant={selection.numberOfSeats === this.state.selection.numberOfSeats ? "contained": "outlined"} className='number-button'>{selection.numberOfSeats}</Button>
                        </Box>
                      ))
                    }
                  </Box>
                  <Divider className='divider' />
                  <Box className='seat-option-wrapper'>
                    {
                      this.state.isGridApiLoading ? (
                        <Fade in={this.state.isGridApiLoading} unmountOnExit>
                          <Box display="flex" justifyContent="center">
                            <CircularProgress className='circular-progress' />
                          </Box>
                        </Fade>
                      ) : (
                        <FormControl className='form-wrapper'>
                            <RadioGroup
                              row
                              aria-labelledby="demo-form-control-label-placement"
                              name="position"
                              defaultValue={this.state.selection.selectedSectionName}
                              onChange={(event) => this.changeSectionSelect(event.target.value)}
                              className='radio-group'
                              data-test-id='seat-tier'
                            >
                            {
                              this.state.seatSectionDetails.map(section => (
                                <FormControlLabel
                                  key={section.id}
                                  value={section.seat_type}
                                  control={<Radio checkedIcon={<img src={CheckedRadioButton} className='radio__button__checked' alt="icon" />} />}
                                  labelPlacement="bottom"
                                  className="option-label"
                                  label={
                                    <Box className='option-detail'>
                                      <span className='seat-type'>{section.seat_type}</span>
                                      <span><span className="rupees-icon">&#8377;&nbsp;</span>{section.price.toFixed(2)}</span>
                                      <span className={section.seat_status.toLowerCase() === "sold_out" ? "seat-status sold-out" : "seat-status"}>{section.seat_status}</span>
                                    </Box>
                                  }
                                />
                              ))
                            }

                          </RadioGroup>
                        </FormControl>
                      )
                    }
                  </Box>
                </DialogWrapper>
              )}
              dialogTitle={(
                <DialogWrapper>
                  <Typography data-test-id="dialog-title" className='dialog__title'>{configJSON.DialogTitle}</Typography>
                </DialogWrapper>
              )}
          />

            <RFHDialog
              data-test-id="edit-seat-dialog"
              open={this.state.isEditSeatOpen}
              handleClose={this.closeEditSeat}
              maxWidth="sm"
              disableEscapeKeyDown={true}
              dialogActions={(
                <RFHDialogButton disabled={this.state.selection.selectedSectionName ? false : true} data-test-id="proceed-btn" fullWidth onClick={this.closeEditSeat}>
                  {configJSON.Proceed}
                </RFHDialogButton>
              )}
              dialogContent={(
                <DialogWrapper>
                  <Box className='seat-section-wrapper'>
                    {
                      SELECTION_ARRAY.map(selection => (
                        <Box className='seat-wrapper' key={selection.numberOfSeats}>
                          <Button data-test-id="number-of-seat" onClick={() => this.changeNumberOfSeats(selection.numberOfSeats)} variant={selection.numberOfSeats === this.state.selection.numberOfSeats ? "contained" : "outlined"} className='number-button'>{selection.numberOfSeats}</Button>
                        </Box>
                      ))
                    }
                  </Box>
                </DialogWrapper>
              )}
              dialogTitle={(
                <DialogWrapper>
                  <Typography data-test-id="dialog-title" className='dialog__title'>{configJSON.DialogTitle}</Typography>
                </DialogWrapper>
              )}
            />

            <Backdrop className="backdrop" open={this.state.isBackDropOpen} >
              <CircularProgress className='circular-progress' color="inherit" />
            </Backdrop>

          </MainWrapper>

      </ThemeProvider>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "& .backdrop": {
    color: "#ffb43a",
    zIndex: 2000
  },
  "& .circular-progress": {
    marginTop: "20px",
    color: "#ffb43a"
  },
  "& .timeslot-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .edit-seat-number": {
      cursor: "pointer",
      display: "inline-flex",
      gap: "4px",
      "& .seat-text": {
        color: "#ffb43a",
        fontSize: "16px",
        fontWeight: 500
      },
      "@media (max-width: 600px)": {
        display: "none"
      }
    },
    "& .timeslot-detail": {
      "& .timeslot-detail-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& > .edit-seat-number-mobile": {
          cursor: "pointer",
          display: "none",
          gap: "4px",
          "& .seat-text": {
            color: "#ffb43a",
            fontSize: "16px",
            fontWeight: 500
          },
          "@media (max-width: 600px)": {
            display: "inline-flex"
          }
        },
      },
      "& .chip__wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        marginTOp: "8px",
        overflowX: "scroll",
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      } 
    },
    "@media (max-width: 600px)": {
      display: "block"
    }
  },
  "& .screen-image-wrapper": {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginBottom: "32px",
    "& .screen-message": {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "118px"
    },
    "& .screen__image": {
      width: "362px",
      height: "70px",
      "@media (max-width: 600px)": {
        width: "251px",
        height: "50px",
      }
    }
  },
  "& .seat-selection-root": {
    display: "flex",
    justifyContent: "center",
    overflowX: "scroll",
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    "& .seat-selection-wrapper": {
      width: "fit-content",
      "& .section": {
        marginBottom: "48px",
        position: "relative",
        "& .section-title": {
          fontWeight: 500,
          marginBottom: "32px",
          textTransform: "capitalize",
          "& .rupees-icon": {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 600
          },
          "& .price": {
            fontWeight: 600,
            fontFamily: "Poppins"
          },
          "&:last-child": {
            marginBottom: 0
          }
        },
        "& .seat-section-wrapper": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          "& .seat-row-wrapper": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            "& .seat-label": {
              fontSize: "16px",
              fontWeight: 500,
              width: "40px"
            }
          }
        },
        "& .selected": {
          position: "absolute",
          top: "48px",
          left: "-10px",
          right: "-12px",
          bottom: "-12px",
          border: "solid 1px #ffb53a",
          borderRadius: "8px"
        },
      },
    }
  },
  "& .footer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px",
    backgroundColor: "#40404040",
    "& .submit-button": {
      padding: "16px",
      borderRadius: "12px",
      backgroundColor: "#ffb43a",
      color: "#000",
      fontWeight: 600,
      fontSize: "18px",
      fontFamily: "Poppins, sans-serif",
      maxWidth: "420px",
      border: "none"
    }
  }
})

const webStyle = {
  DialogTitle: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#FFF",
    textAlign: "center" as const
  }
}

const RFHDialogButton = styled(Button)({
  textTransform: "capitalize",
  color: "#000",
  backgroundColor: "#ffb43a",
  borderRadius: "12px",
  height: "56px",
  fontWeight: 600,
  fontSize: "16px",
  marginBottom: "12px",
  "&:hover": {
    backgroundColor: "#ffb43a"
  }
})

const DialogWrapper = styled(Box)({
  "& .dialog__title": {
    fontWeight: 600,
    fontSize: "20px",
    color: "#FFF",
    textAlign: "center" as const,
  },
  "& .seat-section-wrapper": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
    gridGap: "32px",
    marginTop: "28px",
    "& .seat-wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "50px",
      "& .number-text": {
        color: "#FFF",
        fontWeight: 500,
        fontSize: "16px",
        marginBottom: "12px",
        "@media (max-width: 600px)": {
          fontSize: "12px !important",
          marginBottom: "10px !important"
        },
      },
      "& .number-button": {
        color: "#FFF",
        border: "solid 1.1px #ffb53a",
        borderRadius: "6.5px",
        width: "40px",
        height: "40px",
        fontWeight: 600,
        fontSize: "18px",
        minWidth: "unset !important",
        "&.MuiButton-contained": {
          backgroundColor: "#ffb53a",
          color: "#000",
          boxShadow: "unset"
        }
      }
    },
  },
  "& .divider": {
    backgroundColor: "#979797",
    marginTop: "32px",
    marginBottom: "22px"
  },
  "& .seat-option-wrapper": {
    minHeight: "60px",
    "& .form-wrapper": {
      display: "flex",
      alignItems: "center",
      "& .radio-group": {
        width: "100%",
        justifyContent: "space-around",
        "& .option-label": {
          margin: "0 8px",
          "& .radio__button__checked": {
            width: "23px",
            height: "23px",
            "@media (max-width: 600px)": {
              width: "18px",
              height: "18px"
            }
          },
          "& .option-detail": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "2px",
            "& .seat-type": {
              textTransform: "uppercase",
              fontSize: "16px",
            },
            "& .seat-status": {
              fontSize: "16px",
              color: "#ffb43a",
              textTransform: "capitalize",
              "&.sold-out": {
                color: "#FFF"
              },
            },
            "& .rupees-icon": {
              fontFamily: "Roboto, sans-serif"
            },
            "@media (max-width: 600px)": {
              fontSize: "14px",
              "& .seat-type": {
                fontSize: "12px",
              },
              "& .seat-status": {
                fontSize: "12px",
              },
            }
          },
          "& .MuiButtonBase-root": {
            minHeight: "42px"
          },
        },
        "@media (max-width: 600px)": {
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px"
          }
        }
      }
    },
    
  },
  "@media (max-width: 600px)": {
    "& .dialog__title": {
      fontSize: "16px !important",
    },
    "& .seat-section-wrapper": {
      gridTemplateColumns: "repeat(auto-fill, minmax(22px, 1fr))",
      "& .seat-wrapper": {
          "& .number-text": {
              fontSize: "12px !important",
          },
          "& .number-button": {
              width: "32px !important",
              fontSize: "14px !important",
              height: "32px !important",
          }
      }
  },
  }
})
// Customizable Area End

export default AuctionBiddingNotification