import React from "react";

import {
    Box,
    Typography,
    // Customizable Area Start

    Paper,
    Container,
    Avatar,
    CssBaseline,
    Hidden,
    Chip,
    IconButton,
    Popper,
    Popover,
    ClickAwayListener,
    MenuList,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, createTheme, styled, withStyles } from "@material-ui/core/styles";
import {
    starFilled,
    starOutline,
    thumbsDown,
    thumbsUp,
    logo,
    back,
    FilterIcon,
} from "./assets"
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";

const configJSON = require("./config");


const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#404040",
            default: "#121212",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h3: {
            fontSize: "20px",
            fontWeight: 600,
            "@media (max-width: 600px)": {
                fontSize: "16px"
            }
        },
        h4: {
            fontSize: "16px",
            "@media (max-width: 600px)": {
                fontSize: "14px"
            }
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    breakpoints: {
        values: {
            xs: 300,
            sm: 600,
            md: 900,
            lg: 1400,
            xl: 1920,
        },
    },
    overrides: {
        MuiChip: {
            root: {
                fontSize: "12px",
                fontWeight: 500,
                color: "#000",
                fontFamily: "Poppins",
                borderRadius: "12px",
                padding: "8px 6px",
                lineHeight: 1,
                height: "36px",
                backgroundColor: "#ffb53a",
                cursor: "pointer"
            },
            outlined: {
                fontSize: "12px",
                color: "#FFF",
                border: "solid 1px #b4b4b4",
                background: "#2f2617"
            },
            label: {
                fontWeight: 500
            },
            clickable: {
                '&:active': {
                    backgroundColor: '#ffb53a',
                },
                '&:focus': {
                    backgroundColor: '#ffb53a',
                },
                '&:hover': {
                    backgroundColor: '#ffb53a',
                },
            },
        },
    }
});

const RFHMenuList = styled(MenuList)({
    minWidth: "190px",
    padding: "0 !important",
    "& > li:nth-child(odd)": {
        fontFamily: "Poppins",
        color: "#fff",
        padding: "8px 16px",
        fontWeight: 500,
        fontSize: "16px",
        border: "solid 1px #5a5a5a !important",
        minHeight: "42px",
        backgroundColor: "#5a5a5a !important",
        "&.Mui-selected": {
            color: "#000",
            backgroundColor: "#ffb43a !important",
            border: "none"
        },
        "&:hover": {
            color: "#000",
            backgroundColor: "#ffb43a !important"
        }
    },
    "& > li:nth-child(even)": {
        color: "#fff",
        padding: "8px 16px",
        fontWeight: 500,
        fontFamily: "Poppins",
        fontSize: "16px",
        border: "solid 1px #5a5a5a !important",
        minHeight: "42px",
        backgroundColor: "#404040 !important",
        "&.Mui-selected": {
            color: "#000",
            border: "none",
            backgroundColor: "#ffb43a !important",
        },
        "&:hover": {
            backgroundColor: "#ffb43a !important",
            color: "#000",
        }
    }
})


// Customizable Area End
import RatingsReviewsController, { Props } from "./RatingsReviewsController.web";
import { countReviewDays } from '../../../components/src/utilities'
import { thumbsUpFilled } from "../../reservations2/src/assets";
import { thumbsDownFilled } from "../../../components/src/assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { ISelectOption } from "../../../components/src/interfaces.web";
import { ReviewsFilterOption } from "../../../components/src/enums.web";

class RatingsReviews extends RatingsReviewsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ReviewFilterOptionList: ISelectOption<ReviewsFilterOption>[] = [
        { label: "Newest first", value: ReviewsFilterOption.NewestFirst },
        { label: "Oldest first", value: ReviewsFilterOption.OldestFirst },
    ]
    // Customizable Area End

    render() {
        const { classes } = this.props
        const { movieName } = this.state
        const hashtagChunks: any[] = [];
        const hashtagsPerRow = 3;
        for (let i = 0; i < this.state.hastaglist.length; i += hashtagsPerRow) {
            hashtagChunks.push(this.state.hastaglist.slice(i, i + hashtagsPerRow));
        }

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme} >
                <CssBaseline />
                <MainWrapper>
                    <Box>
                        <Hidden smDown>
                            <MainHeaderComp navigation={this.props.navigation} />
                        </Hidden>
                    </Box>

                    <Box flex={1}>
                        <Container>

                            <Box className="header">
                                <img src={back} data-test-id="back" className="back__button" alt="back-button" onClick={this.handleBack} />
                                <Typography variant="h3" className="movie__title">{movieName}</Typography>
                                <Hidden smUp>
                                    <IconButton
                                        className="filter__button"
                                        onClick={this.handleFilterClick}
                                        aria-haspopup="true"
                                        aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                                        aria-expanded={this.state.anchorEl ? 'true' : undefined}
                                    >
                                        <img src={FilterIcon} className="filter__icon" alt="filter-button" />
                                    </IconButton>
                                </Hidden>
                            </Box>

                            <Paper className="summary">
                                <Typography variant="h3" className="summary__label">{configJSON.summaryLabel}</Typography>
                                <Typography variant="h4" className="rating">{`${Math.ceil(this.state.reviewInfo.averageRating)}/5`}</Typography>
                                <Box className="rating__star">
                                    {
                                        [...Array(5)].map((_, index) => <img src={index < this.state.reviewInfo.averageRating ? starFilled : starOutline} className="star" alt="star" />)
                                    }
                                </Box>
                                <Typography variant="h4" className="ratings__count">
                                    {`${configJSON.ratingsLabel} ${this.state.reviewInfo.ratingsCount}`}
                                </Typography>
                            </Paper>

                            <Box className="filter_main_wrapper" display={{ xs: "block", sm: "flex" }}>
                                <Box className="filter__wrapper">
                                    {
                                        this.state.FilterList.map((item) => (
                                            <Chip
                                                label={
                                                    <Box className="rating__label">
                                                        {item.label}
                                                        {typeof item.label == "string" ? null : <img className="filter__star" src={starFilled} alt="filter-star" />}
                                                    </Box>
                                                }
                                                key={item.filterId}
                                                variant={this.state.selectedFilter.rating === item.filterId ? "default" : "outlined"}
                                                onClick={() => this.handleRatingAndSort(item.filterId, this.state.selectedFilter.sortBy)}
                                                data-test-id="star"
                                            />
                                        ))
                                    }
                                </Box>

                                <Hidden smDown>
                                    <IconButton
                                        className="filter__button"
                                        onClick={this.handleFilterClick}
                                        aria-haspopup="true"
                                        aria-controls={this.state.anchorEl ? 'basic-menu' : undefined}
                                        aria-expanded={this.state.anchorEl ? 'true' : undefined}
                                        data-test-id="filter-menu-button"
                                    >
                                        <img src={FilterIcon} className="filter__icon" alt="filter-button" />
                                        <Typography variant="subtitle2" className="filter__text">{configJSON.Filter}</Typography>
                                    </IconButton>
                                </Hidden>

                                <Popper
                                    className="popper__wrapper"
                                    placement="bottom-end"
                                    anchorEl={this.state.anchorEl}
                                    transition
                                    disablePortal
                                    role={undefined}
                                    open={!!this.state.anchorEl}
                                >
                                    <Popover
                                        onClose={this.handleFilterClose}
                                        anchorEl={this.state.anchorEl}
                                        open={!!this.state.anchorEl}
                                        transformOrigin={{
                                            horizontal: 'right',
                                            vertical: 'top',
                                        }}
                                        PaperProps={{
                                            style: {
                                                boxShadow: "none",
                                                borderRadius: "12px",
                                                background: 'transparent !important'
                                            },
                                        }}
                                        anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom',
                                        }}
                                    >
                                        <ClickAwayListener onClickAway={this.handleFilterClose}>
                                            <RFHMenuList
                                                id="composition-menu"
                                                autoFocusItem={!this.state.anchorEl}
                                                data-test-id="filter-menu-root"
                                            >
                                                {
                                                    this.ReviewFilterOptionList.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            data-test-id="filter-menu"
                                                            selected={this.state.selectedFilter.sortBy === option.value}
                                                            onClick={() => this.handleRatingAndSort(this.state.selectedFilter.rating, option.value)}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </RFHMenuList>
                                        </ClickAwayListener>
                                    </Popover>

                                </Popper>
                            </Box>

                            {
                                this.state.filterReviewList.map((review) => (
                                    <Paper className="review__wrapper" data-test-id="review-wrapper">
                                        <Box className="user__info">
                                            <Avatar src={review.profileUrl} alt="profile" className="user__profile" />
                                            <Box className="info__wrapper">
                                                <Typography className="user">{review.reviewerName}</Typography>
                                                <Typography className="booked__text">{`${configJSON.bookedOn}  `}</Typography>
                                                <img className="logo" src={logo} alt="ticket-change" />
                                            </Box>
                                        </Box>

                                        <Box className="rating__star">
                                            {
                                                [...Array(5)].map((_, index) => <img src={index < review.rating ? starFilled : starOutline} className="star" alt="star" />)
                                            }
                                            <Typography className="review_days">{countReviewDays(review.reviewDate)}</Typography>
                                        </Box>

                                        <Typography className="review">{review.reviewDescription}</Typography>

                                        <Box className="action__wrapper" id="like-dislike">
                                            <IconButton onClick={() => this.handleLike(review.reviewId)} disabled={review.liked} data-test-id="like">
                                                <img src={review.liked ? thumbsUpFilled : thumbsUp} className="thumb__icon" />
                                            </IconButton>
                                            <span className="like__count">{review.likeCount}</span>
                                            <IconButton onClick={() => this.handleDislike(review.reviewId)} disabled={review.disliked} data-test-id="dislike">
                                                <img src={review.disliked ? thumbsDownFilled : thumbsDown} className="thumb__icon" />
                                            </IconButton>
                                            <span className="like__count">{review.dislikeCount}</span>
                                        </Box>
                                    </Paper>
                                ))
                            }


                        </Container>
                    </Box>

                    <Box>
                        <FooterWithEvents />
                    </Box>

                </MainWrapper>
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& .header": {
        display: "flex",
        margin: "28px 0",
        alignItems: "center",
        "& .back__button": {
            width: "28px",
            height: "28px",
            cursor: "pointer",
            marginRight: "20px"
        },
        "& .movie__title": {
            fontWeight: 600
        },
        "& .filter__button": {
            padding: 0,
            marginLeft: "auto",
            "& .filter__icon": {
                width: "20px",
                height: "20px"
            },
            "& .filter__text": {
                color: "#fff",
                fontSize: "14px",
                marginLeft: "8px"
            }
        }
    },
    "& .summary": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "24px 16px",
        gap: "12px",
        borderRadius: "8px",
        "& .summary__label": {
            fontWeight: 600
        },
        "& .rating": {
            fontWeight: 600
        },
        "& .rating__star": {
            display: "flex",
            alignItems: "center",
            gap: "6px",
            "& .star": {
                width: "22px",
                height: "22px"
            }
        },
        "& .ratings__count": {
            
        }
    },
    "& .filter_main_wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        "& .filter__wrapper": {
            margin: "28px 0",
            display: "flex",
            gap: "16px",
            overflowX: "scroll",
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            "& .rating__label": {
                display: "flex",
                alignItems: "center",
                "& .filter__star": {
                    height: "14px",
                    width: "14px",
                    marginLeft: "4px"
                }
            },
        },
        "& .filter__button": {
            padding: 0,
            "& .filter__icon": {
                width: "20px",
                height: "20px"
            },
            "& .filter__text": {
                color: "#fff",
                fontSize: "14px",
                marginLeft: "8px"
            }
        }
    },
    "& .review__wrapper": {
        padding: "16px",
        borderRadius: "8px",
        marginBottom: "28px",
        "& .user__info": {
            display: "flex",
            gap: "12px",
            "& .user__profile": {
                width: "44px",
                height: "44px",
                border: "none",
                borderRadius: "44px"
            },
            "& .info__wrapper": {
                "& .user": {
                    fontSize: "16px",
                    marginBottom: "4px"
                },
                "& .booked__text": {
                    display: "inline",
                    fontSize: "10px",
                },
                "& .logo": {
                    width: "12px",
                    height: "12px"
                }
            },
        },
        "& .rating__star": {
            display: "flex",
            alignItems: "center",
            gap: "6px",
            margin: "22px 0 8px",
            "& .star": {
                width: "22px",
                height: "22px"
            }
        },
        "& .review_days": {
            fontWeight: 300,
            color: "#b4b4b4",
            fontSize: "16px",
        },
        "& .review": {
            fontSize: "16px",
            fontWeight: 300
        },
        "& .action__wrapper": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "20px",
            "& > button": {
                padding: 0,
            },
            "& .thumb__icon": {
                height: "22px",
                margin: "0 6px 0 0"
            },
            "& .like__count": {
                fontSize: "12px",
                color: "#b4b4b4",
                marginRight: "18px",
            }
        }
    },
    "@media (max-width: 600px)": {
        "& .header": {
            margin: "22px 0 !important",
            "& .back__button": {
                width: "24px !important",
                height: "24px !important",
                marginRight: "16px"
            },
        },
        "& .summary": {
            padding: "16px 8px !important",
            gap: "10px",
        },
        "& .review__wrapper": {
            padding: "12px !important",
            marginBottom: "28px !important",
            "& .user__info": {
                "& .user__profile": {
                    width: "40px !important",
                    height: "40px !important",
                    borderRadius: "40px !important"
                },
                "& .info__wrapper": {
                    "& .user": {
                        fontSize: "14px !important",
                    },
                    "& .booked__text": {
                        fontSize: "8px !important",
                    },
                    "& .logo": {
                        width: "10px !important",
                        height: "10px !important"
                    }
                },
            },
        },
        "& .rating__star": {
            margin: "18px 0 6px !important",
            gap: "4px !important",
            "& .star": {
                width: "16px !important",
                height: "16px !important"
            }
        },
        "& .review_days": {
            fontSize: "12px !important",
        },
        "& .review": {
            fontSize: "12px !important",
        },
        "& .action__wrapper": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "20px",
            "& > button": {
                padding: 0,
            },
            "& .thumb__icon": {
                height: "18px !important",
                margin: "0 6px 0 0"
            },
            "& .like__count": {
                fontSize: "12px",
                color: "#b4b4b4",
                marginRight: "18px",
            }
        }
    },
})

const styles = (theme: any) => ({
    ThumbIcon: {
        height: "24px"
    },
    LikeCount: {
        fontSize: "12px",
        color: "#b4b4b4",
        fontFamily: "Poppins",
        width: "25px",
        display: "flex",
        alignItems: "center"
    },
    countStyle: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#b4b4b4'
    },
    MainWrapper: {
        padding: "26px 0",
    },
    fontStyle: {
        fontFamily: 'Poppins',

        fontSize: '16px',
        color: '#fff'
    },
    reviewMain: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '20px'
    },
    mainBox: {
        display: "flex",
        flexDirection: 'column' as const,
        gridGap: '10px'
    },
    boxContent: {
        display: 'flex', gridGap: '10px', margin: "20px"
    },
    imagecontent: {
        display: 'flex', alignItems: 'center', gridGap: '3px'
    },
    mainPaperStyle: {
        width: '100vw',
        display: 'flex',
        backgroundColor: "#121212",
        flexDirection: 'column' as const,
    },
    reviewMainBox: {
        backgroundColor: '#404040',
        padding: '14px 22px',
        borderRadius: '6px',
        marginLeft: '6px',
        boxSizing: 'border-box' as const,
        marginRight: '22px',
    },
    movieName: {
        display: 'flex',
        fontSize: '22px',
        fontFamily: 'Poppins',
        marginLeft: '16px',
        fontWeight: 600,
    },
    mainHeading: {
        backgroundColor: "#404040",
        borderRadius: "5px",
        width: "100%",
        height: "auto",
        margin: "17px",
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '20px'
        },
        [theme.breakpoints.down('md')]: {
            margin: '25px'
        }
    },
    heading: {
        display: "flex",
        justifyContent: "center",
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        margin: '26px',

    },
    rate: {
        display: "flex",
        justifyContent: "center",
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        margin: '26px',

    },
    rating: {
        display: "flex",
        justifyContent: "center",
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        margin: '26px',

    },
    starConatiner: {
        display: "flex",
        justifyContent: "center",
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        margin: '26px',
    },
    starBody: {
        height: '32px',
        width: '32px'
    },
    reviewHead: {
        display: 'flex',
        background: '#404040',
        margin: '51px 0px 0px 0px',
        fontSize: '20px',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    avatarStyle: {
        height: '52px',
        width: '52px',
        marginRight: '14px'
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row' as const,
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: 500,
    },
    filterContainer: {
        display: 'flex',
        justifyContent: 'center',
        background: '#2f2617',
        padding: '8px 17px',
        borderRadius: '12px',
        border: 'solid 1px #b4b4b4',
        margin: '32px 16px 38px',
        alignItems: 'center',
        gridGap: '5px',
        cursor: 'pointer'

    },
    FilterWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row' as const,
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        alignItems: 'center',
        marginRight: "14px",
        cursor: 'pointer'
    },
    typographyWord: {
        wordBreak: 'break-all' as const,
    },
    typographyWord1: {
        wordBreak: 'break-all' as const,
        marginLeft: "10px",
    }
})
export default (withStyles(styles)(RatingsReviews))
export { RatingsReviews }
// Customizable Area End
