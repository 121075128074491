Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPutMethod = "PUT";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.ReviewsApiContentType = "application/json";
exports.getReviewsAPiEndPoint = "reviews/reviews";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.titleTitle = "Title";
exports.descriptionTitle = "Description";
exports.accountIdTitle = "Account Id";
exports.anonymousTitle = "Anonymous";

exports.description = "Description";
exports.account_id = 1;
exports.anonymous = false;
exports.namePlaceHolder = "Name";
exports.titlePlaceHolder = "Title";
exports.numberPlaceHolder = "Phone number";
exports.descriptionPlaceHolder = "Description";

exports.labelTitleText = "Reviews";
exports.labelBodyText = "Reviews Body";

exports.btnExampleTitle = "CLICK ME";
exports.contentTypeApi = "application/json";
exports.getHashTagsApiEndPoint = "bx_block_catalogue/tags"
exports.createReviewsApiEndPoint = "bx_block_reviews/reviews"
exports.reviewApiCallUrl = "bx_block_reviews/reviews";
exports.guestToken = "guest_user";
exports.reviewApiEndPoint = "bx_block_reviews/reviews/average_ratings";
exports.likeApiEndPoint = "bx_block_like/likes"
exports.dislikeikeApiEndPoint = "bx_block_like/likes/dislike"

exports.summaryLabel = "Ratings & Reviews";
exports.ratingsLabel = "Ratings:";
exports.bookedOn = "Booked on";
exports.Filter = "Filter";
exports.ratingTitle = "Add Rating & Review";
exports.experienceTitle = "Rate your experience";
exports.formTitle = "Write a Review";
exports.submit = "Submit"; 
// Customizable Area End