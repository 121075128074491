import React from "react";

// Customizable Area Start
import MTicket from "../../../components/src/MTicket";
// Customizable Area End

import ElectronicTicketController, { 
  Props,
  configJSON
} from "./ElectronicTicketController";

export default class ElectronicTicket extends ElectronicTicketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {
          this.state.isDataLoaded && <MTicket booking={this.state.booking} />
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
