import React from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { previousButton } from "./assets";

export const PreviousBtnPlay = ({ className, onClick, currentSlide }:any) => {
    return (
     <>
    { currentSlide != 0 && 
        <div className={className} onClick={onClick}>
            <img src={previousButton} className="previous__button" alt="previous" />
        </div>
    }
    </>
  );
};

 export const PreviousBtnMain = ({ className, onClick }:any) => {
  
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon style={{ color: "black", fontSize: "28px" , paddingLeft:'10px' }} />
    </div>
  );
};