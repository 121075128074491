import React from 'react';
import { Link } from "react-router-dom";

import moment from "moment";
import AuctionBiddingController, { Props, configJSON } from "./AuctionBiddingController.web";

// Customizable Area Start
import { Grid, Typography, Container, Tab, Tabs, Box, ThemeProvider, CssBaseline, createTheme, Fade, styled, CircularProgress, Divider, Button, Chip, FormGroup, FormControlLabel, TextField, Checkbox, Tooltip } from '@material-ui/core';

import "../assets/styles/index.css";
import { FeedbackSuccessful, PlayBtn, backButton, filledHeart, notification, outlinedHeart, reportIcon, shareImg } from "./assets";
import MainHeaderComp from '../../../components/src/MainHeaderComp.web';
import FooterWithEvents from '../../../components/src/FooterWithEvents.web';
import { convertToPad, getFormattedDate, getMovieDuration, getTrailersText, goToPrevPage } from '../../../components/src/utilities';
import ListWithToggle from '../../../components/src/ListWithToggle.web';
import CardCarousel from '../../../components/src/CardCarousel';
import RFHDialog from '../../../components/src/RFHDialog';
import { CheckBoxOutlined } from '@material-ui/icons';
import Share from '../../share/src/Share.web';
import { AddFavourites } from '../../favourites/src/AddFavourites.web';

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    }
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h2: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#FFF",
      "@media (max-width: 600px)": {
        fontSize: "16px"
      }
    },
    h3: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#FFF",
      "@media (max-width: 600px)": {
        fontSize: "16px"
      }
    },
    h5: {
      fontSize: "14px",
      color: "#FFF",
      margin: "12px 0",
      "@media (max-width: 600px)": {
        fontSize: "12px",
        fontWeight: "200",
        margin: "0",
      }
    },
    h6: {
      fontSize: "16px",
      color: "#FFF",
      fontWeight: 500,
    },
    body1: {
      fontSize: "16px",
      color: "#FFF",
      fontWeight: 300,
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    subtitle1: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#FFF",
      marginBottom: "14px",
      "@media (max-width: 600px)": {
        fontSize: "16px",
        marginBottom: "12px"
      }
    }
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#000",
        textTransform: "capitalize"
      },
      contained: {
        backgroundColor: "#ffb43a",
        "&:hover": {
          backgroundColor: "#ffcc80 !important",
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#121212",
        color: "#b4b4b4",
        border: "solid 1px #b4b4b4",
        position: 'relative',
        fontFamily: "Poppins"
      },
      arrow: {
        '&:before': {
          border: 'solid 1px #b4b4b4',
        },
        color: '#121212',
      },

    }
  }
})
// Customizable Area End

class AuctionBidding extends AuctionBiddingController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  liveAuctionView = () => {
    return (
      <>
        {this.state.live_auction && this.state.live_auction.map((item) => (
          <Grid item xs={12} style={{ margin: "20px 0px",backgroundColor: "#d3dbe8", padding:"10px", borderRadius:"10px" }} key={item.id} >
            <Grid container>
              <Grid item>
                <img
                  src={item?.attributes?.product_images[0]?.url}
                  alt="image" style={{ width: "120px", height: "120px", borderRadius: "20px" }}
                />
              </Grid>
              <Grid item xs style={{ paddingLeft: "20px" }}>
                 <Typography className="textStyle">Product Name : {item?.attributes?.product_name}</Typography>
                <Typography className="textStyle">Minimum Price : ${item?.attributes?.minimum_bid}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.start_time).format('DD/MM/YYYY')}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.end_time).format('DD/MM/YYYY')}</Typography>
                <Typography className="textStyle">Current Bid Price : ${item?.attributes?.maximum_bid_placed}</Typography>
              </Grid>
              <Link to={`AuctionBiddingDetail/${item.id}`}>Details</Link>
            </Grid>
          </Grid>
        ))}
        {this.state.live_auction == null &&
          <Grid item xs={12} style={{ margin: "20px 0px" }} >
            <Typography className={"textStyle"}>No Product Found</Typography>
          </Grid>
        }
      </>

    )
  }

  upcomingAuctionView = () => {
    return (
      <>
        {this.state.upcoming_auction && this.state.upcoming_auction.map((item) => (
          <Grid item xs={12} style={{ margin: "20px 0px",backgroundColor: "#d3dbe8", padding:"10px", borderRadius:"10px" }} key={item.id}>
            <Grid container>
              <Grid item>
                <img
                  src={item?.attributes?.product_images[0].url}
                  alt="image" style={{ width: "120px", height: "120px", borderRadius: "20px" }}
                />
              </Grid>
              <Grid item xs style={{ paddingLeft: "20px" }}>
                <Typography className="textStyle">Product Name : {item?.attributes?.product_name}</Typography>
                <Typography className="textStyle">Minimum Price : ${item?.attributes?.minimum_bid}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.start_time).format('DD/MM/YYYY')}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.end_time).format('DD/MM/YYYY')}</Typography>
              </Grid>

              <Link to={`AuctionBiddingDetail/${item.id}`}>Details</Link>
            </Grid>
            
          </Grid>
        ))}
        {this.state.upcoming_auction == null &&
          <Grid item xs={12} style={{ margin: "20px 0px" }} >
            <Typography className="textStyle">No Product Found</Typography>
          </Grid>
        }
      </>
    )
  }

  header = () => {
    return (
      <Grid container xs={12} style={{ backgroundColor: "#e3dede",flexBasis: "0%"}}>
          <Grid xs={11}>
            <h2>Auction Bidding</h2>
          </Grid>
          <Grid xs={1}>
            <Link to={`AuctionBiddingNotification`}>
              <img
                src={notification}
                alt="image" style={{ width: "40px", height: "40px", marginTop: "10px" }}
              />
            </Link>
            
          </Grid>
      </Grid>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
      <Box display="none">
        {this.header()}
        <Container maxWidth={"md"}>
          <Tabs
            value={this.state.selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
            className="tabStyle"
            classes={{
              indicator: "indicator"
            }}
            data-testid="auction_tab"
          >

            <Tab label="Live Auction" />
            <Tab label="Upcoming Auction" />
          </Tabs>
          {configJSON.labelTitleText}
          <Grid container style={{ paddingLeft: "20px" }}>
            {this.state.selectedTab == 0 && this.liveAuctionView()}
            {this.state.selectedTab == 1 && this.upcomingAuctionView()}
          </Grid>
        </Container>
      </Box>

      <ThemeProvider theme={theme}>
            <CssBaseline />

          <MainWrapper>
            <Box display={{ xs: "none", sm: "block" }}>
              <MainHeaderComp />
            </Box>
            <Box flex={1}>

                {
                  this.state.isLoading ? (
                    <Fade in={this.state.isLoading} unmountOnExit>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress className='circular-progress' />
                      </Box>
                    </Fade>
                  ) : (
                    <>
                      <Box display={{ xs: "none", sm: "block" }} className="hero-section-wrapper">
                        <img src={this.state.movieDetails.attributes.image_url} alt="movie-poster" />

                        <Container>
                          
                          <Box className='movie-details-wrapper'>

                            <Box>

                              <Typography variant="h2" data-test-id="movie-title">{this.state.movieDetails.attributes.name}</Typography>
                              <Box className='category-wrapper'>
                                {this.state.movieDetails.attributes.category_type.split(", ").map((category) => <span key={category} className='category'>{category}</span>)}
                              </Box>
                              <Typography variant='h5'>
                                {configJSON.UA}&nbsp;&nbsp;
                                {configJSON.Duration}
                                {getMovieDuration(this.state.movieDetails.attributes.duration_in_minutes)}
                              </Typography>
                              <Typography variant='h5' className='screen-types'>
                                {(this.state.movieDetails.attributes.screen_types.map(screen => screen.screen_type)).join(", ")}
                                &nbsp;&nbsp;
                                <ListWithToggle itemsList={this.state.movieDetails.attributes.languages.map(language => language.language_name)} />
                              </Typography>
                              <Typography className='release-date'>
                                {configJSON.ReleaseDate}
                                {getFormattedDate(this.state.movieDetails.attributes.release_date, "DD MMM, YYYY")}
                              </Typography>
                              <Box>
                                <Box className='countdown-wrapper'>

                                  <Typography variant='h6' align='center'>Closes in</Typography>

                                  <Box className='timer-number-wrapper'>
                                    <Box>
                                      <Typography align='center' className='numbers'>
                                        {this.state.days}
                                      </Typography>
                                      <Typography className='time-text'>
                                        {configJSON.Days}
                                      </Typography>
                                    </Box>

                                    <Divider />

                                    <Box>
                                      <Typography className='numbers' align='center'>
                                        {convertToPad(this.state.hours)}
                                      </Typography>
                                      <Typography className='time-text'>
                                        {configJSON.Hours}
                                      </Typography>
                                    </Box>

                                    <Divider />

                                    <Box>
                                      <Typography className='numbers' align='center'>
                                        {convertToPad(this.state.minutes)}
                                      </Typography>
                                      <Typography className='time-text'>
                                        {configJSON.Minutes}
                                      </Typography>
                                    </Box>

                                    <Divider />

                                    <Box>
                                      <Typography className='numbers' align='center'>
                                        {convertToPad(this.state.seconds)}
                                      </Typography>
                                      <Typography className='time-text'>
                                        {configJSON.Seconds}
                                      </Typography>
                                    </Box>
                                  </Box>

                                </Box>
                              </Box>
                              <Box className='action-wrapper'>
                                <Button 
                                  disabled={this.state.isExpired}
                                  data-test-id="apply-btn"
                                  className="apply-btn"
                                  variant="contained"
                                  size="small"
                                  onClick={this.handleApplyForIto}
                                >
                                  {configJSON.ApplyForITO}
                                </Button>

                                <Box data-test-id="play-btn" onClick={() => this.redirectToTrailers()}>
                                  <Tooltip title="Play Trailer" arrow>
                                    <img className="trailer-button-wrapper" src={PlayBtn} alt="trailer-button" />
                                  </Tooltip>
                                </Box>

                                <AddFavourites
                                  navigation={this.props.navigation} 
                                  id="navigation"
                                  addFavourites={{
                                    heartIcon: outlinedHeart,
                                    filledHeart: filledHeart,
                                    imageStyle: webStyle.shareImageStyle,
                                    favouriteable_id: this.state.movieDetails.id,
                                    favouriteable_type: "BxBlockCategories::Movie",
                                    tooltipDirection: "bottom",
                                    isFavourite: this.state.movieDetails.attributes.is_favourite || false
                                  }}
                                />

                                <Share shareDetails={{ shareSrc: shareImg, imageStyle: webStyle.shareImageStyle }} navigation={this.props.navigation} id="share" />
                              </Box>

                            </Box>
                            
                            <img src={this.state.movieDetails.attributes.image_url} alt="poster" />
                          </Box>

                        </Container>
                      </Box>

                      <Box display={{ xs: "block", sm: "none" }} className='mobile__hero__section'>
                        <Box className='mobile__header'>
                          <img src={backButton} className='back__button' alt="back-button" onClick={goToPrevPage} />
                          <Box className='header__action'>
                            <AddFavourites
                              id="navigation"
                              navigation={this.props.navigation}
                              addFavourites={{
                                filledHeart: filledHeart,
                                heartIcon: outlinedHeart,
                                imageStyle: webStyle.shareImageStyle,
                                favouriteable_type: "BxBlockCategories::Movie",
                                favouriteable_id: this.state.movieDetails.id,
                                isFavourite: this.state.movieDetails.attributes.is_favourite || false,
                                tooltipDirection: "bottom",
                              }}
                            />

                            <Share 
                              shareDetails={{ imageStyle: webStyle.shareImageStyle, shareSrc: shareImg }} 
                              navigation={this.props.navigation} 
                              id="share" 
                            />
                            
                          </Box>
                        </Box>
                      </Box>

                      <Box display={{ xs: "block", sm: "none" }}>
                        <Box className='mobile__poster__wrapper' onClick={this.redirectToTrailers}>
                          <img src={this.state.movieDetails.attributes.image_url} alt="poster" className='poster' />
                          <Box className='trailer__wrapper'>
                            <img
                              src={PlayBtn}
                              className='trailer__icon'
                            />
                            <span className='trailer__text'>{`${getTrailersText(this.state.movieDetails.attributes.youtube_links)} (${this.state.movieDetails.attributes.youtube_links.length})`}</span>
                          </Box>

                          <Box className='backdrop'></Box>
                        </Box>
                      </Box>

                      <Box display={{ xs: "block", sm: "none" }} className='mobile__hero__section'>

                        <Box className='main__hero'>

                          <img className='poster' src={this.state.movieDetails.attributes.image_url} alt='poster' />

                          <Box className='movie__details'>
                            <Typography variant="h2">{this.state.movieDetails.attributes.name}</Typography>
                            <Box className='category__wrapper'>
                              {this.state.movieDetails.attributes.category_type.split(", ").map((category) => <span key={category} className='category'>{category}</span>)}
                            </Box>
                            <Typography variant='h5'>
                              {configJSON.UA}&nbsp;&nbsp;
                              {configJSON.Duration}
                              {getMovieDuration(this.state.movieDetails.attributes.duration_in_minutes)}
                            </Typography>
                            <Typography className='screen__types'>
                              <ListWithToggle itemsList={this.state.movieDetails.attributes.screen_types.map(screen => screen.screen_type)} />
                              &nbsp;&nbsp;
                              <ListWithToggle itemsList={this.state.movieDetails.attributes.languages.map(language => language.language_name)} />
                            </Typography>
                            <span className='release__date'>
                              {configJSON.ReleaseDate}
                              {getFormattedDate(this.state.movieDetails.attributes.release_date, "DD MMM, YYYY")}
                            </span>
                          </Box>

                        </Box>

                        <Box className='button__wrapper'>
                          <Button
                            disabled={this.state.isExpired}
                            className="apply__btn"
                            variant="contained"
                            onClick={this.handleApplyForIto}
                          >
                            {configJSON.ApplyForITO}
                          </Button>
                        </Box>

                        <Box>
                          <Box className='countdown-wrapper'>

                            <Typography variant='h6' align='center'>Closes in</Typography>

                            <Box className='timer-number-wrapper'>
                              <Box>
                                <Typography className='numbers' align='center'>{this.state.days}</Typography>
                                <Typography className='time-text'>{configJSON.Days}</Typography>
                              </Box>

                              <Divider />

                              <Box>
                                <Typography className='numbers' align='center'>{convertToPad(this.state.hours)}</Typography>
                                <Typography className='time-text'>{configJSON.Hours}</Typography>
                              </Box>

                              <Divider />

                              <Box>
                                <Typography className='numbers' align='center'>{convertToPad(this.state.minutes)}</Typography>
                                <Typography className='time-text'>{configJSON.Minutes}</Typography>
                              </Box>

                              <Divider />

                              <Box>
                                <Typography className='numbers' align='center'>{convertToPad(this.state.seconds)}</Typography>
                                <Typography className='time-text'>{configJSON.Seconds}</Typography>
                              </Box>
                            </Box>

                          </Box>
                        </Box>

                        <Divider className='side-divider' />

                      </Box>

                      <Container>
                        <Box className='description-wrapper' maxWidth="923px">
                            <Typography data-test-id="title" className="title" variant="h3">{configJSON.MovieDescription}</Typography>
                            <Typography variant="body1">{this.state.movieDetails.attributes.description}</Typography>
                        </Box>
                      </Container>

                      <Container className='divider-container'>

                        <Divider className='side-divider' />

                      </Container>

                      <Container>

                        <Box>
                          <Typography variant='subtitle1'>{configJSON.Cast}</Typography>

                          {
                            this.state.isMovieCastLoading ? (
                              <Fade in={this.state.isLoading} unmountOnExit>
                                <Box display="flex" justifyContent="center">
                                  <CircularProgress className='circular-progress' />
                                </Box>
                              </Fade>
                            ) : (
                              <CardCarousel cardList={this.state.movieCastList} />
                            )
                          }

                        </Box>

                        <Divider className='side-divider' />

                        <Box>
                          <Typography variant='subtitle1'>{configJSON.Crew}</Typography>

                          {
                            this.state.isMovieCrewLoading ? (
                              <Fade in={this.state.isLoading} unmountOnExit>
                                <Box display="flex" justifyContent="center">
                                  <CircularProgress className='circular-progress' />
                                </Box>
                              </Fade>
                            ) : (
                              <CardCarousel cardList={this.state.movieCrewList} />
                            )
                          }

                        </Box>

                        <Divider className='side-divider' />

                      </Container>
                    </>
                  )
                }


            </Box>
            <Box>
              <FooterWithEvents />
            </Box>

            <RFHDialog
              data-test-id="feedback-form-dialog"
              open={this.state.isFeedbackFromOpen}
              handleClose={this.handleCloseFeedbackForm}
              maxWidth="md"
              disableEscapeKeyDown={true}
              dialogContent={(
                <form data-test-id="submit-form" onSubmit={this.submitFeedbackForm}>
                  <FeedbackFromWrapper>
                    <Box display="flex" justifyContent="center" p={0}>
                      <img src={reportIcon} className='feedback-icon' alt="done" />
                    </Box>
                    <Typography className="feedback__title">
                      {configJSON.CastAndCrew}
                    </Typography>
                    <Typography className="feedback__question">{configJSON.CastAndCrewQuestion}</Typography>
                    <FormGroup data-test-id='form'>
                      {this.state.feedBackOptions.map(option => (
                        <FormControlLabel
                          key={option.optionId}
                          label={option.label}
                          className="formcontrol__label"
                          control={
                            <Checkbox
                              size='small'
                              data-test-id="feedback-checkbox"
                              checked={option.checked}
                              onChange={() => this.selectOption(option.optionId)}
                              value={option.label}
                              checkedIcon={<CheckBoxOutlined className='checked__icon' />}
                            />
                          }
                        />
                      ))}
                    </FormGroup>


                    <Typography className="message__title">
                      {configJSON.EnterMessage}
                    </Typography>
                    <TextField
                      minRows={3}
                      maxRows={4}
                      placeholder={configJSON.TextAreaMessage}
                      multiline
                      fullWidth
                      required={this.state.feedBackOptions.find(option => option.optionId === 6)?.checked}
                      value={this.state.feedbackMessage}
                      onChange={this.changeFeedbackMessage}
                      className="message__content"
                      data-test-id="feedback-message"
                    />

                    <RFHDialogButton type='submit' disabled={!(this.state.feedbackMessage !== "" || this.state.feedBackOptions.some(option => option.checked))} data-test-id="submit-feedback-btn" fullWidth>
                      {configJSON.Submit}
                    </RFHDialogButton>
                  </FeedbackFromWrapper>
                </form>
              )}
            />


            <RFHDialog
              data-test-id="feedback-successful-dialog"
              open={this.state.isFeedbackOpen}
              handleClose={this.handleFeedbackClose}
              maxWidth="sm"
              disableEscapeKeyDown={true}
              dialogActions={(
                <RFHDialogButton data-test-id="feedback-done-btn" fullWidth onClick={this.handleFeedbackClose}>
                  {configJSON.Done}
                </RFHDialogButton>
              )}
              dialogContent={(
                <FeedbackFromWrapper>
                  <Box display="flex" justifyContent="center" flexDirection="column">
                    <Box display="flex" justifyContent="center" p={0}>
                      <img src={FeedbackSuccessful} height="48px" width="48px" alt="done" />
                    </Box>
                    <Typography align="center" className='successful__message'>
                      {configJSON.FeedbackSuccessful}
                    </Typography>
                  </Box>
                </FeedbackFromWrapper>
              )}
            />

            <RFHDialog
              data-test-id="language-format-dialog"
              open={this.state.isSelectLanguageOpen}
              handleClose={this.handleLanguageClose}
              maxWidth="sm"
              disableEscapeKeyDown={true}
              dialogTitle={
                <RFHContentWrapper>
                  <Typography align="center" className='header__title'>{configJSON.SelectLanguageAndFormat}</Typography>
                </RFHContentWrapper>
                }
              dialogActions={(
                <RFHDialogButton 
                  data-test-id="language-format-btn" 
                  fullWidth 
                  onClick={this.proceedToBook}
                  disabled={!(this.state.selectedFormatId && this.state.selectedLanguageId)}
                >
                  {configJSON.Continue}
                </RFHDialogButton>
              )}
              dialogContent={(
                <RFHContentWrapper>
                  <Typography className="title">{configJSON.SelectLanguage}</Typography>
                  <Box className='options__wrapper'>
                    {
                      this.state.movieDetails?.attributes?.languages.map(language => (
                        <Chip
                          data-test-id="language-chip"
                          label={language.language_name}
                          variant={this.state.selectedLanguageId === language.language_id ? "outlined" : "default"}
                          key={language.language_id}
                          className="modified__chip"
                          onClick={() => this.selectLanguageFormat(language.language_id, "selectedLanguageId")}
                        />
                      ))
                    }
                  </Box>

                  <Divider className='divider' />

                  <Box className='options__wrapper'>
                    {
                      this.state.movieDetails?.attributes?.screen_types.map(screen_type => (
                        <Chip
                          data-test-id="format-chip"
                          label={screen_type.screen_type}
                          variant={this.state.selectedFormatId === screen_type.screen_type_id ? "outlined" : "default"}
                          key={screen_type.screen_type_id}
                          className="modified__chip"
                          onClick={() => this.selectLanguageFormat(screen_type.screen_type_id, "selectedFormatId")}
                        />
                      ))
                    }
                  </Box>

                </RFHContentWrapper>
              )}
            />

          </MainWrapper>

      </ThemeProvider>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const RFHDialogButton = styled(Button)({
  textTransform: "capitalize",
  color: "#000",
  backgroundColor: "#ffb43a",
  borderRadius: "12px",
  height: "56px",
  fontWeight: 600,
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#ffb43a"
  },
  "@media (max-width: 600px)":{
    height: "48px",
    fontSize: "16px"
  }
})

const RFHContentWrapper = styled(Box)({
  "& .header__title": {
    color: "#FFF",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Poppins, sans-serif",
  },
  "& .title": {
    fontFamily: "Poppins",
    color: "#FFF",
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "16px",
    "@media (max-width: 600px)": {
      marginBottom: "12px"
    }
  },
  "& .options__wrapper": {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px"
  },
  "& .divider": {
    height: "0.5px",
    backgroundColor: "#979797",
    margin: "24px 0"
  },
  "& .modified__chip": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    borderRadius: "8px",
    padding: "12px 0",
    lineHeight: 1.2,
    minHeight: "40px",
    cursor: "pointer",
    color: "#FFF",
    border: "solid 1px #ffb43a",
    backgroundColor: "#2f2617",
    "&.MuiChip-outlined": {
      backgroundColor: "#ffb53a",
      color: "#000",
    },
    "&:hover": {
      backgroundColor: "#ffb53a",
      color: "#000",
    }
  },
  "@media (max-width: 600px)": {
    "& .header__title": {
      fontSize: "16px !important",
    },
    "& .title": {
      fontSize: "14px !important",
    },
    "& .modified__chip": {
      fontSize: "12px !important",
      minHeight: "unset !important",
      padding: "10px 0 !important"
    },
    "& .divider": {
      margin: "18px 0 !important"
    },
  }
})

const FeedbackFromWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& .feedback-icon": {
    width: "80px",
    aspectRatio: "1/1.02",
    marginBottom: "25px",
  },
  "& .feedback__title": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    color: "#FFF",
    marginBottom: "16px"
  },
  "& .feedback__question": {
    fontSize: "18px",
    color: "#FFF"
  },
  "& .formcontrol__label": {
    margin: "5px 0",
    "& .checked__icon": {
      color: "#ffb43a",
    },
    "& .MuiCheckbox-root": {
      padding: "4px",
      marginRight: "6px",
      color: "#fff",
      "& .MuiSvgIcon-root": {
        fontSize: "1.1rem"
      }
    }
  },
  "& .message__title": {
    fontSize: "20px",
    color: "#FFF",
    fontWeight: 500,
    margin: "50px 0 16px"
  },
  "& .message__content": {
    border: "solid 1px #b4b4b4",
    borderRadius: "12px",
    fontSize: "16px",
    marginBottom: "20px",
    "& .MuiInput-underline:after": {
      display: "none"
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& textarea": {
      color: "#b4b4b4",
      padding: "16px"
    },
  },
  "& .successful__message": {
    color: "#FFF",
    marginTop: "30px",
    fontSize: "24px",
    fontWeight: 600,
  },
  "@media (max-Width: 600px)": {
    "& .feedback-icon": {
      width: "70px !important",
    },
    "& .feedback__title": {
      fontSize: "16px !important",
    },
    "& .feedback__question": {
      fontSize: "14px !important",
    },
    "& .formcontrol__label": {
      margin: "0 !important"
    },
    "& .message__title": {
      fontSize: "14px !important",
      margin: "30px 0 14px !important"
    },
    "& .message__content": {
      marginBottom: "0 !important",
      "& textarea": {
        padding: "12px !important"
      }
    },
    "& .successful__message": {
      fontSize: "16px !important"
    }
  }
})

const webStyle = {
  FeedbackSuccessful: {
    color: "#FFF",
    marginTop: "32px",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Poppins, sans-serif",
  },
  Title: {
    color: "#FFF",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Poppins, sans-serif",
  },
  shareImageStyle: {
    height: '32px',
    width: '32px',
    cursor: "pointer"
  }
}

const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "& .circular-progress": {
    marginTop: "20px",
    color: "#ffb43a"
  },
  "& .hero-section-wrapper": {
    position: "relative",
    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: -100
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.9),rgba(0,0,0,0.8),rgba(0,0,0,0.4), rgba(0,0,0,0.1))",
      backdropFilter: "blur(6px)",
      zIndex: -99,
    },
    "& .movie-details-wrapper": {
      display: "flex",
      justifyContent: "space-between",
      padding: "35px 45px 40px 0",
      "& > img": {
        width: "320px",
        aspectRatio: 0.73,
      },
      "& .category-wrapper": {
        display: "flex",
        gap: "16px",
        margin: "20px 0",
        flexWrap: "wrap",
        "& .category": {
          color: "#FFF",
          fontSize: "14px",
          border: "solid 0.5px #ffb43a",
          padding: "8px",
          borderRadius: "1px",
          fontFamily: "Poppins, sans-serif"
        },
      },
      "& .screen-types": {
        textTransform: "uppercase"
      },
      "& .release-date": {
        border: "solid 0.8px #fff",
        backgroundColor: "#2f2617",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "14px",
        padding: "8px",
        borderRadius: "4px",
        display: "inline-block"
      },
      
      "& .action-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "24px",
        "& .apply-btn": {
          borderRadius: "10px",
          border: "none",
          padding: "12px 28px",
          fontWeight: 600,
          fontSize: "14px",
          "&.Mui-disabled": {
            backgroundColor: "#ffb43a",
            cursor: "not-allowed",
          }
        },
        "& .trailer-button-wrapper" : {
          width: "32px",
          height: "32px",
          cursor: "pointer"
        }
      },
    },
  },
  "& .mobile__hero__section": {
    padding: "16px 12px",
    "& .mobile__header": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .back__button": {
        height: "24px",
        width: "24px"
      },
      "& .header__action": {
        display: "flex",
        alignItems: "center",
        gap: "12px", 
        "& img": {
          height: "24px !important",
          width: "24px !important"
        }
      }
    },
    "& .main__hero": {
      display: "flex",
      "& .poster": {
        marginRight: "14px",
        width: "125px",
        aspectRatio: 0.79,
        border: "solid 1px #ffb43a",
        overflow: "hidden"
      },
      "& .movie__details": {
        width: "212px",
        "& .category__wrapper": {
          margin: "12px 0 8px", 
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
          "& .category": {
            padding: "4px",
            fontSize: "12px",
            color: "#FFF",
            border: "solid 0.5px #ffb53a"
          }
        },
        "& .screen__types": {
          textWrap: "wrap",
          maxWidth: "185px",
          color: "#FFF",
          fontWeight: 400,
          margin: "4px 0 10px",
          fontSize: "10px"
        },
        "& .release__date": {
          fontSize: "10px",
          color: "#FFF",
          border: "solid 0.5px #fff",
          padding: "4px 8px",
          backgroundColor: "#2f2617",
          borderRadius: "4px",
          fontWeight: 300,
        }
      }
    },
    "& .button__wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "20px 0",
      "& .apply__btn": {
        padding: "4px 22px",
        borderRadius: "6px"
      }
    },
    "& .side-divider": {
      margin: "0",
    },
  },
  "& .mobile__poster__wrapper": {
    position: "relative",
    "& .poster": {
      width: "100%",
      height: "211px",
      objectFit: "cover" as const
    },
    "& .backdrop": {
      position: "absolute" as const,
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      backgroundColor: "grey",
      opacity: 0.4,
    },
    "& .trailer__wrapper": {
      position: "absolute" as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "4px",
      cursor: "pointer",
      "& > img": {
        width: "22px",
        height: "22px"
      },
      "& .trailer__text": {
        fontSize: "12px",
        fontWeight: 600,
        color: "#fff",
        fontFamily: "Poppins, sans-serif",
      }
    }
  },
  "& .description-wrapper": {
    margin: "26px 0",
    "& .title": {
      marginBottom: "16px"
    },
    "@media (max-width: 600px)": {
      margin: "5px 0 0"
    }
  },
  "& .divider-container": {
    padding: "0 8px",
  },
  "& .side-divider": {
    margin: "28px 0",
    backgroundColor: "#404040",
    "@media (max-width: 600px)": {
      margin: "24px 0"
    }
  },
  "& .feedback-content": {
    color: "#FFF",
    fontSize: "24px",
    fontWeight: 600,
  },
  "& .countdown-wrapper": {
    border: "solid 1px #fff",
    backgroundColor: "#2f2617",
    fontFamily: "Poppins",
    padding: "18px 20px 20px",
    borderRadius: "8px",
    margin: "28px 0 38px",
    width: "350px",
    "& .timer-number-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "10px",
      "& .numbers": {
        fontSize: "16px",
        fontWeight: 600,
        color: "#ffb43a",
      },
      "& .time-text": {
        fontSize: "12px",
        color: "#fff"
      },
      "& hr": {
        backgroundColor: "#8a8a8a",
        height: "24px",
        width: "1px"
      }
    },
    "@media (max-width: 600px)": {
      width: "100%",
      margin: "25px 0",
    }
  },
})
// Customizable Area End

export default AuctionBidding