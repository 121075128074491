import React from "react";

import {
    // Customizable Area Start
    Container,
    Grid,
    Paper,
    Box,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Divider,
    InputAdornment
    // Customizable Area End
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
// Customizable Area Start

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

});
import { backarrowleft, close } from "./assets";
import { Seat } from "../../../components/src/BookingSuccessfulTicket.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";

// Customizable Area End

import ListToSellController, {
    Props,
} from "./ListToSellController.web";
import moment from "moment";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";




class ListToSell extends ListToSellController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { isModalOpen, bookingData, seatID, bookingResponse } = this.state;
        const { classes } = this.props;
        const attributes = bookingData && bookingData.attributes
        const dateArray = attributes && attributes?.show_date_time.split(" | ")
        const bookingAttributes = bookingResponse && bookingResponse.attributes

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    <Box className={classes.listWrapperBoxs} >
                        <Container >
                            <Box className={classes.listWrapperHeads} data-test-id="listHead">
                                <img src={backarrowleft} alt="" style={{
                                    width: '32px', height: '32px', cursor: "pointer"
                                }}
                                    data-test-id="back" onClick={() => this.handleNavigate(bookingAttributes?.booking_id)} />
                                <Typography component={'p'} data-testid="head" className={classes.listWrapperTypos} >List To Sell</Typography>
                            </Box>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                    <Box className={classes.listBgs} >
                                        <Box className={classes.listMainWrappers}>
                                            <Box className={classes.ImageWrapper} >
                                                <img src={attributes?.movie.image_url} alt="" className={classes.MovieImage} />
                                            </Box>
                                            <Box className={classes.listMovieWrappers}>
                                                <Typography component={'p'} className={classes.listWrapperTypos} >{attributes?.movie.name}</Typography>
                                                <Box className={classes.listBodyTypogrphys}>
                                                    <span>{moment(dateArray && dateArray[0]).format("ddd DD MMM,  YYYY")}
                                                        <span style={{ color: '#ffb43a' }}> |</span> </span>
                                                    <span> {dateArray && dateArray[1]}</span>
                                                </Box>
                                                <Typography component={'p'}>{attributes?.theatre_name}</Typography>
                                                <Typography component={'p'}> {attributes?.language}, {attributes?.screen_type}</Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>


                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <Box style={{ minHeight: '680px' }} className={classes.listBgs}>
                                        <Box>
                                            <Typography component={'p'} className={classes.listTypos}> List to Sell</Typography>
                                            <Box minHeight="514px">
                                                <Typography component={'p'} className={classes.lisFontSize}
                                                    style={{ margin: '24px 0px 24px 0px' }}> Choose Tickets for Listing to Sell</Typography>
                                                <Box className={classes.listSeatBoxs}>
                                                    <Box className={classes.listMainWrappers} style={{ cursor: 'pointer' }} data-test-id="seats" >
                                                        {attributes?.seat_id_number.map((seat: any) => (
                                                            <Box className={classes.listBoxBgs} key={seat.seat_id} data-test-id={`seatSelect${seat.seat_id}`} onClick={() => this.handleSelectTicket(seat.seat_id)}  >
                                                                <Typography component={"span"}>{seat.seat_number}</Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    {this.state.seatsSelected === true ?
                                                        <Box>
                                                            <Typography component={'p'}
                                                                style={{ margin: '24px 0px 24px 0px' }}
                                                                className={classes.lisFontSize}>
                                                                Listed Tickets: {seatID.length.toLocaleString('en-US', { minimumIntegerDigits: 2 })}

                                                            </Typography>
                                                            <Box className={classes.listMainWrappers}>
                                                                {seatID.map((seat: any) => (
                                                                    <Box key={seat.seat_id}>
                                                                        <Seat seatLabel={seat.seat_number} />
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                            <Box>
                                                                <Typography component={'p'}
                                                                    style={{ margin: '24px 0px 24px 0px' }}
                                                                    className={classes.lisFontSize}>
                                                                    Enter Price per Ticket

                                                                </Typography>
                                                                <Box>
                                                                    <TextField
                                                                        data-test-id="price"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        placeholder="Enter Price"
                                                                        value={this.state.price}
                                                                        inputProps={{ min: "0" }} className={classes.listPrices}
                                                                        onBlur={() => this.isPriceValid()}
                                                                        onChange={(e: any) => this.handleChange(e)}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    {this.state.price && <span className={classes.RupeesSymbols}>{"\u20B9"}</span>}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Box className={classes.priceError}>
                                                                        {this.state.priceError}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        :

                                                        <Box>
                                                            <Typography component={'p'}
                                                                style={{ margin: '24px 0px 24px 0px' }}
                                                                className={classes.lisFontSize}>
                                                                Selected Seats
                                                            </Typography>
                                                            <Box className={classes.listMainWrappers}>
                                                                {attributes?.seat_id_number.map((seat: any) => (
                                                                    <Box className={classes.listBoxBgs} key={seat.seat_id}>
                                                                        <span></span>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Button className={classes.listBtns} disabled={this.state.price <=0} data-test-id={'list'} onClick={this.createLisApi}>List to Sell</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Container>
                    </Box>
                    <FooterWithEvents />
                </Paper>
                <Dialog open={isModalOpen}
                    PaperProps={{
                        style: {
                            backgroundColor: "#404040",
                            color: "#fff",
                            padding: '28px',
                            position: "relative"
                        }
                    }}>
                    <Box className={classes.closeIconWrapper}>
                        <img src={close} alt="closebutton"
                            color="primary"
                            data-test-id="closeModal"
                            className={classes.listCloseIcons}
                            onClick={() => this.handleNavigate(bookingAttributes?.booking_id)}
                        />
                    </Box>
                    <DialogTitle id="alert-dialog-slide-title" className={classes.noPadding}>
                        <Box className={classes.listTilteHeadBoxs}>
                            <Box className={classes.listTitleWrappers}>
                                {"Tickets Listed Successfully!"}
                            </Box>
                            <Box className={classes.listDialogBoxs}>
                                You have successfully listed Tickets to Sell.
                            </Box>
                        </Box>
                    </DialogTitle>

                    <DialogContent className={classes.noPadding}>
                        <Box>
                            <Box className={classes.listSubeWrappers}
                            >
                                {bookingAttributes?.movie_name}
                            </Box>
                            <Box>
                                <Box className={classes.listBoxWrappers}>
                                    Tickets Listed :
                                    <Box>
                                        {bookingAttributes?.ticket_requested.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Price Per Ticket :
                                    <Box>
                                        <span className={classes.RupeesSymbols}>{"\u20B9"}&nbsp;</span>
                                        {bookingAttributes?.ticket_price && bookingAttributes?.ticket_price.toFixed(2)}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Total Amount :
                                    <Box>
                                        <span className={classes.RupeesSymbols}>{"\u20B9"}&nbsp;</span>
                                        {bookingAttributes?.sub_total && bookingAttributes?.sub_total.toFixed(2)}
                                    </Box>
                                </Box>
                            </Box>
                            <Divider className={classes.customDividers} />
                            <Box>
                                <Box className={classes.listBoxWrappers}>
                                    Request Id :
                                    <Box>
                                        {bookingAttributes?.request_id}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Time :
                                    <Box>
                                        {bookingAttributes?.slot_time ? this.calculateAMorPM(bookingAttributes?.slot_time) : null}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Date :
                                    <Box>
                                        {moment(bookingAttributes?.slot_date).format("ddd, DD MMM  YYYY")}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </DialogContent>
                    <DialogActions className={classes.noPadding}>
                        <Button className={classes.listDialogBtns} data-test-id="navigate" onClick={() => this.handleNavigate(bookingAttributes?.booking_id)}>Done</Button>
                    </DialogActions>

                </Dialog>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const styles = () => ({
    ImageWrapper: {
        width: "179px",
        height: "229px",
        border: "solid 1px #ffb53a",
        marginRight: "18px",
        "@media (max-width: 660px)": {
            width: "130px",
            height: "174px",
        },
        "@media (max-width: 560px)": {
            marginRight: "12px",
            width: "110px",
            height: "130px",
        },
    },
    MovieImage: {
        width: '100%',
        height: '100%',
    },
    priceError: {
        color: ' red',
        fontFamily: 'Poppins',
        fontSize: '16px',
    },
    RupeesSymbols: {
        fontFamily: 'Roboto'
    },
    listBoxWrappers: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#fff',
        margin: '12px 9px 9px 0px',

    },
    customDividers: {
        backgroundColor: "#979797",
    },
    listTilteHeadBoxs: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    listDialogBoxs: {
        fontSize: '18px',
        fontFamily: 'Poppins',
        marginTop: '12px',
        textAlign: 'center' as const,
        color: '#fff',
    },
    listDialogBtns: {
        width: '100%',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: '#202020',
        fontSize: '18px',
        textTransform: 'none' as const,
        // bottom: '5%',
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',

        }

    },
    closeIconWrapper: {
        position: "absolute" as const,
        top: "12px",
        right: "12px"
    },
    noPadding: {
        paddingLeft: 0,
        paddingRight: 0
    },
    listCloseIcons: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        height: '28px',
        width: '28px',
        cursor: 'pointer',
    },
    listTitleWrappers: {
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '22px',
        fontWeight: 600,
        textAlign: 'center' as const,
        "@media (max-width: 600px)": {
            fontSize: "18px",
        }
    },
    listSubeWrappers: {
        height: '33px',
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center' as const,
        margin: '5px auto',
        display: 'flex',
        justifyContent: 'center'

    },
    mainWrappers: {
        padding: "26px 106px",
        maxWidth: '1440px',
    },
    listMainWrappers: {
        display: 'flex',
        gridGap: '5px',
        fontFamily: 'Poppins',

    },
    listWrapperHeads: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '15px',
        marginBottom: '22px'
    },
    listWrapperTypos: {
        fontSize: '24px',
        fontWeight: 600
    },
    listBodyTypogrphys: {
        fontSize: '18px',
        fontWeight: 600
    },
    lisFontSize: {
        fontSize: '22px',
        fontWeight: 600
    },
    listWrapperBoxs: {
        minHeight: '100vh',
    },
    listMovieWrappers: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '8px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
    listPrices: {
        width: '156px',
        height: '56px',
        border: 'solid 1px #fff',
        backgroundColor: '#404040',
        borderRadius: '12px',
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '26px'
        }
    },
    listTypos: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 600,
        color: "#b4b4b4"
    },
    listTexts: {
        width: '122px',
        height: '33px',
        margin: '32px 582px 32px 24px',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: '0.12px',
        textAlign: 'center' as const,
        color: '#fff',
    },
    listSeatBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '30px'
    },
    listBgs: {
        backgroundColor: 'rgba(64,64,64,0.4)',
        padding: '26px',
        "@media (max-width: 600px)": {
            padding: "14px"
        }
    },
    listBoxBgs: {
        backgroundColor: '#404040',
        width: '28px',
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listBtns: {
        width: '100%',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        color: '#202020',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        textTransform: 'none' as const,
        marginTop: "16px",
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',

        }

    },
})
//@ts-ignore
export default (withStyles(styles)(ListToSell))
export { ListToSell }
// Customizable Area End
