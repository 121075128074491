import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Container,
    Divider,
    Fade,
    Grid,
    Icon,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { backarrowleft, car, downGreyIcon, food, greenIcon, InvalidStatusImage, location, phone, redIcon, upGreyIcon } from "./assets";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import MapDialog from "../../../components/src/MapDialog";
import RFHDialog from "../../../components/src/RFHDialog";
import { PaymentSuccessfull } from "../../requestmanagement/src/assets";
import { RFHButton } from "../../events/src/SelectPreference.web";
import { SeatType } from "./ViewTicket.web";
import { EventSeat } from "../../../components/src/BookingSuccessfulTicket.web";
import { IEventransactionDetails } from "../../../components/src/interfaces.web";
import KycDialogeBox from "../../cfwallet19/src/KycDialogeBox.web";
import IITOMovieEventTicketsInfo from "../../../components/src/ITOMovieEventTicketsInfo.web";
import { isDateTimeInPast } from "../../../components/src/utilities";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1:{
            fontSize:24,
            fontWeight: 600
        },
        h2:{
            fontSize:20,
            fontWeight: 600,
            color:"#b4b4b4"
        },
        h3:{
            fontSize:18,
            fontWeight: 600
        },
        h4:{
            fontSize:18,
            fontWeight: "normal"
        },
        h5:{
            fontSize:16,
            fontWeight: "normal"
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const configJSON = require("./config");
// Customizable Area End

import EventViewTicketController, {
    Props,
} from "./EventViewTicketController";

class EventViewTicket extends EventViewTicketController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    buttonComponent() {
        const { classes } = this.props;
        const { is_list_to_sell, listed_resell_seats, seat_allocation_type, event: { booking_end_date, booking_end_time } } = this.state.eventBookingData.attributes;
        const buttonContent = !is_list_to_sell ? 'List to Sell' : 'Cancel Listing';
        const hideListToSellButton = isDateTimeInPast(booking_end_date, booking_end_time)
        return hideListToSellButton ? null : (
            <>
                <Box>
                    <Button className={classes.viewBtns}
                        disabled={this.isShowTimeOut() || seat_allocation_type === "manual"}
                        data-test-id={'view'}
                        onClick={() => !is_list_to_sell ? this.navigateToListTOsell() : this.handleCancelListing(listed_resell_seats.id)}
                    >
                        {buttonContent}
                    </Button>
                </Box>
                <Divider className={classes.DashedDivider} />
            </>
        );
    }

    renderSeatSelection() {
        const { classes } = this.props;
        const { eventBookingData : {attributes}, seatID } = this.state
        return (
            <Box>
                <Box className={classes.viewMainWrappers} style={webStyle.selectSeat} data-test-id="seats">
                    {attributes?.seats.map((seat: any) => (
                        <Box
                            data-test-id={`seatSelect`}
                            className={`${classes.viewBoxBgs}`}
                            onClick={() => this.handleSeatSelect(seat.id)}
                            key={seat.id}
                            style={seatID[seat.id] ? { background: "#FFB43A", color: "#121212" } : {}}
                        >
                            <Typography component={"span"}>
                                <SeatType>{seat.seat_number}</SeatType>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
    renderEventSelectedSeats() {
        const { classes } = this.props;
        const { attributes } = this.state.eventBookingData

        return (
            <>
                <Box display="flex" className={classes.SeatDisplays}>
                    {attributes?.listed_resell_seats?.all_listed_seats?.map((seats: any) => (
                        <Box key={seats.seat_id}>
                            <EventSeat key={seats.seat_number} seatLabel={seats.seat_number} />
                        </Box>
                    ))}
                </Box>
                <Box  display="flex" alignItems="center" justifyContent="end">
                    <Typography component={"span"} data-test-id="edit-seat" onClick={this.handleEditSeat} className={classes.EditEventButtonAction}>Edit</Typography>
                </Box>
            </>
        );
    }

    renderDifferenceAmount(isProfit: boolean, differencePercentage: string) {

        const { classes } = this.props;
        return (
            <Box className={classes.differenceWrapper}>
                {configJSON.labelDifferenceAmount}
                <Box className={classes.differenceIconWrapper}>
                    <img className={classes.arrowIcon} src={isProfit ? greenIcon : upGreyIcon} alt="icon" />
                    <img className={classes.arrowIcon} src={isProfit ? downGreyIcon : redIcon} alt="icon" />
                </Box>
                <span className={isProfit ? classes.profitPercentage : classes.lossPercentage}>
                    {parseFloat(differencePercentage).toFixed(2)}%
                </span>
            </Box>
        )
    }

    renderInvalidEvent (transaction_details: IEventransactionDetails) {

        const { classes } = this.props;
        const { attributes: bookingAttributes } = this.state.eventBookingData

        return (<>
            <Box display="flex" justifyContent="space-between" style={{ marginTop: 10 }}>
                {bookingAttributes.seat_allocation_type === "manual"
                    ? <>
                        <span className={classes.ScreenLabel}>
                            ---
                        </span>
                        <Typography style={{width:"85%"}} className={classes.Location}>{configJSON.first_come_heading}</Typography>
                    </> : <>
                        <Typography variant="h3"> Seat No.</Typography>
                        <Box display="flex" className={classes.SeatDisplay} >
                            {bookingAttributes.seats.map((seat) => <EventSeat key={seat.id} seatLabel={seat.seat_number} />)}
                        </Box>
                    </>}
            </Box>

            <Box className={classes.BookingStatusLabel}>
                <img src={InvalidStatusImage} width="261px" height="204px" />
            </Box>

            <Divider className={classes.Dividers} />
            <List className={classes.PaymentWrapper}>
                <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>
                        <span className={classes.SpecialFontSize}>
                            Total Amount
                        </span>
                        <ListItemIcon className={classes.EventMenuExpandIcon} data-test-id="totalCost-dropdown-invalid"
                            onClick={() => this.handleShowCost()}
                        >
                            {this.state.isCostOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItemText>
                    <span className={classes.SpecialFontSize}>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {(+bookingAttributes.total_amount).toFixed(2)}
                    </span>
                </ListItem>

                <Collapse data-test-id="cost-collapse" in={this.state.isCostOpen} timeout="auto" unmountOnExit>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}> Price per Ticket</ListItemText>
                        <span>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {(+bookingAttributes.sub_total / bookingAttributes.total_seats).toFixed(2)}
                        </span>
                    </ListItem>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>Sub - Total</ListItemText>
                        <span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{(+bookingAttributes.sub_total).toFixed(2)}</span>
                    </ListItem>

                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>
                            Convenience Fees
                            <ListItemIcon className={classes.EventMenuExpandIcon} data-test-id="cost-dropdown"
                                onClick={() => this.showCostData()}
                            >
                                {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemIcon>
                        </ListItemText>
                        <span>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {(+bookingAttributes.convenience_fees).toFixed(2)}
                        </span>
                    </ListItem>

                    <Collapse data-test-id="cost-collapse" in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>
                        <List className={classes.SubList} component="div" disablePadding>
                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>Base Amount</ListItemText>
                                <span className={classes.MenuListBody}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {(+bookingAttributes.base_amount).toFixed(2)}
                                </span>
                            </ListItem>

                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>
                                    {`CGST @ ${bookingAttributes.cgst_percent}%`}
                                </ListItemText>
                                <span className={classes.MenuListBody}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {(+bookingAttributes.cgst).toFixed(2)}
                                </span>
                            </ListItem>
                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>
                                    {`SGST @ ${bookingAttributes.sgst_percent}%`}
                                </ListItemText>
                                <span className={classes.MenuListBody}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {(+bookingAttributes.sgst).toFixed(2)}
                                </span>
                            </ListItem>
                        </List>
                    </Collapse>
                </Collapse>
            </List>
            
            {
                bookingAttributes.child_booking &&
                (
                    <>
                        <Typography className={classes.ScreenInfo} style={{ marginTop: 15 }} align="center">{configJSON.InvalidTicketMessage}</Typography>
                        <Typography align="center" data-test-id="eventNewTicket" onClick={() => this.handleClickEventNewTicket()} className={classes.EditButtonAction}>Link of new ticket.</Typography>
                    </>
                )
            }
            <Divider className={classes.DashedDivider} />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Tickets Sold</Typography>
                <Box display="flex" className={classes.SeatDisplay}>   
                   {bookingAttributes.listed_resell_seats?.all_listed_seats.map((seatNumber: {seat_number:string, id: string}) => <EventSeat key={seatNumber.id} seatLabel={seatNumber.seat_number} />)}
                </Box>
            </Box>
            <Divider className={classes.DashedDivider} />
            <Typography variant="h3"> Transaction Details</Typography>
            <Box display="flex" justifyContent="space-between" style={{ margin: "15px 0px" }}>
                <Typography variant="h3">Tickets Sold</Typography>
                <Box display="flex" className={classes.SeatDisplay}>
                  {bookingAttributes.listed_resell_seats?.all_listed_seats.map((seatNumber: {seat_number:string, id: string}) => <EventSeat key={seatNumber.id} seatLabel={seatNumber.seat_number} />)}
                </Box>
            </Box>

            <List className={classes.PaymentWrapper}>
                <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>
                        <span className={classes.SpecialFontSize}>
                            {configJSON.transactionAmountLabel}
                        </span>
                        <ListItemIcon className={classes.EventMenuExpandIcon}
                            data-test-id="transection-dropdown"
                            onClick={()=> this.showTransectionAMountData()}
                        >
                            {this.state.isTransferAmountData ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItemText>
                    <span className={classes.SpecialFontSize}>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {transaction_details.sold && (+transaction_details.sold).toFixed(2)}
                    </span>
                </ListItem>
            </List>

            <Collapse data-test-id="cost-collapse" in={this.state.isTransferAmountData} timeout="auto" unmountOnExit>
                <List className={classes.SubList} component="div" disablePadding>
                    <ListItem className={classes.viewList}>
                        <ListItemText className={classes.MenuListText}>{configJSON.soldLabel}</ListItemText>
                        <span className={classes.MenuListBody}>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {transaction_details.sold && 
                                (+transaction_details.sold).toFixed(2)}
                        </span>
                    </ListItem>

                    <ListItem className={classes.viewList}>
                        <ListItemText className={classes.MenuListText}>
                            {configJSON.purchasedLabel}
                        </ListItemText>
                        <span className={classes.MenuListBody}>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {transaction_details.purchased && 
                                (+transaction_details.purchased).toFixed(2)}
                        </span>
                    </ListItem>
                    <ListItem className={classes.viewList}>
                        <ListItemText className={classes.MenuListText}>
                            {this.renderDifferenceAmount(transaction_details.is_profit, transaction_details.difference_percent)}
                        </ListItemText>
                        <span className={classes.MenuListBody}>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {transaction_details.difference_amount && (parseFloat(transaction_details.difference_amount).toFixed(2))}
                        </span>
                    </ListItem>
                    <ListItem className={classes.viewList}>
                        <ListItemText className={classes.MenuListText}>
                            Service Fee @ {transaction_details.service_percent}% of <br /> Difference Amount
                        </ListItemText>
                        <span className={classes.MenuListBody}>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {transaction_details.service_charge && (+transaction_details.service_charge).toFixed(2)}
                        </span>
                    </ListItem>
                </List>
            </Collapse>

            <Box style={{marginTop:10}} className={classes.priceTicket} display="flex" justifyContent="space-between">
                <span className={classes.viewWrappe}>{configJSON.TransferToWalletText}</span>
                <span className={classes.SpecialFontSize}>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {transaction_details.amount_transferred && (+transaction_details.amount_transferred).toFixed(2)}
                    </span>
            </Box>

            <Box className={classes.IconWrappe} style={{ marginTop: 20 }}>
                <img className={classes.IconImage} src={phone} alt="phone Icon" />
                <Box className={classes.FullWidth}>
                    <Box className={classes.OfferAction} data-test-id="contact-us"
                    > Contact Us</Box>
                </Box>
            </Box>
        </>)
    }

    renderList () {
        const { classes } = this.props;
        const { attributes } = this.state.eventBookingData
        return (
            <List className={classes.PaymentWrapper}>
                <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>
                        <span className={classes.SpecialFontSize}>
                            Total Amount
                        </span>
                        <ListItemIcon className={classes.EventMenuExpandIcon} data-test-id="totalCost-dropdown"
                            onClick={() => this.handleShowCost()}
                        >
                            {this.state.isCostOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItemText>
                    <span className={classes.SpecialFontSize}>
                        <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                        {(+attributes.total_amount).toFixed(2)}
                    </span>
                </ListItem>
                <Collapse data-test-id="cost-collapse" in={this.state.isCostOpen} timeout="auto" unmountOnExit>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}> Price per Ticket</ListItemText>
                        <span>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {(+attributes.sub_total / attributes.total_seats).toFixed(2)}
                        </span>
                    </ListItem>
                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>Sub - Total</ListItemText>
                        <span><span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>{(+attributes.sub_total).toFixed(2)}</span>
                    </ListItem>

                    <ListItem className={classes.PaymentListItem}>
                        <ListItemText className={classes.MenuListText}>
                            Convenience Fees
                            <ListItemIcon className={classes.EventMenuExpandIcon} data-test-id="cost-dropdown"
                                onClick={() => this.showCostData()}
                            >
                                {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemIcon>
                        </ListItemText>
                        <span>
                            <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                            {(+attributes.convenience_fees).toFixed(2)}
                        </span>
                    </ListItem>

                    <Collapse data-test-id="cost-collapse" in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>
                        <List className={classes.SubList} component="div" disablePadding>
                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>Base Amount</ListItemText>
                                <span className={classes.MenuListBody}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {(+attributes.base_amount).toFixed(2)}
                                </span>
                            </ListItem>

                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>
                                    {`CGST @ ${attributes.cgst_percent}%`}
                                </ListItemText>
                                <span className={classes.MenuListBody}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {(+attributes.cgst).toFixed(2)}
                                </span>
                            </ListItem>
                            <ListItem className={classes.viewList}>
                                <ListItemText className={classes.MenuListText}>
                                    {`SGST @ ${attributes.sgst_percent}%`}
                                </ListItemText>
                                <span className={classes.MenuListBody}>
                                    <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                    {(+attributes.sgst).toFixed(2)}
                                </span>
                            </ListItem>
                        </List>
                    </Collapse>
                </Collapse>

                <ListItem className={classes.PaymentListItem}>
                    <ListItemText className={classes.MenuListText}>{configJSON.Discount}</ListItemText>
                    <span>
                        {`-`}
                        <span className={classes.RupeesSymbol}>&nbsp;{"\u20B9"}&nbsp;</span>
                        {`00.00`}
                    </span>
                </ListItem>

            </List>
        ) 
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { attributes } = this.state.eventBookingData
        const language = attributes?.language.map((item: any, index:number) => `${item.name} ${attributes?.language.length-1 != index ? ', ':""}`)
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    {
                        this.state.isLoading ? (
                            <Fade in={this.state.isLoading} unmountOnExit>
                                <Box display="flex" justifyContent="center">
                                    <CircularProgress className={classes.CircularProgress} />
                                </Box>
                            </Fade>
                        )
                            : (
                                <Box className={classes.viewWrapperBoxs} >
                                    <Container>
                                        <Box className={classes.viewWrapperHeads} data-test-id="viewHead">
                                            <img src={backarrowleft} alt="" style={webStyle.backErroeStyle}
                                                data-test-id="backs"
                                                onClick={this.handleGoBack} 
                                            />
                                            <Typography variant="h1" data-testid="head" >View Ticket</Typography>
                                        </Box>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                                <Box className={classes.viewBgs} >
                                                    <Box className={classes.viewMainWrappers}>
                                                        <Box className={classes.ImageWrapper} >
                                                            <img src={attributes?.poster_url[0]} alt="" className={classes.EventImage} />
                                                        </Box>
                                                        <Box className={classes.viewMovieWrappers}>
                                                            <Typography variant="h1" >{attributes?.event.name}</Typography>
                                                            <Typography component={'p'}> {language}</Typography>

                                                            <Typography variant="h3">
                                                                <span>
                                                                    {moment(attributes.schedule.schedule_date).format("ddd DD MMM,  YYYY")}
                                                                    <span style={{ color: '#ffb43a' }}> | </span>
                                                                    {attributes.schedule.start_time}
                                                                </span>
                                                            </Typography>
                                                            <Typography variant="h3">
                                                                {attributes.venue.name}
                                                            </Typography>
                                                            <Box className={classes.FindCinemaWrapper}
                                                            onClick={this.handleOpenNavigation}
                                                            >
                                                                <Icon>
                                                                    <img src={location} className={classes.IconWrappers} width="18px" height="18px" />
                                                                </Icon>
                                                                <span className={classes.EventLocation}>Find venue</span>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <IITOMovieEventTicketsInfo
                                                        applicationData = {attributes.booked_date} 
                                                        screeningData = {attributes?.schedule.schedule_date}
                                                        ticketRequested = {attributes.seats.length}
                                                        ticketSold = ""
                                                        ticketAlloted={attributes.seats.map((elm)=> elm.seat_number)}
                                                        ticketListedToSell = {attributes?.listed_resell_seats?.all_listed_seats?.map((seatNumber:{seat_number:string})=> seatNumber.seat_number)}
                                                        bookingType = {attributes?.booking_type}
                                                        isListToSell = {attributes.is_list_to_sell}
                                                        tier = {attributes.tier}
                                                        isInvalid = {attributes.is_invalid}
                                                    />
                                                </Box>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                                <Box style={{ minHeight: 'auto' }} className={classes.viewBgs}>
                                                    <Box className={classes.eventMainBoxWrapper}>
                                                        <Typography component={'p'} className={classes.viewTypos}>  Booking Summary</Typography>
                                                        
                                                        {this.state.is_invalid && this.state.eventBookingData.attributes.transaction_details ? this.renderInvalidEvent(this.state.eventBookingData.attributes.transaction_details)
                                                            : <>
                                                                <Box>
                                                                    <Box display="flex" justifyContent="center">
                                                                        <img className={classes.QRwrapper}
                                                                            src={attributes.qr_code}
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography className={classes.Booking} align="center">Booking Id</Typography>
                                                                        <Typography className={classes.BookingID} align="center">{attributes.uniq_booking_id}</Typography>
                                                                    </Box>
                                                                </Box>

                                                                <Box>

                                                                    <Box display="flex" justifyContent="space-between">
                                                                        {attributes.seat_allocation_type === "manual"
                                                                            ? <>
                                                                                <Typography style={{width:"85%"}} className={classes.Location}>{configJSON.first_come_heading}</Typography>
                                                                            </> : <>
                                                                                <Typography variant="h3"> Seat No.</Typography>
                                                                                <Box display="flex" className={classes.SeatDisplay} >
                                                                                    {attributes.seats.map((seat) => <EventSeat key={seat.id} seatLabel={seat.seat_number} />)}

                                                                                </Box>
                                                                            </>}
                                                                    </Box>
                                                                </Box>
                                                                <Divider className={classes.Dividers} />
                                                                <Box>
                                                                    {this.renderList()}
                                                                </Box>

                                                                {/* ONLY SHOW THIS SECTION IF THIS TICKET IS LISTED TO SELL */}
                                                                {attributes?.is_list_to_sell && (
                                                                    <>
                                                                        <Divider className={classes.DashedDivider} />
                                                                        <Box display="flex" justifyContent="space-between">
                                                                            <Typography variant="h3">Tickets Listed to Sell</Typography>
                                                                            <Box display="flex" className={classes.SeatDisplay}>
                                                                                {attributes?.listed_resell_seats?.all_listed_seats?.map((seats: any) => {
                                                                                    return (
                                                                                        <Box key={seats.seat_id}>
                                                                                            <EventSeat key={seats.seat_number} seatLabel={seats.seat_number} />
                                                                                        </Box>
                                                                                    );
                                                                                })}
                                                                            </Box>
                                                                        </Box>
                                                                        <Divider className={classes.DashedDivider} />
                                                                        <Box>
                                                                            <Box pb={2} display="flex" alignItems="center" justifyContent="end">
                                                                                <span data-test-id="edit-info"
                                                                                    onClick={() => { this.handleEditInfo() }}
                                                                                    className={classes.EditEventButtonAction}>Edit</span>
                                                                            </Box>
                                                                            <Box display="flex" justifyContent="space-between">
                                                                                <Typography component={"span"} className={classes.viewWrappe}>Tickets Listed to Sell</Typography>
                                                                                <Box display="flex" className={classes.SeatDisplay}>
                                                                                    {attributes?.listed_resell_seats.seats?.map((seats: any) => {
                                                                                        return (
                                                                                            <Box key={seats.seat_id}>
                                                                                                <EventSeat key={seats.seat_number} seatLabel={seats.seat_number} />
                                                                                            </Box>
                                                                                        );
                                                                                    })}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className={classes.eventPriceTicket}>
                                                                            <span className={classes.viewWrappe}>Price per Ticket</span>
                                                                            <Typography component={"span"}>
                                                                                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                                {(+attributes?.listed_resell_seats.price_per_seat).toFixed(2)}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={classes.eventPriceTicket}>
                                                                            <span className={classes.viewWrappe}>Total Amount</span>
                                                                            <Typography component={"span"}>
                                                                                <span className={classes.RupeesSymbol}>{"\u20B9"}&nbsp;</span>
                                                                                {attributes?.listed_resell_seats.total_amount}
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                )}

                                                                <Divider className={classes.Dividers} />
                                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                    <Box className={classes.IconWrappe}>
                                                                        <img className={classes.IconImage} src={car} alt="car Icon" />
                                                                        <Box className={classes.FullWidth}>
                                                                            <Box className={classes.OfferAction}> Book a Cab</Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className={classes.IconWrappe}>
                                                                        <img className={classes.IconImage} src={food} alt="food Icon" />
                                                                        <Box className={classes.FullWidth}>
                                                                            <Box className={classes.OfferAction}> Grab a Bite</Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Divider className={classes.Dividers} />
                                                                <Box className={classes.boxContainer}>
                                                                    <Box className={classes.IconWrappe}>
                                                                        <img className={classes.IconImage} src={phone} alt="phone Icon" />
                                                                        <Box className={classes.FullWidth}>
                                                                            <Box className={classes.OfferAction} data-test-id="contact-us"
                                                                                onClick={this.navigateToContactUs}
                                                                            > Contact Us</Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>

                                                                <Divider className={classes.DashedDivider} />

                                                                {this.buttonComponent()}

                                                                <Typography className={classes.eventTicketText} align="center">{configJSON.TicketMessage}</Typography>
                                                            </>
                                                        }
                                                        

                                                    </Box>

                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Container>
                                </Box>
                            )}
                    <FooterWithEvents />
                </Paper>

                <RFHDialog
                    data-test-id="edit-dialog"
                    open={this.state.isModalOpen}
                    maxWidth="xs"
                    handleClose = {this.handleCloseEditPopup}
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHButton data-test-id="payment-done-btn" fullWidth
                            onClick={() => this.updateListApiById()}
                            disabled ={!this.state.updatedPrice}
                        >
                            Confirm
                        </RFHButton>
                    )}
                    dialogContent={
                        <Box className={classes.isEditContent} >
                        <Typography variant="h1">Tickets Listed to Sell</Typography>
                        {this.state.isSeatEdit ? this.renderSeatSelection() : this.renderEventSelectedSeats()}
                        <Box className={classes.editPrice}>
                            <Typography variant="h3">Price Per Ticket</Typography>
                                <Box className={classes.textInput}>
                                    <TextField
                                        data-test-id="price"
                                        type="test"
                                        variant="outlined"
                                        placeholder="Enter Price"
                                        value={this.state.updatedPrice}
                                        className={classes.viewPrices}
                                        defaultValue={this.state.defaultPrice}
                                        onChange={(e: any) => this.handleChangePrice(e)}
                                        onBlur={() => this.isValid()}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <span className={classes.RupeesSymbols}>{"\u20B9"}</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box className={classes.priceError} data-test-id="priceError" >
                                        {this.state.inputErrorMessage}
                                    </Box>
                                </Box>
                        </Box>
                    </Box>
                    }
                />

                <RFHDialog
                    data-test-id="successful-payment-dialog"
                    open={this.state.dialogueMessage.length > 0}
                    handleClose = {this.handleOnClickDoneCancleListing}
                    maxWidth="sm"
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHButton data-test-id="cancel-done-btn" fullWidth
                            onClick={() => this.handleOnClickDoneCancleListing()}>
                            Done
                        </RFHButton>
                    )}
                    dialogContent={

                        <Box display="flex" justifyContent="center" flexDirection="column">
                            <Box p={0} mb={2} display="flex" justifyContent="center">
                                <img src={PaymentSuccessfull} alt="check" height="48px" width="48px" />
                            </Box>
                            <Box className={classes.viewSubeWrappers}>
                                {this.state.dialogueMessage}
                            </Box>
                        </Box>
                    
                    }
                />

                {/* MAP DIALOG FOR NAVIGATION */}
                <MapDialog
                    fullWidth
                    data-test-id="map-dialog"
                    open={this.state.openNavigationDialog}
                    handleClose={this.handleCloseDialog}
                    title={attributes?.event.name || ''}
                    destinationAddress={attributes?.venue.full_address || ''}
                />

                {this.state.isApprovedKyc &&
                    <KycDialogeBox
                        data-test-id="crossBtn"
                        navigation={this.props.navigation}
                        id="seat-selection-kyc"
                        handleOnClose={this.handleCloseKycPopup}
                    />
                }

            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    backErroeStyle : { 
        cursor: "pointer",
        width: '32px', 
        height: '32px', 
    },
    selectSeat:{ 
        cursor: 'pointer', 
        flexWrap:"wrap" as const, 
        justifyContent:"center" 
    }
}

const styles = {
    boxContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0px 25px 0px 16px",
        "@media (min-width: 960px) ": {
            margin: "0px 16px 0px 16px",
        },
    },
    ScreenLabel: {
        fontSize: "18px",
        color: "#fff",
        fontFamily: "Poppins",
        textTransform: "capitalize" as const,
        marginBottom: 4
    },
    DisplayFlex: {
        display: "flex",
        justifyContent: "space-between"
    },
    viewList: {
        '&.MuiListItem-gutters': {
            padding:"0px",
            paddingRight:'16px',
        }
    },
    RupeesSymbol: {
        fontFamily: 'Roboto'
    },
    EventImage: {
        width: '100%',
        height: '100%',
    },
    CircularProgress: {
        marginTop: "20px",
        color: "#ffb43a"
    },
    ImageWrapper: {
        width: "179px",
        height: "229px",
        border: "solid 1px #ffb53a",
        marginRight: "18px",
        "@media (max-width: 560px)": {
            width: "130px",
            height: "174px",
        },
        "@media (max-width: 660px)": {
            width: "130px",
            height: "174px",
        }
    },
    OfferAction: {
        color: "#ffb43a",
        cursor: 'pointer',
        fontSize: "18px",
    },
    IconWrappe: {
        display: "flex",
        gridGap: '8px',
        alignItems: "center",
        marginRight: "12px",
        justifyContent: "center",
    },
    IconImage: {
        height: "22px",
        width: "22px",
    },
    eventPriceTicket: {
        display: "flex",
        margin: "10px 0",
        justifyContent: "space-between",
    },
    EditEventButtonAction: {
        fontSize: "16px",
        cursor: "pointer",
        color: "#ffb53a",
    },
    eventTicketText: {
        color: "#b4b4b4",
    },
    eventMainBoxWrapper: {
        display: "flex",
        flexDirection: 'column' as const,
        position: "relative" as const,
        height: '95%'
    },
    EventMenuExpandIcon: {
        color: '#ffb43a',
        cursor: "pointer",
    },
    MenuListText: {
        fontSize: "16px",
        display: "inline-flex",
        alignItems: "center",
        "& span": {
            display: 'flex',
            gap: "8px",
            alignItems: 'center',
        },
    },
    PaymentListItem: {
        padding: "4px 0",
        maxHeight: "36px",
        "& span": {
            fontSize: "16px",
            lineHeight: 1,
        },
        "&:last-child": {
            paddingBottom: 0,
        },
        "&:first-child": {
            paddingTop: 0,
        },
    },
    MenuListBody: {
        marginRight: '-14px'
    },
    PaymentWrapper: {
        padding: 0,
    },
    SeatDisplay: {
        gap: "8px",
        maxWidth: "210px",
        flexFlow: "wrap",
    },
    ScreenInfo: {
        color: "#b4b4b4",
        fontSize: "16px",
        textTransform: 'capitalize' as const,
    },
    Dividers: {
        margin: "24px 0",
        backgroundColor: "#979797",
    },
    SeatDisplays: {
        gap: "8px",
        flexFlow: "wrap",
        justifyContent:"center"
    },
    BookingID: {
        color: "#fff",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500
    },
    Booking: {
        color: "#fff",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif"
    },
    QRwrapper: {
        marginTop: "18px",
        marginBottom: "16px",
        height: "80px",
        width: "80px",
        "@media (min-width: 600px)": {
            marginTop: "24px",
            height: "132px",
            width: "132px",
        }
    },
    TicketIcon: {
        width: "24px",
        height: "22px",
    },
    IconWrapper: {
        display: "flex",
        gridGap: '15px'
    },
    EventLocation: {
        color: "#ffb636",
        fontFamily: "Poppins, sans-serif",
        fontSize: "18px",
    },
    IconWrappers: {
        width: "18px",
        height: "22px"
    },
    FindCinemaWrapper: {
        display: "flex",
        alignItems: "center",
        maxWidth: "150px",
        gap: "4px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    Divider: {
        height: 0,
        boxSizing: 'border-box' as const,
        borderBottom: "dotted 1.5px #f0f6f7",
        margin: "20px 0",
        opacity: '0.6'
    },
    viewWrapperBoxs: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '18px',
        fontFamily: 'Poppins',
        color: '#fff',
        marginTop: '12px',
    },
    RupeesSymbols: {
        fontFamily: 'Roboto'
    },
    viewTilteHeadBoxs: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    customDividers: {
        backgroundColor: "#979797",
    },
    viewDialogBtns: {
        width: '400px',
        height: '56px',
        backgroundColor: '#ffb43a',
        borderRadius: '12px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        color: '#202020',
        fontSize: '18px',
        textTransform: 'none' as const,
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',
        }

    },
    viewDialogBoxs: {
        fontSize: '18px',
        fontFamily: 'Poppins',
        width: '392px',
        height: '25px',
        margin: '12px 0px 0px 0px',
        textAlign: 'center' as const,
        color: '#fff'
    },
    viewCloseIcons: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        height: '28px',
        width: '28px',
        cursor: 'pointer',
    },
    viewTitleWrappers: {
        width: '330px',
        height: '33px',
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center' as const,
        margin: '4px 1px 0px 0px',

    },
    viewSubeWrappers: {
        width: '330px',
        height: '33px',
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center' as const,
        margin: '12px 7px 10px 24px;',

    },
    mainWrapp: {
        padding: "26px 106px",
        maxWidth: '1920',
    },
    viewMainWrappers: {
        display: 'flex',
        gridGap: '5px'
    },
    viewWrapperHeads: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '15px',
        marginBottom: '22px'
    },
    viewWrappe: {
        fontSize: '18px',
    },
    viewsWrapperBoxs: {
        minHeight: '100vh',
    },
    viewMovieWrappers: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '8px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
    viewPrices: {
        width: '146px',
        height: '56px',
        border: 'solid 1px #fff',
        backgroundColor: '#404040',
        borderRadius: '12px',
        fontFamily: "Poppins",
    },
    viewTypo: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: "#b4b4b4",
        opacity: '0.6'
    },
    viewTypos: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '20px',
        color: "#b4b4b4"
    },
    viewSeatBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridGap: '30px'
    },
    viewTexts: {
        width: '122px',
        height: '33px',
        margin: '32px 582px 32px 24px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        letterSpacing: '0.12px',
        color: '#fff',
        textAlign: 'center' as const,
    },
    viewBoxBgs: {
        padding:"0 5px",
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap:"wrap" as const,
        fontSize: "14px",
        border: 'solid 1px #615e5e',
        fontWeight: 600,
        fontFamily: "Poppins",
        backgroundColor: '#404040',
        borderRadius: "4px",
        color: "#fff",
        "&.selected": {
            backgroundColor: '#404040',

        },
        ".selected": {
            color: "#121212",
            background: "#FFB43A",
        },
    },
    viewBgs: {
        padding: '26px',
        backgroundColor: 'rgba(64,64,64,0.4)',
        "@media (max-width: 600px)": {
            padding: "16px"
        }
    },
    SpecialFontSize: {
        fontSize: "18px !important",
    },
    viewBtns: {
        width: '100%',
        height: '56px',
        color: '#202020',
        fontSize: '18px',
        borderRadius: '12px',
        fontFamily: 'Poppins',
        backgroundColor: '#ffb43a',
        textTransform: 'none' as const,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#ffb43a',
            color: '#202020',
        }
    },
    DashedDivider: {
        margin: "27px 0",
        background: "repeating-linear-gradient(to right, transparent, transparent 3px, #b1b1b1 3px, #b1b1b1 8px)"
    },
    priceError: {
        color: ' red',
        fontSize: '14px',
        fontFamily: 'Poppins',
        marginTop: "4px"
    },
    isEditContent: {
        gridGap: '10px',
        display: "flex",
        alignItems: 'center',
        justifyContent: "space-between",
        flexDirection: "column" as const,
    },
    editPrice: {
        gridGap: '12px',
        display: 'flex',
        margin: '7px auto',
        alignItems: 'center',
        flexDirection: 'column' as const,
    },
    textInput: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column' as const,
    },
    BookingStatusLabel: {
        top: "35px",
        left: "50%",
        transform: "translateX(-50%)",
        position: 'absolute' as const,
    },
    EditButtonAction: {
        fontSize: "16px",
        color: "#ffb53a",
        cursor: "pointer",
    },
    differenceWrapper: {
        fontFamily: "Poppins, sans-serif",
        color: "#FFF",
        display: "flex",
        alignItems: "center",
    },
    differenceIconWrapper: {
        display: "flex",
        flexDirection: "column" as const,
        gap: "3px",
        margin: "0 4px 0 6px"
    },
    arrowIcon: {
        height: "7px",
        width: "10px"
    },
    lossPercentage: {
        color: "#ff3b30"
    },
    profitPercentage: {
        color: "#5ed808"
    }
}


export default (withStyles(styles)(EventViewTicket))
export { EventViewTicket }
// Customizable Area End
