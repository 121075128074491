import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastPartOfURL } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { IBookingCancelDetail, IBookingResponse } from "../../../components/src/interfaces.web";

type FoodCostDetails = {
    totalFoodAmount: number
    Food: {
        menuId: number
        label: string
        price: number
        quantity: number
    }[],
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    isCostOpen: boolean;
    refundType: any;
    selectedValue: any;
    isModalOpen: any;
    isPolicy: boolean;
    cancelLimit: boolean;
    isCancelled: boolean;
    isConvenienceFeeOpen: boolean;
    bookingData: IBookingCancelDetail;
    openNavigationDialog:boolean;
    cancelBookingPolicy:any;
    popupDescription:string;
    loading:boolean;
    foodCostCalculation: FoodCostDetails,
    isFoodOpen: boolean,
    isTotalAmount: boolean,
    openBackdropLoadingOpen: boolean,
    openCancelBookingError: boolean,
    cancelBookingErrorMessage: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CancelBookingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
     getCancelDataInfoApi = ""
     getCancelBookingPolicyApi = ""
     cancelBookingApi=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isCostOpen: false,
            refundType: [{ value: 'Wallet', label: `Wallet ` },
            { value: 'Other', label: 'Original source of Payment' }],
            selectedValue: '',
            isModalOpen: false,
            isPolicy: false,
            cancelLimit: false,
            isCancelled: false,
            isConvenienceFeeOpen: false,
            bookingData: {} as IBookingCancelDetail,
            openNavigationDialog:false,
            cancelBookingPolicy:"",
            popupDescription:"",
            loading:true,
            isFoodOpen: false,
            foodCostCalculation: {} as FoodCostDetails,
            isTotalAmount:false,
            openBackdropLoadingOpen: false,
            openCancelBookingError: false,
            cancelBookingErrorMessage: ""
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
              const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

              if(responseJson) {
                switch (apiRequestCallId) {
                  case this.getCancelDataInfoApi:

                        if (responseJson.message) {
                            this.setState({
                                loading: false,
                                openCancelBookingError: true,
                                cancelBookingErrorMessage: responseJson.message,
                            })
                            break;
                        }
                        const food = this.getFoodCostDetails((responseJson.data) as IBookingResponse);

                    this.setState({ 
                        bookingData:responseJson.data, 
                        isCancelled: responseJson.data.attributes.status === "cancelled" ? true : false,
                        loading: false,
                        foodCostCalculation: food,
                    })
                    break;
                 case this.getCancelBookingPolicyApi:
                    this.setState({cancelBookingPolicy:responseJson.data.attributes.description})
                    break;
                 case this.cancelBookingApi:
                    this.handleCancelBookingResponse(responseJson);
                    break;
                }
              }    
            }
        // Customizable Area End
    }

    // Customizable Area Start

    // for go previous page from the page
    handleBack = () => {
        this.props.navigation.goBack();
    }
    showCost() {
        this.setState({ isCostOpen: !this.state.isCostOpen })
    }
    handleChange = (event: { target: { value: any; }; }) => {
        this.setState({ selectedValue: event.target.value });
    };

    // on click of cancel booking button
    handleOnClickCancelBooking = async() => {
        if (this.state.selectedValue === "") {
            this.setState({ isModalOpen: true, isPolicy: false, popupDescription:"Select Refund Method!" })
        }else{
            this.setState({ openBackdropLoadingOpen: true })
            const bookingId = getLastPartOfURL();
            this.cancelBookingApi = callApi({
                contentType: configJSON.apiContentType,
                method: configJSON.deleteOrderItemMethod,
                endPoint: `${configJSON.cancelBookingEndPoint.replace(":id", bookingId)}${this.state.selectedValue==="Wallet"?"?refunded_to=wallet":""}`,
                headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
            }, runEngine)
        }
    }
    handleCancelPolicyPopup = () => {
        this.setState({ isModalOpen: true, isPolicy: true })
    }
    showCostData=()=>{
        this.setState({ isConvenienceFeeOpen: !this.state.isConvenienceFeeOpen })
    }

    // handle Find Cinema button click 
    handleOpenNavigation = () => {
        this.setState({ openNavigationDialog: true })
    }

    handleDialogClose = () => {
        this.setState({ openNavigationDialog: false })
    }

    handleOnClosePopup = () => {
        this.setState({isModalOpen: false })
    }

    // to get the policy for cancel booking 
    getCancelBookingPolicy = async() =>{
        this.getCancelBookingPolicyApi = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.GETMethod,
            endPoint: configJSON.cancelBookingPolicyEndPoint,
            headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
        }, runEngine)
    }

    // to get the ticket details by id which we want to cancel
    getTicketData = async() =>{
        const bookingId = getLastPartOfURL()
        this.getCancelDataInfoApi = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.GETMethod,
            endPoint: `${configJSON.getCancelBookingInfoApiEndPoint.replace(":id", bookingId)}`,
            headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
        }, runEngine)
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getTicketData();
        this.getCancelBookingPolicy()
    }

    handleCancelBookingResponse = (responseJson:any) => {
        this.setState({ 
            isModalOpen: true,
            openBackdropLoadingOpen: false,
            isPolicy: false, 
            isCancelled: responseJson.data ? true: false,
            cancelLimit: responseJson.data ? false :true,
            popupDescription:responseJson.data?"Cancellation Sucessfull!":responseJson.message 
        })
    }

    getFoodCostDetails = (resJson: IBookingResponse): FoodCostDetails => {
        const { attributes: { menu }} = resJson
        let total_amount = 0
        let menuItems: { quantity: number, menuId: number, label: string, price: number }[] = []
        if (menu) {
            menu.forEach(({ quantity, quantity_price, booking_menu_id, data: { attributes: { name } } }) => {
                total_amount += quantity_price;
                menuItems.push({ quantity, price: quantity_price, label: name, menuId: booking_menu_id, })
            })
        }
        return { totalFoodAmount: total_amount, Food: menu ? menuItems : [] }
    }

    showFoodCostDetails = () => this.setState({ isFoodOpen: !this.state.isFoodOpen });

    showTotalAmount = () => this.setState({isTotalAmount : !this.state.isTotalAmount})
    // Customizable Area End
}
