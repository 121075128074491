import moment from 'moment';
import {signInWithPopup} from "firebase/auth"

// Format Duration of movie to "2h 30m" format
export const getMovieDuration = (durationInMinutes: number) => {
    const duration = moment.duration(durationInMinutes, 'minutes');
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${hours}h ${minutes}m`;
};
// to calculate the time in AM and PM
export const  calculateAMorPM = (time: any) => {
    const [hour] = time.split(':');
    return parseInt(hour, 10) >= 12 ? 'PM' : 'AM';
  };


export function convertTo12HourFormat(time24: string): string {
    const splittedArray = time24.split(":")

    const hours = Number(splittedArray[0])
    const minutes = Number(splittedArray[1])

    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        throw new Error("Invalid time format");
    }

    let period = "AM";
    let twelveHourFormat = hours;

    if (hours === 0) {
        twelveHourFormat = 12;
    } else if (hours === 12) {
        period = "PM";
    } else if (hours > 12) {
        twelveHourFormat = hours - 12;
        period = "PM";
    }

    const formattedTime = `${twelveHourFormat.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${period}`;
    return formattedTime;
}

// convert string/number to 2 digit with leading 0
export function convertToPad(number: number, digitsToShow = 2): string {
    return `${number < 10 ? number.toString().padStart(digitsToShow, "0") : number}`
}

// get Trailers text based on number of trailers
export function getTrailersText(list: string[] = []): string {
    return list.length > 1 ? 'Trailers' : 'Trailer'
}

export function handleFirebase(auth: any, customProvider: any, googleSignupApi:any ){

    signInWithPopup(auth, customProvider).then((result) => {      
        googleSignupApi(result.user.email, result.user.uid)
      })

}
export function getHostName(): string {
    return window.location.origin
}

export function getLastPartOfURL() {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
};
export function  countReviewDays(date: Date | string): string {
    const duration = moment.duration(moment().diff(moment(date)))
    // Get the values in different units
    const minutes = duration.asMinutes();
    const hours = duration.asHours();
    const days = duration.asDays();
    const weeks = duration.asWeeks();
    // Determine the appropriate representation
    let result;
    if (weeks >= 1) {
        result = weeks > 52 ? '1 y' : `${Math.floor(weeks)} w`;
    } else if (days >= 1) {
        result = days > 6 ? '1 w' : `${Math.floor(days)} d`;
    } else if (hours >= 1) {
        result = `${Math.floor(hours)}h`;
    } else {
        result = `${Math.floor(minutes)}m`;
    }
    return result;
}

export const goToPrevPage = () => window.history.back()

export const getDateAndTimeFromString = (showDate: string): [string, string] => {

    const convertedDate = showDate.split(" | ");
    return [convertedDate[0], convertedDate[1]]
}

export const convertDateForTicket = (date: string) => moment(new Date(date)).format("ddd, DD MMM YYYY");

export const formatPhoneNumber = (phoneNumber:string) => {
    // Remove leading "+" if present
    phoneNumber = phoneNumber.replace(/^\+/, '');
    
    // Insert "-" after country code
    phoneNumber = phoneNumber.replace(/^(\d{2})(\d{2})/, '+$1-$2');
  
    return phoneNumber;
  }

export const redirectTo = (path: string) => {
    window.location.href = path;
}

export const getFormattedDate = (date: string | Date, stringFormat: string) => {
    return moment(date).format(stringFormat);
}

export function isEmpty(value: unknown): boolean {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
}

export function calculateRemainingTime(date: string) {
    const targetDate = new Date(date);
    let seconds = Math.floor((targetDate.getTime() - new Date().getTime()) / 1000);
    
    let interval = seconds / 31536000;
    if (interval >= 1) {
        return generatePluralString(interval, "Year", "Years");
    }
    
    interval = seconds / 2592000;
    if (interval >= 1) {
        return generatePluralString(interval, "Month", "Months");
    }
    
    interval = seconds / 86400;
    if (interval >= 1) {
        return generatePluralString(interval, "Day", "Days");
    }
    
    interval = seconds / 3600;
    if (interval >= 1) {
        return generatePluralString(interval, "Hour", "Hours");
    }

    interval = seconds / 60;
    if (interval >= 1) {
        return generatePluralString(interval, "Minute", "Minutes");
    }

    if (seconds > 0) {
        return generatePluralString(seconds, "Second", "Seconds");
    }

    return "Happening Now";
}

export function generatePluralString(numberToCompare: number, singularString: string, pluralString: string) {
    const roundedNumber = Math.floor(numberToCompare);
    const suffix = roundedNumber === 1 ? singularString : pluralString;
    return `${roundedNumber} ${suffix}`;
}

export function isDateTimeInPast(dateString: string, timeString: string) {
    
    const dateTimeString = `${dateString} ${timeString}`;

    const givenDateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm');
    const currentDateTime = moment();

    return givenDateTime.isBefore(currentDateTime);
}