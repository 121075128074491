Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.getAuctionApiEndPoint = "/bx_block_auctionbidding/auction_biddings";
exports.getAuctionApiMethod = "GET";
exports.getAuctionApiContentType = "application/json";

exports.getAuctionDetailApiEndPoint = "/bx_block_auctionbidding/auction_biddings";
exports.getAuctionDetailApiMethod = "GET";
exports.getAuctionDetailApiContentType = "application/json";

exports.getBiddingApiEndPoint = "/bx_block_auctionbidding/bids_listing";
exports.getBiddingApiMethod = "GET";
exports.getBiddingApiContentType = "application/json";

exports.placeBidApiEndPoint = "/bx_block_auctionbidding/place_bid";
exports.placeBidApiMethod = "POST";
exports.placeBidApiContentType = "application/json";

exports.loginApiEndPoint = "/login/login";
exports.loginApiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.AuctionAcceptedNotificationApiEndPoint ="/bx_block_push_notifications/push_notifications";
exports.AuctionAcceptedNotificationApiMethod = "GET";
exports.AuctionAcceptedNotificationApiContentType = "application/json";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AuctionBidding";
exports.labelBodyText = "AuctionBidding Body";

exports.btnExampleTitle = "CLICK ME";


exports.ContentType = "application/json";
exports.GuestToken = "guest_user";
exports.GetMethod = "GET";
exports.PostMethod = "POST";

exports.GetMovieITOApiCallId = "bx_block_categories/movies/{MOVIE_ID}";
exports.GetMovieCastApiCallId = "bx_block_categories/movies/{MOVIE_ID}/movie_cast";
exports.GetMovieCrewApiCallId = "bx_block_categories/movies/{MOVIE_ID}/movie_crew";
exports.timeSlotApiEndPoint = "bx_block_categories/screenings";
exports.submitFeedbackApiEndPoint = "bx_block_categories/itos/cast_and_crew_feedback";
exports.termsAndConditionApiEndPoint = "bx_block_categories/itos/ito_terms_and_condition";
exports.SeatGridApiEndPoint = "bx_block_categories/screenings/{ID}"
exports.ItoBookingApiEndPoint = "bx_block_categories/bookings/ito_booking";


exports.UA = "UA";
exports.Duration="Duration: ";
exports.ReleaseDate = "Release Date: ";
exports.Days = "Days";
exports.Hours = "Hours";
exports.Minutes = "Minutes";
exports.Seconds = "Seconds";
exports.ApplyForITO = "Apply for ITO";
exports.MovieDescription = "Movie Description";
exports.Cast = "Cast";
exports.Crew = "Crew";
exports.FeedbackSuccessful = "Feedback Submitted Successfully!";
exports.Done = "Done";
exports.Continue = "Continue";
exports.Cancel = "Cancel";
exports.Accept = "Accept";
exports.Submit = "Submit";
exports.SelectLanguage = "Select Language";
exports.SelectLanguageAndFormat = "Select Language & Format";
exports.CastAndCrew= "Connect with the Cast and Crew.";
exports.CastAndCrewQuestion = "I’m applying for ITO because I’m a huge fan of the";
exports.EnterMessage = "Enter Your Message";
exports.TextAreaMessage = "Hi Madhavan. I am so excited for your films release. I am a huge fan and watch all your films. You're the best!!!";
exports.UA = "UA";
exports.TermsAndCondition = "Terms & Conditions";
exports.BookTickets = "Book Tickets";
exports.ScreenMessage = "All eyes this way please!";
exports.DialogTitle = "Select Number of Seats";
exports.Proceed = "Proceed";
exports.Tickets = "Tickets";
// Customizable Area End