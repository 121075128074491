import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { EventListenerKeys, LocalStorageKeys, MenuFilterList } from "../../../components/src/enums.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { IBookingData, IBookingResponse } from "../../../components/src/interfaces.web";

// Customizable Area Start
interface IUpdateBookingPayload {
  booking: {
    booking_menus_attributes: Array<{
      menu_id: number
      quantity: number
      id?: number
    }>
  }
}

interface IDestroyPayload {
  booking: {
    booking_menus_attributes: Array<{
      _destroy: boolean
      id?: number
    }>
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface Addons {
  id: number,
  name: string,
  menu_id: number,
  price: number
}

export interface MenuItem {
  id: number,
  type: string,
  attributes: {
    name: string,
    total_price: string,
    addons: Addons[],
    food_type: MenuFilterList,
    menu_image: string,
    user_cart: {
      total_items: number,
      quantity_price: number,
    },
  },
  booking_menu_id?: number
}

interface S {
  // Customizable Area Start
  menuList: MenuItem[]
  totalItemsAddedInCart: number
  filteredMenuList: MenuItem[]
  activeFilter: MenuFilterList
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FoodAndBeveragesController extends BlockComponent<
  Props,
  S,
  SS
> {

  menuListAPICallId: string = '';
  addItemApiCallId: string = '';
  bookingDataApiId: string = '';

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      menuList : [],
      totalItemsAddedInCart: 0,
      activeFilter: MenuFilterList.All,
      filteredMenuList: [],
      isLoading: true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.menuListAPICallId) {
        this.handleMenuListApiCall(responseJson)
      }
      else if(apiRequestCallId === this.addItemApiCallId) {
        this.handleChangeQuantity(responseJson.data)
      } else if(apiRequestCallId === this.bookingDataApiId) {
        if(responseJson.data?.attributes.menu) this.handleChangeQuantity(responseJson.data)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeQuantity = (data: IBookingResponse): void => {

    const { attributes: { menu: results } } = data

    this.setState(prevState => ({
      totalItemsAddedInCart: results ? results.length : 0, 
      menuList: prevState.menuList.map(item => {

        const matchingResult = results?.find(result => +result.data.id === +item.id)

        if (matchingResult) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              user_cart: {
                ...item.attributes.user_cart,
                total_items: matchingResult.quantity,
              }
            },
            booking_menu_id: matchingResult.booking_menu_id
          }
        }

        // remove bookable id as this item is not included in the cart
        const { booking_menu_id, ...otherItemProperties } = item

        return {
          ...otherItemProperties,
          attributes: {
            ...item.attributes,
            user_cart: {
              ...item.attributes.user_cart,
              total_items: 0
            }, otherItemProperties
          }
        }
      })
    }), () => {
      this.onFilterChange(this.state.activeFilter)
    })
  }


  onFilterChange = (filter: MenuFilterList) => {
    let filteredList = []
    if (filter === MenuFilterList.All)
      filteredList = this.state.menuList
    else
      filteredList = this.state.menuList.filter(item => item.attributes.food_type === filter)
    this.setState({ filteredMenuList: filteredList, activeFilter: filter })
  }

  onAddItem = async (menuItemId: number, quantity: number, booking_menu_id?: number) => {

    let payload: IUpdateBookingPayload | IDestroyPayload;

    if(quantity) {
      payload = {
        booking: {
          booking_menus_attributes: [
            {
              menu_id: +menuItemId,
              quantity,
              ...(booking_menu_id ? { id: booking_menu_id } : null)
            }
          ]
        }
      }
    } else {
      payload = {
        booking: {
          booking_menus_attributes: [
            {
              id: booking_menu_id ? +booking_menu_id : 0,
              _destroy: true
            }
          ]
        }
      }
    }

    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
        
    this.addItemApiCallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.putAPIMethod,
      endPoint: `${configJSON.updateBookingApiEndPoint}`.replace('{BOOKING_ID}', `${bookingId}`),
      headers: { "token": authToken },
      body: payload
    }, runEngine)

  }


  handleMenuListApiCall = (results: any) => {
    this.setState({
      menuList: results.data,
      isLoading: false,
    })
    this.onFilterChange(MenuFilterList.All)
  }

  navigateToPaymentScreen = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage),"PaymentSelection");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }

  getMenuList = () => {
    this.menuListAPICallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `${configJSON.menuAPIEndPoint}`,
      headers: { "token": localStorage.getItem("login") },
    }, runEngine)
  }

  handlePreviewBookingEvent = (event: any) => this.handleChangeQuantity(event?.detail as IBookingResponse)

  async getBookingData() {

    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string 

    this.bookingDataApiId = callApi({
      contentType: configJSON.content,
      method: configJSON.apiGetMethodType,
      endPoint: `${configJSON.getBookingDataApiEndPoint}`.replace('{BOOKING_ID}', `${bookingId}`),
      headers: { "token": loginToken }
    }, runEngine)

  }

  async componentDidMount() {
    this.getMenuList();
    this.getBookingData()
    

    window.addEventListener(EventListenerKeys.PreviewBookingUpdateMenu, this.handlePreviewBookingEvent)
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener(EventListenerKeys.PreviewBookingUpdateMenu, this.handlePreviewBookingEvent)
  }

  // Customizable Area End
}
