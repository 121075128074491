// Customizable Area Start
import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { MenuFilterList } from "./enums.web";

const useStyles = makeStyles((theme) => ({
    FilterWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: "16px",
        alignItems: 'center',
        whiteSpace: 'nowrap',
        margin: "32px 0 24px 0",
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        "@media (max-width: 600px)": {
            gap: "16px",
            margin: "16px 0 16px !important",
        }
    },
    CustomButton: {
        backgroundColor: "#2f2617",
        border: "solid 1px #b4b4b4",
        fontSize: "14px",
        padding: "8px 16px",
        borderRadius: "12px",
        "&.MuiButton-root": {
            textTransform: 'none', 
            minWidth: 'auto',
        },
        "&.selected": {
            borderColor: '#ffb43a',
            backgroundColor: "#ffb43a",
            color: '#000',
        },
        "&:hover": {
            borderColor: '#ffb43a',
            backgroundColor: "#ffb43a",
            color: '#000',
        },
        "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "6px 16px",
        }
    }
}))

interface FilterListProps {
    selectedFilter: MenuFilterList
    handleOnClick: (filter: MenuFilterList) => void
}

const FilterListArray = [
    { id: 1, label: 'All', value: MenuFilterList.All },
    { id: 2, label: 'Combos', value: MenuFilterList.Combos },
    { id: 3, label: 'Veg', value: MenuFilterList.Veg },
    { id: 4, label: 'Non-veg', value: MenuFilterList.NonVeg },
    { id: 5, label: 'Best Sellers', value: MenuFilterList.BestSellers },
]

const FilterList: React.FC<FilterListProps> = ({ selectedFilter, handleOnClick }) => {

    const classes = useStyles()

    return (
        <Box className={classes.FilterWrapper}>
            {FilterListArray.map((item) => (
                <Button 
                    data-test-id="filter-button"
                    key={item.id} 
                    variant="outlined" 
                    className={`${classes.CustomButton} ${item.value === selectedFilter ? 'selected' : ''}`} 
                    onClick={() => handleOnClick(item.value)}
                >
                        {item.label}
                </Button>
            ))}
        </Box>
    )
}

export default FilterList;
// Customizable Area End
