// Customizable Area Start
import React from "react";
import { makeStyles, Theme, Button } from "@material-ui/core";
import Slider, { Settings } from "react-slick";
import moment from "moment";

interface DateCarouselProps {
  noOfDaysToDisplay: number,
  onChange: (date: Date) => void,
  selectedDate: Date,
  startDate: Date,
}

const useStyles = makeStyles((theme: Theme) => ({
  dateBox: {
    border: "none",
    fontSize: "1rem",
  },
  SliderWrapper: {
    width: "364px",
    [theme.breakpoints.down(600)]: {
      width: "296px",
    },
    [theme.breakpoints.down(400)]: {
      width: "228px",
    },
    "& .slick-list": {
      "& .slick-track": {
        "& .slick-slide": {
          width: "60px",
          height: "84px",
          margin: "0 4px",
        },
      },
    },
    "& .slick-next": {
      right: "-15px",
      "&::before": {
        fontSize: "1.5rem",
        color: "#ffb43a",
        opacity: "1",
      },
    },
    "& .slick-prev": {
      "&::before": {
        fontSize: "1.5rem",
        color: "#ffb43a",
        opacity: "1",
      },
    },
    "& .slick-next.slick-disabled": {
      "&::before": {
        opacity: "0.25",
      },
    },
    "& .slick-prev.slick-disabled": {
      "&::before": {
        opacity: "0.25",
      },
    },
  },
  dateBtn: {
    minWidth: "30px",
    width: "60px",
    borderRadius: "8px",
    maxHeight: "84px",
    padding: "4px 14px",
    boxShadow: "none",
    backgroundColor: "#616161",
    color: "#FFF",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      textTransform: "capitalize",
      fontFamily: "Poppins",
      "& .date-span": {
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "32px",
        backgroundColor: "transparent",
      },
    },
    "&:hover": {
      backgroundColor: "#ffb43a",
      color: "#000",
      boxShadow: "none",
      "& .MuiButton-label": {
        "& .date-span": {
          backgroundColor: "#fff",
        },
      },
    },
  },
  selectedDateBtn: {
    backgroundColor: "#ffb43a",
    color: "#000",
    "& .MuiButton-label": {
      "& .date-span": {
        backgroundColor: "#fff",
      },
    },
  },
}));

// Generate next N days array based on passed date
function getNextNDates(noOfDateToDisplay: number, startDate = new Date()): Date[] {
  const nextDates: Date[] = [];
  let currentDate = moment(startDate);

  for (let i = 0; i < noOfDateToDisplay; i++) {
    nextDates.push(currentDate.toDate());
    currentDate = currentDate.add(1, 'day');
  }

  return nextDates;
}

const DateCarousel: React.FC<DateCarouselProps> = (props) => {

  const classes = useStyles(props);
  const { startDate, noOfDaysToDisplay, selectedDate, onChange } = props;
  const dates: Date[] = getNextNDates(noOfDaysToDisplay, startDate);

  const sliderSettings: Settings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 4,
    draggable: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleDateClick = (date: Date) => {
    onChange(date)
  };

  return (
    <div>
      <Slider {...sliderSettings} className={classes.SliderWrapper}>
        {dates.map((date, index) => (
          <div
            key={`item_${index}`}
            className={classes.dateBox}
          >
            <Button
              variant="contained"
              onClick={() => handleDateClick(date)}
              className={`${classes.dateBtn} ${moment(date).isSame(selectedDate) ? classes.selectedDateBtn : ""
                }`}
            >
              <span>{moment(date).format("MMM")}</span>
              <span className="date-span">{moment(date).format("DD")}</span>
              <span>{moment(date).format("ddd")}</span>
            </Button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DateCarousel;
// Customizable Area End
