import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Fade,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { backarrowleft, close } from "./assets";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import moment from "moment";
import { getMovieDuration } from "../../../components/src/utilities";
import { styles } from "./ListToSell.web";
import { EventSeat } from "../../../components/src/BookingSuccessfulTicket.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import EventListToSellController, {
    Props,
} from "./EventListToSellController";

class EventListToSell extends EventListToSellController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { attributes } = this.state?.eventBookingData
        const listToSellAttributes = this.state.eventListToSellResponse.attributes

        const eventLanguage = attributes?.language.map((item: any, ind:number) => `${item.name} ${attributes?.language.length-1 != ind ? ', ':""}`);
       
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper elevation={0}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    {
                        this.state.isLoading ? (
                            <Fade in={this.state.isLoading} unmountOnExit>
                                <Box display="flex" justifyContent="center">
                                    <CircularProgress className={classes.CircularProgress} />
                                </Box>
                            </Fade>
                        )
                            : (
                    <Box className={classes.listWrapperBoxs} >
                        <Container >
                            <Box className={classes.listWrapperHeads} data-test-id="listHead" style={{marginTop:20}}>
                                <img src={backarrowleft} alt="" style={webStyle.backArrow}
                                    data-test-id="back" 
                                    onClick={this.handleBack} 
                                />
                                <Typography component={'p'} data-testid="head" className={classes.listWrapperTypos} >List To Sell</Typography>
                            </Box>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={7} lg={7}>
                                    <Box className={classes.listBgs} >
                                        <Box className={classes.listMainWrappers}>
                                            <Box className={classes.ImageWrapper} >
                                                <img src={attributes.poster_url[0]} alt="" className={classes.MovieImage} />
                                            </Box>
                                            <Box className={classes.listMovieWrappers}>
                                                <Typography component={'p'} className={classes.listWrapperTypos} >{attributes.event.name}</Typography>
                                                <Box className={classes.listBodyTypogrphys}>
                                                    <span>{moment(attributes.schedule.schedule_date).format("ddd DD MMM,  YYYY")}
                                                        <span style={{ color: '#ffb43a' }}> |</span> </span>
                                                    <span> {attributes.schedule.start_time}</span>
                                                </Box>
                                                <Typography component={'p'}>{attributes.venue.name}</Typography>
                                                <Typography component={'p'}> {eventLanguage}</Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>


                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <Box className={classes.listBgs} style={{ minHeight: '680px' }}>
                                        <Box>
                                            <Typography 
                                                className={classes.listTypos} 
                                                component={'p'}
                                            > List to Sell</Typography>
                                            <Box minHeight="514px">
                                                <Typography 
                                                    className={classes.lisFontSize}
                                                    component={'p'}
                                                    style={{ margin: '24px 0px' }}
                                                > 
                                                    Choose Tickets for Listing to Sell
                                                </Typography>
                                                <Box className={classes.listSeatBoxs}>
                                                    <Box 
                                                        className={classes.listMainWrappers} 
                                                        data-test-id="seats" 
                                                        style={webStyle.seatSelectWrapper} 
                                                    >
                                                        {attributes.seats.map((seat: any) => (
                                                            <Box style={webStyle.listBoxBgs} key={seat.id} data-test-id={`seatSelect`} 
                                                            onClick={() => this.handleSelectTicket(seat.id)} 
                                                             >
                                                                <Typography component={"span"}>{seat.seat_number}</Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    {this.state.seatsSelected === true ?
                                                        <Box>
                                                            <Typography component={'p'}
                                                                style={{ margin: '24px 0px 24px 0px' }}
                                                                className={classes.lisFontSize}>
                                                                Listed Tickets: {attributes.total_seats.toLocaleString('en-US', { minimumIntegerDigits: 2 })}

                                                            </Typography>
                                                            <Box className={classes.listMainWrappers} flexWrap="wrap">
                                                                {this.state.seatID.map((seat: any) => (
                                                                    <Box key={seat.id}>
                                                                        <EventSeat seatLabel={seat.seat_number} />
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                            <Box>
                                                                <Typography component={'p'}
                                                                    style={{ margin: '24px 0px 24px 0px' }}
                                                                    className={classes.lisFontSize}>
                                                                    Enter Price per Ticket

                                                                </Typography>
                                                                <Box>
                                                                    <TextField
                                                                        data-test-id="price"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        placeholder="Enter Price"
                                                                        value={this.state.eventPrice}
                                                                        inputProps={{ min: "0" }} className={classes.listPrices}
                                                                        onBlur={() => this.isPriceValid()}
                                                                        onChange={(e: any) => this.handleChange(e)}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    {this.state.eventPrice && <span className={classes.RupeesSymbols}>{"\u20B9"}</span>}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Box className={classes.priceError}>
                                                                        {this.state.priceError}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        :

                                                        <Box>
                                                            <Typography component={'p'}
                                                                style={{ margin: '24px 0px 24px 0px' }}
                                                                className={classes.lisFontSize}>
                                                                Selected Seats
                                                            </Typography>
                                                            <Box className={classes.listMainWrappers}>
                                                                {attributes?.seats.map((seat: any) => (
                                                                    <Box className={classes.listBoxBgs} key={seat.id}>
                                                                        <span></span>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Button className={classes.listBtns} disabled={this.state.eventPrice <=0} data-test-id={'list'} 
                                                onClick={this.handleOnClickListToSell}
                                                >List to Sell</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Container>
                    </Box>
                    )}
                    <FooterWithEvents />
                </Paper>
                <Dialog 
                    open={this.state.isModalOpen}
                    PaperProps={{
                        style: {
                            backgroundColor: "#404040",
                            color: "#fff",
                            padding: '28px',
                            position: "relative"
                        }
                    }}
                >
                    <Box className={classes.closeIconWrapper}>
                        <img src={close} alt="closebutton"
                            color="primary"
                            data-test-id="closeModal"
                            className={classes.listCloseIcons}
                            onClick={this.navigateToViewTicket}
                        />
                    </Box>
                    <DialogTitle id="alert-dialog-slide-title" className={classes.noPadding}>
                        <Box className={classes.listTilteHeadBoxs}>
                            <Box className={classes.listTitleWrappers}>
                                {"Tickets Listed Successfully!"}
                            </Box>
                            <Box className={classes.listDialogBoxs}>
                                You have successfully listed Tickets to Sell.
                            </Box>
                        </Box>
                    </DialogTitle>

                    <DialogContent className={classes.noPadding}>
                        <Box>
                            <Box className={classes.listSubeWrappers}
                            >
                                {listToSellAttributes?.event.name}
                            </Box>
                            <Box>
                                <Box className={classes.listBoxWrappers}>
                                    Tickets Listed :
                                    <Box>
                                        {listToSellAttributes?.no_of_seats.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Price Per Ticket :
                                    <Box>
                                        <span className={classes.RupeesSymbols}>{"\u20B9"}&nbsp;</span>
                                        { listToSellAttributes?.price_per_seat}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Total Amount :
                                    <Box>
                                        <span className={classes.RupeesSymbols}>{"\u20B9"}&nbsp;</span>
                                        {listToSellAttributes?.total_amount}
                                    </Box>
                                </Box>
                            </Box>
                            <Divider className={classes.customDividers} />
                            <Box>
                                <Box className={classes.listBoxWrappers}>
                                    Request Id :
                                    <Box>
                                        {this.state.eventListToSellResponse?.id}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Time :
                                    <Box>
                                        {getMovieDuration(listToSellAttributes?.event_schedule?.duration_in_mintues)}
                                    </Box>
                                </Box>
                                <Box className={classes.listBoxWrappers}>
                                    Date :
                                    <Box>
                                        {moment(listToSellAttributes?.slot_date).format("ddd, DD MMM  YYYY")}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </DialogContent>
                    <DialogActions className={classes.noPadding}>
                        <Button className={classes.listDialogBtns} data-test-id="navigate" 
                            onClick={() => this.navigateToViewTicket()}
                        >Done</Button>
                    </DialogActions>

                </Dialog>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    backArrow:{
        width: '32px', 
        height: '32px', 
        cursor: "pointer"
    },
    listBoxBgs: {
        backgroundColor: '#404040',
        padding:"0 5px",
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    seatSelectWrapper : { 
        cursor: 'pointer',
        flexWrap:"wrap" as const, 
    }
}
export default (withStyles(styles)(EventListToSell))
export { EventListToSell }
// Customizable Area End
