import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Container,
    Paper,
    Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { SelectPreference } from "./SelectPreference.web";
import moment from "moment";
import EventHeader from "../../../components/src/EventHeader";
import Loader from "../../../components/src/Loader";
import RFHDialog from "../../../components/src/RFHDialog";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#252525",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1: {
            fontSize: 24,
            fontWeight: 600,
            '@media(max-width:600px)': {
                fontSize:16,
            },
        },
        h2: {
            fontSize: 20,
            fontWeight: 600,
            '@media(max-width:600px)': {
                fontSize:16,
            },
        },
        h3: {
            fontSize: 18,
            fontWeight: "normal",
            margin: "10px 0",
            '@media(max-width:600px)': {
                fontSize:16,
            },
        },
        h4: {
            fontSize: 16,
            fontWeight: "normal",
            '@media(max-width:600px)': {
                fontSize:12,
            },
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import SelectVenueScreenController, {
    Props,
} from "./SelectVenueScreenController";

class SelectVenueScreen extends SelectVenueScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    VenueComponent(classes: any, data: any) {
        const handleClick = (event: React.SyntheticEvent,id: string)=> {
            this.setState({ anchorEl: event ? id : false });
        };

        return (
            <>
                <Box data-test-id="venuebox" className={classes.venueBox} 
                    onClick={(e)=>{handleClick(e,data.id)}}>
                    <Box style={{ maxWidth: 503 }}>
                        <Typography variant="h4">{data.city}- {moment(data.date).format("ddd D MMM, YYYY")}</Typography>
                        <Typography variant="h3">{data.name}, {data.city}</Typography>
                        <Typography variant="h4" className={classes.location}>{data.address}</Typography>
                    </Box>
                    {this.state.anchorEl === data.id ? (
                        <ExpandLessIcon className={classes.downarrow} />
                    ) : (
                        <KeyboardArrowDownIcon className={classes.downarrow} />
                    )}
                </Box>

                <RFHDialog
                    data-test-id="successful-payment-dialog"
                    open={this.state.Termsandcondtions}
                    handleClose={this.closeLearnMoreDialog}
                    showTitleDivider={true}
                    maxWidth="md"
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width:"100%"
                        }}>
                            <Button
                                data-test-id="cancelbutton"
                                className={classes.cancelbutton}
                                onClick={this.closeLearnMoreDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-test-id="Acceptbutton"
                                className={classes.acceptbutton}
                                onClick={this.onSelectRegularBooking}
                            >
                                Accept
                            </Button>
                        </div>
                    )}
                    dialogTitle={(
                        <Typography align="center" variant="h1">Terms & Conditions</Typography>
                    )}

                    dialogContent={<Typography variant="h4" style={{ marginTop: 15 }}
                        dangerouslySetInnerHTML={{
                            __html: this.state.description
                        }}
                    />}
                />
                {this.state.anchorEl === data.id && (
                <Box data-test-id="anchorbtn" className={classes.venueTime}>
                    <Box className={classes.timeInnerBox}>
                        {data.time.map((elm: any) => (
                            <Button
                                data-test-id="time_slot"
                                onClick={() => { this.onTermsAndConditionsCheck(elm.id, data.name, data.city)}}
                                className={classes.timeSlot}
                            >
                                {moment(elm.start_time, ["HH:mm"]).format('hh:mm A')}
                            </Button>
                        ))}
                    </Box>
                </Box>
                )}
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <Paper elevation={0} data-test-id="main-wrapper">
                    <EventHeader navigation={this.props.navigation} hideLocation />
                    <Container>
                        <Box className={classes.WrapperBoxs}>
                            <Typography variant="h2">Select Venue</Typography>
                            {this.state.venueList?.map((elm: any) => (
                                this.VenueComponent(classes, elm)
                            ))}
                        </Box>
                    </Container>
                    <FooterWithEvents />


                    {this.state.isTimeSelected && (
                        <SelectPreference
                            classes={classes}
                            navigation={""}
                            handleOnClose={this.onClosePreference}
                            handleOnSelectRegularBooking={this.onSelectRegularBooking}
                            handleOnSelectListedTicket={this.onSelectListedTicket}
                            id=""
                            data-test-id="preference-selection"
                            sheduled_id={this.state.event_schedule_id}
                        />
                    )}
                </Paper>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    WrapperBoxs: {
        maxWidth: 734,
        backgroundColor: "#252525",
        padding: "24px 20px 65px",
        marginTop: 26,
        borderRadius: 8,
        '@media(max-width:600px)': {
            padding:"24px 10px 65px"
        },
    },
    CloseIcons: {
        display: "flex",
        alignItems: "flex-end",
        padding: 5,
        height: '28px',
        width: '28px',
        cursor: 'pointer',
    },
    venueBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "solid 1px #404040",
        margin: "16px 0",
        padding: "12px 16px",
        cursor: "pointer",
        '@media(max-width:600px)': {
            padding:"12px 10px"
        },
    },
    location: {
        color: "#b4b4b4",
        fontWeight: 300
    },
    venueTime: {
        marginTop: -16,
        padding: "16px",
        backgroundColor: "#404040",
        '@media(max-width:600px)': {
            padding:"16px 5px "
        },
    },
    timeInnerBox: {
        padding: "24px 18px 24px 20px",
        borderRadius: "12px",
        border: "solid 1px #979797",
        background: "#121212",
        display: "flex",
        flexWrap: "wrap" as const,
        '@media(max-width:600px)': {
            padding:"24px 5px"
        },
    },
    timeSlot: {
        padding: "12px 4.5px 13px 8.5px",
        borderRadius: "8px",
        border: "solid 1px #ffb53a",
        backgroundColor: "#2f2617",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 500,
        width: " fit-content",
        margin: "0 11px 20px 0",
        cursor: "pointer",
        "&:hover": {
            color: "#000000",
            backgroundColor: "#ffb53a"
        },
        '@media(max-width:600px)': {
            fontSize:16,
        },
    },
    cancelbutton: {
        padding: "19px 55px",
        borderRadius: "8px",
        border: "solid 1px #ffb53a",
        borderWidth:"1px",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 500,
        marginBottom: "10px",
        textTransform:"none" as const,
        maxWidth:248,
        width:"95%",
        maxHeight:"56px",
        '@media(max-width:600px)': {
            fontSize:14,
            width:"48%",
        },
    },
    acceptbutton: {
        padding: "19px 55px",
        borderRadius: "8px",
        backgroundColor: "#ffb53a",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "10px",
        color:"#000",
        textTransform:"none" as const,
        "&:hover": {
            color: "#000",
            backgroundColor: "#ffb53a"
        },
        maxWidth:248,
        width:"95%",
        maxHeight:"56px",
        '@media(max-width:600px)': {
            fontSize:14,
            width:"48%",
        },
    },
    prefMainBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap" as const,
        padding: "0 50px",
        '@media(max-width:600px)': {
            padding: "0"
        },
    },
    dialogContent:{
        '@media(max-width:600px)': {
            padding:"0px"
        },
    }
}



export default (withStyles(styles)(SelectVenueScreen))
export { SelectVenueScreen }
// Customizable Area End
