import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Toolkit";
import { IBookingData, IBookingResponse } from "../../../components/src/interfaces.web";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { getHostName } from "../../../components/src/utilities";

// Customizable Area Start
import { EventListenerKeys } from "../../../components/src/enums.web";
import {IUserDetails } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookingData: IBookingResponse,
  isApiLoading: any,
  mTicketEnabled: boolean,
  isForEvent:string,
  isKycDone:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookingSuccessfulController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendMTicketApiCallId: any
  bookingHistoryApiID: any
  timerId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookingData: {} as IBookingResponse,
      isApiLoading: true,
      mTicketEnabled: false,
      isForEvent:"",
      isKycDone:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(getName(MessageEnum.bookingparams));
      this.setState({ bookingData: data.data, isApiLoading: true })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.sendMTicketApiCallId) {
        this.setState((prevState) => ({ mTicketEnabled: !prevState.mTicketEnabled }))
      }
      else if (apiRequestCallId === this.bookingHistoryApiID) {
        this.setState({ bookingData: responseJson && responseJson.data as IBookingResponse, isApiLoading: false })

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  redirectToHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Homepage");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
  }

  async componentDidMount() {
    super.componentDidMount();

    // If user comes on this page apart from payment page like Home with back button navigation then we should avoid user to access this page
    const bookingData = await getStorageData(LocalStorageKeys.BookingData, true)
    if(!bookingData) this.redirectToHomePage() 
      
    const isForEvent =  await getStorageData(LocalStorageKeys.BookingForEvent)
    this.setState({isForEvent:isForEvent},()=> this.getBookingData());
    window.scrollTo(0, 0)
    this.timerId = setTimeout(() => {
      removeStorageData(LocalStorageKeys.BookingData)
      this.redirectToHomePage();
    }, 3000)
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getBookingData)
    }

    async componentWillUnmount() {
        window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getBookingData);
      if (this.timerId) { clearTimeout(this.timerId) }
    };

  handleChangeMTicket = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {

      const uniqueBookingID = this.state.bookingData?.attributes?.uniq_booking_id
      const token = await getStorageData(LocalStorageKeys.LoginToken)

      const hostname = getHostName()
      this.sendMTicketApiCallId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.postAPiMethod,
        endPoint: `${this.state.isForEvent === "event" ? configJSON.sendMTicketEventApiEndPoint :configJSON.sendMTicketApiEndPoint}`.replace("{BOOKING_ID}", this.state.bookingData?.id),
        headers: { "token": token },
        body: {
          url: `${hostname}/m/${uniqueBookingID}`
        }
      }, runEngine)

    }
  }

  sentMTicket = (mTicketStatus: boolean) => {
    // API CALL LOGIC
  }
  getBookingData = async () => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData

    this.bookingHistoryApiID = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.apiGetMethodType,
      endPoint: `${this.state.isForEvent === "event" ? configJSON.EventBookingApiEndPoint.replace(":id", bookingId) : configJSON.BookingApiEndPoint.replace(":id", bookingId)}`,
      headers: { "token": authToken },
    }, runEngine)
  }


  handleList = async() => {
    const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true)) as IUserDetails;
    if (userDetails.attributes.kyc_status != "approved") {
      this.setState({ isKycDone: true });
      return
    }
    await removeStorageData(LocalStorageKeys.BookingData);
    await setStorageData(LocalStorageKeys.ValidListToSellRedirection, "true")
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ListToSell");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.bookingData.id);
    this.send(message)
  }

  handleListEvent = async() => {
    const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true)) as IUserDetails;
    if (userDetails.attributes.kyc_status != "approved") {
      this.setState({ isKycDone: true });
      return
    }
    await removeStorageData(LocalStorageKeys.BookingData);
    await setStorageData(LocalStorageKeys.ValidListToSellRedirection, "true")
    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EventListToSell");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), bookingId);
    this.send(msg)

  }


  handleOnCloseKycPopup = () => this.setState({ isKycDone: false });
  // Customizable Area End
}
