import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastPartOfURL } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  trailer:any;
  isLoading:boolean;
  poster_url:any;
  videos:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventTrailerScreenController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    getEventDetailApiCall:any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            trailer:[],
            isLoading:true,
            poster_url:[],
            videos:[]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
          if (responseJson && apiRequestCallId === this.getEventDetailApiCall) {
            this.setState({ 
              trailer: responseJson.data.attributes.youtube_links,
              poster_url: responseJson.data.attributes.poster_url,
              videos : responseJson.data.attributes.video_url
            })
            console.log("response getEventDetailApiCall ---------", responseJson)
          }
          this.setState({ isLoading: false })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      this.getEventDetails()
    }

    getEventDetails = async() =>{
      const id = getLastPartOfURL()
      this.getEventDetailApiCall = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.GetMethod,
        endPoint: `${configJSON.getAllEventEndPoint}/${id}`,
        headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string  },
      }, runEngine)
    }
    // Customizable Area End
}
