import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IBookingData, IBookingResponse, SeatIdNumber } from "../../../components/src/interfaces.web";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL, goToPrevPage } from "../../../components/src/utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    seatsSelected: boolean;
    isModalOpen: boolean;
    price: any;
    bookingData: IBookingResponse;
    seatID: SeatIdNumber[];
    bookingResponse: any;
    priceError: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ListToSellController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    bookingHistoryApiID: any;
    createListApiID: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            seatsSelected: false,
            isModalOpen: false,
            price: null,
            bookingData: {} as IBookingResponse,
            seatID: [],
            bookingResponse: {},
            priceError: ''
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId === this.bookingHistoryApiID) {
                this.setState({ bookingData: resJson.data })
            }
            if (apiRequestCallId === this.createListApiID) {
                this.handleReceive(resJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleBack = () => {
        this.props.navigation.goBack();
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen })
    }
    handleSelectTicket = (seatId: number) => {
        const { bookingData, seatID } = this.state;
        // Check if the seat is already selected
        const isSeatSelected = seatID.some((selectedSeat: any) => selectedSeat.seat_id === seatId);
        if (isSeatSelected) {
            // Deselect the seat
            const updatedSeats = seatID.filter((selectedSeat: any) => selectedSeat.seat_id !== seatId);
            this.setState({ seatID: updatedSeats, seatsSelected: updatedSeats.length > 0 });
        } else {
            // Check if the seat is available for selection
            const selectedSeat = bookingData.attributes.seat_id_number.find((seat: any) => seat.seat_id === seatId);
            if (selectedSeat) {
                // Select the seat if it is defined
                this.setState({ seatID: [...seatID, selectedSeat], seatsSelected: true ,priceError:''});
            }
        }
    }

    handleChange = (e: any) => {
        const inputValue = e.target.value;
        let regex = /^\d+$/;
        const isValidInput = regex.test(inputValue);
        if (inputValue > 49999) {
            this.setState({ priceError: 'Enter less than 49999 amount' })
        }
        else {
            this.setState({ price: isValidInput ? inputValue : '', priceError: '' });
        }
    }

    isPriceValid = (data?: string) => {
        if (this.isStringNull(data || this.state.price)) {
            this.setState({ priceError: 'Enter a Price' })
        }
        else {
            this.setState({ priceError: '' })
        }
    }
    isStringNull = (str: string) => {
        return str === null || str?.length === 0
    }
    getBookingData = async () => {
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
        const bookingId = getLastPartOfURL();

        this.bookingHistoryApiID = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.GETMethod,
            endPoint: `${configJSON.getBookingApiEndPoint.replace(":id", bookingId)}`,
            headers: { "token": authToken },
        }, runEngine)
    }
    createLisApi = async () => {
        const { bookingData, price, seatID } = this.state
        const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string
        const bookingId = bookingData.id;
        const screenId = bookingData && bookingData.attributes.screening_id
        if (price === '' || isNaN(parseFloat(price))) {
            this.setState({ priceError: 'Enter a Price' })
            return
        }
        const seatIds = seatID.map((seat: any) => seat.seat_id)
        const body = {
            "booking_request": {
                "bookings": [{ "booking_id": bookingId, "ticket_price": price, "seat_ids": seatIds }],
                "request_type": "resell",
                "screening_id": screenId
            }
        }
        this.createListApiID = callApi({
            contentType: configJSON.apiContentType,
            method: configJSON.POSTMethod,
            endPoint: `${configJSON.createListApiEndPoint}`,
            headers: { "token": authToken },
            body: body
        }, runEngine)
    }
    handleReceive = (resJson: any) => {
        if (resJson[0].data) {
            this.setState({ bookingResponse: resJson[0].data, isModalOpen: true, price: '', priceError: '' })
        }
        else{
            this.setState({priceError: resJson.message})
        }
    }
    handleNavigate = (id?: number) => {
        removeStorageData(LocalStorageKeys.ValidListToSellRedirection).then(() => {
            goToPrevPage();
        })
    }
    calculateAMorPM = (time: any) => {
        const [hours, minutes] = time.split(':');
        let period = 'AM';
        let hour = parseInt(hours, 10);
        if (hour >= 12) {
            period = 'PM';
            if (hour > 12) {
                hour -= 12;
            }
        }
        return `${hour}:${minutes} ${period}`;
    };
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getBookingData()

        const validRedirection = await getStorageData(LocalStorageKeys.ValidListToSellRedirection, true);
        if(!validRedirection) goToPrevPage();
        await removeStorageData(LocalStorageKeys.ValidListToSellRedirection)
    }
    // Customizable Area End
}
