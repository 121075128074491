import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, DetailsType, LocalStorageKeys, KycStatus } from "../../../components/src/enums.web";
import { IEventLanguages, ILanguage, IMovieAttributes, IMovieEventItoListingResponse, IMovieItoListing, IUserDetails } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isGuestUser: boolean
  openSignup: boolean
  userToken: string
  location: string
  isApiLoading: boolean
  openKycPendingDialog: boolean
  itoListing: IMovieItoListing[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ItoListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getItoListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      location: '',
      userToken: '',
      isGuestUser: false,
      isApiLoading: true,
      openSignup: false,
      itoListing: [],
      openKycPendingDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson !== undefined && responseJson?.errors === undefined) {

        if (apiRequestCallId === this.getItoListApiCallId) {
          this.handleItoListing(responseJson as IMovieEventItoListingResponse)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleItoListing = (responseJson: IMovieEventItoListingResponse) => {
    const { data } = responseJson;
    this.setState({ itoListing: data, isApiLoading: false })
  }

  redirectToTrailersScreen = (movieId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Trailers");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), movieId);
    this.send(message);
  }

  getItoListing = async () => {
    this.setState({ isApiLoading: true })
    const location = await getStorageData(LocalStorageKeys.Location) as string;

    let endpoint = `${configJSON.getMovieEventItoListing}`;
    if (location) endpoint = endpoint.concat(`?location=${location}`);

    this.getItoListApiCallId = callApi({
      method: configJSON.GetMethod,
      endPoint: endpoint,
      headers: { "token": this.state.userToken || "guest_user" },
    }, runEngine)
  }

  handleRedirect = async (redirectId: number, type: DetailsType) => {

    // check for logged in user and kyc status
    const userToken = (await getStorageData(LocalStorageKeys.LoginToken)) as string
    if (userToken) {
      const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true)) as IUserDetails;
      if (userDetails.attributes.kyc_status !== KycStatus.Approved) {
        this.setState({ openKycPendingDialog: true })
        return
      }

      let redirectToTarget = "AuctionBidding";
      if (type === DetailsType.Event) {
        redirectToTarget = "EventDetail";
      }

      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), redirectToTarget);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), redirectId);
      this.send(message);
    } else {
      this.setState({ openSignup: true })
    }
  }



  async componentDidMount() {
    const userToken = (await getStorageData(LocalStorageKeys.LoginToken)) as string
    this.setState({ userToken: userToken || "", isGuestUser: !!userToken }, () => this.getItoListing())

    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getItoListing)
  }

  async componentWillUnmount() {
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getItoListing)
  }

  closeSignup = () => this.setState({ openSignup: false })
  handleKycClose = () => this.setState({ openKycPendingDialog: false })

  isILanguage = (element: ILanguage | IEventLanguages): element is ILanguage => {
    return (element as ILanguage).language_name !== undefined;
  }

  getLanguageList = (languages: Array<ILanguage | IEventLanguages>) => {
    return languages.reduce((accumulator, current) => {
      if (this.isILanguage(current)) {
        return accumulator.concat(` ${current.language_name},`);
      }
      return accumulator.concat(` ${current.name},`);
    }, "").slice(0, -1)
  }

  getPosterUrl = (attributes: IMovieAttributes) => {
    if (attributes.image_url) return attributes.image_url
    return attributes.poster_url[0]
  }

  getCategoryList = (category_type: string) => category_type.split(",").join("")
  // Customizable Area End
}
