import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IMovieAttributes } from "../../../components/src/interfaces.web";

// Customizable Area Start
type trailerData = {
  movieName: string,
  posterUrl: string,
  list: {
    trailerId: number,
    trailerUrl: string,
    language: string
  }[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  trailerData: trailerData
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TrailersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTrailersId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      trailerData: {} as trailerData,
      isLoading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTrailersId) {
        this.handleReceive(resJson?.data?.attributes);
      }
    } else {
      runEngine.debugLog("GOIT");
    }
  
    // Customizable Area End
  }

  handleReceive(movieData: IMovieAttributes){

    const trailerData = {
      movieName: movieData.name,
      posterUrl: movieData.image_url,
      list: movieData.youtube_links.map(link => ({
        trailerId: link.id,
        trailerUrl: link.link,
        language: this.getLanguageNameById(parseInt(link.language), movieData.languages)
      }))
    }

    this.setState({ trailerData: trailerData as trailerData, isLoading: false })
  }

  // web events
 
  // Customizable Area Start
  getLanguageNameById(languageId: number, languages: { language_id: number; language_name: string; }[]): string {
    const language = languages.find(lang => lang.language_id === languageId);
    return language ? language.language_name : "";
  }

  getTrailers = () => {
    const movieId = this.props.navigation.getParam("navigationBarTitleText");
    const endPoint = `bx_block_categories/movies/${movieId}`
    const method = "GET"
    const header = {
      "Content-Type": 'application/json',
      'token':"guest_user"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrailersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getTrailers()
  }
  // Customizable Area End
}
