import React from 'react'
import { IEventBookingFullResponse } from './interfaces.web'
import { Box, Button, Divider, Typography } from '@material-ui/core'
import moment from 'moment'
import {  convertToPad, getMovieDuration } from './utilities'
import { InvalidStatusImage, cancelledImage } from './assets'
import { FilterOption } from './enums.web'
import { EventSeat } from './BookingSuccessfulTicket.web'

interface IEventMobileTicketProps {
  booking: IEventBookingFullResponse
  redirectToViewTicket: (id: number) => void
  message: string
  classes : any
  configJSON : any
}

const EventMobileBookingHistory: React.FC<IEventMobileTicketProps> = (props) => {

  let ImageURL;

  const {
    booking: {
      id: ticketId,
      attributes
    },
    message,
    redirectToViewTicket,
    classes,
    configJSON
  } = props

  const bookingHistoryData = {
    image_url: attributes.poster_url[0],
    name: attributes.event.name,
    release_date: attributes.schedule.schedule_date,
    duration_in_minutes: attributes.schedule.duration_in_mintues,
    theatre_name: attributes.venue.name,
    language: attributes.language.map((item: any, ind: number) => `${item.name} ${attributes.language.length - 1 != ind ? ', ' : ""}`),
    show_date_time: [attributes.schedule.schedule_date, moment(attributes.schedule.start_time, ["HH:mm"]).format('hh:mm A')],
    seat_number: attributes.seats,
    booked_date: attributes.booked_date,
    number_of_seats: attributes.seats.length,
    total_amount: attributes.total_amount,
    status: attributes.status,
    is_invalid: attributes.is_invalid,
    seat_allocation_type : attributes.seat_allocation_type,
  }


  switch (bookingHistoryData.status) {
    case FilterOption.Cancelled:
      ImageURL = cancelledImage;
      break;

    case FilterOption.Invalid:
      ImageURL = InvalidStatusImage;
      break;

    default:
      ImageURL = null;
      break;
  }

  if (bookingHistoryData.is_invalid) ImageURL = InvalidStatusImage


  return (
    <Box className={classes.Ticket}>

      <Box display="flex" gridGap="12px" position="relative">

        {/* BOOKING STATUS LABEL START */}
        <Box className={classes.BookingStatusLabel}>
          {ImageURL && <img src={ImageURL} width="261px" height="204px" />}
        </Box>
        {/* BOOKING STATUS LABEL END */}

        <Box className={classes.PosterWrapper}>
          <img className={classes.PosterImage} src={bookingHistoryData.image_url} alt="Poster-image" />
        </Box>

        <Box>
          <Typography className={classes.TicketTitle}>{bookingHistoryData.name}</Typography>
          <Typography>
            <span className={classes.TicketYear}>{new Date(bookingHistoryData.release_date).getFullYear()}</span>
            &nbsp;
            <span className={classes.TicketYear}>UA</span>
            &nbsp;
            <span className={classes.TicketYear}>{getMovieDuration(bookingHistoryData.duration_in_minutes)}</span>
          </Typography>
          <Typography className={classes.TicketLanguageFormat}>{bookingHistoryData.language}</Typography>
          <Typography className={classes.DateTime}>
              {bookingHistoryData.show_date_time[0]}
              <span>&nbsp;|&nbsp;</span>
              {bookingHistoryData.show_date_time[1]}
          </Typography>
          <Typography className={classes.Location}>{bookingHistoryData.theatre_name}</Typography>
        </Box>

      </Box>

      <Divider className={classes.Divider} />

      <Box className={classes.DisplayFlex}>
        {bookingHistoryData.seat_allocation_type === "manual"
          ? <Typography className={classes.Location}>Seats will be assigned on a first-come, first-served basis.</Typography>
          : <>
            <span className={classes.ScreenLabel}>
              Seat No
            </span>
            <Box className={classes.SeatWrapper} style={{ maxWidth: "auto !important" }}>
              {
                bookingHistoryData.seat_number.map((seat) => <EventSeat key={seat.id} seatLabel={seat.seat_number} />)
              }
            </Box>
          </>
        }

      </Box>

      <Box className={classes.DisplayFlex}>
        <span className={classes.BookingDateLabel}>{configJSON.BookingDate}</span>
        <span className={classes.BookingDateLabel}>{moment(bookingHistoryData.booked_date).format("DD MMM, YYYY")}</span>
      </Box>

      <Divider className={classes.Divider} />

      <Box className={classes.DisplayFlex} alignItems="center">
        <span className={classes.BookingDateLabel}>{`${configJSON.TicketsLabel} (${convertToPad(bookingHistoryData.number_of_seats)})`}</span>
        <Typography className={classes.TicketTotal}><span>₹</span> {Number(bookingHistoryData.total_amount).toFixed(2)}</Typography>
      </Box>

      <hr className={classes.DottedDivider} />

      <Button className={classes.ViewButton} fullWidth onClick={() => redirectToViewTicket(+ticketId)}>{configJSON.ViewTicket}</Button>

      <hr className={classes.DottedDivider} />

      <Typography className={classes.Message}>{message}</Typography>

      <span className="left-side-cut"></span>
      <span className="right-side-cut"></span>

    </Box>
  )
}

export default EventMobileBookingHistory