import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getLastPartOfURL } from "../../../components/src/utilities";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { EventSeatCategory, IBookingData, IUserDetails } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSeatSelection:boolean,
  selectedSeatNumber: number,
  tierList: EventSeatCategory[],
  event_seat_category_id:any,
  isLoading:boolean,
  noSeat:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectTierController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
  getTierListApiCall: any
  eventBookingAPICallId: any
  evntItoBookingAPICallId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isSeatSelection:false,
            selectedSeatNumber: 2,
            tierList: [],
            event_seat_category_id:0,
            isLoading:true,
            noSeat:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            
            if (responseJson) {
              if (apiRequestCallId === this.getTierListApiCall) {
                this.setState({ tierList: responseJson.data });
              } else if (apiRequestCallId === this.eventBookingAPICallId) {
                if (responseJson.data) {
                  this.handleEventBookResponse(responseJson.data);
                } else {
                  this.setState({ noSeat: true, isSeatSelection: false });
                }
              } else if (apiRequestCallId === this.evntItoBookingAPICallId) {
                this.handleEventBookResponse(responseJson.data);
              }
            }
            
            this.setState({isLoading:false})
          }
        // Customizable Area End
    }

    // Customizable Area Start

  async componentDidMount() {
    this.getTierList()
  }

  getTierList = async () => {
    const id = getLastPartOfURL()
    this.getTierListApiCall = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.GetMethod,
      endPoint: `${configJSON.getEventTierListEndPoint}${id}`,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) as string },
    }, runEngine)
  }

  onSelectTier = (id: any) => {
    this.setState({ isSeatSelection: true, event_seat_category_id: id })
  }

  handleOnClose = () => {
    this.setState({ isSeatSelection: false })
  }

  onClickSeatNumber = (number:number) => {
    this.setState({ selectedSeatNumber: number })
  }

    handleOnCloseNoSeat = () =>{
      this.setState({noSeat:false})
    }



  handleContinue = async () => {

    const { attributes: { email, full_phone_number } }: IUserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);

    const httpBody = {
      event_booking: {
        event_schedule_id: getLastPartOfURL(),
        total_seats: this.state.selectedSeatNumber,
        event_seat_category_id: this.state.event_seat_category_id,
        account_email: email,
        phone: full_phone_number
      }
    }

    this.eventBookingAPICallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.PostMethod,
      endPoint: configJSON.eventBookingEndPoint,
      body: httpBody,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
    }, runEngine)
  }

  handleEventBookResponse = async (responseJson: any) => {
    const bookingData: IBookingData = {
      id: +responseJson.id,
      totalAmount: +responseJson?.attributes?.total_amount,
      hideOtherPayment: responseJson?.attributes?.booking_type === "ito"
    }
    await setStorageData(LocalStorageKeys.BookingData, JSON.stringify(bookingData))

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PaymentSelectionEvent");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), "event");
    this.send(msg);

  }



  handleContinueforITO = async () => {
    const { attributes: { email, full_phone_number } }: IUserDetails = await getStorageData(LocalStorageKeys.UserDetails, true);
    const httpBody = {
      event_ito_booking: {
        booking_type: "ito",
        event_schedule_id: getLastPartOfURL(),
        total_seats: this.state.selectedSeatNumber,
        event_seat_category_id: this.state.event_seat_category_id,
        account_email: email,
        phone: full_phone_number
      }
    }

    this.evntItoBookingAPICallId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.PostMethod,
      endPoint: configJSON.eventsItoBookingEndPoint,
      body: httpBody,
      headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
    }, runEngine)
  }


  handlefunctioncall = async () => {
    const getITOstate = await getStorageData("ITOstate")
    if (getITOstate == "true") {
      this.handleContinueforITO()
    }
    else {
      this.handleContinue()
    }

  }
    // Customizable Area End
}
