import React from "react";

import {
  // Customizable Area Start
  Paper,
  Box,
  Fade,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KycDialogeBox from "../../cfwallet19/src/KycDialogeBox.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    background: {
      paper: "#121212",
      default: "#40404060",
    },
    text: {
      primary: "#FFF",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h2: {
      fontSize: "22px",
      fontWeight: 500,
      letterSpacing: 0.38,
      "@media (max-width: 600px)": {
        fontSize: "20px",
        letterSpacing: 0
      }
    },
    h6: {
      fontSize: "20px",
      fontWeight: 600,
      letterSpacing: "0.11px",
      "@media (max-width: 600px)": {
        fontSize: "18px"
      }
    },
    subtitle1: {
      fontSize: "22px",
      fontWeight: 600,
      "@media (max-width: 600px)": {
        fontSize: "20px"
      }
    },
    subtitle2: {
      fontSize: "1.125rem",
      letterSpacing: "0.09px",
    },
    body1: {
      fontSize: "18px",
      fontWeight:"normal",
      "@media (max-width: 600px)": {
        fontSize: "14px"
      }
    },
    body2: {
      fontSize: "18px",
      "@media (max-width: 600px)": {
        fontSize: "16px"
      }
    },
  },
});
// Customizable Area End

import BookingSuccessfulController, {
  Props,
} from "./BookingSuccessfulController";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import BookingSuccessfulTicket from "../../../components/src/BookingSuccessfulTicket.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";

export default class BookingSuccessful extends BookingSuccessfulController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{ minHeight: '470px' }}>
          <Paper data-test-id="book" style={withStyles.main}>
            <MainHeaderComp navigation = {this.props.navigation} />

            {
              this.state.isApiLoading ? (
                <Box p={2} display="flex" justifyContent="center">
                  <Fade in={this.state.isApiLoading} unmountOnExit>
                    <CircularProgress />
                  </Fade>
                </Box>

              ) : (
                this.state.bookingData && (
                  <BookingSuccessfulTicket
                    data-test-id="m-ticket-button"
                    bookingData={this.state.bookingData}
                    isApiLoading={this.state.isApiLoading}
                    mTicketEnabled={this.state.mTicketEnabled}
                    handleChangeMTicket={this.handleChangeMTicket}
                    handleList={this.handleList}
                    handleListEvent= {this.handleListEvent}
                    isForEvent={this.state.isForEvent}
                  />
                )
              )
            }

            {this.state.isKycDone &&
              <KycDialogeBox
                data-test-id="crossBtn"
                navigation={this.props.navigation}
                id="seat-selection-kyc"
                handleOnClose={this.handleOnCloseKycPopup}
              />
            }
            <Box style={{ marginTop: 'auto' }} display={{ xs: "none", sm: "block" }}>

              <FooterWithEvents />

            </Box>
          </Paper>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const withStyles = {
  main: {
    display: 'flex',
    minHeight: "100vh",
    flexDirection: 'column' as const,
  }
}
// Customizable Area End
