import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Paper,
    Typography,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import EventHeader from "../../../components/src/EventHeader";
import RFHDialog from "../../../components/src/RFHDialog";
import { PaymentSuccessfull } from "../../requestmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { RFHButton } from "./SelectPreference.web";
import ReactPaginate from 'react-paginate';

const ReactPaginateComp = styled(ReactPaginate)(({ theme }) => ({
  display:"flex",
  color:"#fff",
  fontSize:18,
      fontFamily:"Poppins",
  '& > li':{
    listStyle:"none",
    width: 25,
    height: 25,
    marginLeft: 10,
    textAlign: "center",
    cursor:"pointer"
  },
  '& .selected':{
    background:"#ffb636",
    borderRadius:4,
    color:"#000"
  },
  '@media(max-width:600px)': {
    fontSize: "12px",
    '& > li':{
        width: 20,
        height: 20,
    }
},
}))

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1:{
            fontSize:24,
            fontWeight:600,
            '@media(max-width:600px)': {
                fontSize: "16px",
            },
        },
        h6:{
            fontSize:18,
            fontWeight:"normal",
            '@media(max-width:600px)': {
                fontSize: "10px",
            },
        },
        h2:{
            fontSize:22,
            fontWeight:600
        },
        h3:{
            fontSize:18,
            fontWeight:"normal",
            margin:"10px 0"
        },
        h4:{
            fontSize:16,
            fontWeight:"normal"
        },
        h5:{
            fontSize:18,
            fontWeight:500,
            '@media(max-width:600px)': {
                fontSize: "12px",
            },
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import ListedTicketController, {
    Props,
    configJSON
} from "./ListedTicketController";

class ListedTicket extends ListedTicketController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Paper className={classes.WrapperBoxs} elevation={0} >
                <EventHeader navigation={this.props.navigation}/>
                <Loader loading={this.state.isLoading} />
                    <Box className={classes.mainBox}>
                        <Typography className={classes.listed_ticket} variant="h1">Listed Tickets</Typography>
                        {this.state.listedTickets.map((elm: any) => <Box className={classes.listedTicketList}>
                            <Box className={classes.ticketDetailsWrapper}>
                                <Box className="seat__details">
                                    <Typography variant="h5">Tier: {elm.attributes.seat_details[0].tier}</Typography>
                                        <Box className={classes.ticketsWrapper}>
                                    {elm.attributes.seat_details.map((elm:any)=> (
                                        <Box
                                        className={classes.ticketNumbersBtn}
                                    >
                                        {elm.seat_number}
                                    </Box>
                                    ))}
                                    </Box>
                                </Box>
                                <Box className="price__details">
                                    <Box className={classes.priceWrapper}>
                                        <Typography variant="h6">
                                            Price per Seat: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <Typography variant="h6">
                                            <span
                                                style={{ fontFamily: "Roboto" }}>
                                                &#8377;
                                            </span> {parseFloat(elm.attributes.price_per_seat).toFixed(2)}
                                        </Typography>
                                    </Box>

                                    <Box className={classes.priceWrapper}>
                                        <Typography variant="h6">
                                            No of Seats: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <Typography variant="h6">
                                            {elm.attributes.no_of_seats}
                                        </Typography>
                                    </Box>

                                    <Box className={classes.priceWrapper}>
                                        <Typography variant="h6">
                                            Total Amount: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <Typography variant="h6">
                                            <span
                                                style={{ fontFamily: "Roboto" }}>
                                                &#8377;
                                            </span> {parseFloat(elm.attributes.total_amount).toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Button
                                data-test-id="buyListedTicketBtn"
                                color="primary"
                                className={classes.buyBtn}
                                onClick={()=> this.buyListedTicket(elm.id)}
                            >
                                Buy
                            </Button>
                        </Box>
                        )}

                        <Box data-test-id="pagination-box" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <ReactPaginateComp
                                data-test-id="pagination"
                                nextLabel=">"
                                onPageChange={(e) => this.handleOnPageChange(e)}
                                pageCount={this.state.pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        </Box>
                    </Box>
                    
                    <RFHDialog
                        data-test-id="successful-payment-dialog"
                        open={this.state.message.length > 0}
                        hideCloseButton={true}
                        maxWidth="xs"
                        disableEscapeKeyDown={true}
                        dialogActions={(
                            <RFHButton data-test-id="payment-done-btn" fullWidth onClick={() => { this.setState({ message: "" }) }}>
                                {configJSON.Done}
                            </RFHButton>
                        )}
                        dialogContent={<Box display="flex" justifyContent="center" flexDirection="column">
                            <Box p={0} mb={2} display="flex" justifyContent="center">
                                <img src={PaymentSuccessfull} height="48px" width="48px" />
                            </Box>
                            <Typography align="center" variant="h1">
                                {this.state.message}
                            </Typography>
                        </Box>}
                    />
                </Paper>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    WrapperBoxs: {
        display:"flex",
        flexDirection:"column" as const,
        alignItems:"center",
        height:"100vh",
        overflowY:"auto" as const,
    },
    mainBox:{
        maxWidth:797,
        width:"100%",
        marginTop:70,
        marginBottom:150,
        '@media(max-width:600px)': {
            marginTop:25,
            marginBottom:40,
        }
    },
    listedTicketList:{
        width:"100%",
        margin: "32px 0 24px 0",
        padding: "32px 72px",
        borderRadius: "6px",
        backgroundColor: "#404040",
        boxSizing:"border-box" as const,
        '@media(max-width:600px)': {
            padding: "20px 15px",
            margin:"20 5px",
            width:"auto",
        },
    },
    priceWrapper:{
        display:"flex",
        justifyContent:"space-between",
        marginBottom:10,
        flexWrap:"wrap" as const
    },
    ticketDetailsWrapper: {
        display: "flex",
        "& .seat__details": {
            flex: 1
        },
        "& .price__details": {
            flex: 1,
            maxWidth: "252px"
        }
    },
    ticketsWrapper: {
        display: "flex",
        gap: "10px",
        flexWrap: "wrap" as const,
        marginTop: "12px"
    },
    ticketNumbersBtn:{
        border: "solid 1.1px #ffb53a",
        borderRadius: 2,
        fontSize:16,
        fontWeight:600,
        padding:"0px 5px",
        height:32,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontFamily:"Poppins",
        background:"#ffb53a",
        color:"#050505",
        '@media(max-width:600px)': {
            fontSize: "10px",
            height: "24px"
        },
    },
    buyBtn: {
        padding: '12px 80px',
        width: "100%",
        backgroundColor: '#ffb43a',
        borderRadius: 12,
        textTransform: 'capitalize' as const,
        color: '#202020',
        fontFamily: "Poppins",
        fontSize: 18,
        fontWeight: 600,
        textAlign: "center" as const,
        marginTop:40,
        "&:hover": {
          backgroundColor: '#ffb43a',
        },
        '@media(max-width:600px)': {
            fontSize: "14px",
        },
      },
      listed_ticket:{
        '@media(max-width:600px)': {
            margin:5
        },
      }
}

export default (withStyles(styles)(ListedTicket))
export { ListedTicket }
// Customizable Area End
