import { Box, styled } from '@material-ui/core'
import React, { Component } from 'react'
import Slider, { Settings } from 'react-slick'

interface Props {
  settings?: Settings
}
export default class GenericCarousel extends Component<Props> {

  render() {

    return (
      <BoxWrapper className='slider-container'>
        <Slider {...this.props.settings}>
          {this.props.children}
        </Slider>
      </BoxWrapper>
    )
  }
}

const BoxWrapper = styled(Box)({
  "& .slick-list": {
    width: "100%"
  },
  "& .slick-next::before": {
    content: "none"
  },
  "& .slick-prev::before": {
    content: "none"
  },
  "& .slick-arrow": {
    zIndex: 500,
    "& .next__button": {
      width: "35px",
      height: "35px",
      cursor: "pointer"
    },
    "& .previous__button": {
      width: "35px",
      height: "35px",
      cursor: "pointer"
    },
  },
  "@media (max-width: 600px)": {
    "& .slick-arrow": {
      "& .next__button": {
        width: "28px !important",
        height: "28px !important",
      },
      "& .previous__button": {
        width: "28px !important",
        height: "28px !important",
      },
    },
  }
  
})
