import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { EventListenerKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { title } from "process";

// Customizable Area Start

type MovieLanguage = {
  id: string;
  type: "movie_language";
  attributes: {
    name: string;
  };
};

type MovieScreenType = {
  id: string;
  type: "movie_screen_type";
  attributes: {
    screen_type: string;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  objMovie: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isHover: boolean;
  movieid: any;
  anchorEl: any
  movieList: any[],
  reviewedmovie:boolean,
  filterList: any[]
  lstSearch: any[]
  checkedValues: any[]
  lstMenu: any[]
  doSignUp:boolean
  Ratingpage:boolean,
  getMovie: any,
  dummyData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SeeAllMoviesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllMoviesCallId: any
  languageFilterApiCallId: string = "";
  formatFilterApiCallId: string = "";
  genreFilterApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isHover: false,
      anchorEl: null,
      movieList: [],
      reviewedmovie:false,
      movieid: '',
      filterList: [],
      doSignUp:false,
      Ratingpage:false,
      getMovie: '',
      dummyData: [],
      lstMenu: [
        {
          title: "Currently Playing",
          options: [
            "Currently Playing",
            "This Friday",
            "Coming Soon",
            "Apply for ITO",
          ],
          isCheckBox: false
        },
        {
          title: "Languages",
          options: [
            "English",
            "Hindi",
            "Tamil",
            "Telugu",
            "Malyalam",
            "Kannada",
            "Marathi",
            "Bengali",
            "Punjabi"
          ],
          isCheckBox: true
        },
        {
          title: "Format",
          options: [
            "2D",
            "3D",
          ],
          isCheckBox: true
        },
        {
          title: "Genres",
          options: [
            "Romance",
            "Comedy",
            "Action",
            "Thriller",
            "Horror",
            "Crime",
            "Biography",
            "Psychological",
            "Mystery"
          ],
          isCheckBox: true
        }
      ],
      lstSearch: [
        { title: "Currently Playing", lstSelected: [] },
        { title: "Languages", lstSelected: [] },
        { title: "Format", lstSelected: [] },
        { title: "Genres", lstSelected: [] }
      ],
      checkedValues: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getAllMoviesCallId:
          this.handleReceive(resJson);
          break;

        case this.languageFilterApiCallId:
          this.handleLanguageFilter(resJson.data);
          break;

        case this.formatFilterApiCallId:
          this.handleFormatFilter(resJson.data);
          break;

        case this.genreFilterApiCallId:
          this.handleGenreFilter(resJson.data);
          break;

      }
    } else {
      runEngine.debugLog("GOIT");
    }

  }


  getMoviedetails = (movieid: any) => {
    this.props.navigation.navigate("MovieDetails", { id: movieid });
  }

  handleReceive = (resJson: any) => {
    this.setState({ movieList: resJson.data })
  }


  // Customizable Area Start
  getAllmovies = () => {
    const location = localStorage.getItem('location')
    const endPoint = `bx_block_categories/currently_playing/currently_playing?status=playing&location=${location}`
    const token = localStorage.getItem('login')
    const guest = 'guest_user'
    const method = "GET"
    const header = {
      "Content-Type": 'application/json',
      "token": token || guest
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllMoviesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  
  handleLanguageFilter = (response: MovieLanguage[]) => {
    const languages = response.map(item => item.attributes.name)
    this.setState((prevState) => ({
      lstMenu: prevState.lstMenu.map(item => item.title == "Languages" ? ({
        title: "Languages",
        options: languages,
        isCheckBox: true
      }) : item)
    }))
  }

  handleFormatFilter = (response: MovieScreenType[]) => {
    const formats = response.map(item => item.attributes.screen_type);
    this.setState((prevState) => ({
      lstMenu: prevState.lstMenu.map(item => item.title == "Format" ? ({
        title: "Format",
        options: formats,
        isCheckBox: true
      }) : item)
    }))
  }

  handleGenreFilter = (response: string[]) => {
    this.setState((prevState) => ({
      lstMenu: prevState.lstMenu.map(item => item.title == "Genres" ? ({
        title: "Genres",
        options: response,
        isCheckBox: true
      }) : item)
    }))
  }

  getLanguagesFilter = () => {
    this.languageFilterApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.getLanguagesFilterApiEndPoint,
      headers: { "token": configJSON.guestToken },
    }, runEngine)
  }

  getFormatFilter = () => {
    this.formatFilterApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.getFormatFilterApiEndPoint,
      headers: { "token": configJSON.guestToken },
    }, runEngine)
  }

  getGenreFilter = () => {
    this.genreFilterApiCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.getGenreFilterApiEndPoint,
      headers: { "token": configJSON.guestToken },
    }, runEngine)
  }
  
  async componentDidMount() {
    this.getLanguagesFilter();
    this.getFormatFilter();
    this.getGenreFilter();
    await super.componentDidMount();
    this.getAllmovies()
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    if (loginToken) {
      localStorage.setItem('isGuest', 'false');

    } else {
      localStorage.setItem('isGuest', 'true');

    }
    window.scrollTo(0, 0)

    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getAllmovies)
  }
  
  async componentWillUnmount(): Promise<void> {
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getAllmovies)  
  }

  onFilter = () => {
    let lstTmpMain = [...this.state.lstSearch]
    let Languages = lstTmpMain.find((obj: any) => obj.title == "Languages").lstSelected
    let Format = lstTmpMain.find((obj: any) => obj.title == "Format").lstSelected
    let Genres = lstTmpMain.find((obj: any) => obj.title == "Genres").lstSelected

    let filterListTmp = this.state.movieList.filter((obj) => {
      let objMovie = obj.attributes
      let lstMoviesLanguage = objMovie.languages.map((objLan: any) => objLan.language_name)
      let lstMoviesFormat = objMovie.screen_types.map((objLan: any) => objLan.screen_type)
      let lstMoviesGenres = objMovie.category_type.split(", ")
      const isL = Languages.length ? Languages.every((strFL: any) => lstMoviesLanguage.includes(strFL)) : true
      const isF = Format.length ? Format.every((strFF: any) => lstMoviesFormat.includes(strFF)) : true
      const isG = Genres.length ? Genres.every((strFG: any) => lstMoviesGenres.includes(strFG)) : true
      if (isL && isF && isG) {
        return obj
      }
    })

    this.setState({ filterList: filterListTmp })
  }



  setSearch = (list: string, title: string) => {
    let lstTmp = [...this.state.lstSearch]
    let lstFinal = lstTmp.map((obj: any) => {
      if (obj.title == title) {
        let lstSelectedTmp = obj.lstSelected.includes(list) ? obj.lstSelected.filter((strFi: any) => strFi !== list) : [...obj.lstSelected, list]
        return {
          ...obj,
          lstSelected: lstSelectedTmp
        }
      } else {
        return obj
      }
    })
    this.setState({ lstSearch: lstFinal })
  }

  isChecked = (list: string, title: string) => {
    // title lstSelected
    let lstTmp = [...this.state.lstSearch]
    let objTmp = lstTmp.find((obj: any) => obj.title == title)
    if (objTmp) {
      if (objTmp.lstSelected.length) {
        let isExist = objTmp.lstSelected.includes(list)
        if (isExist) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  redirectToTrailers = (id?: number) => {

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Trailers");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(msg);
    
  }


  getCount = (title: string) => {
    let lstTmpMain = [...this.state.lstSearch]
    let searchList = lstTmpMain.find((obj: any) => obj.title == title)?.lstSelected
    return searchList?.length
  }

  resetFilter = () => {
    this.setState({
      lstSearch: [
        { title: "Currently Playing", lstSelected: [] },
        { title: "Languages", lstSelected: [] },
        { title: "Format", lstSelected: [] },
        { title: "Genres", lstSelected: [] }
      ]
    })
  }
  isFilterApplyed = () => {
    return this.state.lstMenu.find((obj) => this.getCount(obj.title)) ? true : false
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.lstSearch !== prevState.lstSearch) {
      this.onFilter()
    }
  }

  handleOpenDialog = async (movieid: any) => {
    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string;
    if (!authToken) {
      this.setState({doSignUp:true,Ratingpage:false});
    }
    else {
      this.props.navigation.navigate("MovieDetails", { id: movieid });
    }
}



  // Customizable Area End
}
