import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { IEventAdvanchSearch, IEventLanguages, IMovieAdvanchSearch } from "../../../components/src/interfaces.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open:boolean;
  handleClose: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;

  tabValue:string;
  movieList: IMovieAdvanchSearch [];
  eventList: IEventAdvanchSearch [];
  loading:boolean;
  searchValue:string;
  anchorEl : HTMLElement | null,
  languages: IEventLanguages[],
  languageIds: number[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getMovieEventApiCall : string = "";
  getAllEventApiCall : string = "";
  getLanguageApiCall : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,

      tabValue:"1",
      movieList: [
        {
          "id": "18",
          "type": "movie",
          "attributes": {
              "name": "Veera Simha Reddy",
              "category_type": "Action, Drama",
              "duration_in_minutes": 170,
              "release_date": "2023-01-11",
              "description": "The father of Veera Simha Reddy is a revered man in a village and his son Bala Simha Reddy settles in the USA.When his father gets killed in the village politics, Bala Simha returns to India and takes revenge on who killed his father.",
              "status": "playing",
              "image_url": "https://m.media-amazon.com/images/M/MV5BNmE4MTY4NmEtNzM1My00YWU3LTgyOTMtODEwMzQzZWQyOTY3XkEyXkFqcGdeQXVyMTUzOTcyODA5._V1_.jpg",
              "youtube_links": [],
              "languages": [
                  {
                      "language_id": 3,
                      "language_name": "Hindi"
                  },
                  {
                      "language_id": 4,
                      "language_name": "Telugu"
                  },
                  {
                      "language_id": 5,
                      "language_name": "Malyalam"
                  }
              ],
              "screen_types": [
                  {
                      "screen_type_id": 1,
                      "screen_type": "2D"
                  },
                  {
                      "screen_type_id": 2,
                      "screen_type": "3D"
                  }
              ],
              "search_count": 1,
              "trailers": [],
              "is_favourite": false,
              "is_reviewed": false,
              "is_promoted": false,
              "priority": null,
              "is_listed_to_ito": false,
              "is_ito_live": false,
              "ito_start_date": null,
              "ito_start_time": null,
              "ito_end_date": null,
              "ito_end_time": null,
              "ito_listing_date": null,
              "ito_listing_time": null
          }
      }
      ],
      eventList:[
        {
          "id": "36",
          "type": "event_detail",
          "attributes": {
              "name": "T k play 123",
              "description": "Itis based on poetry /love/ healing show. ",
              "term_and_conditions": "<p>Need to come with valid id prrof.</p>",
              "classify": "recurring",
              "above_age": "18",
              "created_at": "2024-03-19T08:11:34.734Z",
              "updated_at": "2024-03-27T13:09:59.068Z",
              "show_type": [
                  "workshops",
                  "performance"
              ],
              "booking_start_date": "27-03-2024",
              "booking_start_time": "06:41 PM",
              "booking_end_date": "20-04-2024",
              "booking_end_time": "08:47 PM",
              "languages": [
                  {
                      "id": 1,
                      "name": "English",
                      "created_at": "2023-02-28T11:08:11.089Z",
                      "updated_at": "2023-02-28T11:08:11.089Z"
                  },
                  {
                      "id": 3,
                      "name": "Hindi",
                      "created_at": "2023-02-28T11:08:31.635Z",
                      "updated_at": "2023-02-28T11:08:31.635Z"
                  }
              ],
              "poster_url": [
                  "https://ticketxchange-203359-ruby.b203359.dev.eastus.az.svc./rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbVFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--79f61ae31b51c05dde5d5396bd7471111675e211/2023-12-19_15h38_31.png"
              ],
              "video_url": [],
              "is_promoted": false,
              "priority": null,
              "venues": [
                  {
                      "id": 49,
                      "name": "shambhu coffee ahmedabad",
                      "full_address": "Ahmedabad, Gujarat, India",
                      "user_city_id": 3,
                      "created_at": "2024-03-19T08:12:28.130Z",
                      "updated_at": "2024-03-19T08:12:28.130Z",
                      "event_detail_id": 36,
                      "lat": "23.022505",
                      "lng": "72.5713621",
                      "pin_code": "380043"
                  },
                  {
                      "id": 50,
                      "name": "shambhu coffee ahmedabad",
                      "full_address": "Ahmedabad, Gujarat, India",
                      "user_city_id": 3,
                      "created_at": "2024-03-19T08:13:40.925Z",
                      "updated_at": "2024-03-19T08:13:40.925Z",
                      "event_detail_id": 36,
                      "lat": "23.022505",
                      "lng": "72.5713621",
                      "pin_code": "380043"
                  },
                  {
                      "id": 53,
                      "name": "light house",
                      "full_address": "Indore, Madhya Pradesh, India",
                      "user_city_id": 237,
                      "created_at": "2024-03-26T07:30:15.393Z",
                      "updated_at": "2024-03-26T07:30:15.393Z",
                      "event_detail_id": 36,
                      "lat": "22.7195687",
                      "lng": "75.8577258",
                      "pin_code": "452001"
                  }
              ],
              "schedules": [
                  {
                      "id": 70,
                      "venue_id": 53,
                      "start_time": "13:00",
                      "end_time": "17:00",
                      "schedule_date": "2024-04-21",
                      "created_at": "2024-03-26T07:30:15.397Z",
                      "updated_at": "2024-03-26T07:30:15.397Z",
                      "duration_in_mintues": 240
                  },
                  {
                      "id": 71,
                      "venue_id": 53,
                      "start_time": "11:49",
                      "end_time": "23:49",
                      "schedule_date": "2024-04-24",
                      "created_at": "2024-03-30T06:19:07.548Z",
                      "updated_at": "2024-03-30T06:19:07.548Z",
                      "duration_in_mintues": 720
                  },
                  {
                      "id": 65,
                      "venue_id": 49,
                      "start_time": "13:42",
                      "end_time": "13:42",
                      "schedule_date": "2024-03-21",
                      "created_at": "2024-03-19T08:12:28.133Z",
                      "updated_at": "2024-03-19T08:12:28.133Z",
                      "duration_in_mintues": 0
                  },
                  {
                      "id": 66,
                      "venue_id": 50,
                      "start_time": "13:43",
                      "end_time": "13:43",
                      "schedule_date": "2024-03-21",
                      "created_at": "2024-03-19T08:13:40.927Z",
                      "updated_at": "2024-03-19T08:13:40.927Z",
                      "duration_in_mintues": 0
                  }
              ],
              "event_artist": [],
              "is_fav": false,
              "ito": [],
              "youtube_links": []
          }
      }
      ],
      loading: true,
      searchValue:"",
      anchorEl: null,
      languages:[],
      languageIds:[],
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    await this.getLanguageList();
    this.getMovieEventList (this.state.searchValue);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } 

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // alert(JSON.stringify(responseJson))
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if(responseJson){
        switch (apiRequestCallId) {

          case this.getMovieEventApiCall:
            this.handleSearchReasponse(responseJson);
            break;

          case this.getAllEventApiCall:
            this.setState({eventList:responseJson.data})
            break;

          case this.getLanguageApiCall: 
            this.setState({languages:responseJson.data});
            break;

        }
        this.setState({loading:false})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getMovieEventList = async(searchBy:string) => {

    const location = await getStorageData(LocalStorageKeys.Location) as string;
    
    this.setState({loading:true})
    this.getMovieEventApiCall = callApi({
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getMovieEventEndPoint}?search=${searchBy}&languages=${this.state.languageIds.length > 0 ? '['+ this.state.languageIds.join(",")+"]":""}${location && "&location="+location}`,
      headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string ?? configJSON.guestToken },
    }, runEngine)
  }

  getLanguageList = async() => {
    this.getLanguageApiCall = callApi({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getLanguageEndPoint,
      headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string ?? configJSON.guestToken },
    }, runEngine)
  }

  handleSearchReasponse = (responseJson:any) =>{
    const movie = responseJson.data.filter((element:any)=> element.type === "movie")
        const event = responseJson.data.filter((element:any)=> element.type === "event_detail")

        const movies = this.state.searchValue === "" ? this.getTrendingData(movie) : movie;

        this.setState({movieList: movies, eventList:event, loading:false});
        this.state.searchValue === "" && this.getEventList(this.state.languageIds);
  }

  handleFilterLanguage = (langId: number) => {
    let langIds = [...this.state.languageIds];
    let isChecked = langIds.findIndex((element) => element === langId)

    if (isChecked !== -1) {
      langIds.splice(isChecked, 1)
    } else {
      langIds.push(langId)
    }
    this.setState({languageIds:langIds},()=>{
      this.getMovieEventList (this.state.searchValue);
    })
  }

  getEventList = async(languageIds : number []) =>{

    const location = await getStorageData(LocalStorageKeys.Location) as string;

    let endpoint = configJSON.getAllEventEndPoint;

    if(location) {
      endpoint = endpoint + `?location=${location}`;
    }

    if(languageIds.length > 0) {
      endpoint = endpoint + `${location?"&":"?"}languages=[${languageIds.join(",")}]`;
    }

    this.getAllEventApiCall = callApi({
      method: configJSON.httpGetMethod,
      endPoint: endpoint,
      headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string  ?? configJSON.guestToken },
    }, runEngine)
  }

  getTrendingData = (movie: IMovieAdvanchSearch []) => {
    const sortedMovies = movie.filter(item => {
      return item.attributes.search_count !== null
    })
    sortedMovies.sort(
      (a: IMovieAdvanchSearch, b: IMovieAdvanchSearch) => {
        if(a.attributes.search_count === b.attributes.search_count) return 0;
        return (a.attributes.search_count < b.attributes.search_count) ? 1 : -1;
      }
    )
    if (sortedMovies.length > 5) {
      return sortedMovies.slice(0, 5)
    }

    return sortedMovies;
  }

  handleSearchChange = (event:any) =>{
    this.setState({ searchValue: event.target.value })
    this.getMovieEventList(event.target.value )
  }

   // Handle on Change the tab
   handleTabChange = (event:any, newValue: string) => {
    this.setState({ tabValue:newValue });
  }

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ anchorEl: event?.currentTarget });

  handleClose = () => this.setState({ anchorEl: null })

  navigateToEventDetail = (identify:number) =>{
    const apiMsg = new Message(getName(MessageEnum.NavigationMessage));
    apiMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EventDetail");
    apiMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    apiMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), identify);
    this.send(apiMsg);
  }

  navigateToMovieDetail = (identify:number, is_ito_live: boolean) =>{
    const apiMsg = new Message(getName(MessageEnum.NavigationMessage));
    apiMsg.addData(getName(MessageEnum.NavigationTargetMessage), is_ito_live ? "AuctionBidding" : "MovieDetail");
    apiMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    apiMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), identify);
    this.send(apiMsg);
  }
  
  // Customizable Area End
}
