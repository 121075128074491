import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Typography,
  Grid,
  OutlinedInput,
  Slider,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled, withStyles } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { Back_arrow } from "../../contactus/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { pendingIcon, rupeeIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary:{
      main: "#404040",
      contrastText: "#404040",
    }
  },
  typography: {
    h1:{
      fontSize:24,
      fontWeight:600,
      fontFamily:"Poppins",
      color:"#fff",
      marginTop:40,
      marginBottom:18,
      '@media(max-width:600px)': {
        fontSize: "16px",
    },
    },
    h2: {
      fontSize:24,
      fontWeight:500,
      fontFamily:"Poppins",
      color:"#fff",
      '@media(max-width:600px)': {
        fontSize: "16px",
    },
    },
    h3: {
      color: "#fff",
      fontSize: 16,
      fontFamily: "Poppins",
      '@media(max-width:700px)': {
        fontSize: "14px",
      },
      '@media(max-width:500px)': {
        fontSize: "10px",
      },
    },
    h4: {
      color: "#fff",
      fontSize: 18,
      fontFamily: "Poppins",
      fontWeight:"normal",
      '@media(max-width:700px)': {
        fontSize: "14px",
      },
      '@media(max-width:500px)': {
        fontSize: "10px",
      },
    },
    h5: {
      fontSize: 14,
      fontFamily: "Poppins",
      color: "#000",
    }
  },
});

const tooltip = {
  tooltip: {
    backgroundColor: '#f8b43a',
    fontSize:14,
    fontFamily:"Poppins",
    color:"#000",
    padding:"8px 10px",
  },
  arrow: {
    color: "#f8b43a",
  }
}
const CustomTooltip = withStyles(tooltip)(Tooltip);

const SliderComponent = styled(Slider)(({ theme }) => ({
  width: '90%',
  height: '12px',
  marginLeft: 14,
  marginBottom: 15,
  objectFit: 'contain' as const,
  borderRadius: '5px',
  background: "#404040",
  '& .MuiSlider-root': {
    padding: '1px'
  },
  padding: '1px',
  '& .MuiSlider-thumb': {
    marginTop: 0,
    width:20,
    height:30,
    backgroundColor:"transparent",
    transform:"none !important",
    top:"-10px",
    boxShadow:"none",
    borderRadius:0,
    "&:hover": {
      boxShadow:"none"
    }
  },
  '& .MuiSlider-valueLabel': {
    color: "#fff"
  },
  '& .MuiSlider-rail': {
    color: "#404040",
  },
  '& .MuiSlider-track': {
    color: "#05d848",
    height: "12px",
    borderRadius: '5px',
    backgroundImage:"none !important"
  },
  '@media(max-width:600px)': {
    width:"97%"
  },
}));

const AvailableBalanceBlockBox = styled(Box) ({
    position:"relative",
    height:700,
    background:"#2d2d2d",
    padding:"32px",
    borderRadius:8,
    width:"100%",
    '@media(max-width:600px)': {
      padding:"32px 10px",
    },
})

const ProceedButton = styled(Button)({
  backgroundColor: '#ffb43a',
  padding: '12px 80px',
  borderRadius: 12,
  color: '#202020',
  textTransform: 'capitalize' as const,
  fontFamily: "Poppins",
  fontSize: 18,
  fontWeight: 600,
  textAlign: "center",
  position:"absolute",
  right:0,
  bottom:32,
  "&:hover": {
    backgroundColor: '#ffb43a',
  },
  '@media(max-width:600px)': {
    fontSize: 14,
    padding: '12px 40px',
    borderRadius: 8,
  },
})

export const MainHeadingStyle = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: 24,
  fontWeight: 600,
  color: "#FFF",
  '@media(max-width:600px)': {
    fontSize: 18,
  },
})

// Customizable Area End

import AddMoneyController, {
    Props,
    configJSON,
  } from "./AddMoneyController";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";

export default class AddMoney extends AddMoneyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.bgcolor}>
        <Loader loading={this.state.loading} />
        <ThemeProvider theme={theme}>
        <MainHeaderComp navigation = {this.props.navigation} />
          <Container maxWidth={"lg"}>
            <Grid container style={webStyle.heading} spacing={3}>
              <Grid 
                item
                container
                xs={12}
                direction="row"
                alignItems="center"
                >
                <img
                  style={webStyle.backArrow}
                  src={Back_arrow}
                  alt='backArrow'
                  data-test-id="back"
                  onClick={this.handleBackBtn}
                />
                <MainHeadingStyle
                  id="contactText">
                  {configJSON.addMoney}
                </MainHeadingStyle>
              </Grid>
            </Grid>

            <Grid container style={webStyle.upperContainer}>
              <AvailableBalanceBlockBox>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Typography variant="h2">{configJSON.availableBalance}</Typography>
                  <Typography variant="h2" style={webStyle.rupies}><span style={{fontFamily:"Roboto"}}>&#8377;</span> {this.state.totalWalletBalance.toFixed(2)}</Typography>
                </Grid>

                <Grid item xs={12} md={11} style={{position:"relative", height:"90%"}}>
                <Typography variant="h1">{this.state.isFromBank ? configJSON.transferToBankHeading : configJSON.transferToWalletHeading}</Typography>

                <Grid item xs={12}>
                  <label style={webStyle.labelStyle}>Amount</label>
                  <div style={{ marginTop: "10px", position: "relative" }}>
                    <OutlinedInput
                      onChange={(event) => this.handleChangeAmount(event, event.target.value)}
                      name="amount"
                      value={this.state.amountEntered}
                      fullWidth
                      data-test-id="amount"
                      style={webStyle.textInput} 
                      inputProps={{
                        // step: 1,
                        min: 0,
                        max: 10000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                    <label style={webStyle.inputRupees}><span style={{fontFamily:"Roboto"}}>&#8377;</span></label>
                    {this.state.amountInputError ? (
                      <span style={webStyle.formerror}>
                        {this.state.amountInputError}
                      </span>
                    ) : (<></>)}
                  </div>
                </Grid>

                <Grid item xs={12} style={webStyle.sliderBoxContainer}>
                  <SliderComponent
                    ThumbComponent={(props: any) => (
                        <>
                          <CustomTooltip title={<Typography variant="h5">+ <span style={{fontFamily:"Roboto",fontWeight:"bold"}}>&#8377;</span> {this.state.amountEntered}</Typography>}
                            arrow
                            placement="bottom">
                            <img
                              data-test-id='image'
                              id="rating-img"
                              src={rupeeIcon}
                              {...props}
                            />
                          </CustomTooltip>
                        </>
                    )}
                    aria-labelledby="input-slider"
                    value={this.state.amountEntered}
                    data-test-id="sliders"
                    onChange={this.handleChangeAmount}
                    min={0}
                    max={10000}
                  />
                  <Grid
                    container
                    xs={12} md={11}
                    direction="row"
                    justifyContent="space-between">
                    {configJSON.sliderMarks.map((elm: string, index: number) =>
                      <Typography variant="h3" key={index}>
                        {elm !== "" && <>+ <span style={{fontFamily:"Roboto"}}>&#8377;</span> {elm}</>}
                      </Typography>)}
                  </Grid>
                </Grid>

                <ProceedButton
                  data-test-id="proceedBtn"
                  color="primary"
                  disabled = {this.state.amountEntered == 0}
                  onClick={()=> this.handleProceed()}
                >
                  Proceed
                </ProceedButton>
                </Grid>
              </AvailableBalanceBlockBox>
            </Grid>

            <Dialog
              id="language"
              open={this.state.insufficiantAmount}
              PaperProps={{
                style: {
                  backgroundColor: "#404040",
                  color: "#fff",
                  padding: '25px 32px',
                  maxWidth: "456px",
                  boxSizing: "border-box"
                },
                elevation: 0,
              }}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              data-test-id="seat_dialog"
            >
              <DialogContent
                style={webStyle.dialogContent}
                data-test-id="seat_number">
                <img src={pendingIcon} alt="success"
                  color="primary"
                  style={webStyle.successImg}
                />
                <Typography variant="h1" style={{ marginTop: 24 }}>{configJSON.insufficientBalanceHeading}</Typography>
                <Typography variant="h4">
                  Your Current Balance is less than <br />
                  <span style={{fontFamily:"Roboto"}}>&#8377;</span> {this.state.amountEntered - this.state.totalWalletBalance} kindly
                  <br /> update your Wallet Balance.
                </Typography>
              </DialogContent>
              <DialogActions >
                <Button
                  data-test-id="doneBtn"
                  color="primary"
                  style={webStyle.popupBtn}
                  onClick={() => this.handleOnClickDone()}
                >
                  Done
                </Button>
              </DialogActions>
            </Dialog>

            {/* for success and failed transection popup */}
            <Dialog
              open={this.state.transectionStatusPopupContent.message}
              id="language"
              PaperProps={{
                style: {
                  boxSizing: "border-box",
                  backgroundColor: "#404040",
                  color: "#fff",
                  maxWidth: "456px",
                  padding: '25px 32px',
                },
                elevation: 0,
              }}
              aria-labelledby="alert-dialog-slide-title"
              keepMounted
              data-test-id="seat_dialog"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent
                data-test-id="seat_number"
                style={webStyle.dialogContent}
              >
                <img src={this.state.transectionStatusPopupContent.image} alt="success"
                  color="primary"
                  style={webStyle.tansactionImg}
                />
                <Typography variant="h1" style={{ marginTop: 24 }}>{this.state.transectionStatusPopupContent.message}</Typography>
              </DialogContent>
              <DialogActions >
                <Button
                  data-test-id="transactionStatusBtn"
                  color="primary"
                  style={webStyle.popupBtn}
                  onClick={() => this.navigateToWallet()}
                >
                  Done
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
          <FooterWithEvents />
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bgcolor: { 
    backgroundColor: "#121212"
  },
  upperContainer:{
    background:"#404040",
    borderRadius:"16px",
    marginTop:"32px"
  },
  backArrow:{
    heigth:"32px",
    width:"32px",
    marginRight:10,
    cursor:"pointer"
  },
  heading: {
    marginTop: 30
  },
  rupies:{
    color:"#ffb636",
  },
  inputRupees:{
    position:"absolute",
    color: "#ffffff",
    fontSize: "24px",
    fontFamily: 'Poppins',
    left:15,
    top:14
  }as CSSProperties,
  textInput: {
    color: "#ffffff",
    borderRadius: "9px",
    backgroundColor: "#404040",
    fontSize: "24px",
    height: "56px",
    paddingLeft:20,
    border: "solid 1px #b4b4b4",
  },
  labelStyle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 18,
    color:"#fff"
  },
  sliderBoxContainer: {
    boxSizing:"border-box",
    height: 126,
    padding:"20px 14px",
    marginTop:32,
    borderRadius:13,
    background: "rgb(64, 64, 64, 0.8)",
  }as CSSProperties,
  doneBtn: {
    backgroundColor: '#ffb43a',
    padding: '12px 80px',
    borderRadius: 12,
    color: '#202020',
    textTransform: 'capitalize' as const,
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    position:"absolute",
    right:0,
    bottom:32,
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  } as CSSProperties,
  formerror: {
    color: "red",
    marginTop: 10,
    fontFamily: "Poppins"
  },
  dialogContent: {
    textAlign: "center",
  } as CSSProperties,
  popupBtn: {
    padding: '12px 80px',
    width: "100%",
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    marginTop:18,
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  } as CSSProperties,
  successImg: {
    height: '48px',
    width: '48px'
  },
  tansactionImg:{
    width:253,
    height:157
  }
}
// Customizable Area End
