import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { IPushNotification, IPushNotificationResponse } from "../../../components/src/interfaces.web";
import moment from "moment";
import { redirectTo } from "../../../components/src/utilities";

const enum notificationKeywords {
  REQUEST_APPROVED = "approved",
  REQUEST_DECLINED = "declined",
  REQUEST_PENDING = "raised buy request",
  REQUEST_COMPLETED = "payment done",
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isNotificationOpen: boolean
  setUnReadNotificationCount: React.Dispatch<React.SetStateAction<number>>
  setIsNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notificationList: IPushNotification[],
  isLoading: boolean,
  authToken: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationsListApiId: string = "";
  updateNotificationsApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notificationList: [],
      isLoading: true,
      authToken: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    getStorageData(LocalStorageKeys.LoginToken).then((userToken: string) => {
      this.getNotificationsList(userToken)
      this.setState({ authToken: userToken })
    })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


    if (apiRequestCallId && responseJson !== undefined) {

      if (apiRequestCallId === this.getNotificationsListApiId && responseJson.data) {
        this.setState({ notificationList: (responseJson as IPushNotificationResponse).data, isLoading: false })
        this.props.setUnReadNotificationCount((responseJson as IPushNotificationResponse).unread_count)
      }
      if (apiRequestCallId === this.getNotificationsListApiId && responseJson.errors) {
        this.setState({ isLoading: false })
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  iconBellProps = {
    source: imgBell,
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return this.generatePluralString(interval, "Year", "Years")
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return this.generatePluralString(interval, "Month", "Months")
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return this.generatePluralString(interval, "Day", "Days")
    }
    interval = seconds / 3600;
    if (interval > 1 && (new Date().setHours(0, 0, 0, 0).toString() !== new Date(date).setHours(0, 0, 0, 0).toString())) {
      return this.generatePluralString(interval, "Hour", "Hours")
    }
    let result = " Today ";
    return result.concat(moment(date).format("hh:mm A"))
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  generatePluralString(numberToCompare: number, singularString: string, pluralString: string) {
    let staticText = " Ago";
    return Math.floor(numberToCompare) === 1 ?  ` ${Math.floor(numberToCompare)} ${singularString.concat(staticText)}` : ` ${Math.floor(numberToCompare)} ${pluralString.concat(staticText)}`;
  }

  getNotificationsList = async (token?: string) => {
    this.getNotificationsListApiId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.getDataMethod,
      endPoint: configJSON.GetNotificationsApiEndPoint,
      headers: { "token": token || this.state.authToken },
    }, runEngine)
  }

  markAsReadNotification = async (notificationId: number) => {
    this.updateNotificationsApiId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.markAsReadMethod,
      endPoint: configJSON.UpdateNotificationsApiEndPoint,
      headers: { "token": this.state.authToken },
      body: {
        notification_ids: [notificationId]
      }
    }, runEngine)
  }

  onNotificationClick = async (notificationId: number, message: string) => {
    await this.markAsReadNotification(notificationId);
    if (message.toLowerCase().includes(notificationKeywords.REQUEST_COMPLETED)) {
      redirectTo("/TransactionHistory");
    } else {
      redirectTo("/request");
    }
  }

  componentDidUpdate(prevProps: Props) { if( prevProps.isNotificationOpen !== this.props.isNotificationOpen) this.getNotificationsList()}
  // Customizable Area End
}
