import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";

interface IListWithToggleProps {
  itemsList: string[]
}

const useStyles = makeStyles(() => ({
  PrimaryColor: {
    color: "#ffb636",
    cursor: "pointer",
  }
}))

const ListWithToggle: React.FC<IListWithToggleProps> = ({ itemsList }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles()

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  if(!itemsList) return null

  const visibleItems = expanded ? itemsList : itemsList.slice(0, 2);
  const hiddenItemCount = itemsList.length - visibleItems.length;

  return (
    <>
      {
        visibleItems?.join(", ").toUpperCase( )
      }
      &nbsp;
      {
        (hiddenItemCount > 0  && !expanded) ? <span onClick={toggleExpansion} className={classes.PrimaryColor}>+{hiddenItemCount}</span> : (expanded && <span onClick={toggleExpansion} className={classes.PrimaryColor}>-</span>)
      }
    </>
  );
};

export default ListWithToggle