import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL } from "../../../components/src/utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  vogueDetail:any
  loading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VogueDetailsController extends BlockComponent<
    Props,
    S,
    SS
    > {
    // Customizable Area Start
    vogueDetailsCall=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
              vogueDetail :{},
              loading:true
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            
            if(responseJson) {
              if(apiRequestCallId === this.vogueDetailsCall){
                this.setState({vogueDetail:responseJson.data.attributes, loading:false})
              }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    handleBackBtn = () => {
      this.props.navigation.goBack();
    }

    getVogueDetails = async() => {
      const id = getLastPartOfURL()
      this.vogueDetailsCall = callApi({
        contentType: configJSON.categoryApiContentType,
        method: configJSON.httpGetType,
        endPoint: `${configJSON.vogueDetailsAPIEndPoint}/${id}`,
        headers: { "token": await getStorageData(LocalStorageKeys.LoginToken) },
      }, runEngine)
    }

    async componentDidMount() {
      this.getVogueDetails();
    }
    // Customizable Area End
}
