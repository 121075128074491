import React from 'react'
import { IItoBookingResponse } from './interfaces.web'
import { Box, Button, Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import { EventSeat, Seat } from './BookingSuccessfulTicket.web'
import { convertDateForTicket, convertToPad, getDateAndTimeFromString, getFormattedDate } from './utilities'
import { InvalidStatusImage, cancelledImage } from './assets'
import { DetailsType, FilterOption, ITOFilterOption } from './enums.web'

interface IITOBookingHistoryListingProps {
    booking: IItoBookingResponse
    message: string
    redirectToViewTicket: (id: number) => void
    mobileCard?: boolean
}


const useStyles = makeStyles(({
    DisplayFlex: {
        display: "flex",
        justifyContent: "space-between"
    },
    Ticket: {
        backgroundColor: "#252525",
        border: "solid 0.2px #b4b4b4",
        borderRadius: "12px",
        padding: "48px 32px",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        "& .status-label": {
            color: "#000",
            position: "absolute",
            fontSize: "14px",
            transform: 'rotate(-45deg)',
            textTransform: "uppercase",
            fontWeight: 500,
            fontFamily: "Poppins, sans-serif",
            height: 0,
            textAlign: "center",
            borderLeft: "solid 20px transparent",
            borderBottom: "solid 20px #ffb43a",
            borderRight: "solid 20px transparent",
            top: "48px",
            left: "-40px",
            "&::before": {
                position: 'absolute',
                content: "''",
                left: "-20px",
                bottom: "-23px",
                borderLeft: "solid 3px transparent",
                borderTop: "solid 3px #D09432",
                borderRight: "solid 3px transparent"
            },
            "&::after": {
                position: 'absolute',
                content: "''",
                right: "-19px",
                top: "20px",
                borderLeft: "solid 3px transparent",
                borderTop: "solid 3px #D09432",
                borderRight: "solid 3px transparent"
            },
            "&.status-pending": {
                borderBottom: "solid 20px #ffb43a",
                "&::after": {
                    borderTop: "solid 3px #D09432",
                },
                "&::before": {
                    borderTop: "solid 3px #D09432",
                },
            },
            "&.status-list-to-sell": {
                borderBottom: "solid 20px #ffb43a",
                top: "28px",
                left: "-33px",
                "&::after": {
                    borderTop: "solid 3px #D09432",
                },
                "&::before": {
                    borderTop: "solid 3px #D09432",
                },
            },
            "&.status-invalid": {
                borderBottom: "solid 20px #ffb43a",
                top: "11px",
                left: "-25px",
                "&::after": {
                    borderTop: "solid 3px #D09432",
                },
                "&::before": {
                    borderTop: "solid 3px #D09432",
                },
            },
            "&.status-successfull": {
                borderBottom: "solid 20px #05d848",
                top: "55px",
                left: "-45px",
                "&::after": {
                    borderTop: "solid 3px #07b23d",
                },
                "&::before": {
                    borderTop: "solid 3px #07b23d",
                },
            },
            "&.status-time-out": {
                top: "62px",
                left: "-46px",
                borderBottom: "solid 20px #ff3b30",
                "&::after": {
                    borderTop: "solid 3px #d0332a",
                },
                "&::before": {
                    borderTop: "solid 3px #d0332a",
                },
            },
        },
        "&::before": {
            content: "''",
            position: "absolute",
            top: "-1px",
            left: "50%",
            width: "40px",
            transform: "translate(-50%, 0)" as const,
            height: "20px",
            backgroundColor: "#121212",
            border: "solid 0.2px #b4b4b4",
            borderRadius: "0 0 30px 30px",
            borderTop: 'none'
        },
        "&::after": {
            content: "''",
            position: "absolute",
            bottom: "-1px",
            left: "50%",
            width: "40px",
            transform: "translate(-50%, 0)" as const,
            height: "20px",
            backgroundColor: "#121212",
            border: "solid 0.2px #b4b4b4",
            borderRadius: "30px 30px 0 0",
            borderBottom: 'none'
        }
    },
    MaxWidth: {
        maxWidth: "375px"
    },
    PosterWrapper: {
        alignItem: "center",
        justifyContent: "center",
        border: "solid 0.7px #ffb53a",
        marginRight: "20px"
    },
    MovieTitle: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#fff",
        marginBottom: "8px"
    },
    FormatAndLanguage: {
        textTransform: "uppercase"
    },
    DateTime: {
        color: "#fff",
        fontWeight: 500,
        marginTop: "8px",
        marginBottom: "8px",
        fontSize: "18px",
        "& span": {
            color: "#ffb53a"
        }
    },
    ScreenName: {
        fontSize: "18px",
        fontWeight: 500,
        color: "#fff",
        fontFamily: "Poppins"
    },
    ScreenDetails: {
        maxWidth: "375px",
        position: "relative",
    },
    SeatWrapper: {
        display: "flex",
        gap: "6px",
        justifyContent:"flex-end",
        flexWrap: "wrap",
        "& > div": {
            "& div": {
                backgroundColor: "#ffb43a !important"
            }
        }
    },
    TicketLabel: {
        fontSize: "18px",
        color: "#b4b4b4"
    },
    Divider: {
        margin: "24px 0 16px 0",
        backgroundColor: "#b4b4b4"
    },
    TicketDivider: {
        border: 'none',
        borderBottom: "dashed 2px #b1b1b1",
        margin: "22px 0"
    },
    TicketTotal: {
        fontSize: "18px",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Poppins, sans-serif",
        "& > span": {
            fontFamily: "Roboto"
        }
    },
    FW600: {
        fontWeight: 600
    },
    ScreenLabel: {
        fontSize: "18px",
        color: "#fff",
        fontFamily: "Poppins",
        textTransform: "capitalize" as const,
        marginBottom: 4
    },
    SubmitButton: {
        backgroundColor: "#ffb43a",
        color: "#202020",
        padding: "14px 36px",
        fontWeight: 600,
        fontSize: "18px",
        textTransform: "capitalize",
        maxHeight: "56px",
        border: "none",
        borderRadius: "8px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ffb43a",
            color: "#202020",
        }
    },
    Message: {
        fontSize: "16px"
    },
    PosterImage: {
        width: "212px",
        height: "270px",
        objectFit: "cover"
    },
    BookingStatusLabel: {
        position: 'absolute',
        top: 0,
        left: "50%",
        transform: "translate(-50%)"
    },
    message_block: {
        width: "50%"
    },
    mobileBookingStatusLabel: {
        position: 'absolute',
        top: "140px",
        left: "50%",
        transform: "translate(-50%)",
        zIndex: 2000
    },
    ticketWrapper: {
        backgroundColor: "#121212",
        border: "none",
        borderRadius: "12px",
        padding: "16px",
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          width: "16px",
          height: "32px",
          top: "170px",
          left: "0",
          transform: "translate(-6px, 0)" as const,
          backgroundColor: "#404040",
          borderRadius: "0 32px 32px 0",
          border: 'none'
        },
        "&::after": {
          content: "''",
          position: "absolute",
          width: "16px",
          height: "32px",
          top: "170px",
          right: "0",
          transform: "translate(6px, 0)" as const,
          backgroundColor: "#404040",
          borderRadius: "32px 0 0 32px",
          border: 'none'
        },
        "& .left__side__cut": {
          position: "absolute",
          width: "16px",
          height: "32px",
          left: "0",
          bottom: "30px",
          transform: "translate(-6px, 0)" as const,
          backgroundColor: "#404040",
          borderRadius: "0 32px 32px 0",
          border: 'none'
        },
        "& .right__side__cut": {
          position: "absolute",
          width: "16px",
          height: "32px",
          right: "0",
          bottom: "30px",
          transform: "translate(6px, 0)" as const,
          backgroundColor: "#404040",
          borderRadius: "32px 0 0 32px",
          border: 'none'
        },
        "& .status-label": {
            color: "#000",
            position: "absolute",
            fontSize: "12px",
            transform: 'rotate(-45deg)',
            textTransform: "uppercase",
            fontWeight: 500,
            fontFamily: "Poppins, sans-serif",
            height: 0,
            textAlign: "center",
            borderLeft: "solid 20px transparent",
            borderBottom: "solid 20px #ffb43a",
            borderRight: "solid 20px transparent",
            top: "38px",
            left: "-36px",
            zIndex: 2000,
            "&::before": {
                position: 'absolute',
                content: "''",
                left: "-20px",
                bottom: "-23px",
                borderLeft: "solid 3px transparent",
                borderTop: "solid 3px #D09432",
                borderRight: "solid 3px transparent"
            },
            "&::after": {
                position: 'absolute',
                content: "''",
                right: "-19px",
                top: "20px",
                borderLeft: "solid 3px transparent",
                borderTop: "solid 3px #D09432",
                borderRight: "solid 3px transparent"
            },
            "&.status-pending": {
                borderBottom: "solid 20px #ffb43a",
                "&::after": {
                    borderTop: "solid 3px #D09432",
                },
                "&::before": {
                    borderTop: "solid 3px #D09432",
                },
            },
            "&.status-list-to-sell": {
                borderBottom: "solid 20px #ffb43a",
                top: "22px",
                left: "-30px",
                "&::after": {
                    borderTop: "solid 3px #D09432",
                },
                "&::before": {
                    borderTop: "solid 3px #D09432",
                },
            },
            "&.status-invalid": {
                borderBottom: "solid 20px #ffb43a",
                top: "8px",
                left: "-24px",
                "&::after": {
                    borderTop: "solid 3px #D09432",
                },
                "&::before": {
                    borderTop: "solid 3px #D09432",
                },
            },
            "&.status-successfull": {
                borderBottom: "solid 20px #05d848",
                top: "47px",
                left: "-40px",
                "&::after": {
                    borderTop: "solid 3px #07b23d",
                },
                "&::before": {
                    borderTop: "solid 3px #07b23d",
                },
            },
            "&.status-time-out": {
                top: "53px",
                left: "-42px",
                borderBottom: "solid 20px #ff3b30",
                "&::after": {
                    borderTop: "solid 3px #d0332a",
                },
                "&::before": {
                    borderTop: "solid 3px #d0332a",
                },
            },
        },
      },
    mDottedDivider: {
        border: "none",
        borderTop: "dotted 1px #b4b4b4",
        margin: "12px 0"
    },
    mDivider: {
        backgroundColor: "#b4b4b4",
        margin: "12px 0"
    },
    photoWrapper: {
        alignItem: "center",
        justifyContent: "center",
    },
    photoImage: {
        border: "solid 0.7px #ffb53a",
        borderRadius: "2px",
        width: "120px",
        height: "150px",
    },
    bookingTitle: {
        color: "#fff",
        fontSize: "16px",
        fontWeight: 600,
        maxWidth: "140px",
        marginBottom: "8px"
    },
    releaseDate: {
        color: "#b4b4b4",
        fontSize: "12px",
    },
    ticketLanguageFormat: {
        color: "#b4b4b4",
        fontSize: "12px",
        textTransform: "uppercase",
        margin: "4px 0"
    },
    bookingDateTime: {
        color: "#fff",
        fontSize: "12px",
        fontWeight: 600,
        margin: "4px 0",
        "& .bar": {
            color: "#ffb53a"
        }
    },
    location: {
        fontSize: "12px",
        color: "#b4b4b4"
    },
    itoMessage: {
        fontSize: "12px",
        color: "#b4b4b4",
        textAlign: "center"
    },
    mSubmitButton: {
        backgroundColor: "#ffb53a",
        border: "none",
        borderRadius: "8px",
        color: "#000",
        fontWeight: 600,
        textTransform: "capitalize",
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        minHeight: "48px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ffb43a",
            color: "#202020",
        }
    },
    label: {
        color: "#b4b4b4",
        fontSize: "14px",
        textTransform: "capitalize",
    },
    value: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#FFF"
    },
    mobileDisplayFlex: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "12px"
    }
}))

const getBookingStatusLabelURL = (bookingStatus: string) => {

    switch (bookingStatus) {
        case FilterOption.Cancelled:
            return cancelledImage;

        case FilterOption.Invalid:
            return InvalidStatusImage;

        default:
            return null
    }

}

const getITOBookingStatusLabel = (bookingStatus: ITOFilterOption): React.ReactElement | null => {

    let labelString: string = "";
    let filteredClassName: string = "";

    switch (bookingStatus) {
        case ITOFilterOption.Pending:
            labelString = "Application Pending";
            filteredClassName = "status-pending"
            break;

        case ITOFilterOption.Unsuccessfull:
            labelString = "Application Unsuccessful";
            filteredClassName = "status-time-out"
            break;

        case ITOFilterOption.ListToSell:
            labelString = "Listed To Sell";
            filteredClassName = "status-list-to-sell"
            break;

        case ITOFilterOption.Successfull:
            labelString = "Application Successful";
            filteredClassName = "status-successfull"
            break;

        case ITOFilterOption.Invalid:
            labelString = "Invalid";
            filteredClassName = "status-invalid"
            break;
    }

    return (labelString ? (
        <div className={`status-label ${filteredClassName}`}>
            <span>
                {labelString}
            </span>
        </div>
    ) : null)
}


const ITOBookingHistoryListing: React.FC<IITOBookingHistoryListingProps> = (props) => {

    const classes = useStyles()
    const { booking: { attributes, type }, mobileCard } = props;

    const itoBookingData = type === "event_booking" ? {
        type: DetailsType.Event,
        image_url: attributes.poster_url && attributes.poster_url[0],
        name: attributes.event?.name,
        release_date: attributes.schedule?.schedule_date,
        duration_in_minutes: attributes.schedule?.duration_in_mintues,
        theatre_name: attributes.venue?.name,
        screen_name: attributes.tier,
        language: typeof (attributes.language) !== "string" && attributes.language?.map((item: any, ind: number) => `${item.name}`).join(", "),
        show_date_time: attributes.schedule && attributes.schedule.schedule_date +" | "+ attributes.schedule.start_time,
        seat_number: attributes.seats?.map((seat)=> (seat.seat_number)),
        booked_date: attributes.booked_date,
        number_of_seats: attributes.total_seats,
        total_amount: attributes.total_amount,
        status: attributes.status,
        is_invalid: attributes.is_invalid,
        screen_type: "",
        ito_status: attributes.ito_status,
        list_to_sell: attributes.is_list_to_sell,
        seat_allocation_type:attributes.seat_allocation_type
    }
        : {
            type: DetailsType.Movie,
            image_url: attributes.movie?.image_url,
            name: attributes.movie?.name,
            release_date: attributes.movie?.release_date,
            theatre_name: attributes.theatre_name,
            screen_name: attributes.screen_name,
            language: attributes.language,
            screen_type: attributes.screen_type,
            show_date_time: attributes.show_date_time,
            seat_number: attributes.seat_number,
            booked_date: attributes.booked_date,
            number_of_seats: attributes.number_of_seats,
            total_amount: attributes.total_amount,
            bookingStatus: attributes.status,
            is_invalid: attributes.is_invalid,
            ito_status: attributes.ito_status,
            list_to_sell: attributes.is_list_to_sell
        }

        let BookingStatusBasedOnConditions: string | undefined = ""; 

        if(itoBookingData.is_invalid){
            BookingStatusBasedOnConditions = "is_invalid"
        }else if(itoBookingData.list_to_sell){
            BookingStatusBasedOnConditions = "is_list_to_sell"
        }else{
            BookingStatusBasedOnConditions = itoBookingData.ito_status
        }

    const ITOStatusMessage = itoBookingData.ito_status === "pending"
        ? "Your application is pending. You will receive a status update shortly."
        : "Sorry! Your tickets could not be confirmed due to massive oversubscription."

        if(mobileCard) {
            return (
                <Box className={classes.ticketWrapper}>
                    {getITOBookingStatusLabel(BookingStatusBasedOnConditions as ITOFilterOption)}

                    <Box className={classes.mobileBookingStatusLabel}>
                        {
                            BookingStatusBasedOnConditions && getBookingStatusLabelURL(BookingStatusBasedOnConditions) && <img src={getBookingStatusLabelURL(BookingStatusBasedOnConditions)} width="230px" height="184px"  />
                        }
                    </Box>

                    <Box display="flex" position="relative" gridGap="12px">

                        <Box className={classes.photoWrapper}>
                            <img className={classes.photoImage} src={itoBookingData.image_url} alt="Poster-image" />
                        </Box>

                        <Box>
                            <Typography className={classes.bookingTitle}>{itoBookingData.name}</Typography>
                            <Typography className={classes.ticketLanguageFormat}>
                                {`${itoBookingData.language}, ${itoBookingData.screen_type}`}
                            </Typography>
                            <Typography className={classes.location}>{itoBookingData.theatre_name}</Typography>
                            <Typography className={classes.bookingDateTime}>
                                {itoBookingData?.show_date_time && convertDateForTicket(getDateAndTimeFromString(itoBookingData?.show_date_time)[0])}
                                <span className='bar'>&nbsp;|&nbsp;</span>
                                {itoBookingData.show_date_time && getDateAndTimeFromString(itoBookingData.show_date_time)[1]}
                            </Typography>
                            <Typography className={classes.releaseDate}>{`Release Date : ${itoBookingData?.release_date && getFormattedDate(itoBookingData?.release_date, "DD MMM, YYYY")}`}</Typography>
                        </Box>

                    </Box>

                    <Divider className={classes.mDivider} />

                    <Box className={classes.mobileDisplayFlex}>
                        <span className={classes.label}>Application Date:</span>
                        <span className={classes.label}>{getFormattedDate(itoBookingData.booked_date, "DD MMM, YYYY")}</span>
                    </Box>

                    <Box className={classes.mobileDisplayFlex}>
                        <span className={classes.label}>Screening Date:</span>
                        <span className={classes.label}>{itoBookingData.release_date && getFormattedDate(itoBookingData.release_date, "DD MMM, YYYY")}</span>
                    </Box>

                    <Divider className={classes.mDivider} />

                    <Box className={classes.mobileDisplayFlex}>
                        <span className={classes.label}>
                            {itoBookingData.ito_status === "alloted" ? "Tickets Allotted:" : "Tickets Requested:"}
                        </span>
                        <Typography className={classes.label} font-weight="500">{itoBookingData.number_of_seats && convertToPad(itoBookingData.number_of_seats)}</Typography>
                    </Box>

                    {
                        itoBookingData.ito_status === "pending" && (
                            <>
                                <Box className={classes.DisplayFlex}>
                                    <span className={classes.label}>Tickets Status:</span>
                                    <span className={classes.label}>Pending</span>
                                </Box>
                                <hr className={classes.mDottedDivider} />
                            </>
                        )
                    }

                    {
                        itoBookingData.ito_status === "alloted" && (
                            <>
                                <Box className={classes.mobileDisplayFlex}>
                                    {itoBookingData.seat_allocation_type === "manual"
                                        ? <Typography variant="body1">Seats will be assigned on a first-come, first-served basis.</Typography>
                                        : <>
                                            <span className={classes.label}>Seats</span>
                                            <Box className={classes.SeatWrapper}>
                                                {
                                                    itoBookingData.seat_number?.map((seat) => type === "event_booking" ? <EventSeat key={seat} seatLabel={seat} /> : <Seat key={seat} seatLabel={seat} />)
                                                }
                                            </Box>
                                        </>
                                    }
                                </Box>
                                <hr className={classes.mDottedDivider} />
                            </>
                        )
                    }

                    
                    {
                        itoBookingData.ito_status === "refunded" && (
                            <>
                                <Box className={classes.DisplayFlex}>
                                    <span className={classes.ScreenLabel}>Total Amount:</span>
                                    <span className={classes.ScreenLabel} font-weight="500">{convertToPad(+itoBookingData.total_amount)}</span>
                                </Box>
                                <Box className={classes.DisplayFlex}>
                                    <span className={classes.ScreenLabel}>Tickets Allotted:</span>
                                    <span className={classes.ScreenLabel} font-weight="500">00</span>
                                </Box>
                                <hr className={classes.mDottedDivider} />
                            </>
                        )
                    }

                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Typography className={classes.value}>{itoBookingData.language}</Typography>
                            <Typography className={classes.label}>Language</Typography>
                        </Box>

                        <Box>
                            <Typography className={classes.value}>{itoBookingData.screen_name}</Typography>
                            <Typography className={classes.label}>
                                {itoBookingData.type === DetailsType.Movie ? "Screen" : "Tier"}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography className={classes.value}>{itoBookingData.number_of_seats && convertToPad(itoBookingData.number_of_seats)}</Typography>
                            <Typography className={classes.label}>Tickets</Typography>
                        </Box>
                    </Box>

                    <hr className={classes.mDottedDivider} />

                    {
                        itoBookingData.ito_status === "alloted" ?
                            <Button
                                fullWidth
                                className={classes.mSubmitButton}
                                onClick={() => props.redirectToViewTicket(+props.booking.id)}>
                                View Ticket
                            </Button> :
                            <Typography className={classes.itoMessage}>{ITOStatusMessage}</Typography>
                    }

                    <hr className={classes.mDottedDivider} />

                    <span className="left__side__cut"></span>
                    <span className="right__side__cut"></span>

                </Box>
            )
        } else {   
    return (
        <Box className={classes.Ticket}>
            {getITOBookingStatusLabel(BookingStatusBasedOnConditions as ITOFilterOption)}
            <Grid container>

                <Grid item className={classes.MaxWidth}>
                    <Box className={classes.PosterWrapper}>
                        <img src={itoBookingData.image_url} className={classes.PosterImage} />
                    </Box>
                </Grid>

                <Grid item xs>
                    <Grid container>

                        <Grid item xs>
                            <Box>
                                <Typography className={classes.MovieTitle}>{itoBookingData.name}</Typography>
                                <Typography variant="h6" text-transform="uppercase" className={classes.ScreenLabel}>{`${itoBookingData.language} ${itoBookingData.screen_type && ','} ${itoBookingData.screen_type}`}</Typography>
                                <Typography variant="h6" className={classes.ScreenLabel}>{itoBookingData.theatre_name}</Typography>

                                <Typography variant="h6" className={classes.DateTime}>
                                    {itoBookingData?.show_date_time && convertDateForTicket(getDateAndTimeFromString(itoBookingData?.show_date_time)[0])}
                                    <span>&nbsp;|&nbsp;</span>
                                    {itoBookingData.show_date_time && getDateAndTimeFromString(itoBookingData.show_date_time)[1]}
                                </Typography>

                                <Typography variant="h6">{`Release Date : ${itoBookingData?.release_date && getFormattedDate(itoBookingData?.release_date, "DD MMM, YYYY")}`}</Typography>


                            </Box>
                        </Grid>

                        <Grid item xs className={classes.ScreenDetails}>
                            <Box className={classes.BookingStatusLabel}>
                                {
                                    BookingStatusBasedOnConditions && getBookingStatusLabelURL(BookingStatusBasedOnConditions) && <img src={getBookingStatusLabelURL(BookingStatusBasedOnConditions)} width="261px" height="204px" />
                                }
                            </Box>

                            <Box className={classes.DisplayFlex} mt={0.5}>
                                <span className={classes.ScreenLabel}>Application Date:</span>
                                <Typography className={classes.ScreenLabel}>{getFormattedDate(itoBookingData.booked_date, "DD MMM, YYYY")}</Typography>
                            </Box>

                            <Box className={classes.DisplayFlex} mt={0.5}>
                                <span className={classes.ScreenLabel}>Screening Date:</span>
                                <Typography className={classes.ScreenLabel} font-weight="500">{itoBookingData.release_date && getFormattedDate(itoBookingData.release_date, "DD MMM, YYYY")}</Typography>
                            </Box>

                            <Box className={classes.DisplayFlex} mt={0.5}>
                                <span className={classes.ScreenLabel}>
                                    {itoBookingData.ito_status === "alloted" ? "Tickets Allotted:" : "Tickets Requested:"}
                                </span>
                                <Typography className={classes.ScreenLabel} font-weight="500">{itoBookingData.number_of_seats && convertToPad(itoBookingData.number_of_seats)}</Typography>
                            </Box>

                            {itoBookingData.ito_status === "alloted" && (<> 
                        
                            <Box className={classes.DisplayFlex}>
                            {itoBookingData.seat_allocation_type === "manual"
                                        ? <Typography variant="h6">Seats will be assigned on a first-come, first-served basis.</Typography>
          :<>
                                <span className={classes.ScreenName}>
                                    Seats
                                </span>
                                <Box className={classes.SeatWrapper}>
                                    {
                                        itoBookingData.seat_number?.map((seat) => {return type === "event_booking" ? <EventSeat key={seat} seatLabel={seat} /> : <Seat key={seat} seatLabel={seat} />}
                                    )}
                                </Box>
                                </>
                                }
                            </Box>


                                <Divider className={classes.Divider} />

                                <Box className={classes.DisplayFlex}>
                                    <Typography className={classes.TicketLabel}>Total Amount</Typography>
                                    <Typography className={classes.TicketTotal} font-weight="500"><span>₹</span> {convertToPad(+itoBookingData.total_amount)}</Typography>
                                </Box>
                            </>
                            )}

                            {itoBookingData.ito_status === "pending" && <Box className={classes.DisplayFlex} mt={0.5}>
                                <span className={classes.ScreenLabel}>Tickets Status:</span>
                                <span className={classes.ScreenLabel} font-weight="500">Pending</span>
                            </Box>}

                            {itoBookingData.ito_status === "refunded" && <>
                                <Box className={classes.DisplayFlex} mt={0.5}>
                                    <span className={classes.ScreenLabel}>Total Amount:</span>
                                    <span className={classes.ScreenLabel} font-weight="500">{convertToPad(+itoBookingData.total_amount)}</span>
                                </Box>
                                <Box className={classes.DisplayFlex} mt={0.5}>
                                    <span className={classes.ScreenLabel}>Tickets Allotted:</span>
                                    <span className={classes.ScreenLabel} font-weight="500">00</span>
                                </Box>
                            </>}
                        </Grid>

                    </Grid>

                    <Divider className={classes.TicketDivider} />

                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={5}>
                                {itoBookingData.ito_status === "alloted" ?
                                    <Button data-test-id="view-ticket-button" className={classes.SubmitButton}
                                        onClick={() => props.redirectToViewTicket(+props.booking.id)}
                                    >View Ticket</Button>
                                    : <Typography variant="h6" align='center' className={classes.Message}>{ITOStatusMessage}</Typography>
                                }
                            </Grid>
                            <Grid item xs={5} container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Box>
                                    <Typography className={classes.ScreenLabel} variant="h6">{itoBookingData.language}</Typography>
                                    <Typography variant="h6">Language</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.ScreenLabel} variant="h6">{itoBookingData.screen_name}</Typography>
                                    <Typography variant="h6">
                                        {itoBookingData.type === DetailsType.Movie ? "Screen" : "Tier"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.ScreenLabel} variant="h6">{itoBookingData.number_of_seats && convertToPad(itoBookingData.number_of_seats)}</Typography>
                                    <Typography variant="h6">Tickets</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Box>
    )
        }
}

export default ITOBookingHistoryListing