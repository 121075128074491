import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { IEventBookingData, IEventFilter, IShowType } from "../../../components/src/interfaces.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  objEvent ?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allMenuFlag: boolean;
  allFilter: boolean;
  showMonthPickerModal: boolean;
  showMonth: boolean;
  date: any;
  month: any;
  eventDetailFlag: boolean;
  allEventData: any[];
  allCalenderData: any[];
  markedDate: any;
  allEventDataDateWise: any[];
  selectedDate: string;
  allStartDate: string;
  allEndDate: string;
  isAuthozied: boolean;
  totalAllEvent: any[];
  totalAllLocal: any[];
  allLocalDataDateWise: any[];
  token: string;
  filterEventList: any[]
  allEventList: IEventBookingData[]
  isHover:boolean;
  loading:boolean,
  currentPage:number,
  pageCount:number,
  languages: IEventFilter[],
  genersList: IEventFilter[],
  selectedLanguageIds: string[],
  selectedGenreIds: string[],
  isLanguage : HTMLElement | null,
  isGenres : HTMLElement | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEventController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllEventId: string = "";
  getAllEventApiCall:string = "";
  getLanguagesApiCall: string = "";
  getGenersApiCall: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allMenuFlag: false,
      allFilter: false,
      showMonthPickerModal: false,
      showMonth: false,
      date: new Date(Date.now() + 6 * 1000),
      eventDetailFlag: false,
      allEventData: [],
      allCalenderData: [],
      markedDate: {},
      allEventDataDateWise: [],
      selectedDate: "",
      allStartDate: "",
      allEndDate: "",
      month: new Date(Date.now() + 6 * 1000),
      isAuthozied: false,
      totalAllEvent: [],
      totalAllLocal: [],
      allLocalDataDateWise: [],
      token: "",
      isHover:false,
      allEventList: [],
      filterEventList: [],
      loading:true,
      currentPage:1,
      pageCount:1,
      languages:[],
      genersList:[],
      selectedLanguageIds :[],
      selectedGenreIds:[],
      isLanguage: null,
      isGenres: null
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getLanguagesList();
    this.getGenersList();
    this.getEventList();
    
    window.scrollTo(0,0);
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getEventList)
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
        if(responseJson) {
          switch (apiRequestCallId) {
            case this.getAllEventApiCall:
              this.setState({
                allEventList:responseJson.data,
                pageCount:responseJson.meta.pagination.total_pages,
              })
              break;
            case this.getLanguagesApiCall:
              let updateLangList = responseJson.data.map((element: IShowType) =>({label: element.name, value: element.id }))
              this.setState({languages:updateLangList});
              break;
            case this.getGenersApiCall:
              let updateGenersList = responseJson.data.map((element: IShowType) =>({label: element.event_show_type, value: element.event_show_type }))
              this.setState({genersList: updateGenersList})
              break;
          }
        }
  
        this.setState({loading:false});
      }
    // Customizable Area End
  }

  // Customizable Area Start

  checkLocalCalendarAuthetication = () => {
  };

  navigateToEventDetail = (id:any) =>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EventDetail");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(msg);
  }

  getEventList = async() =>{

    const token = (await getStorageData(LocalStorageKeys.LoginToken) as string);

    const location = await getStorageData(LocalStorageKeys.Location) as string;

    let endpoint = configJSON.getAllEventEndPoint;
    
    if(location) {
      endpoint = endpoint + `?location=${location}`;
    }

    if(this.state.selectedLanguageIds.length > 0) {
      endpoint = endpoint + `${location?"&":"?"}languages=[${this.state.selectedLanguageIds.join(",")}]`;
    }

    if(this.state.selectedGenreIds.length > 0) {
      endpoint = endpoint + `${location || this.state.selectedLanguageIds.length > 0 ?"&":"?"}show_type=[${this.state.selectedGenreIds.map((idArray: string) => `"${idArray}"`)}]`;
    }
    
    this.getAllEventApiCall = callApi({
      contentType: configJSON.contentTypeApi, 
      method: configJSON.GetMethod,
      endPoint: `${endpoint}&per=12&page=${this.state.currentPage}`,
      headers: { "token":  token || "guest_user"  },
    }, runEngine)
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getEventList)  
  }

  handleOnPageChange = (e: any) => {
    this.setState({ currentPage: e.selected + 1 }, ()=> this.getEventList())
  }

  getLanguagesList = async() => {
    this.getLanguagesApiCall = callApi({
      method: configJSON.GetMethod,
      endPoint: configJSON.getLanguageEndPoint,
      headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string || configJSON.guestToken },
    }, runEngine)
  }

  getGenersList = async() => {
    this.getGenersApiCall = callApi({
      method: configJSON.GetMethod,
      endPoint: configJSON.getGenersEndPoint,
      headers: { "token":  await getStorageData(LocalStorageKeys.LoginToken) as string || configJSON.guestToken },
    }, runEngine)
  }

  handleClickLanguageFilter = (event: React.MouseEvent<HTMLButtonElement>) =>  this.setState({ isLanguage: event?.currentTarget });

  handleCloseLanguageFilter = () => this.setState({ isLanguage: null })

  handleClickGenersFilter = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ isGenres: event?.currentTarget });

  handleCloseGenersFilter = () => this.setState({ isGenres: null })

  handleFilterLanguages = (langId: string) => {
    let langIds = [...this.state.selectedLanguageIds];
    let isChecked = langIds?.findIndex((elm) => elm === langId)

    if (isChecked !== -1) {
      langIds.splice(isChecked, 1)
    } else {
      langIds.push(langId)
    }
    this.setState({selectedLanguageIds:langIds},()=>{
      this.getEventList ();
    })
  }

  handleFilterGenres = (genrName: string) => {
    let genersName = [...this.state.selectedGenreIds];
    let isChecked = genersName?.findIndex((elm) => elm === genrName)

    if (isChecked !== -1) {
      genersName.splice(isChecked, 1)
    } else {
      genersName.push(genrName)
    }
    this.setState({selectedGenreIds:genersName},()=>{
      this.getEventList ();
    })
  }

  reseFiletrs = () =>{
    this.setState({
      loading:true,
      selectedGenreIds:[],
      selectedLanguageIds:[]
    },()=> {this.getEventList()})
  }
  // Customizable Area End
}
