import React from "react";
// Customizable Area Start

import {
  Container,
  Button,
  Typography,
  Grid,
  Card,
  Divider,
  OutlinedInput,
  Select,
  MenuItem,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Tooltip,
  InputLabel,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { createTheme, ThemeProvider,withStyles, Theme as AugmentedTheme, createStyles, styled} from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ContactusScreenController, { Props, configJSON } from "./ContactUsScreenController.web";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { attach_icon, quetion_icon, indianFlag, Back_arrow, close, plus, success, pdf } from "./assets";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import Loader from "../../../components/src/Loader.web";
import { MainHeadingStyle } from "../../cfwallet19/src/AddMoney.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography:{
    h2:{
      fontFamily: 'Poppins',
      fontSize: 20,
      fontWeight: 600,
      color: "#FFF",
      '@media(max-width:600px)': {
        fontSize: "16px",
      },
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 500,
      '@media(max-width:600px)': {
        fontSize: "14px",
      },
    },

    h4:{
      fontSize:"16px",
      color:"#fff",
      fontFamily: 'Poppins',
      textAlign:"center",
      margin:"15px",
      fontWeight:"bold",
      '@media(max-width:700px)': {
        fontSize: "14px",
    },
    },
    h5:{
      fontSize:"16px",
      color:"#fff",
      fontFamily: 'Poppins',
      textAlign:"center",
      margin: "24px 0px 12px 0",
      '@media(max-width:700px)': {
        margin:"10px 0px 0px 0px",
        fontSize: "14px",
    },
    },
    h6:{
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 500,
      color: '#b4b4b4',
      '@media(max-width:600px)': {
        fontSize: "14px",
      },
    }
  }
});

const LableMargin = styled(Box)({
  marginTop:"24px",
  '@media(max-width:600px)': {
    marginTop:"14px",
  },
})

const InputMargin = styled(Box)({
  marginTop:"16px",
  '@media(max-width:600px)': {
    marginTop:"10px",
  },
})

const SubmitQuery = styled(Grid)({
  marginTop:52,
  '@media(max-width:600px)': {
    marginTop:"30px",
  },
})

const AttachmentButton = styled(Button) ({
  borderRadius: 8,
  border: '1px solid #fff',
  backgroundColor: '#2f2617',
  padding: '12px 52px 12px 15px',
  fontFamily: 'Poppins',
  fontSize: 18,
  fontWeight: 500,
  "&:hover": {
    backgroundColor: '#2f2617',
  },
  '@media(max-width:600px)': {
    fontSize: 14,
    padding:"7px 15px 7px 15px"
  },
})

const SubmitButton = styled(Button)({
   padding: '12px 80px',
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: '#ffb43a',
    },
    '@media(max-width:600px)': {
      fontSize: 14,
      width:"100%",
      borderRadius:8
    },
})

export const customStyles = (theme: AugmentedTheme) => createStyles({
  
  paper:{
    "& .MuiList-padding": {
      padding: "0",
    },
    "& .MuiPaper-elevation8": {
      boxShadow:"0px 0px 0px 0px !important",
    },
    border: "solid 1px #929292"
  },

  dialogPaper:{
    backgroundColor: "#404040",
    color: "#fff",
    padding: '12px 32px 48px 32px',
    maxWidth:"456px",
    boxSizing:"border-box",
    '@media(max-width:700px)': {
      padding: 0,
    },
  },
  root: {
    background: "#252525",
    marginTop: 30,
    color: "#FFF",
    borderRadius: 8,
    padding: 30,
    width: "100%",
    marginBottom: 80,
    '@media(max-width:600px)': {
      padding: 10,
      marginTop: 15,
  },
    "& .MuiPaper-root":{
      backgroundColor:'#929292 !important',
      color:'#fff'
    },
    "& .MuiDivider-root": {
      backgroundColor: "#b4b4b4"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "none",
      borderWidth: 0
    },
    "& .MuiOutlinedInput-input": {
      fontSize: '16px'
    },
    "& .MuiInputBase-inputMultiline": {
      color: '#fff'
    },
    "& .MuiSelect-iconOutlined": {
      color: '#ffb43a'
    },
    "& .MuiMenuItem-root": {
      '&:nth-of-type(odd)': {
        backgroundColor: "white",
      },
      '&:nth-of-type(even)': {
        backgroundColor: "grey",
      },
    },
  
    "& .MuiSvgIcon-root": {
      color: "#ffb43a",
    },
  },
  attachImg: {
    marginTop: "37px",
    marginRight:"26px",
    border: "10px solid rgba(255, 180, 58, 0.4)",
    borderRadius: "8px",
    height:"100px",
    width:"100px",
    position:"relative",
    boxSizing:"border-box"
  },
  closeBtn:{
    width: "16px",
    height: "16px",
    position:"absolute",
    top: "-18px",
    right: "-20px",
    cursor:"pointer",
  },
  dialogClose:{
    height: '28px', 
    width: '28px', 
    cursor: 'pointer',
    '@media(max-width:700px)': {
      marginRight:5,
      marginTop:5
    },
  },
  dialogContent:{
    textAlign:"center",
  },
});

export const AttachImg = ({ ...other }) => {
  return <img src={attach_icon} data-test-id="attach" alt="images" width="24px" />;
}

const tooltip = {
  tooltip: {
    backgroundColor: '#121212',
    fontSize:14,
    fontFamily:"Poppins",
    color:"#b4b4b4",
    padding:"5px 10px",
    border:"1px solid #b4b4b4"
  },
  arrow: {
    color: "#121212",
    "&::before": {
      border: "1px solid #b4b4b4",
      }
  }
}
const CustomTooltip = withStyles(tooltip)(Tooltip);
// Customizable Area End

 class ContactusScreen extends ContactusScreenController {
  constructor(props: Props) {
    super(props);
  }
        // Customizable Area Start
        // Customizable Area End

  render() {
    const {classes} = this.props;
    return (
        // Customizable Area Start
      <div style={this.state.loading ? styles.main : {backgroundColor: "#121212"}}>
        <ThemeProvider theme={theme}>    
        <Loader loading={this.state.loading}/>      
          <MainHeaderComp navigation = {this.props.navigation} />
              <Container maxWidth={"lg"} >                
                <Grid container spacing={3} style={styles.heading}>
                  <Grid item 
                    xs={12} 
                    alignItems="center" 
                    container
                    direction="row">
                    <img
                    src={Back_arrow}
                    style={styles.backArrow}
                    alt='backArrow'
                    onClick={this.handleBackBtn}
                    data-test-id="back"
                  />
                    <MainHeadingStyle id="contactText">Contact Us</MainHeadingStyle>
                  </Grid>
                </Grid>

                <Grid container spacing={3} style={styles.mainGrid}>
                  <Grid container item xs={12} md={10}>
                    <Card className={classes.root}>
                      
                      <Grid item xs={12}>
                        <Typography variant="h2">Contact Information</Typography>
                        <Divider style={styles.divider} />
                      </Grid>
                      
                      <Grid item xs={12} md={11}>
                        <LableMargin>
                          <Typography variant="h3">Email Id*</Typography>
                        </LableMargin>
                        <InputMargin>
                          <OutlinedInput
                            onChange={(event) => this.handleEmailValue(event)}
                            name="email" value={this.state.email}                            
                            placeholder="Enter email id"
                            fullWidth
                            data-test-id="email"
                            style={styles.textInput} />
                        </InputMargin>
                        {this.state.emailError ? (
                          <span style={styles.formerror}>
                            {this.state.emailErrorMessage}
                          </span>
                        ) : (<></>)}
                        
                      </Grid>

                      <Grid item xs={12} md={11}>
                        <LableMargin>
                          <Typography variant="h3">Phone Number*</Typography>
                        </LableMargin>
                        <InputMargin>
                          <OutlinedInput
                            fullWidth
                            style={styles.textInput}
                            data-test-id="phone"
                            placeholder="Enter mobile number"
                            inputProps={{
                              style: { fontSize: '16px' },
                              maxLength: 10
                            }}
                            value={this.state.phone}
                            onChange={(event) => this.handlePhoneNumberValue(event)}
                            name="phoneNumber"                            
                            startAdornment={
                              <InputAdornment position="start">
                                <IconButton
                                  style={{
                                    borderRight: "1px solid #979797",
                                    width: "82px",
                                    height: "36px",
                                    borderRadius: "0%",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    fontSize: '10px',                                    
                                  }}
                                  id='countrycode'
                                >
                                  <img src={indianFlag} alt="indian" style={{ height: '14px', marginRight: "5px" }} />
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#ffffff"
                                    }}
                                  >
                                    +91
                                  </span>
                                  <KeyboardArrowDownIcon style={{ color: '#ffb43a' }} />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </InputMargin>

                        {this.state.phoneErrorMessage ? (
                          <span style={styles.formerror}>
                            {this.state.phoneErrorMessage}
                          </span>
                        ) : (<></>)}

                      </Grid>
                      
                      <SubmitQuery item xs={12}>
                        <Typography variant="h2">Submit a Query</Typography>
                        <Divider style={styles.divider} />
                      </SubmitQuery>
                      <Grid item xs={12} md={11}>
                        <LableMargin>
                          <Typography variant="h3">Subject*</Typography>
                        </LableMargin>
                        <InputMargin>
                          <OutlinedInput 
                            placeholder="Enter subject" 
                            data-test-id="subject"
                            fullWidth 
                            style={styles.textInput} 
                            value={this.state.subject}
                            onChange={(event) => this.handleSubjectValue(event)}                           
                            />
                        </InputMargin>
                        {this.state.subjectError ? (
                          <span style={styles.formerror}>
                            {this.state.subjectErrorMessage}
                          </span>
                        ) : (<></>)}
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <LableMargin>
                          <Typography variant="h3">Query*</Typography>
                        </LableMargin>
                        <div>
                          <Select
                            fullWidth
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              classes: {
                                paper: classes.paper
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null,
                            }}
                            value={this.state.queryOption}
                            data-test-id="query"
                            onChange={(event) => this.handleQueryOption(event)}
                            variant="outlined"
                            label="Select query"
                            displayEmpty
                            style={styles.selectLable}
                          >
                            <MenuItem style={{display:'none'}} value="">
                              <span style={styles.defaultOption}>Select query</span>
                            </MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Payment">Payment</MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Refund">Refund</MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Cancellation">Cancellation</MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Resold">Tickets Resold</MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Requested">Tickets Requested</MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Failed">Transaction Failed</MenuItem>
                            <MenuItem style={styles.optionsStyle} value="Found">Ticket Not Found</MenuItem>
                          </Select>
                        </div>
                        {this.state.queryError ? (
                          <span style={styles.formerror}>
                            {this.state.queryErrorMessage}
                          </span>
                        ) : (<></>)}
                      </Grid>


                      <Grid item xs={12} md={11}>
                        <LableMargin>
                          <Typography variant="h3">Your Message*</Typography>
                        </LableMargin>
                        <InputMargin>
                          <TextField
                            fullWidth
                            data-test-id="description"
                            InputProps={{
                              style : styles.textArea,
                              maxRows: 5
                            }}
                            variant="outlined"
                            placeholder="Describe your query in detail."
                            multiline
                            value={this.state.description}
                            onChange={(event) => this.handleMessage(event)}
                            maxRows={5}
                            minRows={5}
                          />
                        </InputMargin>
                        {this.state.descError ? (
                          <span style={styles.formerror}>
                            {this.state.descErrorMessage}
                          </span>
                        ) : (<></>)}
                      </Grid>
                
                      {this.state.mediaImage ? (
                        <Grid item style={styles.imageSection}>
                      {this.state.mediaImageUrl.map((elm: any, idx:number) => <div key={idx} className={classes.attachImg}>
                        <img
                          src={elm.includes("application/pdf") ? pdf : elm}
                          width={"100%"}
                          height={"100%"} />
                        <img
                          className={classes.closeBtn}
                          src={close}
                          data-test-id="removeimg"
                          onClick={()=> this.removeAttachment(idx)} />
                      </div>
                      )}
                        
                        <Box style={styles.addBtn}>
                        <input 
                          type="file" 
                          accept="application/pdf, image/png, image/gif, image/jpeg"
                          data-test-id="attachFile"
                          id="fileInput" 
                          value={""}
                          style={{ display: 'none' }} 
                          onChange={(event) => { this.handleMedia(event)}}
                          />
                        <InputLabel htmlFor="fileInput">
                        <img style={styles.plusBtn} src={plus} />
                        </InputLabel>
                        </Box>
                      </Grid>                      

                      ) : (
                        <Grid item xs={12} md={11} style={styles.gridStyle}>

                        <Box style={styles.fileUploadWrapper}>
                          <img src={attach_icon} style={styles.attachmentIcon} alt="attachment-icon" />
                          <span style={styles.attachmentLabel}>{configJSON.attachmentLabel}</span>

                          <input
                            style={styles.fileInput}
                            type="file"
                            accept="application/pdf, image/png, image/gif, image/jpeg"
                            data-test-id="attachFile"
                            id="fileInput"
                            value={""}
                            onChange={(event) => { this.handleMedia(event) }}
                          />
                        </Box>

                      <CustomTooltip
                        title="Maximum upload file size is 2 MB."
                        arrow
                        placement="top"
                      >
                        <div>
                          <img src={quetion_icon} data-test-id="question" alt="images" width="24px" height={"24px"} style={{ marginLeft: 16 }} />
                        </div>
                      </CustomTooltip>

                      </Grid>
                      )}

                      {this.state.attachmentsError ? (
                          <span style={styles.formerror}>
                            {this.state.attachmentsError}
                          </span>
                        ) : (<></>)}

                      <Grid
                        item
                        xs={12} md={11}
                        style={{
                          marginTop: "52px",
                          display: "flex",
                          justifyContent: "end"
                        }}
                      >
                        <SubmitButton
                          data-test-id="submitBtn"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSubmit}                                            
                        >
                          Submit
                        </SubmitButton>
                      </Grid>
                      <Grid item xs={12} md={11} style={{ marginTop: "24px", textAlign: "center" }}>
                        <Typography variant="h6">
                          You will receive a response within 24 hours.
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>

                <Dialog
                    id="language"
                    open={this.state.completedSuccsessfully}
                    PaperProps={{
                        classes:{root: classes.dialogPaper},
                        elevation: 0,
                    }}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    data-test-id="seat_dialog"

                >
                    <DialogContent className={classes.dialogContent} data-test-id="seat_number">
                    <img src={success} alt="success"
                            color="primary"
                            style={styles.successImg}
                        />
                        <Typography variant="h5">
                        Your message has been submitted. 
                        <br/>Our Customer care Team will get in touch 
                        within 24 hours.
                        </Typography>
                        <Typography variant="h4">Your Query ID: {this.state.query_id}</Typography>
                       
                    </DialogContent>
                    <DialogActions >
                    <Button
                          data-test-id="doneBtn"
                          variant="contained"
                          color="primary"
                          style={styles.doneBtn}
                          onClick={this.handleDone}                                            
                        >
                          Done
                        </Button>
                    </DialogActions>
                </Dialog>
              </Container>
          <FooterWithEvents />
        </ThemeProvider>
      </div>
        // Customizable Area End

//Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const styles = {
  main: {
    backgroundColor: "#121212",
    overflow:"hidden"
  },
  heading: {
    marginTop: 30
  },
  formerror: {
    color: "red",
    marginTop: 10,
    fontFamily: "Poppins"
  },
  textInput: {
    border: "solid 1px #b4b4b4",
    borderRadius: "9px",
    backgroundColor: "#404040",
    color: "#ffffff",
    fontSize: "16px",
    height: "56px",
  },
  defaultOption:{
    fontFamily:'Poppins',
    fontSize: '16px',
    color:'#b4b4b4'
  },
  optionsStyle: {
    backgroundColor: "#404040",
    color: '#ffffff',
    borderBottom: "1px solid #979797",
    padding:"10px 16px"
  },
  label: {
    marginTop: "24px"
  },
  selectLable: {
    borderRadius: "9px",
    color: "#ffffff",
    border: "solid 1px #b4b4b4",
    marginTop: "15px  ",
    padding: 0,
    background: "#404040"
  },
  textArea: {
    border: "solid 1px #b4b4b4",
    borderRadius: "9px",
    backgroundColor: "#404040",
    color: "#ffff",    
    fontSize: "16px",
    fontFamily: 'Poppins'
  },
  mainGrid: {
    marginBottom: '80px'
  },
  labelStyle: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 500
  },
  doneBtn:{
    padding: '12px 80px',
    width:"100%",
    backgroundColor: '#ffb43a',
    borderRadius: 12,
    textTransform: 'capitalize' as const,
    color: '#202020',
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: '#ffb43a',
    }
  },
  receiveLine: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 500,
    color: '#b4b4b4',
  },
  gridStyle: {
    marginTop: "24px",
    alignItems: 'center',
    display: 'flex'
  },
  plusBtn:{
    width: "37px",
    height: "37px",
  },
  addBtn:{
    marginTop: "37px",
    width: "100px",
    height: "100px",
    backgroundColor: "rgba(255, 180, 58, 0.4)",
    borderRadius: "8px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    cursor:"pointer",
  },
  imageSection: {
    display: "flex"
  },
  divider:{ 
    marginTop: "14px", 
    background: "#b4b4b4" 
  },
  backArrow:{
    heigth:"32px",
    width:"32px",
    marginRight:10
  },
  successImg:{
    height: '48px', 
    width: '48px'
  },
  fileUploadWrapper: {
    padding: "12px 38px 12px 12px",
    border: "solid 1px #fff",
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative" as const,
    display: "flex",
    alignItems: "center",
    gap: "12px",
    backgroundColor: "#2f2617"
  },
  attachmentIcon: {
    width: "24px",
    height: "24px",
  },
  attachmentLabel: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: "#FFF",
    textTransform: "uppercase" as const
  },
  fileInput: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // bottom: 0,
    opacity: 0
  }
}

export default withStyles(customStyles, { withTheme: true })(ContactusScreen);
export { ContactusScreen };
// Customizable Area End
