import React from "react";

// Customizable Area Start
import { Box, Button, CircularProgress, Container, CssBaseline, Fade, ThemeProvider, Tooltip, Typography, createTheme, styled } from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import { PlayCircleFilledWhite } from "@material-ui/icons";
import { filled_heart, heart } from "../../splashscreen/src/assets";
import AddFavouritesWeb from "../../favourites/src/AddFavourites.web";
import UserSignUp from "../../social-media-account-registration/src/UserSignUp.web";
import { DetailsType } from "../../../components/src/enums.web";
import KycDialogeBox from "../../cfwallet19/src/KycDialogeBox.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffb43a",
    },
    secondary: {
      main: "#fff",
    },
    background: {
      default: "#121212",
      paper: "#404040"
    },
    text: {
      primary: "#FFF"
    }
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h2: {
      fontSize: "32px",
      fontWeight: 500,
      color: "#FFF"
    },
    h3: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#FFF",
    },
    h5: {
      fontSize: "16px",
      color: "#FFF",
      margin: "16px 0"
    },
    h6: {
      fontSize: "12px",
    },
    body1: {
      fontSize: "10px",
    },
    body2: {
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: 1.6
    },
    subtitle1: {
      fontSize: "14px",
      fontWeight: 600
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize",
        fontSize: "12px",
        backgroundColor: "#121212",
        border: "solid 0.5px #ffb43a",
        maxHeight: "34px",
        fontWeight: 500,
        "&.MuiButton-root:hover": {
          backgroundColor: "#ffb43a",
          color: "#000",
        }
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#121212",
        color: "#b4b4b4",
        border: "solid 1px #b4b4b4",
        position: 'relative',
        fontFamily: "Poppins"
      },
      arrow: {
        '&:before': {
          border: 'solid 1px #b4b4b4',
        },
        color: '#121212',
      },
    }
  }
})
// Customizable Area End

import ItoListingController, { Props, configJSON } from "./ItoListingController.web";

export default class ItoListing extends ItoListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainWrapper>

          <Box className="header_wrapper">
            <MainHeaderComp navigation={this.props.navigation}  />
          </Box>

          <Box flex={1}>

            <Container className="container__wrapper" data-test-id="container-wrapper">
              {
                this.state.isApiLoading ? (
                  <Fade in={this.state.isApiLoading} unmountOnExit>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress className="circular-progress" />
                    </Box>
                  </Fade>
                ) : (
                  <>

                    {
                      this.state.itoListing.length ? (
                        <Box className="grid_wrapper">
                          {
                            this.state.itoListing.map((cardDetails) => (
                              <Box className="card" data-test-id="card" key={cardDetails.id}>
                                <img className="card_image" src={this.getPosterUrl(cardDetails.attributes)} />

                                {
                                  (cardDetails.type as DetailsType) === DetailsType.Movie && (
                                    <Box className="card_actions_wrapper">

                                      <AddFavouritesWeb
                                        navigation={this.props.navigation}
                                        id="favourites"
                                        addFavourites={{
                                          heartIcon: heart,
                                          filledHeart: filled_heart,
                                          imageStyle: {
                                            width: "14px",
                                            height: "13px",
                                            cursor: "pointer"
                                          },
                                          favouriteable_id: `${cardDetails.id}`,
                                          favouriteable_type: "BxBlockCategories::Movie",
                                          tooltipDirection: "left-end",
                                          isFavourite: cardDetails.attributes.is_favourite
                                        }}
                                      />

                                      <Tooltip placement="left" title={configJSON.TrailerTitle} arrow>
                                        <PlayCircleFilledWhite data-test-id="redirect-to-trailer" className="trailer_icon" onClick={() => this.redirectToTrailersScreen(cardDetails.id)} />
                                      </Tooltip>

                                    </Box>
                                  )
                                }

                                <Box className="card_details">
                                  <Typography variant="subtitle1">
                                    {cardDetails.attributes.name}
                                  </Typography>
                                  <Typography variant="body1">
                                    {this.getLanguageList(cardDetails.attributes.languages)}
                                    <span className="grey_color_text">&nbsp;(UA)</span>
                                  </Typography>
                                  <Typography variant="body2" className="category_type">
                                    {this.getCategoryList(cardDetails.attributes.category_type || cardDetails.attributes.show_type.join(", "))}
                                  </Typography>
                                  <Button data-test-id="apply-for-ito-btn" fullWidth className="apply_btn" onClick={() => this.handleRedirect(cardDetails.id, cardDetails.type as DetailsType)}>{configJSON.ApplyForIto}</Button>
                                </Box>

                              </Box>
                            ))
                          }
                        </Box>
                      ) : (
                        <Typography variant="h6" className="message">{configJSON.ITOMessage}</Typography>
                      )
                    }


                  </>
                )
              }

            </Container>
          </Box>

          <Box className="footer_wrapper">
            <FooterWithEvents />
          </Box>

          {this.state.openSignup && (
            <UserSignUp navigation={this.props.navigation} id="ratings" showSignup={this.closeSignup} />
          )}

          {this.state.openKycPendingDialog && <KycDialogeBox navigation={this.props.navigation} id="kyc-ito-listing" handleOnClose={this.handleKycClose} />}

        </MainWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "& .backdrop": {
    color: "#ffb43a",
    zIndex: 2000
  },
  "& .circular-progress": {
    marginTop: "20px",
    color: "#ffb43a"
  },
  "& .header_wrapper": {
    zoom: 0.8
  },
  "& .footer_wrapper": {
    zoom: 0.8
  },
  "& .container__wrapper": {
    maxWidth: "1030px",
    "& .grid_wrapper": {
      marginTop: "22px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      columnGap: "18px",
      rowGap: "22px",
      "& .card": {
        aspectRatio: "0.62",
        boxSizing: "border-box",
        border: "solid 0.5px #ffb636",
        position: "relative",
        "& .card_image": {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        },
        "& .card_actions_wrapper": {
          position: "absolute",
          top: "8px",
          right: "8px",
          background: "rgba(0,0,0,0.7)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "8px 6px",
          gap: "8px",
          "& .trailer_icon": {
            fontSize: "1.15rem",
            color: "#ffb43a",
            cursor: "pointer"
          },
          "& .rating_icon": {
            width: "18px",
            height: "18px",
            cursor: "pointer"
          }
        },
        "& .card_details": {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0,
          transition: "all 0.2s ease-in-out",
          padding: "8px",
          background: "linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 0.6), #362203)",
          backdropFilter: "blur(0.8px)",
          "& .apply_btn": {
            marginTop: "4px"
          },
          "& .grey_color_text": {
            color: "#b4b4b4"
          },
          "& .category_type": {
            textTransform: "capitalize"
          }
        },
        "&:hover .card_details": {
          opacity: 1
        },
      },
      "@media(max-width: 900px)": {
        gridTemplateColumns: "1fr 1fr 1fr",
      },
      "@media(max-width: 600px)": {
        gridTemplateColumns: "1fr 1fr",
      }
    },
    "& .message": {
      margin: "16px",
      textAlign: "center",
      color: "#aeacac"
    }
  }
})
// Customizable Area End
