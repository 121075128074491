import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Toolkit";
import { SelectOption } from "../../../components/src/SelectDropdown.web";

// Customizable Area Start
import moment from "moment";
import { LocalStorageKeys } from "../../../components/src/enums.web";


type SeatStatusType = 'available' | 'fast_filling' | 'sold_out';
interface Availability {
  seat_type: string,
  seat_status: SeatStatusType,
  price: number,
}
interface ScreeningData {
  id: string;
  type: string;
  attributes: {
    theatre_name: string;
    screen_name: string;
    slot_time: string;
    slot_date: string;
    theatre_id: number;
    is_favourite:boolean;
    availability: Availability[]
  };
}

interface TimeSlotByScreens {
  screenName: string;
  timeSlots: { screenId: string; timeSlot: string }[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  languageOptionsList: SelectOption[]
  formatOptionsList: SelectOption[]
  termsModel: boolean;
  movieId: number | null;
  movieDetail: {
    id: string;
    type: string;
    attributes: {
      name: string;
      categoryType: string[];
      durationInMinutes: number;
      releaseDate?: Date;
      description?: string;
      status?: string;
      imageUrl?: string;
      trailers: string[];
      youtube_links: [];
      isFavourite?: boolean;
    }
  }
  filterData: {
    selectedDate: Date,
    selectedLanguageId: number,
    selectedFormatId: number,
  }
  location: string,
  timeSlotList: {
    screenName: string;
    theaterName: string;
    theatreId:number;
    isFavourite:boolean;
    timeSlots: {
      timeSlot: string;
      screenId: number;
      availability: Availability[];
    }[];
  }[],
  searchTimeSlotList: {
    screenName: string;
    theaterName: string;
    theatreId:number,
    isFavourite:boolean,
    timeSlots: {
      timeSlot: string;
      screenId: number;
      availability: Availability[];
    }[];
  }[],
  selectedTimeSlotScreen: {
    screenId: number,
    screenName: string,
    theaterName: string,
    selectedTimeSlot: string,
  },
  isSearch: boolean,
  isMovieTimeSlotApiLoading: boolean,
  isInitialLoading: boolean,
  isSoldOutOpen: boolean,
  termsAndConditionDialogContent: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RegularBookingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsAndContions: any
  movieDetailCallId: string = '';
  timeSlotApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      termsModel: false,
      movieId: null,
      movieDetail: {
        id: '',
        type: '',
        attributes: {
          name: '',
          categoryType: [],
          durationInMinutes: 0,
          trailers: [],
          youtube_links: []
        },
      },
      languageOptionsList: [],
      formatOptionsList: [],
      filterData: {
        selectedDate: moment(new Date()).startOf('day').toDate(),
        selectedLanguageId: 0,
        selectedFormatId: 0,
      },
      location: '',
      timeSlotList: [],
      searchTimeSlotList: [],
      selectedTimeSlotScreen: {
        screenId: -1,
        theaterName: '',
        screenName: '',
        selectedTimeSlot: '',
      },
      isSearch: false,
      isMovieTimeSlotApiLoading: true,
      isInitialLoading: true,
      isSoldOutOpen: false,
      termsAndConditionDialogContent: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTermsAndContions) {
        this.handleReceive(resJson);
      }
      if (apiRequestCallId === this.movieDetailCallId) {
        this.handleMovieDetail(resJson)
      }
      if (apiRequestCallId === this.timeSlotApiCallId) {
        this.createTimeSlotData(resJson)
      }
    } else {
      runEngine.debugLog("GOIT");
    }

    // Customizable Area End
  }

  getMovieDetailsById = () => {
    this.movieDetailCallId = callApi({
      contentType: configJSON.content,
      method: configJSON.apiGetMethodType,
      endPoint: `bx_block_categories/movies/${this.state.movieId}`,
      headers: { "token": configJSON.guestToken },
    }, runEngine)
  }

  getTimeSlotsByMovieId = async() => {

    this.setState({ isMovieTimeSlotApiLoading: true })
    const {
      movieId,
      location,
      filterData: {
        selectedDate,
        selectedFormatId,
        selectedLanguageId
      }
    } = this.state;

    const authToken = await getStorageData(LocalStorageKeys.LoginToken) as string

    this.timeSlotApiCallId = callApi({
      contentType: configJSON.content,
      method: configJSON.apiGetMethodType,
      endPoint: `${configJSON.timeSlotsApiEndpoint}?language_id=${selectedLanguageId}&location=${location}&screen_type_id=${selectedFormatId}&slot_date=${moment(selectedDate).format("YYYY-MM-DD")}&movie_id=${movieId}          
`,
      headers: { "token": authToken ?? configJSON.guestToken },

    }, runEngine)
  }

  handleMovieDetail = (resJson: any) => {

    const { data: { id, type, attributes } } = resJson;
    this.setState({
      movieDetail: {
        id,
        type,
        attributes: {
          name: attributes['name'],
          categoryType: attributes['category_type'].split(", "),
          durationInMinutes: attributes['duration_in_minutes'],
          releaseDate: new Date(attributes['release_date']),
          description: attributes['description'],
          status: attributes['status'],
          imageUrl: attributes['image_url'],
          trailers: attributes['trailers'],
          youtube_links: attributes['youtube_links'],
          isFavourite: attributes['is_favourite'],
        }
      },
      languageOptionsList: attributes['languages'].map((language: { "language_id": string | number, "language_name": string }) => ({
        value: language['language_id'],
        label: language['language_name'],
      })),
      formatOptionsList: attributes['screen_types'].map((screen: { "screen_type_id": string | number, "screen_type": string }) => ({
        value: screen['screen_type_id'],
        label: screen['screen_type'],
      })),
    })

  }

  getSortedTimeSlot = (screen: {
    timeSlot: string;
    screenId: number;
    availability: Availability[]
  }[]) => {
    const sortedScreen = [...screen];
    sortedScreen.sort((a, b) => a.timeSlot.localeCompare(b.timeSlot));
    return sortedScreen;
  }

  createTimeSlotData = (resJson: any) => {
    const screeningsByScreen: {
      [key: string]: {
        timeSlot: string,
        screenId: number,
        availability:Availability[],
        theatreId:number,
        isFavourite:boolean,
        screenName: string,
      }[]
    } = {};

    // Loop through the data array and group the screenings by screen_name
    resJson.data.forEach((screening: ScreeningData) => {
      const { id, attributes } = screening;
      const { screen_name, slot_time, theatre_name, availability, theatre_id, is_favourite, slot_date } = attributes;

      // check if this slot is past time or not
      const slotDateTime = moment(`${slot_date} ${slot_time}`, 'YYYY-MM-DD HH:mm');
      let currentDateTime = moment(moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
      if(slotDateTime.isBefore(currentDateTime)) {
        // if it is a past time then will not include in listing
        return;
      }

      if (!screeningsByScreen[theatre_name]) {
        screeningsByScreen[theatre_name] = [{
          screenId: +id,
          timeSlot: slot_time,
          screenName: screen_name,
          availability: availability,
          theatreId:theatre_id,
          isFavourite:is_favourite
        }];
      }
      else {
        screeningsByScreen[theatre_name].push({ screenId: +id, timeSlot: slot_time, screenName: screen_name, availability: availability, theatreId:theatre_id, isFavourite:is_favourite });
      }
    });


    // Convert the grouped object into an array of ScreenType
    const screenTypeArray = Object.keys(screeningsByScreen).map((theatre_name) => ({
      screenName: screeningsByScreen[theatre_name][0]?.screenName,
      theaterName: theatre_name,
      theatreId:screeningsByScreen[theatre_name][0]?.theatreId,
      isFavourite:screeningsByScreen[theatre_name][0]?.isFavourite,
      timeSlots: this.getSortedTimeSlot(screeningsByScreen[theatre_name]),      
    }))

    this.setState({ 
      timeSlotList: screenTypeArray, 
      searchTimeSlotList: screenTypeArray,
      isMovieTimeSlotApiLoading: false,
      isInitialLoading: false
    })
  }

  handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState((prevState) => ({
      filterData: {
        ...prevState.filterData,
        selectedLanguageId: event.target.value as number,
      }
    }), () => this.getTimeSlotsByMovieId())
  }

  handleFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState((prevState) => ({
      filterData: {
        ...prevState.filterData,
        selectedFormatId: event.target.value as number,
      }
    }), () => this.getTimeSlotsByMovieId())
  }

  handleDateChange = (date: Date) => {
    this.setState((prevState) => ({
      filterData: {
        ...prevState.filterData,
        selectedDate: date,
      }
    }), () => this.getTimeSlotsByMovieId())
  }

  selectTimeSlot = (screenId: number, theaterName: string, screenName: string, timeSlot: string) => {
    this.setState({ termsModel: true, selectedTimeSlotScreen: { screenId, theaterName, screenName, selectedTimeSlot: timeSlot } })
  }

  handleNavigateToTrailer = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Trailers");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.movieId);
    this.send(msg);
  }

  handleReceive(resJson: any) {
    this.setState({ termsAndConditionDialogContent: resJson.data[0].attributes.description })
  }

  acceptTerms = () => {
    const DetailsToStore = {
      movieName: { ...this.state.movieDetail.attributes, movieId: this.state.movieDetail.id },
      selectedScreenDetail: {
        screenDetail: this.state.selectedTimeSlotScreen,
        selectedLanguage: this.state.languageOptionsList.find(lan => lan.value === this.state.filterData.selectedLanguageId),
        selectedFormat: this.state.formatOptionsList.find(lan => lan.value === this.state.filterData.selectedFormatId),
        selectedDate: this.state.filterData.selectedDate,
      },
      screenTimeSlots: this.state.timeSlotList.find(element => element.theaterName === this.state.selectedTimeSlotScreen.theaterName),
    }
    localStorage.setItem('selectedTimeSlotDetail', JSON.stringify(DetailsToStore))
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'SeatSelection');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.selectedTimeSlotScreen.screenId);
    this.send(msg);
  }

  termsModelClose = () => {
    this.setState({ termsModel: false })
  }

  // Customizable Area Start
  TermsAndContions = () => {
    const endPoint = configJSON.termsAndConditionsEndPoint
    const method = "GET"
    const header = {
      "Content-Type": 'application/json',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsAndContions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  onSearch = () => {
    this.setState({ isSearch: true });
  }

  onSearchCinema = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filterData = this.state.searchTimeSlotList.filter((item) => item.theaterName.toLowerCase().includes(event.target.value.toLowerCase()))
    this.setState({ timeSlotList: filterData })
  }

  handleOnCloseSearchBar = () => {
    this.setState({ isSearch: false });
  }

  async componentDidMount() {

    const selectedLanguageId = await JSON.parse(await getStorageData('language_id', false))
    const selectedFormatId = await JSON.parse(await getStorageData('screen_type_id', false))
    const movieId = +(this.props.navigation.getParam("id"));
    const location = await getStorageData('location', false)

    this.setState((prevState) => ({
      location,
      movieId,
      filterData: {
        ...prevState.filterData,
        selectedLanguageId,
        selectedFormatId,
      }
    }), () => {
      this.getMovieDetailsById()
      this.getTimeSlotsByMovieId()
    })
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    if (loginToken) {
      await setStorageData("isGuest", 'false')
    } else {
      await setStorageData("isGuest", 'true')
    }
    this.TermsAndContions()

    window.scrollTo(0, 0)
  }

  initialDate = (): Date => moment(new Date()).startOf('day').toDate()

  // Customizable Area End
}
