import React from "react";

// Customizable Area Start
import { 
  Backdrop,
  Box, 
  Button, 
  CircularProgress, 
  Collapse, 
  Container, 
  CssBaseline, 
  Divider, 
  Fade, 
  Grid, 
  Hidden, 
  IconButton, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Paper, 
  ThemeProvider, 
  Typography, 
  createTheme, 
  styled 
} from "@material-ui/core";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import { ArrowBackIosOutlined, ExpandLess, ExpandMore } from "@material-ui/icons";
import { convertDateForTicket, convertTo12HourFormat, convertToPad, goToPrevPage } from "../../../components/src/utilities";
import RFHDialog from "../../../components/src/RFHDialog";
import { BackArrow, PaymentSuccessfull, WalletLow } from "./assets";


const theme = createTheme({
  palette: {
      primary: {
          main: "#ffb43a",
      },
      background: {
        default: "#121212",
        paper: "#40404066"
      },
      text: {
        primary: "#FFF"
      }
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    h4: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "24px",
      fontWeight: 600,
      paddingBottom: "12px"
    },
    h6: {
      fontSize: "18px",
      textTransform: 'uppercase',
      marginBottom: "4px"
    },
    subtitle1: {
      fontSize: "18px",
      marginBottom: "4px",
    },
    subtitle2: {
      fontSize: "18px",
      fontWeight: 500,
      marginBottom: "4px",
    },
    body1: {
      fontSize: "18px",
      "@media (max-width: 600px)": {
        fontSize: "14px"
      }
    },
    body2: {
      fontSize: "16px",
      color: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize"
      },
    },
  },
});

const RFHPaper = styled(Paper)({
  padding: "32px",
  borderRadius: "0",
  "&.movie-details": {
    borderRadius: "8px"
  }
})

const RFHList = styled(List)({
  padding: 0,
  "& > li": {
    padding: "4px 0",
    "& .MuiTypography-root": {
      fontFamily: "Poppins, Roboto, sans-serif"
    },
    "& span.seat-tier": {
      textTransform: 'capitalize'
    },
    "& span.rupee-icon": {
      fontFamily: "Roboto, sans-serif",
    },
    "&:first-child": {
      paddingTop: 0
    },
    "@media (max-width: 600px)": {
      padding: 0,
      "& p": {
        fontWeight: 600
      }
    }
  },
  "&.collapse-list > li": {
    padding: "2px 0",
    "& .MuiTypography-root": {
      fontFamily: "Poppins, Roboto, sans-serif",
      fontSize: "16px",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    "@media (max-width: 600px)": {
      padding: 0,
    }
  }
})

const RFHButton = styled(Button)({
  textTransform: "capitalize",
  color: "#000",
  backgroundColor: "#ffb43a",
  borderRadius: "12px",
  height: "56px",
  fontWeight: 600,
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#ffb43a"
  }
})

const RFHListItemText = styled(ListItemText)({
  "& span": {
    display: "flex",
    alignItems: "center",
    "& .MuiListItemIcon-root": {
      margin: "4px 0 0 4px"
    }
  }
})
// Customizable Area End

import ConfirmPaymentController, { 
  Props,
  configJSON
} from "./ConfirmPaymentController";

export default class ConfirmPayment extends ConfirmPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ConfirmPaymentTitle = <Typography variant='h4' align="center">{`Confirm Payment`}</Typography>
  
  PaymentSuccessfulContent = (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" justifyContent="center" p={0}>
        <img src={PaymentSuccessfull} height="48px" width="48px" />
      </Box>
      <Typography variant="body2" align="center" style={webStyle.FW600}>
        {configJSON.PaymentSuccessful}
      </Typography>
    </Box>
  )

  ConfirmPaymentActions = <RFHButton data-test-id="proceed-payment-btn" fullWidth onClick={this.handleConfirmPayment}>{configJSON.Proceed}</RFHButton>

  LowWalletBalanceActions = <RFHButton data-test-id="add-money-to-wallet-btn" fullWidth onClick={this.redirectToWalletScreen}>{configJSON.UpdateWalletBalance}</RFHButton>

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Hidden smDown>
        <MainHeaderComp navigation = {this.props.navigation} />
        </Hidden>
        <CssBaseline />

        <Backdrop style={webStyle.BackDrop} open={this.state.openBackdropLoadingOpen}>
          <CircularProgress style={webStyle.CircularProgress} />
        </Backdrop>

        <Hidden xsDown>
        <Container>

          <Box display="flex" alignItems="center" mt={4} mb={4}>
            <IconButton style={webStyle.BackButton} data-test-id="button" onClick={goToPrevPage}>
              <ArrowBackIosOutlined style={webStyle.BackButtonIcon} />
            </IconButton>

            <Typography variant="h4">{configJSON.RequestApprovedTitle}</Typography>
          </Box>

          <Grid container spacing={4}>

            <Grid item xs>
              <RFHPaper className="movie-details">

                {
                  this.state.isApiLoading ? (
                    <Fade in={this.state.isApiLoading} unmountOnExit>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress style={webStyle.CircularProgress} />
                      </Box>
                    </Fade>
                  ) : (

                    <Grid container>
                      <Grid item xs style={webStyle.MaxWidth}>
                        <Box style={webStyle.PosterWrapper}>
                          <img src={this.state.bookingData.attributes.movie_image} style={webStyle.PosterImage} alt="Poster-movie" />
                        </Box>
                      </Grid>
                      <Grid item xs>

                        <Typography variant="h5">{this.state.bookingData.attributes.movie_name}</Typography>
                        <Typography variant="h6">{this.state.bookingData.attributes.movie_languages.concat(", " + this.state.bookingData.attributes.movie_screen_types)}</Typography>
                        <Typography variant="subtitle1">
                          {convertDateForTicket(this.state.bookingData.attributes.slot_date)}
                          <span style={webStyle.MainColor}>&nbsp;|&nbsp;</span>
                          {convertTo12HourFormat(this.state.bookingData.attributes.slot_time)}
                        </Typography>
                        <Typography variant="subtitle1">{this.state.bookingData.attributes.theatre_name}</Typography>

                      </Grid>
                    </Grid>

                  )
                }

              </RFHPaper>
            </Grid>

            <Grid item xs style={webStyle.PriceDataMaxWidth}>
              <RFHPaper>

                {
                  this.state.isApiLoading ? (
                    <Fade in={this.state.isApiLoading} unmountOnExit>
                      <Box display="flex" justifyContent="center" data-test-id="container-test">
                        <CircularProgress style={webStyle.CircularProgress} />
                      </Box>
                    </Fade>
                  ) : (
                    <>
                        <RFHList>

                          <ListItem>
                            <ListItemText>{configJSON.NumberOfTickets}</ListItemText>
                            <Typography>{convertToPad(this.state.bookingData.attributes.ticket_requested)}&nbsp;-&nbsp;<span className="seat-tier">{this.state.bookingData.attributes.seat_type}</span></Typography>
                          </ListItem>

                          <ListItem>
                            <ListItemText>{configJSON.RequestPricePerTicket}</ListItemText>
                            <Typography>
                              <span className="rupee-icon">₹&nbsp;&nbsp;</span>
                              {this.state.bookingData.attributes.ticket_price}
                            </Typography>
                          </ListItem>

                          <ListItem>
                            <ListItemText>{configJSON.SubTotal}</ListItemText>
                            <Typography><span className="rupee-icon">₹&nbsp;&nbsp;</span>{this.state.bookingData.attributes.sub_total}</Typography>
                          </ListItem>

                          <ListItem style={webStyle.ConveneinceButton}  onClick={() => this.setState((prevState) => ({ isConvenienceFeeOpen: !prevState.isConvenienceFeeOpen }))}>
                            <RFHListItemText style={webStyle.DisplayFlex}>
                             {configJSON.ConvenienceFees}
                              <ListItemIcon style={webStyle.MainColor}>
                                {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                              </ListItemIcon>
                            </RFHListItemText>
                            <Typography><span className="rupee-icon">₹&nbsp;&nbsp;</span>{this.state.bookingData.attributes.convenience_fees}</Typography>
                          </ListItem>

                          <Collapse in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>

                            <RFHList className="collapse-list" disablePadding>
                              <ListItem>
                                <ListItemText>{configJSON.BaseAmount}</ListItemText>
                                <Typography><span className="rupee-icon">₹&nbsp;&nbsp;</span>{this.state.bookingData.attributes.base_amount}</Typography>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  {(configJSON.CGST as string).concat(`${this.state.bookingData.attributes.cgst_percent} %`)}
                                </ListItemText>
                                <Typography><span className="rupee-icon">₹&nbsp;&nbsp;</span>{this.state.bookingData.attributes.cgst}</Typography>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  {(configJSON.SGST as string).concat(`${this.state.bookingData.attributes.sgst_percent} %`)}
                                </ListItemText>
                                <Typography><span className="rupee-icon">₹&nbsp;&nbsp;</span>{this.state.bookingData.attributes.sgst}</Typography>
                              </ListItem>
                            </RFHList>

                          </Collapse>

                          <Divider style={webStyle.Divider} />

                          <ListItem>
                            <ListItemText>{configJSON.TotalPayableAmount}</ListItemText>
                            <Typography><span className="rupee-icon">₹&nbsp;&nbsp;</span>{this.state.bookingData.attributes.total_amount}</Typography>
                          </ListItem>
                          
                        </RFHList>

                        <Box mt={8}>
                          <RFHButton
                            fullWidth
                            variant="contained"
                            onClick={this.openConfirmPaymentDialog}
                            data-test-id="confirm-payment-sidebar-btn"
                          >
                            {configJSON.ConfirmPayment}
                          </RFHButton>
                        </Box>

                    </>
                  )
                }

              </RFHPaper>
            </Grid>

          </Grid>

        </Container>
        </Hidden>

        <Hidden smUp>
          <MobileWrapper>
            <Box className="header">
              <img src={BackArrow} className="back__button" alt="back-arrow" onClick={goToPrevPage} />

              <Typography variant="h4" className="header__title">{configJSON.RequestApprovedTitle}</Typography>
            </Box>

            {
              this.state.isApiLoading ? (
                <Fade unmountOnExit in={this.state.isApiLoading} >
                  <Box display="flex" justifyContent="center">
                    <CircularProgress style={webStyle.CircularProgress} />
                  </Box>
                </Fade>
              ) : (
                <>
                  <Box flex={1}>  
                    <img src={this.state.bookingData.attributes.movie_image} className="hero__image" alt="poster" />

                    <Container className="mobile__container">

                      <Typography className="movie__title">{this.state.bookingData.attributes.movie_name}</Typography>
                      <Typography className="languages__format">{this.state.bookingData.attributes.movie_languages.concat(", " + this.state.bookingData.attributes.movie_screen_types)}</Typography>
                      <Typography className="date__time">
                        {convertDateForTicket(this.state.bookingData.attributes.slot_date)}
                        <span style={webStyle.MainColor}>&nbsp;|&nbsp;</span>
                        {convertTo12HourFormat(this.state.bookingData.attributes.slot_time)}
                      </Typography>
                      <Typography className="location">{this.state.bookingData.attributes.theatre_name}</Typography>

                      <hr className="divider" />

                        <RFHList>

                          <ListItem>
                            <ListItemText>{configJSON.NumberOfTickets}</ListItemText>
                            <Typography>{convertToPad(this.state.bookingData.attributes.ticket_requested)}&nbsp;-&nbsp;<span className="seat-tier">{this.state.bookingData.attributes.seat_type}</span></Typography>
                          </ListItem>

                          <ListItem>
                            <ListItemText>{configJSON.RequestPricePerTicket}</ListItemText>
                            <Typography>
                              <span className="rupee-icon">₹&nbsp;</span>
                              {this.state.bookingData.attributes.ticket_price.toFixed(2)}
                            </Typography>
                          </ListItem>

                          <ListItem>
                            <ListItemText>{configJSON.SubTotal}</ListItemText>
                            <Typography><span className="rupee-icon">₹&nbsp;</span>{this.state.bookingData.attributes.sub_total.toFixed(2)}</Typography>
                          </ListItem>

                          <ListItem style={webStyle.ConveneinceButton} onClick={() => this.setState((prevState) => ({ isConvenienceFeeOpen: !prevState.isConvenienceFeeOpen }))}>
                            <RFHListItemText style={webStyle.DisplayFlex}>
                              {configJSON.ConvenienceFees}
                              <ListItemIcon style={webStyle.MainColor}>
                                {this.state.isConvenienceFeeOpen ? <ExpandLess /> : <ExpandMore />}
                              </ListItemIcon>
                            </RFHListItemText>
                            <Typography><span className="rupee-icon">₹&nbsp;</span>{this.state.bookingData.attributes.convenience_fees}</Typography>
                          </ListItem>

                          <Collapse in={this.state.isConvenienceFeeOpen} timeout="auto" unmountOnExit>

                            <RFHList className="collapse-list" disablePadding>
                              <ListItem>
                                <ListItemText>{configJSON.BaseAmount}</ListItemText>
                                <Typography><span className="rupee-icon">₹&nbsp;</span>{(+this.state.bookingData.attributes.base_amount).toFixed(2)}</Typography>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  {(configJSON.CGST as string).concat(`${this.state.bookingData.attributes.cgst_percent} %`)}
                                </ListItemText>
                                <Typography><span className="rupee-icon">₹&nbsp;</span>{(+this.state.bookingData.attributes.cgst).toFixed(2)}</Typography>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  {(configJSON.SGST as string).concat(`${this.state.bookingData.attributes.sgst_percent} %`)}
                                </ListItemText>
                                <Typography><span className="rupee-icon">₹&nbsp;</span>{(+this.state.bookingData.attributes.sgst).toFixed(2)}</Typography>
                              </ListItem>
                            </RFHList>

                          </Collapse>

                          <hr className="divider" />

                          <ListItem>
                            <ListItemText>{configJSON.TotalPayableAmount}</ListItemText>
                            <Typography><span className="rupee-icon">₹&nbsp;</span>{(+this.state.bookingData.attributes.total_amount).toFixed(2)}</Typography>
                          </ListItem>

                        </RFHList>


                    </Container>
                  </Box>

                  <Box>
                    <Container>
                      <RFHButton
                        fullWidth
                        variant="contained"
                        onClick={this.openConfirmPaymentDialog}
                      >
                        {configJSON.ConfirmPayment}
                      </RFHButton>
                    </Container>
                  </Box>
                </>
              )
            }

          </MobileWrapper>
        </Hidden>


        <RFHDialog
          data-test-id="confirm-payment-dialog"
          open={this.state.isConfirmPaymentDialogOpen}
          handleClose={this.closeConfirmPaymentDialog}
          maxWidth="xs"
          disableEscapeKeyDown={true}
          dialogTitle={this.ConfirmPaymentTitle}
          dialogActions={this.ConfirmPaymentActions}
          dialogContent={(
            <Box display="flex" justifyContent="center">
              <Typography variant="body2" align="center" style={webStyle.ContentMaxWidth}>
                <span style={webStyle.RupeeIcon}>₹&nbsp;</span>
                {(configJSON.ConfirmPaymentMessage as string).replace("{AMOUNT}", this.state.bookingData?.attributes?.total_amount)}
              </Typography>
            </Box>
          )}
        />

        <RFHDialog
          data-test-id="successful-payment-dialog"
          open={this.state.isSuccessfulOpen}
          handleClose={() => this.redirectToViewTicket(+this.state.newBookingId)}
          maxWidth="xs"
          disableEscapeKeyDown={true}
          dialogActions={(
            <RFHButton data-test-id="payment-done-btn" fullWidth onClick={() => this.redirectToViewTicket(+this.state.newBookingId)}>
              {configJSON.Done}
            </RFHButton>
          )}
          dialogContent={this.PaymentSuccessfulContent}
        />

        <RFHDialog
          data-test-id="add-money-dialog"
          open={this.state.isLowWalletBalanceOpen}
          hideCloseButton={true}
          maxWidth="xs"
          dialogActions={this.LowWalletBalanceActions}
          dialogContent={(
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" justifyContent="center" p={0}>
                <img src={WalletLow} height="48px" width="48px" />
              </Box>
              <Typography variant="h4" align="center" style={webStyle.WalletBalanceTitle}>{configJSON.WalletLow}</Typography>
              <Typography variant="body2" align="center" style={webStyle.WalletBakanceMaxWidth}>
                {(configJSON.WalletLowMessage as string).replace("{AMOUNT}", this.state.requiredAmountToAddInWallet.toString())}
              </Typography>
            </Box>
          )}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        />

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  CircularProgress: {
    marginTop: "20px",
    color: "#ffb43a"
  },
  BackDrop: {
    zIndex: 2000
  },
  BackButton: {
    width: "32px",
    height: "32px",
    backgroundColor: "#ffb43a",
    marginRight: "20px"
  },
  BackButtonIcon: {
    width: "24px",
    height: "24px"
  },
  MaxWidth: {
    maxWidth: "179px",
    padding: 0,
    marginRight: "16px"
  },
  PosterWrapper: {
    alignItem: "center",
    justifyContent: "center",
    border: "solid 0.7px #ffb53a"
  },
  PosterImage: {
    border: "solid 0.7px #ffb53a",
    width: "100%",
    borderRadius: "2px",
    objectFit: "cover" as const,
    height: "230px",
  },
  PriceDataMaxWidth: {
    maxWidth: "505px"
  },
  MainColor: {
    color: "#ffb43a"
  },
  Divider: {
    backgroundColor: "#b4b4b4",
    margin: "24px 0"
  },
  ConveneinceButton: {
    cursor: "pointer",
  },
  DisplayFlex: {
    display: "flex",
    alignItems: "center"
  },
  RupeeIcon: {
    fontFamily: "Roboto, sans-serif"
  },
  ContentMaxWidth: {
    maxWidth: "250px",
    textWrap: "wrap"
  },
  FW600: {
    fontWeight: 600,
    marginTop: "32px"
  },
  WalletBalanceTitle: {
    margin: "24px 16px" 
  },
  WalletBakanceMaxWidth: {
    margin: "auto 30px"
  }
}

const MobileWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingBottom: "16px",
  height: "100%",
  "& .header": {
    display: "flex",
    padding: "12px 16px 20px",
    "& .back__button": {
      width: "24px",
      height: "24px",
      marginRight: "18px",
    },
    "& .header__title": {
      fontSize: "18px"
    }
  },
  "& .hero__image": {
    objectFit: "cover",
    width: "100%",
    aspectRatio: 1.8,
    opacity: 0.6
  },
  "& .mobile__container": {
    padding: "14px",
    "& > p": {
      marginBottom: "2px"
    },
    "& .movie__title": {
      fontSize: "16px",
      marginBottom: "4px"
    },
    "& .languages__format": {
      fontSize: "14px",
      textTransform: "uppercase"
    },
    "& .date__time": {
      fontSize: "14px",
      fontWeight: 600
    },
    "& .location": {
      fontSize: "14px",
    },
    "& .divider": {
      margin: "24px 0"
    }
  }
})
// Customizable Area End
