import React from "react";

// Customizable Area Start
import { Box, CircularProgress, Container, Fade, styled, withStyles } from "@material-ui/core";
import { backarrowleft, playButton } from "./assets";
import MainHeaderComp from "../../../components/src/MainHeaderComp.web";
import FooterWithEvents from "../../../components/src/FooterWithEvents.web";
import ReactPlayer from "react-player";
import { goToPrevPage } from "../../../components/src/utilities";


// Customizable Area End

import TrailersController, {
    Props,
} from "./TrailersController.web";

const LanguageWrapper = styled(Box)({
    color: "#fff",
    fontSize: '16px',
    fontWeight: 500,
    textTransform: "uppercase",
    borderRadius: 0,
    padding: "6px 14px",
    border: '1.3px solid #ffb43a',
    position: 'absolute',
    bottom: '60px',
    left: '20px',
    lineHeight: "1.1",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    fontFamily: "Poppins, sans-serif",
    "@media (max-width: 600px)": {
        padding: "4px 8px",
        left: "10px !important",
        bottom: "35px !important",
        fontSize: "12px",
        border: "1px solid #ffb43a"
    }
});


export class Trailers extends TrailersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { classes } = this.props
        return (
            // Customizable Area Start
            <Box>
                <div style={webStyle.bgcolor}>
                    <MainHeaderComp navigation = {this.props.navigation} />
                    <Container maxWidth="lg">
                        {
                            this.state.isLoading ? (
                                <Fade in={this.state.isLoading} unmountOnExit>
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress style={webStyle.circularProgress} />
                                    </Box>
                                </Fade>
                            ) : (
                                <>
                        <BoxWrapper>
                            <img src={backarrowleft} className="back__button" alt="back-button" onClick={goToPrevPage} />
                            {this.state.trailerData.movieName}
                            &nbsp;
                            {this.state.trailerData.list.length > 1 ? 'Trailers' : 'Trailer'}
                        </BoxWrapper>
                        {this.state.trailerData.list.map((item) => {
                            return (
                                <div style={webStyle.trailersAlign} key={item.trailerId}>
                                    <ReactPlayer
                                        id="Trailer"
                                        url={item.trailerUrl}
                                        className={classes.reactPlayer}
                                        playing
                                        controls
                                        width='100%'
                                        height='100%'
                                        light={this.state.trailerData.posterUrl}
                                        playIcon={<img src={playButton} alt="play" height={50} width={50} />}
                                    />
                                    <LanguageWrapper>{item.language}</LanguageWrapper>
                                </div>
                            )
                        })}
                                </>
                            )
                        }
                    </Container>
                    <Box style={{ marginTop: 'auto' }}>

                        <FooterWithEvents />
                    </Box>
                </div>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const BoxWrapper = styled(Box)({
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "20px",
    margin: "24px 0 20px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .back__button": {
        width: "24px",
        height: "24px",
        cursor: "pointer"
    },
    "@media (max-Width: 600px)": {
        margin: "16px 0",
    }
})
const webStyle = {
    circularProgress: {
        marginTop: "20px",
        color: "#ffb43a"
    },
    bgcolor: {
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column' as const,
        backgroundColor: "#121212",
    },
    trailersAlign: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '25px',
        position: 'relative' as const,
        width: "100%",
        aspectRatio: "1.9",
        ".react-player__preview": {
            backgroundPosition: 'top center !important'
        }
    },
    movieNameTxt: {
        color: "#fff",
        margin: "40px 0 28px",
        fontWeight: 600,
        fontFamily: "Poppins",
        fontSize: "24px"
    },
    languagetxt: {
        color: "#fff",
        margin: "10px",
        fontFamily: "Poppins",
        fontSize: "12px",
    },
    main: {
        display: "flex",
        justifyContent: "space-between",
        padding: '48px 0px 40px 0px'
    },
};

const styles = {
    reactPlayer: {
        "& .react-player__preview": {
            backgroundPosition: 'top center !important',
            backgroundRepeat: 'no-repeat',
            "& > img": {
                "@media (max-width: 600px)": {
                    width: "22px !important",
                    height: "22px !important"
                }
            }

        }

    },
}

export default withStyles(styles)(Trailers);


// Customizable Area End