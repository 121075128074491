import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { IBookingData, IBookingResponse, IBookingMenuData, SeatIdNumber } from "../../../components/src/interfaces.web";
import moment from "moment";

// Customizable Area Start
interface IMovieDetails {
  name: string
  duration: number
  releaseDate: string | Date
  format: string
  language: string
  imageUrl: string
 }

interface ITheaterDetails {
  state: string;
  name: string
  screenName: string
  showDate: string | Date
  showTime: string
}

interface ISeatData {
  seat_id: number
  seat_number: string 
}

interface ISeatSelection {
  type: string
  selectedSeats: SeatIdNumber[]
}

interface ICostDetails {
  subTotal: number
  convenienceFees: number
  convenienceFeesBifurcation: {
    baseAmount: number
    CGSTPercent: number
    SGSTPercent: number
    IGST: number
    IGSTPercent:number
    CGST: number
    SGST: number
    discount: number
  },
  totalFoodAmount?: number
  Food?: {
    menuId: number
    label: string
    price: number
    quantity: number
  }[],
  total: number
}

interface IItoDetails {
  isItoBooking: boolean,
  itoCloseIn: string,
  itoPricePerTicket: number,
  seatTier: string,
  numbersOfSeats: number
}

interface IPreviewBookingData {
  movie: IMovieDetails
  theater: ITheaterDetails
  seats: ISeatSelection
  costDetails: ICostDetails
  userInfo: {
    email: string
    phoneNumber: string
  }  
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  isUpdated?: string;
  handlePayment?: any;
  handleContactInfo? :any
  isPaymentSelection? :boolean,
  selectedValue? :string,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  previewData: IPreviewBookingData
  isLoading: boolean
  isFoodOpen: boolean
  isConvenienceFeeOpen: boolean,
  isContactInfo: boolean,
  phoneNumber:string,
  email:string,
  emailError:string,
  mobileError:string,
  itoDetails: IItoDetails
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PreviewBookingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  bookingDataApiId: string = ''
  updateBookingApiId: string = ''
  updateBookingContactInfoApiId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      previewData: {} as IPreviewBookingData,
      isFoodOpen: false,
      isConvenienceFeeOpen: false,
      isLoading: true,
      isContactInfo: false,
      phoneNumber:"",
      email:"",
      emailError:"",
      mobileError:"",
      itoDetails: {
        isItoBooking: false,
        itoCloseIn: "",
        itoPricePerTicket: 0,
        seatTier: "",
        numbersOfSeats: 0
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if(responseJson) {
        switch (apiRequestCallId) {
          case this.bookingDataApiId:
            this.handleBookingData(responseJson.data)
            break;

          case this.updateBookingApiId:
            this.handleUpdateBooking(responseJson.data)
            break;

          case this.updateBookingContactInfoApiId:
            this.handleUpdateContactInfoBooking(responseJson.data)
            break;
        
          default:
            break;
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  showCostData() {
    this.setState({ isConvenienceFeeOpen: !this.state.isConvenienceFeeOpen })
  }

  showFoodData() {
    this.setState({ isFoodOpen: !this.state.isFoodOpen })
  }

  stateCheck (name:string){
    return (name === "maharshtra" || name === "maharashtra")
}

  getMovieDetails(result: IBookingResponse): IMovieDetails {
    const { movie: { name, duration_in_minutes, image_url, release_date }, language, screen_type } = result.attributes

    return {
      name: name,
      duration: duration_in_minutes,
      imageUrl: image_url,
      releaseDate: moment(release_date).format('YYYY'),
      format: screen_type,
      language: language,
    }
  }

  getTheaterDetails(result: IBookingResponse): ITheaterDetails {
    const { attributes: { show_date_time, theatre_name, screen_name, theatre } } = result
    const [showdate, showtime] = show_date_time.split(" | ")

    return {
      showDate: moment(showdate).format('ddd DD MMM, YYYY'),
      name: theatre_name,
      screenName: screen_name,
      showTime: showtime,
      state: theatre.state
    }
  }

  getMenuCost(menu: IBookingMenuData[]) {
    let total_menu_amount = 0
    let menuItems: { price: number, menuId: number, quantity: number, label: string }[] = []
    if (menu) {
      menu.forEach(({ quantity, quantity_price, booking_menu_id, data: { attributes: { name } } }) => {
        total_menu_amount += quantity_price;
        menuItems.push({ price: quantity_price, menuId: booking_menu_id, quantity, label: name })
      })
    }
    return { totalFoodAmount: total_menu_amount, ...(menu ? { Food: menuItems } : null) }
  }

  getCostDetails(result: IBookingResponse): ICostDetails {
    const { base_amount, convenience_fees, sub_total, sgst, cgst, cgst_percent, sgst_percent, total_amount, menu , igst } = result.attributes

    return {
      subTotal: +sub_total,
      convenienceFees: +convenience_fees,
      convenienceFeesBifurcation: {
        baseAmount: +base_amount,
        CGST: +cgst,
        CGSTPercent: cgst_percent,
        IGST: +igst,
        IGSTPercent: +cgst_percent + +sgst_percent,
        SGST: +sgst,
        SGSTPercent: sgst_percent,
        discount: 0
      },
      total: +total_amount,
      ...this.getMenuCost(menu),
    }
  }

  getItoDetails = (result: IBookingResponse): IItoDetails => {

    if(result.attributes.movie.is_ito_live) {
      const { 
        attributes: {
          number_of_seats,
          movie: {
            is_ito_live,
            ito_end_date
          },
          price_structure: {
            seat_type,
            price
          }
        }
      } = result;
  
      return {
        isItoBooking: is_ito_live,
        itoCloseIn: ito_end_date,
        itoPricePerTicket: price,
        seatTier: seat_type,
        numbersOfSeats: number_of_seats
      } as IItoDetails
    } else {
      return {} as IItoDetails
    }
  }

  handleBookingData(result: IBookingResponse) {
    this.setState({
      previewData: {
        movie: this.getMovieDetails(result),
        theater: this.getTheaterDetails(result),
        seats: {
          type: result.attributes.seat_tier[0],
          selectedSeats: result.attributes.seat_id_number
        },
        costDetails: this.getCostDetails(result),
        userInfo: {
          email: result.attributes.account_email,
          phoneNumber: result.attributes.full_phone_number?.split("").splice(2).join("") || ""
        },
      },
      phoneNumber: result.attributes.full_phone_number && result.attributes.full_phone_number.length > 10 
                  ? result.attributes.full_phone_number.split("").splice(2).join("")
                  :result.attributes.full_phone_number,
      email: result.attributes.account_email,
      itoDetails: this.getItoDetails(result),
      isLoading: false
    }, async () => {

      const existingBookingData = (await getStorageData(LocalStorageKeys.BookingData, true)) as IBookingData;

      const bookingData = {
        ...existingBookingData,
        id: +result.id,
        totalAmount: +result.attributes.total_amount,
      } as IBookingData

      await setStorageData(LocalStorageKeys.BookingData, JSON.stringify(bookingData))
    })
  }

  handleUpdateBooking(result: IBookingResponse) {
    this.setState((prevState) => ({
      previewData: {
        ...prevState.previewData,
        costDetails: this.getCostDetails(result)
      }
    }), async () => {

      const existingBookingData = (await getStorageData(LocalStorageKeys.BookingData, true)) as IBookingData;

      const bookingData = {
        ...existingBookingData,
        id: +result.id,
        totalAmount: +result.attributes.total_amount,
      } as IBookingData

      await setStorageData(LocalStorageKeys.BookingData, JSON.stringify(bookingData))

      window.dispatchEvent(new CustomEvent(EventListenerKeys.PreviewBookingUpdateMenu, { detail: result }))
    })
  }

  async getBookingData() {

    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string 

    this.bookingDataApiId = callApi({
      contentType: configJSON.content,
      method: configJSON.apiGetMethodType,
      endPoint: `${configJSON.getBookingDataApiEndPoint}`.replace('{BOOKING_ID}', `${bookingId}`),
      headers: { "token": loginToken }
    }, runEngine)

  }

  removeItemByMenuId = async (menuId?: number) => {

    const payload = {
      booking: {
        booking_menus_attributes: menuId ?
          [{
            id: menuId,
            _destroy: true
          }] : this.state.previewData.costDetails.Food?.map(item => ({ id: item.menuId, _destroy: true }))
      }
    }

    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string 

    this.updateBookingApiId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.putAPIMethod,
      endPoint: `${configJSON.updateBookingApiEndPoint}`.replace('{BOOKING_ID}', `${bookingId}`),
      headers: { "token": loginToken },
      body: payload
    }, runEngine)

  }

  handleOnClickPay = () => {
    if (this.props?.isPaymentSelection) {
      this.state.previewData?.userInfo?.phoneNumber && this.state.previewData?.userInfo?.email
        ? this.props?.handlePayment()
        : this.handleContactInfo()
    } else {
      this.props?.handlePayment()
    }
  }

  handleContactInfo = () => {
    this.setState({ isContactInfo: true });
  }

  onChangeEmail = (e: any) => {
    this.setState({
      email: e.target.value,
      emailError: e.target.value.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/) ? "" : "please enter valid email"
    });
  }

  onChangeNumber = (event: any) => {
    this.setState({
      phoneNumber: event.target.value,
      mobileError: !event.target.value || event.target.value.length != 10 ? "please enter valid Phone number" : ""
    })
  }

  checkValidation = () => {

    let isValid = true;
    if (this.state.email === "") {
      this.setState({
        emailError: "Please enter Email Id"
      });
      isValid = false;
    }

    const emailRegex = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);

    if (!emailRegex.test(this.state.email)) {
      this.setState({
        emailError: "Please enter valid Email Id"
      });
      isValid = false;
    }
    if (this.state.phoneNumber === "") {
      this.setState({
        mobileError: "Please enter phone number"
      });
      isValid = false;
    }
    if (this.state.phoneNumber.length !== 10) {
      this.setState({
        mobileError: "Please enter valid phone number"
      });
      isValid = false;
    }

    return isValid
  }

  onContinueContactInfo = async () => {

    const payload = {
      booking: {
        account_email: this.state.email,
        full_phone_number: this.state.phoneNumber
      }
    }

    const { id: bookingId } = await getStorageData(LocalStorageKeys.BookingData, true) as IBookingData
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string

    const validity = this.checkValidation();

    if (validity) {
      this.updateBookingContactInfoApiId = callApi({
        contentType: configJSON.contentTypeApi,
        method: configJSON.putAPIMethod,
        endPoint: `${configJSON.updateBookingApiEndPoint}`.replace('{BOOKING_ID}', `${bookingId}`),
        headers: { "token": loginToken },
        body: payload
      }, runEngine);
    }
  }

  async handleUpdateContactInfoBooking(resJson: any) {
    this.setState((prevState) => ({
      previewData: {
        ...prevState.previewData,
        userInfo: {
          phoneNumber: resJson.attributes.full_phone_number,
          email: resJson.attributes.account_email,
        }
      }
    }));
    this.setState({ isContactInfo: false });
    const paymentContactData = {
      name:"Test",
      phoneNumber:resJson.attributes.full_phone_number,
      contactEmail:resJson.attributes.account_email
    }
    setStorageData(LocalStorageKeys.PaymentContactInfo, JSON.stringify(paymentContactData))
  }

  async componentDidMount() { 
    this.getBookingData()
    window.scrollTo(0, 0); 
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.isUpdated !== prevProps.isUpdated) {
      setTimeout(() => this.getBookingData(), 1)
    }
  }

  // Customizable Area End
}
