import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Typography,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { close } from "../../contactus/src/assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { listedTicket, regularBooking } from "./assets";
import RFHDialog from "../../../components/src/RFHDialog";
import { pendingIcon } from "../../cfwallet19/src/assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffb43a",
        },
        background: {
            paper: "#121212",
            default: "#404040",
        },
        text: {
            primary: "#FFF",
        },
    },
    typography: {
        fontFamily: "Poppins",
        h1:{
            fontSize:24,
            fontWeight:600,
            '@media(max-width:600px)': {
                fontSize: "16px",
            },
        },
        h2:{
            fontSize:18,
            fontWeight:500,
            '@media(max-width:600px)': {
                fontSize: "14px",
            },
        },
        h5:{
            fontSize:14,
            fontWeight:"normal",
            color:"#b4b4b4",
            '@media(max-width:600px)': {
                fontSize: "10px",
            },
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export const RFHButton = styled(Button)({
    textTransform: "capitalize",
    color: "#000",
    backgroundColor: "#ffb43a",
    borderRadius: "12px",
    height: "56px",
    fontWeight: 600,
    fontSize: "18px",
    margin:"10px 10px 0",
    "&:hover": {
      backgroundColor: "#ffb43a"
    },
    '@media(max-width:600px)': {
        fontSize: "14px",
        margin:0,
    },
  })

export const PrefBox = styled(Box)({
    padding: " 32px 19px",
    borderRadius: "10.4px",
    backgroundColor: "#252525",
    maxWidth: 213,
    width: "48%",
    boxSizing: "border-box",
    cursor:"pointer",
    marginTop:40,
    '@media(max-width:600px)': {
        padding: " 32px 10px",
    },
})

const ImageBox = styled(Box)({
    width:52,
    height:40,
    marginBottom:12,
    '@media(max-width:600px)': {
      width:32,
      height:24,
    },
})

// Customizable Area End

import SelectPreferenceController, {
    Props,
    configJSON
} from "./SelectPreferenceController";

class SelectPreference extends SelectPreferenceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    NoListedTicketContent = (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box display="flex" justifyContent="center" p={0} mb={2}>
            <img src={pendingIcon} height="48px" width="48px" />
          </Box>
          <Typography variant="h1" align="center">
            {configJSON.noTicketMessage}
          </Typography>
        </Box>
    )
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { 
            classes, 
            handleOnSelectRegularBooking, 
            handleOnSelectListedTicket } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Dialog
                    id="language"
                    open={true}
                    PaperProps={{
                        style: webStyle.papperStyle,
                        elevation: 0,
                    }}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    data-test-id="seat_dialog"
                >
                    <img
                        src={close}
                        alt='closebutton'
                        data-test-id="crossBtn"
                        className={classes.crossBtn}
                        style={webStyle.crossBtn}
                        onClick={() => this.props.handleOnClose()}
                    />
                    <DialogContent 
                    className={classes.dialogContent}
                        data-test-id="seat_number">
                        <Typography align="center" variant="h1">Select your Preference</Typography>
                        <Box className={classes.prefMainBox}>
                            <PrefBox data-test-id="regular_booking" onClick={() => handleOnSelectRegularBooking()}>
                                <ImageBox>
                                    <img width="100%" height="100%" src={regularBooking} />
                                </ImageBox>
                                <Typography style={webStyle.bookingPref} variant="h2">Regular Booking</Typography>
                                <Typography variant="h5">Book your ticket directly
                                    <br /> from Ticket Xchange.</Typography>
                            </PrefBox>

                            <PrefBox data-test-id="listed_ticket" onClick={() => this.getListedTicket()}>
                               <ImageBox>
                                <img
                                    width="100%"
                                    height="100%"
                                    src={listedTicket}
                                />
                                </ImageBox>
                                <Typography variant="h2" style={webStyle.bookingPref}>Listed Tickets</Typography>
                                <Typography variant="h5">Buy your tickets from
                                    <br />Seller.</Typography>
                            </PrefBox>
                        </Box>
                    </DialogContent>
                </Dialog>

                <RFHDialog
                    data-test-id="successful-payment-dialog"
                    open={this.state.noTicket}
                    handleClose={() => { this.onCloseTicket() }}
                    maxWidth="xs"
                    disableEscapeKeyDown={true}
                    dialogActions={(
                        <RFHButton data-test-id="payment-done-btn" fullWidth onClick={() => { this.onCloseTicket() }}>
                            {configJSON.GoRegularBooking}
                        </RFHButton>
                    )}
                    dialogContent={this.NoListedTicketContent}
                />
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    papperStyle: {
        backgroundColor: "#404040",
        color: "#fff",
        padding: '48px 32px',
        maxWidth: "667px",
        width: "100%",
        boxSizing: "border-box",
        margin:"0px 5px"
    } as CSSProperties,
    crossBtn: {
        width: 28,
        height: 28,
        position: "absolute" as const,
        top: 10,
        right: 10
    },
    prefMainBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    } as CSSProperties,

    bookingPref: {
        color: "#ffb43a",
        marginBottom: 12
    }
}

const styles = {
    WrapperBoxs: {
        minHeight: '100vh',
    },
    crossBtn:{
        width:28,
        height:28,
        position:"absolute" as const,
        top:10,
        right:10
    }
}

export default (withStyles(styles)(SelectPreference))
export { SelectPreference }
// Customizable Area End
