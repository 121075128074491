export const starFilled = require("./assets/starFilled.png");
export const starOutline = require("./assets/starOutline.png");
export const thumbsUp = require("./assets/thumbsUp.png");
export const thumbsDown = require("./assets/thumbsDown.png");
export const thumbsDownFilled = require("./assets/thumbsDownFilled.png");
export const thumbsUpFilled = require("./assets/thumbsUpFilled.png");
export const cancelledImage = require("./assets/cancel-listing-label.png");
export const InvalidStatusImage = require("./assets/Invalid.png");
export const nextButton = require("./assets/next.png");
export const previousButton = require("./assets/previous.png");
export const vegIcon = require("./assets/ic-veg.png");
export const nonVegIcon = require("./assets/ic-non-veg.png");
